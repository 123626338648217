
  <div class="app content kobra">

    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>Nuevo</mat-card-title>
      </mat-card-header>
      <mat-card-content class="kobra-card-content">
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px">
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-select-search
              [label]="'Producto'"
              [placeHolder]="'Selecciona un producto'"
              [value]="'idProducto'"
              [labelField]="'idProductoYNombre'"
              [icon]="'inventory_2'"
              [options]="productos"
              [(ngModel)]="idProducto"
              (ngModelChange)="onSelectProduct();"
              class="pago-gestor"
            >
            </mat-select-search>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="pago-gestor">
              <mat-label>Pago al gestor</mat-label>
              <input matInput textSanitizer  type="number"
                min="1.00"
                max="99999.99"
                (input)="onInputLimite($event)"
                [(ngModel)]="pagoAlGestor"
                placeholder="Capture cuanto se le pagará al gestor">
              <mat-icon matSuffix>attach_money</mat-icon>
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list-responsive>

        <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px" *ngIf="idTipoProducto == 1">
          <mat-grid-tile colspan="1" rowspan="1">

            <mat-form-field class="pago-gestor">
              <mat-label></mat-label>
              <input matInput textSanitizer  class="upper"
                maxlength="100"
                [disabled]="true"
                (ngModelChange)="idProductoYNombre"
                [(ngModel)]="idProductoYNombre">
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="pago-gestor">
              <mat-label>Pago al gestor</mat-label>
              <input matInput textSanitizer  type="number"
                min="1.00"
                max="99999.99"
                (input)="onInputLimite($event)"
                [(ngModel)]="pagoAlGestorPromesa"
                placeholder="Capture cuanto se le pagará al gestor">
              <mat-icon matSuffix>attach_money</mat-icon>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list-responsive>

        <div class="button-center">
          <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()" [disabled]="loadings.productos || loadings.guardando">
            <mat-icon class="kobra-icon">
              close
            </mat-icon> Cancelar</button>
          <button mat-stroked-button class="kobra-button" type="submit" (click)="save()" [disabled]="loadings.productos || loadings.guardando">
            <mat-icon>
              save
            </mat-icon> Guardar</button>
        </div>
      </mat-card-content>
      <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadings.productos || loadings.guardando">
        <i *ngIf="loadings.guardando">Guardando concepto...</i>
        <i *ngIf="loadings.productos">Obteniendo productos...</i>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
    <!--div class="ui segment">
      <h1>Nuevo</h1>
      <div class="content">
        <div class="ui form">
          <div class="two fields">
            <div class="field">
              <mat-label>Nombre*</mat-label>
              <input
                matInput textSanitizer
                type="text"
                placeholder="Nombre"
                (ngModelChange)="requireds.nombre = false;"
                [(ngModel)]="productoNvo.nomProducto"
                disabled = true
              />
              <label for="" style="position: absolute;" *ngIf="requireds.nombre" class="errors">Este campo es requerido.*</label>
            </div>
            <!--div class="field">
              <mat-label>Alias</mat-label>
              <input
                matInput textSanitizer
                type="text"
                placeholder="Alias"
                [(ngModel)]="sucursalNva.alias"
              />
            </div>
          </div>
          <div class="ui segment">
            <div _ngcontent-dgk-c22="" class="ui top attached label"> Información Producto </div>
            <div class="ui form">
              <div class="four fields">
                <div class="field">
                  <mat-label><b>Nombre</b></mat-label>
                  <br>{{productoNvo.nomProducto}}
                </div>
              </div>
              <div class="four fields">
                <div class="field">
                  <mat-label><b>Descripción</b></mat-label>
                  <br>{{productoNvo.descripcion}}
                </div>
              </div>
            </div>
          </div>

          <div style="margin: 30px 0">

            <div class="ui segment">
              <h3 class="ui left floated header">Conceptos Productos</h3>
              <div class="ui clearing divider"></div>
              <div>
                <div class="two fields">
                  <div class="field">
                    <label for="">{{loadings.administradoresFinanciera ? 'Cargando Administradores...' : 'Administrador'}}</label>
                    <select [(ngModel)]="adminCliente" name="addAdmin">
                      <option [ngValue]="null">Selecciona Administradores</option>
                      <option *ngFor="let administrador of administradores" [ngValue]="administrador">{{administrador.nombre}} {{administrador.apellidoPaterno}} {{administrador.apellidoMaterno}}</option>
                    </select>
                  </div>
                </div>

                <div class="action-buttons" align="right">
                  <button class="ui button" type="button" (click)="agregarAdministrador()">Agregar</button>
                </div>
              </div>

              <div>
                <h4 >Administradores Producto</h4>
                <div class="ui clearing divider"></div>

                <div style="margin: 10px;">
                  <br />

                  <table class="ui very basic collapsing celled table">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Primer Apellido</th>
                        <th>Segundo Apellido</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let administrador of productoNvo.administradores; let i = index;">
                        <td>
                          <h4 class="ui image header">
                            <div class="content" style="font-weight: normal;">
                              {{administrador.nombre}}
                            </div>
                          </h4>
                        </td>
                        <td>
                          <h4 class="ui image header">
                            <div class="content" style="font-weight: normal;">
                              {{administrador.apellidoPaterno}}
                            </div>
                          </h4>
                        </td>
                        <td>
                          <h4 class="ui image header">
                            <div class="content" style="font-weight: normal;">
                              {{administrador.apellidoMaterno}}
                            </div>
                          </h4>
                        </td>
                        <td>
                          <h4 class="ui image header">
                            <div class="content" style="font-weight: normal;">
                              {{administrador.email}}
                            </div>
                          </h4>
                        </td>
                        <td>
                          <div class="ui button" (click)="borrarAdministrador(i)">Borrar</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" *ngIf="loadings.administradores"><i>Cargando Administradores Productos...</i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="action-buttons">
          <button type="button" class="ui button" (click)="cancel()">
            Cancelar</button>
          <button [disabled]="!cambioData" [ngClass]="{'positive ui button': true}" (click)="save()">Guardar</button>
        </div>
      </div>
    </div-->
  </div>
