import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IClientesMovimientos, IClientesMovimientosPagination } from './clientes-movimientos.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientesMovimientosService {

  loader: boolean;
  public clientesMovs$: Observable<IClientesMovimientos[]>;

  public tiposMovimientos = [
    { id: 1, nombre: 'Todos' }, { id: 2, nombre: 'Abono' }, { id: 3, nombre: 'Cargo' }
  ];

  constructor(
    public saldosServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder,
  ) {

  }

  generarMovimiento(formValue: any) {

    if (!(formValue.fecMovimiento instanceof Date)) {
      formValue.fecMovimiento = formValue.fecMovimiento.toDate();
    }
    
    formValue.fecMovimiento = new Date(formValue.fecMovimiento-formValue.fecMovimiento.getTimezoneOffset()*60000).toISOString().substr(0, 10);
    return this.saldosServ.post(`/financieras/${formValue.idFinanciera}/movimientos`, formValue);
  }

  getGrid(queryParams: string) {
    return this.saldosServ.get(`/financieras-movs-grid${queryParams}`);
  }

  subirArchivo(uploadFile: File, ) {
    const formData = new FormData();
    formData.append('archivo', uploadFile);

    return this.saldosServ.uploadFile(`/financieras/ajustes-masivos`, formData);

  }

}
