import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {
  transform(size: number): string {
    return filesize(size, { base: 10 }) as string;  // Aquí puedes agregar las opciones de configuración necesarias
  }
}
