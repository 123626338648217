import { Component, Input, OnInit } from '@angular/core';
import { take } from "rxjs/internal/operators/take";
import { TimelineEvent } from './timeline-event.interface';
import { CuentaService } from "../../cuentas/shared/cuenta.service";
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'app-timeline-cuenta',
  templateUrl: './timeline-cuenta.component.html',
  styleUrls: ['./timeline-cuenta.component.css'],
  providers: [CuentaService]
})
export class TimelineCuentaComponent implements OnInit {
  @Input() idCliente: number;
  @Input() idAcreditado: number;
  @Input() idCuenta: number;
  @Input() height: string;
  //@Input() subIdExterno?: string;
  modoAgregar:boolean = false;
  loadingAccountHistory = true;
  nuevoComentario: string = '';
  pagina: number = 1;
  registros: number = 20;
  totalPaginas: number = 0;

  public bitacoras: TimelineEvent[] = [];

  constructor (
    private app: ApplicationService,
    private cuentaService: CuentaService
  ){

  }

  ngOnInit(): void {
      this.cargarBitacoraCuenta(this.idCliente, this.idAcreditado, this.idCuenta)
  }

  private cargarBitacoraCuenta(idCliente: number, idAcreditado: number, idCuenta: number, pagina: number = 1) {
    this.loadingAccountHistory = true;
    if(pagina == 1){
      this.bitacoras = [];
    }
    this.cuentaService
        .obtenerBitacoraCuenta(idCliente, idAcreditado, idCuenta, pagina, this.registros)
        .pipe(take(1))
        .subscribe
        (
            (respuesta: any) => {
              this.pagina = pagina;
              this.loadingAccountHistory = false;
              this.totalPaginas = respuesta.data.pages;
              if(pagina == 1){
                this.bitacoras = respuesta.data.pageData;
              }
              else{
                for (let index = 0; index < respuesta.data.pageData.length; index++) {
                  const bitacora = respuesta.data.pageData[index];
                  this.bitacoras.push(bitacora);
                }
              }
            },
            (err: any) => {
                this.app.showError(err);
                this.loadingAccountHistory = false;
            }
        );
  }

  addComment(){
    if(!this.nuevoComentario){
      this.app.showSnackbar(
        "Aviso",
        "Es necesario capturar un comentario.",
        3000,
        "warning"
      );
      return;
    }
    /*let estructuraComentario = this.nuevoComentario.trim().split(' ');
    if(estructuraComentario.length < 5){
      this.app.showSnackbar(
        "Aviso",
        "Es necesario que el comentario sea mas descriptivo.",
        3000,
        "warning"
      );
      return;
    }*/
    let bitacora = {
      idAccion: 1,
      comentario: this.nuevoComentario
    };
    let loading = this.app.showLoading("Guardando comentario en la bitácora...");
    this.cuentaService
        .guardarBitacoraCuenta(this.idCliente, this.idAcreditado, this.idCuenta, bitacora)
        .pipe(take(1))
        .subscribe
        (
            (respuesta: any) => {
              let usuario = JSON.parse(localStorage.getItem('user')).admin;
              let nvaBitacora: TimelineEvent = {
                usuario: `${usuario.nombre} ${usuario.apellidoPaterno}`,
                accion: 'ha añadido un',
                comentario: bitacora.comentario,
                tipoUsuario: 'El usuario',
                accionMarcada: 'comentario',
                fecha: new Date(),
                flagVisita: false
              }
              this.bitacoras.unshift(nvaBitacora);
              this.nuevoComentario = null;
              this.modoAgregar = false;
              this.app.hideLoading(loading);
            },
            (err: any) => {
                this.app.hideLoading(loading);
                this.app.showError(err);
                this.loadingAccountHistory = false;
            }
        );
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement; // Se obtiene el elemento que tiene el scroll
    const scrollHeight = element.scrollHeight;
    const scrollTop = element.scrollTop;
    const clientHeight = element.clientHeight;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight * 100;
    //this.checkColumnsVisibility(element);
    
    if (scrollPercentage >= 99.5 && !this.loadingAccountHistory && this.pagina < this.totalPaginas) {
      this.cargarBitacoraCuenta(this.idCliente, this.idAcreditado, this.idCuenta, this.pagina+1);
    }
  }
}
