import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'historial-no-disponible',
  templateUrl: './historial-no-disponible.component.html',
  styleUrls: ['./historial-no-disponible.component.css'],
  providers: [
  ]
})
export class HistorialNoDisponibleComponent implements OnInit {
  @Input() historialNoDisponible:Array<any>;

  constructor(
   
  ) {
   
  }

  ngOnInit() {
    
  }

}
