<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; obtenerGeoCercas()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="lenders"
          [(ngModel)]="idLender"
          (ngModelChange)="onChangeLender($event);"
          [ngModelOptions]="{standalone: true}"
        >
        </mat-select-search>
        
      </li>
      <li class="filtro-side">
        <mat-select-search
            class="id-card-field"
            [label]="'Agente'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'idAgente'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="asignarAgenteFilterSearch"
            [(ngModel)]="filtros.idCobrador"
            (ngModelChange)="this.contabilizadorDeFiltros('generales');"
            [minBusqueda]="1"
        >
        </mat-select-search>
     
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; obtenerGeoCercas()">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
<div class="app content kobra" style="overflow: hidden;" *ngIf="!hijosActivos">
    <div class="button-container" *ngIf="!crearGeoCerca && !isEditar">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" style="background-color: white;">
          <mat-button-toggle value="filtros" 
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar" mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="obtenerGeoCercas();"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="dibujar" 
            (click)="modoDibujar()" 
            matTooltip="Dibujar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">brush</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-header>
            <mat-card-title> </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
                El módulo Administradores está diseñado para manejar eficientemente el listado de usuarios del sistema,
                enfocándose en aquellos con roles administrativos. Este módulo es fundamental para la gestión de accesos y
                control de usuarios. Para garantizar la seguridad y la autenticidad, los usuarios se dan de alta utilizando
                el correo institucional de la empresa. A continuación, se detallan sus principales características y
                funcionalidades:
            </p>
            Acciones:
            <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los
                conceptos, permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
                criterios de filtros aplicados.
            </p>
            <p>
                <mat-icon class="kobra-icon">brush</mat-icon><b> Dibujar: </b> Permite a los usuarios dibujar geocercas
                directamente en el mapa, facilitando la delimitación precisa del área de trabajo para los agentes en campo.
                Una vez creada una geocerca, se puede asignar como el área de trabajo específica para uno o más agentes,
                asegurando que las actividades se centren en la zona designada.
                Cada geocerca puede ser nombrada y descrita para una fácil identificación y referencia. Esto ayuda a los
                usuarios a entender rápidamente el propósito y el alcance de cada geocerca.
                Puedes también establecer una fecha de vencimiento para cada geocerca. La inclusión de una fecha de
                vencimiento es opcional, pero puede ser útil para geocercas temporales o para proyectos con un plazo
                definido.
            </p>
        </mat-card-content>
    </mat-card>
    <div class="custom-drawer-container">
        <div class="custom-drawer-content">
            <mat-card class="geo-card">
                <mat-card-header *ngIf="crearGeoCerca || isEditar">
                    <mat-card-title>{{crearGeoCerca ? 'Nueva geocerca' : 'Editar geocerca'}}</mat-card-title>
                </mat-card-header>
                <mat-card-content [ngClass]="{'geo-content': !crearGeoCerca && !isEditar, 'geo-content-editar': crearGeoCerca || isEditar}">
                    <div *ngIf="!crearGeoCerca && !isEditar">
                        <div style="margin-left: 30px; display: flex; justify-content:right;">Mostrar</div>
                        <div style="display: flex; justify-content: right; margin-right: 15px;">
                            <mat-checkbox class="kobra-checkbox example-margin field" [checked]="mostrarTodasLasGeoCercas"
                                (change)="seleccionarTodasLasGeoCercas($event.checked)"
                                [(ngModel)]="mostrarTodasLasGeoCercas">
                            </mat-checkbox>
                        </div>
                        <div class="sinFormulario">
                            <app-loader *ngIf="loading"  [message]="'Cargando información...'"></app-loader>
                            <div *ngFor="let geoCerca of geoCercas">                    
                                <mat-card class="card" (click)="onSelectListEvent(geoCerca)">  
                                  <mat-card-content>
                                    <div style="display: flex; margin-bottom: 5px;">
                                      <mat-icon class="iconoDoc kobra-icon" style="margin-top: 7px;">public</mat-icon>
                                      <span style="font-weight: bold; word-break: break-all; flex: 1; margin-top: 10px;">{{geoCerca.nombre}}</span> 
                                      <div class="iconoGrid">
                                        <button mat-icon-button       
                                          matTooltip="Descripción"
                                          mat-tooltip-panel-above
                                          (click)="ayudaDescripcion(geoCerca)">
                                          <mat-icon color="primary">help</mat-icon>
                                        </button>
                                        <button mat-icon-button
                                          [matMenuTriggerFor]="menuDescarga"
                                          matTooltip="Acciones"
                                          mat-tooltip-panel-above>
                                          <mat-icon class="kobra-icon">more_vert</mat-icon>
                                        </button>
                                      </div> 
                                    </div>
                                    <div *ngIf="geoCerca.isDescripcion" style="margin-top: 10px;">
                                      <span style="font-weight: bold;">Descripción</span>
                                      <p style="margin-left: 10px; word-break: break-all; flex: 1; ">{{geoCerca.descripcion}}</p>
                                    </div>
                                    <div style="margin-top: 5px;  width: 100%; font-size: small;color: gray; margin-bottom: 10px;">
                                        Vencimiento: {{geoCerca.fechaVencimiento?momentGeocerca(geoCerca.fechaVencimiento).format('DD/MM/YYYY'):'N/A'}}
                                    </div>
                                    <div style="display: flex;" class="iconoGrid">
                                      <i style="color: #38373782;">{{momentGeocerca(geoCerca.fecRegistro).format('DD/MM/YYYY HH:mm')}}</i>
                                    </div>
                                    <mat-menu #menuDescarga="matMenu">
                                      <button mat-menu-item 
                                        (click)="editarGeocerca(geoCerca)"
                                        >
                                        <mat-icon class="kobra-icon">add</mat-icon>
                                        <span>Editar</span>
                                      </button>
                                      <button mat-menu-item 
                                        (click)="confirmarBorrado(geoCerca)"
                                        >
                                        <mat-icon color="warn">delete-forever</mat-icon>
                                        <span>Eliminar</span>
                                      </button>
                                    </mat-menu>
                                  </mat-card-content>                  
                                </mat-card>
                              </div>
                            <div *ngIf="geoCercas.length == 0 && !loading" class="sin-info">
                                <i>Sin información para mostrar</i>
                              </div>
                        </div>
                    </div>

                    <div *ngIf="crearGeoCerca || isEditar">
                        <div class="conFormulario">
                            <mat-accordion [multi]="true">
                                <mat-expansion-panel [expanded]="true">
                                    <mat-expansion-panel-header>
                                      Información geocerca
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label for="">Nombre</mat-label>
                                        <input matInput textSanitizer  type="text" id="dnombreGeoCerca"
                                            [(ngModel)]="geoCercaSeleccionadaMapa.nombre" maxlength="100">
                                    </mat-form-field>
                                    <mat-date-picker-formater
                                        [label]="'Fecha Vencimiento'"
                                        [min]="today"
                                        [placeHolder]="'Capture la fecha'"
                                        [(ngModel)]="geoCercaSeleccionadaMapa.fechaVencimiento"
                                        (ngModelChange)="fechaVencimientoAgente = geoCercaSeleccionadaMapa.fechaVencimiento"
                                    />
                                    <mat-form-field style="width: 100%;" appearance="fill">
                                        <mat-label>Descripción</mat-label>
                                        <textarea rows="3" maxlength="500"
                                            [(ngModel)]="geoCercaSeleccionadaMapa.descripcion" matInput textSanitizer ></textarea>
                                    </mat-form-field>
                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                      Agentes
                                    </mat-expansion-panel-header>
                                    <mat-select-search
                                        class="id-card-field"
                                        [label]="'Agente'"
                                        [placeHolder]="'Busca por id o nombre'"
                                        [value]="'option'"
                                        [labelField]="'nombre'"
                                        [icon]="'account_circle'"
                                        [realizarBusqueda]="asignarAgenteFilterSearch"
                                        [(ngModel)]="agenteSeleccionado"
                                        [minBusqueda]="1"
                                    >
                                    </mat-select-search>
                                    
                                    <mat-date-picker-formater
                                        [label]="'Fecha Vencimiento'"
                                        [min]="today"
                                        [max]="geoCercaSeleccionadaMapa.fechaVencimiento"
                                        [placeHolder]="'Capture la fecha'"
                                        [(ngModel)]="fechaVencimientoAgente"
                                        [limpiar]="false"
                                    />

                                    <mat-card-actions align="end" style="padding: 0px !important;">
                                        <button mat-stroked-button [disabled]="!agenteSeleccionado" [ngClass]="{'boton-accion-front': agenteSeleccionado}"
                                            (click)="agregarAgenteGeocerca()"
                                            matTooltip="Agregar agente"
                                            mat-tooltip-panel-above
                                        >
                                            <mat-icon>
                                                add
                                            </mat-icon>
                                            Agregar
                                        </button>
                                    </mat-card-actions>

                                    <div *ngIf="agentesGeocercas.length > 0" style="overflow: auto; max-height: 300px;">
                                        <div *ngFor="let agente of agentesGeocercas">
                                          <mat-card class="card" *ngIf="!agente.isEliminar">
                                            <mat-card-content>                      
                                              <div style="display: flex;">
                                                <mat-icon class="iconoDoc kobra-icon">person</mat-icon>
                                                <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{agente.nombre}}</span> 
                                                <div class="iconoGrid">
                                                    <mat-form-field style="visibility: hidden; width: 1px;height: 1px;" class="example-full-width" appearance="fill">
                                                        <mat-label>Choose a date</mat-label>
                                                        <input [min]="today" [max]="geoCercaSeleccionadaMapa.fechaVencimiento" (ngModelChange)="cambioFechaVencimientoAgente(agente)" matInput textSanitizer  [matDatepicker]="picker" [(ngModel)]="agente.fechaVencimiento">
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                    <a matTooltip="Cambiar fecha vencimiento"
                                                    mat-tooltip-panel-above>
                                                        <mat-icon
                                                        (click)="picker.open()">event
                                                        </mat-icon>
                                                    </a>  
                                                    <button mat-icon-button
                                                            (click)="confirmarBorradoAgente(agente)"
                                                            matTooltip="Eliminar agente"
                                                            mat-tooltip-panel-above 
                                                        >
                                                            <mat-icon color="warn">delete_forever</mat-icon>
                                                    </button>                   
                                                </div>
                                                
                                              </div>
                                                <div>  
                                                    Vencimiento: {{agente.fechaVencimiento?momentGeocerca(agente.fechaVencimiento).format('DD/MM/YYYY'):'N/A'}}
                                                </div>
                                            </mat-card-content>
                                            
                                          </mat-card>
                                        </div>
                                      </div>
                                    <!-- <mat-list *ngIf="agentesGeocercas.length > 0">
                                        <mat-list-item class="item-agentes" *ngFor="let agente of agentesGeocercas">
                                            <div style="width: 500px; ">
                                                <div class="item-agente-nombre">
                                                    {{agente.nombre}}
                                                </div>
                                                <div style="display: inline-block;">
                                                    <div style="display:inline-block; ">
                                                        <mat-form-field style="visibility: hidden; width: 1px;height: 1px;" class="example-full-width" appearance="fill">
                                                            <mat-label>Choose a date</mat-label>
                                                            <input (ngModelChange)="cambioFechaVencimientoAgente(agente)" matInput textSanitizer  [matDatepicker]="picker" [(ngModel)]="agente.fechaVencimiento">
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                        <a matTooltip="Cambiar fecha vencimiento"
                                                        mat-tooltip-panel-above>
                                                            <mat-icon
                                                            (click)="picker.open()">event
                                                            </mat-icon>
                                                        </a>
                                                    </div>
                                                
                                                    <div style="display:inline-block;">
                                                        <button mat-icon-button
                                                            (click)="borrarAgenteGeocerca(agente.idAgente)"
                                                            matTooltip="Eliminar agente"
                                                            mat-tooltip-panel-above 
                                                        >
                                                            <mat-icon>delete_forever</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style="width: 400px; color: gray;font-size: small; margin-left: 10px;">  
                                                    Vencimiento: {{agente.fechaVencimiento?momentGeocerca(agente.fechaVencimiento).format('DD/MM/YYYY'):'N/A'}}
                                                </div>
                                            </div>
                                            <hr style="margin-right: 15px;">
                                        </mat-list-item>
                                    </mat-list> -->
                                </mat-expansion-panel>  
                            </mat-accordion>
                        </div>  
                        <mat-card-actions align="end" style="gap: 10px;">
                            
                            <button class="boton-cancelar" mat-flat-button (click)="cancelarGeoCerca()">
                              <mat-icon class="kobra-icon">
                                close
                              </mat-icon> 
                              Cancelar
                            </button>
                            
                            <button mat-stroked-button [ngClass]="{'boton-accion-guardar': validarGuardar()}" (click)="guardarGeoCerca()" [disabled]="!validarGuardar()">
                                <mat-icon>save</mat-icon> 
                                Guardar
                            </button>
                        </mat-card-actions>
                    </div>
                    
                </mat-card-content>
                
            </mat-card>
        </div>
        <div class="custom-drawer-content">
            <mat-card class="geo-card-mapa">
                <mat-card-content>
                  <div class="mapa-radio">
                    <mat-radio-group [(ngModel)]="tipoMapa" (ngModelChange)="cambioTipoMapa()">
                      <mat-radio-button class="kobra-radio" [value]="1">Normal</mat-radio-button>
                      <mat-radio-button class="kobra-radio" [value]="2">Satélite</mat-radio-button>
                    </mat-radio-group>
                  </div>
                    <div class="map-style" [ngClass]="{'editar': crearGeoCerca || isEditar}" id="map"></div>
                </mat-card-content>
            </mat-card>
        </div>
      </div>
</div>
