import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Account } from '../../account/account.model';
import { ServiciosKobraService } from '../../core/http/servicios-kobra/servicios-kobra.service';
import { Client } from '../../client/client.model';
import { ClientAddress } from '../../client-address/client-addres.model';
import { Visit } from '../../visit/visit.model';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ServicesKobraResponse } from '../../core/http/services-kobra/services-kobra-response'
import { ServicesVisitasService } from '../../core/http/services-visitas/services-visitas.service';
import { environmentSelector } from 'src/environments/environment.selector';


@Injectable()
export class CuentaService {
  public baseURL: string = environmentSelector().kobraServices.servicios;
  private resource: string = '/cuentas';

  constructor(
    private httpClient: HttpClient,
    private serviciosKobra: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.servicios;
  }

  private headers(): HttpHeaders {
    let header: any = { 'Content-Type': 'application/json' };
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Authorization'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, login: boolean = false): Observable<ServicesKobraResponse> {

    return req.pipe(

      map(data => {
        let res = <ServicesKobraResponse>data;
        return res;
      }),
      catchError((err: HttpErrorResponse | string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            } 
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              message = err.error.error.message || 'Refresca la página e intentalo de nuevo.';
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public client(id: number): Observable<Client> {
    return this.serviciosKobra.get(`${this.resource}/${id}/acreditado`).pipe(
      map(res => {
        let client: Client = Client.map(res.data);
        return client;
      })
    );
  }

  public clientAddress(id: number, queryString: string): Observable<ClientAddress> {   
    let uri = `${this.resource}/${id}/domicilio-acreditado${queryString}`;
    return this.serviciosKobra.get(uri).pipe(
      map(res => {
        let clientAddress: ClientAddress = ClientAddress.map(res.data);
        return clientAddress;
      })
    );
  }

  // public oneDetail(id: number): Observable<Account> {
  //   return this.serviciosKobra.get(`${this.resource}/${id}`).pipe(
  //     map(res => {
  //       let account: Account = Account.map(res.data);
  //       return account;
  //     })
  //   );
  // }

  public visits(id: number): Observable<Visit[]> {
    return this.serviciosKobra.get(`${this.resource}/${id}/visitas`).pipe(
      map(res => {
        let visits: Visit[] = Visit.mapArray(res.data);
        return visits;
      })
    );
  }

  obtenerBitacoraCuenta(idCliente: number, idAcreditado: number, idCuenta: number, page: number, rows: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/acreditados/${idAcreditado}/cuentas/${idCuenta}/bitacora?page=${page}&rows=${rows}`);
  }

  guardarBitacoraCuenta(idCliente: number, idAcreditado: number, idCuenta: number, bitacora: any) {
    return this.serviciosVisitas.post(`/clientes/${idCliente}/acreditados/${idAcreditado}/cuentas/${idCuenta}/bitacora`, bitacora).pipe(
      map(res => res.data)
    );
  }

  public patch(path: string, data: any): Observable<ServicesKobraResponse> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, { headers: this.headers() }));
  }

  public saveDomicilio(idAcreditado: number, domicilio: object): Observable<any> {
    return this.patch(`/acreditado/${idAcreditado}/domicilio`, domicilio);
  }

  public update(idAcreditado: number ,client: Client): Observable<any> {
    return this.patch(`/acreditado/${idAcreditado}`, client);
  }

  public getMotivo(): Observable<any> {
    return this.serviciosKobra.get('/etiquetas-operaciones')
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public actualizaEstatus(idCuenta: number, estatus: object): Observable<any> {
    return this.patch(`/cuentas/${idCuenta}/estatus`, estatus);
  }

  public actualizarCuenta(idCuenta: number, cuenta: any): Observable<any> {
    return this.patch(`/cuentas/${idCuenta}`, cuenta);
  }
  public editarCuentaDetalle(idCuenta: number, idFinanciera: number, listaDetalleCuenta: Array<any>): Observable<any> {
    let detalle = {
      idFinanciera: idFinanciera,
      listaDetalleCuenta: listaDetalleCuenta
    };
    return this.patch(`/cuentas/${idCuenta}/detallado-cuenta`, detalle);
  }
}


