import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CobradorMovimiento, CoMovimientoPagination } from './cobrador-movimiento.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CobradoresMovimientosService {
  public movimientos$: Observable<CobradorMovimiento[]>;
  public movimiento: CobradorMovimiento;
  public loader: boolean;

  public pagination: CoMovimientoPagination;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;

  public movStatus = [
    { id: 1, nombre: 'Todos' },
    { id: 2, nombre: 'Con periodo de facturación' },
    { id: 3, nombre: 'Sin periodo de facturación' }
  ];

  public filtros = {
    idCobradorFacturacionPeriodo:  null,
    nombrePeriodo:  null,
    idCobrador:  null,
    nombreCobrador:  null,
    idConcepto:  null,
    nombreConcepto:  null,
    movStat:  null,
    fechaInicio:  null,
    fechaFin:  null,
    page: 1
  }
  

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    private fb: FormBuilder
  ) {

  }

  obtenerMovimientos(queryParams: string): Observable<any> {
    return this.saldosKobraServ.get(`/cobradores-movs${queryParams}`);   
  }
  

  agregarMovimiento(movimiento: CobradorMovimiento): Observable<any> {
    return this.saldosKobraServ.post('/cobrador-mov', movimiento);
  }


  asignarPeriodoMovs(idPeriodo: number, fechaInicio: string, fechaFin: string, idCobrador?: number, idConcepto?: number  ) {
    const periodoObj = {
      idCobradorFacturacionPeriodo: idPeriodo
    };

    let queryParams: string;

    queryParams = `?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

    if (idCobrador) {
      queryParams += `&idCobrador=${this.filtros.idCobrador}`;
    }

    if (idConcepto) {
      queryParams += `&idConcepto=${this.filtros.idConcepto}`;
    }

    return this.saldosKobraServ.put(`/asignar-periodo-movs${queryParams}`, periodoObj);
  }

  setMovimientoData(movimiento: CobradorMovimiento) {
    this.movimiento = movimiento;
  }

  obtenerCobradores(term: string) {
    let queryParam = '';

    if (Number(term)) {
      queryParam += `?idCobrador=${term}`;
    } else {
      queryParam += `?nombre=${term}`;
    }

    return this.saldosKobraServ.get(`/cobradores${queryParam}`)
      .pipe(
        catchError((error: any) => {
          this.app.showError(error);

          return of([]);
        })
      );
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

 

  obtenerAjuste6(): Observable<any> {
    return this.saldosKobraServ.get(`/cobradores/ajuste6`).pipe(map((res: any) => res.data));
  }

  aplicarAjuste6( data: Object ): Observable<any> {
    return this.saldosKobraServ.post(`/cobradores/ajuste6`, data).pipe(map((res: any) => res.data));
  }

  cancelarAjuste6( idCobradorAjusteNomina: number ): Observable<any> {
    return this.saldosKobraServ.delete(`/cobradores/ajuste6/${idCobradorAjusteNomina}`,).pipe(map((res: any) => res.data));
  }

  subirArchivo(uploadFile: File, idCobradorFacturacionPeriodo: number) {
    const formData = new FormData();
    formData.append('archivo', uploadFile);
    formData.append('idCobradorFacturacionPeriodo', idCobradorFacturacionPeriodo.toString());

    return this.saldosKobraServ.uploadFile(`/agentes/ajustes-masivos`, formData);

  }
}
