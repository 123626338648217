// loading.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading = new BehaviorSubject<boolean>(false);

  public setLoading(loading: boolean): void {
    this.loading.next(loading);
  }

  public getLoading() {
    return this.loading.asObservable();
  }
}
