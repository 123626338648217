import {Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';



@Component({
    selector: 'variables-dialog',
    templateUrl: './variables-dialog.component.html',
    styleUrls: ['./variables-dialog.component.css']
  })
  export class VariablesDialog {
    public cuenta: any[] = [];
    public cuentaDetalle: any[] = [];
    public visita: any[] = [];
    public checkLists: any[] = [];
    public generales: any[] = [];
    public condicionales: any[] = [];
    public loadVariables = false;

    public tablaCuenta: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    } 

    public tablaCuentaDetalle: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }
  
    public tablaVisita: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaCheckList: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaGenerales: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaCondicionales: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    constructor(
      public dialogRef: MatDialogRef<VariablesDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        //this.agentes = data;
    }

    ngOnInit() {

      this.obtienerVariable();
    }

    getVariableColumns() {
      return [
        {
          name: 'ID Variable',
          key: 'claveInterna',
          hide: true
        },
        {
          name: ' ',
          key: 'claveUsuario',
          type: 'copy',
          hide: false
        },
        {
          name: 'Variable',
          key: 'claveUsuario',
          hide: false
        },
        {
          name: 'Descripción',
          key: 'descripcion',
          hide: false
        }
      ];
    }

    onNoClick(): void {
      this.dialogRef.close(false);
    }

    salir(){
      this.dialogRef.close();
    }

    processVarsAfectadas() {
      // Verificamos que exista el objeto varsAfectadas y el tipoFormato sea igual a 1
      if (this.data.variablesAfectadas && this.data.tipoFormato === 1) {
        // Procesamos los bloques solo si existen
        if (this.data.variablesAfectadas.bloques && Array.isArray(this.data.variablesAfectadas.bloques)) {
          this.condicionales = this.data.variablesAfectadas.bloques;
        }
  
        // Procesamos el checklist solo si existe
        if (this.data.variablesAfectadas.checklist && Array.isArray(this.data.variablesAfectadas.checklist)) {
          this.checkLists = this.data.variablesAfectadas.checklist;
        }
      }
    }

    private obtienerVariable(): void {
      this.loadVariables = true;
      if(this.data.isVersioNamiento) {
        this.processVarsAfectadas(); 
      } else {
        this.data.variables.esquemas.forEach(subArray => {
                    
          if(subArray.nombre === 'cuenta'){
            subArray.vars.forEach(data => {
              this.cuenta = data;
            });
          }
  
          if(subArray.nombre === 'cuentaDetalle'){
            subArray.vars.forEach(data => {
              this.cuentaDetalle = data;
            });
          }
          //console.log(this.data.tipoFormato);
          if(this.data.tipoFormato == 1){
  
            if(subArray.nombre === 'visita'){
              subArray.vars.forEach(data => {
                this.visita = data;
              });
            }
    
            if(subArray.nombre === 'checkLists'){
              subArray.vars.forEach(data => {
                this.checkLists = data;
              });
            }
    
            if(subArray.nombre === 'condicionales'){
              subArray.vars.forEach(data => {
                this.condicionales = data;
              });
            }
            
          }
  
          if(subArray.nombre === 'generales'){
            subArray.vars.forEach(data => {
              this.generales = data;
            });
          }
          
        });
      }
      
   
      this.loadVariables = false;
    }

    onClick({ row, columnPressed }) {
     // console.log(row);
    }

  }
