<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="obtenerConceptosSaldos(filtros.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/conceptos/nuevo']" queryParamsHandling="preserve">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1"  (click)="onEdit(itemsSelected[0])">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="onDelete(itemsSelected[0])">
        <mat-icon color="warn" >delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
  
    <app-table
      [loading]="cargandoLista"
      [columns]="tabla.columns"
      [items]="conceptos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      [classContainer]="'tabla-completa'"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"
      (sortChange)="setSortField($event)"
      >

      <div t-header>  
        <mat-card *ngIf="ayuda" class="cardAyuda">
         
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              El módulo "Conceptos" es un componente importante del módulo general de "Saldos", desempeñando un papel fundamental en
              la gestión financiera tanto para clientes como para agentes.
              Este módulo permite a los usuarios crear y administrar conceptos que serán aplicados en cargos o abonos, una
              funcionalidad esencial para el manejo eficiente de las transacciones financieras.
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los conceptos,
              permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
              filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Los usuarios pueden añadir nuevos conceptos al sistema.
            </p>
            <p>
              <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta acción permite la actualización y modificación de
              los conceptos existentes, asegurando que la información sea precisa y esté actualizada.
            </p>
            <p>
              <mat-icon color="warn">delete_forever</mat-icon><b> Eliminar: </b> Ofrece la opción de eliminar conceptos del sistema.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel  [expanded]="true">
        <mat-expansion-panel-header>
          Generales
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Concepto</mat-label>
              <input matInput textSanitizer 
              [(ngModel)]="filtros.nombre"
              (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"
              placeholder="Capture el concepto">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.nombre" 
              (click)="filtros.nombre = null; this.contabilizadorDeFiltros('generales') ">clear</mat-icon>
              <mat-icon matSuffix>donut_large</mat-icon>
            </mat-form-field>
       
          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Tipo concepto</mat-label>
              <mat-select
                [(ngModel)]="filtros.idTipoConcepto"
                (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"
                placeholder="Seleccione..."
              >
                <ng-container *ngFor="let tipoConcepto of cSaldosServ.tiposConceptos | async; index as ti">
                  <mat-option [value]="tipoConcepto.idTipoConcepto">{{tipoConcepto.nombre}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.idTipoConcepto != null" 
                  (click)="filtros.idTipoConcepto = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales')">clear</mat-icon> 
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>
            
          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Estatus del concepto</mat-label>
              <mat-select
                [(ngModel)]="filtros.activo"
                (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"

                placeholder="Seleccione..."
              >
                <ng-container *ngFor="let stat of cSaldosServ.status; index as ti">
                  <mat-option [value]="stat.id">{{stat.nombre}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.activo != null" 
                  (click)="filtros.activo = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales') ">clear</mat-icon> 
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>
          
          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  <ol class="lista-filtros-buttons">
    <button
      style="width: 100%;"
      mat-stroked-button
      class="boton-accion-front"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filterModal(true);">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="filterModal(false); removerFiltros();">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>