import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { IUsuario, IUsuariosPagination } from './usuarios.interface';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service'
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario: IUsuario;
  loader: boolean;
  usuarios$: Observable<IUsuario[]>;

  public pagination: IUsuariosPagination;
  public filter = false;
  public pageSize = 50;
  public fromRowPage: number;
  public toRowPage: number;

  public status = [
    { id: 1, nombre: 'Todos' }, { id: 2, nombre: 'Activos' }, { id: 3, nombre: 'Inactivos' }
  ];
  public filtros = {
    nombre: null,
    apellidoPaterno: null,
    apellidoMaterno: null,
    idFinanciera: null,
    email: null,
    activo: 1,
    page: 1,
    filtered: false
  }

  constructor(
    private lServ: ServiciosKobraService,
    private app: ApplicationService,
    public filtrosService: FiltrosService
  ) {

  }

  limpiarFiltros(){
    this.filtros = {
      nombre: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      idFinanciera: null,
      email: null,
      activo: 1,
      page: 1,
      filtered: false
    }
  }

  getGrid(page: number) {
    this.loader = true;

    const campos = '"idUsuario","idAdministradorFinanciera", "financiera", "nombre", "apellidoPaterno", "apellidoMaterno", "email", "isSuperAdmin", "deleted", "idFinanciera", "notificacionVisita", "notificacionCitas", "notificacionSaldos","notificacionReporteRedeco", "idCliente", "idClienteAdministrador"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy="nombre"`;

    queryParams += this.filtros.nombre ? `&nombre=${this.filtros.nombre}` : '';
    queryParams += this.filtros.apellidoPaterno ? `&apellidoPaterno=${this.filtros.apellidoPaterno}` : '';
    queryParams += this.filtros.apellidoMaterno ? `&apellidoMaterno=${this.filtros.apellidoMaterno}` : '';
    queryParams += this.filtros.email ? `&email=${this.filtros.email}` : '';
    queryParams += this.filtros.idFinanciera ? `&idFinanciera=${this.filtros.idFinanciera}` : '';

    if (this.filtros.activo && this.filtros.activo !== 1) {
      queryParams += `&deleted=${this.filtros.activo === 2 ? false : true}`;
    }

    this.usuarios$ = this.lServ.get(`/administradores-financiera-grid${queryParams}`)
      .pipe(
        map((res: any) => {
          res.data.pageData = res.data.pageData.map((usuario: IUsuario) => {
            usuario.isSuperAdmin = +usuario.isSuperAdmin;
            usuario.deleted = +usuario.deleted;
            usuario.tipoPermiso = usuario.isAdmin ? 'Admin' : (usuario.isSuperAdmin ? 'Super Admin' : 'Usuario');
            usuario.estatus = usuario.deleted ? 'Inactivo' : 'Activo';
            usuario.nombreCompleto = `${usuario.nombre} ${usuario.apellidoPaterno || ''}${usuario.apellidoMaterno ? ' ' + usuario.apellidoMaterno : ''}`;
            usuario.notificacionVisita = +usuario.notificacionVisita;
            usuario.notificacionCitas = +usuario.notificacionCitas;
           usuario.notificacionSaldos = +usuario.notificacionSaldos;

            return usuario;
          });
          this.pagination = res.data;
          this.calcFromToRowPage();

          return this.pagination.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
    this.usuarios$.subscribe((res: any) => {
        this.loader = false;
        //this.pagination = res.data;
        //this.productos = ProductoNvo.mapArray(res.data.pageData);
        //this.calcFromToRowPage();
    }, (err: any) => {
      this.loader = false;
      this.app.showError(err);
    });
  }

  obetenr(idUsuario: number) {
    return this.lServ.get(`/administradores-financiera/${idUsuario}`);
  }

  agregar(usuario: IUsuario): Observable<any> {
    return this.lServ.post('/administradores-financiera', usuario);
  }

  editar(idUsuario: number, usuarioData: IUsuario) {
    return this.lServ.patch(`/administradores-financiera/${idUsuario}`, usuarioData);
  }

  eliminar(idUsuario: number) {
    return this.lServ.delete(`/administradores-financiera/${idUsuario}`);
  }

  setUsuarioData(usuario: IUsuario) {
    this.usuario = usuario;
  }


  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  saveParams(){
    this.filtrosService.guardarFiltros(this.filtros);
  }

  clearFilters(){
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
    this.filtros.activo = 1;
    this.filtros.page = 1;
    this.filtros.filtered = false;
    this.filtrosService.removerFiltros();
  }
}
