export class PercentageDeallocation {
  totalAssignedAccounts: number;
  totalExpiredAccounts: number;
  percentageDeallocation: string;

  public static map(data: any): PercentageDeallocation {
    let instance = new PercentageDeallocation();

    instance.totalAssignedAccounts = +data.totalCuentasApartadas || +data.totalCuentasAsignadas || 0;
    instance.totalExpiredAccounts = +data.totalCuentasVencidas;
    instance.percentageDeallocation = data.prcDesasignacion;
    return instance;
  }

}
