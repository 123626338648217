import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CobradorFacturacionPeriodo, CFPPagination } from './cobrador-facturacion-periodo.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CobradoresFacturacionesPeriodosService {

  public periodos$: Observable<CobradorFacturacionPeriodo[]>;
  public periodo: CobradorFacturacionPeriodo;

  public pagination: CFPPagination;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;
  public filter = false;

  public loader: boolean;

  public filtros = {
    nombre: null,
    page: 1
  }

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder
  ) {

  }

  obtenerPeriodos(queryParams: string) {
    this.loader = true;
    return this.saldosKobraServ.get(`/cobradores-facturaciones-periodos${queryParams}`);
  }

  searchPeriodos(term: string) {
    const query = `?nombre=${term}`;
    return this.saldosKobraServ.get(`/cobradores-facturaciones-periodos${query}`)
  }

  obtenerPeriodo(idPeriodo: number) {
    return this.saldosKobraServ.get(`/cobradores-facturaciones-periodos?idCobradorFacturacionPeriodo=${idPeriodo}`);
  }

  agregarPeriodo(periodo: CobradorFacturacionPeriodo): Observable<any> {
    return this.saldosKobraServ.post('/cobrador-periodo-facturacion', periodo);
  }

  actualizarPeriodo(idPeriodo: number, Periodo: CobradorFacturacionPeriodo) {
    return this.saldosKobraServ.put(`/cobrador-periodo-facturacion/${idPeriodo}`, Periodo);
  }

  eliminarPeriodo(idPeriodo: number) {
    return this.saldosKobraServ.delete(`/cobrador-periodo-facturacion/${idPeriodo}`);
  }

  setPeriodoData(periodo: CobradorFacturacionPeriodo) {
    this.periodo = periodo;
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  removerFiltros(){
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
  }

}
