import { Component, Input, OnChanges  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ElementRef } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { MatGridList } from '@angular/material/grid-list';

@Component({
  selector: 'mat-grid-list-responsive',
  styleUrls: ['./mat-grid-list-responsive.component.css'],
  template: '<ng-content></ng-content>',
})
export class MatGridListResponsiveComponent extends MatGridList implements OnChanges  {
  @Input() public colsXs: number;
  @Input() public colsSm: number;
  @Input() public colsMd: number;
  @Input() public colsLa: number;
  @Input() public colsXl: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private elementRef: ElementRef,
    dir: Directionality
  ){
    super(elementRef, dir);
    this.cols=1;
  }

  ngOnChanges() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall
      , Breakpoints.Small
      , Breakpoints.Medium
      , Breakpoints.Large
      , Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.cols = this.colsXs !== null && this.colsXs !== undefined ? this.colsXs :
          this.colsSm !== null && this.colsSm !== undefined ? this.colsSm :
          this.colsMd !== null && this.colsMd !== undefined ? this.colsMd :
          this.colsLa !== null && this.colsLa !== undefined ? this.colsLa :
          this.colsXl !== null && this.colsXl !== undefined ? this.colsXl :
          this.cols;
      }
      if (result.breakpoints[Breakpoints.Small]) {
        this.cols = this.colsSm !== null && this.colsSm !== undefined ? this.colsSm :
          this.colsXs !== null && this.colsXs !== undefined ? this.colsXs :
          this.colsMd !== null && this.colsMd !== undefined ? this.colsMd :
          this.colsLa !== null && this.colsLa !== undefined ? this.colsLa :
          this.colsXl !== null && this.colsXl !== undefined ? this.colsXl :
          this.cols;
      }
      if (result.breakpoints[Breakpoints.Medium]) {
        this.cols = this.colsMd !== null && this.colsMd !== undefined ? this.colsMd :
          this.colsSm !== null && this.colsSm !== undefined ? this.colsSm :
          this.colsXs !== null && this.colsXs !== undefined ? this.colsXs :
          this.colsLa !== null && this.colsLa !== undefined ? this.colsLa :
          this.colsXl !== null && this.colsXl !== undefined ? this.colsXl :
          this.cols;
      }
      if (result.breakpoints[Breakpoints.Large]) {
        this.cols = this.colsLa !== null && this.colsLa !== undefined ? this.colsLa :
          this.colsXl !== null && this.colsXl !== undefined ? this.colsXl :
          this.colsMd !== null && this.colsMd !== undefined ? this.colsMd :
          this.colsSm !== null && this.colsSm !== undefined ? this.colsSm :
          this.colsXs !== null && this.colsXs !== undefined ? this.colsXs :
          this.cols;
      }
      if (result.breakpoints[Breakpoints.XLarge]) {
        this.cols = this.colsXl !== null && this.colsXl !== undefined ? this.colsXl :
          this.colsLa !== null && this.colsLa !== undefined ? this.colsLa :
          this.colsMd !== null && this.colsMd !== undefined ? this.colsMd :
          this.colsSm !== null && this.colsSm !== undefined ? this.colsSm :
          this.colsXs !== null && this.colsXs !== undefined ? this.colsXs :
          this.cols;
      }
    });
    
  }
}
