import { Visit } from "./visit.model";
import { Pagination } from "../pagination/pagination.model";

export class VisitPaginator {
  pagination: Pagination;
  data: Visit[];

  constructor() {
    this.pagination = new Pagination();
  }
}