import { ProductosFinanciera } from "./productos-financiera.model";

export class AgenteProductosFinanciera{
    nombreFinanciera: String;
    productos: ProductosFinanciera[] = [];
    idCliente: number;
    fechaRegistroCliente:Date;

    public static mapArrayData(data: any[]): AgenteProductosFinanciera[] {
        try {
            let apf: AgenteProductosFinanciera[] = [];
            let productos: ProductosFinanciera[] = [];

            let financiera = data[0].nombreFinanciera;
            let idFinActual = data[0].idFinanciera;
            let i = 0;
            let idCliente = data[0].idCliente;
            let fechaRegistroCliente = data[0].fechaRegistroCliente
            
            for (i = 0; i < data.length-1; i++) {
                if(data[i].idProducto != null){
                    productos.push(ProductosFinanciera.map(data[i]));
                }
                
                if(idFinActual != data[i+1].idFinanciera){
                    apf.push(AgenteProductosFinanciera.map(productos, financiera, idCliente, fechaRegistroCliente));
                    productos = [];
                    idFinActual = data[i+1].idFinanciera;
                    financiera = data[i+1].nombreFinanciera;
                }
            }
            productos.push(ProductosFinanciera.map(data[i]));
            apf.push(AgenteProductosFinanciera.map(productos, financiera, idCliente, fechaRegistroCliente));
        
            return apf;
        } catch (error) {
            return null;
        }
    }
    
    public static map(productos: ProductosFinanciera[], financiera: String, idCliente: number, fechaRegistroCliente: Date): AgenteProductosFinanciera {
        let instancia = new AgenteProductosFinanciera();
        
        instancia.nombreFinanciera = financiera;
        instancia.idCliente = idCliente;
        instancia.productos = productos;
        instancia.fechaRegistroCliente = fechaRegistroCliente;

        return instancia;
    }
}