import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LoginService {

  constructor(
    private router: Router,
    private leanServ: ServiciosKobraService
  ) { }

  reloadBaseUrl(archivo: string = ""){
    this.leanServ.reloadBaseUrl(archivo);
  }

  logIn(token, cc = ""): Observable<any> {
    let params = { 
      token: token,
      cc: cc ? cc : null
    };
    return this.leanServ.post('/login', params).pipe(map(res => res));
  }

  logInDashboard(token): Observable<any> {
    return this.leanServ.post('/login-dashboard', { token }).pipe(map(res => res));
  }
}
