import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaquetesFinancieraService } from './paquetes-financiera.service';
import { PaquetesFinanciera , PaquetesPagination} from './paquetes-financiera.interface';
import { LenderService } from '../../lender/lender.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'app-paquetes-financiera',
  templateUrl: './paquetes-financiera.component.html',
  styleUrls: ['./paquetes-financiera.component.css'],
  providers: [
    LenderService
  ]
})
export class PaquetesFinancieraComponent implements OnInit, OnDestroy {
  sessionData: SessionData;
  private querySub: Subscription;
  public idLender: any = null;
  public lenders: any[] = [];
  tabla: any;
  paquetes: any;
  cargandoLista: boolean = false;
  ayuda: boolean = false;
  hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;
  sortField: string = 'nombreFinanciera';
  sortAsc: string = '';
  public pagination: PaquetesPagination;
  public pageSize = 50;
  public itemsSelected: any[] = [];

  constructor(
    public pqtFinancieraServ: PaquetesFinancieraService,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private app: ApplicationService,
    private route: ActivatedRoute) {
      this.paquetes = [];
      this.crearTabla();
    }

  ngOnInit() {
    this.sessionData = this.localStorageService.getSessionData();
    this.readQueryParams(null);
    this.loadLenders();
  }

  ngOnDestroy() {
    this.querySub.unsubscribe();
  }

  crearTabla() {
    this.tabla = {
        headers: false,
        multiSelect: false,
        columns: [
          {
              name: 'Cliente',
              key: 'nombreFinanciera',
              hide: false
          },
          {
              name: 'Paquete Actual',
              key: 'nombrePaquete',
              hide: false
          },
          {
              name: 'Precio Paquete',
              key: 'precioPaquete',
              hide: false,
              type: 'money'
          },
          {
              name: 'Saldo Paquete',
              key: 'saldoPaquete',
              hide: false,
              type: 'money'
          },
          {
            name: 'Saldo General',
            key: 'saldoFinanciera',
            hide: false,
            type: 'money'
          },{
            name: 'Estatus',
            key: 'estatus',
            hide: false,
            type: 'estatus'
          }
        ]
    }
  }

  readQueryParams(currentPage: number) {
    this.querySub = this.route.queryParams.subscribe((params: Params) => {
      let page: number = params['_page'] || 1;

      if( currentPage != null ) {
        page = currentPage;
      }

      this.getSaldosPaquetesFinancieras(page);
    });
  }

  queryParamFiltros(page: number, descarga: boolean = false) {

    const campos = '"idFinanciera","nombreFinanciera","nombrePaquete","precioPaquete","saldoPaquete", "saldoFinanciera","activa"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.sortField}${this.sortAsc ? ' ' + this.sortAsc : ''}`;
    if(this.idLender){
      queryParams+= `&idFinanciera=${this.idLender}`;
    }

    return queryParams;
  }

  setSortField(sort: any){
    this.sortAsc = sort.direction;
    if (sort.active != this.sortField) {
      this.sortField = sort.active;
    }
    this.getSaldosPaquetesFinancieras(1);
  }

  public getSaldosPaquetesFinancieras(page: number) {
    this.cargandoLista = true;
    const queryParams = this.queryParamFiltros(page);
    this.pqtFinancieraServ.getSaldosPaquetesFinancieras(queryParams).subscribe(
      (res: any) => {
        if(res.success){
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos, 
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          for (let i = 0; i < datos.length; i++) {
            datos[i].estatus = datos[i].activa ? 'Activa' : 'Inactiva';
          } 

          this.paquetes = datos;
        }
        this.cargandoLista = false;
        
      },(error) => {
            this.app.showError(error);
            this.cargandoLista = false;
        },
    );
  }


  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
        this.getSaldosPaquetesFinancieras(newPagina.pageIndex+1);
    }
  }

  setQueryParam(page: number) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          _page: page
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  onDetalle(paquete: PaquetesFinanciera) {
    this.pqtFinancieraServ.setPaqueteData(paquete);
    this.router.navigate(['paquetes-cliente', paquete.idFinanciera], {
        queryParamsHandling: 'preserve'
      }
    );
  }

  public loadLenders(): void {
    let loadingFinancieras = true;

    this.lenderService.getAll('all').subscribe(
      res => {
        this.lenders = res;
        loadingFinancieras = false;
      },
      err => {
        this.pqtFinancieraServ.appService.showError(err);
      },
      () => loadingFinancieras = false
    );
  }

  public onChangeLender(event){
    this.readQueryParams(1);
  }

  filterModal(filtro: boolean) {
    if (filtro) {
      this.getSaldosPaquetesFinancieras(1);
    }else {
      this.idLender = null;
      //this.getSaldosPaquetesFinancieras(1);
    }
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  onDoubleClick({ row }) {
    this.onDetalle(row);
  }

  inactivarHijo(){
    this.getSaldosPaquetesFinancieras(1);
  }

}
