import { Financiera } from './../../../financieras/shared/financiera.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/operators';
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductosService } from '../productos.service';
import { ChangeDetectorRef } from '@angular/core';
import { ProductoNvo } from '../../productos/productos-nvo.model';

@Component({
  selector: 'app-productos-modificar',
  templateUrl: './productos-modificar.component.html',
  styleUrls: ['./productos-modificar.component.css'],
  providers: [LenderService]
})
export class ProductosModificarComponent implements OnInit {
  sessionData: SessionData;
  title: string = '';
  producto: ProductoNvo;
  administradores: any = [];
  adminCliente: any = null;
  accion: string = 'Detalle';
  financieras: any = null;
  public cambioData = false;
  public permisos:any = [];
  cargando: boolean = false;

  loadings: any = {
    productos: true,
    financieras: true,
    administradoresClientes: true,
    administradores: true
  }

  requireds: any = {
    financiera: false,
    nombre: false
  }

  tablaAdmin: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columns: [
        {
            name: 'Nombre',
            key: 'nombre',
            hide: false
        }, {
            name: 'Primer Apellido',
            key: 'apellidoPaterno',
            hide: false
        }, {
            name: 'Segundo Apellido',
            key: 'apellidoMaterno',
            hide: false
        }, {
            name: 'Email',
            key: 'email',
            hide: false
        }, {
          name: ' ',
          key: 'eliminar',
          hide: false,
          icono:'delete_forever',
          tooltip: "Eliminar",
          type: 'icono',
      }
    ]
  }
  editarPresente: boolean = false;
  idProductoParams:number;
  nombreProducto:string;
  nombreCliente: string;
  idCliente: number;

  constructor(
    private appService: ApplicationService,
    private lenderService: LenderService,
    private productosService:ProductosService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    
      this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
      this.idProductoParams = +this.route.snapshot.queryParamMap.get('idProducto');
      this.nombreProducto = this.route.snapshot.queryParamMap.get('nombreProducto');
      this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
      if (!this.idCliente || !this.idProductoParams || !this.nombreProducto || !this.nombreCliente) {
        this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
        this.router.navigate([`catalogos/permisos-producto`]);
      }else{
        this.title = 'Editar';
        this.loadProducto();
        
      }
    
    /* this.producto = JSON.parse(localStorage.getItem('procutoNuevoTemp'));
     this.productoNvo = Object.assign({}, this.producto);*/
  }

  private loadProducto(){
    this.loadings.productos = true;

    this.productosService.obtenerProducto(this.idCliente, this.idProductoParams)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.productos = false;
        this.producto = ProductoNvo.map(res.data.producto);
        this.loadAdministradoresProductos();
        this.loadAdministradoresClientes();
    }, (err: any) => {
        this.loadings.productos = false;
        this.app.showError(err);
    });
  }

  validaRequeridos(){
    let requridos = false;
    if(!this.producto.idFinanciera){
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar financiera.', 3000, 'warning');
      this.requireds.financiera = true;
      requridos = true;
    }

    if(!this.producto.nombre || this.producto.nombre == ''){
      if(!requridos)
        this.appService.showSnackbar('¡Aviso!', 'Es necesario capturar el nombre de la sucursal.', 3000, 'warning');
      this.requireds.nombre = true;
      requridos = true
    }
    return requridos;
  }

  cancel() {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];
    this.router.navigate(['catalogos/permisos-producto'], {
      queryParams: { f: fParam }
    });
  }

  save() {
    const loading = this.appService.showLoading(
      `Guardando información...`
    );

      this.productosService.guardarProductos(this.producto.idProducto,this.producto.idCliente,this.producto.administradores).subscribe(() => {
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('Aviso', 'Se ha actualizado el producto correctamente', 3000, 'success');
        this.cancel();
      }, error => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      });

  }

  agregarAdministrador() {
    this.cargando = true;
    if (this.adminCliente) {
      const adminExists = this.producto.administradores.some(
        admin => admin.idClienteAdministrador === this.adminCliente.idClienteAdministrador
      );

      if (adminExists) {
        this.appService.showSnackbar(
          '¡Aviso!',
          'El administrador seleccionado ya pertenece a este producto.',
          3000,
          'warning'
        );
      } else {
        this.producto.administradores = [...this.producto.administradores, this.adminCliente];
        this.cambioData = true;
        this.adminCliente = null;
        //this.changeDetectorRef.detectChanges();
      }
    } else {
      this.appService.showSnackbar(
        '¡Aviso!',
        'Es necesario seleccionar un administrador para agregarlo.',
        3000,
        'warning'
      );
    }
    this.cargando = false;
}

  onClick({ row, columnPressed }){
    if(columnPressed == 'eliminar'){
      this.borrarAdministrador(row);
    }
  }

  borrarAdministrador(data: any){
    this.producto.administradores = this.producto.administradores.filter((item) => item.idClienteAdministrador !== data.idClienteAdministrador);
    this.cambioData = true;
  }

  loadAdministradoresProductos(){
    this.loadings.administradores = true;

    if(this.producto.idCliente && this.producto.idProducto){
      this.productosService.obtenerAdministradoresProductos(this.producto.idCliente, this.producto.idProducto)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.producto.administradores = res.data;
        this.loadings.administradores = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loadings.administradores = false;
      });
    }
    else{
      this.loadings.administradores = false;
    }
  }

  loadAdministradoresClientes(){
    this.loadings.administradoresClientes = true;
    let query: string = '';
    let idCliente: number;

    if(!this.sessionData.isSuperAdmin) {
      idCliente =  this.sessionData.idCliente;
    }else {
      idCliente =  this.producto.idCliente;
    }

    if(idCliente){
      this.productosService.obtenerAdministradoresCliente(idCliente)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.administradores = res.data;

        this.loadings.administradoresClientes = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loadings.administradoresClientes = false;
      });
    }
    else{
      this.loadings.administradoresClientes = false;
    }
  }

  public loadLenders(): void {
    this.loadings.financieras = true;

    this.lenderService.getAll('all').subscribe(
        res => {
            this.financieras = res;
            if(!this.sessionData.isSuperAdmin){
              this.producto.idCliente = this.sessionData.idCliente;
            }
        },
        err => {
            this.app.showError(err);
            this.loadings.financieras = false;
        },
        () => this.loadings.financieras = false
    );
  }

}
