export class Lender {
  id: number;
  idCliente: number;
  idFinanciera:any;
  name: string;
  nombre: string;
  razonSocial: string;
  email: string;
  sitioWeb: string;
  nombreEncargado: string;
  financieraDemo:boolean;

  public static map(data: any): Lender {
    let instance: Lender = new Lender();
    instance.id = data.id || null;
    instance.idCliente = data.idCliente || null;
    instance.idFinanciera = data.idFinanciera || null;
    instance.name = data.name || null;
    instance.nombre = data.nombre || null;
    instance.razonSocial = data.razonSocial || null;
    instance.email = data.email || null;
    instance.sitioWeb = data.sitioWeb || null;
    instance.nombreEncargado = data.nombreEncargado || null;
    instance.financieraDemo = data.financieraDemo || false;
    return instance;
  }

  public static mapArray(data: any[]): Lender[]{
    let instance:Lender[] = [];
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        instance.push(Lender.map(element));
    }
    return instance;
  }
}
