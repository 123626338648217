<h1 mat-dialog-title>
  <!-- <div class="icono" *ngIf="data.icono">
    <mat-icon color={{data.colorIcono}}>{{data.icono}}</mat-icon>
  </div> -->
  {{data.titulo}}
</h1>
<mat-dialog-content>
  <div>
    <p class="texto pregunta" [innerHTML]="data.mensaje"></p>
    <div *ngIf="data.texto" class="margen">
      <p class="texto" [innerHTML]="data.texto"></p>
    </div>
  </div>    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>{{data.boton1}}</button>
  <button mat-stroked-button class={{data.claseAccion}} (click)="Aceptar()"><mat-icon>{{data.icono}}</mat-icon>{{data.boton2}}</button>
</mat-dialog-actions>
