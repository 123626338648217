import { ChecklistRespuesta } from "./checklist-respuesta.model";

export class ChecklistPregunta {
  idChecklistPregunta: number;
  idChecklistPreguntaPadre: number;
  idChecklistPreguntaCondicion: number;
  orden: number;
  nombrePregunta: String;
  llaveCliente: any;
  respuestas: ChecklistRespuesta[];
  editando: boolean;
  seleccionada: boolean;
  condicionMostrar: String;
  visible: boolean;
  margen: number;

  constructor() {
    //this.preguntasHijas = [];
    this.respuestas = [];
  }

  public static map(data: any): ChecklistPregunta {
    let instance: ChecklistPregunta = new ChecklistPregunta();
    instance.idChecklistPregunta = data.idChecklistPregunta || null;
    instance.idChecklistPreguntaPadre = data.idChecklistPreguntaPadre || null;
    instance.idChecklistPreguntaCondicion = data.idChecklistPreguntaCondicion || null;
    instance.orden = data.orden || null;
    instance.nombrePregunta = data.nombrePregunta || null;
    instance.llaveCliente = data.llaveCliente || null;
    //instance.preguntasHijas = data.preguntasHijas ? ChecklistPregunta.mapArray(data.preguntasHijas) : [];
    instance.respuestas = data.respuestas ? ChecklistRespuesta.mapArray(data.respuestas) : [];
    instance.editando = false;
    instance.seleccionada = data.seleccionada || false;
    instance.condicionMostrar = data.condicionMostrar || null;
    instance.visible = data.visible;
    instance.margen = 0;

    return instance;
  }

  public static mapArray(data: any[]): ChecklistPregunta[] {
    let instances: ChecklistPregunta[] = [];
    for (let i = 0; i < data.length; i++) {
      data[i].idChecklistPreguntaCondicion =  data[i].idChecklistPreguntaCondicion || (i+1);
      data[i].orden = i+1;
      data[i].idChecklistPreguntaPadre = data[i].idChecklistPreguntaCondicion ? data[i].idChecklistPreguntaPadre : null;
      data[i].visible = data[i].idChecklistPreguntaCondicion && data[i].idChecklistPreguntaPadre ? false : true;
      instances.push(ChecklistPregunta.map(data[i]));
    }
    return instances;
  }
}
