<app-modal [title]="'Asignar visibilidad de cuentas'" [custom]="true">
  <div>
    <div class="kobra three columns">
      <div class="kobra column two-colspan">
        <mat-select-search
            class="id-card-field"
            [label]="'Agente'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="asignarAgenteFilterSearch"
            [(ngModel)]="agente"
            [minBusqueda]="1"
            (ngModelChange)="onAsignarAgenteFilterSelected($event);"
          >
        </mat-select-search>
        <!--label for="">Agente</label>
        <sui-search (keydown.enter)="$event.preventDefault()" placeholder="Busca por ID o nombre"
            [optionsLookup]="asignarAgenteFilterSearch" optionsField="nombre" searchDelay="500"
            maxResults="10" (resultSelected)="onAsignarAgenteFilterSelected($event)"
            #asignarAgenteFilterInput style="width: 420px;" >
        </sui-search-->
      </div>
      <div class="kobra column">
        <button mat-stroked-button class="botonAgregar boton-accion-front" (click)="agregarAgente()" >
          <mat-icon>person_add</mat-icon>
          Agregar
        </button>
      </div>
    </div>
    
  </div>
  <div content [ngClass]="{'contenido':agentes.length > 3
  , 'contenidoSinScroll' : agentes.length <= 3}">
    <div *ngFor="let agente of agentes; let i = index;">
      <mat-card class="card">
        <!--mat-card-content-->
          <div class="kobra three columns">
            <div class="kobra column two-colspan">
              <span class="spanText">{{agente.nombreCompleto}}</span>
            </div>
            <div class="kobra column">
              <a (click)="eliminarAgente(agente)" class="icono">    
                <mat-icon color="warn">delete</mat-icon>    
              </a>  
            </div>
          </div>
        <!--/mat-card-content-->
        
      </mat-card>
    </div>
  </div>

  <div class="action-buttons-modal" m-actions>
    <button mat-flat-button class="boton-cancelar" (click)="onNoClick()">
      <mat-icon class="kobra-icon">close</mat-icon>Cerrar</button>
    <button mat-stroked-button class="boton-accion-front" *ngIf="agentes.length > 0" (click)="limpiarAgente()" >
      <mat-icon>delete_forever</mat-icon>Limpiar</button>
    <button mat-stroked-button class="botonAsignar boton-accion-guardar" *ngIf="agentes.length > 0" (click)="asignar()" >
      <mat-icon>save</mat-icon>
      Asignar</button>
  </div>
</app-modal>