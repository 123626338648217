import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { Tarifa } from "./tarifa-conceptos.model";
import { ServiciosSaldosKobraService } from "../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service";
import { map } from 'rxjs/operators';

@Injectable()
export class TarifaConceptosService {
  private serviciosSaldosURL: string = '/tarifas';

  constructor(
    private serviciosSaldosKobra: ServiciosSaldosKobraService,
  ) { }

  public obtenerPaqueteVisitasPrecios(idConcepto: number): Observable<Tarifa[]> {
    return this.serviciosSaldosKobra.get(this.serviciosSaldosURL + '/tarifas-cobradores/'+idConcepto).pipe(
      map((res) => <Tarifa[]> res.data)
    )
  }
}
