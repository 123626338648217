import { Request } from "./request.model";
import { Pagination } from "../pagination/pagination.model";

export class RequestPaginator {
  pagination: Pagination;
  data: Request[];

  constructor() {
    this.pagination = new Pagination();
  }
}