<h1 mat-dialog-title>Estatus</h1>
<mat-dialog-content>
  <div class="container">
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Estatus</mat-label>
        <mat-select [(ngModel)]="selectedStatus">
            <mat-option *ngFor="let agentStatus of data.agentsStatus" [value]="agentStatus.idCobradorEstatus" >
              {{agentStatus.nombre}}
            </mat-option>
        </mat-select >
        <mat-icon matSuffix  class="icono-limpiar-solo"
                  *ngIf="selectedStatus" 
                  (click)="selectedStatus = null;">clear</mat-icon> 
      </mat-form-field>
      <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedStatus">
        <a (click)="selectedStatus = null"><i>Limpiar selección</i></a>
      </div> -->
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button  class="boton-guardar" (click)="actualizaEstatus()"><mat-icon style="color:white !important">sync</mat-icon>Actualizar</button>
</mat-dialog-actions>


