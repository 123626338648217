
export class PromisePayment {
    id: number;
    idCuenta: number;
    folioVisita: string;
    idCliente: number;
    idFinanciera: number;
    nombreCliente: string;
    idExterno: number;
    nombreAcreditado: string;
    nombreCompletoAcreditado: string;
    idCobrador: number;
    nombreCobrador: string;
    nombreCompletoCobrador: string;
    monto: number;
    fechaPagar: Date;
    comentario: string;
    metodoPago: string;
    estatusPromesa: string;
    fechaRegistro: Date;
    municipio: string;
    estado: string;
    subIdExterno:string;
    folioDomicilio:string;

    constructor() {
    }

    public static map(data: any): PromisePayment {
        let instance: PromisePayment = new PromisePayment();
        instance.id = data.idExternoCliente;
        instance.idCuenta = data.idCuenta;
        instance.idFinanciera = data.idFinanciera;
        instance.idCliente = data.idCliente;
        instance.nombreCliente = data.nombreCliente;
        instance.folioVisita = data.folioVisita;
        instance.idExterno = data.idExterno;
        instance.nombreCompletoAcreditado = `${data.nombreAcreditado ? data.nombreAcreditado : ''} ${data.apePatAcreditado ? data.apePatAcreditado : ''} ${data.apeMatAcreditado ? data.apeMatAcreditado : ''}`;
        instance.idCobrador = data.idCobrador;
        instance.nombreCobrador = data.nombreAgente;
        instance.nombreCompletoCobrador = `${data.nombreAgente ? data.nombreAgente : ''} ${data.apePatAgente ? data.apePatAgente : ''} ${data.apeMatAgente ? data.apeMatAgente : ''}`;
        instance.monto = data.montoPagoPromesa;
        instance.fechaPagar = data.fechaPagoPromesa;
        instance.comentario = data.comentarios;
        instance.metodoPago = data.metodoPagoPromesa;
        instance.estatusPromesa = data.nombrePromesaPagoEstatus;
        instance.fechaRegistro = data.fecVisitaRegistro;
        instance.municipio = data.municipio;
        instance.estado = data.estado;
        instance.subIdExterno = data.subIdExterno;
        instance.folioDomicilio = data.folioDomicilio;
        return instance;
    }

    public static mapArray(data: any[]): PromisePayment[] {
        let instances: PromisePayment[] = [];
        for (let i = 0; i < data.length; i++) {
            instances.push(PromisePayment.map(data[i]));
        }
        return instances;
    }
}
