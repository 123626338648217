import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VisitPaginator } from '../visit/visit.paginator';
import { VisitFilter } from '../visit/visit-filter.model';
import { Lender } from '../lender/lender.model';
import { Visit } from '../visit/visit.model';
import { CalificarObligatoriosService } from './calificar-obligatorios.service';
import { Observable, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { ApplicationService } from '../application/shared/application.service';
import { Account } from '../account/account.model';
import { TakenAccount } from '../taken-account/taken-account.model';
import { GeneralChecklist } from '../general-checklist/general-checklist.model';
import { LenderService } from '@lender/lender.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environmentSelector } from '../../environments/environment.selector';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { AgentService } from '@agent/agent.service';
import { ModalVisitaDetalleComponent } from "../visit/detalle-dialog/visit-detail-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { ModalVisitaDetalleMasivaComponent } from '../visit/detalle-masiva-dialog/detalle-masiva-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


declare var $: any;

@Component({
  selector: 'app-calificar-obligatorios',
  templateUrl: './calificar-obligatorios.component.html',
  styleUrls: ['./calificar-obligatorios.component.css'],
  providers: [
    LenderService,
    CalificarObligatoriosService,
    AgentService
  ]
})
export class CalificarObligatoriosComponent implements OnInit {
  private environment = environmentSelector();

  public tablaVisitas: any = [];
  today = new Date();
  public showCalifButton = false;
  public showCalifButtonMasiva = false;
  public visitPaginator: VisitPaginator;
  public visitFilter: VisitFilter;
  public sortField: string;
  public sortAsc: string = '';
  public isAllSelected = false;
  public listFiltered: boolean;
  public lenders: Lender[];
  public visits: Visit[];
  public pageSize = 50;
  ayuda = false;

  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  sessionData: SessionData;
  itemsSelected: Array<any>[] = [];
  private slt: string = '"idVisita","folioVisita", "tipoProducto", "inicioVisita", "finVisita", "idCuenta", "idSubcuenta", "idAcreditado", "idExterno", "nombreAcreditado", "apellidoPaternoAcreditado",' +
    ' "apellidoMaternoAcreditado", "nombreCliente", "creacionCuenta", "idCobrador","idAgente", "nombreCobrador", "comentarios", "promesaPago", "mock", "repunteo",' +
    ' "agenteInterno", "idCliente", "cancelada", "municipio", "estado", "folioDomicilio", "idFinanciera", "subIdExterno"';

  public labelEstado: string;
  public labelMunicipio: string;
  public visitasACalificar: Visit[];
  public botonCM: boolean = false;
  public lenders$: Observable<any>;
  public currentPage: number;
  listFields: any;
  filtrosAbiertos = false;
  idFinanciera: number;
  hijosActivos: boolean = false;

  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;


  public filtros = {
    VisitID: null,
    PaymentPromise: null,
    Lender: null,
    VisitStartDate: null,
    VisitEndDate: null,
    AgentID: null,
    CobradorNombre: null,
    CobradorSegundoNombre: null,
    ApellidoPaternoCobrador: null,
    ApellidoMaternoCobrador: null,
    IDExterno: null,
    NombreAcreditado: null,
    ApellidoPaternoAcreditado: null,
    ApellidoMaternoAcreditado: null,
    Estado: null,
    Municipio: null,
    Ciudad: null,
    page: 1,
    financiera: null,
    idAcreditado: null,
    idAgente: null,
    acreditado: null,
    agente: null
  }
  loadingVisitas = false;

  constructor(
    private lenderServ: LenderService,
    private rootElement: ElementRef,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private router: ActivatedRoute,
    public califServ: CalificarObligatoriosService,
    private routeNavigate: Router,
    private localStorageService: LocalStorageService,
    private agenteServ: AgentService,
    private dialog: MatDialog,


  ) {

    this.visitFilter = new VisitFilter();
    this.visitPaginator = new VisitPaginator();
    this.visits = [];
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelEstado = this.environment.labelEstado;
    this.crearTabla();
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();

  }

  ngOnInit() {
    this.sortField = 'v.folio';
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.respuestaFiltros.bind(this));

  }

  private agregarContabilizadoresDeFiltros(){
    
    this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
        'financiera'
    ]);
  
   
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'VisitID',
        'PaymentPromise'
    ]);

    this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
        'acreditado'
    ]);


    this.contabilizadorFiltros.add('Ubicación', 'ubicacion', [
        'Estado',
        'Municipio'
    ]);

    this.contabilizadorFiltros.add('Fechas', 'fechas', [
        'VisitStartDate',
        'VisitEndDate'
    ]);
    this.contabilizadorFiltros.add('Agente', 'agente', [
        'agente'
    ]);
  }

  public contabilizadorDeFiltrosTotales():void{
          
    let totalFiltrosArray: number[] = [];
    if(this.sessionData.isSuperAdmin){
        totalFiltrosArray.push( this.contabilizadorFiltro('superAdmin'));
    }
    totalFiltrosArray.push(  this.contabilizadorFiltro('generales'));
    totalFiltrosArray.push(  this.contabilizadorFiltro('cuenta'));
    totalFiltrosArray.push(  this.contabilizadorFiltro('ubicacion'));
    totalFiltrosArray.push(  this.contabilizadorFiltro('fechas'));
    totalFiltrosArray.push(  this.contabilizadorFiltro('agente'));


    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public contabilizadorFiltro(filtroName: string){
   return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  respuestaFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
      this.listFiltered = true;
      this.filtros = filtrosNuevos;
    }
    else {
      this.inicializaFechas();
    }

    if (this.filtros.financiera) {
      this.idFinanciera = this.filtros.financiera.idFinanciera;
    }
    this.loadLenders();

    this.visitPaginator.pagination.currentPage = this.filtros.page || 1;

    this.loadVisits(this.visitPaginator.pagination.currentPage);
  }

  inicializaFechas() {
    this.filtros.VisitStartDate = new Date();
    this.filtros.VisitStartDate.setDate(this.filtros.VisitStartDate.getDate() - 10);
    this.filtros.VisitEndDate = new Date();

    this.contabilizadorDeFiltrosTotales();

  }

  crearTabla() {
    this.tablaVisitas = {
      headers: false,
      multiSelect: true,
      columns: [
        {
          name: 'Cliente',
          key: 'lender.name',
          hide: false
        },
        {
          name: 'Folio Visita',
          key: 'folio',
          hide: false,
          type: 'link',
          sortName: 'v.folio',
          link: '/calificar-obligatorios/visit/',
        },
        {
          name: 'ID Cuenta',
          key: 'client.externalID',
          hide: false,
          type: 'link',
          sortName: 'a.id_externo_kobra'
        },
        {
          name: 'ID Subcuenta',
          key: 'idSubcuenta',
          hide: false,
          sortName: 'ac.sub_id_externo_cliente'
        }, {
          name: 'Folio Domicilio',
          key: 'clientAddress.folioDomicilio',
          hide: false,
          sortName: 'acd.folio_domicilio'
        }, {
          name: 'Nombre Cuenta',
          key: 'client.fullName',
          hide: false
        }, {
          name: 'Producto',
          key: 'account.product.name',
          hide: false,
          sortName: 'p.nombre'
        }, {
          name: 'ID Agente',
          key: 'agent.id',
          hide: false,
          sortName: 'ag.id_externo_kobra'
        }, {
          name: 'Agente',
          key: 'agent.fullName',
          hide: false,
          sortName: 'ag.nombreCompleto'
        }, {
          name: 'Fecha Visita',
          key: 'createdAt',
          hide: false,
          type: 'dateTime',
          sortName: 'v.fec_registro'
        }, {
          name: this.labelMunicipio,
          key: 'clientAddress.municipality',
          hide: false,
          sortName: 'COALESCE( m.nombre, acd.municipio )'
        }, {
          name: this.labelEstado,
          key: 'clientAddress.state',
          hide: false,
          sortName: 'COALESCE( e.nombre, acd.estado )'
        }, {
          name: 'Promesa de Pago',
          key: 'generalChecklist.debtCollectionChecklist.paymentPromise',
          hide: false,
          type: 'boolean'
        }
      ]
    }
  }

  activarHijo(){
    this.hijosActivos = true;
  }

  desactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.financiera){
        this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.respuestaFiltros.bind(this));
  }

  loadVisits(page?: any): void {
    this.loadingVisitas = true;
    this.listFiltered = true;
    if (!this.sortField) {
      this.sortField = 'v.folio';
    }

    let queryParams: string = '&rows=' + this.pageSize;
    queryParams += this.crearURLQuery();

    this.visitPaginator.pagination.nextPage = page || 1;
    this.currentPage = this.visitPaginator.pagination.nextPage;

    if (page != this.filtros.page) {
      this.filtros.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }

    queryParams += '&page=' + page;
    //this.califServ.callLoader(true, 'Cargando visitas...');
    this.califServ.getVisits(this.visitPaginator.pagination, queryParams, this.pageSize)
      .pipe(
        take(1)
      ).subscribe((paginator: VisitPaginator) => {
        this.visitPaginator = paginator;
        const visits: Visit[] = paginator.data;
        this.initNulls(visits);
        this.visits = visits;
        this.loadingVisitas = false;
      }, (err: any) => {
        this.loadingVisitas = false;
        this.app.showError(err);
      });
  }

  showFilter(): void {
    //this.loadLenders();
  }

  descargarListado(): void {
  }

  clearFilter(): void {
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
    this.idFinanciera = null;
    this.inicializaFechas();
    this.filtros.page = 1;
    this.filtrosService.removerFiltros();
    this.contabilizadorDeFiltrosTotales();
  }

  public setFilter(): void {
    const obligatorios = ['VisitEndDate','VisitStartDate' ]; 
    const camposNulos = obligatorios.filter(campo => this.filtros[campo] === null);

    if (camposNulos.length === obligatorios.length) {
        this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar las fechas de visita.`, 3000, 'warning');
        this.filtrosSideNav.toggle();
        //${camposNulos.join(', ')}
        return; 
    }
  

    this.listFiltered = true;
    this.filtros.page = 1;
    this.filtrosService.guardarFiltros(this.filtros);
    this.loadVisits(1);
    if (this.filtros.financiera) {
      this.botonCM = true;
    }
    this.contabilizadorDeFiltrosTotales();
  }

  public loadLenders(): void {
    this.lenderServ.getAll('all').subscribe(
      res => {
        this.lenders = res;
      },
      err => {
        this.app.showError(err);
      }
    );
  }

  public onChangeLender(event) {
    if (event) {
      this.lenders.forEach(lender => {
        if (lender.idFinanciera == event) {
          this.filtros.financiera = lender;
        }
      });
    }
    else {
      this.filtros.financiera = null;
    }
  }

  masterToggle() {
    this.isAllSelected ?
      this.visits.forEach(visit => visit.selected = false) :
      this.visits.forEach(visit => visit.selected = true);

    this.isAllSelected = !this.isAllSelected;
    this.showCalifButton = this.isAllSelected;
    this.showCalifButtonMasiva = this.showCalifButton;

  }

  setSortField(sort: any) {
    this.sortAsc = sort.direction;
    if (sort.active !== this.sortField) {
      this.sortField = sort.active;
    }

    this.loadVisits(1);
  }

  checkSelected() {
    let selectedVisitsCount = 0;

    for (const visit of this.visits) {
      if (visit.selected) {
        selectedVisitsCount++;

        if (selectedVisitsCount >= 2) {
          break; // Si ya tienes al menos 2 elementos seleccionados, no necesitas seguir contando
        }
      }
    }

    this.showCalifButton = selectedVisitsCount > 0;
    this.showCalifButtonMasiva = selectedVisitsCount >= 2;
  }

  private crearURLQuery(): string {
    let queryParams = '';

    if (this.slt) {
      queryParams += '&slt=' + this.slt;
    }

    if (this.sortField) {
      queryParams += '&orderBy=' + this.sortField + (this.sortAsc ? ' ' + this.sortAsc : ' desc');
    }

    if (this.filtros.VisitStartDate) {
      let date = new Date(this.filtros.VisitStartDate);
      let strDate: string = date.toISOString();
      queryParams += '&inicioVisita=' + strDate.split('T')[0];
    }

    if (this.filtros.VisitEndDate) {
      let date = new Date(this.filtros.VisitEndDate);
      let strDate: string = date.toISOString();
      queryParams += '&finVisita=' + strDate.split('T')[0];
    }

    if (this.filtros.agente) {
      queryParams += `&idCobrador=${this.filtros.agente.idCobrador}`;
    }

    if (this.filtros.PaymentPromise != null) {
      queryParams += `&promesaPago=${this.filtros.PaymentPromise}`;
    }

    if (this.filtros.financiera != null) {
      queryParams += `&idCliente=${this.filtros.financiera.idCliente}`;
      this.botonCM = true;
    }

    if (this.filtros.VisitID && this.filtros.VisitID !== '') {
      queryParams += `&folio=${this.filtros.VisitID}`;
    }

    if (this.filtros.ApellidoMaternoCobrador && this.filtros.ApellidoMaternoCobrador !== '') {
      queryParams += `&apellidoMaternoCobrador=${this.filtros.ApellidoMaternoCobrador}`;
    }

    if (this.filtros.acreditado) {
      queryParams += `&idExterno=${this.filtros.acreditado.idExterno}`;
    }

    if (this.filtros.Estado && this.filtros.Estado !== '') {
      queryParams += `&estado=${this.filtros.Estado}`;
    }

    if (this.filtros.Municipio && this.filtros.Municipio !== '') {
      queryParams += `&municipio=${this.filtros.Municipio}`;
    }

    return queryParams;
  }

  initNulls(visits: Visit[]): void {
    for (let i = 0; i < visits.length; i++) {
      const visit: Visit = visits[i];
      visit.account = visit.account || new Account();
      visit.takenAccount = visit.takenAccount || new TakenAccount();
      visit.generalChecklist = visit.generalChecklist || new GeneralChecklist();

    }
  }

  salirCalificar(modal: any) {
    this.isAllSelected = false;
    this.visits.forEach(visit => visit.selected = false);
    this.showCalifButton = false;

    modal.approve('approved');
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.visitPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize) {
      this.visitPaginator.pagination.currentPage = newPagina.pageSize;
      this.loadVisits(newPagina.pageIndex + 1);
    }
  }

  public onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  onClick({ row, columnPressed }) {
    if (columnPressed === 'folio') {
      this.LinkVisitas(row);
    } else if (columnPressed === 'client.externalID') {
      this.linkDetalleCuenta(row);
    }
  }

  LinkVisitas(visita: any) {
    this.routeNavigate.navigate(['/visit/' + visita.folio]);
  }

  linkDetalleCuenta(cuenta: any) {
    let queryString = `?idExterno=${cuenta.id}`;
        queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";

        if(this.sessionData.isSuperAdmin){
            queryString += "&idCliente="  + cuenta.lender.id;
        };
    this.routeNavigate.navigateByUrl('/calificar-obligatorios/cuenta' + queryString);

  }

  onCtrlClick({ row, columnPressed }) {
    if (columnPressed === 'client.externalID') {
      this.ctrlClickLinkDetalleCuenta(row);
    }
  }

  ctrlClickLinkDetalleCuenta(cuenta: any) {
    const queryParams = cuenta.account.urlDetalleCuenta;
    window.open('/cuentas/domicilios/account' + queryParams, '_blank');

  }

  acreditadoFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera && this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera : null)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idAcreditado, nombreCompleto, idCliente, idExterno }) => (
            { idAcreditado: idAcreditado, nombre: idExterno + ' - ' + nombreCompleto, idCliente: idCliente }
          )
        ))
      )
  }.bind(this);

  public agenteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAgentes(term, this.filtros.agenteActivo)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
      );
  }.bind(this);

  onCalificar() {

    const dialogRef = this.dialog.open(ModalVisitaDetalleComponent, {
      width: 'calc(100% - 100px)',
      data: this.itemsSelected
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadVisits(this.filtros.page);
      }

    });
  }

  onCalificarMasivas() {
    this.visitasACalificar = [];

    if (this.filtros.financiera === null) {
      this.app.showSnackbar('¡Aviso!', 'Se debe filtrar por cliente', 3000, 'warning');
      return;
    }

    this.itemsSelected.forEach(visita => {
      if (
        (this.sessionData.isSuperAdmin && typeof (visita as any).calificacionSuperAdministrador !== 'number') ||
        (!this.sessionData.isSuperAdmin && typeof (visita as any).calificacionAdministradorFinanciera !== 'number')
      ) {
        this.visitasACalificar.push(visita as any);
      }
    });

    if (this.visitasACalificar.length === 0) {
      this.app.showSnackbar('¡Aviso!', 'Las visitas seleccionadas ya se encuentran calificadas.', 3000, 'warning');
      return;
    }

    const dialogRef = this.dialog.open(ModalVisitaDetalleMasivaComponent, {
      height: '500px',
      width: '800px',
      data: {
        visitasACalificar: this.visitasACalificar,
        idCliente: this.filtros.financiera.idCliente,
        idFinanciera: this.filtros.financiera.idFinanciera
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadVisits(this.filtros.page);
      }

    });
  }

}
