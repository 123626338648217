import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';
//import { FiltersComponent } from './filters/filters.component';
import * as moment from 'moment';
import { TablaParametros } from '../modelos/tabla-parametros.model';
import { sortLinear } from '@swimlane/ngx-charts';

export interface Column {
    name: string;
    key: string,
    sortName?: string;
    type?: string;
    icono?: string;
    link?: string;
    tooltip?: string;
    hide?: boolean;
}

@Component({
    selector: 'app-tabla-expandible',
    templateUrl: './tabla-expandible.component.html',
    styleUrls: ['./tabla-expandible.component.css']
})
export class TablaExpandibleComponent implements OnInit, OnDestroy, OnChanges {
    @Input() items: Array<any> = [];
    @Input() parametros: TablaParametros;
    private resizeObserver: ResizeObserver;
    displayedColumns: string[];
    dataSource;
    selection = new SelectionModel<any>(true, []);
    selectedRow: any;
    subscription: Subscription;
    filterFormGroup: FormGroup;
    momentDate;
    copiado:any;

    constructor(
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private paginatorIntl: MatPaginatorIntl
    ) {
        this.momentDate = moment;
    }

    ngOnInit() {
        this.paginatorIntl.itemsPerPageLabel = 'Registros por página:';
        this.paginatorIntl.nextPageLabel = 'Siguiente';
        this.paginatorIntl.previousPageLabel = 'Anterior';
        this.paginatorIntl.firstPageLabel = 'Primera página';
        this.paginatorIntl.lastPageLabel = 'Última página';

        this.paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
            }

            const startIndex = page * pageSize;
            const endIndex =
            startIndex + pageSize > length ? length : startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} de ${length}`;
        };
        this.filterFormGroup = this.formBuilder.group({
            search: ['']
        });

        this.displayedColumns = ['expand'].concat(this.parametros.columns.map(c => c.name));
        this.dataSource = new MatTableDataSource<any>(this.items);

        //Se coloca para cuando se van a inicializar registros seleccionados
        this.seleccionarPorDefecto(this.items);

        this.subscription = this.selection.changed.subscribe(data => {
            if(this.parametros.onSelectedRows){
                this.parametros.onSelectedRows(data.source.selected);
            }
        });
    }

    ngOnChanges(changes: any) {

        if (Boolean(changes.items)) {
            this.dataSource = new MatTableDataSource<any>(changes.items.currentValue);
            this.selection.clear();
            this.seleccionarPorDefecto(changes.items.currentValue);
        }
        
    }

    ngOnDestroy() {
        if(this.subscription){
            this.subscription.unsubscribe();
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private seleccionarPorDefecto(items){
        if(items){
            items.forEach(item => {
                if(item.seleccionado){
                    item.seleccionado = false;
                    this.seleccionar(item);
                }
    
                if(item.seleccionada){
                    item.seleccionada = false;
                    this.seleccionar(item);
                }
            });
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    seleccionar(row) {
        if(row.seleccionado === false || row.seleccionado === true){
            row.seleccionado = !row.seleccionado;
        }
        if(row.seleccionada === false || row.seleccionada === true){
            row.seleccionada = !row.seleccionada;
        }
        if (!this.parametros.multiSelect) {
            this.selection.clear();
            this.selectedRow = row;
        }
        this.selection.toggle(row);
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }

        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    linkRow($event, row?: any, columnPressed?: any) {
        $event.stopPropagation();
        if(this.parametros.onLinkRow){
            this.parametros.onLinkRow({ row, columnPressed });
        }
    }

    onDblClick(row) {
        if(this.parametros.onDoubleClick){
            this.parametros.onDoubleClick({row});
        }
    }

    sortData(sort: Sort) {
        if(this.items && this.items.length> 0){
            const column = this.parametros.columns.find((c: any) => c.name === sort.active);
            if (!this.parametros.ignoreSortChange) {
                if(this.parametros.sortChange){
                    this.parametros.sortChange({
                        active: column?.sortName || column?.key,
                        direction: sort.direction
                    });
                }
            }
            else {
                if(column){
                    if (sort.direction == 'asc') {
                        this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a, b) => {
                            var keyA = a[column.key];
                            var keyB = b[column.key];
                            if (keyA > keyB) {
                                return 1;
                            }
                            if (keyA < keyB) {
                                return -1;
                            }
                            return 0;
                        }));
                    }
                    else if (sort.direction == 'desc') {
                        this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a, b) => {
                            var keyA = a[column.key];
                            var keyB = b[column.key];
                            if (keyA > keyB) {
                                return -1;
                            }
                            if (keyA < keyB) {
                                return 1;
                            }
                            return 0;
                        }));
                    }
                }
                
            }
        }
    }

    getStatusClass(value: string): string {
        const classes = {
            'Procesado con errores': 'procesado-errores',
            'En cola...': 'en-cola',
            'Cargando...': 'cargando',
            'Cargado': 'cargado',
            'Procesando': 'procesando',
            'Procesado': 'procesado',
            'Geolocalizando': 'geolocalizando...',
            'Geolocalizado': 'geolocalizado',
            'Calculando cobertura': 'calculando-cobertura',
            'Cobertura completa': 'cobertura-completa',
            'Calculando SLA': 'calculando-sla',
            'Terminado': 'terminado',
            'Importado': 'importado',
            'Activa': 'cuentaActiva',
            'Activo': 'cuentaActivo',
            'Visitada': 'cuentaEnEspera',
            'Apartada': 'cuentaApartada',
            'En Espera de Confirmación': 'cuentaEnEsperaDeConfirmacion',
            'Inactiva': 'cuentaInactiva',
            'Por Confirmar': 'cuentaEnEsperaDeConfirmacion',
            'Aceptada': 'terminado',
            'Rechazada': 'procesado-errores',
            'Desasignada': 'en-cola',
            'Reasignada': 'importado',
            'Reasignada (Cuenta inactiva)': 'estatus-defecto',
            'Aprobado': 'terminado',
            'Borrador': 'estatus-defecto',
            'Rechazado': 'procesado-errores',
            'Pendiente confirmación': 'pendiente',
            'Pendiente': 'pendiente',
            'Aplicado': 'cargando',
            'Timbrado': 'terminado',
            'Cancelada': 'calculando-cobertura',
            'Cancelado': 'calculando-cobertura',
            'Error': 'error',

        };
        
        // Si el valor contiene la palabra "Error", retorna la clase 'error'.
        if (value.includes('Error')) {
            return 'error';
        }
        
        return classes[value] || 'estatus-defecto'; // 'estatus-defecto' es la clase por defecto si no se encuentra una coincidencia
    }

    handleLinkClick(event: MouseEvent, item: any, key: string): void {
        if (event.ctrlKey) {
          this.handleCtrlLinkClick(event, item, key);
        } else {
          this.linkRow(event, item, key);
        }
    }

    handleCtrlLinkClick($event, row?: any, columnPressed?: any): void {
        $event.stopPropagation();
        if(this.parametros.onCtrlClicLinkRow){
            this.parametros.onCtrlClicLinkRow({ row, columnPressed });
        }
    }

    openFilters() {
        /*const dialogRef = this.dialog.open(FiltersComponent, {
          minWidth: 400,
          disableClose: true,
          data: this.filters
        });
    
        dialogRef.afterClosed().subscribe(data => {
          if (data) {
            this.filtersChange.emit(data);
          }
        });*/
      }

    expandir(row: any){
        for (let index = 0; index < this.items.length; index++) {
            const itemValidar = this.items[index];
            if(itemValidar != row){
                itemValidar.isExpanded = false;
                if(itemValidar[this.parametros.expandProperty] && row[this.parametros.expandProperty].length > 0){
                    this.deseleccionarHijos(itemValidar[this.parametros.expandProperty]);
                }
            }
        }
        if(this.parametros.expandParams && this.parametros.expandParams.onSelectedRows){
            this.parametros.expandParams.onSelectedRows([]);
        }
        if((row[this.parametros.expandProperty] && row[this.parametros.expandProperty].length > 0) || row.isExpanded){
            row.isExpanded = !row.isExpanded;
        }
        else {
            this.parametros.onShowDetail(row);
        }
    }

    deseleccionarHijos(items: any[]){
        for (let index = 0; index < items.length; index++) {
            const itmeDeseleccionar = items[index];
            itmeDeseleccionar.seleccionado = false;
            itmeDeseleccionar.seleccionada = false;
        }
    }
}
