import { Financiera } from './../../../financieras/shared/financiera.model';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AgentService } from "../../agent.service";
import { LoaderService } from "../../../loader/loader.service";
import { MessageService } from "../../../shared/message/message.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { VisitService } from "../../../visit/visit.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from "rxjs";
import { Account } from "../../../account/account.model";
import { AccountService } from "../../../account/account.service";
import { MapboxCuentasComponent } from 'src/app/mapbox/cuentas/mapbox-cuentas.component';
import { environmentSelector } from '../../../../environments/environment.selector';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { FiltrosCuentas } from "../../../account/modelos/filtros-cuentas.model";
import { LenderService } from "../../../lender/lender.service";
import { Lender } from "../../../lender/lender.model";
import { StateService } from '../../../state/state.service';
import { OperationTag } from "../../../operation-tag/operation-tag.model";
import { OperationTagService } from "../../../operation-tag/operation-tag.service";
import { AccountPaginator } from "../../../account/account.paginator";
import { MunicipalityService } from '../../../municipality/municipality.service';
import { Page } from './../../../shared/interfaces/page';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormValidation } from 'src/app/core/form-validation/form-validation';
import * as moment from 'moment';
import * as turf from '@turf/turf';
import { VisualizarCuentasDialog } from '../../../account/visualizar-cuentas/visualizar-cuentas-dialog.component';
import { GestoresNoVinculadosDialog } from '../../visibilidad-cuentas/modales/gestores-no-vinculados/gestores-no-vinculados-dialog.component';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

declare let $: any;

@Component({
    selector: 'lista-visibilidad',
  templateUrl: './lista-visibilidad.component.html',
  styleUrls: ['./lista-visibilidad.component.css'],
    providers: [
        AccountService,
        OperationTagService,
        AgentService,
        LenderService,
        VisitService,
        AgentService,
        StateService,
        MunicipalityService,
        LoaderService
    ]
})

export class ListaVisibilidadComponent implements OnInit {
    private environment = environmentSelector();

    hijosActivos: boolean = false;
    sessionData: SessionData;
    filtrosAbiertos: boolean = false;
    loadCuentasMapa: boolean = false;
    private $dropdownAgent: any;
    private $filterModal: any;
    private allAccountsSelected: boolean;
    private pageSize: number = 50;
    private isLoad = true;
    public pagina: number = 1;
    public ayuda: boolean = false;

    public accountPaginator: AccountPaginator;
    public accounts: Account[];
    public accountsSelected: Account[];
    public agentSearchText: string;
    public assignComment: string;
    public assignMessage: string;
    public idLender: number = null;
    public filterLender: number = null;
    public filterVisibility: string = null;
    public filterAccountAssignedEndDate: Date;
    public filterAccountAssignedStartDate: Date;
    public filterAccountEndDate: Date;
    public filterAccountStartDate: Date;
    public filterEstatus: string = null;
    public filterGeoloc: string = null;
    public filterProductType: number = null;
    public filterTag: number = null;
    public dibujando = false;
    public eventoCreado = false;

    public httpError: any;
    public lenders: Lender[];
    public listFields: any = {};
    public listFiltered: boolean;
    public search: string;
    public selectedAgent: number = null;
    public selectedStatus: string = null;
    public selectedTag: number = null;
    public showActions: boolean;
    public sortField: string;
    public sortAsc: boolean;
    public tags: OperationTag[];
    public etiquetas: Array<any>;
    public sucursales: Array<any>;
    public today: Date = new Date();

    public fechaCreacionMaxDate: Date;
    public fechaCreacionMinDate: Date;
    public fechaAsignacionMaxDate: Date;
    public fechaAsignacionMinDate: Date;
    public fechaImportacionMaxDate: Date;
    public fechaImportacionMinDate: Date;
    public fechaAsignacionCuentaMinDate: Date = new Date();
    public fechaAsignacionCuentaMaxDate: Date;

    public financieraVld: FormValidation;
    public visibilidadVld: FormValidation;
    public etiquetaVld: FormValidation;
    public estatusVld: FormValidation;
    public geolocVld: FormValidation;
    public tipoCuentaVld: FormValidation;
    public fechaCreacionVld: FormValidation;
    public fechaAsignacionVld: FormValidation;

    public tagLoader = false;
    public setTagMsg: string;

    public labelColonia: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelCodigoPostal: string;
    public pais: string;
    public nonSelectedTagWarning: boolean = false;
    public estados: any[];
    public municipios: any[];
    public establecerVisita: boolean = true;
    public establecerFecha: boolean = true;
    public styleModel: string = "hidden";
    public formAsignacion: FormGroup;
    public horariosVisitas = {};
    public fechaVisita: string;
    public fechaLimite: string;
    public numDiasLimite: number;
    public idCobrador: number;
    public momentFecha;
    public diasVencimiento: number;
    public mapHeight: number = 600;
    public tableHeight: number = 600;
    public asignarTodasLasCuentas = false;
    public loadingEtiquetasCuenta: boolean = false;
    public etiquetasCuenta = [];
    public geoCercaACrear:any = {};
    public contadorCuentas:number = 0;
    public contadorSeleccion:number = 0;
    public cuentasSeleccionadas: number = 0;
    public asignarSeleccion: boolean =  true;
    public redInterna: boolean= false;
    public aplicoFiltros: boolean= false;
    
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    @ViewChild('mapaCuentas') private mapaCuentas: MapboxCuentasComponent;

    @ViewChild('agenteFilterInput') private agenteFilterInput: any;
    private agenteFilterFlag = false;
    private agenteFilterFounded: any;

    @ViewChild('asignarAgenteFilterInput') private asignarAgenteFilterInput: any;
    private asignarAgenteFilterFlag = false;
    private asignarAgenteFilterFound: any;

    @ViewChild('acreditadoFilterInput') private acreditadoFilterInput: any;
    private acreditadoFilterFlag = false;
    private acreditadoFilterFounded: any;

    @ViewChild('grupoFilterInput') private grupoFilterInput: any;
    private grupoFilterFlag = false;
    private grupoFilterFounded: any;

    public products: Array<any>;
    public loadingProducts: boolean;
    slt: string = 'lenderID,lenderName,productName,accountID,clientID,idCliente,idAcreditadoPG,idCuentaPG,clientFullName,externalID,accountStatus,accountCreatedAt,agentID,agentFullName,takenAccountTakenDate,takenAccountExpirationDate,accountDaysInPlatform,accountDaysTaken,city, municipality,state, suburb,clientAddressGeoloc,operationTagID,operationTagName,accountAssignedDate,visibility, clienteActivo, fecVisita, hrInicio1, hrFin1, hrInicio2, hrFin2, totalVisitas, sucursal, grupo, diasMoratorios, lat, lng, calle, numeroInterior, numeroExterior, cp,nombreEtiquetaCuenta, subExternalID, folioDomicilio, idDomicilioAcreditado, folioDomicilio, tipoCobertura, idAcreditadoCuentaDomicilio, domicilioCompleto, idProducto';
    sltCuentas: string = 'idCliente, nombreCliente, idFinanciera, idExterno, subIdExterno, nombreAcreditado, idAcreditado, idCuenta, idCobrador, idAgente, nombreAgente, nombreProducto, grupo, sucursal, folioDomicilio, idAcreditadoCuentaDomicilio, idDomicilioAcreditado, numeroExt, numeroInt, calle, entreCalles, codigoPostal, colonia, municipio, estado, latitud, longuitud';
    adip: string = '"accountDaysInPlatform"';

    

    public subscribeRouterParamsBounded: Function;

    public loadings: any = {
        sucursales: false,
        etiquetas: false,
        municipios: false,
        estados: true
    }

    public filtros: FiltrosCuentas;

    public filtrosModal:any = {
        idAcreditado: null,
        idAgente: null,
        idCliente: null,
        idFinanciera: null
    };
    public idCobradorModal:any = null;
    public idExternoModal:any = null;
    public idFinancieraModal:any = null;
    public cuentas: any[];
    public bloquearEliminar:boolean = false;
    public cargandoCuentas:boolean = false;
    public itemsSelected: Array<any>[] = [];
    filtroAcreditado: any;

    tablaCuentas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: true,
        columnas: [
            {
                name: 'ID Cliente',
                key: 'idCliente',
                hide: true
            }, {
              name: 'Cliente',
              key: 'nombreCliente',
              hide: false
            }, {
              name: 'ID Financiera',
              key: 'idFinanciera',
              hide: true
            }, {
                name: 'ID Cuenta',
                key: 'idExterno',
                type: 'link',
                hide: false
            }, {
                name: 'ID Subcuenta',
                key: 'subIdExterno',
                hide: false
            }, {
                name: 'Cuenta',
                key: 'nombreAcreditado',
                hide: false
            }, {
                name: 'Producto',
                key: 'nombreProducto',
                hide: false
            }, {
                name: 'Folio Domicilio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Domicilio',
                key: 'domicilio',
                hide: false
            }, {
                name: 'ID Acreditado PG',
                key: 'idAcreditado',
                hide: true
            }, {
              name: 'ID Cuenta PG',
              key: 'idCuenta',
              hide: true
            }, {
                name: 'ID Cobrador',
                key: 'idCobrador',
                hide: true
            }, {
                name: 'ID Agente',
                key: 'idAgente',
                hide: true
            }, {
              name: 'Agente',
              key: 'nombreAgente',
              hide: false
            }, {
                name: 'Grupo',
                key: 'grupo',
                hide: false
            }, {
                name: 'Sucursal',
                key: 'sucursal',
                hide: false
            }
        ]
    }

    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;
    public totalMapaFiltros: number = 0;

    constructor(
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private accountService: AccountService,
        private accountTagService: OperationTagService,
        private lenderService: LenderService,
        private loaderService: LoaderService,
        private visitService: VisitService,
        private messageService: MessageService,
        private rootElement: ElementRef,
        private router: ActivatedRoute,
        private route: Router,
        private stateService: StateService,
        private municipalityService: MunicipalityService,
        private agenteServ: AgentService,
        public dialog: MatDialog
    ) {
        this.sessionData = this.localStorageService.getSessionData();
        this.cuentas = [];
        this.fechaCreacionMaxDate = new Date();
        this.fechaAsignacionMaxDate = new Date();
        this.fechaImportacionMaxDate = new Date();
        this.filtros = new FiltrosCuentas();
        this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
        this.accounts = [];
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();

    }

    ngOnInit() {
        
        this.$filterModal = $(this.rootElement.nativeElement).find('.ui.filter.modal');
        this.search = '';
        this.sortField = 'ac.id_externo_kobra';
        this.sortAsc = false;
        this.accountPaginator = new AccountPaginator();
        this.labelColonia = this.environment.labelColonia;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.labelCodigoPostal = this.environment.labelCodigoPostal;
        this.pais = this.environment.pais;
        this.mapHeight = window.innerHeight * .70;
        this.tableHeight = window.innerHeight * .70;
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
        //this.cargarCuentas(this.pagina);
        this.loadLenders();
        
    }

    private agregarContabilizadoresDeFiltros(){
   
        this.contabilizadorFiltros.add('Generales', 'generalesAdmin', [
            'idCliente',
            'idAcreditado',
            'idAgente',
           
        ]);
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'idAcreditado',
            'idAgente',
           
        ]);
       
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'idCliente',
            'excludeFinancierasDemo'
        ]);

        this.contabilizadorFiltros.add('Generales', 'generalesMapa', [
            'idExterno',
            'tipoCuenta',
            'sucursal',
            'etiquetaKobra',
            'idEtiquetaCuenta'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'idAcreditado',
            'diasMoratorios'
        ]);

        this.contabilizadorFiltros.add('Grupo', 'grupo', [
            'idGrupo'
           
        ]);

        this.contabilizadorFiltros.add('Ubicación', 'ubicacion', [
            'estado',
            'municipio',
            'colonia',

        ]);

        this.contabilizadorFiltros.add('Ubicación', 'ubicacionMX', [
           'idEstado',
           'idMunicipio',
           'codigoPostal',
           'colonia'
        ]);

        this.contabilizadorFiltros.add('Fechas', 'fechasC', [
           'fechaCreacionInicio',
           'fechaCreacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas', 'fechasA', [
           'fechaAsignacionInicio',
           'fechaAsignacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas', 'fechasI', [
           'fechaImportacionInicio',
           'fechaImportacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas total', 'fechasTotal', [
            'fechaCreacionInicio',
            'fechaCreacionFin',
            'fechaAsignacionInicio',
           'fechaAsignacionFin',
           'fechaImportacionInicio',
           'fechaImportacionFin'
         ]);
        
        this.contabilizadorFiltros.add('Agente', 'agente', [
           'agente'
        ]);
        
    }
    
    public contabilizadorDeFiltrosMapa(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
    }
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtrosModal);
    }
    
    public contabilizadorDeFiltrosTotales():void{
        
       
        let totalFiltrosMapaArray: number[] = [];
        let totalFiltrosArray: number[] = [];
        this.totalFiltros = 0;
        this.totalMapaFiltros = 0;

        if(!this.filtros.mostrarMapa){

            if(this.sessionData.isSuperAdmin){
                
                totalFiltrosArray.push( this.contabilizadorDeFiltros('generalesAdmin') );
            }

            totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
            this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        }else{

            if(this.sessionData.isSuperAdmin){
                totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('superAdmin') );
            }
            
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('generalesMapa') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('cuenta') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('grupo') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('ubicacion') );
            if(this.pais == 'MX'){
                totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('ubicacionMX') );
            }
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('fechasC') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('fechasA') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('fechasI') );
            totalFiltrosMapaArray.push( this.contabilizadorDeFiltrosMapa('agente') );
            this.totalMapaFiltros = totalFiltrosMapaArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        }

    }


    dibujarGeoCerca() {
        const data = this.mapaCuentas.draw.getAll();
        this.geoCercaACrear = {
            type: 'Polygon',
            coordinates: [data.features[0].geometry.coordinates[0]]
        }
        this.revisarCuentasEnRango();
    }

    revisarCuentasEnRango(){
        const turfPolygon = turf.polygon(this.geoCercaACrear.coordinates,{name:'polygon'});
         
        
        for (let i = 0; i < this.accounts.length; i++) {
            const cuenta = this.accounts[i];
            const turfPoint =  [cuenta.lng,cuenta.lat];
            if(turf.inside(turfPoint,turfPolygon)){
                cuenta.selected = true;
                this.contadorCuentas++;
            };
        }

        this.cuentasSeleccionadas = this.contadorCuentas + this.contadorSeleccion;

        if(this.cuentasSeleccionadas > 300) {
            this.asignarSeleccion =  false;
            this.app.showSnackbar("Aviso", "No se permite seleccionar más de 300 cuentas, favor de reducir tu selección.", 6000, 'warning');
        }
        
        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
        this.mapaCuentas.draw.deleteAll();
        this.dibujando = false;
    }

    public obtenerClienteRedInterna() {

        if (!this.sessionData.isSuperAdmin) {

            this.lenderService.obtenerClienteRedInterna(this.sessionData.idCliente)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.redInterna = res.data.redInterna;
            }, (err: any) => {
                this.app.showError(err);
            });
        } 
    }


    private subscribeRouterParams(filtrosNuevos: FiltrosCuentas): void {
        var iniciar = false;
        if (filtrosNuevos) {
            this.listFiltered = true;
            iniciar = true;
            this.filtros = filtrosNuevos;
            if (this.filtros.mostrarMapa) {
                this.pageSize = 1000;
            }
            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;
            this.accountPaginator.pagination.currentPage = this.filtros.page || 1;

            if (this.filtros.agente) {
                this.agenteFilterSearch(this.filtros.agente.idCobrador + '', true);
            }

            if (this.filtros.idAcreditado) {
                this.acreditadoFilterSearch(this.filtros.idAcreditado + '', true);
            }

            if (this.filtros.idGrupo) {
                this.grupoFilterSearch(this.filtros.idGrupo + '', true);
            }



            if (this.filtros.idMunicipio) {
                iniciar = false;
                this.loadMunicipios(true);
            }
            else if (this.filtros.idEstado) {
                iniciar = false;
                this.loadEstados(true);
            }

            if(this.filtros.mostrarMapa){
                this.accounts= [];
                this.consultarCuentasMapa(0, 1);
            }
        }

        if (!(this.sessionData.isSuperAdmin)) {
            this.filtros.excludeFinancierasDemo = false;
        }

        this.initListColumns();
        this.loadOperationTags();

        this.filtros.visibilidad = 1;
        this.filtros.estatus = 1;
        this.filtros.geoloc = 1;


        this.loadLenders();
        if (this.sessionData.isSuperAdmin) {
            this.loadCombos(iniciar);
        }

        this.isLoad = false;
    }

    private loadCombos(iniciar = true) {
        this.loadSucursales();
        this.cargarEtiquetasCuenta();
        this.loadProducts();
        this.loadEstados();
    }


    public selectedAgentDropdown(agent: number): void {
        if (agent) {
            this.selectedAgent = +agent;
        }
    }

    public checkSelecteds(): void {
        this.showActions = this.getSelectedAccounts().length ? true : false;
    }

    public clearAgentSelection(): void {
        this.$dropdownAgent.dropdown('clear');
    }

    public clearFilter(): void {
        Object.keys(this.filtros).forEach(key => {
            if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')) {
                this.filtros[key] = null;
                if (this.idLender){
                    this.idLender = null;
                }
            }
        });
        this.filtros.excludeFinancierasDemo = this.sessionData.isSuperAdmin ? true : false;
        this.filtros.page = 1;
        this.resetAgenteFilter();
        // this.agenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        // this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        // this.$filterModal.modal('hide');
        this.filtrosService.removerFiltros();
        this.filtros.mostrarMapa = true;
        this.accounts = [];
        this.consultarCuentasMapa(0, 1);
    }

    public closeModals(): void {
      this.establecerVisita = true;
      this.establecerFecha = true;
      this.$filterModal.modal('hide');
      this.resetAsignarAgenteFilter();
  }


    public createQueryFilter(): string {
        let query: string = '';

        if (this.sortField) {
            query += '&orderBy=' + this.sortField + (this.sortAsc ? ' asc' : ' desc');
        }

        if (this.filtros.financiera) query += '&idFinanciera=' + this.filtros.financiera.idFinanciera.toString();
        if (this.filtros.idExterno) query += '&idExterno=' + this.filtros.idExterno.toString();
        query += '&visibilidad=2';
        if (this.filtros.etiqueta) query += '&idEtiqueta=' + this.filtros.etiqueta.toString();
        if (this.filtros.etiquetaKobra) query += '&idOperationTag=' + this.filtros.etiquetaKobra.toString();
        if (this.filtros.idEtiquetaCuenta) query += '&idEtiquetaCuenta=' + this.filtros.idEtiquetaCuenta.toString();
        query += '&geoloc=1';
        if (this.filtros.tipoCuenta) query += '&idProduct=' + this.filtros.tipoCuenta.toString();
        if (this.filtros.idCuenta) query += '&idCuenta=' + this.filtros.idCuenta.toString();
        if (this.filtros.agente) query += '&idCobrador=' + this.filtros.agente.idCobrador.toString();

        if (this.filtros.idAcreditado) query += '&idAcreditado=' + this.filtros.idAcreditado.toString();
        if (this.filtros.idCliente) query += '&idCliente=' + this.filtros.idCliente.toString();
        query += '&cuentaEstatus=1';

        if (this.pais != 'CO') {
            if (this.filtros.idEstado) query += '&estado=' + this.estados.find(estado => estado.idEstado == this.filtros.idEstado).nombre;
            if (this.filtros.idMunicipio) query += '&municipio=' + this.municipios.find(municipio => municipio.idMunicipio == this.filtros.idMunicipio).nombre;
            if (this.filtros.codigoPostal) query += '&codigoPostal=' + this.filtros.codigoPostal.toString();
            if (this.filtros.colonia) query += '&colonia=' + this.filtros.colonia.toString();
        }
        else {
            if (this.filtros.estado) query += '&estado=' + this.filtros.estado.toString();
            if (this.filtros.ciudad) query += '&ciudad=' + this.filtros.ciudad.toString();
            if (this.filtros.municipio) query += '&municipio=' + this.filtros.municipio.toString();
            if (this.filtros.colonia) query += '&colonia=' + this.filtros.colonia.toString();
        }
        if (this.filtros.excludeFinancierasDemo) query += '&excludeFinancierasDemo=' + this.filtros.excludeFinancierasDemo.toString();
        if (this.filtros.sucursal) query += '&idSucursal=' + this.filtros.sucursal.toString();
        if (this.filtros.idGrupo) query += '&idGrupo=' + this.filtros.idGrupo.toString();

        if (this.filtros.fechaCreacionInicio) query += '&cuentaCreacionFechaInicio=' + this.filtros.fechaCreacionInicio.toISOString().split('T')[0];
        if (this.filtros.fechaCreacionFin) {
            let date: Date = new Date(this.filtros.fechaCreacionFin.getTime());
            date.setDate(this.filtros.fechaCreacionFin.getDate() + 1);
            query += '&cuentaCreacionFechaFin=' + date.toISOString().split('T')[0];
        }
        if (this.filtros.fechaAsignacionInicio) query += '&cuentaAsignadaFechaInicio=' + this.filtros.fechaAsignacionInicio.toISOString().split('T')[0];
        if (this.filtros.fechaAsignacionFin) {
            let date: Date = new Date(this.filtros.fechaAsignacionFin.getTime());
            date.setDate(this.filtros.fechaAsignacionFin.getDate() + 1);
            query += '&cuentaAsignadaFechaFin=' + date.toISOString().split('T')[0];
        }
        if (this.filtros.fechaImportacionInicio) query += '&cuentaImportadaFechaInicio=' + this.filtros.fechaImportacionInicio.toISOString().split('T')[0];
        if (this.filtros.fechaImportacionFin) {
            let date: Date = new Date(this.filtros.fechaImportacionFin.getTime());
            date.setDate(this.filtros.fechaImportacionFin.getDate() + 1);
            query += '&cuentaImportadaFechaFin=' + date.toISOString().split('T')[0];
        }
        if (this.filtros.diasMoratorios) query += '&diasMoratorios=' + parseInt(this.filtros.diasMoratorios);

        if (this.filtros.fecVisita) query += '&fecVisita=' + this.filtros.fecVisita.toISOString().split('T')[0];

        query += `&slt=${this.slt}`;

        return query;
    };


    private getSelectedAccounts(): Account[] {
        let accounts: Account[] = [];

        for (var i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].selected || this.asignarTodasLasCuentas) {
                let account: Account = new Account();
                account.id = this.accounts[i].id;
                account.externalID = this.accounts[i].externalID;
                account.subExternalID = this.accounts[i].subExternalID;
                account.lenderID = this.accounts[i].lenderID;
                account.idCliente = this.accounts[i].idCliente;
                account.idAcreditadoPG = this.accounts[i].idAcreditadoPG;
                account.idCuentaPG = this.accounts[i].idCuentaPG;
                account.idProducto = this.accounts[i].idProducto;
                account.nameProduct = this.accounts[i].nameProduct;
                account.nombreAcreditado = this.accounts[i].nombreAcreditado;
                account.idAcreditadoCuentaDomicilio = this.accounts[i].idAcreditadoCuentaDomicilio;
                account.domicilios = this.accounts[i].domicilios;
                accounts.push(account);
            }
        }
        return accounts;
    }
   
    private initListColumns(): void {
        let listFields: any = JSON.parse(localStorage.getItem('accountListFields')) || {};
        this.listFields.accountCreatedAt = listFields.accountCreatedAt == false ? false : true;
        this.listFields.lenderName = listFields.lenderName == false ? false : true;
        this.listFields.nameProduct = listFields.nameProduct == false ? false : true;
        this.listFields.clientID = listFields.clientID == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.clientFullName = listFields.clientFullName == false ? false : true;
        this.listFields.accountStatus = listFields.accountStatus == false ? false : true;
        this.listFields.accountTag = listFields.accountTag == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.accountTakenDate = listFields.accountTakenDate == false ? false : true;
        this.listFields.accountAssignedDate = listFields.accountAssignedDate == false ? false : true;
        this.listFields.accountExpirationDate = listFields.accountExpirationDate == false ? false : true;
        this.listFields.accountDaysInPlatform = listFields.accountDaysInPlatform == false ? false : true;
        this.listFields.accountUntilFirstVisit = listFields.accountUntilFirstVisit == false ? false : true;
        this.listFields.accountDaysTaken = listFields.accountDaysTaken == false ? false : true;
        this.listFields.accountMoraDays = listFields.accountMoraDays == false ? false : true;
        this.listFields.clientLocation = listFields.clientLocation == false ? false : true;
        this.listFields.clientCity = listFields.clientCity == false ? false : true;
        this.listFields.clientMunicipality = listFields.clientMunicipality == false ? false : true;
        this.listFields.clientState = listFields.clientState == false ? false : true;
        this.listFields.clientSuburb = listFields.clientSuburb == false ? false : true;
        this.listFields.clientAddressGeoloc = listFields.clientAddressGeoloc == false ? false : true;
        this.listFields.visibility = listFields.visibility == false ? false : true;
        this.listFields.fecVisita = listFields.fecVisita == false ? false : true;
        this.listFields.visitGroup = listFields.visitGroup == false ? false : true;
        this.listFields.countVisits = listFields.countVisits == false ? false : true;
        this.listFields.sucursal = listFields.sucursal == false ? false : true;
        this.listFields.nombreEtiquetaCuenta = listFields.nombreEtiquetaCuenta == false ? false : true;
    }


    consultarCuentasMapa(inicio:number, contadorPagina:number) {

        this.contabilizadorDeFiltrosTotales();
        if ( this.totalMapaFiltros < 2){
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return;
        }

        let queryParams: string = this.createQueryFilter();
        let rows = 1000;
        let start = inicio;
        let page =  contadorPagina;
        this.aplicoFiltros = true;
        this.loadCuentasMapa = true;
        
        this.accountService.obtenerCuentasMapa(start, rows, page, queryParams)
            .pipe(take(1))
            .subscribe((response: any) => {

                for (let i = 0; i < response.pageData.length; i++) {
                    if(response.pageData[i].lat && response.pageData[i].lng){
                        let account: Account = new Account();
                        let domicilio: string;
                        account.id = response.pageData[i].accountID;
                        account.status = response.pageData[i].accountStatus;
                        account.client.idAcreditado = response.pageData[i].clientID;
                        account.client.externalID = response.pageData[i].externalID;
                        account.client.subExternalID = response.pageData[i].subExternalID;
                        account.client.fullName = response.pageData[i].clientFullName;
                        account.taken.agent.id = response.pageData[i].agentID;
                        account.taken.agent.fullName = response.pageData[i].agentFullName;  
                        account.lat = response.pageData[i].lat;
                        account.lng = response.pageData[i].lng;
                        account.idCliente = response.pageData[i].idCliente;
                        account.idAcreditadoPG = response.pageData[i].idAcreditadoPG;
                        account.idCuentaPG = response.pageData[i].idCuentaPG;
                        account.grupo = response.pageData[i].grupo;
                        account.sucursal = response.pageData[i].sucursal;
                        account.nameProduct = response.pageData[i].productName;
                        account.idProducto = response.pageData[i].idProducto;
                        account.lenderID = response.pageData[i].lenderID;
                        account.externalID = response.pageData[i].externalID;
                        account.subExternalID = response.pageData[i].subExternalID;
                        account.nombreAcreditado = response.pageData[i].clientFullName;
                        account.client.address.idDomicilioAcreditado = response.pageData[i].idDomicilioAcreditado;
                        account.idAcreditadoCuentaDomicilio = response.pageData[i].idAcreditadoCuentaDomicilio;
                        account.client.address.fullAddress = response.pageData[i].domicilioCompleto ? response.pageData[i].domicilioCompleto : `
                            ${account.client.address.calle ? account.client.address.calle + ' ' : ''} 
                            ${account.client.address.numeroExt ? account.client.address.numeroExt + ', ' : ''}
                            ${account.client.address.numeroInt ? account.client.address.numeroInt + ', ' : ''}
                            ${account.client.address.suburb ? account.client.address.suburb + ' ' : ''}
                            ${account.client.address.zipCode ? account.client.address.zipCode + ', ' :''}
                            ${account.client.address.municipality?account.client.address.municipality + ' ': ''}
                            ${account.client.address.state? account.client.address.state : ''}`;
                        account.client.address.folioDomicilio = response.pageData[i].folioDomicilio;
                        domicilio = (response.pageData[i].calle ? response.pageData[i].calle +' ' : '') +
                                    (response.pageData[i].numeroExterior ? response.pageData[i].numeroExterior +', ' : '') + 
                                    (response.pageData[i].numeroInterior ? response.pageData[i].numeroInterior +', ' : '') + 
                                    (response.pageData[i].suburb ? response.pageData[i].suburb +' ' : '') +
                                    (response.pageData[i].suburb ? response.pageData[i].cp +', ' : '') +
                                    (response.pageData[i].municipality ? response.pageData[i].municipality+', ' : '') +
                                    (response.pageData[i].state ? response.pageData[i].state +'.' : '') ;
                        account.domicilios = [{
                            folioDomicilio: response.pageData[i].folioDomicilio,
                            idDomicilioAcreditado: response.pageData[i].idDomicilioAcreditado,
                            idAcreditadoCuentaDomicilio: response.pageData[i].idAcreditadoCuentaDomicilio,
                            domicilio: domicilio
                        }];
                        this.accounts.push(account);  
                    }
                    
                }
                
                start += rows;
                page = page + 1;

                if( response.pageData.length < rows ) {

                    if(this.filtros.mostrarMapa && this.mapaCuentas) {
                        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
                    }

                    return;
                } else {
                    this.consultarCuentasMapa(start, page);
                }

                this.loadCuentasMapa = false;
             
            }, (err: any) => {
                this.loadCuentasMapa = false;
                this.app.showError(err);
                
            }, 
            () => this.loadCuentasMapa = false
        );
        this.contabilizadorDeFiltrosTotales();

    }

    public loadOperationTags(): void {
        let etiquetas: OperationTag[] = <OperationTag[]>JSON.parse(localStorage.getItem('etiquetasOperaciones'));
        if (etiquetas) {
            this.tags = etiquetas;
        } else {
            this.accountTagService.getAll().subscribe(
                res => {
                    this.tags = res;
                    localStorage.setItem('etiquetasOperaciones', JSON.stringify(res));
                },
                err => {
                    this.app.showError(err);
                }
            );
        }
    }

    public loadSucursales() {
        if (this.filtros.financiera) {
            let uri = '/sucursales';
            this.loadings.sucursales = true;

            uri += `?idCliente=${this.filtros.financiera.idCliente.toString()}`;

            this.visitService.get(uri, true)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadings.sucursales = false;
                    this.sucursales = res;
                }, (err: any) => {
                    this.loadings.sucursales = false;
                    this.app.showError(err);
                });
        }
        else {
            this.sucursales = [];
        }
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtrosModal.idFinanciera = lender.idFinanciera;
                    this.filtrosModal.idCliente = lender.idCliente;
                    this.filtros.financiera = lender;
                    this.filtros.idCliente = lender.idCliente;
                    if (lender.financieraDemo) {
                        this.filtros.excludeFinancierasDemo = false;
                    }
                }
            });
            this.loadSucursales();
            this.cargarEtiquetasCuenta();
            this.loadProducts();
        }
        else {
            this.filtros.financiera = null;
            this.filtrosModal.idFinanciera = null;
            this.filtrosModal.idCliente = null;
        }

    }

    public validarMoratorios(event) {
        if (event.key == '.') {
            // invalid character, prevent input
            event.preventDefault();
        }
        var valor: string = null;
        var numero: number = 0;
        if (this.filtros.diasMoratorios) {
            valor = this.filtros.diasMoratorios;
            valor = valor + event.key;
            numero = parseInt(valor);
        }
        if (numero > 9999) {
            this.filtros.diasMoratorios = "9999";
            event.preventDefault();
        }

    }

    public onChangeEstate(event) {
        this.resetFilterByName('municipio');
        this.loadMunicipios();
    }

    public loadLenders(): void {
        this.lenderService.getAll('all').subscribe(
            lenders => {
                this.lenders = lenders;
                if (!this.sessionData.isSuperAdmin) {
                    this.idLender = this.sessionData.idFinanciera;
                    this.onChangeLender(this.idLender);
                }
                this.loadCombos();
            },
            err => {
                this.app.showError(err);
            }
        );
    }

    loadProducts() {
        this.loadingProducts = true;
        if (this.sessionData.isSuperAdmin) {
            if (this.filtros.financiera) {
                this.lenderService.obtenerProductosCliente(this.filtros.financiera.idCliente)
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            } else {
                this.lenderService.obtenerProductos()
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            }
        } else {
            this.lenderService.obtenerProductosCliente(this.sessionData.idCliente)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadingProducts = false;
                    this.products = res.data;
                }, (err: any) => {
                    this.loadingProducts = false;
                    this.app.showError(err);
                });
        }

    }


    public saveInStorage(): void {
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    public selectAllAccounts(): void {
        for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].clienteActivo) {
                this.accounts[i].selected = this.allAccountsSelected;
            }
        }

        this.checkSelecteds();
    }

    public setFilter(): void {
        if (this.filtros.idCuenta && !Number(this.filtros.idCuenta)) {
            this.app.showSnackbar('¡Aviso!', 'Solo se permiten números en el filtro ID Cuenta.', 3000, 'warning');

            return;
        }

        if (this.filtros.agente.idCobrador && !Number(this.filtros.agente.idCobrador)) {
            this.app.showSnackbar('¡Aviso!', 'Solo se permiten números en el filtro ID Cobrador.', 3000, 'warning');

            return;
        }

        if ((this.filtros.codigoPostal || this.filtros.colonia) && (!this.filtros.estado && !this.filtros.idEstado)) {
            if (this.pais != 'CO') {
                this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el estado para filtrar por colonia y/o código postal.', 3000, 'warning');
            }
            else {
                this.app.showSnackbar('Aviso', 'Es necesario capturar el estado para filtrar por barrio.', 3000, 'warning');
            }
            this.showFilter();
            return;
        }

        this.listFiltered = true;
        this.filtros.page = 1;
        this.$filterModal.modal('hide');
        this.filtrosService.guardarFiltros(this.filtros);

        if (this.filtros.mostrarMapa && this.mapaCuentas) {
            this.accounts = [];
            this.consultarCuentasMapa(0, 1);
        }else {
            //this.loadAccounts();
        }

        this.contabilizadorDeFiltrosTotales();
        
    }

    public showFilter(): void {
      this.$filterModal.modal('show');
    }

    public setSortField(field: string) {
        if (field == this.sortField) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortField = field;
            this.sortAsc = true;
        }

        //this.loadAccounts(1);
    }

    public asignarCuentasPorRango(){
        if(!this.eventoCreado){
            this.mapaCuentas.map.on('draw.create', this.dibujarGeoCerca.bind(this));
            this.eventoCreado = true;
        }
        this.mapaCuentas.modoDibujar();
        this.dibujando= true;
    }

    public borrarConteo() {
        this.contadorCuentas = 0;
        this.contadorSeleccion = 0;
        this.cuentasSeleccionadas = 0;
        this.asignarSeleccion =  true;
    }

    public borrarSeleccion(){
       this.borrarConteo();     
        this.mapaCuentas.borrarSeleccion();
    }


    public showMap(mostrar: boolean): void {
        this.accounts= [];
        this.filtros.mostrarMapa = true;
        this.consultarCuentasMapa(0, 1);
    }

    public consultarCuentas() {
        this.borrarConteo();
        this.accounts = [];
        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
        this.filtros.mostrarMapa = false;
        this.dibujando = false;
        this.eventoCreado = false;
        //this.loadAccounts();
    }

  

    public tooglefilterAgentAvailable() {
        this.agenteFilterInput.searchService.reset();
        this.agenteFilterInput._element.nativeElement.firstChild.firstChild.focus();
    }

    public agenteFilterSearch = function (term: string, seleccionarPrimero: boolean = false) {
        return this.agenteServ.obtenerAgentes(term)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idCobrador, nombreCompleto, idAgente }) => (
                            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                        )
                    ))
                );
    }.bind(this);

    public asignarAgenteFilterSearch = async (term: string) => {
        if (this.asignarAgenteFilterFlag) {
            this.asignarAgenteFilterFlag = false;
            return this.asignarAgenteFilterFound;
        }

        this.asignarAgenteFilterInput._element.nativeElement.firstChild.firstChild.blur();
        return new Promise<any>(resolve => {
            this.agenteServ.obtenerAgentes(term)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idCobrador, nombreCompleto }) => (
                            { idCobrador: idCobrador, nombre: idCobrador + ' - ' + nombreCompleto }
                        )
                    ))
                );
        });
    }

    onAgenteFilterSelected(agente: any) {
        this.agenteFilterFlag = true;
        this.filtros.idCobrador = agente.idAgente;
    }

    onAsignarAgenteFilterSelected(agente: any) {
        this.asignarAgenteFilterFlag = true;
        //this.filtros.idCobradorAsignado = agente.idCobrador;
        this.formAsignacion.get("idCobradorAsignado").patchValue(agente.idCobrador);
    }

    resetAgenteFilter() {
        this.filtros.idCobrador = null;
    }

    resetAsignarAgenteFilter() {
        this.filtros.idCobradorAsignado = null;
        this.asignarAgenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        this.asignarAgenteFilterInput.searchService.reset();
    }

    public acreditadoFilterSearch = function (term: string, seleccionarPrimero: boolean = false) {

        return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera: null)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idAcreditado, nombreCompleto, idCliente, idExterno}) => (
                            { idAcreditado: idAcreditado, nombre: idExterno+' - '+nombreCompleto, idCliente: idCliente }
                        )
                    ))
                );
    }.bind(this);

    onAcreditadoFilterSelectedModal(acreditado: any) {
        if(acreditado){
            this.acreditadoFilterFlag = true;
            this.filtrosModal.idAcreditado = acreditado.idAcreditado;
            this.filtrosModal.idCliente = acreditado.idCliente;
        }
        else{
            this.acreditadoFilterFlag = false;
            this.filtrosModal.idAcreditado = null;
            this.filtrosModal.idCliente = null;
        }
        
    }

    onAcreditadoFilterSelected(acreditado: any) {
        if(acreditado){
            this.acreditadoFilterFlag = true;
            this.filtros.idAcreditado = acreditado.idAcreditado;
            this.filtros.idCliente = acreditado.idCliente;
        }
        else{
            this.acreditadoFilterFlag = false;
            this.filtros.idAcreditado = null;
            this.filtros.idCliente = null;
        }
        
    }

    resetAcreditadoFilter() {
        this.filtros.idAcreditado = null;
        this.filtros.idCliente = this.sessionData.isSuperAdmin ? null : this.filtros.idCliente;
        this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.value = '';
    }

    public grupoFilterSearch = function (term: string, seleccionarPrimero: boolean = false) {
        let uri = '/clientes-grupos-grid'

        uri += `?nombre=${term}`;
        if (this.filtros.financiera.idFinanciera) {
            uri += `&idFinanciera=${this.filtros.financiera.idFinanciera}`;
        }

        return this.visitService.get(uri, true)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.grupoFilterFounded = res;

                    setTimeout(() => {
                        if (seleccionarPrimero) {
                            this.grupoFilterInput._element.nativeElement.firstChild.firstChild.value = this.grupoFilterFounded[0].nombre;
                        }
                        else {
                            this.grupoFilterInput._element.nativeElement.firstChild.firstChild.focus();
                        }
                    }, 200);
                }, (err: any) => {
                    this.app.showError(err);
                });
    }.bind(this);

    onGrupoFilterSelected(grupo: any) {
        this.grupoFilterFlag = true;
        this.filtros.idGrupo = grupo.id;
    }

    resetGrupoFilter() {
        this.filtros.idGrupo = null;
        this.grupoFilterInput._element.nativeElement.firstChild.firstChild.value = '';
    }

    resetFinancieraFilter() {
        this.filtros.financiera = null;
        this.filtros.excludeFinancierasDemo = true;
        this.idLender = this.sessionData.idFinanciera;
        this.onChangeLender(this.idLender);
    }

    resetFilterByName(controlName) {
        this.filtros[controlName] = null;
    }

    loadEstados(lanzarAccounts: boolean = false): void {
        this.loadings.estados = true;
        this.estados = [];
        this.stateService.get().subscribe(
            (res: any) => {
                this.estados = res;
                this.loadings.estados = false;
            },
            (err: any) => {
                this.app.showError(err);
                this.loadings.estados = false;
            }
        );
    }

    loadMunicipios(lanzarAccounts: boolean = false): void {
        var query: string = '';
        this.loadings.municipios = true;
        this.municipios = [];
        if (this.filtros.idEstado) {
            query = `?idEstado=${this.filtros.idEstado}`

            this.municipalityService.all(query).subscribe(
                (res: any) => {
                    this.municipios = res;
                    this.loadings.municipios = false;
                    if (lanzarAccounts) {
                        this.loadEstados(lanzarAccounts);
                    }
                },
                (err: any) => {
                    this.app.showError(err);
                    this.loadings.municipios = false;
                }
            );
        }
        else {
            this.loadings.municipios = false;
        }
    }

    cargarEtiquetasCuenta() {
        if (this.filtros.financiera) {
            this.loadingEtiquetasCuenta = true;
            this.visitService.obtenerEtiquetasCuenta(this.filtros.financiera.idCliente)
                .pipe(
                    take(1)
                ).subscribe((respuesta: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.etiquetasCuenta = respuesta.data;
                }, (err: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.app.showError(err);
                });
        }
    }

    public restaFechas(f1, f2) {
        var aFecha1 = f1.split('/');
        var aFecha2 = f2.split('/');
        var fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0]);
        var fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
        var dif = fFecha2 - fFecha1;
        var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
        return dias;
    }

    public outputEmiter(seleccionadas: number) {
        this.contadorSeleccion = seleccionadas;
        this.cuentasSeleccionadas = this.contadorCuentas + this.contadorSeleccion;
        

        if(this.cuentasSeleccionadas > 300) {
            this.asignarSeleccion =  false;
            this.app.showSnackbar("Aviso", "No se permite seleccionar más de 300 cuentas, favor de reducir tu selección.", 6000, 'warning');
        }else {
            this.asignarSeleccion =  true;
        }
        
    }

    public vizulizarCuentaAgente(limpiarAsignarTodas: boolean = true): void {

        if (limpiarAsignarTodas) {
            this.asignarTodasLasCuentas = false;
        }

        let cuentasAsignar = this.asignarTodasLasCuentas ? this.accounts : this.accounts.filter((cuenta) => cuenta.selected);

        if (!this.filtros.mostrarMapa || (cuentasAsignar.length <= 300)) {
            this.visualizarCuentasDialog();
        }
        else {
            this.app.showSnackbar('Aviso', 'No se pueden asingar más de 300 cuentas en el mapa, por favor remueve algunos registros o reduce tu búsqueda.', 3000, 'warning');
        }
    }

    visualizarCuentasDialog(): void {
        let accounts: Account[] = this.getSelectedAccounts();
        
        if (accounts.length === 0) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar por lo menos 1 cuenta.",
                3000,
                "warning"
            );
            return;
        }

        const dialogRef = this.dialog.open(VisualizarCuentasDialog, {
            width: '30%',
  		    //height: '800px',
            data : {
                isSuperAdmin: this.sessionData.isSuperAdmin,
                idCliente: this.sessionData.idCliente,
                accounts: accounts
            } 
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result){

                if(result.agentesInvalidos.length > 0 || result.productoNoVinculado.length > 0){
                    this.agentesNoVinculados(result);
                }
                this.borrarSeleccion();
            }
        });
    }

    agentesNoVinculados(data:any) {
      const dialogRef = this.dialog.open(GestoresNoVinculadosDialog, {
        data : {
            agentesInvalidos: data.agentesInvalidos,
            productoNoVinculado: data.productoNoVinculado
        } 
      });

      dialogRef.afterClosed().subscribe(result => {});
    }

    /*---aqui empieza el nuevo codigo*/

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !== this.accountPaginator.pagination.currentPage || newPagina.pageSize !== this.accountPaginator.pagination.perPage){
            this.accountPaginator.pagination.perPage = newPagina.pageSize;
            this.cargarCuentas(newPagina.pageIndex+1);
        }
    }

  public cargarCuentas(page?: number) {
        this.totalFiltros = 0

         this.contabilizadorDeFiltrosTotales();
        if ( this.totalFiltros < 1){
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return;
        }

        this.cargandoCuentas = true;
        ///let cargando = this.app.showLoading('Cargando cuentas...');
        let idCliente;
        let filtrosQuery: string = '&rows=' + this.pageSize + '&page=' + page + '&slt='+ this.sltCuentas 
        filtrosQuery += '&orderBy=acv.id_agente asc';
        this.accountPaginator.pagination.nextPage = page || 1;

        if (page != this.pagina) {
            this.pagina = page;
            this.filtrosService.guardarFiltros(this.filtrosModal);
        }
  
        if(this.filtrosModal){
            this.idCobradorModal =  this.filtrosModal.idCobrador;
            this.idExternoModal = this.filtrosModal.idExterno;
            this.idFinancieraModal = this.filtrosModal.idFinanciera;
            idCliente = this.filtrosModal.idCliente;
            this.totalFiltros++
    
            if(this.filtrosModal.idAcreditado){
                filtrosQuery +=`&idAcreditado=${this.filtrosModal.idAcreditado}`;
                this.totalFiltros++
            }
            
            if(this.filtrosModal.idAgente){
                filtrosQuery +=`&idAgente=${this.filtrosModal.idAgente}`;
                this.totalFiltros++
            }
        }

        
        if(!idCliente){
            idCliente = this.sessionData.idCliente;
        }

        this.contabilizadorDeFiltrosTotales();
      
        this.visitService.cargarCuentas(idCliente, filtrosQuery, this.accountPaginator.pagination,  this.pageSize).subscribe(
            (paginator: AccountPaginator) => {
                this.accountPaginator = paginator;
                this.cuentas = paginator.data;
                //this.app.hideLoading(cargando)
            },
            (error) => {
                this.cargandoCuentas = false;
                this.app.showError(error);
                //this.app.hideLoading(cargando)
            },
            () => {
                this.cargandoCuentas = false;
                //this.app.hideLoading(cargando)
                //subscription.unsubscribe();
            }
        );
    }
    
  public onSelected( rows ){
    this.itemsSelected = rows.slice();

    if(this.itemsSelected.length > 0) {
      this.bloquearEliminar = true;
    }else{
      this.bloquearEliminar = false;
    }
  }

  public onlink({ row, columnPressed }){

  }

    onClick({ row, columnPressed }) {
        if(columnPressed === 'idExterno' || columnPressed === 'subIdExterno') {
            this.linkDetalleCuenta(row);
        }
    }

    generarDetalleQueryParams(cuenta: any){
        let queryParams = '';
        if(this.filtrosModal.idCliente && this.sessionData.isSuperAdmin){
            queryParams=`?idCliente=${this.filtrosModal.idCliente}`;
        }

        if(cuenta.idExterno){
            if(queryParams){
                queryParams+=`&idExterno=${cuenta.idExterno}`;
            }
            else{
                queryParams=`?idExterno=${cuenta.idExterno}`;
            }
        }

        if(cuenta.subIdExterno){
            if(queryParams){
                queryParams+=`&subIdExterno=${cuenta.subIdExterno}`;
            }
            else{
                queryParams=`?subIdExterno=${cuenta.subIdExterno}`;
            }
        }
        return queryParams;
    }

    linkDetalleCuenta(cuenta: any) {
        this.route.navigateByUrl('/agentes-visibilidad/cuenta' + this.generarDetalleQueryParams(cuenta));
    }

    onCtrlClick({ row, columnPressed }) {
        if(columnPressed === 'idExterno' || columnPressed === 'subIdExterno') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }

    ctrlClickLinkDetalleCuenta(cuenta: any) {
        window.open('/agentes-visibilidad/cuenta' + this.generarDetalleQueryParams(cuenta), '_blank');
        
    }


  public eliminarCuenta(): void {
    
    const loading: number = this.app.showLoading('Actualizando cuenta.');
    this.visitService.eliminarCuentasVisibles(this.sessionData.idCliente, this.itemsSelected).subscribe(
        res => {
            this.app.hideLoading(loading);
            if (res.success) {
              this.cargarCuentas(this.pagina);

                this.app.showSnackbar(
                    "Aviso",
                    "La cuentas se eliminaron correctamente.",
                    3000,
                    "success"
                );
            } else {
                this.app.showSnackbar(
                    "Aviso",
                    "Ocurrio un Error: " + res.error.message,
                    5000,
                    "error"
                );
            }
        },
        error => {
            this.app.showError(error);
            this.app.hideLoading(loading);
        }
    );
  }

  openDialogConfirmacion(): void {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
            titulo: "Confirmar",
            mensaje: `¿Deseas eliminar los registros seleccionados?`,
            icono:"delete_forever",
            boton1: "No",
            boton2: "Sí",
            claseAccion: "boton-accion-eliminar"
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.eliminarCuenta()
        }   
    });
  }

  openDialogFiltros(): void {

    /*const dialogRef = this.dialog.open(ModalFiltrosCuentaComponent, {
        //height: '400px',
        //width: '500px',
        //disableClose: true,
        data:{
                opcion: 1,
                financieras: this.lenders,
                idCobrador: this.idCobradorModal,
                idExterno: this.idExternoModal,
                idFinanciera: this.idFinancieraModal
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.filtrosModal = result;
            this.cargarCuentas(this.pagina);
            
        }   
    });*/
  }

  mostrarMapa(){
    this.filtros.mostrarMapa = true;
    //this.filtrosService.guardarFiltros(this.filtros);
    //this.accounts = [];
    this.contabilizadorDeFiltrosTotales();
  }

  ocultarMapa(){
    this.ayuda = false;
    this.borrarSeleccion();
    this.filtros.mostrarMapa = false;
    this.dibujando = false;
    this.eventoCreado = false;
    if(!this.aplicoFiltros) {
        this.accounts = [];
    }
   //this.accounts = [];
    //this.filtrosService.guardarFiltros(this.filtros);
    //this.cargarCuentas(this.pagina);
    this.contabilizadorDeFiltrosTotales();
  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  inactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.financiera){
        this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
    
  }
}

