import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../application/shared/application.service";
import { VisitService } from "../visit.service";
import { take } from 'rxjs/operators';
@Component({
    selector: 'app-acreditado-dialog',
    templateUrl: 'acreditado-dialog.component.html',
    styleUrls: ['acreditado-dialog.component.css'],
    providers: [
        VisitService, 
    ]
  })
  export class AcreditadoDialog implements OnInit {
    public acreditadoSeleccionado: any;
    public acreditados = [];
    cargando: boolean = true;
    public tablaAcreditados: any;
    constructor(
        private app: ApplicationService,
        public dialogRef: MatDialogRef<AcreditadoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private visitService: VisitService
    ) {
        //this.acreditados = data;
        this.acreditados = [];
        this.crearTabla();
    }
    
    ngOnInit() {
        this.obtenerAcreditados();
    }

    crearTabla(){
        this.tablaAcreditados= {
            headers: false,
            tableHeaders: false,
            multiSelect: false,
            columnas: [
                {
                    name: 'ID acreditado',
                    key: 'idAcreditado',
                    hide: true
                }, {
                    name: 'ID CuentaPG',
                    key: 'idCuenta',
                    hide: true
                }, {
                    name: 'ID Cuenta',
                    key: 'idExterno',
                    hide: false
                }, {
                    name: 'ID Subcuenta',
                    key: 'subIdExternoCliente',
                    hide: false
                }, {
                    name: 'Producto',
                    key: 'nombreProducto',
                    hide: false
                }, {
                    name: 'Nombre',
                    key: 'nombreCompleto',
                    hide: false
                }, {
                    name: 'Dirección',
                    key: 'selectDomicilio',
                    hide: false
                }, {
                    name: 'ID Domicilio Acreditado',
                    key: 'idDomicilioAcreditado',
                    hide: true
                } , {
                    name: 'latitud',
                    key: 'latitud',
                    hide: true
                } , {
                    name: 'longuitud',
                    key: 'longuitud',
                    hide: true
                }
            ]
        }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }


     onSelected(row) {
        this.acreditadoSeleccionado = row[0];        
    }

    onDone(){
        if(this.acreditadoSeleccionado){
            this.dialogRef.close(this.acreditadoSeleccionado);
            /*if(!this.acreditadoSeleccionado.latitud || !this.acreditadoSeleccionado.longuitud ) {
                this.app.showSnackbar(
                    "Aviso",
                    "El domicilio seleccionado no se encuentra geocalizado.",
                    3000,
                    "warning"
                );
                return;
            }else {
                this.dialogRef.close(this.acreditadoSeleccionado);
            } */      
        }
    }

    obtenerAcreditados() {
        //const textoAcreditado = this.acreditadoFilterInput.nativeElement.value.toString();
        
        this.acreditados= [];
        if(this.data.idAcreditado){
             this.visitService.obtenerAcreditados(this.data.idAcreditado, this.data.idCliente)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {

                for (let i = 0; i < respuesta.data.length; i++) {
                    let direccion: string;
                    direccion = (respuesta.data[i].calle ? respuesta.data[i].calle +', ' : '') +
                                                (respuesta.data[i].numeroExterior ? respuesta.data[i].numeroExterior +', ' : '') +
                                                (respuesta.data[i].numeroInterior ? respuesta.data[i].numeroInterior +', ' : '') +
                                                (respuesta.data[i].colonia ? respuesta.data[i].colonia +', ' : '') +
                                                (respuesta.data[i].municipio ? respuesta.data[i].municipio+', ' : '') +
                                                (respuesta.data[i].estado ? respuesta.data[i].estado +'.' : '') ;
                    respuesta.data[i].selectDomicilio = respuesta.data[i].folioDomicilio + ' - ' + direccion;
                }
                this.acreditados = respuesta.data;
                this.cargando = false;
                // if(this.acreditados.length === 0){

                //     this.app.showSnackbar(
                //         "Aviso",
                //         "No se encontro información para el acreditado, favor de verificar su busqueda.",
                //         6000,
                //         "warning"
                //     );
                //     return;

                // }else {
                //     this.openDialog(this.acreditados);
                // }

            }, (error: any) => {
                this.cargando = false;
                this.app.showError(error);

            });
        }else{
            this.cargando = false;
            this.app.showSnackbar('¡Aviso!', 'Favor de ingresar un criterio de búsqueda', 3000, 'warning');
        }


    }

  }