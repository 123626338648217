import { Component, OnInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UsuarioService } from './usuario.service';
import { LenderService } from '@lender/lender.service';
import { IUsuario } from './usuarios.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormControl } from '@angular/forms';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { take } from 'rxjs/internal/operators/take';
import { Lender } from '@lender/lender.model';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

declare var $: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [
    LenderService
  ]
})
export class UsuariosComponent implements OnInit, OnDestroy {
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
  public filtrosAbiertos: boolean = false;
  private querySub: Subscription;
  sessionData: SessionData;
  public selectedRow: IUsuario;
  public lenders: Lender[];
  inactivando: boolean;
  public ayuda: boolean = false;
  public hijosActivos: boolean = false;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: []
  };

  public datosConsulta: any = {
    pageSize: 100,
    rows: 50,
    sortField: 'idUsuario',
    sortAsc: true,
    query: "",
    fromRowPage: 0,
    toRowPage: 50
  };

  constructor(
    public usuServ: UsuarioService,
    private route: ActivatedRoute,
    private app: ApplicationService,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private dialog: MatDialog,
    private rootElement: ElementRef
  ) { 
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
    this.tabla.columns = [
      {
          name: 'ID Usuario',
          key: 'idUsuario',
          hide: true
      },
      {
          name: 'Nombre',
          key: 'nombreCompleto',
          hide: false
      },
      {
          name: 'Cliente',
          key: 'financiera',
          hide: this.sessionData.isSuperAdmin ? false : true
      },
      {
          name: 'Email',
          key: 'email',
          hide: false
      },
      {
          name: 'Tipo Permiso',
          key: 'tipoPermiso',
          sortName: 'isSuperAdmin',
          hide: false
      },
      {
          name: 'Estatus',
          key: 'estatus',
          sortName: 'deleted',
          hide: false,
          type: 'estatus'
      }
    ];
  }

  ngOnInit() {
    this.obtenerClientes();
    this.usuServ.limpiarFiltros();
    this.usuServ.filtrosService.obtenerFiltrosIniciales(this.usuServ.filtros, this.suscribeParams.bind(this));
  }

  private agregarContabilizadoresDeFiltros(){
   
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'nombre',
        'apellidoPaterno',
        'apellidoMaterno',
        'email',
        'activo',
        'idFinanciera'
       
    ]);

}

public contabilizadorDeFiltros(filtroName: string){
  return this.contabilizadorFiltros.countActiveFilters(filtroName, this.usuServ.filtros);
}


public contabilizadorDeFiltrosTotales():void{

    let totalFiltrosArray: number[] = [];
   
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  private suscribeParams(filtrosNuevos: any): void{
    var loadPromise = false;

    if(filtrosNuevos){
      loadPromise = true;
      this.usuServ.filter = true
      this.usuServ.filtros = filtrosNuevos;
      this.usuServ.filtros.filtered = true;
    }

    this.usuServ.getGrid(this.usuServ.filtros.page || 1);
  }

  ngOnDestroy() {

  }

  //INICIO EVENTOS TABLA
  onSelected(rows) {
    this.selectedRow = rows.slice()[0];
  //  console.log(this.selectedRow);
  }

  /*onLinkRow({ row, columnPressed }) {
      if (columnPressed === 'nombreSucursal') {
          this.detalleSucursal(row, false);
      }
  }

  onDoubleClick({ row }) {
      this.detalleSucursal(row, false);
  }*/

  sortChange(sort: any) {
      //console.log(sort);
      if (sort.active == this.datosConsulta.sortField) {
          this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
      } else {
          this.datosConsulta.sortField = sort.active;
          this.datosConsulta.sortAsc = true;
      }
      this.usuServ.getGrid(1);
  }

  filtersChange(values) {
  }

  searchChange(text) {
  }
  //FIN EVENTOS TABLA

  /*onPage(pagina: number): void {
    if (pagina !== this.usuServ.pagination.page) {
      this.setQueryParam(pagina);
      this.usuServ.getGrid(pagina);
    }
  }*/

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.usuServ.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
        this.datosConsulta.rows = newPagina.pageSize;
        this.setQueryParam(newPagina.pageIndex+1);
        this.usuServ.getGrid(newPagina.pageIndex+1);
    }
  }

  setQueryParam(page: number) {
    this.usuServ.filtros.page = page;
    this.usuServ.saveParams();
  }

  obtenerClientes(): void {
    this.lenderService.getLenders().subscribe(
      (res: any) => {
        this.lenders = res.data;
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }


  public filtrar(page: number){
    this.usuServ.filtros.filtered = true;
    this.usuServ.filtrosService.guardarFiltros(this.usuServ.filtros);
    this.usuServ.getGrid(page);
    this.contabilizadorDeFiltrosTotales();
  }

  public limpiarFiltros(){
    this.usuServ.clearFilters();
    //this.usuServ.getGrid(1);
  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  onEdit() {
    if (this.selectedRow) {
      //this.usuServ.setUsuarioData(this.selectedRow);

      //console.log(this.selectedRow);
      this.router.navigate(
        ['editar', { 'id': this.selectedRow.idAdministradorFinanciera }],
        {
          relativeTo: this.route,
          queryParamsHandling: 'preserve'
        }).then(() => {
          this.selectedRow = undefined;
        });
    } else {
      this.app.showSnackbar('¡Aviso!', 'Debes seleccionar un registro para editarlo.', 3000, 'warning');
    }
  }

  onDelete() {
    if (this.selectedRow) {
      if(this.filtrosAbiertos)
      {
          this.filtrosSideNav.toggle();
          this.filtrosAbiertos = !this.filtrosAbiertos;
      }
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
          data:{
            titulo: "Confirmar",
            mensaje: `¿Deseas inactivar el administrador "${this.selectedRow.nombre} ${this.selectedRow.apellidoPaterno || ""} ${this.selectedRow.apellidoMaterno || ""}"?`,
            icono: "delete_forever",
            colorIcono: "warn",
            boton1: "No",
            boton2: "Sí",
            //texto: 'Confirmación',
            claseAccion: "boton-accion-eliminar"
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.eliminarRegistro();
          }   
      });
    }
    else{
      this.app.showSnackbar('¡Aviso!', 'Debes seleccionar un registro para inactivarlo.', 3000, 'warning');
    }
  }

  eliminarRegistro() {
    this.inactivando = true;
    this.selectedRow.deleted = 1;

    this.usuServ.eliminar(this.selectedRow.idAdministradorFinanciera)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.app.showSnackbar('¡Aviso!', 'Usuario borrado correctamente.', 3000, 'success');
        this.selectedRow = undefined;

        const page = +this.route.snapshot.queryParamMap.get('_page') || 1;
        this.usuServ.getGrid(page);
        this.inactivando = false;
      }, (error: any) => {
        this.app.showError(error);
        this.inactivando = false;
        this.selectedRow.deleted = 0;
      });
  }

  onDblClick(usuario: IUsuario) {
    this.selectedRow = usuario;

    this.onEdit();
  }

  openFilterModal() {
    if(this.filtrosAbiertos)
    {
        this.filtrosSideNav.toggle();
        this.filtrosAbiertos = !this.filtrosAbiertos;
    }
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
            titulo: "Confirmar",
            mensaje: "¿Deseas inactivar el administrador seleccionado?",
            icono: "delete_forever",
            colorIcono: "save",
            boton1: "No",
            boton2: "Sí",
            texto: this.selectedRow.nombre,
            claseAccion: "boton-accion-guardar"
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
          Object.keys(this.usuServ.filtros).forEach(key => {
            if(key != 'page' && this.usuServ.filtros[key]){
              this.usuServ.filter = true;
            }
          });
  
          if (this.usuServ.filter) {
            this.usuServ.filtros.page = 1;
            this.usuServ.saveParams();
            this.usuServ.getGrid(this.usuServ.filtros.page);
          }
        } 
        else{
          if (result === 'closed' || !this.usuServ.filter) {
            return;
          }
  
          this.usuServ.filter = false;
          this.usuServ.clearFilters();
          this.usuServ.getGrid(1);
        }  
    });
  }

  onDoubleClick({ row }) {
    this.onEdit();
  }

  inactivarHijo(){
    this.usuServ.filtrosService.asignarOpcion();
    if(this.usuServ.filtros && (this.usuServ.filtros.filtered)){
      this.usuServ.filtrosService.guardarFiltros(this.usuServ.filtros, true);
    }
    this.usuServ.filtrosService.obtenerFiltrosIniciales(this.usuServ.filtros, this.suscribeParams.bind(this));
  }

  sucursalEditar(){
    this.router.navigate(['editar-sucursal'], { 
      relativeTo: this.route,
      queryParams: {
        idCliente: this.selectedRow.idCliente,
        idAdministradorFinanciera: this.selectedRow.idAdministradorFinanciera,
        idClienteAdministrador: this.selectedRow.idClienteAdministrador,
        nombreAdmin: this.selectedRow.nombreCompleto,
        //nombreCliente: this.selectedRow.financiera
      },
      queryParamsHandling: 'merge'
    });
  }

  productoEditar(){
    this.router.navigate(['editar-producto'], { 
      relativeTo: this.route,
      queryParams: {
        idCliente: this.selectedRow.idCliente,
        idClienteAdministrador: this.selectedRow.idClienteAdministrador,
        nombreAdmin: this.selectedRow.nombreCompleto,
        //nombreCliente: this.selectedRow.financiera
      },
      queryParamsHandling: 'merge'
    });
  }

}
