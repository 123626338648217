// loading-bar.component.ts
import { Component } from '@angular/core';
import { LoadingService } from './loading-bar.service';

@Component({
  selector: 'app-loading-bar',
  template: `
    <div *ngIf="loading$ | async" class="loading-bar">
    </div>
  `,
  styles: [`
    .loading-bar {
        width: 30%; 
        height: 3px;
        background-color: #00c977;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        animation: bounce  2s linear infinite; 

    }
    @keyframes bounce {
        0%, 100% { transform: translateX(0%); } 
        50% { transform: translateX(calc(100vw - 100%)); } 
   
      }
  `]
})
export class LoadingBarComponent {
  loading$ = this.loadingService.getLoading();

  constructor(private loadingService: LoadingService) {}
}
