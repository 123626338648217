<h1 mat-dialog-title>Desvinculación Masiva</h1>
<mat-dialog-content>
  <alerta-informativa 
    clase="danger"
    titulo="¡Aviso!" 
    texto="Al desvincular a los agentes, se eliminarán todas las asociaciones con los clientes y productos.">
  </alerta-informativa>
  <div>
    <mat-select-search
        class="id-card-field"
        [label]="'Cliente'"
        [placeHolder]="'Selecciona un cliente'"
        [value]="'idCliente'"
        [labelField]="'nombre'"
        [icon]="'account_balance'"
        [options]="data.lenders"
        [(ngModel)]="idCliente"
        (ngModelChange)="onchageCliente($event)"
        [disabled]="isClienteBloqueo"
      >
      </mat-select-search>
  </div>
  <section>
    <mat-checkbox (ngModelChange)="onchageMasivo($event)" [disabled]="isCheckBloqueo" class="kobra-checkbox" [(ngModel)]="desvinculacionMasiva">Todos los clientes</mat-checkbox>  
  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button [disabled]="validar()" [ngClass]="{'boton-guardar': !validar()}" (click)="desvincular()"><mat-icon>save</mat-icon>Guardar</button>
</mat-dialog-actions>