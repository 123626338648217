<div class="app content kobra" *ngIf="!transitionEffect"> 
  <div class="centrado">
    <mat-card class="card-center anchoCard">
      <div class="contendor"> 
        <div class="item pading-panel-1" >
          <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile>
              <!-- <img class="logo-login" src="assets/img/logo.png" alt=""> -->
              <strong style="font-size: 40px; line-height: 1;">¡Hola, mundo!</strong>
            </mat-grid-tile>
          </mat-grid-list>
          
          <form [formGroup]="form" (submit)="logIn()">
            <mat-form-field class="width100">
              <mat-label>Correo electrónico</mat-label>
              <input class="login-input" name="username" id="username" #search matInput textSanitizer  placeholder="Capture su dirección de correo" formControlName="correo" [formValidationInput]="correoVld">
              <mat-hint></mat-hint>
              <mat-error *ngIf="form.controls['correo'].hasError('email') && !form.controls['correo'].hasError('required')">
                Introduce un correo valido
              </mat-error>
              <mat-error *ngIf="form.controls['correo'].hasError('required')">
                Dirección de correo es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          
            <mat-form-field class="width100">
              <mat-label>Contraseña</mat-label>
              <input class="login-input" matInput textSanitizer  placeholder="Capture su contraseña" formControlName="contrasena" [formValidationInput]="contrasenaVld" [type]="hide ? 'password' : 'text'">
              <mat-hint></mat-hint>
              <mat-error *ngIf="form.controls['contrasena'].hasError('contrasena') && !form.controls['contrasena'].hasError('required')">
                Introduce tu contraseña
              </mat-error>
              <mat-error *ngIf="form.controls['contrasena'].hasError('required')">
                Contraseña es <strong>requerida</strong>
              </mat-error>
              <mat-icon class="ver-password kobra-icon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
          
            <mat-form-field class="width100">
                  
              <mat-label for="">Selecciona tú país</mat-label>
              <mat-select [(ngModel)]="paisActual" [ngModelOptions]="{standalone: true}" (ngModelChange)="cambioPais()" >
                <mat-option *ngFor="let pais of paises" [value]="pais">
                  <img [src]="pais.img" style="height: 15px;"/> {{pais.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          
            <div class="login-button-row">
              <button  mat-stroked-button class="boton-guardar" [ngClass]="{ 'buton-disabled' : loading}" [disabled]="loading">Iniciar sesión</button>
            </div>
          </form>
        </div>
        <div class="item relleno centrado">
          <img style="width:50%" src="assets/img/Logo_kobra.png" alt="">
        </div>
      </div>
      
    </mat-card>
    <div class="footer">
      <mat-grid-list cols="1" rowHeight="35px">
        <mat-grid-tile>
          &copy; {{year}} Kobra - Todos los derechos reservados.
        </mat-grid-tile>
        <mat-grid-tile>
          <a href="https://kobra.red/aviso-de-privacidad#privacy" target="_blank">Política de privacidad <mat-icon class="login_open">open_in_new</mat-icon></a>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>


