import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// Directives
import { FormValidationClassDirective } from './form-validation/form-validation-class.directive';
import { FormValidationInputDirective } from './form-validation/form-validation-input.directive';
import { FormValidationMessageDirective } from './form-validation/form-validation-message.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
  ],
  declarations: [
    FormValidationClassDirective,
    FormValidationInputDirective,
    FormValidationMessageDirective
  ],
  exports: [
    // Modules
    ReactiveFormsModule,
    // Directives
    FormValidationClassDirective,
    FormValidationInputDirective,
    FormValidationMessageDirective

  ]
})
export class CoreModule { }
