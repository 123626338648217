import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ImportService } from '../import.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Product } from '../models/product.model';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { FiltrosService } from 'src/app/application/shared/filtros.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [
    ImportService
  ]
})
export class ProductComponent implements OnInit {
  form: FormGroup;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() idCliente: number;
  isLoad = true;


  PRODUCT_TYPES = {
    COLLECT: 1,
    VALIDATE: 2,
    RECEIVE: 3,
    ORIGINATE: 4
  };

  products: Product[] = [];
  public filters: any = {
    financiera: null,
    nombre: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  };

  constructor(
    private formBuilder: FormBuilder,
    private importService: ImportService,
    private localStorageService: LocalStorageService,
    private appService: ApplicationService,
    private filtrosService: FiltrosService
  ) { 
    
  }

  ngOnInit() {
    
    if(!localStorage.getItem('importacion-temp')){
      setTimeout(() => {
        //const loading = this.appService.showLoading('Cargando productos...');
        this.importService.getProducts(this.idCliente).subscribe(
          (products: Product[]) => {
            this.products = products.map(p => {
              let image = '';

              if (p.idProductType === this.PRODUCT_TYPES.COLLECT) {
                image = 'cobranza';
              } else if (p.idProductType === this.PRODUCT_TYPES.VALIDATE) {
                image = 'validacion';
              } else if (p.idProductType === this.PRODUCT_TYPES.RECEIVE) {
                image = 'recabado';
              } else if (p.idProductType === this.PRODUCT_TYPES.ORIGINATE) {
                image = 'negocio';
              }

              p.image = image;

              return p;
            });
            this.isLoad = false;
            //this.appService.hideLoading(loading);
          }, err => {
            this.isLoad = false;
            //this.appService.hideLoading(loading);
            this.appService.showError(err);
          });
      }, 200);
     
    }

    this.form = this.formBuilder.group({ id: ['', Validators.required] });

    if(!localStorage.getItem('importacion-temp')){
      this.form.valueChanges.subscribe(val => {
        const product = this.products.find(p => p.id === val.id);

        this.onChange.emit(product);
      });
    }

  }

  change(product) {
    this.form.controls['id'].setValue(product['id']);
  }

}
