import { Condicion } from "./condicion.model";

export class CondicionRespuesta {
  when: Condicion;

  constructor() {
  }

  public static map(data: any): CondicionRespuesta {
    let instance: CondicionRespuesta = new CondicionRespuesta();
    instance.when = data.when || null;

    return instance;
  }

  public static mapArray(data: any[]): CondicionRespuesta[] {
    let instances: CondicionRespuesta[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(CondicionRespuesta.map(data[i]));
    }
    return instances;
  }
}
