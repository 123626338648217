<h1 mat-dialog-title>Estado de Cuenta</h1>
<mat-dialog-content>
  <form [formGroup]="movimientosForm">
    <div class="row">
      <div class="column">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'name'"
          [icon]="'account_balance'"
          [options]="lenders"
          formControlName="idFinanciera"
          cdkFocusInitial
        >
        </mat-select-search>
      </div>
      <div  class="column">
        <mat-form-field style="width: 100%;">
          <mat-label>Mes y Año</mat-label>
          <input matInput textSanitizer  [matDatepicker]="dp" formControlName="fecha">
          <mat-hint>MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp
                          startView="multi-year"
                          (monthSelected)="setMonthAndYear($event, dp)"
                          panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  
    <h2>Columnas a visualizar</h2>
    <section class="example-section">
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colFecha" >Fecha</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colPaquete" >Paquete</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colConcepto" >Concepto</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colImporte" >Importe</mat-checkbox>
    </section>
  
    <section class="example-section">
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colFolioVisita">Folio Visita</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox"  formControlName="colIdExterno">ID Externo</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colMunicipio">Municipio</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colEstado">Estado</mat-checkbox>
      <mat-checkbox class="example-margin kobra-checkbox" formControlName="colCP">Código Postal</mat-checkbox>
    </section>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button mat-dialog-close><mat-icon class="kobra-icon">close</mat-icon> Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="descargar()"><mat-icon>cloud_download</mat-icon>Descargar</button>
</mat-dialog-actions>