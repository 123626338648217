import { ChecklistPregunta } from "./checklist-pregunta.model";

export class Checklist {
  idChecklist: number;
  numVersion: number;
  idTipoCredito: number;
  idChecklistCliente: number;
  idCliente: number;
  idFinanciera: number;
  idProducto: number;
  nombre: String;
  fecha: Date;
  activo: boolean;
  producto: String;
  desplegado: boolean;
  checklistsInactivos: Checklist[];
  preguntas: ChecklistPregunta[];

  constructor() {
    this.checklistsInactivos = [];
    this.preguntas = [];
  }

  public static map(data: any): Checklist {
    let instance: Checklist = new Checklist();
    instance.idChecklist = data.idChecklist || null;
    instance.numVersion = data.numVersion || null;
    instance.idTipoCredito = data.idTipoCredito || null;
    instance.idChecklistCliente = data.idChecklistCliente || null;
    instance.idCliente = data.idCliente || null;
    instance.idFinanciera = data.idFinanciera || null;
    instance.idProducto = data.idProducto || null;
    instance.producto = data.producto || null;
    instance.nombre = data.nombre || null;
    instance.fecha = new Date(data.fecha) || null;
    instance.activo = data.activo || false;
    instance.desplegado = false;
    instance.checklistsInactivos = data.checklistsInactivos ? Checklist.mapArray(data.checklistsInactivos) : [];
    instance.preguntas = data.preguntas ? ChecklistPregunta.mapArray(data.preguntas) : [];

    return instance;
  }

  public static mapArray(data: any[]): Checklist[] {
    let instances: Checklist[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(Checklist.map(data[i]));
    }
    return instances;
}
}
