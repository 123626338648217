export const environment = {
  production: false,
  kobraServices: {
    api: 'https://development.api.kobra.red',
    mobile: 'https://development.mobile.kobra.red',
    services: 'https://development.services.kobra.red',
    servicios: 'https://dev-apps.kobra.red/s1/lean-services/admin',
    serviciosClientes: 'https://dev-apps.kobra.red/s1/lean-services/clientes',
    serviciosSaldosKobra: "https://dev-apps.kobra.red/s1/kobra-saldos/admin",
    storage: 'https://dev-apps.kobra.red/s1/storage/admin',
    visitas: 'https://dev-apps.kobra.red/s1/visitas/admin',
    gestores: 'https://dev-apps.kobra.red/s1/gestores/admin',
    bi: 'https://development.bi.kobra.red/permisos/api',
    notifications: 'https://dev-apps.kobra.red',
    notificationsUri: '/s2/notifications'
  },
  kobraStorage: "https://dev-apps.kobra.red/s1/storage/admin",
  kobraRed: "https://development.kobra.red",
  kobraDashboard: "https://development.dashboard.kobra.red",
  kobraHelp: "https://development.ayuda.kobra.red",
  dashboardLink: "https://development.dashboard.kobra.red/site/login",
  adminLink: "http://development.admin.kobra.red/login",
  tokenMapbox: "pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg",
  saldosAccess: ['all'],
  pais: 'MX',
  country: 'mx',
  labelColonia: 'Colonia',
  labelColoniaPlural: 'Colonias',
  labelMunicipio: 'Municipio',
  labelCodigoPostal: 'Código Postal',
  labelMunicipioPlural: 'Municipios',
  labelCiudad: 'Municipio',
  labelEstado: 'Estado',
  labelEstadoPlural: 'Estados',
  labelRFC: 'RFC',
  labelCURP: 'CURP',
  labelINE: 'INE',
  labelCedula: 'Cédula Fiscal',
  labelCLABE: 'CLABE',
  labelEligeMunicipio: 'Elige un municipio',
  labelEligeEstado: 'Elige un estado',
  labelEligeColonia: 'Elige una colonia',
  labelFiltraMunicipio: 'Filtrar por municipio...',
  labelFiltraEstado: 'Filtrar por estado...',
  paisLat: 23.938338746567457,
  paisLng: -101.92833930447114,
  cubejsToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODkzOTY5ODR9.lLaY4EG_wgzy5RedTnUgXJwYJVhNDmQGDPULg7vBvI0",
  cubejsApiUrl: "https://development.bi.kobra.red/cubejs-api/v1"
};
