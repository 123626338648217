import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PaquetesSaldosService {
  public concepto: Object;
  public tiposConceptos: Observable<Object[]>;
  public loader: boolean;

  public pagination: any;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;

  public filtrosForm: FormGroup;

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    private fb: FormBuilder
  ) {

    this.pagination = {
        page: 1,
        pageData: null,
        pages: null,
        totalRows: null
    };
  }

  obtenerPaquetes(page: number, sortField: string = 'nombre', sortAsc: string = ''): Observable<any> {
    this.loader = true;

    const campos = '"idPaquete", "nombre", "precio"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${sortField}`;

    return this.saldosKobraServ.get(`/paquetes${queryParams}`);
  }

  agregarPaquete(paquete: any): Observable<any> {
    return this.saldosKobraServ.post('/paquetes', paquete);
  }

  editarPaquete(idPaquete: number, paquete: any): Observable<any> {
    return this.saldosKobraServ.put('/paquetes/' + idPaquete, paquete);
  }

  borrarPaquete(idPaquete: number): Observable<any> {
    return this.saldosKobraServ.delete('/paquetes/' + idPaquete);
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  obtenerPaquete(idPaquete: number): Observable<any> {
    return this.saldosKobraServ.get('/paquetes/' + idPaquete);  
  }
}
