import { Revisita } from "../models/revisita.model";
import { Pagination } from "../../../pagination/pagination.model";

export class RevisitaPaginator {
  pagination: Pagination;
  data: Revisita[];

  constructor() {
    this.pagination = new Pagination();
  }
}
