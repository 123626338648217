import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiKobraService } from '../../core/http/api-kobra/api-kobra.service';
import { AgentAddress } from "./agent-address.model";

@Injectable()
export class AgentAddressService {
  private apiService: string = '/v2/agents-address';
  // private headers: Headers = new Headers({
  //   'Content-Type': 'application/json',
  //   'Access-Token': localStorage.getItem('access-token')
  // });
  // private options: RequestOptions = new RequestOptions({ headers: this.headers});

  constructor(
    private http: ApiKobraService
    // private http: Http,
    // private httpErrorService: HttpErrorService
  ) { }

  public add( agentAddress: AgentAddress): Observable<AgentAddress> {
    return this.http.post(this.apiService, agentAddress).pipe(
      map(res => <AgentAddress> res.data)
    );
  }

  public update( agentAddress: AgentAddress): Observable<AgentAddress> {
    return this.http.patch(`${this.apiService}/${agentAddress.id}`, agentAddress).pipe(
      map(res => <AgentAddress> res.data)
    );
  }
}