import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lender } from '@lender/lender.model';

@Injectable({
  providedIn: 'root'
})
export class ClientPickerService {
  private clientSource: BehaviorSubject<Lender>;
  public currentClient: Observable<Lender>;

  constructor() {
  	this.clientSource = new BehaviorSubject<Lender>(null);
  	this.currentClient = this.clientSource.asObservable();
  }

  changeClient(lender: Lender) {
    this.clientSource.next(lender);
  }
}
