import {Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ApplicationService } from 'src/app/application/shared/application.service';



@Component({
    selector: 'posicion-pregunta-dialog',
    templateUrl: './posicion-pregunta-dialog.component.html',
    styleUrls: ['./posicion-pregunta-dialog.component.css']
  })
  export class PosicionPreguntaDialog {
    maxValue: number = 100;
    respuesta: Function;
    posicion: number = 1;

    constructor(
      private app: ApplicationService,
      public dialogRef: MatDialogRef<PosicionPreguntaDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.posicion = data.currentValue;
      this.maxValue = data.maxValue;
      this.respuesta = data.respuesta;
    }

    ngOnInit() {
    }

    salir(){
      this.dialogRef.close();
    }

    guardar(){
      if(this.posicion>0){
        this.respuesta(this.posicion-1);
        this.dialogRef.close();
      }
      else{
        this.app.showSnackbar('¡Aviso!', 'Es necesario capturar una posición válida.', 3000, 'warning');
      }
    }

  }
