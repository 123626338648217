import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';

import { AgentBank } from "./agent-bank.model";
import { ApiKobraService } from '../../core/http/api-kobra/api-kobra.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { HttpErrorService } from "../shared/http-error/http-error.service";

@Injectable()
export class AgentBankService {
  private apiService: string = '/v2/agents-bank-accounts';
  private servicesURL = '/cobradores';

  constructor(
    private http: ApiKobraService,
    private serviciosSaldos: ServiciosSaldosKobraService
  ) { }

  public add( agentBank: AgentBank): Observable<AgentBank> {
    return this.serviciosSaldos.post(`${this.servicesURL}/cuenta-bancaria`, agentBank).pipe(
      map(res => AgentBank.map(res.data))
    );
  }

  public update( agentBank: AgentBank): Observable<AgentBank> {
    return this.http.patch(`${this.apiService}/${agentBank.id}`, agentBank).pipe(
      map((res) => AgentBank.map( res.data))
    );
  }
}