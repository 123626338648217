import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { SessionData } from '../../shared/interfaces/session-data';
import { Product } from '../models/product.model';
import { Type } from '../models/type.model';
import { Batch } from '../models/batch.model';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { VisitService } from "../../visit/visit.service";
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from "../../application/shared/application.service";
import { MatStepper } from '@angular/material/stepper';



@Component({
  selector: 'app-import-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css'],
  providers: [VisitService],
})
export class WizardComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  product: Product;
  type: Type;
  file: File;
  batch: Batch;
  finishable: boolean = false;
  selectedIndex: number = 0;
  continueWithoutFile: boolean = false;
  importacion: any;
  private sessionData: SessionData;
  public filters: any = {
    financiera: null,
    nombre: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  };
  public idCliente: number;
  public nombreCliente: string;
  public idFinanciera: number;

  constructor(
    private visitService: VisitService,
    private app: ApplicationService,
    private router: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private filtrosService: FiltrosService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    
    this.idCliente = this.router.snapshot.params['idCliente'];
    this.idFinanciera = this.router.snapshot.params['idFinanciera'];
    //this.nombreCliente = this.router.snapshot.params['nombreCliente'];
    if(this.idCliente){
      this.obtenerCliente();
    }
    
    if(localStorage.getItem('importacion-temp')){
      this.importacion = JSON.parse(localStorage.getItem('importacion-temp'));
      if(this.importacion.financiera){
        this.idCliente = this.importacion.financiera.idCliente;
        this.idFinanciera = this.importacion.financiera.idFinanciera;
        if(this.idCliente){
          this.obtenerCliente();
        }
      }
      this.batch = <Batch> {
        id: this.importacion.idImportacion,
        totalErrors: 0,
        total: 0,
        idStatus: 4
      };
      this.type = {
        id: this.importacion.idTipoImportacion,
        name: '',
        description: ''
      };
      this.continueWithoutFile = true;
      this.selectedIndex = this.importacion.stepperIndex;
    }
  }

  onChangeProduct(product) {
    this.product = product;
  }

  onChangeType(type) {
    this.type = type;
  }

  onChangeFile(file) {
    this.file = file;
  }

  onChangeBatch(batch: Batch) {
    const total = batch.total || 0;
    const totalErrors = batch.totalErrors || 0;

    this.finishable = (total - totalErrors)>0;
    this.batch = batch;
  }

  public obtenerCliente() {
    this.visitService.obtenerCliente(this.idCliente)
        .pipe(take(1))
        .subscribe((respuesta: any) => {
          this.nombreCliente = respuesta.data[0].nombreFinanciera;
        },
            (error: any) => {
                this.app.showError(error);
            }
        );
  }

  goPrevious() {
    this.stepper.previous();
  }
  
  goNext() {
    if (this.currentStepIsValid()) {
      this.stepper.next();
    }
  }
  

  currentStepIsValid(): boolean {
   
    switch (this.selectedIndex) {
     
        case 0:
            return !!this.product;
        case 1:
            return !!this.type;
        case 2:
            return !!this.file;
        case 3:
          return (this.file || this.continueWithoutFile) && 
                  (!!this.batch) && 
                  ((this.batch.idStatus < 5 && this.batch.idStatus !== 1) || this.finishable);
        default:
            return false;
    }
  }

  onStepChanged(event: any) {
    this.selectedIndex = event.selectedIndex;
  }
}
