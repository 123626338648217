  <div class="app content kobra">
    <mat-card>
      <!-- <mat-card-header>
        <mat-card-title>{{producto.nombreCliente}}</mat-card-title>
      </mat-card-header> -->
      
      <mat-card-content class="contenido">
        <app-loader *ngIf="loadings.productos" [message]="'Obteniendo información...'"></app-loader>
        <div *ngIf="!loadings.productos">
          <mat-card style="margin-bottom: 20px;">
            <mat-card-header>
              <mat-card-title>Información Producto</mat-card-title>
            </mat-card-header>
            
            <mat-card-content>
              <div>
                <mat-label><b>Nombre</b></mat-label>
                <br>{{producto.nombre}}
              </div>
              <div style="margin-top: 20px;">
                <mat-label><b>Descripción</b></mat-label>
                <br>{{producto.descripcion}}
              </div>
            </mat-card-content>
          </mat-card>
  
          <mat-card>
            <mat-card-header>
              <mat-card-title>Administradores productos</mat-card-title>
            </mat-card-header>
            
            <mat-card-content >
              <div class="d-flex justify-content-between" style="gap: 10px; margin-bottom:-90px">
                <mat-form-field style="width: 40%;">
                  <mat-label>Administradores</mat-label>
                  <mat-select [(ngModel)]="adminCliente">
                    <mat-option *ngFor="let administrador of administradores" [value]="administrador">
                      {{administrador.nombre}} {{administrador.apellidoPaterno}} {{administrador.apellidoMaterno}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-stroked-button class="boton-accion-front" (click)="agregarAdministrador()" >
                  <mat-icon >add</mat-icon> Agregar
                </button>
              </div>
              
              <app-table
              [loading]="this.loadings.administradores"
              [columns]="tablaAdmin.columns"
              [items]="producto.administradores"
              [headers]="tablaAdmin.headers"
              [multiSelect]="tablaAdmin.multiSelect"
              (onLinkRow)="onClick($event)"
            >
            </app-table>
            </mat-card-content>
          </mat-card>
        </div>
        
        
      </mat-card-content>
      <mat-card-actions class="botones-card-derecha">
        <button class="boton-cancelar" mat-flat-button (click)="cancel()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
        <button mat-stroked-button [ngClass]="{'buton-disabled': !cambioData, 'boton-guardar': cambioData}" [disabled]="!cambioData" (click)="save()"><mat-icon>save</mat-icon>Guardar</button>
      </mat-card-actions>
    </mat-card>
  </div>
  