import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Subscription, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { EtiquetasVisitasService } from './../etiquetas-visitas.service';

@Component({
  selector: 'app-etiquetas-visitas-form',
  templateUrl: './etiquetas-visitas-form.component.html',
  styleUrls: ['./etiquetas-visitas-form.component.css'],
  providers: [
  ]
})
export class EtiquetasVisitasFormComponent implements OnInit {
  @Input() metodoCancelarGuardarPropio?: boolean = false;
  @Output() cierraComponente: EventEmitter<any> = new EventEmitter();
  public saveButton = false;
  private querySub: Subscription;
  public etiquetaForm: FormGroup;
  public loaderText: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: ApplicationService,
    public etiquetasVisitasService: EtiquetasVisitasService,
    private fb: FormBuilder,
  ) { 
    this.etiquetaForm = this.fb.group(
      {
        nombre: ['', Validators.required],
      }
    );
  }

  ngOnInit() {
  }

  checkControl(control: string) {
    return this.etiquetaForm.controls[control].invalid && this.etiquetaForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.etiquetaForm.controls[control].hasError('required') ? 'Este campo es requerido.' : '';
  }

  cancel(){
    if(this.metodoCancelarGuardarPropio){
      this.cierraComponente.emit();
    }
    else{
      this.router.navigate([`catalogos/etiquetas-visitas`], {
        queryParamsHandling: 'preserve'
      });
    }
  }

  onSubmit() {
    this.saveButton = true;
    this.app.showLoading("Guardando nueva etiqueta");
    if (this.etiquetaForm.valid) {
      this.etiquetasVisitasService.nuevaEtiqueta(this.etiquetaForm.value)
        .pipe(
          take(1)
        ).subscribe((res: any) => {
          this.saveButton = false;
          this.app.hideLoading();
          this.app.showSnackbar('¡Aviso!', 'Se creo la etiqueta correctamente', 3000, 'success');
          if(this.metodoCancelarGuardarPropio){
            this.cierraComponente.emit();
          }
          else{
            this.router.navigate([`catalogos/etiquetas-visitas`], {
              queryParamsHandling: 'preserve'
            });
          }
        }, (error: any) => {
          this.saveButton = false;
          this.app.hideLoading();
          this.app.showError(error);
        });
    } else {
      this.saveButton = false;
      (<any>Object).values(this.etiquetaForm.controls).forEach((control: FormControl) => {
        this.app.hideLoading();
        control.markAsTouched();
      });
    }
  }
}