import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from "../../application/shared/application.service";
import { VisitService } from "../../visit/visit.service";
import { Router } from '@angular/router';

@Component({
  selector: 'geocalizacion-cuentas',
  templateUrl: 'geocalizacion-cuentas.component.html',
  styleUrls: ["geocalizacion-cuentas.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalGeocalizacionCuentasComponent { 
    public cuentas = []; 
    public cargando: boolean = false;
    itemsSelected: Array<any>[] = [];
    tablaCuentas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: true,
        columnas: [
            {
                name: 'idAcreditadoCuentaDomicilio',
                key: 'idAcreditadoCuentaDomicilio',
                hide: true
            }, {
                name: 'ID cuenta',
                key: 'idExterno',
                type: 'link',
                hide: false
            }, {
                name: 'ID subcuenta',
                key: 'subIdExterno',
                hide: false
            }, {
                name: 'Folio visita',
                key: 'folioVisita',
                type: 'link',
                hide: false
            }, {
                name: 'Folio domicilio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Dirección',
                key: 'domicilio',
                hide: false
            }, {
                name: 'Cuenta coordenadas',
                key: 'coordenadaCuenta',
                type: 'link',
                hide: false
            }, {
                name: 'Visita coordenadas',
                key: 'coordenadaVisita',
                type: 'link',
                hide: false
            }
        ]
    }

    public actualizar: boolean = false;
    public ayuda: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalGeocalizacionCuentasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private visitService: VisitService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.cuentas = [];
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

    ngOnInit() {
        this.loadAccounts();
    }

    public onSelected( rows ){
        this.itemsSelected = rows.slice();
    }

    loadAccounts() {
        this.cargando = true;
    
        this.visitService.obtieneAcreditadosCoordenadas(this.data.idCliente, this.data.id).pipe(take(1)).subscribe(
            (data: Array<any>) => {
    
              data.forEach((row, idx) => {
                let queryString = '';
                data[idx].domicilio = (data[idx].calle ? data[idx].calle +' ' : '') +
                            (data[idx].numeroExt ? data[idx].numeroExt +', ' : '') + 
                            (data[idx].numeroInt ? data[idx].numeroInt +', ' : '') + 
                            (data[idx].colonia ? data[idx].colonia +' ' : '') +
                            (data[idx].colonia ? data[idx].codigoPostal +', ' : '') +
                            (data[idx].municipio ? data[idx].municipio +', ' : '') +
                            (data[idx].estado ? data[idx].estado +'.' : '') ;
                data[idx].coordenadaCuenta = data[idx].latitud ? `${data[idx].latitud} , ${data[idx].longuitud}` : 'No disponible';
                data[idx].coordenadaVisita = data[idx].latitudVisita ? `${data[idx].latitudVisita} , ${data[idx].longuitudVisita}` : 'No disponible';
                
                if(data[idx].idSubCuenta != null){
                    queryString = "&subIdExterno="  + data[idx].subIdExterno;
                };

                data[idx].urlDetalleCuenta = `/cuentas/domicilios/account?idExterno=${data[idx].idExterno}&idCliente=${data[idx].idCliente}${queryString}`;

              });
              
              this.cuentas = data;
              console.log(this.cuentas);
              this.cargando = false;
            }, err => {
                this.cargando = false;
                this.app.showError(err);
            });
      }

    public actualizaCoordenadas(): void {

        if (this.itemsSelected.length == 0) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar por lo menos una cuenta.",
                3000,
                "warning"
            );
            return;
        }else {
            this.actualizar = true;
            this.visitService.actualizaCoordenadas(this.data.idCliente, this.itemsSelected).subscribe(
                res => {
                    if (res.success) {
                       this.loadAccounts();
                       this.dialogRef.close(true);
                        this.app.showSnackbar(
                            "Aviso",
                            "Las ubicación se actualizó correctamente.",
                            3000,
                            "success"
                        );
    
                    } else {
                        //this.dialogRef.close(false);
                        this.app.showSnackbar(
                            "Aviso",
                            "Ocurrio un Error: " + res.error.message,
                            5000,
                            "error"
                        );
                    }
                    this.actualizar = false;
                },
                error => {
                    this.app.showError(error);
                    this.actualizar = false;
                }
            );

        }


    }

    ayudaComentario(bool: boolean){
        this.ayuda = bool;
        //console.log("si entra");
    }

    onLinkRow({ row, columnPressed }) {
        
        if (columnPressed === 'folioVisita') {
            this.LinkVisitas(row);
        }

        if (columnPressed === 'idExterno') {
            //this.router.navigate([row.urlDetalleCuenta]);
            window.open(row.urlDetalleCuenta, '_blank');
        }

        if (columnPressed == 'coordenadaCuenta') {
            if (row.coordenadaCuenta != 'No disponible' || row.coordenadaVisita != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        }else if (columnPressed == 'coordenadaVisita') {
            if (row.coordenadaVisita != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadaVisita}`, '_blank');
            }
        }
    }

    LinkVisitas(visita: any) {
        window.open('/visits/visit/' + visita.folioVisita, '_blank');
        //this.router.navigate(['/visit/' + visita.folioVisita]);
    }
}
