<div class="table-header" *ngIf="tableHeaders">
  <div class="header-left">
    <ng-content select="[t-left]"></ng-content>
  </div>

  <div class="header-right" *ngIf="!headers">
    <ng-content select=".ui.mini.icon.buttons"></ng-content>
  </div>

  <div class="header-right" *ngIf="headers">
    <ng-content select="[t-right]"></ng-content>

    <mat-form-field [formGroup]="filterFormGroup">
      <mat-icon matPrefix>search</mat-icon>

      <input matInput textSanitizer  placeholder="Buscar" formControlName="search">

      <button
        [disabled]="!filterFormGroup.dirty"
        mat-icon-button
        matSuffix
        (click)="filterFormGroup.reset(); $event.stopPropagation();"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="filters?.length" mat-icon-button (click)="openFilters()">
      <mat-icon aria-label="Filtros">filter_list</mat-icon>
    </button>

    <button mat-icon-button (click)="onDownload.emit()">
      <mat-icon aria-label="Descargar">file_download</mat-icon>
    </button>
  </div>

</div>

<div style="background-color: white;">
  <div class="table-container">
    <!--app-loader *ngIf="loading"></app-loader-->

    <table
      *ngIf="dataSource"
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8"
      (matSortChange)="sortData($event)"
      [ngClass]="{'hide': loading}"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            class="kobra-checkbox"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()" *ngIf="multiSelect">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            class="kobra-checkbox"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)" *ngIf="multiSelect">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="column.hide" style="font-weight: 550;" class="generic-mat-cell"> {{ column.name }} </th>

        <td mat-cell *matCellDef="let item" [hidden]="column.hide" class="generic-mat-cell">
          <ng-container *ngIf="!column.type || column.type === 'string'">
            {{ item[column.key] }}
          </ng-container>

          <ng-container *ngIf="column.type === 'dateTime'">
            {{ item[column.key] ? momentDate(item[column.key]).format('DD/MM/YYYY HH:mm:ss') : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'money'">
            <ng-container *ngIf="!column.link">
              {{ item[column.key] | currency }}
            </ng-container>
            <a *ngIf="column.link" (click)="linkRow($event, item, column.key)">{{ item[column.key] | currency }}</a>
          </ng-container>

          <ng-container *ngIf="column.type === 'link'">
            <a class="sombreado-link" *ngIf="column.link" routerLink="{{column.link + item[column.key]}}">{{ item[column.key] }}</a>
            <a class="sombreado-link" *ngIf="!column.link" (click)="linkRow($event, item, column.key)">{{ item[column.key] }}</a>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{highlight: selectedRow === row  }"
      (click)="seleccionar(row);"
      (dblclick)="onDblClick(row)"></tr>
    </table>
  </div>

  <div class="paginator">
    <div class="actions" align="right">
      <ng-content select="[t-paginator]"></ng-content>
    </div>
  </div>
</div>
