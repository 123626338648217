import { PromisePayment } from "./PromisePayment.model";
import { Pagination } from "../pagination/pagination.model";

export class PromisePaginator {
  pagination: Pagination;
  data: PromisePayment[];

  constructor() {
    this.pagination = new Pagination();
  }
}
