<router-outlet (activate)="activarHijo()" (deactivate)="desactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            (click)="descargarListado()" 
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadPromise(filters.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
           <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tablaPromesas.columns"
      [items]="promises"
      [headers]="tablaPromesas.headers"
      [multiSelect]="tablaPromesas.multiSelect"
      (onLinkRow)="onClick($event)"
      (sortChange)="setSortField($event)"
      >

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-header>
          <mat-card-title>Ayuda</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Promesas de pago" en el sistema está específicamente enfocada en las cuentas de tipo cobranza y es una herramienta vital para gestionar y seguir las promesas de pago realizadas por los clientes.
En este módulo, los usuarios pueden acceder a información detallada y relevante sobre las promesas de pago, que son resultado de las gestiones de cobranza realizadas.
Lo que hace particularmente útil a esta vista es su capacidad para mostrar de manera clara y precisa los elementos esenciales de cada promesa de pago. Esto incluye el monto comprometido por el cliente y la fecha acordada para el pago.
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las promesas de pago.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de las promesas de pago para su análisis o uso fuera de línea.
          </p>
         
        </mat-card-content>
      </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="this.promisePaginator.pagination?.totalCount"
          [pageIndex]="this.promisePaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>


<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros
    </mat-toolbar>
    <div class="accordion-filtros">
      <mat-accordion [multi]="false">
        <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
          <mat-expansion-panel-header>
            Súper Administrador
            <span *ngIf="this.contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('superAdmin') }}</span>
          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Cliente'"
                [placeHolder]="'Selecciona un cliente'"
                [value]="'idFinanciera'"
                [labelField]="'name'"
                [icon]="'account_balance'"
                [options]="lenders"
                [(ngModel)]="idLender"
                (ngModelChange)="onChangeLender($event);"
              >
              </mat-select-search>
             
            </li>
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Excluir Demo</mat-label>
                <mat-select [(ngModel)]="filters.excluirDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                  <mat-option [value]="true">Sí</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
              </mat-form-field>
            </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
          <mat-expansion-panel-header>
            Generales
            <span *ngIf="this.contabilizadorDeFiltros('generales') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('generales') }}</span>
          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Folio Visita</mat-label>
                <input matInput textSanitizer 
                [(ngModel)]="filters.visitFolio"
                (ngModelChange)="this.contabilizadorDeFiltros('generales')"
                placeholder="Capture el folio de la visita">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="filters.visitFolio" 
                (click)="filters.visitFolio = null;this.contabilizadorDeFiltros('generales')">clear</mat-icon>
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
      
            </li>
            <li class="filtro-side">
                <mat-form-field  style="width: 100%;">
                  <mat-label>{{loadings.metodosPago ? 'Cargando métodos de pago...' : 'Método de pago'}}</mat-label>
                  <mat-select  [(ngModel)]="filters.metodoPagoID" (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                    <mat-option *ngFor="let metodo of metodos" [value]="metodo.idMetodoPago" >
                      {{metodo.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo" 
                  *ngIf="filters.metodoPagoID != null" 
                  (click)="filters.metodoPagoID = null;$event.stopPropagation(); this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                </mat-form-field>
             
              </li>
              <li class="filtro-side">
                <mat-form-field  style="width: 100%;">
                  <mat-label>{{loadings.estatusPromesaPago ? 'Cargando estatus de promesas de pago...' : 'Estatus Promesa de Pago'}}</mat-label>
                  <mat-select  [(ngModel)]="filters.estatusPromesaID"  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                    <mat-option *ngFor="let estatusPromesa of estatusPromesas" [value]="estatusPromesa.idEstatus" >
                      {{estatusPromesa.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo" 
                  *ngIf="filters.estatusPromesaID != null" 
                  (click)="filters.estatusPromesaID = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                </mat-form-field>
              </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Cuenta
            <span *ngIf="this.contabilizadorDeFiltros('cuenta') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('cuenta') }}</span>
          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Cuenta'"
                [placeHolder]="'Busca por id o nombre'"
                [value]="'option'"
                [labelField]="'nombre'"
                [icon]="'account_circle'"
                [realizarBusqueda]="acreditadoFilterSearch"
                [(ngModel)]="filters.acreditado"
                (ngModelChange)="this.contabilizadorDeFiltros('cuenta');"
              >
              </mat-select-search>
             
            </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Fechas
            <span *ngIf="this.contabilizadorDeFiltros('fechasV') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasV') }}</span>
          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-accordion [multi]="true">
                <mat-expansion-panel  [expanded]="true">
                  <mat-expansion-panel-header>
                    Fechas visita
                    <span *ngIf="this.contabilizadorDeFiltros('fechasV') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasV') }}</span>

                  </mat-expansion-panel-header>
                  <ol class="lista-filtros-side">
                    <li class="filtro-side">
                      <mat-date-picker-formater
                        [label]="'Fecha inicio'"
                        [placeHolder]="'Capture la fecha inicio'"
                        [max]="filters.fechaFin || today"
                        [(ngModel)]="filters.fechaInicio"
                        (ngModelChange)="this.contabilizadorDeFiltros('fechasV')"
                      />
                    
                    </li>
                    <li class="filtro-side">
                      <mat-date-picker-formater
                        [label]="'Fecha fin'"
                        [placeHolder]="'Capture la fecha fin'"
                        [min]="filters.fechaInicio"
                        [max]="today"
                        [(ngModel)]="filters.fechaFin"
                        (ngModelChange)="this.contabilizadorDeFiltros('fechasV')"
                      />
                     
                    </li>
                  </ol>
                </mat-expansion-panel>
              </mat-accordion>
            </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Agente
            <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>
          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Agente'"
                [placeHolder]="'Busca por id o nombre'"
                [value]="'option'"
                [labelField]="'nombre'"
                [icon]="'account_circle'"
                [realizarBusqueda]="agenteFilterSearch"
                [(ngModel)]="filters.agente"
                (ngModelChange)="this.contabilizadorDeFiltros('agente')"
                [minBusqueda]="1"
              >
              </mat-select-search>
              
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    
    
    <ol class="lista-filtros-buttons">
      <button
      class="boton-accion-front"
        style="width: 100%;"
        mat-stroked-button
        color="primary"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
      <button
        style="width: 100%; margin-top: 10px;"
        mat-stroked-button
        (click)="clearFilter()">
          <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
          Limpiar Filtros
      </button>
    </ol>
  </mat-sidenav>