<div class="app content kobra">
    <div>
      <div class="ui one columns grid">
        <div class="column c-center">
          <mat-card class="alturaMaximaCard kobra-card">
            <mat-card-header>
              <mat-card-title>Alta de agente interno</mat-card-title>
            </mat-card-header>
            <mat-card-content class="contenido" >
              <div class="button-container-wrapper button-container"> 
                <div class="button-toggle-group-container right">
                  <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                    <mat-button-toggle value="ayuda" 
                      matTooltip="Ayuda"
                      mat-tooltip-panel-above
                      (click)="ayudaRoles = !ayudaRoles">
                      <mat-icon color="primary">help</mat-icon>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              
              <form class="max26en">
                  <mat-grid-list-responsive [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="4:1" *ngIf="sessionData.isSuperAdmin">
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.financiera}">
                        <mat-select-search
                          class="id-card-field"
                          [label]="'Cliente*'"
                          [placeHolder]="'Selecciona un cliente'"
                          [value]="'idFinanciera'"
                          [labelField]="'nombre'"
                          [icon]="'account_balance'"
                          [disabled]="loadingRegistro"
                          [options]="clientes"
                          [(ngModel)]="agente.idFinanciera"
                          [ngModelOptions]="{standalone: true}"
                        >
                        </mat-select-search>
                        <mat-label *ngIf="validados.financiera" class="error-message">Es necesario seleccionar un cliente.*</mat-label>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list-responsive> 
                
                  <mat-grid-list-responsive [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="4:1">
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.nombre}">
                        <mat-form-field>
                          <mat-label>Nombre(s)*</mat-label>
                          <input matInput textSanitizer  class="upper"
                            maxlength="100"
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="validados.nombre = false"
                            [(ngModel)]="agente.nombre" placeholder="Ingrese el nombre del agente">
                          <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                        <mat-label *ngIf="validados.nombre" class="error-message">Es necesario capturar el nombre.*</mat-label>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.apellidoP}">
                        <mat-form-field>
                          <mat-label>Apellido paterno*</mat-label>
                          <input matInput textSanitizer  class="upper"
                            maxlength="100"
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="validados.apellidoP = false"
                            [(ngModel)]="agente.apellidoP" placeholder="Ingrese el apellido paterno del agente">
                          <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                        <mat-label *ngIf="validados.apellidoP" class="error-message">Es necesario capturar el apellido.*</mat-label>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field">
                        <mat-form-field>
                          <mat-label>Apellido materno</mat-label>
                          <input matInput textSanitizer  class="upper"
                            maxlength="100"
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="agente.apellidoM" placeholder="Ingrese el apellido materno del agente">
                          <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.sexo}">
                        <mat-form-field>
                          <mat-label>Sexo*</mat-label>
                          <mat-select
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="agente.sexo"
                            (ngModelChange)="validados.sexo = false"
                          >
                            <mat-option [value]="'M'">
                              Masculino
                            </mat-option>
                            <mat-option [value]="'F'">
                              Femenino
                            </mat-option>
                          </mat-select>
                          <mat-icon matSuffix>wc</mat-icon>
                        </mat-form-field>
                        <mat-label *ngIf="validados.sexo" class="error-message">Es necesario seleccionar el sexo.*</mat-label>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list-responsive> 

                  <mat-grid-list-responsive [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="3.5:1">
                    
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.email || validados.mailInvalido}">
                        <mat-form-field>
                          <mat-label>Email*</mat-label>
                          <input matInput textSanitizer  class="lower"
                            type="email"
                            maxlength="150"
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="validados.email = false"
                            [(ngModel)]="agente.email" placeholder="Ingrese el email del agente">
                          <mat-icon matSuffix>mail</mat-icon>
                        </mat-form-field>
                        <mat-label *ngIf="validados.email" class="error-message">Es necesario capturar el email.*</mat-label>
                        <mat-label *ngIf="validados.mailInvalido" class="error-message">Favor de capturar un email válido.*</mat-label>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.cel}">
                        <mat-form-field>
                          <mat-label>Teléfono celular</mat-label>
                          <input matInput textSanitizer 
                            [disabled]="loadingRegistro"
                            maxlength="20"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="validados.cel = false"
                            [(ngModel)]="agente.telefonoCelular" placeholder="Ingrese el número de celular del agente">
                          <mat-icon matSuffix>smartphone</mat-icon>
                        </mat-form-field>
                        <mat-label *ngIf="validados.cel" class="error-message">El número de celular es inválido.*</mat-label>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                      <div style="display: flex; flex-direction: column;" class="id-card-field" [ngClass]="{'errors': validados.contrasena || validados.contraCorrecta}">
                        <mat-form-field>
                          <mat-label>Contraseña*</mat-label>
                          <input matInput textSanitizer  
                            maxlength="16"
                            [disabled]="loadingRegistro"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="validados.contrasena = false"
                            [(ngModel)]="agente.pwd"  [type]="hidePwd ? 'password' : 'text'" placeholder="Ingrese su contraseña">
                          <mat-icon matSuffix (click)="hidePwd = !hidePwd">{{ hidePwd ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </mat-form-field>
                        <mat-error *ngIf="validados.mailInvalido">
                          Es necesario capturar una contraseña.*
                        </mat-error>
                        <mat-label *ngIf="validados.contrasena" class="error-message">Es necesario capturar una contraseña.*</mat-label>
                        <mat-label *ngIf="validados.contraCorrecta && !validados.contrasena" class="error-message">'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número.'</mat-label>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list-responsive>

                <div>
                  <mat-checkbox
                    class="kobra-checkbox responsive"
                    [(ngModel)]="agente.rolCobrador"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="loadingRegistro"
                  >
                    Rol de cobranza
                  </mat-checkbox>
                  <mat-checkbox
                    class="kobra-checkbox responsive"
                    [(ngModel)]="agente.rolDistribuidor"
                    [ngModelOptions]="{standalone: true}" 
                    [disabled]="loadingRegistro"
                  >
                    Rol de solicitante
                  </mat-checkbox>
                </div>
                <!--label for="">Rol de cobranza</label>
                <div class="ui toggle checkbox">
                  <input type="checkbox" [disabled]="loadingRegistro"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="agente.rolCobrador">
                  <label>&nbsp;</label>
                </div>

                <label for="">Rol de solicitante</label>
                <div class="ui toggle checkbox">
                  <input type="checkbox" [disabled]="loadingRegistro"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="agente.rolDistribuidor"
                  >
                  <label>&nbsp;</label>
                </div-->
                <!--button mat-icon-button color="primary"
                  matTooltip="Ayuda sobre los roles"
                  mat-tooltip-panel-above
                  class="botonAyuda"
                  (click)="ayudaRoles = !ayudaRoles">
                  <mat-icon>help</mat-icon>
                </button--><!--label for="" style="font-style: italic;">{{"<- si tienes dudas sobre los role da clic aquí" }}</label-->

                  
              </form>
              <!-- <div *ngIf="ayudaRoles">
                <mat-card class="cardAyuda">
                  <p>
                    ¿Que indican los roles del agente?
                  </p>
            
                  <p>
                    <b>Rol de cobranza:</b> indica que el agente tendrá permitido realizar visitas de cobranza.
                  </p>
                  <p>
                    <b>Rol de solicitante:</b> indica que el agente tendrá permitido realizar solicitudes.
                  </p>
                </mat-card>
              </div> -->
            </mat-card-content>
            <mat-card-actions class="button-center">
              <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancelar()" [disabled]="loadingRegistro">
                <mat-icon class="kobra-icon">
                  close
                </mat-icon> Cancelar</button>
              <button mat-stroked-button class="kobra-button" type="submit" (click)="guardarAgente()" [disabled]="loadingRegistro">
                <mat-icon>
                  save
                </mat-icon> Guardar</button>
            </mat-card-actions>
            <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadingRegistro || loadingLenders">
              <i *ngIf="loadingRegistro">Registrando agente...</i>
              <i *ngIf="loadingLenders">Obteniendo clientes...</i>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </mat-card>
        </div>
    </div>
</div>