import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environmentSelector } from '../../../../environments/environment.selector';
import { Response } from './response';


@Injectable()
export class ClientService {
  private baseURL: string = environmentSelector().kobraServices.serviciosClientes;

  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.serviciosClientes;
  }

  private headers(props = {}): HttpHeaders {
    let header: any = { 'Content-Type': 'application/json', ...props };
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Authorization'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, plain = false, login: boolean = false): Observable<Response> {
    return req.pipe(
      map(data => {
        if (plain) {
          return data;
        }

        let res = <Response> data;
        if(!res.success) {
          throw res.error.message;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse|string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = err.error.message;
          } else {
            console.log(err);
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }
                
              location.href = '/auth';
            } 
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              console.log(err);
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(
                `Backend returned code ${err.status}, ` +
                `body was: ${err.message}`);
              message = err.message;
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete( path: string ): Observable<Response> {
    return this.request(this.httpClient.delete(this.baseURL + path, {headers: this.headers()}));
  }

  public get( path: string): Observable<Response> {
    return this.request(this.httpClient.get(this.baseURL + path, { headers: this.headers() }));
  }

  public patch( path: string, data: any ): Observable<Response> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, {headers: this.headers()}));
  }

  public post( path: string, data: any ): Observable<Response> {
    return this.request(this.httpClient.post(this.baseURL + path, data, {headers: this.headers()}));
  }

  public download(path: string): Observable<any> {
    return this.request(this.httpClient.get(this.baseURL + path, {
      headers: this.headers(),
      responseType: 'blob'
    }), true);
  }

  public upload(path: string, formData: FormData): Observable<any> {
    let header: any = {};
    let token = localStorage.getItem('access-token');

    if (token) {
      header['Authorization'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }

    return this.request(this.httpClient.post(this.baseURL + path, formData, { headers: new HttpHeaders(header) }));
  }
}
