import { Component, ViewChild, ElementRef, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() acceptedTypes: string = ''; // Esto admitirá una cadena con tipos de archivo, por ejemplo: '.pdf,.xlsx,.docx'
  @Input() allowMultiple: boolean = false; // Recibe el valor booleano para determinar si se permiten múltiples archivos
  @Output() filesSelectedEmitter = new EventEmitter<File[]>();
  @Input() mostrarBotonEliminar: boolean = true;
  selectedFiles: File[] = [];
  uploadPercentage: number = 0;
  bloquear = false;
  

  onFilesSelected(event: any) {
    const files = event.target.files;
    if (files && files.length) {
        let validFiles: File[] = (Array.from(files) as File[]).filter(file => this.isFileTypeValid(file.name));
        this.selectedFiles = this.allowMultiple ? validFiles : [validFiles[0]];
        // Emitir los archivos seleccionados
        this.filesSelectedEmitter.emit(this.selectedFiles);
    }
  }


  private isFileTypeValid(fileName: string): boolean {
    if (!this.acceptedTypes) {
        return true; 
    }
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';
    const acceptedExtensions = this.acceptedTypes.split(',').map(ext => ext.trim().toLowerCase());
    return acceptedExtensions.includes('.' + fileExtension);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length) {
      this.selectedFiles = this.allowMultiple ? Array.from(files) : [files[0]];
      // Emitir los archivos seleccionados
      this.filesSelectedEmitter.emit(this.selectedFiles);
    }
  }


  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  bloquearComponete(bol: boolean){
    this.bloquear = bol;
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  removeFile(event?: Event, file?: File) {
    if (event) {
        event.stopPropagation();
    }
    if (file) {
        const index = this.selectedFiles.indexOf(file);
        if (index > -1) {
            this.selectedFiles.splice(index, 1);
        }
    } else {
        this.selectedFiles = []; // Si no se proporciona un archivo específico, limpia todos los archivos.
    }
    // Emitir la lista actualizada de archivos
    this.filesSelectedEmitter.emit(this.selectedFiles);
  }

}
