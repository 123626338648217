export class Revisita {
  folio: string;
  idExternoCliente: number;
  idFinanciera: number;
  idCliente: number;
  idExterno: number;
  subIdExterno:string;
  nombreProducto: string;
  nombreAcreditado: string;
  apePatAcreditado: string;
  apeMatAcreditado: string;
  idCobrador: number;
  nombreAgente: string;
  apePatAgente: string;
  apeMatAgente: string;
  comentario: string;
  fecVisitaRegistro: Date;
  nombreAdministrador: string;
  nombreEstatus: string;
  origenRevisitaNombre: string;
  idEstatusPP: number;
  revisitaGestor: boolean;
  fecha: Date;
  idEtiquetaVisita: number;
  nombreFinanciera: string;
  folioDomicilio: string;
  nombreCompletoAgente: string;
  nombreCompletoAcreditado: string;

  constructor() {
  }

  public static map(data: any): Revisita {
    let instance: Revisita = new Revisita();
    instance.folio = data.folioVisita;
    instance.idExternoCliente = data.idExternoCliente;
    instance.idFinanciera = data.idFinanciera;
    instance.idCliente = data.idCliente;
    instance.idExterno = data.idExterno;
    instance.subIdExterno = data.subIdExterno;
    instance.nombreProducto = data.nombreProducto;
    instance.nombreAcreditado = data.nombreAcreditado;
    instance.apePatAcreditado = data.apePatAcreditado;
    instance.apeMatAcreditado = data.apeMatAcreditado;
    instance.nombreCompletoAcreditado = `${data.nombreAcreditado} ${data.apePatAcreditado ? data.apePatAcreditado : ''} ${data.apeMatAcreditado ? data.apeMatAcreditado : ''}`;
    instance.idCobrador = data.idCobrador;
    instance.nombreAgente = data.nombreAgente;
    instance.apePatAgente = data.apePatAgente;
    instance.apeMatAgente = data.apeMatAgente;
    instance.nombreCompletoAgente = `${data.idCobrador} - ${data.nombreAgente} ${data.apePatAgente ? data.apePatAgente : ''} ${data.apeMatAgente ? data.apeMatAgente : ''}`;
    instance.comentario = data.comentario;
    instance.fecVisitaRegistro = data.fecVisitaRegistro;
    instance.nombreAdministrador = data.nombreAdministrador;
    instance.nombreEstatus = data.nombreEstatus;
    instance.origenRevisitaNombre = data.origenRevisitaNombre;
    instance.idEstatusPP = data.idEstatusPP;
    instance.revisitaGestor = data.revisitaGestor;
    instance.fecha = data.fecha;
    instance.idEtiquetaVisita = data.idEtiquetaVisita;
    instance.nombreFinanciera = data.nombreFinanciera;
    instance.folioDomicilio = data.folioDomicilio;
    return instance;
  }

  public static mapArray(data: any[]): Revisita[] {
      let instances: Revisita[] = [];
      for (let i = 0; i < data.length; i++) {
          instances.push(Revisita.map(data[i]));
      }
      return instances;
  }
}
