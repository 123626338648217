<app-loader *ngIf="showSegmentLoader" [message]="'Cargando onBoarding...'">
</app-loader>

<div *ngIf="onboarding">

  <div  class="kobra two columns">
    <div class="kobra column">
      <div class="kobra two columns">
        <div class="kobra column">
          <label for="">Fecha de Registro: </label>
        </div>
        <div class="kobra column">
          <p *ngIf="onboarding.fechaRegistro">{{onboarding.fechaRegistro | date:'dd/MM/yyyy HH:mm'}}</p>
        </div>
      </div>
    </div>

    <div class="kobra column">
      <div class="kobra two columns">
        <div class="kobra column">
          <label for="">Onboarding Completo:  </label>
        </div>
        <div class="kobra column">
          <p *ngIf="onboarding.onBoardingDistribuidorCompleto">Sí</p>
          <p *ngIf="!onboarding.onBoardingDistribuidorCompleto">No</p>
        </div>
      </div>
    </div>

    <div class="kobra column">
      <div class="kobra two columns">
        <div class="kobra column">
          <label for="">Configuración de Perfil: </label>
        </div>
        <div class="kobra column">
          <p *ngIf="onboarding.perfil">Sí</p>
          <p *ngIf="!onboarding.perfil">No</p>
        </div>
      </div>
    </div>

    <div class="kobra column">
      <div class="kobra two columns">
        <div class="kobra column">
          <label for="">Descarga Aplicación: </label>
        </div>
        <div class="kobra column">
          <p *ngIf="onboarding.primerLogin">Sí</p>
          <p *ngIf="!onboarding.primerLogin">No</p>
        </div>
      </div>
    </div>
  </div>

  <div class="kobra three columns">
    <div class="kobra column ">
      <mat-form-field style="width: 80%">
        <mat-label>Vinculación Financiera Demo:</mat-label>
        <mat-select
          [(ngModel)]="onboarding.solicitudVinculacionDistribuidor"
          (ngModelChange)="onChange(onboarding.solicitudVinculacionDistribuidor,'solicitudVinculacionDistribuidor')"
          [disabled] = "!sessionData.isSuperAdmin"
        >
          <mat-option [value]="0">
            No
          </mat-option>
          <mat-option [value]="1">
            Sí
          </mat-option>
        </mat-select>
        <mat-icon  matSuffix *ngIf="onboarding.solicitudVinculacionDistribuidor == 0">link_off</mat-icon>
        <mat-icon  matSuffix *ngIf="onboarding.solicitudVinculacionDistribuidor == 1">link</mat-icon>
      </mat-form-field>
    </div>

    <div class="kobra column three-colspan"></div>

    <div class="kobra column">
      <mat-form-field style="width: 80%">
        <mat-label>Certificación de Distribución:</mat-label>
        <mat-select
          [(ngModel)]="onboarding.certificacionDistribuidor"
          (ngModelChange)="onChange(onboarding.certificacionDistribuidor,'certificacionDistribuidor')"
        >
          <mat-option [value]="0">
            No
          </mat-option>
          <mat-option [value]="1">
            Sí
          </mat-option>
        </mat-select>
        <mat-icon  matSuffix>workspace_premium</mat-icon>
      </mat-form-field>
    </div>

    <div class="kobra column">
      <mat-form-field style="width: 80%">
        <mat-label>Solicitud Demo:</mat-label>
        <mat-select
          [(ngModel)]="onboarding.solicitudDistribuidor"
          (ngModelChange)="onChange(onboarding.solicitudDistribuidor,'solicitudDistribuidor')"
          [disabled] = "!sessionData.isSuperAdmin"
        >
          <mat-option [value]="0">
            No
          </mat-option>
          <mat-option [value]="1">
            Sí
          </mat-option>
        </mat-select>
        <mat-icon  matSuffix>inventory</mat-icon>
      </mat-form-field>
    </div>

    <div class="kobra column">
      <mat-form-field style="width: 80%">
        <mat-label>Solicitud Real:</mat-label>
        <mat-select
          [(ngModel)]="onboarding.solicitudRealDistribuidor"
          (ngModelChange)="onChange(onboarding.solicitudRealDistribuidor,'solicitudRealDistribuidor')"
          [disabled] = "!sessionData.isSuperAdmin"
        >
          <mat-option [value]="0">
            No
          </mat-option>
          <mat-option [value]="1">
            Sí
          </mat-option>
        </mat-select>
        <mat-icon  matSuffix>inventory</mat-icon>
      </mat-form-field>
    </div>
  </div>

</div>

<div *ngIf="!onboarding" style="text-align: center;">
  <i>Sin información para mostrar</i>
</div>