import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LenderService } from '@lender/lender.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import {  Subject } from 'rxjs';


@Component({
  selector: 'layout-field-nuevo',
  templateUrl: './layout-field-nuevo.component.html',
  styleUrls: ['./layout-field-nuevo.component.css'],
  providers: [
    LenderService                                   
  ]
})
export class LayoutFieldNuevo implements OnInit, OnDestroy {

  public title: string;
  layoutFields: any;
  public idValue: any;
  public nombreNuevo: any;
  public claveKey: any;
  public claveName: any;
  public tipoNuevo: number = 1;
  public reqNuevo: boolean = false;
  public mostrarNuevo: boolean = false;
  public custom: boolean = true;
  public hijosActivos: boolean = false;
  public newRow: boolean = false;
  default: boolean;
  public mostrarNombre : boolean = false;
  @ViewChild('inputNombre') inputNombre: ElementRef;


 
  private onDestroy: Subject<void> = new Subject<void>();
  
  itemsFieldType: Array<any> = [{
    name: 'Entero',
    value: 1
  }, {
    name: 'Decimal',
    value: 2
  }, {
    name: 'Alfanumérico',
    value: 3
  }, {
    name: 'Fecha',
    value: 4
  }, {
    name: 'Fecha y hora',
    value: 5
  }, {
    name: 'Boleano',
    value: 6
  }, {
    name: 'Hora',
    value: 7}
  ];
  nombreFinanciera: string;
  idFinanciera: any;
  idProducto: any;
  idCliente: any;

  constructor(
    private router: Router,
    private lenderService: LenderService,
    private appService: ApplicationService,
    private route: ActivatedRoute, 
  ) {
    this.idCliente = this.route.snapshot.queryParamMap.get('idCliente');
     this.idProducto = this.route.snapshot.queryParamMap.get('idProducto');
    this.idFinanciera = this.route.snapshot.queryParamMap.get('idFinanciera')
  }

  ngOnInit() {

    this.lenderService
    .getLayoutFieldsByProductDefault(this.idCliente, this.idProducto)
    .subscribe(
      (res) => {
      this.layoutFields = res; 
     },
     (err) => {
      this.appService.showError(err);
  }
     )
  }

  ngOnDestroy() {

    this.onDestroy.next();
    this.onDestroy.complete();
  }

  cancel() {
    this.router.navigate([`catalogos/checklists/layout`], {
      queryParamsHandling: 'merge'
    });
  }

  public toggleMostrarNombre(): void {
    this.mostrarNombre = !this.mostrarNombre;
    if (this.mostrarNombre) {
        setTimeout(() => {
            if (this.inputNombre) {
                this.inputNombre.nativeElement.focus();
            }
        }, 0);
    }
}

  onChangeNombre(name: any){
    if (name) {
      const fieldValue = this.layoutFields.find((field: any) => field.name === name);
      if (fieldValue) {
        this.setFieldValues(fieldValue);
        this.default = true;
      } else {
        this.claveKey = this.normalize(name)
        this.default = false;
      }
    } else {
      this.resetFieldValues();
    }
  }

  private setFieldValues(fieldValue: any) {
    this.idValue = fieldValue.id;
    this.claveKey = fieldValue.key;
    this.claveName = fieldValue.name;
    this.tipoNuevo = fieldValue.idFieldType;
    this.reqNuevo = fieldValue.required;
    this.mostrarNuevo = fieldValue.showOnApp;
    this.custom = fieldValue.customized;

  }

  public resetFieldValues() {
      this.idValue = '';
      this.nombreNuevo = '';
      this.claveKey = '';
      this.claveName = '';
      this.tipoNuevo = null;
      this.reqNuevo = false;
      this.mostrarNuevo = false;
      this.custom = true;
  }

  normalize(str: string) {
    return str.toLowerCase().replace(/^(\d+)?/, '')
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => 
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      ).replace(/\s+/g, '');
  }

  onSubmit() {
    if(this.nombreNuevo == null || this.claveKey == null || this.tipoNuevo == null){
      this.appService.showSnackbar("Aviso", "Favor de ingresar los campos obligatorios", 3000, 'warning');
      return;
    }
    const loading = this.appService.showLoading('Estamos guardando el layout...');

    let fields: {}[] = [{
      idCuentaCampo: this.idValue,
      descripcion: this.nombreNuevo.split(' (')[0],
      clave: this.claveKey,
      idCuentaCampoTipoDato: this.tipoNuevo,
      requerido: this.reqNuevo,
      mostrarEnApp: this.mostrarNuevo,
    }];   

    this.lenderService.saveLayoutFields(
      this.idFinanciera, this.idProducto, fields
    ).subscribe(() => {
      this.resetFieldValues();
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('¡Aviso!', 'La configuración del layout ha sido guardada.', 3000, 'success');
      this.ngOnInit();
      this.resetFieldValues
    }, error => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    });
  }

}
