import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'alerta-informativa',
  templateUrl: './alerta-informativa.component.html',
  styleUrls: ['./alerta-informativa.component.css']
})
export class AlertaInformativaComponent implements OnInit {
  @Input() clase: string;
  @Input() texto: string;
  @Input() titulo: string;
  @Input() html: string;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {}

  getClass(value: string): string {
    const classes = {
        'primary': 'primary',
        'secondary': 'secondary',
        'success': 'success',
        'danger': 'danger',
        'warning': 'warning',
        'info': 'info',
        'light': 'light',
        'dark': 'dark'
    };
    
    return classes[value];
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
