import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";

import { Relationship } from "./relationship.model";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RelationshipService {
  private apiService = `/v2/relationships`;

  constructor(
    private apiKobra: ApiKobraService,
  ) { }

  private relationships: Relationship[] = [
    { id: 1, name: 'Amigo' },
    { id: 2, name: 'Familiar' },
    { id: 3, name: 'Conocido' }
  ];

  public all(): Observable<Relationship[]> {
    return of(this.relationships);
  }
}