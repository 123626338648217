import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '../../../application/shared/application.service';
import { ChecklistService } from '../../../checklist/checklist.service';
import { AnswerComponent } from '../answer/answer.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
  providers: [ChecklistService]
})
export class QuestionComponent implements OnInit {
  formGroup: FormGroup;
  pregunta: any;
  tiposRespuestas = [];

  constructor(
    private formBuilder: FormBuilder,
    private appService: ApplicationService,
    private checklistService: ChecklistService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<QuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.data = this.data || {};
    this.pregunta = this.data.pregunta || {};

    this.formGroup = this.formBuilder.group({
      idChecklistPregunta: new FormControl(this.pregunta.idChecklistPregunta),
      idChecklistPreguntaPadre: new FormControl(this.pregunta.idChecklistPreguntaPadre),
      llaveCliente: new FormControl(this.pregunta.llaveCliente),
      nombrePregunta: new FormControl({
        value: this.pregunta.nombrePregunta,
        disabled: this.hasAction
      }, [Validators.required, Validators.maxLength(200)]),
      respuestas: this.buildAnswers(this.pregunta)
    });

    if (!Array.isArray(this.pregunta.respuestas)) {
      this.pregunta.respuestas = [{
        accion: null,
        idTipoRespuesta: 1,
        mostrarPreguntasCuando: false,
        nombreRespuesta: null,
        options: [],
        requerida: false
      }];

      this.formGroup.markAsDirty();
    }

    (async () => {
      this.tiposRespuestas = await this.checklistService
      .getTypesAnswers()
      .toPromise()
      .catch((error) => this.appService.showError(error));
    })();
  }

  get title() {
    let title: string = 'Nueva pregunta';

    if (this.pregunta.nombrePregunta) {
      title = `Pregunta ${this.pregunta.nombrePregunta}`;
    }

    if (this.formGroup.get('nombrePregunta').value) {
      title = this.formGroup.get('nombrePregunta').value;
    }

    return title;
  }

  get hasAction() {
    return this.pregunta.respuestas && this.pregunta.respuestas.findIndex(r => Boolean(r.accion)) !== -1;
  }

  buildAnswers(pregunta: any): FormArray {
    let respuestas = this.formBuilder.array([]);
  
    if (!Array.isArray(pregunta.respuestas)) {
      respuestas.push(this.formBuilder.control(this.formBuilder.group({
        idChecklistRespuesta: new FormControl(null),
        idAccionRespuesta: new FormControl(null),
        accion: new FormControl(null),
        idTipoRespuesta: new FormControl(1),
        mostrarPreguntasCuando: new FormControl(false),
        nombreRespuesta: new FormControl(null),
        options: new FormControl([]),
        requerida: new FormControl(false)
      })));
  
      return respuestas;
    }
  
    pregunta.respuestas.forEach(respuesta => {
      respuestas.push(this.formBuilder.control(this.formBuilder.group({
        idChecklistRespuesta: new FormControl(respuesta.idChecklistRespuesta),
        idAccionRespuesta: new FormControl(respuesta.idAccionRespuesta),
        accion: new FormControl(respuesta.accion),
        idTipoRespuesta: new FormControl(respuesta.idTipoRespuesta),
        mostrarPreguntasCuando: new FormControl(respuesta.mostrarPreguntasCuando || false),
        nombreRespuesta: new FormControl(respuesta.nombreRespuesta),
        options: new FormControl(respuesta.options.slice()),
        requerida: new FormControl(respuesta.requerida || false)
      })));
    });
  
    return respuestas;
  }
  

  getTypeAnswerName(answer) {
    return this.tiposRespuestas.find(t => t.id === answer.idTipoRespuesta).name;
  }

  addAnswer() {
    const pregunta = this.formGroup.getRawValue();

    const dialogRef = this.dialog.open(AnswerComponent, {
      width: '400px',
      disableClose: true,
      data: {
        nameAnswerRequired: pregunta.respuestas.length > 0,
        soloCheckBox: pregunta.respuestas.length > 0 && pregunta.respuestas[0].idTipoRespuesta == 6,
        fromChildQuestion: true
      }
    });

    dialogRef.afterClosed().subscribe(answer => {
      if (answer) {
        const answers = this.formGroup.get('respuestas');
        const controls = answers['controls'];

        if (controls.length === 1) {
          for (let control of controls) {
            const nameAnswer = control.get('nombreRespuesta');

            nameAnswer.setValidators(Validators.required);
            nameAnswer.updateValueAndValidity();
          }
        }

        answers['push'](this.formBuilder.group({
          accion: new FormControl(answer.accion),
          idTipoRespuesta: new FormControl(answer.idTipoRespuesta),
          mostrarPreguntasCuando: new FormControl(answer.mostrarPreguntasCuando || false),
          nombreRespuesta: new FormControl(answer.nombreRespuesta, controls.length > 0 ? Validators.required : null),
          options: new FormControl(answer.options.slice()),
          requerida: new FormControl(answer.requerida || false)
        }));

        if (!Array.isArray(this.pregunta.respuestas)) {
          this.pregunta.respuestas = [];
        }

        this.pregunta.respuestas.push(answer);
        this.formGroup.get('respuestas').setErrors(null);
        this.formGroup.markAsDirty();
      }
    });
  }

  editAnswer(answerIndex) {
    const pregunta = this.formGroup.getRawValue();
    const answerFormGroup = pregunta.respuestas[answerIndex];

    const dialogRef = this.dialog.open(AnswerComponent, {
      width: '400px',
      disableClose: true,
      data: {
        answer: answerFormGroup,
        nameAnswerRequired: pregunta.respuestas.length > 1,
        soloCheckBox: pregunta.respuestas.length > 1 && pregunta.respuestas[1].idTipoRespuesta == 6,
        fromChildQuestion: true
      }
    });

    dialogRef.afterClosed().subscribe(answer => {
      if (answer) {
        const answerFormGroup = this.formGroup.get('respuestas')['controls'][answerIndex];

        answerFormGroup.get('idChecklistRespuesta').setValue(answer.idChecklistRespuesta);
        answerFormGroup.get('idAccionRespuesta').setValue(answer.idAccionRespuesta);
        answerFormGroup.get('accion').setValue(answer.accion);
        answerFormGroup.get('idTipoRespuesta').setValue(answer.idTipoRespuesta);
        answerFormGroup.get('mostrarPreguntasCuando').setValue(answer.mostrarPreguntasCuando);
        answerFormGroup.get('nombreRespuesta').setValue(answer.nombreRespuesta);
        answerFormGroup.get('options').setValue(answer.options.slice());
        answerFormGroup.get('requerida').setValue(answer.requerida);

        this.pregunta.respuestas[answerIndex] = answer;

        this.formGroup.markAsDirty();
      }
    });
  }

  removeAnswer(answerIndex) {

    if(this.pregunta.respuestas[answerIndex].accion){
      this.appService.showSnackbar('¡Aviso!', 'No se puede eliminar respuestas con acción.', 3000, 'warning');

      return;
    }

    this.pregunta.respuestas.splice(answerIndex, 1);

    this.formGroup.get('respuestas')['removeAt'](answerIndex);

    if (this.pregunta.respuestas.length === 1) {
      const answers = this.formGroup.get('respuestas');
      const controls = answers['controls'];
      this.pregunta.respuestas[0].nombreRespuesta = '';

      for (let control of controls) {
        const nameAnswer = control.get('nombreRespuesta');

        nameAnswer.setValidators([]);
        nameAnswer.updateValueAndValidity();
      }
    }

    if (this.pregunta.respuestas.length === 0) {
      this.formGroup.get('respuestas').setErrors({ required: true });
    }

    this.formGroup.markAsDirty();
  }

  validarAgregarRespuesta(pregunta){
    if(pregunta.respuestas.length > 0 && pregunta.respuestas[0].accion){
      return false;
    }
    return true;
  }

  onClose() {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(this.formGroup.getRawValue());
  }
}
