<div class="app conten kobra">
  <mat-card>
  <div class="import-wizard-actions">
    <span class="nombreFinanciera">{{ nombreCliente }}</span>
  </div>
  
  <div class="">
    
    <mat-horizontal-stepper [linear]="false" #stepper labelPosition="bottom" [selectedIndex]="selectedIndex" (selectionChange)="onStepChanged($event)">
      <mat-step>
        <ng-template matStepLabel>PRODUCTO</ng-template>
        <app-product [idCliente]="idCliente" (onChange)="onChangeProduct($event)"></app-product>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>TIPO</ng-template>
        <app-type *ngIf="product && stepper.selectedIndex > 0" [idCliente]="idCliente" [product]="product" (onChange)="onChangeType($event)"></app-type>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>CARGAR</ng-template>
        <app-upload *ngIf="type && product" [product]="product" [type]="type" (onChange)="onChangeFile($event)"></app-upload>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>VISTA PREVIA</ng-template>
        <app-preview [idCliente]="idCliente" [idFinanciera]="idFinanciera" *ngIf="(file || continueWithoutFile) && stepper.selectedIndex === 3" [product]="product" [type]="type" [file]="file" [batch]="batch" (onChange)="onChangeBatch($event)"></app-preview>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>FINALIZAR</ng-template>
        <app-finish [idCliente]="idCliente" [idFinanciera]="idFinanciera" *ngIf="batch && stepper.selectedIndex === 4" [batch]="batch"></app-finish>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div class="button-group" style="flex-direction: row-reverse; margin-bottom: 10px;margin-right: 10px;" >
    <button mat-stroked-button class="boton-accion-front" *ngIf="this.selectedIndex != 4" [disabled]="!currentStepIsValid()" (click)="goNext()" color="primary">
      <mat-icon>arrow_forward</mat-icon>Siguiente
    </button>
    <button mat-stroked-button class="boton-accion-front" *ngIf="this.selectedIndex != 4" [hidden]="stepper.selectedIndex === 0" (click)="goPrevious()" color="primary">
      <mat-icon>arrow_back</mat-icon>Anterior
    </button>
    <button mat-stroked-button class="boton-accion-front" *ngIf="this.selectedIndex == 4" [routerLink]="['../../../']" color="primary">
      <mat-icon>home</mat-icon>Inicio
    </button>
    <button mat-flat-button class="boton-cancelar" *ngIf="this.selectedIndex != 4"  [routerLink]="['../../../']" color="primary">
      <mat-icon class="kobra-icon">close</mat-icon>Cancelar
    </button>
  </div>
</mat-card>
</div>
