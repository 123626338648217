import { Component, ElementRef, EventEmitter, Inject, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormValidation } from "../../../../shared/form-validation/form-validation.model";
import { FormValidationService } from "../../../../shared/form-validation/form-validation.service";
import { MessageService } from "../../../../shared/message/message.service";
import { PhoneType } from "../../../../phone-type/phone-type.model";
import { PhoneTypeService } from "../../../../phone-type/phone-type.service";
import { WorkReference } from "../work-reference.model";
import { WorkReferenceService } from "../work-reference.service";
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';

declare let $: any;

@Component({
  selector: 'work-reference-modal-form',
  templateUrl: './work-reference-modal-form.component.html',
  styleUrls: ['./work-reference-modal-form.component.css'],
  providers: [
    FormValidationService,
    PhoneTypeService,
    WorkReferenceService
  ]
})
export class WorkReferenceModalFormComponent implements OnInit, OnChanges {
  public $modal: any;
  private rules: any;

  public form: FormGroup;
  public companyValidation: FormValidation;
  public nameValidation: FormValidation;
  public phoneNumberValidation: FormValidation;
  public phoneTypes: PhoneType[];
  public phoneTypeValidation: FormValidation;
  public surnameValidation: FormValidation;

  agentID: number;
  workReference: WorkReference;
  totalReferencias: number;

  afterSave: Function;
  afterDelete: Function;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private phoneTypeService: PhoneTypeService,
    private rootElement: ElementRef,
    private workReferenceService: WorkReferenceService,
    private app: ApplicationService,
    public dialogRef: MatDialogRef<WorkReferenceModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(!data.nuevo){
      this.workReference = data.workReference;
    }else{
      this.workReference = new WorkReference();
    }
    
    this.agentID = data.agentID;
    this.afterSave = data.afterSave;
    this.afterDelete = data.afterDelete;
    this.totalReferencias = data.totalReferencias;
  }

  ngOnInit() {
    this.loadPhoneTypes();
    this.initForm();
    this.initValidation();
  }

  ngOnChanges() {
    if (this.form) {
      this.resetForm();
    }
  }

  delete(): void {
    const loader = this.app.showLoading('Eliminando referencia laboral...')
    const workReference: WorkReference = this.prepareSaveData();
    workReference.totalReferenciasLaborales = this.totalReferencias - 1;
    this.workReferenceService.delete(this.workReference.id, workReference)
    .subscribe(() => {
      this.app.showSnackbar('¡Aviso!', 'Referencia laboral eliminada correctamente.', 3000, 'success');
      this.app.hideLoading(loader);
      this.afterDelete(this.workReference);
      this.dialogRef.close({
        nuevo: this.data.nuevo,
        cancelar: true
      });
    }, error => {
      this.app.hideLoading(loader);
      this.app.showError(error);
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      'name': ['', [
          Validators.required,
          this.noWhitespaceValidator
        ]
      ],
      'surname': ['', [
          Validators.required,
          this.noWhitespaceValidator
        ]
      ],
      'company': ['', [
          Validators.required,
          this.noWhitespaceValidator
        ]
      ],
      'phoneNumber': ['', [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.maxLength(10),
          Validators.minLength(10)
        ]
      ],
      'phoneType': ['', [
          Validators.required
        ]
      ],
    });
    if(this.workReference){
      this.resetForm();
    }
  };

  private initValidation(): void {
    this.nameValidation = new FormValidation();
    this.nameValidation.formControl = this.form.controls['name'];
    this.nameValidation.message = {
      required: 'Información requerida.'
    };
    this.nameValidation.observeFromControl();

    this.surnameValidation = new FormValidation();
    this.surnameValidation.formControl = this.form.controls['surname'];
    this.surnameValidation.message = {
      required: 'Información requerida.'
    };
    this.surnameValidation.observeFromControl();

    this.companyValidation = new FormValidation();
    this.companyValidation.formControl = this.form.controls['company'];
    this.companyValidation.message = {
      required: 'Información requerida.'
    };
    this.companyValidation.observeFromControl();
    
    this.phoneNumberValidation = new FormValidation();
    this.phoneNumberValidation.formControl = this.form.controls['phoneNumber'];
    this.phoneNumberValidation.message = {
      maxlength: `Debe teber exactamente 10 digitos.`,
      minlength: `Debe teber exactamente 10 digitos.`,
      pattern: 'Formato incorrecto, debe ingresar solo números.',
      required: 'Información requerida.'
    };
    this.phoneNumberValidation.observeFromControl();
    
    this.phoneTypeValidation = new FormValidation();
    this.phoneTypeValidation.formControl = this.form.controls['phoneType'];
    this.phoneTypeValidation.message = {
      required: 'Información requerida.'
    };
    this.phoneTypeValidation.observeFromControl();
  }

  private loadPhoneTypes(): void {
    this.phoneTypeService.all().subscribe(
      phoneTypes => this.phoneTypes = phoneTypes,
      error => this.messageService.httpError(error)
    )
  }

  private prepareSaveData(): WorkReference {
    let formModel = this.form.value;
    let workReference: WorkReference = new WorkReference();
    workReference.idCobrador = this.agentID;
    workReference.name = formModel.name.trim();
    workReference.surname = formModel.surname.trim();
    workReference.company = formModel.company.trim();
    workReference.phoneNumber = formModel.phoneNumber;
    workReference.phoneTypeID = formModel.phoneType;
    return workReference;
  }

  onClose() {
    this.dialogRef.close({
      nuevo: this.data.nuevo,
      cancelar: true
    });
  }

  public save(): void {
    if (this.form.valid) {
      let workReference: WorkReference = this.prepareSaveData();
      let response: Observable<number>;
      const loader = this.app.showLoading('Guardando Referencia laboral...');
      if (this.workReference.id) {
        workReference.id = this.workReference.id;
        response = this.workReferenceService.update(workReference);
      } else {
        workReference.agentID = this.agentID;
        workReference.totalReferenciasLaborales = this.totalReferencias + 1;
        response = this.workReferenceService.add(workReference);
      }
      response.subscribe( (res: any) => {
        if (!this.workReference.id) {
          this.totalReferencias++;
        }
        this.app.showSnackbar('¡Aviso!', 'Referencia laboral guardada correctamente.', 3000, 'success');
        this.app.hideLoading(loader);
        workReference.id = workReference.id || +res.idReferenciaLaboral;
        workReference.fullName = `${workReference.name} ${workReference.surname}`;
        if(!this.data.nuevo){
          this.afterSave(workReference);
        }
        
        this.dialogRef.close({
          nuevo: this.data.nuevo,
          cancelar: false
        });
      }, error => {
        this.app.hideLoading(loader);
        this.app.showError(error);
      });
    }
  }

  public resetForm(): void {
    this.form.reset({
      name: this.workReference.name,
      surname: this.workReference.surname,
      company: this.workReference.company,
      phoneNumber: this.workReference.phoneNumber,
      phoneType: this.workReference.phoneTypeID
    });
  }

  public show(): void {
    //this.$modal.modal('show');
  }
}
