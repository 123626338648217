
export class VisitaCalificada{
    idVisitaCalificacion: number;
    nombreCliente: string;
    idCliente: number;
    idFinanciera: number;
    nombreCompletoAcreditadoCalificador: string;
    folioVisita: string;
    idVisita: number;
    nombreCompletoAgenteVisita: string;
    calificacion: number;
    comentario: number;
    fechaCalificacion: Date;
    nombreAgenteCalifica: string;
    nombreCompletoFinancieraCalificador: string;
    nombreCompletoKobraCalificador: string;
    idAcreditado: number;
    idClienteAdministrador: number;
    idSuperClienteAdministrador: number;
    idAgenteCalifica: number;

    constructor() { }

    public static map(data: any): VisitaCalificada{
        let instance: VisitaCalificada = new VisitaCalificada();

        instance.idVisitaCalificacion = data.idVisitaCalificacion;
        instance.nombreCliente = data.nombreCliente;
        instance.idCliente = data.idCliente;
        instance.idFinanciera = data.idFinanciera;
        instance.nombreCompletoAcreditadoCalificador = data.nombreCompletoAcreditadoCalificador;
        instance.folioVisita = data.folioVisita;
        instance.idVisita = data.idVisita;
        instance.nombreCompletoAgenteVisita = data.nombreCompletoAgenteVisita;
        instance.calificacion = data.calificacion;
        instance.comentario = data.comentario;
        instance.fechaCalificacion = data.fechaCalificacion;
        instance.nombreAgenteCalifica = data.nombreAgenteCalifica;
        instance.nombreCompletoFinancieraCalificador = data.nombreCompletoFinancieraCalificador;
        instance.nombreCompletoKobraCalificador = data.nombreCompletoKobraCalificador;
        instance.idAcreditado = data.idAcreditado;
        instance.idClienteAdministrador = data.idClienteAdministrador;
        instance.idSuperClienteAdministrador = data.idSuperClienteAdministrador;
        instance.idAgenteCalifica = data.idAgenteCalifica;

        return instance;
    }

    public static mapArray(data: any[]): VisitaCalificada[] {
        let instances: VisitaCalificada[] = [];
        for (let i = 0; i < data.length; i++) {
          instances.push(VisitaCalificada.map(data[i]));
        }
        return instances;
      }
}