import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'foto-modal',
  templateUrl: './foto-modal.component.html',
  styleUrls: ['./foto-modal.component.css'],
  providers: []
})
export class FotoModalComponent implements OnInit {
  title: string = '';
  urlFoto: string = '';

  constructor(
    private appService: ApplicationService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FotoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.urlFoto = this.data.urlFoto;
    //console.log(this.urlFoto);
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(false);
    const loading = this.appService.showLoading(
      `Estamos guardado la configuración para ${this.data.nombreFinanciera}`
    );
  }
}
