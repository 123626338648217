<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            *ngIf="sessionData.isSuperAdmin"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
		  	[disabled]="!listFiltered"
            (click)="loadImports(filters.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="iniciarImportacion()"
        matTooltip = 'Importar nuevas cuentas y/o actualizar su información.'
        matTooltipPosition='left'
        mat-tooltip-panel-above>
        <mat-icon class="kobra-icon">import_export</mat-icon>
        <span>Nueva Importación</span>
      </button>
	  <a mat-menu-item href="{{url}}/developers" target="_blank" 
      matTooltip = 'Utiliza las API de Kobra para mejorar los flujos de trabajo con datos adicionales y conectar con la ultima milla en tiempo real.'
      matTooltipPosition='left'
      mat-tooltip-panel-above>
        <mat-icon class="kobra-icon">developer_mode</mat-icon>
        <span>Sitio desarrolladores</span>
      </a>
    </mat-menu>
	
  
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tablaImportaciones.columns"
      [items]="dataSource"
      [headers]="tablaImportaciones.headers"
      [multiSelect]="tablaImportaciones.multiSelect"
      [ignoreSortChange]="true"
      (onLinkRow)="onClick($event)"
      >

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
         <mat-card-content>
        <p><mat-icon color="primary">help</mat-icon>

          El módulo Importación es una herramienta clave en el sistema para la integración y actualización de las cuenas de
          nuestros clientes. Esta funcionalidad facilita la transferencia y actualización de cuentas desde el sistema de nuestro
          cliente hacia el sistema Kobra.
          La importación de datos se realiza a través de archivos Excel, un formato ampliamente utilizado y accesible que facilita
          la transferencia de grandes volúmenes de datos de manera estructurada y organizada.
          Además de la importación de nuevas cuentas, este módulo también permite la actualización de cuentas ya existentes en el
          sistema, así como también se pueden dar de baja de manera masiva.

        </p>
        Acciones:
        <p>
          <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b>  Mejora la búsqueda y el acceso a las importaciones ya existentes.
        </p>
        <p>
          <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
        </p>
        <p>
          <mat-icon class="kobra-icon">import_export</mat-icon><b> Nueva importacion: </b> Permite realizar una nueva subida de cuentas en un formato excel.
        </p>
        <p>
          <mat-icon class="kobra-icon">developer_mode</mat-icon><b> Sitio desarrolladores </b> Dirige al sitio para desarroladores con la documentación necesaria para integrarse vía API.
        </p>
      </mat-card-content>
      </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="page?.totalRows"
          [pageIndex]="page?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
	<mat-toolbar>
	  <button class="filter-button-side" mat-flat-button
		matTooltip="Ocultar filtros"
		mat-tooltip-panel-above
		(click)="filtrosSideNav.toggle()"
	  >
		<mat-icon>cancel</mat-icon>
	  </button> 
	  Filtros
	</mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
  <mat-expansion-panel [expanded]="true">
    
	<ol class="lista-filtros-side">
	  <div class="div-filtros-side">
      <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
        <mat-select-search
        [label]="'Cliente'"
        [placeHolder]="'Selecciona un cliente'"
        [value]="'idCliente'"
        [labelField]="'nombre'"
        [icon]="'account_balance'"
        [options]="lenders"
        [(ngModel)]="filters.financiera.idCliente"
        (ngModelChange)="onChangeLender($event);this.contabilizadorDeFiltros('generales');"
        [ngModelOptions]="{standalone: true}"
        >
        </mat-select-search>
      
      </li>
	  </div>
  </ol>
</mat-expansion-panel>
</mat-accordion>
</div>
<ol class="lista-filtros-buttons lista-filtros-groupless">

	  <button
    class="boton-accion-front"
		style="width: 100%;"
		mat-stroked-button
		color="primary"
		(click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; setFilter();">
		  <mat-icon>filter_alt</mat-icon>
		  Filtrar
	  </button>
	  <button
		style="width: 100%; margin-top: 10px;"
		mat-stroked-button
		(click)="clearFilter();">
		  <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
		  Limpiar Filtros
	  </button>
</ol>
  </mat-sidenav>