import { MapboxUsuariosComponent } from './../mapbox-dashboard/mapbox-dashboard.component';

import { FiltrosService } from 'src/app/application/shared/filtros.service';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardUserService } from './dashboard-user.service';
import { ApplicationService } from '../application/shared/application.service';
import { take } from 'rxjs/internal/operators/take';
import { FormGroup, FormBuilder } from '@angular/forms';
import moment from 'moment';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { SessionData } from '../shared/interfaces/session-data';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';



@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.css']
})
export class DashboardUserComponent implements OnInit, OnDestroy {
  public sessionData: SessionData;
  public totalCuentasActivas = 0;
  public totalCuentasApartadas = 0;
  public totalCuentasEspera = 0;
  public totalCuentasEnEsperaConfirmacion = 0;
  public totalVisitasRealizadas = 0;
  public totalVisitasRealizadasGeneral: number = 0;
  public totalAcreditadosVisitados = 0;
  public totalCobradoresVinculados = 0;
  public totalRevisitas = 0;
  public totalPromesasPagoAgentes = 0;
  public totalPromesasPagoAcreditados = 0;
  public products: Array<any>;
  public sucursales: Array<any>;
  //public today = new Date();
  maxInicio = moment(new Date()).format('YYYY-MM-DD');
  minFin = moment(new Date()).format('YYYY-MM-DD');
  maxFin = moment(new Date()).format('YYYY-MM-DD');
  public minInicio = '2015-01-01';
  public filtrosForm: FormGroup;
  public filtrosGeneralesForm: FormGroup;
  public limpioFiltro = true;
  public primeraVez = true;
  public cambioFechaInicio = false;
  public cambioFechaFin = false;
  public loadingCuentas = false;
  public loadingVisitas = false;
  public loadingAcreditadosVisitados = false;
  public loadingKobrasVinculados = false;
  public loadingRevisitas = false;
  public loadingPromesasPagoRegistradasAgentes = false;
  public loadingPromesasPagoRegistradasAcreditado = false;
  public loadingProducts: boolean;
  public loadingSucursales: boolean;
  @ViewChild('mapboxdashboard') private mapboxDashboard: MapboxUsuariosComponent;
  

  constructor(
    private app: ApplicationService,
    private filtrosServices: FiltrosService,
    private dashboardUserServ: DashboardUserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private dateAdapter: DateAdapter<Date>,

  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.dateAdapter.setLocale('es-MX');
    this.filtrosForm = this.formBuilder.group({
      fechaInicio: null,
      fechaFin: null
    });
    this.filtrosGeneralesForm = this.formBuilder.group({
      producto: null,
      sucursal: null
    });
    // this.sessionData = this.localStorageService.getSessionData();
  };

  ngOnInit() {
    this.obtenerTotalesCuentas();
    this.obtenerTotalAcreditadosVisitados();
    this.obtenerTotalVisitasRealizadas();
    this.obtenerTotalCobradoresVinculados();
    this.obtenerTotalRevisitas();
    this.obtenerTotalPromesasPagoAgentes();
    this.obtenerTotalPromesasPagoAcreditados();
    this.loadProducts();
    this.loadSucursales();
  }

  ngOnDestroy() {

  }

  obtenerTotalesCuentas(query = '') {
    this.loadingCuentas = true;

    this.dashboardUserServ.obtenerTotalesCuenta(query)
      .pipe(take(1))
      .subscribe((respuesta: any) => {
        this.totalCuentasActivas = respuesta.data.totalCuentasActivas;
        this.totalCuentasApartadas = respuesta.data.totalCuentasApartadas;
        this.totalCuentasEspera = respuesta.data.totalCuentasEspera;
        this.totalVisitasRealizadasGeneral = respuesta.data.totalVisitasRealizadas;
        this.totalCuentasEnEsperaConfirmacion = respuesta.data.totalCuentasEnEsperaConfirmacion
        this.loadingCuentas = false;
      }, (err: any) => {
        this.loadingCuentas = false;
        this.app.showError(err);
      });
  }

  obtenerTotalVisitasRealizadas(query = '') {
    this.loadingVisitas = true;
    this.dashboardUserServ.obtenerTotalesVisitas(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingVisitas = false;
        this.totalVisitasRealizadas = respuesta.data.totalVisitas;

      }), (err: any) => {
        this.loadingVisitas = false;
        this.app.showError(err);
      };
  }
  obtenerTotalAcreditadosVisitados(query = '') {
    this.loadingAcreditadosVisitados = true;
    this.dashboardUserServ.obtenerTotalAcreditadosVisitados(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingAcreditadosVisitados = false;
        this.totalAcreditadosVisitados = respuesta.data.totalAcreditadosVisitados;

      }, (err: any) => {
        this.loadingAcreditadosVisitados = false;
        this.app.showError(err);
      });
  }

  obtenerTotalCobradoresVinculados(query = '') {
    this.loadingKobrasVinculados = true;
    this.dashboardUserServ.obtenerTotalCobradoresVinculados(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingKobrasVinculados = false;
        this.totalCobradoresVinculados = respuesta.data.totalCobradoresVinculados;

      }, (err: any) => {
        this.loadingKobrasVinculados = false;
        this.app.showError(err);
      });
  }

  obtenerTotalRevisitas(query= '') {
    this.loadingRevisitas = true;
    this.dashboardUserServ.obtenerTotalRevisitas(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingRevisitas = false;
        this.totalRevisitas = respuesta.data.totalRevisitas;

      }, (err: any) => {
        this.loadingRevisitas = false;
        this.app.showError(err);
      });
  }

  obtenerTotalPromesasPagoAgentes(query = '') {
    this.loadingPromesasPagoRegistradasAgentes = true;
    this.dashboardUserServ.obtenerTotalPromesasPagoAgentes(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingPromesasPagoRegistradasAgentes = false;
        this.totalPromesasPagoAgentes = respuesta.data.totalPromesasPagoAgentes;

      }, (err: any) => {
        this.loadingPromesasPagoRegistradasAgentes = false;
        this.app.showError(err);
      });
  }

  obtenerTotalPromesasPagoAcreditados(query = '') {
    this.loadingPromesasPagoRegistradasAcreditado = true;
    this.dashboardUserServ.obtenerTotalPromesasPagoAcreditados(query).pipe(take(1))
      .subscribe((respuesta: any) => {
        this.loadingPromesasPagoRegistradasAcreditado = false;
        this.totalPromesasPagoAcreditados = respuesta.data.totalPromesasPagoAcreditados;

      }, (err: any) => {
        this.loadingPromesasPagoRegistradasAcreditado = false;
        this.app.showError(err);
      });
  }

  cambioInicio($event) {
    const fechaInicio = moment($event.value).format('YYYY-MM-DD');
    this.minFin = fechaInicio;
    this.filtrosForm.get('fechaInicio').setValue(fechaInicio);
    //this.filtrosForm.value.fechaInicio = moment(new Date($event)).format('YYYY-MM-DD');
    if (this.primeraVez) {
      if (this.filtrosForm.value.fechaFin) {
        this.filtrosForm.value.fechaFin = moment(new Date(this.filtrosForm.value.fechaFin)).format('YYYY-MM-DD')
      }
    } else {
      if (this.limpioFiltro) {
        if (!this.cambioFechaFin) {
          this.filtrosForm.value.fechaFin = null;
        } else {
          this.filtrosForm.value.fechaFin = moment(new Date(this.filtrosForm.value.fechaFin)).format('YYYY-MM-DD')
        }
      } else {
        if (this.filtrosForm.value.fechaFin) {
          this.filtrosForm.value.fechaInicio = moment(new Date(this.filtrosForm.value.fechaInicio)).format('YYYY-MM-DD')
          this.limpioFiltro = false;
        }
      }
    }
    this.cambioFechaInicio = true;
  }

  cambioFin($event) {
    
    const fechaFin = moment($event.value).format('YYYY-MM-DD');
    this.maxInicio = fechaFin;
    this.filtrosForm.get('fechaFin').setValue(fechaFin);
   
    if (this.primeraVez) {
      if (this.filtrosForm.value.fechaInicio) {
        this.filtrosForm.value.fechaInicio = moment(new Date(this.filtrosForm.value.fechaInicio)).format('YYYY-MM-DD')
      }
    } else {
      if (this.limpioFiltro) {
        if (!this.cambioFechaInicio) {
          this.filtrosForm.value.fechaInicio = null;
        } else {
          this.filtrosForm.value.fechaInicio = moment(new Date(this.filtrosForm.value.fechaInicio)).format('YYYY-MM-DD')
        }
      } else {
        if (this.filtrosForm.value.fechaInicio) {
          this.filtrosForm.value.fechaInicio = moment(new Date(this.filtrosForm.value.fechaInicio)).format('YYYY-MM-DD')
          this.limpioFiltro = false;
        }
      }
    }
    this.cambioFechaFin = true;


  }

  filtrar() {
    let query = this.obtenerFiltros();
 
    if (this.filtrosForm.value.fechaInicio && this.filtrosForm.value.fechaFin) {
      this.obtenerTotalAcreditadosVisitados(query);
      this.obtenerTotalVisitasRealizadas(query);
      this.obtenerTotalCobradoresVinculados(query);
      this.obtenerTotalRevisitas(query);
      this.obtenerTotalPromesasPagoAgentes(query);
      this.obtenerTotalPromesasPagoAcreditados(query);
      this.primeraVez = false;
    } else {
      this.app.showAlert("Aviso", "Favor de capturar ambas fechas");
    }

  }

  filtrarProductoSucursal(){
      let query = this.obtenerFiltros();
      this.obtenerTotalesCuentas(query);
      this.obtenerTotalAcreditadosVisitados(query);
      this.obtenerTotalVisitasRealizadas(query);
      this.obtenerTotalRevisitas(query);
      this.obtenerTotalPromesasPagoAgentes(query);
      this.obtenerTotalPromesasPagoAcreditados(query);
      //this.mapboxDashboard.limpiarGeoJson();
      //this.mapboxDashboard.consultarCuentasMapa(query,true);
  }

  noFiltrar() {
    this.obtenerTotalAcreditadosVisitados();
    this.obtenerTotalVisitasRealizadas();
    this.obtenerTotalCobradoresVinculados();
    this.obtenerTotalRevisitas();
    this.obtenerTotalPromesasPagoAgentes();
    this.obtenerTotalPromesasPagoAcreditados();
    this.filtrosForm.patchValue({
      fechaInicio: null,
      fechaFin: null
    });
    this.limpioFiltro = true;
    this.primeraVez = false;
    this.cambioFechaFin = false;
    this.cambioFechaInicio = false;
  }

  loadProducts() {
    this.loadingProducts = true;

    this.dashboardUserServ.obtenerProductos(this.sessionData.idCliente)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.loadingProducts = false;
        this.products = res.data;
      }, (err: any) => {
        this.loadingProducts = false;
        this.app.showError(err);
      });
  }

  public loadSucursales() {
    this.loadingSucursales = true;

    this.dashboardUserServ.obtenerSucursales()
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.loadingSucursales = false;
        this.sucursales = res.data;
      }, (err: any) => {
        this.loadingSucursales = false;
        this.app.showError(err);
      });

  }

  obtenerFiltros(){
    let fechaInicial = this.filtrosForm.value.fechaInicio ? moment(this.filtrosForm.value.fechaInicio).format('YYYY-MM-DD') : '';
    let fechaFinal = this.filtrosForm.value.fechaFin ? moment(this.filtrosForm.value.fechaFin).format('YYYY-MM-DD') : '';
    let producto = this.filtrosGeneralesForm.value.producto ?this.filtrosGeneralesForm.value.producto: '';
    let sucursal = this.filtrosGeneralesForm.value.sucursal ?this.filtrosGeneralesForm.value.sucursal: '';

    var query = '?';
    query += (fechaInicial && fechaFinal)?`startDate=${fechaInicial}&toDate=${fechaFinal}&`:``;
    if(producto){
      query += `idProducto=${producto}&`;
    }
    if(sucursal.idSucursal){
      query+= `idSucursal=${sucursal.idSucursal}&`;
    }
    if(sucursal.idGrupo){
      query+= `idGrupo=${sucursal.idGrupo}&`;
    }
    return query;
  }

  VerCuentasActivas() {
    this.navegarCuentas(1);
  }

  VerCuentasEsperaConfirmacion() {
    this.navegarCuentas(2);
  }

  VerCuentasApartadas() {
    this.navegarCuentas(3);
  }

  VerVisitasEspera() {
    this.navegarVisitas();
  }

  navegarCuentas(estatus) {
    let filtrosCuentas: any = {
      estatus: estatus
    };
    if(this.filtrosGeneralesForm.value.producto){
      filtrosCuentas.tipoCuenta = this.filtrosGeneralesForm.value.producto;
    }
    if(this.filtrosGeneralesForm.value.sucursal){
      filtrosCuentas.sucursal = this.filtrosGeneralesForm.value.sucursal.idSucursal;
    }
    this.filtrosServices.guardarFiltros(filtrosCuentas, true, 'cuentas/domicilios');
  }
  navegarVisitas() {
    let filtrosCuentas: any = {};
    if(this.filtrosGeneralesForm.value.producto){
      filtrosCuentas.idProducto = this.filtrosGeneralesForm.value.producto;
    }
    if(this.filtrosGeneralesForm.value.sucursal){
      filtrosCuentas.idSucursal = this.filtrosGeneralesForm.value.sucursal.idSucursal;
    }
    this.filtrosServices.guardarFiltros(filtrosCuentas, true, 'visits');
    
    /*this.router.navigate(
      ['/visits']
    );*/
  }

}
