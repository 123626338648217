<div class="app content kobra">
  <div>
    <mat-grid-list-responsive [colsMd]="3" [colsSm]="1" rowHeight="50px">
      <div [formGroup]="filtrosGeneralesForm">
        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field class="form-field ">
            <mat-label>{{loadingProducts ? 'Cargando productos...' : 'Producto'}}</mat-label>
            <mat-select formControlName="producto" (ngModelChange)="filtrarProductoSucursal()">
              <mat-option [value]="null">Seleccione...</mat-option>
              <mat-option *ngFor="let product of products" [value]="product.idProducto">
                {{product.nomProducto}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field class="form-field ">
            <mat-label>{{loadingSucursales ? 'Cargando sucursales...' : 'Sucursal'}}</mat-label>
            <mat-select (selectionChange)="filtrarProductoSucursal()" formControlName="sucursal">
              <mat-option [value]="null">Seleccione...</mat-option>
              <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal">
                {{sucursal.nombreSucursal}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </div>

    </mat-grid-list-responsive>

    <mat-card class="box-shadow" style="margin-bottom: 20px;">
      <mat-card-content>
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="200px">

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor" (click)="VerCuentasActivas()">
              <mat-card-content>
                <app-loader *ngIf="loadingCuentas" [message]="'Cargando cuentas activas...'"></app-loader>
                <div *ngIf="!loadingCuentas" class="valor valor-centered">
                  {{totalCuentasActivas | number}}
                </div>
                <div class="titulo" *ngIf="!loadingCuentas">
                  Cuentas activas
                </div>
                <div class="inner">
                  <span style="font-size: 1.6rem;">Ver cuentas activas</span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor" (click)="VerCuentasApartadas()">
              <mat-card-content>
                <app-loader *ngIf="loadingCuentas" [message]="'Cargando cuentas apartadas...'"></app-loader>
                <div *ngIf="!loadingCuentas" class="valor valor-centered">
                  {{totalCuentasApartadas | number}}
                </div>
                <div class="titulo" *ngIf="!loadingCuentas">
                  Cuentas apartadas
                </div>
                <div class="inner">
                  <span style="font-size: 1.6rem;">Ver cuentas apartadas</span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor" (click)="VerCuentasEsperaConfirmacion()">
              <mat-card-content>
                <app-loader *ngIf="loadingCuentas"
                  [message]="'Cargando cuentas en espera de confirmación...'"></app-loader>
                <div *ngIf="!loadingCuentas" class="valor valor-centered">
                  {{totalCuentasEnEsperaConfirmacion | number}}
                </div>
                <div class="titulo" *ngIf="!loadingCuentas">
                  Cuentas en espera de confirmación
                </div>
                <div class="inner">
                  <span style="font-size: 1.6rem;"> Ver cuentas en espera</span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor" (click)="VerVisitasEspera()">
              <mat-card-content>
                <app-loader *ngIf="loadingCuentas" [message]="'Cargando cuentas visitadas...'"></app-loader>
                <div *ngIf="!loadingCuentas" class="valor valor-centered">
                  {{totalVisitasRealizadasGeneral | number}}
                </div>
                <div class="titulo" *ngIf="!loadingCuentas">
                  Visitas realizadas
                </div>
                <div class="inner">
                  <span style="font-size: 1.6rem;">Ver visitas realizadas</span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

        </mat-grid-list-responsive>
      </mat-card-content>
    </mat-card>

    <mat-card class="box-shadow" style="margin-bottom: 20px;">
      <mat-card-header>
        <mat-card-title>Resumen de actividad (Histórico)</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-container">
              <form [formGroup]="filtrosForm" class="form-elements">
            <div class="form-element">
              <mat-form-field>
                <mat-label>Fecha Inicio</mat-label>
                <input matInput textSanitizer  [matDatepicker]="fechaInicioPicker" formControlName="fechaInicio"
                  (dateChange)="cambioInicio($event)" [max]="maxInicio" placeholder="Capture la fecha inicio" readonly>
                <mat-datepicker-toggle matSuffix [for]="fechaInicioPicker"></mat-datepicker-toggle>
                <mat-datepicker #fechaInicioPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-element">
              <mat-form-field>
                <mat-label>Fecha fin</mat-label>
                <input matInput textSanitizer  [matDatepicker]="fechaFinPicker" formControlName="fechaFin"
                  (dateChange)="cambioFin($event)" [max]="maxFin" [min]="minFin" placeholder="Capture la fecha fin"
                  readonly>
                <mat-datepicker-toggle matSuffix [for]="fechaFinPicker"></mat-datepicker-toggle>
                <mat-datepicker #fechaFinPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-element">
              <button class="margen-boton"
              style="margin-top: 10px ; margin-right: -10px;"
              mat-stroked-button 
              color="primary"
              (click)="filtrar()">
              <mat-icon>filter_alt</mat-icon> 
              Filtrar</button>
            </div>
            <div class="form-element">
              <button
              style="margin-top: 10px ;"
              mat-stroked-button 
              color="default" 
              (click)="noFiltrar()">
              <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
              Limpiar filtros</button>
            </div>
          </form>
         
        </div>

        <mat-grid-list-responsive [colsMd]="5" [colsSm]="1" rowHeight="200px">

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor">
              <mat-card-content>
                <app-loader *ngIf="loadingVisitas" [message]="'Cargando visitas...'"></app-loader>
                <div *ngIf="!loadingVisitas" class="valor valor-centered">
                  {{totalVisitasRealizadas | number}}
                </div>
                <div class="titulo" *ngIf="!loadingVisitas">
                  Visitas
                </div>

              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor">
              <mat-card-content>
                <app-loader *ngIf="loadingAcreditadosVisitados"
                  [message]="'Cargando clientes visitados...'"></app-loader>
                <div *ngIf="!loadingAcreditadosVisitados" class="valor valor-centered">
                  {{totalAcreditadosVisitados | number}}
                </div>
                <div class="titulo" *ngIf="!loadingAcreditadosVisitados">
                  Clientes visitados
                </div>

              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor">
              <mat-card-content>
                <app-loader *ngIf="loadingKobrasVinculados" [message]="'Cargando kobras vinculados...'"></app-loader>
                <div *ngIf="!loadingKobrasVinculados" class="valor valor-centered">
                  {{totalCobradoresVinculados | number}}
                </div>
                <div class="titulo" *ngIf="!loadingKobrasVinculados">
                  Kobras vinculados
                </div>

              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor">
              <mat-card-content>
                <app-loader *ngIf="loadingRevisitas" [message]="'Cargando revisitas...'"></app-loader>
                <div *ngIf="!loadingRevisitas" class="valor valor-centered">
                  {{totalRevisitas | number}}
                </div>
                <div class="titulo" *ngIf="!loadingRevisitas">
                  Revisitas
                </div>

              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor">
              <mat-card-content>
                <app-loader *ngIf="loadingPromesasPagoRegistradasAgentes"
                  [message]="'Cargando promesas de pago...'"></app-loader>
                <div *ngIf="!loadingPromesasPagoRegistradasAgentes" class="valor valor-centered">
                  {{totalPromesasPagoAgentes | number}}
                </div>
                <div class="titulo" *ngIf="!loadingPromesasPagoRegistradasAgentes">
                  Promesas de pago
                </div>

              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

        </mat-grid-list-responsive>
      </mat-card-content>
    </mat-card>


    <mat-card class="box-shadow">
      <mat-card-header>
        <mat-card-title>Mapa actual del estado de tus cuentas</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxFlex="100">
          <mapbox-dashboard [lat]="24.79032" [lng]="-107.38782" [products]="products" [sucursales]="sucursales"
            #mapboxdashboard></mapbox-dashboard>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>