import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

import { environmentSelector } from "../../../environments/environment.selector";
import { AccountPaginator } from "../agent.paginator";
import { Agent } from "../agent.model";
import { AgentStatus } from "../agent-status.model";
import { AgentService } from "../agent.service";
import { AgentChecklist } from "../../agent-checklist/agent-checklist.model";
import { AgentChecklistService } from "../../agent-checklist/agent-checklist.service";
import { MessageService } from "../../shared/message/message.service";
import { PersonalReferenceService } from "../../personal-reference/personal-reference.service";
import { WorkExperienceService } from "../../work-experience/work-experience.service";
import { WorkReferenceService } from "../../work-reference/work-reference.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LenderService } from '@lender/lender.service';
import { Lender } from '@lender/lender.model';
import { StateService } from '../../state/state.service';
import { MunicipalityService } from '../../municipality/municipality.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/internal/operators/take';
import { PermisosService } from 'src/app/application/shared/permisos.service';
import { ModalEstatusAgenteComponent } from '../modales/estatus/estatus-agente.component';
import { ModalVinculacionProductosComponent } from '../modales/vinculacion/vinculacion-productos.component';
import { ModalDesvinculacionComponent } from '../modales/desvinculacion/desvinculacion.component';
import {MatDialog} from '@angular/material/dialog';  
import { map } from 'rxjs/internal/operators/map';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http'; // Importar HttpClient
import { saveAs } from 'file-saver';
import { LoadingService } from '../../shared/components/loading-bar/loading-bar.service';
import moment from 'moment';

@Component({
  selector: 'agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css'],
  providers: [
    AgentService,
    AgentChecklistService,
    PersonalReferenceService,
    WorkExperienceService,
    WorkReferenceService,
    LenderService,
    StateService,
    MunicipalityService
  ]
})

export class AgentListComponent implements OnInit {
  isLoadAgentes: boolean = false;
  itemsSelected: Array<any>[] = [];
  sessionData: SessionData;
  ayuda: boolean = false;
  hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;

  public visibility: number = null;
  public agent: Agent;
  public agents: Agent[];
  public allRowsSelected: boolean;
  public listFields: any = {};
  public paginator: AccountPaginator;
  public querySearch: string;
  public showActions: boolean;
  public sortAsc: string = '';
  public listFiltered: boolean;
  public sortField: string = 'c.deleted ASC, c.nombre';
  private $statusModal: any;
  public agentsSelected: Agent[];
  private selectedAgent: Agent;
  public selectedStatus: number = null;
  public allAgentsSelected: boolean;
  public statusLoader = false;
  public setStatusMsg: string;
  public agentsStatus: AgentStatus[];
  public labelEstado: string;
  public labelMunicipio: string;
  public labelCargaEstado: string;
  public labelCargaMunicipio: string;
  public firstTime: boolean = true;
  public estados: any[];
  public municipios: any[];
  public filterAgentAvailable: boolean;
  today = new Date();
  maxInicio = this.today;
  minFin = this.today;
  maxFin = this.today;
  primerClickFechaInicio = true;
  //@ViewChild('agregarProductoModal') public agregarProductoModal: ModalTemplate<{}, string, string>;
  public lenders: Lender[];
  public lendersFiltros: Lender[];
  public formAgregarProducto: FormGroup;
  public products: Array<any>;
  //@ViewChild('multiProductSelect') public multiProductSelect: SuiMultiSelect<any, any>;
  public productosSeleccionados: any;
  public selectedProducts;
  private environment = environmentSelector();


  //@ViewChild('multiCreditSelect') public multiCreditSelect: SuiMultiSelect<any, any>;
  public formAgregarCredito: FormGroup;
  public credits: Array<any>;
  public selectedCredits;
  public creditosSeleccionados: any;
  public validarMenu: any;
  tablaAgentes: any;
  public queryParamsGlobal:string;

  public filtros = {
    fecInicio: null,
    fecFin: null,
    buroLaboral: null,
    primerContacto: null,
    disponible: null,
    vinculados: null,
    idCobradorEstatus: null,
    idCobrador: null,
    idFinanciera: null,
    idEstado: null,
    idMunicipio: null,
    visibilidad: null,
    page: 1,
    querySearch: null,
    idCobradorPorNombre:null,
    idCobradorPorCorreo:null
  }

  public loadings: any = {
    municipios: true,
    estados: true
  }

  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  constructor(
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private agentService: AgentService,
    private agentChecklistService: AgentChecklistService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private personalReferenceService: PersonalReferenceService,
    private rootElement: ElementRef,
    private router: ActivatedRoute,
    private routerNav: Router,
    //private localizationService: SuiLocalizationService,
    private workExperienceService: WorkExperienceService,
    private workReferenceService: WorkReferenceService,
    //private modalService: SuiModalService,
    private lenderService: LenderService,
    private stateService: StateService,
    private municipalityService: MunicipalityService,
    private localStorageService: LocalStorageService,
    private permisosService: PermisosService,
    public dialog: MatDialog,
    private http: HttpClient,
    private loadingService: LoadingService


  ) {
    
    this.sessionData = this.localStorageService.getSessionData();
    //localizationService.load('es', es);
    //localizationService.setLanguage('es');
    this.agent = new Agent();
    this.agents = [];
    this.paginator = new AccountPaginator();
    this.paginator.pagination.perPage = 50;
    var menu = localStorage.getItem("menu");
    this.validarMenu = this.permisosService.getPermiso;
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelEstado = this.environment.labelEstado;
    this.labelCargaEstado = this.environment.labelEstadoPlural;
    this.labelCargaMunicipio = this.environment.labelMunicipioPlural;
    this.crearTabla();
    this.createForms();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
        'idFinanciera'
    ]);

    this.contabilizadorFiltros.add('Generales', 'generales', [
        'visibilidad',
        'buroLaboral',
        'primerContacto',
        'disponible',
       
    ]);

    this.contabilizadorFiltros.add('Generales', 'generalesAdmin', [
        'vinculados'
    ]);

    this.contabilizadorFiltros.add('Ubicación', 'ubicacion', [
        'idEstado',
        'idMunicipio'
    ]);

    this.contabilizadorFiltros.add('Fechas', 'fechas', [
        'fecInicio',
        'fecFin'
    ]);

    this.contabilizadorFiltros.add('Agente', 'agente', [
        'idCobradorPorNombre',
        'idCobradorPorCorreo'
    ]);
}

public contabilizadorDeFiltros(filtroName: string){
  return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
}

public contabilizadorDeFiltrosTotales():void{
    

    let totalFiltrosArray: number[] = [];
    if(this.sessionData.isSuperAdmin){
        totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
    }
    if(!this.sessionData.isSuperAdmin){
      totalFiltrosArray.push( this.contabilizadorDeFiltros('generalesAdmin') );
    }
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('ubicacion') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('fechas') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  private subscribeRouterParams(filtrosNuevos: any): void {
    if(filtrosNuevos){
      this.listFiltered = true;
      this.filtros = filtrosNuevos;

      this.paginator.pagination.currentPage = this.filtros.page || 1;
    }

    this.loadLendersFiltros();
    let page = this.paginator.pagination.currentPage;
    this.loadAgents(page);
  }

  ngOnInit() {
    let nombreAgente: string = this.router.snapshot.queryParams['agente'];

    if (nombreAgente != null) {
      this.filtros.querySearch = nombreAgente;
    }

    if(!this.sessionData.isSuperAdmin){
      this.filtros.visibilidad = '1';
    }
    
    //this.initSemantic();
    this.initListColumns();
    this.loadAgentStatus();
    this.loadEstados();
    this.loadMunicipios();
    this.onAddProductType();
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
    this.contabilizadorDeFiltrosTotales();
  }

  hijoDesactivado() {
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.idFinanciera){
        this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
  }

  crearTabla() {
    this.tablaAgentes = {
        headers: false,
        multiSelect: true,
        columns: [
            {
                name: 'ID Agente',
                key: 'id',
                hide: false,
                link: '/agentes/',
                type: 'link',
                sortName: 'c.idCobrador'
            }, {
                name: 'Agente',
                key: 'fullName',
                hide: false,
                sortName: 'c.nombreCompleto'
            }, {
                name: 'Primer Contacto',
                key: 'primerContacto',
                hide: false,
                type: 'boolean'
            }, {
                name: 'Activo',
                key: 'disponible',
                hide: false,
                type: 'boolean'
            }, {
                name: 'Estatus',
                key: 'cobradorEstatus.nombre',
                hide: false, 
                type: 'estatusAgente'
            }, {
                name: this.labelMunicipio,
                key: 'address.city',
                hide: false,
                sortName: 'municipio'
            }, {
                name: this.labelEstado,
                key: 'address.state',
                hide: false,
                sortName: 'estado'
            }, {
                name: 'C.P.',
                key: 'address.postalCode',
                hide: false,
                type: 'estatus'
            }, {
                name: (this.sessionData.isSuperAdmin ? 'Ingreso' : 'Fecha Registro'),
                key: 'createdAt',
                hide: false, 
                type: 'dateTime',
                sortName: 'c.createdAt'
            }, {
                name: 'Puntos',
                key: 'ranking',
                hide: false,
                sortName: 'c.ranking'
            }, {
                name: 'Visitas',
                key: 'visits.length',
                hide: false,
                sortName: 'c.idCobrador'
            }, {
                name: 'Foto',
                key: 'checklist.profilePicture',
                hide: false
            }, {
                name: 'Academia',
                key: 'checklist.certification',
                hide: false,
                type: 'boolean'
            }, {
                name: 'Ref. Per.',
                key: 'checklist.personalReferences',
                hide: false
            }, {
                name: 'Exp. Lab.',
                key: 'checklist.workExperience',
                hide: false
            }, {
                name: 'Ref. Lab.',
                key: 'checklist.workReferences',
                hide: false
            }, {
                name: 'Visibilidad',
                key: 'visibility',
                hide: false
            }, {
                name: 'Cuentas Desasignadas',
                key: 'desassignedAccountCount',
                hide: false
            }, {
                name: 'Cuentas Asignadas',
                key: 'assignedAccountCount',
                hide: false
            }, {
                name: 'Buró Laboral',
                key: 'buroLaboral',
                hide: false,
                type: 'boolean'
            }, {
                name: 'Alta Hacienda',
                key: 'cedulaFiscal',
                hide: false,
                type: 'boolean'
            }
        ]
    }
}

  /*private initSemantic(): void {
    this.$filterModal.modal({
      closable: false
    });
  }*/

  public checkPersonalReference(response: number): void {
    let checklist: AgentChecklist = new AgentChecklist();
    checklist.agentID = this.agent.id;
    checklist.personalReferences = response;
    let loading: number = this.app.showLoading('Guardando validación...');
    //this.app.showHttpLoader('Guardando validación...');
    this.agentChecklistService
      .checkPersonalReferences(checklist)
      .subscribe(
        () => {
          this.app.hideLoading(loading);
          this.messageService.success(`
            Referencias personales del cobrador
            ${this.agent.fullName} fueron
            ${response == 1 ? 'aprovadas' : 'rechzadas'}.
          `);
          this.loadAgents(this.paginator.pagination.currentPage);
        },
        (error) => {
          this.messageService.httpError(error);
          this.app.hideLoading(loading);
        }
      );
  }

  public checkProfilePicture(response: number): void {
    let checklist: AgentChecklist = new AgentChecklist();
    checklist.agentID = this.agent.id;
    checklist.profilePicture = response;
    let loading: number = this.app.showLoading('Guardando validación...');
    this.agentChecklistService
      .checkProfilePicture(checklist)
      .subscribe(
        () => {
          this.app.hideLoading(loading);
          this.messageService.success(`
            Foto de perfil del cobrador
            ${this.agent.fullName} fue
            ${response == 1 ? 'aprovada' : 'rechzada'}.
          `);
          this.loadAgents(this.paginator.pagination.currentPage);
        },
        (error) => {
          this.messageService.httpError(error);
          this.app.hideLoading(loading);
        }
      );
  }

  public checkWorkExperience(response: number): void {
    let checklist: AgentChecklist = new AgentChecklist();
    checklist.agentID = this.agent.id;
    checklist.workExperience = response;
    let loading: number = this.app.showLoading('Guardando validación...');
    this.agentChecklistService
      .checkWorkExperience(checklist)
      .subscribe(
        () => {
          this.app.hideLoading(loading);
          this.messageService.success(`
            Referencias laborales del cobrador
            ${this.agent.fullName} fueron
            ${response == 1 ? 'aprovadas' : 'rechzadas'}.
          `);
          this.loadAgents(this.paginator.pagination.currentPage);
        },
        (error) => {
          this.messageService.httpError(error);
          this.app.hideLoading(loading);
        }
      );
  }

  public checkWorkReferences(response: number): void {
    let checklist: AgentChecklist = new AgentChecklist();
    checklist.agentID = this.agent.id;
    checklist.workReferences = response;
    let loading: number = this.app.showLoading('Guardando validación...');
    this.agentChecklistService
      .checkWorkReferences(checklist)
      .subscribe(
        () => {
          this.app.hideLoading(loading);
          this.messageService.success(`
            Referencias laborales del cobrador
            ${this.agent.fullName} fueron
            ${response == 1 ? 'aprovadas' : 'rechzadas'}.
          `);
          this.loadAgents(this.paginator.pagination.currentPage);
        },
        (error) => {
          this.messageService.httpError(error);
          this.app.hideLoading(loading);
        }
      );
  }

  public checkSelectedRows(): void {
    this.showActions = this.getSelectedAgents().length ? true : false;
  }

  public clearFilter(): void {
    Object.keys(this.filtros).forEach(key => {
      if((this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera'))){
        this.filtros[key] = null;
      }
    });

    this.filtros.buroLaboral = null;
    this.filtros.primerContacto = null;
    this.filtros.disponible = null;
    this.filtros.vinculados = null;
    this.filtros.page = 1;
    this.filtrosService.removerFiltros();
    this.listFiltered = false;
    //this.$filterModal.modal('hide');
    //this.loadAgents();
  }

  private createForms(): void {
    this.formAgregarProducto = this.formBuilder.group({
      idFinanciera: ["", Validators.required],
      products: ["", Validators.required],
      credits: [""]
    });
  }

  private initListColumns(): void {
    let listFields: any = JSON.parse(localStorage.getItem('agentListFields')) || {};
    this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
    this.listFields.agentLocation = listFields.agentLocation == false ? false : true;
    this.listFields.agentCreatedAt = listFields.agentCreatedAt == false ? false : true;
    this.listFields.agentLinkedLenderName = listFields.agentLinkedLenderName == false ? false : true;
    this.listFields.agentVisits = listFields.agentVisits == false ? false : true;
    this.listFields.agentProfilePicture = listFields.agentProfilePicture == false ? false : true;
    this.listFields.agentCritified = listFields.agentCritified == false ? false : true;
    this.listFields.hasPersonalReferences = listFields.hasPersonalReferences == false ? false : true;
    this.listFields.hasWorkExperience = listFields.hasWorkExperience == false ? false : true;
    this.listFields.hasWorkReferences = listFields.hasWorkReferences == false ? false : true;
    this.listFields.visibility = listFields.visibility == false ? false : true;
    this.listFields.desassignedAccountCount = listFields.desassignedAccountCount == false ? false : true;
    this.listFields.assignedAccountCount = listFields.assignedAccountCount == false ? false : true;
    this.listFields.bureauLabor = listFields.bureauLabor == false ? false : true;
    this.listFields.cedulaFiscal = listFields.cedulaFiscal == false ? false : true;
    this.listFields.disponible = listFields.disponible == false ? false : true;
    this.listFields.primerContacto = listFields.primerContacto == false ? false : true;
    this.listFields.cobradorEstatus = listFields.cobradorEstatus == false ? false : true;
    this.listFields.ranking = listFields.ranking == false ? false : true;
  }

  private createQueryParams (){
    this.queryParamsGlobal = "";
    if (this.filtros.querySearch) {
      this.queryParamsGlobal += `&query=${this.filtros.querySearch || ''}`;
    }

    if (this.filtros.visibilidad) {
      this.queryParamsGlobal += this.filtros.visibilidad == 1 ? '&cobradorInterno=1' : '&cobradorInterno=0';
    }

    if (this.listFiltered) {
      if (this.filtros.idFinanciera) {
        this.queryParamsGlobal += '&idFinanciera=' + this.filtros.idFinanciera;
      }

      //filtro buro
      if (this.filtros.buroLaboral != null) {
        this.queryParamsGlobal += '&buroLaboral=' + this.filtros.buroLaboral;
      }

      //filtro primer contacto
      if (this.filtros.primerContacto != null) {
        this.queryParamsGlobal += '&primerContacto=' + this.filtros.primerContacto;
      }

      //disponible
      if (this.filtros.disponible != null && this.filtros.visibilidad != 1) {
        this.queryParamsGlobal += '&disponible=' + this.filtros.disponible ;
      }
      
      if (this.filtros.vinculados && this.filtros.vinculados != null) {
        this.queryParamsGlobal += '&vinculados=' + this.filtros.vinculados;
      }

      if (this.filtros.idFinanciera && this.sessionData.isSuperAdmin) {
        this.queryParamsGlobal += '&vinculados=true';
      }

      if (this.filtros.idCobradorEstatus) {
        this.queryParamsGlobal += '&idCobradorEstatus=' + this.filtros.idCobradorEstatus;
      }

      if (this.filtros.idCobrador) {
        this.queryParamsGlobal += '&idCobrador=' + this.filtros.idCobrador;
      }

      if (this.filtros.idEstado) {
        this.queryParamsGlobal += '&idEstado=' + this.filtros.idEstado;
      }

      if (this.filtros.idMunicipio) {
        this.queryParamsGlobal += '&idMunicipio=' + this.filtros.idMunicipio;
      }

      if (this.filtros.fecInicio && this.filtros.fecFin) {
        this.queryParamsGlobal += '&fecInicio=' + this.filtros.fecInicio.toISOString().substr(0, 10);
        this.queryParamsGlobal += '&fecFin=' + this.filtros.fecFin.toISOString().substr(0, 10);
      }

      else if (this.filtros.fecInicio || this.filtros.fecFin) {
        if (!this.firstTime) {
          this.app.showSnackbar('Aviso', 'Se debe colocar un rango valido de fechas para filtrar', 3000, 'warning');
          //return; corregir esto en nuevo angular
        }
      }
    }

    return this.queryParamsGlobal;
  }

  public agenteFilterSearch = function(term: string) {
    return this.agentService.obtenerAgentes(term)
        .pipe(
            take(1),
            map((res: any) => res.data.map(
                ({ idCobrador, nombreCompleto, idAgente }) => (
                    { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                )
            ))
        );
  }.bind(this);

  public buscarPorCorreoAgente = function(term: string) {
    return this.agentService.obtenerAgentes(null, term)
        .pipe(
            take(1),
            map((res: any) => res.data.map(
                ({ idCobrador, correo, idAgente }) => (
                    { idCobrador: idCobrador, correo: `${idCobrador} - ${correo}`, idAgente: idAgente }
                )
            ))
        );
  }.bind(this);

  public loadAgents(page: number = 1, next?: () => void): void {
    let slt: string = '"agentID","buroLaboral","agentFullName","municipio","estado","postalCode","agentProfilePicture","agentCreatedAt","agentChecklistProfilePicture","agentChecklistCertification","agentChecklistPersonalReferences","agentChecklistWorkExperience","agentChecklistWorkReferences","visibility","agentVisitsCount","bureauLabor","cedulaFiscal","disponible","primerContacto","cobradorEstatus","ranking","deleted"';
    let oderBy: string = `${this.sortField}` + (this.sortAsc ? ' asc' : ' desc');
    let query: string = `?slt=${slt}&page=${page}&orderBy=${oderBy}`;

    query += this.createQueryParams();

    //this.$filterModal.modal('hide');
    this.firstTime = false;

    this.paginator.pagination.nextPage = page;
    if(page != this.filtros.page){
      this.filtros.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }
    this.isLoadAgentes = true;
    //let loading: number = this.app.showLoading('Cargando agentes...');

    this.agentService.obtenerListado(this.paginator.pagination, query, page.toString()).subscribe(
      (paginator: AccountPaginator) => {
        this.paginator = paginator;
        this.agents = paginator.data;
        //this.app.hideLoading(loading);
        this.isLoadAgentes = false;
        if (next) {
          next();
        }
      },
      (error) => {
        this.app.showError(error);
        //this.app.hideLoading(loading);
        this.isLoadAgentes = false;
      },
      () => this.isLoadAgentes = false
    );
  }

 

  public descargarListado() {
    this.loadingService.setLoading(true);

    let queryParams = '?token=' + localStorage.getItem('access-token');
    queryParams += this.queryParamsGlobal; // Asumo que esta variable ya tiene otros parámetros preparados

   this.agentService.descargarListado(queryParams).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.loadingService.setLoading(false);
        const blob = new Blob([event.body], { type: event.body.type });
        const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
        saveAs(blob, `Listado-agentes-${fechaActual}`);
      }
    }, _error => {
      this.loadingService.setLoading(false);
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
      console.log(_error)
    });
  }



  public selectAllRows(): void {
    for (let i = 0; i < this.agents.length; i++) {
      this.agents[i].selected = this.allRowsSelected;
    }
    this.checkSelectedRows();
  }

  public setSortField(sort: any) {
    if(sort.isOrder){
      this.sortAsc = sort.direction;
      if (sort.active != this.sortField) {
        this.sortField = sort.active;
      }
      this.loadAgents();
    }
  }

  public setFilter(): void {
    this.filtros.page = 1;
    this.filtrosService.guardarFiltros(this.filtros);
    this.listFiltered = true;
    this.contabilizadorDeFiltrosTotales();
    this.loadAgents();
  }

  public showFilter(): void {
    //this.$filterModal.modal('show');
  }

  public nuevoAgente(): void {
    //this.$filterModal.modal('show');
  }

  public showPersonalReferenceModal(agent: Agent): void {
    agent.checklist.personalReferences =
      agent.checklist.personalReferences == null ? null :
        +agent.checklist.personalReferences;
    this.agent = agent;
    const loader = this.app.showLoading('Cargando referencias personanles...');
    this.personalReferenceService
      .all(agent.id).subscribe(
        (references) => {
          agent.personalReferences = references;
          this.app.hideLoading(loader);
          setTimeout(() => {
          }, 1);
        },
        (error) => {
          this.app.showError(error);
          this.app.hideLoading(loader);
        }
      );
  }

  public showProfilePictureModal(agent: Agent): void {
    agent.checklist.profilePicture =
      agent.checklist.profilePicture == null ? null :
        +agent.checklist.profilePicture;
    this.agent = agent;
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    let country = "&Country=";
    country+= paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
    this.agent.profilePictureFullPath = this.agent.profilePictureFullPath = `${this.environment.kobraStorage}/cobradores/${agent.id}/foto-perfil?token=${localStorage.getItem('access-token')}${country}`;
    
  }

  public showWorkExperienceModal(agent: Agent): void {
    agent.checklist.workExperience =
      agent.checklist.workExperience == null ? null :
        +agent.checklist.workExperience;
    this.agent = agent;
    const loader = this.app.showLoading('Cargando experiencia laboral...');
    this.workExperienceService.all(agent.id).subscribe(
      (experiences) => {
        this.agent.workExperiences = experiences;
        this.app.hideLoading(loader);
        setTimeout(() => {
        }, 1);
      },
      this.messageService.httpError
    );
  }

  public showWorkReferenceModal(agent: Agent): void {
    agent.checklist.workReferences =
      agent.checklist.workReferences == null ? null :
        +agent.checklist.workReferences;
    this.agent = agent;
    const loader = this.app.showLoading('Cargando referencias personanles...');
    this.workReferenceService
      .all(agent.id).subscribe(
        (references) => {
          agent.workReferences = references;
          this.app.hideLoading(loader);
          setTimeout(() => {
          }, 1);
        },
        (error) => {
          this.messageService.httpError(error);
          this.app.hideLoading(loader);
        }
      );
  }
  // private getSelectedAgents(): number[] {
  //   let agentsID: number[] = [];
  //   for (var i = 0; i < this.agents.length; i++) {
  //     if (this.agents[i].selected) {
  //       agentsID.push(this.agents[i].id);
  //     }
  //   }
  //   return agentsID;
  // }
  //funcion para obtener los agentes seleccionados
  private getSelectedAgents(): Agent[] {
    let agents: Agent[] = [];
    for (var i = 0; i < this.agents.length; i++) {
      if (this.agents[i].selected) {
        let agent: Agent = new Agent();
        agent.id = this.agents[i].id;
        agents.push(agent);
      }
    }
    return agents;
  }

  //funcion para mostrar el modal de estatus
  public showStatusModal(agente?: Agent): void {
    //this.selectedAgent = this.getSelectedAgents();
    this.selectedStatus = !agente ? 1 : agente.idCobradorEstatus;
    this.$statusModal.modal('show');
  }

  public setStatus(): void {
    let agents: Agent[] = [];
    if (this.selectedAgent) {
      agents.push(this.selectedAgent);
    } else {
      agents = this.getSelectedAgents();
    }
    if (agents.length) {
      this.agentsSelected = agents;
      this.$statusModal.modal('hide');
      this.statusLoader = true;

      this.sendAgentsForUpdateStatus(0);
    }
  }
  //funcion para agregar estatus
  public sendAgentsForUpdateStatus(pos: number) {
    if (pos >= this.agentsSelected.length) {
      this.statusLoader = false;
      this.selectedStatus = 1;
      this.allAgentsSelected = false;
      this.loadAgents(
        1, () => {
          this.showActions = false;
          this.app.showSnackbar('¡Aviso!', `${this.agentsSelected.length} cobradores fueron cambiados de estatus correctamente.`, 3000, 'success');
        }
      );
      return;
    }

    this.setStatusMsg = `Actualizando estatus ${pos + 1} de ${this.agentsSelected.length}`;
    let agent: any = {
      id: this.agentsSelected[pos].id,
      idCobradorEstatus: this.selectedStatus
    };

    this.agentService.update(agent)
      .subscribe(
        () => {
          this.sendAgentsForUpdateStatus(pos + 1);
        },
        (error) => {
          this.statusLoader = false;
          this.app.showError(error);
        }
      );
  }
  //seleccionada todos los cobradores
  public selectAllAgents(): void {
    for (let i = 0; i < this.agents.length; i++) {
      this.agents[i].selected = this.allAgentsSelected;
    }
    this.checkSelectedRows();
  }
  public loadAgentStatus(): void {

    //let loadingID: number = this.app.showLoading('Cargando estatus...');
    this.agentService.getAgentStatus().subscribe(
      res => {
        this.agentsStatus = res;
        //localStorage.setItem('agentEstatus', JSON.stringify(res));
      },
      err => {
        this.app.showError(err);
        //this.app.hideLoading(loadingID);
      },
      //() => this.app.hideLoading(loadingID)
    );
  }

  public closeModals(): void {
    this.$statusModal.modal('hide');
  }

  cambioInicio($event: any) {
    if ($event != null || $event != undefined) {
      this.minFin = new Date($event);
    }
    this.contabilizadorDeFiltros('fechas');
  }

  cambioFin($event: any) {
    if ($event != null || $event != undefined) {
      this.maxInicio = new Date($event);
    }
    this.contabilizadorDeFiltros('fechas');
  }

  onCobradorSelectionChange(idCobrador: any) {
    this.filtros.idCobradorPorCorreo = null;
    this.filtros.idCobrador = idCobrador;
    this.contabilizadorDeFiltros('agente');
  }
  
  onCobradorCorreoSelectionChange(idCobrador: any) {
    this.filtros.idCobradorPorNombre = null;
    this.filtros.idCobrador = idCobrador;
    this.contabilizadorDeFiltros('agente');
  }

  onAddProductType() {
    this.loadLenders();
    this.loadProducts();
  }

  loadLenders(): void {
    this.lenderService.getLenders().subscribe(
      (res: any) => {
        res.data.sort(this.compare);
        this.lenders = res.data;
        if(!this.sessionData.isSuperAdmin){
          this.formAgregarProducto.get('idFinanciera').patchValue(this.sessionData.idFinanciera);
        }
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }

  loadLendersFiltros(): void {
    this.lenderService.getLenders().subscribe(
      (res: any) => {
        res.data.sort(this.compare);
        this.lendersFiltros = res.data;
        if(!this.sessionData.isSuperAdmin){
          this.filtros.idFinanciera = this.sessionData.idFinanciera;
        }
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }

  loadEstados(): void {
    this.loadings.estados = true;
    this.estados = [];
    this.stateService.get().subscribe(
      (res: any) => {
        this.estados = res;
        this.loadings.estados = false;
      },
      (err: any) => {
        this.app.showError(err);
        this.loadings.estados = false;
      }
    );
  }

  loadMunicipios(): void {
    var query: string = '';
    this.loadings.municipios = true;
    this.municipios = [];
    this.filtros.idMunicipio = null;
    if(this.filtros.idEstado){
      query = `?idEstado=${this.filtros.idEstado}`
    }
    this.municipalityService.all(query).subscribe(
      (res: any) => {
        this.municipios = res;
        this.loadings.municipios = false;
      },
      (err: any) => {
        this.app.showError(err);
        this.loadings.municipios = false;
      }
    );
    this.contabilizadorDeFiltros('ubicacion');
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  checkControl(control: string) {
    return this.formAgregarProducto.controls[control].invalid && this.formAgregarProducto.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.formAgregarProducto.controls[control].hasError('required') ? 'Este campo es requerido.' : '';
  }

  loadProducts() {
    this.lenderService.obtenerProductos()
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.products = res.data;
      }, (err: any) => {
        this.app.showError(err);
      });
  }

  resetFinancieraFilter() {
    this.filtros.idFinanciera = null;
  }

  public onSelected( rows ){
    this.itemsSelected = rows.slice();
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.paginator.pagination.currentPage || newPagina.pageSize !== this.paginator.pagination.perPage){
        this.paginator.pagination.currentPage = newPagina.pageSize;
        this.loadAgents(newPagina.pageIndex+1);
    }
  }

  onClick({ row, columnPressed }) {
   if(columnPressed === 'id') {
        this.linkDetalleAgente(row);
    }
  }
    
  linkDetalleAgente(data: any) {
      this.routerNav.navigateByUrl('/agentes/' + data.id); 
  }

  openDialogEstatus(): void {

    const dialogRef = this.dialog.open(ModalEstatusAgenteComponent, {
        //height: '400px',
        width: '500px',
        //disableClose: true,
        data:{
                seleccionAgentes: this.itemsSelected,
                agentsStatus: this.agentsStatus
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
          this.agents = [];
            this.loadAgents(this.filtros.page);
        }
    });
  }

  openDialogVinculacion(): void {

    const dialogRef = this.dialog.open(ModalVinculacionProductosComponent, {
      //height: '400px',
      width: '500px',
      //disableClose: true,
      data:{
        seleccionAgentes: this.itemsSelected,
        lenders: this.lenders,
        products: this.products
      }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            //this.loadAgents(this.filtros.page);
        }
    });
  }

  openDialogDesvinculacion(): void {
    //console.log(this.itemsSelected);

    const dialogRef = this.dialog.open(ModalDesvinculacionComponent, {
      //height: '400px',
      width: '500px',
      //disableClose: true,
      data:{
        seleccionAgentes: this.itemsSelected,
        lenders: this.lenders,
        idFinanciera: this.filtros.idFinanciera
      }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.loadAgents(this.filtros.page);
        }
    });
  }

}
