import { Response } from './../../../core/http/client/response';
import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { CuentaService } from "../../../cuentas/shared/cuenta.service";
import { AccountService } from "../../account.service";
import { Account } from "../../account.model";
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';


@Component({
  selector: 'estatus-cuenta.component',
  templateUrl: 'estatus-cuenta.component.html',
  styleUrls: ["estatus-cuenta.component.css"],
  providers: [
    CuentaService,
    AccountService
  ]
})
export class ModalEstatusCuentaComponent {
  public selectedStatus: any;
  public idDomicilio: any;
  public selectedTag: any;
  public nonSelectedTagWarning: boolean = false;
  public domicilioAsignarWarning: boolean = false;
  public accountsSelected: Account[];
  public accounts: Account[];
  public asignarTodasLasCuentas = false;
  public cargando = false;
  constructor(
    public dialogRef: MatDialogRef<ModalEstatusCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private cuentaService: CuentaService,
    private accountService: AccountService
  ){

  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {
        if(this.data.domicilios.length  === 1){
          this.idDomicilio = this.data.domicilios[0].idDomicilioAcreditado;

        }
    }

    public onChangeDomicilioAsignar() {

        if(!this.idDomicilio) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar un domicilio.",
                3000,
                "warning"
            );
        }
    }

    public changeMotivo(): void {
        if (!this.selectedTag) {

            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar el estatus.",
                3000,
                "warning"
            );
        }
    }

    public actualizaEstatus(): void {

        if (!this.selectedTag && this.selectedStatus === 'Inactiva') {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar el motivo.",
                3000,
                "warning"
            );
            return;
        }
        if (!this.selectedStatus) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar el estatus.",
                3000,
                "warning"
            );
            return;
        }else {

            if(this.data.opcion === 1) {
                this.actualizaEstatusManual();
            }else if(this.data.opcion === 2){
                this.actualizaEstatusMasivo();
            }else{
                this.cargando =  true;
                let loading: number = this.app.showLoading(`Actualizando estatus.`);
                this.data.selectedAccount.forEach(item => {
                    this.actualizadoImportaciones(item.idFinanciera, item.idCliente, item.idCuenta, item.idAcreditadoPG, item.idCuentaPG, item.domicilios);
                });
                this.app.showSnackbar(
                    "Aviso",
                    "Las cuentas se actualizaron correctamente.",
                    3000,
                    "success"
                );
                this.cargando =  false;
                this.app.hideLoading(loading);
                this.dialogRef.close(true);

            }
        }


    }

    public actualizaEstatusManual(): void {
        let cuenta: any = {};
        let arrayDomicilios: any = [];
        this.cargando =  true;
        let loading: number = this.app.showLoading(`Actualizando estatus.`);

        if(this.data.domiciliosSeleccionados.length > 0) {
            this.data.domiciliosSeleccionados.forEach(item => {
                //alex
                arrayDomicilios.push({idDomicilioAcreditado:item.idDomicilioAcreditado});
            });
        }else {
            arrayDomicilios.push({idDomicilioAcreditado:this.idDomicilio});
        }


        //alex
        cuenta = {
            idFinanciera: this.data.idFinanciera,
            estatus: this.selectedStatus,
            idCliente: this.data.idCliente,
            idAcreditadoPG: this.data.idAcreditadoPG,
            idCuentaPG: this.data.idCuentaPG,
            domicilios: arrayDomicilios
        };

        if (this.selectedStatus === 'Inactiva') {
            if (!this.selectedTag) {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar el motivo.",
                    3000,
                    "warning"
                );
                //this.nonSelectedTagWarning = true;
                this.cargando = false;
                return;
            }
            else {
                cuenta.idEtiquetaOperacion = this.selectedTag;
            }
        }

        if(this.data.domiciliosSeleccionados.length === 0) {
            if(!this.idDomicilio) {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar un domicilio.",
                    3000,
                    "warning"
                );
                this.cargando = false;
                return;
            }
        }

        //is.closeEstatusModal();
        //const loading: number = this.app.showLoading('Actualizando cuenta.');
        this.cuentaService.actualizaEstatus(this.data.idCuenta, cuenta).subscribe(
            res => {
                //this.app.hideLoading(loading);
                if (res.success) {
                   //this.cargarCuenta(this.account.id, false);
                   this.dialogRef.close(true);
                    this.app.showSnackbar(
                        "Aviso",
                        "La cuenta se actualizó correctamente.",
                        3000,
                        "success"
                    );

                } else {
                    this.dialogRef.close(false);
                    this.app.showSnackbar(
                        "Aviso",
                        "Ocurrio un Error: " + res.error.message,
                        5000,
                        "error"
                    );
                }
                this.app.hideLoading(loading);
                this.cargando = false;
            },
            error => {
                this.app.showError(error);
                //this.app.hideLoading(loading);
                this.cargando = false;
                this.app.hideLoading(loading);
            }
        );
    }

    
    public actualizaEstatusMasivo(): void {
        this.cargando = true;
        let contador = 0;
        let loading: number = this.app.showLoading(`Actualizando estatus.`);

        if ((this.selectedStatus === 'Activa') || (this.selectedStatus === 'Inactiva' && this.selectedTag)) {
          const observables: Observable<any>[] = this.data.selectedAccount.map(subArray => {
            return this.sendAccountForUpdateStatus(subArray);
          });
      
          forkJoin(observables).subscribe(
            (results: any[]) => {
              contador = results.filter(result => result === true).length;
              this.cargando = false;
              this.nonSelectedTagWarning = false;
              this.selectedStatus = null;
              this.selectedTag = null;
              this.app.showSnackbar('¡Aviso!', `${contador} cuentas fueron cambiadas de estatus.`, 3000, 'success');
              this.app.hideLoading(loading);
              this.dialogRef.close(true);
            },
            (error) => {
              this.cargando = false;
              this.app.hideLoading(loading);
              this.app.showSnackbar('Error', 'Hubo un problema al actualizar las cuentas.', 3000, 'error');
            }
          );
        } else if (this.selectedStatus === 'Inactiva' && !this.selectedTag) {
          this.app.showSnackbar('Aviso', 'Favor de seleccionar el motivo.', 3000, 'warning');
          this.cargando = false;
          this.app.hideLoading(loading);
        }
      }
      
    
      public sendAccountForUpdateStatus(cuenta: any): Observable<boolean> {
        return new Observable<boolean>(observer => {
          this.accountService.setStatus(cuenta, this.selectedStatus, this.selectedTag)
            .subscribe(
              respuesta => {
                if (!respuesta.success && respuesta.error.message != '') {
                  this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
                }
                observer.next(true);
                observer.complete();
              },
              error => {
                this.dialogRef.close(false);
                this.app.showError(error);
                observer.next(false);
                observer.complete();
              }
            );
        });
      }
    
    public actualizadoImportaciones(idFinanciera:number, idCliente:number, idCuenta:number, idAcreditadoPG:number, idCuentaPG:number, domicilios: any): void {
        let cuenta: any = {};
        this.cargando =  true;

        //alex
        cuenta = {
            idFinanciera: idFinanciera,
            estatus: this.selectedStatus,
            idCliente: idCliente,
            idAcreditadoPG: idAcreditadoPG,
            idCuentaPG: idCuentaPG,
            domicilios: domicilios
        };

        if (this.selectedStatus === 'Inactiva') {
            if (!this.selectedTag) {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar el motivo.",
                    3000,
                    "warning"
                );
                //this.nonSelectedTagWarning = true;
                this.cargando = false;
                return;
            }
            else {
                cuenta.idEtiquetaOperacion = this.selectedTag;
            }
        }

        //is.closeEstatusModal();
        //const loading: number = this.app.showLoading('Actualizando cuenta.');
        this.cuentaService.actualizaEstatus(idCuenta, cuenta).subscribe(
            res => {
                //this.app.hideLoading(loading);
                if (!res.success) {
                   //this.cargarCuenta(this.account.id, false);
                    this.dialogRef.close(false);
                    this.app.showSnackbar(
                        "Aviso",
                        "Ocurrio un Error: " + res.error.message,
                        5000,
                        "error"
                    );
                }
            },
            error => {
                this.app.showError(error);
                //this.app.hideLoading(loading);
                this.cargando = false;
            }
        );
    }

    private getSelectedAccounts(): Account[] {
        let accounts: Account[] = [];

        for (var i = 0; i < this.data.accounts.length; i++) {
            if (this.data.accounts[i].selected || this.asignarTodasLasCuentas) {
                let account: Account = new Account();
                account.id = this.data.accounts[i].id;
                account.lenderID = this.data.accounts[i].lender.id;
                account.idCliente = this.data.accounts[i].idCliente;
                account.idAcreditadoPG = this.data.accounts[i].idAcreditadoPG;
                account.idCuentaPG = this.data.accounts[i].idCuentaPG;
                account.domicilios = [{idDomicilioAcreditado:this.data.accounts[i].client.address.idDomicilioAcreditado}];
                accounts.push(account);
            }
        }
        return accounts;
    }

    limpiarDomicilio(){
      this.idDomicilio = null;
    }

    limpiarEstatus(){
      this.selectedStatus = null;
    }

    limpiarMotivo(){
      this.selectedTag = null;
    }

}
