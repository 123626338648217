import { Component, forwardRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'mat-date-picker-formater',
  templateUrl: './mat-date-picker-formater.component.html',
  styleUrls: ['./mat-date-picker-formater.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatDatePickerFormater),
      multi: true
    }
  ]
})
export class MatDatePickerFormater implements ControlValueAccessor, OnChanges {
  @Input() label: string;
  @Input() formato?: string = 'DD/MM/YYYY';
  @Input() disabled?: boolean;
  @Input() placeHolder?: string;
  @Input() max?: any;
  @Input() min?: any;
  @Input() limpiar?: boolean = true;

  valorControl: Date | null;
  fechaFormateada: String;
  private _value: any;
  private onChange: (_: any) => void = () => {};

  constructor(){
  }

  ngOnChanges(changes) {
    
  }

  formatearFecha(){
    if(this._value != this.valorControl){
      this._value = this.valorControl;
      this.fechaFormateada = moment(this.valorControl).format(this.formato);
      this.onChange(this._value);
    } 
  }

  /**Metodos para el ngModel
   * 
   */

  writeValue(value: any): void {
    this._value = value;
    if(!this._value){
      this.fechaFormateada = '';
      this.valorControl = null;
    }
    else{
      this.valorControl = value;
      this.fechaFormateada = moment(this.valorControl).format(this.formato);
    }
  }

  hasValue(): boolean {
    return this._value !== null && this._value !== undefined;
  }

  clearValue(): void {
    this.fechaFormateada = '';
    this.valorControl = null;
    this._value = null;
    this.onChange(this._value);
  }
  
  registerOnChange(onChange: (_: any) => void): void {
    this.onChange = onChange;
  }
  
  registerOnTouched(fn: any): void {}
  
  setDisabledState(isDisabled: boolean): void {}
}
