
<div *ngIf="loadBitacoras">
  <app-loader [message]="'Cargando bitácora del agente...'"></app-loader>
</div>
<div *ngIf="!loadBitacoras">
  <!-- <mat-card-actions  align="end" *ngIf="!modoAgregar">
    <button mat-icon-button
      value="Menu"
      matTooltip="Acciones"
      mat-tooltip-panel-above
      [matMenuTriggerFor]="menu"
    >
      <mat-icon class="kobra-icon">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button 
        mat-menu-item 
        matTooltip="Agregar"
        mat-tooltip-panel-above
        (click)="modoAgregar = true;" >
        <mat-icon class="kobra-icon">add</mat-icon>Nuevo
      </button>
    </mat-menu>
  </mat-card-actions> -->
  <div class="comment-form" *ngIf="modoAgregar">
    <div style="width: 100%;">
      <mat-form-field appearance="fill" class="nvo-comentario">
        <mat-label style="font-weight: normal;">Comentario</mat-label>
        <textarea matInput textSanitizer maxlength="3000" placeholder="Añade un nuevo comentario a la bitácora" [(ngModel)]="nuevoComentario" required></textarea>
        <!--input matInput textSanitizer  maxlength="1000" multil placeholder="Añade un nuevo comentario a la bitácora" [(ngModel)]="nuevoComentario" name="comment" required-->
        <mat-icon matSuffix>add_comment</mat-icon>
      </mat-form-field>
      
    </div>
            
    <div class="botones-card-derecha button-agregar">
      <div class="button-toggle-group-container">
          <button mat-stroked-button class="kobra-button" type="submit" (click)="addComment()"
            matTooltip="Guardar"
            mat-tooltip-panel-above
          >
            <mat-icon>save</mat-icon>
            Guardar
          </button>
      </div>
    </div>
  </div>
  <div #timeline class="timeline" style="max-height: 450px; overflow: auto;" (scroll)="onScroll($event)">
    <div #timelineBar class="timeline-bar"></div>
    <!-- ngFor para iterar sobre las experiencias laborales -->
    <div *ngFor="let movimiento of movimientos; index as e" class="timeline-inner clearfix">
      <!-- Verifica el índice para alternar entre izquierda y derecha -->
      <div class="timeline-box timeline-box-right">
        <span class="dot"></span>
        <div class="timeline-box-inner animate-right animated">
          <span class="arrow"></span>
          <span>
            El {{movimiento.banUsuarioAdministrador ? 'usuario' : 'agente'}} <b>{{movimiento.nombre}} {{movimiento.apellidoPaterno}}</b> ha
            <span *ngIf="movimiento.idGrupoEtiqueta == 1">
              dado de baja al gestor por el motivo <b>{{movimiento.etiqueta}}</b>{{movimiento.comentarios ? ' con el comentario "' + movimiento.comentarios + '"': ''}}.
            </span>
            <span *ngIf="movimiento.idGrupoEtiqueta == 2">
              dado de alta al gestor por el motivo <b>{{movimiento.etiqueta}}</b>{{movimiento.comentarios ? ' con el comentario "' + movimiento.comentarios + '"' : ''}}.
            </span>
            <span *ngIf="movimiento.idGrupoEtiqueta == 3 || movimiento.idGrupoEtiqueta == 4">
              cambiado su estatus a <b>{{movimiento.accion}}</b> por el motivo <b>{{movimiento.etiqueta}}</b>.
            </span>
            <span *ngIf="!movimiento.idGrupoEtiqueta" style="word-break: break-all;">
              añadido un comentario "{{movimiento.comentarios}}".
            </span>
          </span>
          <div class="timeline-date-content">
            <span class="fec-registro small timeline-date">{{movimiento.fecRegistro | date:'dd/MM/yyyy HH:mm'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="(!movimientos || movimientos.length === 0) && !loadBitacoras" class="sin-info">
  <i>Sin información para mostrar</i>
</div>

