<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; obtenerPaquetes(1);"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="actualizar"
            (click)="obtenerPaquetes(1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onAdd()">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1"  (click)="onEdit(itemsSelected[0])">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="onDelete(itemsSelected[0])">
        <mat-icon color="warn" >delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
    
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tabla.columns"
      [items]="paquetes"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="setSortField($event)"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"
    >

      <div t-header>     
        <mat-card *ngIf="ayuda" class="cardAyuda">
          
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              El módulo "Paquetes" en el sistema es un catálogo diseñado para la gestión y creación de los paquetes que se ofrecen a los clientes, como financieras, fintech, bancos, entre otros. Esta función es esencial para las operaciones comerciales, ya que la relación con los clientes comienza típicamente con la compra de uno de estos paquetes.

              En este módulo, los usuarios pueden dar de alta diferentes paquetes, ajustando su oferta para satisfacer las diversas necesidades y preferencias de sus clientes.
              
              Un aspecto importante de este catálogo es la inclusión de un paquete por defecto, conocido como "Saldo Vencido". Este paquete juega un papel crucial, ya que está orientado a aquellos clientes que prefieren negociar trabajar a saldo vencido en lugar de comprar un paquete de manera anticipada.
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b>Mejora la búsqueda y el acceso a los paquetes, permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
            <p>
              <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Permite añadir nuevos paquetes al sistema.
            </p>
            <p>
              <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b>  Esta acción permite la actualización y modificación de los paquetes existentes.
            </p>
            <p>
              <mat-icon color="warn">delete_forever</mat-icon><b> Eliminar: </b> Puedes eliminar paquetes del sistema.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

