
<div class="app content kobra">
    
    <mat-card class="kobra-card">
        <mat-card *ngIf="ayuda " class="cardAyuda">
            <mat-card-content>
              <p><mat-icon color="primary">help</mat-icon>
                  El módulo "Recorrido agente" es una apartado muy importante en el sistema que te ayudará a visualizar y analizar el recorrido que tienen nuestros agentes mientras realizan las gestiones.
                  La aplicación recolecta las coordenadas importantes del agente y te las muestra en un mapa para su correcta visualización.
              </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de los recorridos por agente.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
              </p>
             
            </mat-card-content>
          </mat-card>
        <mat-card-content>
            <mat-card-title>
                <div class="button-container">
                    <div class="button-toggle-group-container">
                    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" style="margin-right: 5px;">
                        <mat-radio-group [(ngModel)]="tipoMapa" (change)="inicializarMapa()">
                            <mat-radio-button class="kobra-radio" [value]="1">Normal</mat-radio-button>
                            <mat-radio-button class="kobra-radio" [value]="2">Satélite</mat-radio-button>
                        </mat-radio-group>
                        <mat-button-toggle value="filtros" 
                            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;" matTooltip="Filtrar"
                            mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">filter_alt</mat-icon>
                            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

                        </mat-button-toggle>
                        <mat-button-toggle value="descargas" 
                            (click)="descargarRecorrido();" matTooltip="Descargar Listado"
                            mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">cloud_download</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="actualizar" (click)="buscarRecorridoAgente();"
                            [disabled]="!filtros.idCobrador && !filtros.fecRecorrido" matTooltip="Refrescar" mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">sync</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="ayuda" 
                        matTooltip="Ayuda"
                        mat-tooltip-panel-above
                        (click)="ayudaComentario(!ayuda)">
                        <mat-icon color="primary">help</mat-icon>
                      </mat-button-toggle>
                      
                    </mat-button-toggle-group>
                </div>
               
                </div>
            </mat-card-title>
            <div style="height: calc(100vh - 235px); width:100%;" id="mapaRecorrido"></div>
        </mat-card-content>
        <mat-card-footer>
            <mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>
    <!--mat-drawer-container class="example-container">
        <mat-drawer style="width: 250px" mode="side" opened>

            <div class="two fields">

                <div class="field" *ngIf="!ocultarVistas" style="margin-left: 10px;margin-top: 10px; font-weight: bolder; font-size: large;">
                    Recorrido por agente
                </div>
                <div class="field" *ngIf="ocultarVistas" style="margin-left: 10px;margin-top: 10px; font-weight: bolder; font-size: large;">
                    Recorrido del agente
                </div>
                <div class="ui segment" data-tab="tab-checklist-agentes" style="width: 98%;">
                    <div class="field" *ngIf="sessionData.isSuperAdmin && !ocultarVistas">
                        <label for="">{{cargandoFinancieras ? 'Cargando Clientes' : 'Cliente'}}</label><br>
                        <sui-select [(ngModel)]="idLender" [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="onChangeLender($event);" class="selection" placeholder="Seleccione"
                            [options]="lenders" labelField="name" valueField="idFinanciera" [isSearchable]="true"
                            #financieraInput style="width: 206px">
                            <sui-select-option *ngFor="let financiera of financieraInput.filteredOptions"
                                [value]="financiera"></sui-select-option>
                        </sui-select>
                        <div align="left" *ngIf="filtros.financiera">
                            <a (click)="resetFinancieraFilter()"><i>Limpiar selección</i></a>
                        </div>
                    </div>
                    <div class="field top10" [hidden]="ocultarVistas">
                        <div  class="ui form">
                            <sui-search (keydown.enter)="$event.preventDefault()"
                                placeholder="ID o nombre agente" [optionsLookup]="agenteSearch"
                                optionsField="nombre" searchDelay="500" maxResults="10"
                                (resultSelected)="onAsignarAgenteSelected($event)" #agenteInput
                                style="width: 100%;">
                            </sui-search>

                            <div align="left" *ngIf="filtros.idCobrador">
                                <a (click)="resetAsignarAgente()"><i>Limpiar selección</i></a>
                            </div>
                        </div>
                    </div>
                    <mat-form-field appearance="fill" style="margin-top:10px; width: 206px; display:inline-block;" *ngIf="!ocultarVistas">
                        <mat-label>Fecha</mat-label>
                        <input matInput textSanitizer  [matDatepicker]="pickerAgentes" [(ngModel)]="filtros.fecRecorrido" disabled>
                        <mat-datepicker-toggle matSuffix [for]="pickerAgentes"></mat-datepicker-toggle>
                        <mat-datepicker #pickerAgentes disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-slide-toggle [(ngModel)]="filtros.mostrarNombre" (ngModelChange)="recargarPuntos(false, 'recorrido')">Mostrar núm. ubicación</mat-slide-toggle>
                    <mat-slide-toggle [(ngModel)]="filtros.mostrarLinea" (ngModelChange)="recargarPuntos(false, 'recorridoLineal')">Mostrar lineas</mat-slide-toggle>
                    <div class="two fields">
                        <div class="field"></div>
                        <div class="field" style="display: inline-block; justify-content:right; height: 30px; margin-left: 100px">
                            <button [disabled]="!filtros.idCobrador && !filtros.fecRecorrido" (click)="buscarRecorridoAgente()"
                                class="tiny blue ui button">
                                {{filtrado ? 'Recargar' : 'Ver recorrido'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <div style="height: 80vh; width:100%" id="mapaRecorrido"></div>
        </mat-drawer-content>
    </mat-drawer-container-->
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
        <div class="div-filtros-side">
            <!-- <li class="filtro-side" *ngIf="sessionData.isSuperAdmin && !ocultarVistas">
                <mat-select-search
                    class="id-card-field"
                    [label]="'Cliente'"
                    [placeHolder]="'Selecciona un cliente'"
                    [value]="'idCliente'"
                    [labelField]="'name'"
                    [icon]="'account_balance'"
                    [options]="lenders"
                    [(ngModel)]="idLender"
                    (ngModelChange)="onChangeLender($event);"
                >
                </mat-select-search>
                
            </li> -->
            <li *ngIf="!ocultarVistas">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Agente'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'idCobrador'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="agenteSearch"
                  [(ngModel)]="filtros.idCobrador"
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                  [minBusqueda]="1"
                >
                </mat-select-search>
                
            </li>
            <li style="margin-bottom: 15px;" *ngIf="!ocultarVistas">
                <mat-date-picker-formater
                    class="grid-tile-form-field"
                    [label]="'Fecha del recorrido'"
                    [placeHolder]="'Fecha'"
                    [(ngModel)]="filtros.fecRecorrido"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    [limpiar]="false"
                    [max]="hoy"
                />
               
            </li>
            <li style="margin-bottom: 15px;">
                <mat-slide-toggle [disabled]="!filtrado" class="kobra-slide-toggle" [(ngModel)]="filtros.mostrarNombre" (ngModelChange)="recargarPuntos(false, 'recorrido')">Mostrar núm. ubicación</mat-slide-toggle>
            </li>
            <li>
                <mat-slide-toggle [disabled]="!filtrado" class="kobra-slide-toggle" [(ngModel)]="filtros.mostrarLinea" (ngModelChange)="recargarPuntos(false, 'recorridoLineal')">Mostrar lineas</mat-slide-toggle>
            </li>
        </div>
    </ol>
    
    <ol class="lista-filtros-buttons">
      <button
        class="boton-accion-front"
        style="width: 100%;"
        mat-stroked-button
        
        (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; buscarRecorridoAgente()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
    </ol>
</mat-sidenav>