export class FiltersContabilizer {
    [key: string]: FilterContabilizer | Function | string[];
    properties: string[] = [];
    constructor() {
    }
  
   /**
   * Genera el la propiedad de contabilizacion
   * @param {string} name Es el nombre del apartado.
   * @param {string} propertyName Es el nombre de la propiedad con la que se llamara este objeto en caso que se este utilizando FiltersContabilizer.
   * @param {string[]} filtersProperties Son las propiedades del objeto de filtros que se tomaran en cuenta para contabilizar este apartado.
   * @Auth Uriel Yair Gámez Rosales
   */
   public add(name: string, propertyName: string, filtersProperties: string[]) {
    this[propertyName] = new FilterContabilizer(name, propertyName, filtersProperties);
    this.properties.push(propertyName);
    }

    /**
   * Genera el la propiedad de contabilizacion
   * @param {string} name Es el nombre del apartado.
   * @param {string} propertyName Es el nombre de la propiedad con la que se llamara este objeto en caso que se este utilizando FiltersContabilizer.
   * @param {string[]} filtersProperties Son las propiedades del objeto de filtros que se tomaran en cuenta para contabilizar este apartado.
   * @Auth Uriel Yair Gámez Rosales
   */
    public get(propertyName: string): FilterContabilizer | null {
        return this[propertyName] ? this[propertyName] as FilterContabilizer : null;
    }
    
    /**
         * Cuenta los filtros activos para un contabilizador específico.
         * @param {string} propertyName Nombre del contabilizador.
         * @param {any} filters Objeto con los filtros a evaluar.
         * @returns {number} Número de filtros activos.
         */
    public countActiveFilters(propertyName: string, filters: any): number {
        let contabilizador: FilterContabilizer | null = this.get(propertyName);
        if (contabilizador) {
            contabilizador.activeFilters = 0;
            contabilizador.filtersProperties.forEach(filterProperty => {
                if (filters.hasOwnProperty(filterProperty) && filters[filterProperty] !== null) {
                    contabilizador.activeFilters++;
                }
            });
            return contabilizador.activeFilters;
        }
        return 0;
    }
}

export class FilterContabilizer{
    name: string;
    propertyName: string;
    activeFilters: number;
    filtersProperties: string[] = [];

    /**
   * Genera el contabilizador el cual tiene 4 propiedades, el nombre del apartado, la propiedad con la que se llamará el objeto, el contador y un array con las propiedades de filtros que se van a validar en el apartado para contar.
   * @param {string} name Es el nombre del apartado.
   * @param {string} propertyName Es el nombre de la propiedad con la que se llamara este objeto en caso que se este utilizando FiltersContabilizer.
   * @param {string[]} filtersProperties Son las propiedades del objeto de filtros que se tomaran en cuenta para contabilizar este apartado.
   * @Auth Uriel Yair Gámez Rosales
   */
    constructor(name:string, propertyName: string, filtersProperties: string[]) {
        this.name = name;
        this.propertyName = propertyName;
        this.activeFilters = 0;
        this.filtersProperties = filtersProperties;
    }

}