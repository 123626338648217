import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { UserService } from '../user.service';
import { User } from '../user.model';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Admin } from 'src/app/administrator/administrator.model';

@Component({
  selector: 'user-edit',
  templateUrl: 'user-edit.component.html',
  styleUrls: ['user-edit.component.css'],
  providers: [UserService],
})
export class UserEditComponent implements OnInit {
  public personalInfoForm: FormGroup;
  public passwordForm: FormGroup;
  hideContra = true;
  hideConfirm = true;
  sessionData: SessionData;
  userID: number;
  user: User;
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private app: ApplicationService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.user = new User();
    this.user.admin = new Admin();

    this.personalInfoForm = this.fb.group({
      nombre: ['', [Validators.required]],
      apellidoPaterno: ['', [Validators.required]],
      apellidoMaterno: '',
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
    });

    this.passwordForm = this.fb.group({
      contrasenaActual: ['', [Validators.pattern]],
      contrasenaNueva: ['', [Validators.pattern]],
      contrasenaRepetida: ['', [Validators.pattern]],
    });
  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    let query = '?expand=admin';
    this.userID = +localStorage.getItem('userID');
    
    this.userService.getOne(this.userID, query).subscribe(
      (user) => {
        this.user = user;
        this.personalInfoForm.patchValue({
          nombre: this.user.admin.nombre,
          apellidoPaterno: this.user.admin.apellidoPaterno,
          apellidoMaterno: this.user.admin.apellidoMaterno,
          email: this.user.admin.email,
        });
        this.loading = false;
      }
    );
  }

  guardarDatos() {
    this.actualizarPerfil('Se ha actualizado tu perfil correctamente');
  }

  cambiarContrasenia() {
    this.actualizarPerfil('Se ha actualizado tu contraseña.', true);
  }

  private actualizarPerfil(message: string, cambiarContrasena: boolean = false) {
    if (cambiarContrasena && !this.validarContrasena()) {
      return; // No continuar si la validación de contraseña falla
    }
  
    if (!cambiarContrasena && !this.validarNombreApellido()) {
      return; // No continuar si la validación de nombre y apellido falla
    }
  
    const loadingMessage = cambiarContrasena ? 'Cambiando contraseña.' : 'Actualizando información.';
    const loading = this.app.showLoading(loadingMessage);
  
    const requestObservable = cambiarContrasena
      ? this.userService.cambiarContrasena(this.userID, {
          password: this.passwordForm.get('contrasenaActual').value,
          newPassword: this.passwordForm.get('contrasenaNueva').value
        })
      : this.userService.actualizarPerfil(this.userID, this.user.admin);
  
    requestObservable.subscribe(
      () => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('Aviso', message, 3000, 'success');
        this.cargarDatos();

      },
      (error) => {
        this.app.hideLoading(loading);
        if (cambiarContrasena && error === 'La contraseña es incorrecta') {
          this.app.showSnackbar('Aviso', 'La contraseña es incorrecta.', 3000, 'error');
        } else {
          this.app.showError(error);
        }
      }
    );
  }
  
  private validarContrasena(): boolean {
    const actual = this.passwordForm.get('contrasenaActual').value;
    const nueva = this.passwordForm.get('contrasenaNueva').value;
    const repetida = this.passwordForm.get('contrasenaRepetida').value;

    if (actual == '' || nueva == '' || repetida == ''){
      this.app.showSnackbar('¡Aviso!', 'Debe de capturar todos los campos.', 3000, 'warning');
      return false;
    }

    if (nueva.length < 4) {
      this.app.showSnackbar('¡Aviso!', 'La contraseña debe tener mínimo 4 caracteres.', 3000, 'warning');
      return false;
    }
  
    if (nueva !== repetida) {
      this.app.showSnackbar('¡Aviso!', 'Las contraseñas ingresadas no coinciden.', 3000, 'warning');
      return false;
    }
  
    if (nueva === actual) {
      this.app.showSnackbar('¡Aviso!', 'La contraseña no puede ser la misma.', 3000, 'warning');
      return false;
    }
  
    return true; 
  }
  
  private validarNombreApellido(): boolean {
    //console.log(this.user.admin)
    const nombre = this.personalInfoForm.get('nombre').value;
    const apellidoPaterno = this.personalInfoForm.get('apellidoPaterno').value;
    const apellidoMaterno = this.personalInfoForm.get('apellidoMaterno').value;
  
    if (!nombre || nombre.trim() === '' || !apellidoPaterno || apellidoPaterno.trim() === '') {
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar Nombre y/o Apellido.', 3000, 'warning');
      return false;
    }
    this.user.admin.nombre = nombre;
    this.user.admin.apellidoPaterno = apellidoPaterno;
    this.user.admin.apellidoMaterno = apellidoMaterno;
  
    return true; 
  }
  
}
