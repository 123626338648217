<h1 mat-dialog-title>
  Condicional
</h1>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Tipo</mat-label>
    <mat-select [(ngModel)]="tipoCondicion">
      <mat-option *ngFor="let condicional of condicionalesSQL" [value]="condicional">
        {{ condicional.nombre }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-accion-front" (click)="Aceptar()"><mat-icon>check</mat-icon>Aceptar</button>
</mat-dialog-actions>
