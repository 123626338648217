<div class="app content kobra">


  <div class="contenedorDetalleCuentaGeneral">
    <div class="contenedorNombreFinanciera">
      <span class="nombreCliente"> {{ request?.nombre}} {{ request?.primerApellido}} {{
        request?.segundoApellido}}</span>
      <span class="nombreFinanciera">
        {{request?.nombreCliente}}
      </span>
    </div>
    <div class="contenedorCuentaProducto">
      <span [ngClass]="{'aprobado': request?.estatus === 'Aprobado'
            , 'rechazado' : request?.estatus === 'Rechazado'
            , 'cuentaEnEsperaDeConfirmacion' : request?.estatus === 'Pendiente confirmación'
            , 'borrador' : request?.estatus === 'Borrador'}">
        {{request?.estatus}}
      </span>
      <span class="nombreProducto">
        {{request?.nombreTipoCredito}}
      </span>
      
    </div>
  </div>

  <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="400px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-card class="alturaMaximaCard anchoCard kobra-card margenLeft">
        <mat-card-header>
          <mat-card-title>Solicitud</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="contenidoConScroll detalle">
            <div class="row">
              <div class="column">
                <mat-label class="negrita">Fecha</mat-label>
                <p>{{request?.fechaRegistro}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Nombre del agente</mat-label>
                <p>{{request?.nombreCompletoAgente}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Estatus de la solicitud</mat-label>
                <p>{{request?.estatus}}</p>
              </div>
            </div>
            <div class="row">
              <div class="column" *ngIf="request?.montoSolicitado > 0">
                <mat-label class="negrita">Monto solicitado</mat-label>
                <p>${{request?.montoSolicitado | number}}</p>
              </div>
             
              <div class="column" *ngIf="request?.montoPrestado">
                <mat-label class="negrita">Monto prestado</mat-label>
                <p>${{request?.montoPrestado | number}}</p>
              </div>
            </div>

          </div>

        </mat-card-content>
        <!-- <mat-divider inset></mat-divider> -->


        <mat-card-footer *ngIf="!request" class="kobra-card-footer">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-card class="alturaMaximaCard anchoCard kobra-card anchoCard margenLeft">
        <mat-card-header>
          <mat-card-title>Información del solicitante</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="contenidoConScroll detalle">
            <div class="row">
              <div class="column">
                <mat-label class="negrita">Nombre</mat-label>
                <p>{{ request?.nombre}} {{ request?.primerApellido}} {{ request?.segundoApellido}} </p>
              </div>
              <div class="column">
                <mat-label class="negrita">Tipo Persona</mat-label>
                <p>{{request?.tipoPersona}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Teléfono</mat-label>
                <p>{{ request?.telefonoCelular }}</p>
              </div>
             
            </div>
            <div class="row">
              <div class="column" *ngIf="request?.tipoPersona == 'Fisica'">
                <mat-label class="negrita">Fecha Nacimiento</mat-label>
                <p> {{request?.fechaNacimiento | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="column" *ngIf="request?.tipoPersona == 'Fisica'">
                <mat-label class="negrita">CURP</mat-label>
                <p>{{ request?.curp }}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">RFC</mat-label>
                <p> {{request?.rfc}}</p>
              </div>
             
            </div>


          </div>

        </mat-card-content>

        <mat-card-footer *ngIf="!request" class="kobra-card-footer">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-card class="alturaMaximaCard anchoCard kobra-card anchoCard margenLeft">

        <mat-card-header>
          <mat-card-title>Datos de domicilio</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="contenidoConScroll detalle">
            <div class="row">
              <div class="column">
                <mat-label class="negrita">{{labelEstado}}</mat-label>
                <p>{{ request?.estado }}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">{{labelMunicipio}}</mat-label>
                <p>{{request?.municipio}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Calle</mat-label>
                <p> {{request?.calle}}</p>
              </div>
            </div>
            <div class="row">
              <div class="column">
                <mat-label class="negrita">Número exterior</mat-label>
                <p>{{request?.numeroExterior}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Colonia</mat-label>
                <p> {{request?.colonia}}</p>
              </div>
              <div class="column">
                <mat-label class="negrita">Código Postal</mat-label>
                <p>{{ request?.codigoPostal }}</p>
              </div>
            </div>
            <div class="row">
              <div class="column" *ngIf="request?.entreCalles">
                <mat-label class="negrita">Entre calles:</mat-label>
                <p> {{request?.entreCalles}}</p>
              </div>
              <div class="column" *ngIf="request?.numeroInterior">
                <mat-label class="negrita">Número interior</mat-label>
                <p>{{ request?.numeroInterior }}</p>
              </div>

            </div>
            <div class="row">
              <div class="column" *ngIf="request?.latitudFin && request?.longitudFin">
                <mat-label class="negrita">Coordenadas</mat-label>
                <p><a (click)="onClickMapaGoogle(coordenadas)">{{ request?.latitudFin }},{{ request?.longitudFin}}</a>
                </p>
              </div>

            </div>


          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="!request" class="kobra-card-footer">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
    <!-- <mat-grid-tile colspan="1" rowspan="1">
      <mat-card class="alturaMaximaCard anchoCard kobra-card anchoCard margenLeft">

        <mat-card-header>
          <mat-card-title>Cuestionario</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="contenidoConScroll detalle">
          
            <div class="row">
              <div class="column" *ngFor="let pregunta of checklists.preguntas">
                <mat-label class="negrita">{{pregunta.nombrePregunta}}</mat-label>
                <div class="list" style="padding-top: 0;" *ngIf="pregunta.respuestas.length > 1">
                  <div class="item" *ngFor="let respuesta of pregunta.respuestas">
                    <mat-label class="negrita">{{pregunta.nombrePregunta}}</mat-label>
                    <div class="description" style="margin-left: 10px" *ngIf="respuesta.idTipoRespuesta != 11">
                      {{respuesta.idTipoRespuesta == 6 ? (respuesta.valorRespuesta == 'true' || respuesta.valorRespuesta == '1' ? 'Sí' : 'No') :
                      respuesta.idTipoRespuesta == 8 ? (respuesta.valorRespuesta == 'true' || respuesta.valorRespuesta == '1' ? 'Sí' :
                      (respuesta.valorRespuesta == 'false' || respuesta.valorRespuesta == '0' ? 'No' : '')) :
                      respuesta.valorRespuesta || ''}}
                    </div>
                    <div class="description" style="margin-left: 10px" *ngIf="respuesta.idTipoRespuesta == 11 && respuesta.valorRespuesta">
                      <foto-zoom [foto]="respuesta.fullPath" [posicion]="1" (click)="cargarFotoCompleta(respuesta.fullPath)"></foto-zoom>
                    </div>
                  </div>
                </div>
                <div class="description" style="margin-left: 10px" *ngIf="pregunta.respuestas.length == 1 && pregunta.respuestas[0].idTipoRespuesta != 11">
                  {{pregunta.respuestas[0].accion == 'promesa-pago-monto' ? '$' : ''}}
                  {{pregunta.respuestas[0].idTipoRespuesta == 6 ? (pregunta.respuestas[0].valorRespuesta == 'true' ||
                  pregunta.respuestas[0].valorRespuesta == '1' ? 'Sí' : 'No') :
                  pregunta.respuestas[0].idTipoRespuesta == 8 ? (pregunta.respuestas[0].valorRespuesta == 'true' ||
                  pregunta.respuestas[0].valorRespuesta == '1' ? 'Sí' :
                  (pregunta.respuestas[0].valorRespuesta == 'false' || pregunta.respuestas[0].valorRespuesta == '0' ? 'No' : '')) :
                  pregunta.respuestas[0].idTipoRespuesta == 3 || pregunta.respuestas[0].idTipoRespuesta == 4 ?
                  (pregunta.respuestas[0].valorRespuesta | number) : pregunta.respuestas[0].valorRespuesta || ''}}
                </div>
                <div class="description" style="margin-left: 10px" *ngIf="pregunta.respuestas.length == 1 && pregunta.respuestas[0].idTipoRespuesta == 11 && pregunta.respuestas[0].valorRespuesta">
                  <foto-zoom [foto]="pregunta.respuestas[0].fullPath" [posicion]="1" (click)="cargarFotoCompleta(pregunta.respuestas[0].fullPath)"></foto-zoom>
                </div>
              </div>
            </div>
            

          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="!checklists.preguntas" class="kobra-card-footer">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
      </mat-card>
    </mat-grid-tile> -->
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-card class="alturaMaximaCard anchoCard kobra-card anchoCard margenLeft">
        <mat-card-header>
          <mat-card-title>Cuestionario</mat-card-title>
          <mat-card-subtitle> {{request?.nombreTipoCredito}} </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-content-cuestionario">
          <div *ngFor="let checklist of checklists.preguntas">
            <div class="row">
              <div class="column">
                <strong>{{ checklist.nombrePregunta }}</strong>
                <div *ngIf="checklist.respuestas.length > 1">
                  <mat-chip-listbox aria-label="Fish selection">
                    <div style="margin-left: 10px; margin-top: 5px;" *ngFor="let respuesta of checklist.respuestas">
                      <mat-chip *ngIf="respuesta.idTipoRespuesta != 11">{{ getAnswerValue(respuesta) }}</mat-chip>
                      <div *ngIf="respuesta.idTipoRespuesta === 11">
                        <div class="foto" >
                          <mat-chip>{{respuesta.nombreRespuesta}}</mat-chip>    
                        </div>
                        <foto-zoom style="text-align: center !important;" [foto]="respuesta.fullPath" [posicion]="1" [height]="150"
                          (click)="cargarFotoCompleta(respuesta.fullPath)"></foto-zoom>
                      </div>
                    </div>
                  </mat-chip-listbox>

                </div>
                <div style="margin-left: 10px; margin-top: 5px;" *ngIf="checklist.respuestas.length === 1">
                  <mat-chip> {{ getSingleAnswerValue(checklist).text }}</mat-chip>
                  <div *ngIf="getSingleAnswerValue(checklist).hasPhoto">
                      <foto-zoom *ngIf="checklist.respuestas[0].fullPath" [foto]="checklist.respuestas[0].fullPath" [posicion]="1" [height]="150" (click)="cargarFotoCompleta(checklist.respuestas[0].fullPath)"></foto-zoom>
                      <mat-chip *ngIf="!checklist.respuestas[0].fullPath"> {{ getSingleAnswerValue(checklist).text }}</mat-chip>
                  </div>
                </div>
              </div>
              <div class="column"
                *ngIf="checklist.nombrePregunta == 'Etiqueta' && sessionData.isSuperAdmin && checklist.respuestas[0]['options'].length > 0">
                {{ etiquetaActualObtener(checklist) }}
                <!-- <button mat-stroked-button  (click)="openDialogCheckListsEtiqueta()">
                      <mat-icon class="kobra-icon">sync</mat-icon>
                      Cambiar etiqueta
                  </button> -->
              </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingChecklist" class="kobra-card-footer">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list-responsive>

  <div class="column" style="height: 600px; margin-top: 20px;">
    <mapbox [coordenadas]="coordenadasMapa"></mapbox>
  </div>


</div>