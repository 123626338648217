import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Client } from "../../../client/client.model";
import { FormValidation } from "../../../shared/form-validation/form-validation.model";
import { UICalendar } from "../../../shared/ui-calendar/ui-calendar.model";
import { ApplicationService } from '../../../application/shared/application.service';
import { CuentaService } from '../../../cuentas/shared/cuenta.service';

declare let $: any;

@Component({
  selector: 'detail-form',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.css']
})
export class ClientFormComponent implements OnChanges, OnInit {
  public form: FormGroup;
  public nameValidation: FormValidation;
  public firstSurnameValidation: FormValidation;
  public secondSurnameValidation: FormValidation;
  public curpValidation: FormValidation;
  public rfcValidation: FormValidation;
  public birthdateValidation: FormValidation;
  public genderValidation: FormValidation;
  public phoneValidation: FormValidation;
  public cellphoneValidation: FormValidation;
  public emailValidation: FormValidation;
  public clvIfeValidation: FormValidation;
 
  @Input() client: Client;
  @Input() redInterna: boolean;
  @Input() clientID: number;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<Client> = new EventEmitter();

  constructor(
    private cuentaService: CuentaService,
    private app: ApplicationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnChanges() {
    this.resetForm();
  }

  ngOnInit() {
    this.initForm();
    this.initValidations();
  
    if (this.client) {
      this.resetForm();
    }
  }


  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [
        Validators.required
      ]],
      firstSurname: ['', [
        //Validators.required
      ]],
      secondSurname: [''],
      curp: ['', [
        Validators.pattern
      ]],
      rfc: ['', [
        Validators.pattern
      ]],
      birthdate: [''],
      phone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      cellphone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      email: ['', [
        Validators.email
      ]],
      clvIfe: ['', [
        //Validators.required
      ]]
    });
  }

  onRfcInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value) {
      inputElement.value = inputElement.value.toUpperCase();
    }
  }

  onCurpInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value) {
      inputElement.value = inputElement.value.toUpperCase();
    }
  }

  private initValidations(): void {
    this.nameValidation = new FormValidation();
    this.nameValidation.formControl = this.form.controls['name'];
    this.nameValidation.message = {
      required: 'Campo requerido.'
    };
    this.nameValidation.observeFromControl();
    
    this.firstSurnameValidation = new FormValidation();
    this.firstSurnameValidation.formControl = this.form.controls['firstSurname'];
    this.firstSurnameValidation.message = {
      required: 'Campo requerido.'
    };
    this.firstSurnameValidation.observeFromControl();
  
    this.birthdateValidation = new FormValidation();
    this.birthdateValidation.formControl = this.form.controls['birthdate'];
    this.birthdateValidation.message = {
      required: 'Campo requerido.'
    };
    this.birthdateValidation.observeFromControl();
  
    this.phoneValidation = new FormValidation();
    this.phoneValidation.formControl = this.form.controls['phone'];
    this.phoneValidation.message = {
      pattern: 'Deben ser solo números.'
    };
    this.phoneValidation.observeFromControl();
  
    this.curpValidation = new FormValidation();
    this.curpValidation.formControl = this.form.controls['curp'];
    this.curpValidation.message = {
      pattern: 'El formato de la curp es incorrecto.'
    };
    this.curpValidation.observeFromControl();
  
    this.cellphoneValidation = new FormValidation();
    this.cellphoneValidation.formControl = this.form.controls['cellphone'];
    this.cellphoneValidation.message = {
      required: 'Campo requerido.',
      pattern: 'Deben ser solo números.'
    };
    this.cellphoneValidation.observeFromControl();
  
    this.emailValidation = new FormValidation();
    this.emailValidation.formControl = this.form.controls['email'];
    this.emailValidation.message = {
      required: 'Campo requerido.',
      email: 'Formato incorrecto.'
    };
    this.emailValidation.observeFromControl();
  
    this.rfcValidation = new FormValidation();
    this.rfcValidation.formControl = this.form.controls['rfc'];
    this.rfcValidation.message = {
      required: 'Campo requerido.',
      pattern: 'El formato del rfc es incorrecto.'
    };
    this.rfcValidation.observeFromControl();
  
    this.clvIfeValidation = new FormValidation();
    this.clvIfeValidation.formControl = this.form.controls['clvIfe'];
    this.clvIfeValidation.message = {
      required: 'Campo requerido.'
    };
    this.clvIfeValidation.observeFromControl();
  }
  

  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
  }

  private prepareFormData(): Client {
    const client: Client = new Client();
    client.idAcreditado = this.client.idAcreditado;
    client.idFinanciera = this.client.idFinanciera;
    client.externalID = this.client.externalID;
    client.name = this.form.value.name || null;
    client.apellido_p = this.form.value.firstSurname || null;
    client.apellido_m = this.form.value.secondSurname || null;
    client.curp = this.form.value.curp || null;
    client.rfc = this.form.value.rfc || null;
    client.fechaNacimiento = this.form.value.birthdate || null;
    client.telefonoFijo = this.form.value.phone || null;
    client.telefonoCelular = this.form.value.cellphone || null;
    client.email = this.form.value.email || null;
    client.claveElectoralIfe = this.form.value.clvIfe || null;
    return client;

  }

  private resetForm(): void {
    if (this.form) {
      this.form.reset({
        name: this.client.name,
        firstSurname: this.client.apellido_p,
        secondSurname: this.client.apellido_m,
        phone: this.client.telefonoFijo,
        cellphone: this.client.telefonoCelular,
        email: this.client.email,
        claveElectoralIfe: this.client.claveElectoralIfe,
        curp: this.client.curp,
        rfc: this.client.rfc,
        clvIfe : this.client.claveElectoralIfe,
        birthdate: this.client.fechaNacimiento
      });
      //this.birthdateCalendar.setDate(this.client.fechaNacimiento);

    }
  }

  public save(): void {
    
    if (this.form.get('name').value) {
      this.form.get('name').patchValue(this.form.get('name').value.trim());
    }
    if (this.form.get('firstSurname').value) {
      this.form.get('firstSurname').patchValue(this.form.get('firstSurname').value.trim());
    }
    if (this.form.get('secondSurname').value) {
      this.form.get('secondSurname').patchValue(this.form.get('secondSurname').value.trim());
    }

    if (this.form.valid) {
      const client: Client = this.prepareFormData();
        
      // this.loaderService.showHttpLoader();
      const loading: number = this.app.showLoading('Guardando información del acreeditado.');
      this.cuentaService.update(this.client.idAcreditado, client).subscribe(
        res => {
          this.client = client;
          this.onSave.emit(this.client);
          this.app.hideLoading(loading);
        },
        error => {
          this.app.showError(error);
          this.app.hideLoading(loading);
        }
      );
    }
  }
}
