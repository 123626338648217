import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

import { FormValidation } from "./form-validation";

@Directive({
  selector: '[formValidationClass]'
})
export class FormValidationClassDirective {
  private isInvalid: boolean;

  @Input() set formValidationClass( formValidation: FormValidation) {
    formValidation.isInvalid.subscribe(isInvalid => {
      this.isInvalid = isInvalid;
      this.changeClass();
    });
  };

  constructor(
    private element: ElementRef,
    private render: Renderer2
  ) {
    this.isInvalid = false;
  }

  private changeClass(): void {
    if (this.isInvalid) {
      this.render.addClass(this.element.nativeElement, 'error');
    } else {
      this.render.removeClass(this.element.nativeElement, 'error');
    }
  }
}
