<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; desactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="financieras"
          [disabled]="loadingClientes"
          [(ngModel)]="idFinanciera"
          (ngModelChange)="onChangeLender($event)"
        >
        </mat-select-search>
        
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; filtrar();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="checklists = []; limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>

<div class="app content tabla kobra checklist" *ngIf="!hijosActivos">
  
  
    <mat-card class="kobra-card">
      <!-- <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
          El módulo "Cuestionarios" permite a los usuarios configurar y personalizar los cuestionarios que los gestores deben completar en cada visita.
          Aquí se configura el cuestionario específico que se utilizará durante las visitas. Esta configuración es fundamental para asegurar que se recopile la información necesaria de manera eficiente y efectiva.
          Cada cuestionario se asocia específicamente a un producto y a un cliente, lo que permite un enfoque personalizado y adaptado a las necesidades y características de cada situación.
          Las preguntas creadas en los cuestionarios son completamente dinámicas y se pueden adaptar para recabar cualquier tipo de información necesaria. Esto ofrece una gran flexibilidad para abordar una amplia gama de situaciones y requerimientos.
          Las preguntas pueden ser configuradas para tener múltiples tipos de respuestas, incluyendo switches, opciones de combo, texto libre, fechas, entre otros. Esto permite una personalización profunda del cuestionario, adecuándose a las diferentes necesidades de información.
    
          Cada vez que se hace una modificación en un cuestionario, se genera una nueva versión. Esto mantiene la integridad de los cuestionarios que ya fueron respondidos anteriormente, permitiendo un seguimiento histórico y preciso.
        </mat-card-content>
      </mat-card>  -->
     
      <mat-card-content class="contenido">
        <div class="button-container">
          <div class="button-toggle-group-container">
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
              <mat-button-toggle value="filtros" 
                  (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                  matTooltip="Filtrar"
                  *ngIf="sessionData.isSuperAdmin"
                  mat-tooltip-panel-above>
                  <mat-icon class="kobra-icon">filter_alt</mat-icon>
                  <span *ngIf="filtros.financiera" class="notification-dot-filtros notification-dot-accion">1</span>

                  
              </mat-button-toggle>
              <mat-button-toggle value="actualizar"
                (click)="loadChecklists()"
                matTooltip="Refrescar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">sync</mat-icon>
              </mat-button-toggle>
              <!-- <mat-button-toggle value="ayuda" 
                matTooltip="Ayuda"
                mat-tooltip-panel-above
                (click)="ayuda = !ayuda;">
                <mat-icon color="primary">help</mat-icon>
              </mat-button-toggle> -->
            </mat-button-toggle-group>
          </div>
        </div>
        <mat-accordion  class="example-headers-align" *ngIf="checklists.length > 0">
          <mat-expansion-panel *ngFor="let checklist of checklists; let i = index" class="expansion">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="icon-nombre">
                  <mat-icon class="status-icon active" matTooltip="Activo" matTooltipPosition="above">
                    check_circle
                  </mat-icon>
                  <strong>
                    {{ checklist.nombre }}
                  </strong>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                Versión {{ checklist.numVersion }} actualizada el {{ checklist.fecha | date:'dd/MM/yyyy HH:mm' }}
                <div style="margin-left: auto;"> 
                  <button *ngIf="checklist.activo" mat-icon-button
                    [matMenuTriggerFor]="menuChecklist"
                    matTooltip="Acciones"
                    mat-icon-button
                    (click)="$event.stopPropagation();"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menuChecklist="matMenu">
                    <button *ngIf="checklist.activo" mat-menu-item (click)="editChecklist(checklist)" matTooltip="Editar"
                      matTooltipPosition="above">
                      <mat-icon class="kobra-icon">edit</mat-icon>Editar
                    </button>
                    <button *ngIf="checklist.activo" mat-menu-item (click)="formato(checklist, 'formato-visita')" matTooltip="Plantillas visitas"
                      matTooltipPosition="above">
                      <mat-icon class="kobra-icon">description</mat-icon>Plantillas visitas
                    </button>
                    <button *ngIf="checklist.activo" mat-menu-item (click)="formato(checklist, 'formato-cuenta')" matTooltip="Plantillas cuentas"
                      matTooltipPosition="above">
                      <mat-icon class="kobra-icon">description</mat-icon>Plantillas cuentas
                    </button>
                    <button *ngIf="checklist.activo" mat-menu-item (click)="abrirLayoutEditar(checklist)" matTooltip="Layout"
                      matTooltipPosition="above">
                      <mat-icon class="kobra-icon">view_list</mat-icon>Layout Importación
                    </button>
                    <button *ngIf="checklist.activo && sessionData.isSuperAdmin" mat-menu-item (click)="configChecklist(checklist)" matTooltip="Editar"
                      matTooltipPosition="above">
                      <mat-icon class="kobra-icon">settings</mat-icon>Configuración
                    </button>
                  </mat-menu>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            
            <div class="checklist-inactivos" *ngFor="let checkInactivo of checklist.checklistsInactivos; let j = index;">
              <hr>
              <div *ngIf="!checkInactivo.activo" style="margin-left: 30px;">
                <div class="icon-nombre">
                  <mat-icon class="status-icon inactive" matTooltip="Inactivo" matTooltipPosition="above">
                    highlight_off
                  </mat-icon>
                  <strong>
                    {{ checkInactivo.nombre }}
                  </strong>
                </div>
                <p class="version-inactiva">Versión {{ checkInactivo.numVersion }} actualizada el {{ checkInactivo.fecha | date:'dd/MM/yyyy HH:mm' }}</p>
              </div>  
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <app-loader *ngIf="cargandoChecklist" [message]="'Obteniendo cuestionarios'"></app-loader>
      </mat-card-content>
      <mat-card-footer *ngIf="loadingClientes">
        <i *ngIf="loadingClientes">Obteniendo clientes...</i>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
  <br>
  <!-- <div class="ui active inverted dimmer" *ngIf="loadings.checklists">
    <div class="ui text loader">Cargando productos...</div>
  </div> -->
