import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { ApplicationService } from '../../../application/shared/application.service';
import {  take } from 'rxjs/operators';
import { UploadFileComponent } from 'src/app/shared/components/upload-file-v2/upload-file.component';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import  { ClientesMovimientosService } from '../../clientes-movimientos/clientes-movimientos.service';



@Component({
  selector: 'cliente-importar.component',
  templateUrl: 'cliente-importar.component.html',
  styleUrls: ["cliente-importar.component.css"],
  providers: [LenderService, ClientesMovimientosService]
})
export class ModalClienteImportarComponent implements OnInit  {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public archivos = null;
  file: File[] = [];
  public lenders: Lender[];
  public isFile: boolean;
  myForm: FormGroup;
  @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;

  public concepto: any;
  acceptFiles: string = '.xlsx, .xls';
  idFinanciera: any;

  constructor(
    public dialogRef: MatDialogRef<ModalClienteImportarComponent>,
    private app: ApplicationService,
    public movsServ: ClientesMovimientosService,
    private appService: ApplicationService,
    public dialog: MatDialog,

  ) {
    this.file = null;
    this.myForm = new FormGroup({
      concepto: new FormControl()
    });
  }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close({
      success: false,
    });
  }

  onUpload(files: File []) {
    this.file = files;
    this.isFile = true;
    this.onChange.emit(this.file);
  }

  onRemoveFile() {
    this.isFile = false;
    this.file = null;
    this.onChange.emit(false);
    this.uploadFileComponent.removeFile();     
  }

  onErrorFile(error) {
    this.appService.showError(error);
  }

  handleFiles(files: File[]) {
    this.file = files;
  }

  public subirArchivo() {

    if (!this.file && this.file.length > 0) {
      this.app.showSnackbar(
        '¡Aviso!',
        'Favor de cargar un archivo.',
        3000,
        'warning'
      );
      return;
    }

    const archivo = this.file[0];
    const extension = archivo.name.split('.').pop().toLowerCase();
    const tiposValidos = ['xls', 'xlsx', 'csv'];
  
    if (!tiposValidos.includes(extension)) {
      this.app.showSnackbar(
        '¡Aviso!',
        'El archivo no es un archivo de Excel válido.',
        3000,
        'warning'
      );
      return;
    }
    
    const loading: number = this.app.showLoading('Procesando datos...');
    
    this.movsServ.subirArchivo(this.file[0])
      .pipe(take(1))
      .subscribe((respuesta) => {
        if (respuesta.success) {
          this.onRemoveFile();
          this.app.hideLoading(loading);
          this.app.showSnackbar('¡Aviso!', 'El archivo se ha subido correctamente.', 3000, 'success');
          this.dialogRef.close(true);
        } else {
          this.app.hideLoading(loading);
          this.dialogRef.close(false);
          this.app.showSnackbar('¡Aviso!', 'Error al subir el archivo.', 3000, 'error');
        }
      }, error => {
        this.app.hideLoading(loading);
        this.dialogRef.close(false);
        this.app.showError(error);
      });
  }

  salir(bol: boolean) {
    this.dialogRef.close(bol);
  }

}
