import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

//import { environment } from "../../../../environments/environment";
import { environmentSelector } from '../../../../environments/environment.selector';
import { ServicesVisitasKobraResponse } from './services-visitas-response';
@Injectable({
  providedIn: 'root'
})
export class ServicesVisitasService {
  public baseURL: string = environmentSelector().kobraServices.visitas;
  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.visitas;
  }

  private headers(): HttpHeaders {
    let header: any = { 'Content-Type': 'application/json' };
    let token = localStorage.getItem('access-token');
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
      header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    if (token) {
      header['Authorization'] = token;
    }
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, login: boolean = false): Observable<ServicesVisitasKobraResponse> {

    return req.pipe(

      map(data => {
        let res = <ServicesVisitasKobraResponse>data;
        return res;
      }),
      catchError((err: HttpErrorResponse | string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            }
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              message = err.error.error.message || 'Refresca la página e intentalo de nuevo.';
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete(path: string): Observable<ServicesVisitasKobraResponse> {
    return this.request(this.httpClient.delete(this.baseURL + path, { headers: this.headers() }));
  }

  public get(path: string): Observable<ServicesVisitasKobraResponse> {
    return this.request(this.httpClient.get(this.baseURL + path, { headers: this.headers() }));
  }

  public patch(path: string, data: any): Observable<ServicesVisitasKobraResponse> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, { headers: this.headers() }));
  }

  public put(path: string, data: any): Observable<ServicesVisitasKobraResponse> {
    return this.request(this.httpClient.put(this.baseURL + path, data, { headers: this.headers() }));
  }

  public post(path: string, data: any): Observable<ServicesVisitasKobraResponse> {
    return this.request(this.httpClient.post(this.baseURL + path, data, { headers: this.headers() }));
  }

  public getFile( path: string ): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(this.baseURL + path, {headers: this.headers(),  responseType: 'blob',
    reportProgress: true,
    observe: 'events'});
  }
}