import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//import { ApplicationService } from "../../../application/shared/application.service";

@Component({
  selector: 'domicilio.component',
  templateUrl: 'domicilio.component.html',
  styleUrls: ["domicilio.component.css"]
})
export class ModalSeleccionDomicilioComponent {
  public idDomicilio: any;
  public selectedTag: any;
  constructor(
    public dialogRef: MatDialogRef<ModalSeleccionDomicilioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){

  }

    onNoClick(): void {
        this.dialogRef.close({
            success: false,
        });
    }

    ngOnInit() {
        /*if(this.data.domicilios.length  === 1){
          this.idDomicilio = this.data.domicilios[0].idAcreditadoDomicilio;

        }*/
    }

    public regresarDomicilio(): void {
        this.dialogRef.close({
            success: true,
            idAcreditadoCuentaDomicilio:this.idDomicilio
        });
    }

}
