import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Pagination } from "../pagination/pagination.model";
import { PromisePayment } from "./PromisePayment.model";
import { PromisePaginator } from "./promesas-pago.paginator";
import { map, catchError } from "rxjs/operators";
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';
import { environmentSelector } from 'src/environments/environment.selector';

@Injectable({
    providedIn: 'root'
})
export class PromesasPagoService {
    baseURL: string = environmentSelector().kobraServices.visitas;
    private serviceURL: string = '/promesas-pago-grid';
    private serviceVisitasURL: string = '/visitas';

    constructor(
        private serviciosVisitas: ServicesVisitasService,
        private httpClient: HttpClient) { }

    private headers(): HttpHeaders {
        let header: any = {'Content-Type': 'application/json'};
        let token = localStorage.getItem('access-token');
        if (token) {
            header['Authorization'] = token;
        }
        let paisSeleccionado = localStorage.getItem('paisSeleccionado');
        if(paisSeleccionado){
            header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
        }
        return new HttpHeaders(header);
    }

    public getPromise( pagination: Pagination, extraParams: string ): Observable<PromisePaginator> {

        let query: string = '?page=' + pagination.nextPage + extraParams;
        return this.serviciosVisitas.get(this.serviceURL + query).pipe(
            map(res => {
                let response: any = res;
                let promisePaginator: PromisePaginator = new PromisePaginator();
                promisePaginator.pagination = new Pagination();
                promisePaginator.pagination.currentPage = response.data.page;
                promisePaginator.pagination.pageCount = response.data.pages;
                promisePaginator.pagination.perPage = pagination.perPage;
                promisePaginator.pagination.totalCount = response.data.totalRows;
                promisePaginator.data = PromisePayment.mapArray(response.data.pageData);
                return promisePaginator;
            })
        );
    }

    public obtenerEstatusPromesas(): Observable<any> {
        return this.serviciosVisitas.get(`/estatus?idGrupoEstatus=2`).pipe(
            map((res) => <any> res.data)
        );
    }
}
