<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; hijoDesactivado();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container-wrapper" [ngClass]="{'button-container': !filtros.mostrarMapa, 'button-container-mapa': filtros.mostrarMapa}">
        
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"  
            [matMenuTriggerFor]="menuDescarga"
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="verMapa"
            *ngIf="!filtros.mostrarMapa"  
            (click)="showMap(true)"
            matTooltip="Mostrar en mapa"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">map</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ocultarMapa"
            *ngIf="filtros.mostrarMapa"  
            (click)="showMap(false)"
            matTooltip="Ocultar mapa"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">map</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"  
            (click)="loadAccounts(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizarMapa"
            *ngIf="filtros.mostrarMapa"  
            [disabled]="!listFiltered"
            (click)="consultarCuentasMapa(0, 1)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayudaComentario(!ayuda)">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0"
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogEstatus()">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Estatus</span>
      </button>
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0"
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogEtiquetaOperaciones()">
        <mat-icon class="kobra-icon">bookmark_border</mat-icon>
        <span>Etiquetas</span>
      </button>
      <button mat-menu-item
        [disabled]="itemsSelected.length == 0"
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogAsignar()">
        <mat-icon class="kobra-icon">assignment_ind</mat-icon>
        <span>Asignación</span>
      </button>
      <button mat-menu-item
        [disabled]="itemsSelected.length !== 1  "
        *ngIf="!filtros.mostrarMapa"
        (click)="btnDetalleCuenta()">
        <mat-icon class="kobra-icon">details</mat-icon>
        <span>Ver Detalle</span>
      </button>
      <!-- botones de mapa -->
      <button  mat-menu-item 
        *ngIf="filtros.mostrarMapa"
        [ngClass]="dibujando == false ? 'ui mini blue button' : 'ui mini black button'"
        (click)="asignarCuentasPorRango()">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Seleccionar rango de cuentas</span>
      </button>
      <button mat-menu-item
        *ngIf="filtros.mostrarMapa" 
        (click)="borrarSeleccion()">
        <mat-icon class="kobra-icon">delete</mat-icon>
        <span>Borrar selección</span>
      </button>
      <button mat-menu-item 
        *ngIf="filtros.mostrarMapa"
        (click)="showAgentTodasModal()">
        <mat-icon class="kobra-icon">assignment</mat-icon>
        <span>Asignar todas las cuentas</span>
      </button>
      <button mat-menu-item
        *ngIf="filtros.mostrarMapa"
        [disabled]="!asignarSeleccion"
        (click)="showAgentModal()">
        <mat-icon class="kobra-icon">assignment_ind</mat-icon>
        <span>Asignar selección</span>
      </button>
    </mat-menu>

    <mat-menu #menuDescarga="matMenu">
      <button mat-menu-item 
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(0)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Sin detalle</span>
      </button>
      <button mat-menu-item 
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(1)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Con detalle</span>
      </button>
    </mat-menu>

    <mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
      <mat-toolbar>
        <button class="filter-button-side" mat-flat-button
          matTooltip="Ocultar filtros"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle()"
        >
          <mat-icon>cancel</mat-icon>
        </button> 
        Filtros
      </mat-toolbar>
      <div class="accordion-filtros">
        <mat-accordion [multi]="false">
          <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
            <mat-expansion-panel-header>
              Súper Administrador
              <span *ngIf="this.contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('superAdmin') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cliente'"
                  [placeHolder]="loadingLenders ? 'Obteniendo clientes...' : 'Selecciona un cliente'"
                  [value]="'idFinanciera'"
                  [labelField]="'name'"
                  [icon]="'account_balance'"
                  [options]="lenders"
                  [(ngModel)]="idLender"
                  (ngModelChange)="onChangeLender($event);"
                >
                </mat-select-search>
                
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Excluir Demo</mat-label>
                  <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
            <mat-expansion-panel-header>
              Generales
              <span *ngIf="contabilizadorDeFiltros('generales') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>ID Cuenta</mat-label>
                  <input matInput textSanitizer  
                  [(ngModel)]="filtros.idExterno"
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                  placeholder="Capture el id de la cuenta">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.idExterno" 
                  (click)="filtros.idExterno = null;this.contabilizadorDeFiltros('generales');">clear</mat-icon>
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Visibilidad</mat-label>
                  <mat-select
                  [(ngModel)]="filtros.visibilidad"
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                  placeholder="Seleccione..."
                  >
                  <mat-option [value]="1">Red Kobra</mat-option>
                  <mat-option [value]="2">Red Interna</mat-option>
                </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.visibilidad != null" 
                  (click)="filtros.visibilidad = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>visibility</mat-icon>
                </mat-form-field>
                
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Estatus</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.estatus"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <mat-option [value]="1">Activa</mat-option>
                    <mat-option [value]="2">En Espera de Confirmación</mat-option>
                    <mat-option [value]="3">Apartada</mat-option>
                    <mat-option [value]="4">Visitada</mat-option>
                    <mat-option [value]="5">Inactiva</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.estatus != null" 
                  (click)="filtros.estatus = null; $event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>azm</mat-icon>
                </mat-form-field>
             
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Geolocalizada</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.geoloc"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <mat-option [value]="1">Sí</mat-option>
                    <mat-option [value]="0">No</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.geoloc != null" 
                  (click)="filtros.geoloc = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>public</mat-icon>
                </mat-form-field>
             
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadingProducts ? 'Cargando productos...' : 'Producto'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.tipoCuenta"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let product of products; index as ti">
                      <mat-option [value]="product.idProducto">{{product.nombre || product.nomProducto}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.tipoCuenta != null" 
                  (click)="filtros.tipoCuenta = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>conveyor_belt</mat-icon>
                </mat-form-field>
               
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadings.sucursales ? 'Cargando sucursales...' : 'Sucursal'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.sucursal"
                    placeholder="Seleccione..."
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                  >
                    <ng-container *ngFor="let sucursal of sucursales; index as ti">
                      <mat-option [value]="sucursal.idSucursal">{{sucursal.nombreSucursal}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.sucursal != null" 
                  (click)="filtros.sucursal = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>apartment</mat-icon>
                </mat-form-field>
            
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Etiqueta Kobra</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.etiquetaKobra"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let tag of motivos; index as ti">
                      <mat-option [value]="tag.idEtiquetaOperacion">{{tag.nombre}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.etiquetaKobra != null" 
                  (click)="filtros.etiquetaKobra = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>tag</mat-icon>
                </mat-form-field>
      
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadingEtiquetasCuenta ? 'Cargando etiquetas...' : 'Etiqueta Cuenta'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.idEtiquetaCuenta"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let etiqueta of etiquetasCuenta; index as ti">
                      <mat-option [value]="etiqueta.idEtiquetaCuenta">{{etiqueta.nombre}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.idEtiquetaCuenta != null" 
                  (click)="filtros.idEtiquetaCuenta = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
                  <mat-icon matSuffix>tag</mat-icon>
                </mat-form-field>
               
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Cuenta
              <span *ngIf="this.contabilizadorDeFiltros('cuenta') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('cuenta') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cuenta'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="acreditadoFilterSearch"
                  [(ngModel)]="filtros.acreditado"
                  (ngModelChange)="this.contabilizadorDeFiltros('cuenta');"
                  >
                </mat-select-search>
               
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Días Moratorios</mat-label>
                  <input
                    matInput textSanitizer 
                    type="number"
                    [(ngModel)]="filtros.diasMoratorios"
                    (ngModelChange)="this.contabilizadorDeFiltros('cuenta');"
                    placeholder="Días Moratorioss"
                    maxlength="4"
                    pattern="[0-9]"
                    max="9999"
                    min="1"
                    (input)="onInputLimite()"
                  />
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.diasMoratorios" 
                  (click)="filtros.diasMoratorios = null;this.contabilizadorDeFiltros('cuenta');">clear</mat-icon>
                  <mat-icon matSuffix>date_range</mat-icon>
                </mat-form-field>
                
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Grupo              
              <span *ngIf="this.contabilizadorDeFiltros('grupo') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('grupo') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cuenta'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'id'"
                  [labelField]="'nombre'"
                  [icon]="'groups'"
                  [realizarBusqueda]="grupoFilterSearch"
                  [(ngModel)]="filtros.idGrupo"
                  (ngModelChange)="this.contabilizadorDeFiltros('grupo');"
                >
                </mat-select-search>
              </li>
             
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Ubicación
              <span *ngIf=" this.contabilizadorDeFiltros('ubicacionMX') >= 1 && pais == 'MX' " class="notification-dot-filtros">{{  this.contabilizadorDeFiltros('ubicacionMX') }}</span>
              <span *ngIf=" this.contabilizadorDeFiltros('ubicacion') >= 1 && pais != 'MX' " class="notification-dot-filtros">{{  this.contabilizadorDeFiltros('ubicacion') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side" *ngIf="pais != 'MX'">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelEstado}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.estado"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
                  placeholder="Capture el {{labelEstado}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.estado" 
                  (click)="filtros.estado = null;this.contabilizadorDeFiltros('ubicacion');">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
              
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelMunicipio}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.municipio"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
                  placeholder="Capture el {{labelMunicipio}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.municipio" 
                  (click)="filtros.municipio = null;this.contabilizadorDeFiltros('ubicacion');">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
               
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelColonia}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.colonia"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
                  placeholder="Capture la {{labelColonia}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.colonia" 
                  (click)="filtros.colonia = null;this.contabilizadorDeFiltros('ubicacion');">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
               
              </li>
            </ol>
            <ol class="lista-filtros-side" *ngIf="pais == 'MX'">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="labelEstado"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idEstado'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="estados"
                  [(ngModel)]="filtros.idEstado"
                  (ngModelChange)="onChangeEstate($event);"
                >
                </mat-select-search>
               
              </li>
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="this.loadings.municipios ? 'Cargando municipios...' : labelMunicipio"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idMunicipio'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="municipios"
                  [(ngModel)]="filtros.idMunicipio"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacionMX');"
                >
                </mat-select-search>
                
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelCodigoPostal}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.codigoPostal"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacionMX');"
                  placeholder="Capture el código postal">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.codigoPostal" 
                  (click)="filtros.codigoPostal = null;this.contabilizadorDeFiltros('ubicacionMX');">clear</mat-icon>
                  <mat-icon matSuffix>package</mat-icon>
                </mat-form-field>
               
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelColonia}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.colonia"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacionMX');"
                  placeholder="Capture la colonia">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.colonia" 
                  (click)="filtros.colonia = null;this.contabilizadorDeFiltros('ubicacionMX');">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
              
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Fechas
              <span *ngIf="this.contabilizadorDeFiltros('fechasTotal') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasTotal') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-accordion [multi]="false">
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas creación
                      <span *ngIf="this.contabilizadorDeFiltros('fechasC') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasC') }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaCreacionFin ? filtros.fechaCreacionFin : today"
                          [(ngModel)]="filtros.fechaCreacionInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasC');"
                        />
                        
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaCreacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaCreacionFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasC');"
                        />
                        
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas asignación
                      <span *ngIf="this.contabilizadorDeFiltros('fechasA') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasA') }}</span>

                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaAsignacionFin ? filtros.fechaAsignacionFin : today"
                          [(ngModel)]="filtros.fechaAsignacionInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasA');"
                        />
                       
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaAsignacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaAsignacionFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasA');"
                        />
                       
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas importación
                      <span *ngIf="this.contabilizadorDeFiltros('fechasI') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasI') }}</span>

                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaImportacionFin ? filtros.fechaImportacionFin : today"
                          [(ngModel)]="filtros.fechaImportacionInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasI');"
                        />
                        
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaImportacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaImportacionFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasI');"
                        />
                       
                      </li>
                    </ol>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Agente
              <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Agente'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="agenteFilterSearch"
                  [(ngModel)]="filtros.agente"
                  (ngModelChange)="this.contabilizadorDeFiltros('agente');"
                  [minBusqueda]="1"
                >
                </mat-select-search>
                
              </li>
              
            </ol>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      
      
      <ol class="lista-filtros-buttons">
        <button
        class="boton-accion-front"
          style="width: 100%;"
          mat-stroked-button
          color="primary"
          (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
        </button>
        <button
          style="width: 100%; margin-top: 10px;"
          mat-stroked-button
          (click)="clearFilter()">
            <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
            Limpiar Filtros
        </button>
      </ol>
    </mat-sidenav>
  
    <div class="contenido" [ngStyle]="{'max-height': altoVentana+'px'}" >
  
  <div t-header>
    <mat-card *ngIf="ayuda && filtros.mostrarMapa" class="cardAyuda">
      <mat-card-content>
        <p><mat-icon color="primary">help</mat-icon>
          Este módulo es una sección central en el sistema, donde se visualizan y gestionan las cuentas que han sido subidas por
          los usuarios. En esta vista, las cuentas se muesran a nivel detalle, es decir, mostrando de primera instancia el subid
          o los diferentes domicilios que pueda tener la cuenta.
        </p>
        Acciones:
        <p>
          <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a las cuentas
          ya existentes.
        </p>
        <p>
          <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios
          de filtros aplicados.
        </p>
        <p>
          <mat-icon class="kobra-icon">edit</mat-icon><b> Seleccionar rango de cuentas: </b> Te permite dibujar en el mapa un area para seleccionar esas cuentas.
        </p>
        <p>
          <mat-icon class="kobra-icon">delete</mat-icon><b> Borrar selección: </b> Puedes borrar los elementos seleccionados en el mapa.
        </p>
        <p>
          <mat-icon class="kobra-icon">assignment</mat-icon><b> Asignar todas las cuentas: </b> Te permite asignar un agente a todas las cuentas filtradas.
        </p>
        <p>
          <mat-icon class="kobra-icon">assignment_ind</mat-icon><b> Asignar selección: </b> Puedes asignar un agente a los elementos seleccionados en el mapa.
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  
      <app-loader *ngIf="isLoadCuentasMapa" [message]="'Cargando mapa...'"></app-loader>
      <div style="margin-top: 15px" *ngIf="filtros.mostrarMapa && accounts && !isLoadCuentasMapa">
        <div style="height: calc(100vh - 314px); overflow-y: hidden;">
          <mapbox-cuentas  style="overflow-y:hidden" [cuentas]="accounts" (outputEmiter)="outputEmiter($event)" #mapaCuentas></mapbox-cuentas>
        </div>
      </div>
    </div>
    <div *ngIf="filtros.mostrarMapa && !isLoadCuentasMapa">
      <div>
        <span><b>Cuentas seleccionadas: {{cuentasSeleccionadas}}</b></span>
      </div>
      <div>
        <span><b>Mostrando {{accounts.length}} cuentas</b></span>
      </div>
    </div>
    
    <app-grid 
      *ngIf="!filtros.mostrarMapa"
      [loading]="isLoadCuentas"
      [columns]="tablaCuentas.columns"
      [items]="accounts"
      [headers]="tablaCuentas.headers"
      [multiSelect]="tablaCuentas.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (onLinkRow)="onClick($event)"
      (onCtrlClicLinkRow)="onCtrlClick($event)"
      (sortChange)="setSortField($event)">

      <div t-header>
        <mat-card *ngIf="ayuda && !filtros.mostrarMapa" class="cardAyuda">
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              Este módulo ofrece un listado exhaustivo de todas las cuentas a nivel domicilio. Aquí, puedes visualizar
              información general de tus cuentas y sus domicilios. Dentro de este módulo, se habilitan varias acciones clave
              para la gestión eficiente de las cuentas:
      
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los
              conceptos, permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios
              de filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los
              datos de los productos para su análisis o uso fuera de línea.
            </p>
            <p>
              <mat-icon class="kobra-icon">map</mat-icon><b> Mapa: </b>  Puedes visualizar tus cuentas geolocalizas en un mapa a nivel global.

            </p>
            <p>
              <mat-icon class="kobra-icon">assignment_ind</mat-icon><b> Asignación: </b> Permite asignar cuentas específicas a los
              agentes de campo.
            </p>
            <p>
              <mat-icon class="kobra-icon">swap_vert</mat-icon><b> Estatus: </b> Facilita la actualización del estado de las
              cuentas, ya sea Activa o Inactiva.
            </p>
            <p>
              <mat-icon class="kobra-icon">bookmark_border</mat-icon><b> Etiquetas: </b> Esta función posibilita la adición de
              etiquetas personalizadas a las cuentas.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator *ngIf="!filtros.mostrarMapa"
          showFirstLastButtons
          [length]="accountPaginator.pagination?.totalCount"
          [pageIndex]="accountPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-grid>
</div>
  