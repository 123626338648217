import { Component, EventEmitter, ElementRef, OnChanges, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";

import { FormValidation } from "../../../../shared/form-validation/form-validation.model";
import { FormValidationService } from "../../../../shared/form-validation/form-validation.service";
import { LoaderService } from "../../../../loader/loader.service";
import { MessageService } from "../../../../shared/message/message.service";
import { PersonalReference } from "../personal-reference.model";
import { PersonalReferenceService } from "../personal-reference.service";
import { PhoneType } from "../../../../phone-type/phone-type.model";
import { PhoneTypeService } from "../../../../phone-type/phone-type.service";
import { Relationship } from "../../../../relationship/relationship.model";
import { RelationshipService } from "../../../../relationship/relationship.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';

declare let $: any;

@Component({
  selector: 'personal-reference-modal-form',
  templateUrl: './personal-reference-modal-form.component.html',
  styleUrls: ['./personal-reference-modal-form.component.css'], 
  providers: [
    FormValidationService,
    PersonalReferenceService,
    PhoneTypeService,
    RelationshipService,
    LoaderService
  ]
})
export class PersonalReferenceModalFormComponent implements OnInit, OnChanges {
  private $modal: any;

  public phoneTypes: PhoneType[];
  public relationships: Relationship[];

  public form: FormGroup;
  public nameValidation: FormValidation;
  public phoneNumberValidation: FormValidation;
  public phoneTypeValidation: FormValidation;
  public reltionshipValidation: FormValidation;
  public surnameValidation: FormValidation;

  personalReference: PersonalReference;
  agentID: number;
  totalReferencias: number;

  afterSave: Function;
  afterDelete: Function;

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private personalReferenceService: PersonalReferenceService,
    private phoneTypeService: PhoneTypeService,
    private relationshipService: RelationshipService,
    private rooElement: ElementRef,
    private app: ApplicationService,
    public dialogRef: MatDialogRef<PersonalReferenceModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(!data.nuevo){
      this.personalReference = data.personalReference;
    }else{
      this.personalReference = new PersonalReference();
    }
    
    this.agentID = data.agentID;
    this.afterSave = data.afterSave;
    this.afterDelete = data.afterDelete;
    this.totalReferencias = data.totalReferencias;
  }

  ngOnInit() {
    
    this.loadPhoneTypes();
    this.loadRelationships();
    this.initForm();
    this.initValidation();
  }

  ngOnChanges() {
    if (this.form) {
      this.resetForm();
    }
  }

  onClose() {
    this.dialogRef.close({
      nuevo: this.data.nuevo,
      cancelar: true
    });
  }

  delete(): void {
    const loader = this.app.showLoading('Eliminando referencia personal...')
    const personalReference: PersonalReference = this.prepareSaveData();
    personalReference.totalReferenciasPersonales = this.totalReferencias - 1;
    this.personalReferenceService.delete(this.personalReference.id, personalReference).subscribe(
        () => {
          this.app.showSnackbar('¡Aviso!', 'Referencia laboral eliminada correctamente.', 3000, 'success');
          this.app.hideLoading(loader);
          this.afterDelete(this.personalReference);
          this.dialogRef.close({
            nuevo: this.data.nuevo,
            cancelar: false
          });
        },
        error => {
          this.app.hideLoading(loader);
          this.app.showError(error);
        }
      )
  }

  private getRelationship( array: Relationship[], toFind: number ): Relationship | null {
    let relationship: Relationship | null = null;
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == toFind) {
        relationship = array[i];
        break;
      }
    }
    return relationship;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [
        Validators.required,
        this.noWhitespaceValidator
      ]],
      surname: ['', [
        Validators.required,
        this.noWhitespaceValidator
      ]],
      phoneNumber: ['', [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      phoneType: ['', [
        Validators.required
      ]],
      relationship: ['', [
        Validators.required
      ]],
    });

    if(this.personalReference){
      this.resetForm();
    }
  }

  private initValidation(): void {
    this.nameValidation = new FormValidation();
    this.nameValidation.formControl = this.form.controls['name'];
    this.nameValidation.observeFromControl();
    this.nameValidation.message = {
      required: 'Información requerida.'
    };
    
    this.surnameValidation = new FormValidation();
    this.surnameValidation.formControl = this.form.controls['surname'];
    this.surnameValidation.observeFromControl();
    this.surnameValidation.message = {
      required: 'Información requerida.'
    };
    
    this.phoneNumberValidation = new FormValidation();
    this.phoneNumberValidation.formControl = this.form.controls['phoneNumber'];
    this.phoneNumberValidation.observeFromControl();
    this.phoneNumberValidation.message = {
      maxlength: `Debe teber exactamente 10 digitos.`,
      minlength: `Debe teber exactamente 10 digitos.`,
      pattern: 'Formato incorrecto, debe incluir solo números.',
      required: 'Ingrese un número de teléfono.'
    };
    
    this.phoneTypeValidation = new FormValidation();
    this.phoneTypeValidation.formControl = this.form.controls['phoneType'];
    this.phoneTypeValidation.observeFromControl();
    this.phoneTypeValidation.message = {
      required: 'Información requerida.'
    };
    
    this.reltionshipValidation = new FormValidation();
    this.reltionshipValidation.formControl = this.form.controls['relationship'];
    this.reltionshipValidation.observeFromControl();
    this.reltionshipValidation.message = {
      required: 'Información requerida.'
    };
  }

  public hide(): void {
    this.$modal.modal('hide');
  }

  private loadPhoneTypes(): void {
    this.phoneTypeService.all().subscribe(
      phoneTypes => this.phoneTypes = phoneTypes,
      error => this.messageService.httpError(error)
    )
  }

  private loadRelationships(): void {
    this.relationshipService.all().subscribe(
      relationships => this.relationships = relationships,
      error => this.messageService.httpError(error)
    )
  }

  private prepareSaveData(): PersonalReference {
    let formModel = this.form.value;
    let personalReference: PersonalReference = new PersonalReference();
    personalReference.idCobrador = this.agentID;
    personalReference.id = this.personalReference.id;
    personalReference.name = formModel.name.trim();
    personalReference.surname = formModel.surname.trim();
    personalReference.relationshipID = formModel.relationship;
    personalReference.phoneNumber = formModel.phoneNumber;
    personalReference.phoneTypeID = formModel.phoneType;
    return personalReference;
  }

  private resetForm(): void {
    this.form.reset({
      name: this.personalReference.name,
      surname: this.personalReference.surname,
      phoneNumber: this.personalReference.phoneNumber,
      phoneType: this.personalReference.phoneTypeID,
      relationship: this.personalReference.relationshipID
    });
  }

  public save(): void {
    if (this.form.valid) {
      const loader = this.app.showLoading('Guardando Referencia personal...');
      let personalReference: PersonalReference = this.prepareSaveData();
      let response: Observable<PersonalReference>
      
      personalReference.agentID = this.agentID;
      if (personalReference.id) {
        personalReference.id = this.personalReference.id;
        response = this.personalReferenceService.update(personalReference);
      } else {
        personalReference.totalReferenciasPersonales = this.totalReferencias + 1;
        response = this.personalReferenceService.add(personalReference);
      }

      response.subscribe(response => {
        this.app.hideLoading(loader);
        this.app.showSnackbar('¡Aviso!', 'Referencia personal guardada correctamente.', 3000, 'success');
        personalReference.id = personalReference.id || +response.idReferenciaPersonal;
        personalReference.fullName = `${personalReference.name} ${personalReference.surname}`;
        personalReference.relationship = this.getRelationship(this.relationships, this.form.value.relationship);
        if(!this.data.nuevo){
          this.afterSave(personalReference);
        }
        
        this.dialogRef.close({
          nuevo: this.data.nuevo,
          cancelar: false
        });
      }, error => {
        this.app.hideLoading(loader);
        this.app.showError(error);
      });
    }
  }
}
