<div class="app content tabla kobra">

  <div class="button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="actualizar" (click)="obtenerDescargas(1)" matTooltip="Refrescar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="ayuda" 
                      matTooltip="Ayuda"
                      mat-tooltip-panel-above
                      (click)="ayuda = !ayuda">
                      <mat-icon color="primary">help</mat-icon>
                    </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>


<app-table [classContainer]="'tabla-completa'" [loading]="cargandoDescargas" [columns]="tablaDescargas.columnas"
  [items]="descargas" [headers]="tablaDescargas.headers" [tableHeaders]="tablaDescargas.tableHeaders"
  [ignoreSortChange]="true" [multiSelect]="tablaDescargas.multiSelect" (onLinkRow)="onLinkRow($event)">

  <div t-header>
    <mat-card *ngIf="ayuda" class="cardAyuda">
      
      <mat-card-content>
        <p><mat-icon color="primary">help</mat-icon>

          El módulo "Descargas" centraliza y gestiona todas las descargas realizadas por los usuarios, proporcionando un acceso fácil y organizado a los archivos descargados.


        </p>
        Acciones:
       
        <p>
          <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado.
        </p>
     
      </mat-card-content>
    </mat-card>
  </div>
  <ng-container t-paginator>
    <mat-paginator 
      showFirstLastButtons
      [length]="pagination?.totalRows"
      [pageIndex]="pagination?.page-1"
      [pageSize]="pageSize"
      (page)="onPage($event)"
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </ng-container>

</app-table>
</div>