
<h1 mat-dialog-title>Calificar Visitas Masivas</h1>
<mat-dialog-content >
  <visita-masiva-detail [visitasACalificar]="visitasACalificar"  [idCliente]="idCliente" [idFinanciera]="idFinanciera" (closeModal)="salirCalificar()"></visita-masiva-detail>
  <br>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button class="boton-cancelar" (click)="salirCalificar()"mat-stroked-button><mat-icon>close</mat-icon>Cancelar</button>
</mat-dialog-actions>


