<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="obtenerPeriodos(filtros.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/cobradores-facturaciones-periodos/nuevo']" queryParamsHandling="preserve">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1"  (click)="onEdit(itemsSelected[0])">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="onDelete(itemsSelected[0])">
        <mat-icon color="warn" >delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
  
    <app-table
      [classContainer]="'tabla-completa'"  
      [loading]="cargandoLista"
      [columns]="tabla.columns"
      [items]="periodos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="setSortField($event)"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"
      >

      <div t-header>  
        <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Períodos de Facturación" en el sistema es una herramienta clave para la configuración y gestión de los
            ciclos
            de facturación, un aspecto esencial para el control financiero tanto de clientes como de agentes.
            Este módulo está específicamente diseñado para definir y administrar los períodos de facturación, que actualmente se
            establecen semanalmente, abarcando de lunes a domingo.
        
            La configuración de estos períodos de facturación semanales es fundamental para mantener un seguimiento preciso y
            actualizado del estado de cuenta de los clientes. Permite a los usuarios del sistema organizar y controlar las
            transacciones financieras de manera sistemática y coherente con el ciclo de facturación establecido. Esta
            regularidad y
            claridad en los períodos de facturación son vitales para asegurar que todos los movimientos financieros sean
            registrados
            y gestionados adecuadamente dentro del marco temporal definido.
        
            Además, esta configuración semanal tiene una importancia particular en la precisión del pago que se realiza a los
            agentes. Al tener períodos de facturación claramente definidos, el sistema puede calcular de manera exacta los pagos
            correspondientes a los agentes por su trabajo, basándose en las actividades y transacciones realizadas durante la
            semana. Esto asegura no solo una compensación justa y precisa para los agentes, sino también una gestión financiera
            eficiente y transparente.</p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los periodos de
            facturación existentes, permitiendo aplicar filtros basados en diversos criterios.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
            filtros aplicados.
          <p>
            <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Los usuarios pueden añadir nuevos periodos de
            facturación.
          </p>
          <p>
            <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta acción permite la actualización y modificación de
            los periodos de facturación existentes, asegurando que la información sea precisa y esté actualizada.
          </p>
          <p>
            <mat-icon color="warn">delete_forever</mat-icon><b> Eliminar: </b> Ofrece la opción de eliminar periodos de
            facturación existentes.
        </mat-card-content>
        </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel  [expanded]="true">
        <mat-expansion-panel-header>
          Generales
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Nombre periodo</mat-label>
              <input matInput textSanitizer 
              [(ngModel)]="filtros.nombre"
              (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"
              placeholder="Capture el periodo">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.nombre" 
              (click)="filtros.nombre = null; this.contabilizadorDeFiltros('generales')">clear</mat-icon>
              <mat-icon matSuffix>donut_large</mat-icon>
            </mat-form-field>
          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filterModal(true);">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="filterModal(false); filtros.nombre = null">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>