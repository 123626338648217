import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationService } from '../../../application/shared/application.service';
import { CuentaService } from '../../../cuentas/shared/cuenta.service';
import * as moment from 'moment';

@Component({
  selector: 'form-account-detail',
  templateUrl: './form-account-detail.component.html',
  styleUrls: ['./form-account-detail.component.css'],
  providers: [
    CuentaService
  ]
})
export class FormAccountDetailComponent implements  OnInit {
  public listaCuentaDetalle: Array<any>;
  public momentAccount;
  public loaderText: string;
  public fechaAsignacionMinDate: Date = new Date();

  @Input() cuentaDetalles: Array<any>;
  @Input() idFinanciera: number;
  @Input() idCuenta: number;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<Array<any>> = new EventEmitter();


  constructor(
    private cuentaService: CuentaService,
    private app: ApplicationService,
  ) { }

  ngOnInit() {
    this.sumarDias(this.fechaAsignacionMinDate, -1);
    this.momentAccount = moment;
    this.listaCuentaDetalle = [];

    this.listaCuentaDetalle =JSON.parse(JSON.stringify(this.cuentaDetalles));
    //this.listaCuentaDetalle = this.cuentaDetalles;
    this.inicializaFecha();
  }

  private sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    this.fechaAsignacionMinDate = new Date(fecha);
  }
  public inicializaFecha () {
    for (let lista of this.listaCuentaDetalle) {
      if(lista.idCuentaCampoTipoDato === 4 || lista.idCuentaCampoTipoDato ===5 || lista.idCuentaCampoTipoDato === 7) {
        lista.valor = new Date(lista.valor);
      }
    }
  }

  public cancel(): void {
    this.listaCuentaDetalle = null;
    this.onCancel.emit();
  }

  public guardar(): void {
    let iValida = false;
    let idCuenta= 0;
    
    for (let lista of this.listaCuentaDetalle){
      idCuenta =  this.idCuenta;
      /*
      if(!lista.valor) {
        if(lista.idCuentaCampoTipoDato != 6  && lista.idCuentaCampoTipoDato != 1 && lista.idCuentaCampoTipoDato != 2) {
          iValida = true;
          this.app.showSnackbar('¡Aviso!', 'El campo '+ lista.descripcion + " es requerido", 3000, 'warning');
        }
      }*/
      if(lista.idCuentaCampoTipoDato === 1 || lista.idCuentaCampoTipoDato === 2) {
        if(!lista.valor) {
          lista.valor = 0;
        }
      }

      if(lista.idCuentaCampoTipoDato == 1) {
        if(lista.valor) {
          if(!Number.isInteger(lista.valor)) {
            iValida = true;
            this.app.showSnackbar('¡Aviso!', 'El campo '+ lista.descripcion + " solo permite números enteros.", 3000, 'warning');
          }
        }
      }

      if(lista.idCuentaCampoTipoDato == 4) {
        lista.valor =  this.momentAccount(lista.valor).format('YYYY-MM-DD');
      }else if(lista.idCuentaCampoTipoDato == 5) {
        lista.valor = this.momentAccount(lista.valor).format('YYYY-MM-DD HH:mm:ss');
      }else if(lista.idCuentaCampoTipoDato == 6) {
        if(lista.valor) {
          lista.valor = 'true';
        }else {
          lista.valor = 'false';
        }
      }else if(lista.idCuentaCampoTipoDato == 7) {
        lista.valor =  this.momentAccount( lista.valor).format('HH:mm');
      }
    }

    if(iValida) {
      return;
    }else {
      this.editarCuentaDetalle(idCuenta);
    }
  }

  public editarCuentaDetalle(idCuenta: number) {
    let loading: number = this.app.showLoading('Guardando información de la cuenta.');
    this.loaderText = 'Guardando información de la cuenta....';
    this.cuentaService.editarCuentaDetalle(idCuenta, this.idFinanciera, this.listaCuentaDetalle).subscribe(
      res => {
        if(res.success) {
          this.modificaLista();
          this.onSave.emit(this.listaCuentaDetalle);
          this.app.hideLoading(loading);
          this.loaderText = null;
          this.app.showSnackbar('¡Aviso!', 'Información guardada correctamente.', 3000, 'success');
        }else {
          this.loaderText = null;
          this.app.showSnackbar('¡Aviso!', 'Ocurrio un error al guardar la información.', 3000, 'error');
        }
      }, (error: any) => {
        this.app.showError(error);
        this.app.hideLoading(loading);
        this.loaderText = null;
      });
  }

  public modificaLista(): void {
    for (let lista of this.listaCuentaDetalle) {
      if(lista.idCuentaCampoTipoDato === 7) {
          let fechaActual = new Date();
          let fecha =  this.momentAccount(fechaActual).format('YYYY-MM-DD') + 'T' + lista.valor + ':00';
          lista.valor =  new Date(fecha);
        }else if(lista.idCuentaCampoTipoDato === 6) {
          let bol = String((lista.valor).toLowerCase().trim()) === "true";
          if(bol) {
            lista.valor = 1;
          }else {
            lista.valor = 0;
          }
        }
    }
  }
}
