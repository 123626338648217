
import { Component, ElementRef, OnInit, ChangeDetectorRef  } from '@angular/core';
import {VariablesDialog} from "../modales/catalogo-variables/variables-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { ModalCargarArchivoWordComponent } from '../modales/cargar-archivo-word/cargar-archivo-word.component';
import { ApplicationService } from "../../../application/shared/application.service";
import { take } from "rxjs/internal/operators/take";
import { VisitService } from "../../../visit/visit.service";
import { Checklist } from "../modelos/checklist.model";
import { environment } from "../../../../environments/environment";
import moment from 'moment';
import {ConfirmacionDialog} from "../../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Params, Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-formato-cuenta',
	templateUrl: './formato-cuenta.component.html',
	styleUrls: ['./formato-cuenta.component.css'],
  providers: [VisitService],
})
export class FormatoCuentaComponent implements OnInit {
	public tieneArchivos = false;
  public ayuda: boolean = false;
  public cargando: boolean = false;
  public eliminando: boolean = false;
  public cardAyuda: boolean = false;
  //public checklist: Checklist;
  public cargandoArchivos: boolean = false;
  public file: File[] | null = null;
  public archivos:any;
  public variables:any;
  public loadVariables: boolean = false;
  public descripcion: string;
  public momentFecha;
  public nombreCliente: any;
  public itemsSelected: any[] = [];
  tipoFormato = 2;
  public descargandoArchivo: boolean = false;

	public tablaArchivos: any;
  idCliente: number;
  idChecklist: number;
  idProducto: number;
  numVersion:number;
  idFinanciera: number;
  nombreChecklist:string;

	constructor(
    public dialog: MatDialog,
    private app: ApplicationService,
    private visitService: VisitService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    const checklistTemp = localStorage.getItem('checklisTemp');
    
    /*if (checklistTemp !== null) {
      this.checklist = JSON.parse(checklistTemp) as Checklist;
    } else {
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al recibir la información del checkLists.",
        3000,
        "error"
      );
    }*/
    this.archivos = [];
    this.crearTablaArchivos();
    this.momentFecha = moment;
	}

	ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idFinanciera = +this.route.snapshot.queryParamMap.get('idFinanciera');
    this.idChecklist = +this.route.snapshot.queryParamMap.get('idChecklist');
    this.nombreChecklist = this.route.snapshot.queryParamMap.get('nombreCheckList');
    this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
    this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
    this.numVersion = +this.route.snapshot.queryParamMap.get('numVersion');

    if (!this.idCliente || !this.idChecklist || !this.nombreChecklist || !this.nombreCliente || !this.idProducto || !this.idFinanciera || !this.numVersion) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos/formatos`]);
    }else{
      //this.obtenerCliente();
      this.obtienerVariable();
      this.obtenerArchivos();
    }
	}

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  crearTablaArchivos() {
    this.tablaArchivos= {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columns: [
        {
          name: 'Nombre',
          key: 'nombreArchivo',
          hide: false
        }, {
          name: 'Descripción',
          key: 'descripcion',
          hide: false
        },{
          name: 'Fecha Carga',
          key: 'fechaRegistro',
          hide: false,
          type: 'date'
        }
      ]
    }
  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

  openDialogVariables(): void {

    const dialogRef = this.dialog.open(VariablesDialog, {
        //height: '400px',
        width: '1000px',
        disableClose: true,
        data:{
          variables: this.variables,
          tipoFormato: this.tipoFormato,
          isVersioNamiento: false
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            //this.filtrosModal = result;
            //this.cargarCuentas(this.pagina);

        }
    });
  }  

  public obtenerArchivos() {
    this.cargandoArchivos = true;
    this.visitService.obtenerArchivos(this.idCliente, this.idChecklist, this.numVersion, this.tipoFormato)
        .pipe(take(1))
        .subscribe((respuesta: any) => {
            this.cargandoArchivos = false;
            respuesta.data.forEach(item => {
              item.isDescripcion = false;
          });

          this.archivos = respuesta.data;

        },
            (error: any) => {
                this.cargandoArchivos = false;
                this.app.showError(error);
            }
        );
  }

  private obtienerVariable(): void {
    this.loadVariables = true;
    this.visitService.obtienerVariable(this.idCliente, this.idChecklist, this.numVersion, this.idProducto, this.tipoFormato)
        .subscribe(
          variables => {
                this.variables = variables.data;
                this.loadVariables = false;
            },
            error => this.app.showError(error)
        );
  }

  public descargarArchivo(archivo: any) {
    this.descargandoArchivo = true;
  
    let queryParams = `?ruta=${archivo.ruta}&nombreArchivo=${archivo.nombreArchivoServidor}`;

   this.visitService.descargarArchivoStorage(queryParams, this.idCliente).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, archivo.nombreArchivo);
        
      }
    }, error => {
      this.descargandoArchivo = false;
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
    });
  }

  public eliminarArchivo(archivo: any): void {
    this.eliminando = true;
    this.visitService.obtienerDocumentosDescargados(this.idCliente, archivo.idFormato)
        .subscribe(
          respuesta => {
                if(respuesta.data.length > 0){

                  this.app.showSnackbar(
                    "Aviso",
                    "El documento no puede ser eliminado por que ya cuenta con descargas por parte del agente.",
                    4000,
                    "warning"
                  );

                  this.eliminando = false;
                  return;
                }else{
                  this.eliminarArchivoS3(archivo);
                }
                //this.loadVariables = false;
            }, error => {
              this.eliminando = false;
              this.app.showError(error);
          });
  }

  public eliminarArchivoS3(archivo: any) {
    this.eliminando = true;
    this.visitService.eliminarArchivo(this.idCliente, archivo.ruta, archivo.nombreArchivoServidor)
        .pipe(take(1))
        .subscribe((respuesta) => {
          if(respuesta.success) {    
            this.eliminarFormato(archivo);
          }else {
            this.eliminando = false;
            this.app.showSnackbar(
              "Aviso",
              "Ocurrio un problema al eliminar el archivo.",
              3000,
              "error"
            );
          }
        }, error => {
            this.eliminando = false;
            this.app.showError(error);
        });
  }

  public eliminarFormato(archivo: any) {
    let loading: number = this.app.showLoading('Eliminando archivo...');
    this.eliminando = true;
    this.visitService.eliminarFormatoVisita(this.idCliente, archivo.idFormato, this.idChecklist, this.numVersion)
        .pipe(take(1))
        .subscribe((respuesta) => {
          if(respuesta.success) {   
            this.eliminando = false; 
              this.app.showSnackbar(
                "Aviso",
                "El documento se ha eliminado correctamente.",
                3000,
                "success"
            );
            this.app.hideLoading(loading);
            this.archivos = this.archivos.filter(a => a.idFormato !== archivo.idFormato);
            this.cdr.detectChanges();
          }else {
            this.eliminando = false;
            this.app.showSnackbar(
              "Aviso",
              "Ocurrio un problema al eliminar el archivo.",
              3000,
              "error"
            );
            this.app.hideLoading(loading);
          }
        }, error => {
            this.app.hideLoading(loading);
            this.eliminando = false;
            this.app.showError(error);
        });
  }

  openDialogConfirmacion(archivo:any): void {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas eliminar la plantilla seleccionada?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                texto: archivo.nombreArchivo,
                claseAccion: "boton-accion-eliminar"
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.eliminarFormato(archivo);
        }   
    });
  }

  openDialogCargarArchivo(): void {

    const dialogRef = this.dialog.open(ModalCargarArchivoWordComponent, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
          idCliente: this.idCliente,
          idFinaciera: this.idFinanciera,
          idChecklist: this.idChecklist,
          numVersion: this.numVersion,
          idProducto: this.idProducto,
          tipoFormato: this.tipoFormato,
          formatoConError: []
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.obtenerArchivos();
        }   
    });
  }

  public obtenerCliente() {
    this.visitService.obtenerCliente(this.idCliente)
        .pipe(take(1))
        .subscribe((respuesta: any) => {
          this.nombreCliente = respuesta.data[0].nombreFinanciera;
        },
            (error: any) => {
                this.app.showError(error);
            }
        );
  }
}
