export class PhoneCollected {
  phone: string;

  public static mapArrayData(data: any[]): PhoneCollected[] {
    let array: PhoneCollected[] = [];
    let object: any;

    data.forEach(element => {
      array.push(PhoneCollected.map(element));
    });
    return array;
  }

  public static map(data: any): PhoneCollected {
    let instance = new PhoneCollected();
    instance.phone = data.numTel;
    return instance;
  }
}
