import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConceptosSaldosService } from '../../conceptos-saldos/conceptos-saldos.service';
import { take } from 'rxjs/internal/operators/take';
import { ConceptoSaldo } from '../../conceptos-saldos/conceptos-saldo.interface';
import { ClientesMovimientosService } from '../clientes-movimientos.service';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-clientes-movimientos-form',
  templateUrl: './clientes-movimientos-form.component.html',
  styleUrls: ['./clientes-movimientos-form.component.css'],
  providers: [
    LenderService
  ]
})
export class ClientesMovimientosFormComponent implements OnInit, OnDestroy {

  title: string = 'Agregar';
  public form: FormGroup;
  public lenders: Lender[];
  public saveButton = false;
  private loadRegsFlag = false;
  conceptos: any;

  @ViewChild('conceptosInput') private conceptosInput: any;
  private conceptosFlag = false;
  private conceptosFounded: any;
  private conceptoLabelSelected: string;
  private conceptosBlurSub: Subscription;
  public hoy = new Date();
  public isCargo: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private lenderService: LenderService,
    private app: ApplicationService,
    private conceptosServ: ConceptosSaldosService,
    public clientesMovsServ: ClientesMovimientosService
  ) {

    this.form = this.fb.group({
      idFinanciera: ['', Validators.required],
      concepto: [null, []],
      idConcepto: ['', Validators.required],
      nombreMovimiento: ['', [Validators.required, this.noWhitespaceValidator]],
      monto: ['', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)]],
      fecMovimiento: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.form.get('fecMovimiento').patchValue(this.hoy);
    this.loadLenders();
    const navigationState = window.history.state;
    const idFinanciera = navigationState?.idFinanciera;
    this.form.get('idFinanciera').setValue(idFinanciera);
  }

  ngOnDestroy() {
  }

  checkControl(control: string) {
    return this.form.controls[control].invalid && this.form.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.form.controls[control].hasError('required') ? 'Este campo es requerido.' :
      this.form.controls[control].hasError('pattern') ? 'Solamente valores numéricos' : '';
  }

  onSubmit() {
    if (this.form.valid) {
      if (+this.form.get('monto').value <= 0) {
        this.app.showSnackbar('¡Aviso!', 'El monto debe ser mayor a cero.', 3000, 'warning');
        return;
      }
  
      this.saveButton = true;
      let loading: number = this.app.showLoading('Generando movimiento...');
  
      this.clientesMovsServ.generarMovimiento(this.form.value)
        .pipe(
          take(1)
        )
        .subscribe(
          (res: any) => {
            this.loadRegsFlag = true;
            this.saveButton = false;
            this.form.reset();
            this.conceptoLabelSelected = undefined;
            this.isCargo = undefined;
            this.form.get('fecMovimiento').patchValue(this.hoy);
            this.app.hideLoading(loading);
            this.app.showSnackbar('¡Aviso!', 'Movimiento generado correctamente.', 3000, 'success');
          },
          (error: any) => {
            this.loadRegsFlag = true;
            this.saveButton = false;
            this.app.showError(error);
            this.app.hideLoading(loading);
          }
        );
  
    } else {
      (Object as any).values(this.form.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }
  

  loadLenders(): void {
    this.lenderService.getAll('all').subscribe((lenders) => {
      lenders.sort(this.compare);
      this.lenders = lenders;
    }, (err: any) => {
      this.app.showError(err);
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  public buscarConceptos = function(term: string) {
    let idTipoConceptoFinanciera: number = 1;

    if (this.conceptosFilterFlag) {
      this.conceptosFilterFlag = false;
      return this.conceptosFilterFounded;
    }
    return this.conceptosServ.buscarConceptos(term, idTipoConceptoFinanciera)
        .pipe(
            take(1),
            map((res: any) => res.data.map(
                ({ idConcepto, nombre, idTipoConcepto,  cargo}) => (
                    { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto, cargo: cargo }
                )
            ))
        );
  }.bind(this);

  onConceptoSelected(concepto: ConceptoSaldo) {
    if(concepto){
      this.isCargo = concepto.cargo;

      this.form.get('idConcepto').patchValue(concepto.idConcepto);
      this.form.get('nombreMovimiento').patchValue(concepto.nombre);
    }

    this.conceptosFlag = true;

  }

  resetConcepto() {
    this.isCargo = undefined;
    this.form.get('concepto').reset();
    this.form.get('idConcepto').reset();
    this.form.get('nombreMovimiento').reset();
   
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

}
