import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[textSanitizer]'
})
export class SanitizerDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur') onBlur() {
    this.sanitizerValue();
  }

  @HostListener('keydown.enter') onEnter() {
    this.sanitizerValue();
  }

  private sanitizerValue() {
    let value: string = this.el.nativeElement.value.trim();
    
    // Sanitización: eliminar caracteres especiales
    //value = value.replace(/[^a-zA-Z0-9\s,]/g, '');
    
    if (this.el.nativeElement.value !== value) {
        this.el.nativeElement.value = value;
        this.el.nativeElement.dispatchEvent(new Event('input')); // Dispara un evento de entrada
    }
}

}
