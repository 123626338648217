import { environment } from "../environments/environment";

const getToken = async () => {
  return localStorage.getItem('access-token');
};

const cubejsOptions = {
  token: getToken,
  options: {
    apiUrl: environment.cubejsApiUrl
  }
};

export default cubejsOptions;