import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { environmentSelector } from '../../environments/environment.selector';
import { Coordenadas, CoordenadasUltima } from "./modelos/coordenadas.model";
import mapboxgl from 'mapbox-gl';

//declare var $: any;
//declare var mapboxgl: any;

@Component({
  selector: 'mapbox',
  templateUrl: 'mapbox.component.html',
  styleUrls: [
    'mapbox.component.css'
  ]
})
export class MapboxComponent implements OnChanges, OnDestroy {
  private environment = environmentSelector();

  @Input() public coordenadas :Coordenadas[];
  @Input() public coordenadasUltima?: CoordenadasUltima[]; 
  @Input() public tipoMapa: number; 
  //@Input() public isMapaDetalle: boolean; 
  private ctrlPressed: boolean = false;

  private map: any;
  public loadMapa: boolean = true;
  private isFirstChange = true;
  private previousTipoMapa: number | undefined;

  constructor() {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg';
  }

  /*ngOnInit() {
    this.showMap();
  }*/

  ngAfterViewInit() {
      this.showMap();
  }

  ngOnChanges() {
    this.reloadSource();
    if (!this.isFirstChange && this.previousTipoMapa !== this.tipoMapa) {
      this.showMap();
    }

    this.previousTipoMapa = this.tipoMapa;
    this.isFirstChange = false;
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

  private showMap(): void {
    this.loadMapa = true
    let longitud: any;
    let latitud: any;
    let feature = [];
    let nivelZoom: number;

    if (this.coordenadas) {
      if (this.coordenadas.length === 1) {
        longitud = this.coordenadas[0].lng;
        latitud = this.coordenadas[0].lat;
        nivelZoom = 14;
      } else {
        longitud = this.environment.paisLng;
        latitud = this.environment.paisLat;
        nivelZoom = 4;
      }

      if (this.coordenadasUltima) {
        this.coordenadasUltima.forEach(coordenada => {
          feature.push({
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [coordenada.longitud, coordenada.latitud]
            },
            properties: {
              title: "Ultima Visita",
              "agente": "Agente: " + coordenada.agente,
              "folio": "Folio visita: " + coordenada.folio,
              "domicilio": "Domicilio: " + coordenada.domicilio,
              "comentario": "Comentario: " + coordenada.comentario
            },
          });
        });
      }

      this.coordenadas.forEach(coordenada => {
        feature.push({
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [coordenada.lng, coordenada.lat]
          }
        });
      });
    } else {
      longitud = this.environment.paisLng;
      latitud = this.environment.paisLat;
      nivelZoom = 4;
      feature.push({
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": []
        }
      });
    }

    const poiOptions = {
      "width": 32,
      "height": 32,
      "x": 0,
      "y": 0,
      "pixelRatio": 1
  };

  let sTipoMapa = "";
  if(this.tipoMapa == 2){
    sTipoMapa = 'satellite-streets-v12'
  }else{
    sTipoMapa = 'streets-v12'
  }

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/' + sTipoMapa,
      center: [longitud, latitud],
      zoom: nivelZoom,
      ...poiOptions,
      cooperativeGestures: true
    });

    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      //this.map.scrollZoom.setWheelZoomRate(this.ctrlPressed ? 1 : 0);
      
      this.map.loadImage('./assets/img/kobra-verde.png', (error, verdeImage) => {
        if (error) throw error;
        this.map.addImage('kobra-verde', verdeImage);
        this.map.addLayer({
          "id": "multiples",
          "type": "symbol",
          "source": {
            "type": "geojson",
            "data": {
              "type": "FeatureCollection",
              "features": feature
            }
          },
          "layout": {
            "icon-image": "kobra-verde",
            "icon-size": 0.05
          }
        });
      });

      // Carga la imagen de la kobra amarilla
      this.map.loadImage('./assets/img/kobra-amarilla.png', (error, amarillaImage) => {
        if (error) throw error;
        this.map.addImage('kobra-amarilla', amarillaImage);

        // // Agrega la capa "ultimas" usando la imagen amarilla
        // this.map.addLayer({
        //   "id": "ultimas",
        //   "type": "symbol",
        //   "source": {
        //     "type": "geojson",
        //     "data": {
        //       "type": "FeatureCollection",
        //       "features": feature
        //     }
        //   },
        //   "layout": {
        //     "icon-image": "kobra-amarilla",
        //     "icon-size": 0.05
        //   }
        // });

        // Configura la interacción con la capa "ultimas"
        this.map.on('click', 'ultimas', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { title, agente, folio, domicilio, comentario } = e.features[0].properties;
        


          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(`  <h3>${title}</h3>
            <b>Agente:</b><p>${agente}</p>
            <b>Folio Visita:</b><p>${folio}</p>
            <b>Domicilio:</b><p>${domicilio}</p>
            <b>Comentario:</b><p>${comentario}</p>`)
            .addTo(this.map);
        });

        // Cambia el cursor al hacer hover sobre la capa "ultimas"
        this.map.on('mouseenter', 'ultimas', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        // Cambia el cursor al dejar de hacer hover sobre la capa "ultimas"
        this.map.on('mouseleave', 'ultimas', () => {
          this.map.getCanvas().style.cursor = '';
        });
      });
    });

    this.loadMapa = false;
  }

  public reloadSource(): void {
    if (!this.map) {
      return;
    }
  
    // Actualiza la capa 'ultimas'
    if (this.coordenadasUltima) {
      const ultimaFeatureCollection = {
        type: "FeatureCollection",
        features: this.coordenadasUltima.map(coordenada => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coordenada.longitud, coordenada.latitud],
          },
          properties: {
            "title": "Última Visita",
            "agente": " " + coordenada.agente,
            "folio": " " + coordenada.folio,
            "domicilio": " " + coordenada.domicilio,
            "comentario": " " + coordenada.comentario
          }
        })),
      };
  
      const ultimaSource = this.map.getSource("ultimas");
      if (ultimaSource) {
        ultimaSource.setData(ultimaFeatureCollection);
      } else {
        // Agrega la fuente 'ultimas' si aún no existe
        this.map.addSource("ultimas", {
          type: "geojson",
          data: ultimaFeatureCollection,
        });
  
        // Agrega la capa 'ultimas' usando la imagen amarilla
        this.map.addLayer({
          "id": "ultimas",
          "type": "symbol",
          "source": "ultimas",
          "layout": {
            "icon-image": "kobra-amarilla",
            "icon-size": 0.05
          }
        });
  
        // Configura la interacción con la capa 'ultimas'
        this.map.on('click', 'ultimas', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { title, agente, folio, domicilio, comentario } = e.features[0].properties;
  
          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(`
              <h3>${title}</h3>
              <b>Agente:</b><p>${agente}</p>
              <b>Folio Visita:</b><p>${folio}</p>
              <b>Domicilio:</b><p>${domicilio}</p>
              <b>Comentario:</b><p>${comentario}</p>
            `)
            .addTo(this.map);
        });
  
        // Cambia el cursor al hacer hover sobre la capa 'ultimas'
        this.map.on('mouseenter', 'ultimas', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
  
        // Cambia el cursor al dejar de hacer hover sobre la capa 'ultimas'
        this.map.on('mouseleave', 'ultimas', () => {
          this.map.getCanvas().style.cursor = '';
        });
      }
    }
  
    // Actualiza la capa 'multiples'
    const featureCollection = {
      type: "FeatureCollection",
      features: this.coordenadas.map(coordenada => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [coordenada.lng, coordenada.lat],
        },
      })),
    };
  
    const source = this.map.getSource("multiples");
    if (source) {
      source.setData(featureCollection);
  
      const lastCoordenada = this.coordenadas[this.coordenadas.length - 1];
      const center = this.coordenadas.length === 1
        ? [lastCoordenada.lng, lastCoordenada.lat]
        : [this.environment.paisLng, this.environment.paisLat];
  
      this.map.flyTo({
        zoom: this.coordenadas.length === 1 ? 14 : 4,
        center,
      });
    }
  }
  
  

}