export class ProductoNvo{
    idProducto: number;
    idTipoProducto: number;
    idFinanciera: number;
    idCliente: number;
    nombreCliente: string;
    nombre: string;
    nombreTipoProducto: string;
    descripcion: string;
    editable: boolean;
    tiposImportacion: TipoImportacion[];
    administradores: any[];

    constructor(){
    }

    public static map(data: any): ProductoNvo{
        let instance:ProductoNvo = new ProductoNvo();
        instance.idProducto = data.idProducto || 0;
        instance.idTipoProducto = data.idTipoProducto || 0;
        instance.nombre = data.nombreProducto || data.nombre || '';
        instance.descripcion = data.descripcion || '';
        instance.editable = data.editable || false;
        instance.idFinanciera = data.idFinanciera || 0;
        instance.idCliente = data.idCliente || 0;
        instance.nombreCliente =  data.nombreFinanciera || data.nombreCliente || 'Varios';
        instance.nombreTipoProducto = data.nombreTipoProducto || '';
        instance.tiposImportacion = data.tiposImportacion && data.tiposImportacion.length > 0 ? TipoImportacion.mapArray(data.tiposImportacion) : [];
        return instance;
    }

    public static mapArray(data: any[]): ProductoNvo[]{
        let instance:ProductoNvo[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            instance.push(ProductoNvo.map(element));
        }
        return instance;
    }
}

export class TipoImportacion{
    idTipoImportacion: number;
    nombre: string;

    public static map(data: any): TipoImportacion{
        let instance:TipoImportacion = new TipoImportacion();
        instance.idTipoImportacion = data.idTipoImportacion || 0;
        instance.nombre = data.nomTipoImportacion || data.nombre || 0;
        return instance;
    }

    public static mapArray(data: any[]): TipoImportacion[]{
        let instance:TipoImportacion[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            instance.push(TipoImportacion.map(element));
        }
        return instance;
    }
}