import { style } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from '../../shared/components/loading-bar/loading-bar.service';
import { VisitService } from 'src/app/visit/visit.service';
import { saveAs } from 'file-saver';
import { HttpClient, HttpEventType } from '@angular/common/http';



@Component({
  selector: 'foto-zoom',
  templateUrl: 'foto.component.html',
  styleUrls: ["foto.component.css"]
})

/*********************
 * FotoComponent
 * creater by: Uriel Gámez
 * controla las imagenes para el zoom de manera manual sin librerias.
 */
export class FotoComponent {
  @Input() foto: string;
  //0 izquierda y 1 a la derecha
  @Input() posicion: number;
  @Input() esDialogo?: boolean = false;
  @Input() height?: number = 0;
  @Input() mostrarBotonDescargar?: boolean = true;

  private lens = null;
  private img = null;
  private div = null;
  private result = null;
  private cx = null;
  private cy = null;
  private suscribedMoveLens: Function;
  private suscribeImageZoom: Function;
  private suscribedexitLens: Function;
  public idZoomContainer: string;
  public idImagen: string;
  public idImagenResult: string;
  private interval: any = null;
  public mostrarZoom: boolean = false;
  public mostrarDescarga: boolean = false;
  public urlFotoMostrar: string;

  constructor(
    private loadingService: LoadingService,
    private http: HttpClient

  ) {
    this.suscribedMoveLens = this.moveLens.bind(this);
    this.suscribedexitLens = this.exitLens.bind(this);
    this.suscribeImageZoom = this.imageZoom.bind(this);
    //generamos un id unico
    var idPrincipal = this.uuidv4();
    this.idZoomContainer = "zoomContainer_" + idPrincipal;
    this.idImagen = "imagen_" + idPrincipal;
    this.idImagenResult = "imagenResult_" + idPrincipal;
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  ngOnInit() {
    this.urlFotoMostrar = this.foto;
    this.interval = setInterval(this.suscribeImageZoom, 100);
  }
  

  downloadImage(){
    this.loadingService.setLoading(true);

   // window.open(this.urlFotoMostrar);

    this.http.get(this.urlFotoMostrar, { responseType: 'blob', observe: 'events' })
    .subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.loadingService.setLoading(false);
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, this.idImagen);
      }
    }, error => {
      this.loadingService.setLoading(false);
    });
  }

  imageLoad(){
    this.mostrarDescarga = true;
  }

  public onErrorImageLoad(){
    this.mostrarBotonDescargar = false;
    this.urlFotoMostrar='assets/img/foto-no-disponible.png';
  }

  private imageZoom() {
    this.div = document.getElementById(this.idZoomContainer);
    this.img = document.getElementById(this.idImagen);
    this.result = document.getElementById(this.idImagenResult);
    
    if (!this.img || !this.result) {
      return;
    }
    
    if(this.img.width == 0){
      return;
    }
    
    clearInterval(this.interval);
    /*Creamos el contenedor de la lente: */
    this.lens = document.createElement("DIV");
    this.lens.setAttribute("class", "img-zoom-lens");
    this.lens.setAttribute("style", "position: absolute; border: 0px solid #d4d4d4; width: 100px !important; height: 100px !important; opacity: 20%");
    /* Lo colocamos en el contenedor padre: */
    this.img.parentElement.insertBefore(this.lens, this.img);
    this.result.style.width = this.img.width;
    this.result.style.height = this.img.height;
    /* Calculo el radio abarcado entre el contedor de resultados y la lente: */
    this.cx = 302 / this.lens.offsetWidth;
    this.cy = 302 / this.lens.offsetHeight;
    /* Asignamos la imagen al contenedor del resultado */
    this.result.style.backgroundImage = "url('" + this.urlFotoMostrar + "')";
    
    /* agregamos los eventos a los contenedores */
    this.lens.addEventListener("mousemove", this.suscribedMoveLens);
    this.lens.addEventListener("mouseleave", this.suscribedexitLens);
    this.img.addEventListener("mousemove", this.suscribedMoveLens);
    this.img.addEventListener("mouseleave", this.suscribedexitLens);
    /* y lo mismo en caso dee que se use en touch: */
    this.lens.addEventListener("touchmove", this.suscribedMoveLens);
    this.lens.addEventListener("touchend", this.suscribedexitLens);
    this.img.addEventListener("touchmove", this.suscribedMoveLens);
    this.img.addEventListener("touchend", this.suscribedexitLens);
}


  private exitLens(){
    this.mostrarZoom = false;
    this.lens.style.backgroundColor = "transparent";
  }

  private moveLens(e) {
    if(!this.mostrarZoom){
      this.mostrarZoom = true;
      this.lens.style.backgroundColor = "blue";
    }
    var pos, x, y;
    this.result.style.backgroundSize = (this.img.width * this.cx) + "px " + (this.img.height * this.cy) + "px";
    var getCursorPos = (e)=>{
      var a, x = 0, y = 0;
      e = e || window.event;
      /* Obtenemos la posicion de la imagen */
      a = this.img.getBoundingClientRect();
      /* calculamos las coordenadas en base a la pagina */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* se consudera el scroll de la pagina en caso de haberlo */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
    /* Prevenimos otras acciones durante este proceso */
    e.preventDefault();
    /* Obtenemos la posicion actual del cursor */
    pos = getCursorPos(e);
    /* Calculamos la posicion del lente */
    x = pos.x - (this.lens.offsetWidth / 2);
    y = pos.y - (this.lens.offsetHeight / 2);
    /* Limitamos para que la lente no se coloque fuera de la imagen */
    if (x > this.img.width - this.lens.offsetWidth) {x = this.img.width - this.lens.offsetWidth;}
    if (x < 0) {x = 0;}
    if (y > this.img.height - this.lens.offsetHeight) {y = this.img.height - this.lens.offsetHeight;}
    if (y < 0) {y = 0;}
    /* asignamos la posicion a la lente */
    this.lens.style.left = x + "px";
    this.lens.style.top = y + "px";
    /* Mostramos el zoom en la posicion del lente */
    this.result.style.backgroundPosition = "-" + (x * this.cx) + "px -" + (y * this.cy) + "px";
    var posImagen = this.img.getBoundingClientRect();
    var leftResult = this.posicion == 0 ? (posImagen.left - 305) : (posImagen.left + this.img.width + 5);
    if(e.clientY - 300 < 0){
      this.result.style.top = (e.clientY) + "px";
    }
    else if(e.clientY + 300 > window.innerHeight){
      this.result.style.top = (e.clientY - 300) + "px";
    }
    else{
      this.result.style.top = (e.clientY - 150) + "px";
    }
    if(this.esDialogo){
      this.result.style.marginLeft = "-310px";
    }
    else{
      this.result.style.left = leftResult + "px";
    }
  }
}
