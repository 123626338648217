import { Injectable } from '@angular/core';

import { PersonalReference } from "./personal-reference.model";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';

@Injectable()
export class PersonalReferenceService {

  constructor(
    private leanServ: ServiciosKobraService
  ) { }

  public all( agentID: number): Observable<PersonalReference[]> {
    return this.leanServ.get(`/cobradores/${agentID}/referencia-personal?deleted=0`).pipe(
      map((res) => {
        let referencesData = res.data;
        let references: PersonalReference[] = [];
        for (let i = 0; i < referencesData.length; i++) {
          let reference: PersonalReference = new PersonalReference();
          reference.id = +referencesData[i].id;
          reference.name = referencesData[i].name;
          reference.surname = referencesData[i].surname;
          reference.fullName = referencesData[i].fullName;
          reference.phoneNumber = referencesData[i].phoneNumber;
          reference.phoneTypeID = referencesData[i].phoneTypeID;
          reference.phoneType.id = referencesData[i].phoneTypeID;
          reference.phoneType.name = referencesData[i].phoneTypeName;
          reference.relationshipID = referencesData[i].realationshipID;
          reference.relationship.id = referencesData[i].realationshipID;
          reference.relationship.name = referencesData[i].realationshipName;
          references.push(reference);
        }
        return references;
      })
    )
  }

  public add( personalReference: PersonalReference ): Observable<PersonalReference> {
    return this.leanServ.post(`/cobradores/${personalReference.idCobrador}/referencia-personal`, personalReference).pipe(
      map(res => <PersonalReference> res.data)
    );
  }

  public delete( id: number, personalReference: PersonalReference ): Observable<any> {
    personalReference.deleted = 1;

    return this.leanServ.patch(`/cobradores/referencia-personal/${personalReference.id}`, personalReference).pipe(
      map(res => res.data)
    )
  }

  public update( personalReference: PersonalReference ): Observable<PersonalReference> {
    personalReference.deleted = 0;

    return this.leanServ.patch(`/cobradores/referencia-personal/${personalReference.id}`, personalReference).pipe(
      map(res => <PersonalReference> res.data)
    )
  }
}