
import { Column } from '../interfaces/tabla.interface';

export class TablaParametros {
    columns: Array<Column> = [];
    filters?: Array<any> = [];
    loading: boolean = false;
    headers: boolean = true;
    tableHeaders?: boolean = true;
    multiSelect?: boolean = true;
    ignoreSortChange?: boolean = false;
    expandible?: boolean = false;
    classContainer?: string = 'table-container';
    total?: number;
    anchoMaximoDetalle?: number;
    typeExpandTable?: string = 'app-table';
    expandParams?: TablaParametros = new TablaParametros();
    expandProperty?: string = '';

    onSelectedRows?: Function;
    onLinkRow?: Function;
    sortChange?: Function;
    filtersChange?: Function;

    searchChange?: Function;
    onDownload?: Function;
    onDoubleClick?: Function;
    onCtrlClicLinkRow?: Function;
    onShowDetail?: Function;
}

export class ItemTabla{
    isExpanded?: boolean = false;
    loading?: boolean = false;
}