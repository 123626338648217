<app-modal  title="{{title}}" (onClose)="onClose()" [onlyClose]="true">
  <div *ngIf="data.filter == 'errors' && erroresGenerales.length > 0">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Errores generales encontrados
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let error of erroresGenerales">{{error}}</mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  
  <div class="accounts-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Detalle cuentas
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="dataSourceTotal > 0">
          <div class="table-header">
            <button mat-stroked-button 
              id="cambiarEstatus"
              type="button"
              color="primary"
              [disabled]="selectedAccounts.length==0"
              (click)="openDialogEstatus()">Estatus
            </button>
          </div>
    
          <table mat-table [dataSource]="dataSource" class="table table-wrapper">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [disabled]="idTipoImportacion == 4" [(ngModel)]="allAccountsSelected"
                  (change)="selectAllAccounts()"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (change)="selectAccount(element.id, element.idCliente, element.idAcreditadoPG, element.idCuentaPG, element.idDomicilioAcreditado, element.idCuenta)" [(ngModel)]="element.selected" [disabled]="element.disabled"></mat-checkbox>
              </td>
            </ng-container>
    
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> ID Cuenta</th>
              <td mat-cell *matCellDef="let element"> 
                <a class="sombreado-link" (click)="onLinkDetalleCuenta(element)" >{{element.id}}</a>  
              </td>
            </ng-container>
    
            <ng-container matColumnDef="subId">
              <th mat-header-cell *matHeaderCellDef> ID SubCuenta</th>
              <td mat-cell *matCellDef="let element"> {{element.subId}} </td>
            </ng-container>
    
            <ng-container matColumnDef="folioDomicilio">
              <th mat-header-cell *matHeaderCellDef> Folio Domicilio</th>
              <td mat-cell *matCellDef="let element"> {{element.folioDomicilio}} </td>
            </ng-container>
    
            <ng-container matColumnDef="domicilio">
              <th mat-header-cell *matHeaderCellDef> Domicilio</th>
              <td mat-cell *matCellDef="let element"> {{element.domicilio}} </td>
            </ng-container>
    
            <ng-container matColumnDef="error">
              <th mat-header-cell *matHeaderCellDef> Error </th>
              <td mat-cell *matCellDef="let element"> {{element.error ? 'Sí' : 'No'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="resultado">
              <th mat-header-cell *matHeaderCellDef> Resultado </th>
              <td mat-cell *matCellDef="let element"> {{element.resultado}} </td>
            </ng-container>
    
            <ng-container matColumnDef="estatus">
              <th mat-header-cell *matHeaderCellDef> Estatus </th>
              <td mat-cell *matCellDef="let element"> {{element.idTipoImportacion == 4 ? 'N/A' : element.estatus}} </td>
            </ng-container>
    
            <ng-container matColumnDef="probabilidadVisita">
              <th mat-header-cell *matHeaderCellDef> Probabilidad de visita </th>
              <td mat-cell *matCellDef="let element"> {{element.idTipoImportacion == 4 ? 'N/A' : element.probabilidadVisita}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div *ngIf="dataSourceTotal == 0">
          <mat-list>
            <mat-list-item><i>No existe información para mostrar.</i></mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- <mat-paginator
    *ngIf="page"
    [pageSizeOptions]="[10, 20]"
    [length]="page?.totalRows"
    [pageIndex]="page?.page - 1"
    [pageSize]="page?.rows"
    (page)="onChangePage($event)"
    showFirstLastButtons
  ></mat-paginator> -->

  <button mat-stroked-button (click)="download()" m-actions *ngIf="dataSourceTotal > 0">
    <mat-icon class="kobra-icon">cloud_download</mat-icon>Descargar</button>
</app-modal>
