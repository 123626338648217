<h1 mat-dialog-title>Calificar Visitas</h1>
<mat-dialog-content>
    <div class="centrado">
        <span class="calificando">Calificando {{indiceActual + 1}} de {{todasLasVisitas.length}}</span>
    </div>
    <div #visitDetailContainer></div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 20px;">
    <button mat-stroked-button class="boton-cancelar" (click)="salirCalificar()">
        <mat-icon class="kobra-icon">close</mat-icon>Cancelar
    </button>
    <button mat-stroked-button class="boton-accion-front" (click)="anterior()" *ngIf="mostrarAnterior()">
        <mat-icon>arrow_back</mat-icon>Anterior
    </button>
    <button mat-stroked-button class="boton-accion-front" (click)="siguiente()" *ngIf="mostrarSiguiente()" color="primary">
        <mat-icon>arrow_forward</mat-icon>Siguiente
    </button>
</mat-dialog-actions>


