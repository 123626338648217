<router-outlet (activate)="hijosActivos = true ;"
  (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <div class="button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="filtros" (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar" mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
          <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>

        </mat-button-toggle>
        <mat-button-toggle value="descargas" (click)="obtieneMovExcel();" matTooltip="Descargar listado"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">cloud_download</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="actualizar" (click)="obtieneMovimientos(filtros.page || 1);" matTooltip="Refrescar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="subMenu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menu">
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above (click)="ayuda = !ayuda">
          <mat-icon color="primary">help</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/gestores-movimientos/form']" queryParamsHandling="preserve">
      <mat-icon class="kobra-icon">add_thick</mat-icon>
      <span>Nuevo</span>
    </button>
     <button mat-menu-item (click)="onAsignarPeriodo()" queryParamsHandling="preserve"  >
      <mat-icon class="kobra-icon">edit_calendar</mat-icon>
      <span>Asignar Periodo</span>
    </button> 
    <button mat-menu-item (click)="onImportarXsl()" queryParamsHandling="preserve">
      <mat-icon class="kobra-icon">cloud_upload</mat-icon>
      <span>Subir Excel</span>
    </button>
 <button mat-menu-item (click)="onAjuste6()" queryParamsHandling="preserve" *ngIf="this.mostrarBotonAjuste6">
      <mat-icon class="kobra-icon">filter_6</mat-icon>
      <span>{{ajuste6Txt}}</span>
    </button> 
  </mat-menu>

  <app-table [classContainer]="'tabla-completa'" [loading]="cargandoLista" [columns]="tablaMovimiento.columns"
    [items]="movs" [headers]="tablaMovimiento.headers" [multiSelect]="tablaMovimiento.multiSelect"
    (sortChange)="setSortField($event)">

    <div t-header>

      <mat-card *ngIf="ayuda" class="cardAyuda">
        
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Movimientos Agentes" es una herramienta crucial para el seguimiento detallado de todas las transacciones y movimientos de saldo asociados a cada agente. Es esencial para proporcionar una visión completa y actualizada de las actividades financieras de los agentes, lo que incluye diversas operaciones como ajustes en sus apgos, bonos, entre otros.
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo aplicar filtros basados en diversos criterios.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b>  Ofrece la opción de descargar los datos de las visitas para su análisis o uso fuera de línea.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Permite añadir ajustes o nuevos movimientos a cada agente.
          </p>
         
        </mat-card-content>
      </mat-card>
    </div>

    <ng-container t-paginator>
      <mat-paginator showFirstLastButtons [length]="pagination?.totalRows"
        [pageIndex]="pagination?.page-1" [pageSize]="pageSize" (page)="onPage($event)"
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </ng-container>
  </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generales') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Tipos Movimientos</mat-label>
              <mat-select [(ngModel)]="filtros.movStat" (ngModelChange)="validarTipoMov()" (ngModelChange)="contabilizadorDeFiltros('generales');" placeholder="Seleccione...">
                <mat-option *ngFor="let mov of movsServ.movStatus" [value]="mov.id" >
                  {{ mov.nombre }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
              *ngIf="filtros.movStat != null" 
              (click)="filtros.movStat = null;$event.stopPropagation();contabilizadorDeFiltros('generales');">clear</mat-icon> 
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>
          </li>
          <li class="filtro-side">
            <mat-select-search 
            class="id-card-field" 
            [label]="'Concepto'" 
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'" 
            [labelField]="'nombre'" 
            [icon]="'account_circle'"
            [realizarBusqueda]="buscarConceptos" 
            [(ngModel)]="filtros.concepto"
            (ngModelChange)="contabilizadorDeFiltros('generales');">
            </mat-select-search> 
          </li>
          <li class="filtro-side">
            <mat-select-search 
            class="id-card-field" 
            [label]="'Periodo de facturación'" 
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'" 
            [labelField]="'nombre'" 
            [icon]="'search'"
            [realizarBusqueda]="periodosFilterSearch" 
            [(ngModel)]="filtros.cobradorFacturacionPeriodo"
            (ngModelChange)="contabilizadorDeFiltros('generales');">
            </mat-select-search>
          </li>
         
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>

      <mat-expansion-panel-header >
        Fechas
        <span *ngIf="contabilizadorDeFiltros('fechas') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechas') }}</span>

      </mat-expansion-panel-header>
      <ol class="lista-filtros-side" >
        <li class="filtro-side">
          <mat-date-picker-formater [label]="'Fecha inicio'" [placeHolder]="'Capture la fecha inicio'"
            [max]="movsServ.filtros.fechaFin ? movsServ.filtros.fechaFin : today" [(ngModel)]="filtros.fechaInicio"
            (ngModelChange)="seValidoFechaInicio = false; setFechaFin($event);contabilizadorDeFiltros('fechas')" />
        </li>
        <li class="filtro-side">
          <mat-date-picker-formater [label]="'Fecha fin'" [placeHolder]="'Capture la fecha fin'"
            [min]="filtros.fechaInicio" [max]="today" [(ngModel)]="filtros.fechaFin"
            (ngModelChange)="seValidoFechaFin = false; contabilizadorDeFiltros('fechas')" />
       
        </li>
      </ol>
    </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
          <span *ngIf="contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('agente') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search 
            class="id-card-field" 
            [label]="'Agente'" 
            [placeHolder]="'Buscar agente'"
            [value]="'option'" 
            [labelField]="'nombre'" 
            [icon]="'account_circle'"
            [realizarBusqueda]="agenteFilterSearch" 
            [(ngModel)]="filtros.cobrador"
            (ngModelChange)="contabilizadorDeFiltros('agente');"
            [minBusqueda]="1">
            </mat-select-search> 
          </li>
         
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ol class="lista-filtros-buttons">
    <button style="width: 100%;" mat-stroked-button class="boton-accion-front"
      (click)=" filterModal(true);">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button
      (click)="filterModal(false)">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>