import { ApplicationService } from './application.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

/**
 * Clase generada para validar los permisos
 * @Auth Uriel Yair Gámez Rosales
 */
export class PermisosService {
    constructor(
        private router: Router,
        private activateRouter: ActivatedRoute,
        private app: ApplicationService
      ) { }

    public guardarPermisos(menu: any[]){
        let menuNvo = [];
        this.meterOpciones(menu, menuNvo);
        localStorage.setItem("menu", JSON.stringify(menuNvo));
    }

    public getPermiso(urlPermiso:string): boolean{
        let permiso:boolean = false;
        let menu: any = localStorage.getItem('menu');
        menu = menu ? JSON.parse(menu) : [];

        permiso = menu.some((opcion) => {
            return opcion.txtUrl == urlPermiso
        });

        return permiso;
    }

    private meterOpciones(menuActual: any[], menuNvo: any[]){
        menuActual.forEach(opcion => {
            menuNvo.push(opcion);
            if(opcion.menuHijos){
                this.meterOpciones(opcion.menuHijos, menuNvo);
            }
        });
    }
}