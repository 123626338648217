<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; obtenerVariables();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <div class="contenedor-toggle">
    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
      <mat-button-toggle value="actualizar"
        matTooltip="Refrescar"
        mat-tooltip-panel-above
        (click)="obtenerVariables()">
        <mat-icon class="kobra-icon">sync</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="subMenu"  
        matTooltip="Acciones"
        mat-tooltip-panel-above
        [matMenuTriggerFor]="menu">
        <mat-icon class="kobra-icon">more_vert</mat-icon>
      </mat-button-toggle>
      <!-- <mat-button-toggle value="ayuda" 
        matTooltip="Ayuda"
        mat-tooltip-panel-above
        (click)="ayuda = !ayuda;">
        <mat-icon color="primary">help</mat-icon>
      </mat-button-toggle> -->
    </mat-button-toggle-group>
  </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="abrirBloquesNuevo()">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Agregar</span>
      </button>
      <button [disabled]="itemsSelected.length == 0" mat-menu-item (click)="abrirBloquesEditar(itemsSelected[0])"> 
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button [disabled]="itemsSelected.length == 0" mat-menu-item (click)="openDialogConfirmacion(itemsSelected[0])" mat-menu-item>
        <mat-icon color="warn">delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoVariables"
      [columns]="tablaArchivos.columns"
      [items]="variables"
      [headers]="tablaArchivos.headers"
      [multiSelect]="tablaArchivos.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"
    >

      <div t-header>     
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-header>
            <mat-card-title>Ayuda</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div style="margin-left: 10px; margin-right: 10px; margin-top: 10px;">
              <p>En esta opción podrás subir tus plantillas con extensión ".docx" para que se generen en automático cuando se realicen visitas con este cuestionario.</p>
              <p>¿Cómo le hacemos? A continuación se enlistan los pasos para que puedas configurar tus plantillas:</p>
              <ol>
                <li>
                  <p>Crea tu plantilla en Word y donde quieras que se llene la información dinámica la vas a reemplazar con las variables que te enlistamos en el catálogo de variables.</p>
                </li>
                <li>
                  <p>Sube tus plantillas ya listos con las variables correspondientes y listo, en cuanto guardes todas las visitas que se hagan generarán estos documentos.</p>
                </li>
              </ol>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </app-table>
</div>
