<mat-card>
	<mat-card-content>
		<h3 class="import-title" style="font-size: 20px;">Carga tu archivo</h3>
	
	<h5 class="import-title active" style="margin: 0;">Antes de cargar tus archivos, asegúrate de que tu archivo esté <a href="{{urlHelp}}/conocimiento/como-tener-listo-mi-archivo" target="_blank" class="active">listo para importarse.</a></h5>
	
	<div class="upload-container">
		<!-- <div class="upload-selected-container"> 
			<h3 *ngIf="product" class="upload-selected-title">{{ product.name }}</h3>
			<h5 *ngIf="type" class="upload-selected-subtitle">{{ type.name }}</h5>
		  </div> -->

	  <mat-card class="card">
		<mat-card-header>
			<mat-card-title>{{ product.name }}</mat-card-title>
			<mat-card-subtitle>{{ type.name }}</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<app-upload-file [allowMultiple]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="onUpload($event)"></app-upload-file>
		</mat-card-content>
	  </mat-card>
	</div>
	</mat-card-content>
	
</mat-card>
  