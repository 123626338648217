import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../application/shared/application.service';
import { Product } from '../models/product.model';
import { Type } from '../models/type.model';
import { environmentSelector} from '../../../environments/environment.selector';
import { FilesizePipe } from './filesize.pipe';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  private environment = environmentSelector();

  @Input() product: Product;
  @Input() type: Type;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  //acceptFiles: Array<string> = ['.xls', '.xlsx', '.csv'];
  acceptFiles: string = '.xls,.xlsx';
  file: File;
  urlHelp = this.environment.kobraHelp;
  filesizePipe = new FilesizePipe();  // Create a new instance of FilesizePipe

  constructor(
    private appService: ApplicationService
  ) {}

  ngOnInit() {}

  onUpload(files: File[]) {
    if (files.length > 1) {
      return;
    }

    this.file = files[0];

    this.onChange.emit(this.file);
  }

  onRemoveFile() {
    this.file = null;
    this.onChange.emit(null);
  }

  onErrorFile(error) {
    this.appService.showError(error);
  }

  getFileSize(size: number): string {
    return this.filesizePipe.transform(size);  // Use transform method directly
  }

}
