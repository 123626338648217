import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Injectable({
  providedIn: 'root'
})
export class PaquetesFinancieraDetalleService {

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService
  ) { }

  getConceptos( idFinanciera: number, idFinancieraPaquete: number ): Observable<any> {
    return this.saldosKobraServ.get(`/financieras/${idFinanciera}/paquetes/${idFinancieraPaquete}/conceptos`).pipe(map((res: any) => res.data));
  }

  editarConceptos( idFinanciera: number, idFinancieraPaquete: number, coneptos: any ): Observable<void> {
    return this.saldosKobraServ.put(`/financieras/${idFinanciera}/paquetes/${idFinancieraPaquete}/conceptos`, coneptos).pipe(map((res: any) => res.data));
  }

  getConceptosPorFinanciera(idFinanciera: number): Observable<any> {
    return this.saldosKobraServ.get(`/financieras/${idFinanciera}/conceptos-productos`).pipe(map((res: any) => res.data));
  }
}
