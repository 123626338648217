import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit  } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IProductosAdministrador} from './productos.interface';
import { ProductosService } from '../../permisos-producto/productos.service';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-productos-modificar',
  templateUrl: './productos-modificar.component.html',
  styleUrls: ['./productos-modificar.component.css'],
  providers: [ProductosService]
})
export class AdmistradorProductosModificarComponent implements OnInit  {
  //@ViewChild('divPrincipal') divPrincipalRef: ElementRef;
 //@ViewChild('matCardContent') matCardContentRef: ElementRef;
  espacioRestante: number;
  producto: IProductosAdministrador;
  productosGrid: IProductosAdministrador[] = [];
  productosGridAux: IProductosAdministrador[] = [];
  productos: IProductosAdministrador[] = [];
  fechaActual = new Date();
  loadings: any = {
    productos: true,
    administradorProductos: false
  };

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [{
        name: 'ID Producto',
        key: 'idProducto',
        hide: false
      }, {
        name: 'Producto',
        key: 'nomProducto',
        hide: false
      }, {
        name: 'Descripción',
        key: 'descripcion',
        hide: false
      }, {
        name: 'Fecha Registro',
        key: 'fecRegistro',
        type: 'date',
        hide: false
      }, {
        name: ' ',
        key: 'eliminar',
        hide: false,
        icono:'delete_forever',
        tooltip: "Eliminar",
        type: 'icono',
    }]
  }
  nombreCliente:string;
  nombreAdmin:string;
  idClienteAdministrador:number;
  idCliente:number;
  constructor(
    private appService: ApplicationService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private productosServ: ProductosService
  ) {
  
  }

  ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idClienteAdministrador = +this.route.snapshot.queryParamMap.get('idClienteAdministrador');
    this.nombreAdmin  = this.route.snapshot.queryParamMap.get('nombreAdmin');
    //this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
    if (!this.idCliente || !this.idClienteAdministrador || !this.nombreAdmin) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos/administradores`]);
    }else{
      this.loadProductos();
      this.loadProductosAdministrador();
    }

   
  }

  /*ngAfterViewInit() {
    this.actualizarAlturaMaxima();
    window.addEventListener('resize', () => this.actualizarAlturaMaxima());
  }
  
  actualizarAlturaMaxima() {
    const divPrincipal = this.divPrincipalRef.nativeElement as HTMLElement;
    const matCardContent = this.matCardContentRef.nativeElement as HTMLElement;
    const alturaVentana = window.innerHeight;
    const offsetTop = divPrincipal.offsetTop;
    const nuevaAlturaMaxima = alturaVentana - offsetTop;
    matCardContent.style.maxHeight = nuevaAlturaMaxima + 'px';
  }*/
  
  

  compararObjetos(objeto1: any, objeto2: any): boolean {
    //Devuelve false, cuando los objetos san diferentes
    // Convertir ambos objetos a cadenas JSON
    const jsonStr1 = JSON.stringify(objeto1);
    const jsonStr2 = JSON.stringify(objeto2);

    // Comparar las cadenas JSON
    return jsonStr1 === jsonStr2;
  }


  cancel() {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];

    // Eliminamos todos los parámetros existentes y luego agregamos el parámetro 'f'
    this.router.navigate(['/catalogos/administradores'], {
      queryParams: { f: fParam }
    });
  }

  save() {
    const loading = this.appService.showLoading('Actualizando información...');

    this.productosServ.editarProductosPorAdministrador(this.idCliente, this.idClienteAdministrador, this.productosGrid).subscribe({
        next: () => {
            this.appService.hideLoading(loading);
            this.appService.showSnackbar('Aviso', 'Se ha actualizado el administrador correctamente', 3000, 'success');
            this.cancel();
        },
        error: (error) => {
            this.appService.hideLoading(loading);
            this.appService.showError(error);
        }
    });
}


  agregarProducto() {
    if(this.producto){
      this.producto.fecRegistro = this.fechaActual;
      const isProducto = this.productosGrid.some(element => element.idProducto === this.producto.idProducto);

      if (isProducto) {
        this.appService.showSnackbar('¡Aviso!', `El producto "${this.producto.nomProducto}" ya pertenece a este administrador.`, 3000, 'warning');
      } else {
        this.productosGrid = this.productosGrid.concat(this.producto);
        this.producto = null;
      }
    }
    else{
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar un producto.', 3000, 'warning');
    }
  }

  loadProductos(): void {
    this.loadings.productos = true;
    this.productosServ.obtenerProductosPorCliente(this.idCliente).pipe(
      take(1)
    ).subscribe({
      next: (res: IProductosAdministrador[]) => {
        this.productos = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.loadings.productos = false;
      },
      complete: () => {
        this.loadings.productos = false;
      }
    });
  }

  loadProductosAdministrador() {
    this.loadings.administradorProductos = true;
    this.productosServ.obtieneProductoPorAdministrador(this.idCliente, this.idClienteAdministrador).pipe(
      take(1)
    ).subscribe({
      next: (res: IProductosAdministrador[]) => {
        this.productosGrid = res;
        this.productosGridAux = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.loadings.administradorProductos = false;
      },
      complete: () => {
        this.loadings.administradorProductos = false;
      }
    });
  }

  onClick({ row, columnPressed }) {
    if (columnPressed == 'eliminar') {
        this.productosGrid = this.productosGrid.filter(producto => producto.idProducto !== row.idProducto);
    }
  
  }
  
}
