import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LenderService } from "../../lender.service";
import { ApplicationService } from '../../../application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';

// Importa moment directamente sin alias
import * as moment from 'moment';

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'estado-cuenta.component',
  templateUrl: 'estado-cuenta.component.html',
  styleUrls: ["estado-cuenta.component.css"],
  providers: [
    LenderService,
    ServiciosSaldosKobraService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ModalEstadoCuentaComponent {
  lenders: any;
  movimientosForm: FormGroup;
  //date = new FormControl(moment()); // Utiliza moment aquí
  momentVar: any;

  constructor(
    public dialogRef: MatDialogRef<ModalEstadoCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lenderService: LenderService,
    private app: ApplicationService,
    private formBuilder: FormBuilder,
    private saldosServ: ServiciosSaldosKobraService,
  ) {
    this.momentVar = moment;
    this.crearForm();
  }

  ngOnInit() {
    //console.log(this.data);
    this.loadLenders();
  }

  crearForm() {
    this.movimientosForm = this.formBuilder.group({
      fecha: [this.momentVar().format(), Validators.required],
      idFinanciera: ["", Validators.required],
      colFecha: [true, false],
      colPaquete: [true, false],
      colConcepto: [true, false],
      colImporte: [true, false],
      colFolioVisita: [false, false],
      colIdExterno: [false, false],
      colMunicipio: [false, false],
      colEstado: [false, false],
      colCP: [false, false],
    });

    this.movimientosForm.get('colFecha')?.disable();
    this.movimientosForm.get('colPaquete')?.disable();
    this.movimientosForm.get('colConcepto')?.disable();
    this.movimientosForm.get('colImporte')?.disable();
  }

  public loadLenders(): void {
    this.lenderService.getAll('all').subscribe(
      (lenders) => {
        this.lenders = lenders;
        if (this.data.length == 1) {
          this.movimientosForm.get('idFinanciera').patchValue(this.data[0].idFinanciera);
        }
      },
      (err) => {
        this.app.showError(err);
      }
    );
  }

    descargar(){

        let idFinanciera = this.movimientosForm.get('idFinanciera').value;
        let fecha = this.momentVar(this.movimientosForm.get('fecha').value).format('YYYY-MM') + '-01';
        let columnas = 'fecha, paquete, concepto';
        if(this.movimientosForm.get('colFolioVisita').value){
            columnas+=', folioVisita';
        }
        if(this.movimientosForm.get('colIdExterno').value){
            columnas+=', idExterno';
        }
        if(this.movimientosForm.get('colMunicipio').value){
            columnas+=', municipio';
        }
        if(this.movimientosForm.get('colEstado').value){
            columnas+=', estado';
        }
        if(this.movimientosForm.get('colCP').value){
            columnas+=', cp';
        }
        columnas+=', importe';

        if((idFinanciera.length <= 0 || idFinanciera == 'cli') ||
        (fecha === undefined || fecha == '')){
        //(fechaInicio === undefined || fechaInicio == '') ||
        //(fechaFin === undefined || fechaFin == '')){
            this.app.showSnackbar('Aviso', 'Asegurese de llenar correctamente los campos', 3000, 'warning');
        }
        else{
            let fechaAuxiliar = this.momentVar(fecha).toDate();
            
            let fechaInicio = fechaAuxiliar.toISOString().substr(0,10);
            fechaAuxiliar.setMonth(fechaAuxiliar.getMonth() + 1);
            let fechaFin = new Date(fechaAuxiliar.setDate(fechaAuxiliar.getDate() - 1)).toISOString().substr(0,10);

            let queryParams = `?idFinanciera=${idFinanciera}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

            this.saldosServ.get(`/verificar-saldo-pdf${queryParams}`)
            .pipe(
            take(1)
            ).subscribe((res: any) => {

            if(res.data){
                queryParams += `&columnas=${columnas}`;
                this.saldosServ.get(`/estado-cuenta-pdf${queryParams}`)
                .pipe(
                take(1)
                ).subscribe((res: any) => {
                    this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 1000, 'success');
                }, (error: any) => {
                    this.app.showError(error);
                });
                this.movimientosForm.reset();
                this.dialogRef.close();
            }
            else{
                this.app.showSnackbar('Aviso', 'No hay información de saldos para el cliente y rango de fechas proporcioniados', 3000, 'warning');
            }

            }, (error: any) => {
                this.app.showError(error);
            });
        }
    }

    
    setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.momentVar(this.movimientosForm.value.fecha);
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.movimientosForm.get('fecha')?.setValue(ctrlValue.toISOString()); // Guardar la fecha en formato ISO
        datepicker.close();
    }
      

    onNoClick(): void {
        this.dialogRef.close(false);
    }

}
