import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { map } from 'rxjs/operators';
import { Page } from 'src/app/shared/interfaces/page';
import { Product } from 'src/app/import/models/product.model';
import { Type } from 'src/app/import/models/type.model';
import { Batch } from 'src/app/import/models/batch.model';
import { ClientService } from 'src/app/core/http/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  subject = new Subject<any>();

  constructor(
    private http: ClientService,
  ) { }

  reloadBaseUrl(archivo: string = ""){
    this.http.reloadBaseUrl(archivo);
  }

  public getHistory(idImportacion: number, params: URLSearchParams, idCliente: number): Observable<any> {
    let ruta = `/${idCliente}/importaciones`;

    if( idImportacion != null ) {
      ruta += `/${idImportacion}`;
    }

    if(params.toString()){
      ruta += `?${params.toString()}`;
    }

  	return this.http.get(ruta).pipe(map((res) => <any> res.data));
  }

  public getPreCollect(params: URLSearchParams, idTipoImportacion: any): Observable<any> {
    let ruta = `/importaciones/${idTipoImportacion}/collect`;

    ruta += `?${params.toString()}`;

  	return this.http.get(ruta).pipe(map((res) => <any> res.data));
  }

  public getAccounts(idCliente: number, idImportacion: number, params: URLSearchParams): Observable<any> {
    return this.http.get(`/${idCliente}/importaciones/${idImportacion}/accounts?${params.toString()}`).pipe(map((res) => <any> res.data));
  }

  public getAccountsWithErrors(idImportacion: number, params: URLSearchParams): Observable<any> {
    return this.http.get(`/importaciones/${idImportacion}/accounts-errors?${params.toString()}`).pipe(map((res) => <any> res.data));
  }

  public getErrors(idCliente: number,idImportacion: number, params: URLSearchParams): Observable<any> {
    return this.http.get(`/${idCliente}/importaciones/${idImportacion}/errores?${params.toString()}`).pipe(map((res) => <any> res.data));
  }

  public getProducts(idCliente: number): Observable<Product[]> {
    return this.http.get(`/${idCliente}/productos`).pipe(map((res) => <Product[]> res.data.map(d => ({
      id: d.idProducto,
      name: d.nomProducto,
      description: d.descripcion,
      idProductType: d.idTipoProducto
    }))));
  }

  public getTypes(idProduct: number): Observable<Type[]> {
    return this.http.get(`/productos/${idProduct}/tipos-importaciones`).pipe(map((res) => <Type[]> res.data.map(d => ({
      id: d.idTipoImportacion,
      name: d.nomTipoImportacion,
      description: d.descripcion
    }))));
  }

  public downloadLayout(idCliente: number, idProduct: number, idType: number, params: string) {
    return this.http.download(`/${idCliente}/productos/${idProduct}/tipos-importaciones/${idType}/layout${params}`);
  }

  public exportLayout(idCliente: number, idImportacion: number, params: URLSearchParams) {
    return this.http.download(`/${idCliente}/importaciones/${idImportacion}/exportar?${params.toString()}`);
  }

  public uploadLayout(file: File, batch: Batch, product: Product, type: Type, idCliente: number, idFinanciera: number) {
    const formData = new FormData();

    formData.append('archivoExcel', file);
    formData.append('idImportacion', String(batch ? batch.id : ''));
    formData.append('idProducto', String(product ? product.id : ''));
    formData.append('idTipoLayout', String(type ? type.id : ''));
    formData.append('idFinanciera', String(idFinanciera));

    return this.http.upload(`/${idCliente}/importacion`, formData).pipe(map(res => <Batch> ({
      id: res.data.idImportacionKobra
    })));
  }


  public startProcess(batch: Batch, data: any) {
    return this.http.post(`/importaciones/${batch.id}`, data).pipe(map((res) => <any> res.data));
  }

  public getBatchState(idCliente: number, idFinanciera: number, batch: Batch) {
    return this.http.get(`/${idCliente}/importaciones/${batch.id}/estatus?idFinanciera=${idFinanciera}`).pipe(map((res) => {
      let data = res.data || {};

      return <Batch> {
        ...batch,
        idStatus: data.idEstatus || 0,
        email: data.email,
        emails: data.emails,
        resultado: data.resultado,
        total: data.numCuentasArchivo || 0,
        totalErrors: data.numCuentasError || 0,
        totalGeoExact: data.totalGeolocExactas || 0,
        totalGeoInexact: data.totalGeolocNoExactas || 0,
        totalNoGeoloc: data.totalNoGeoloc || 0,
        totalVeryHigh: data.totalProbabilidadMuyAlta || 0,
        totalHigh: data.totalProbabilidadAlta || 0,
        totalLow: data.totalProbabilidadPoca || 0,
        totalVeryLow: data.totalProbabilidadMuyPoca || 0,
        totalWithoutCoverage: data.totalProbabilidadNula || 0
      };
    }));
  }

  public changeEstatusAccount(idCliente: number, idFinanciera: number, estatus: string, idEtiquetaOperacion: number, accounts: any): Observable<Page> {
  	return this.http.post(`/${idCliente}/importaciones/cambiar-estatus-cuentas`, {
      idFinanciera: idFinanciera,
      estatus: estatus,
      idEtiquetaOperacion: idEtiquetaOperacion,
      cuentas: accounts
    }).pipe(map((res) => <Page> res.data));
  }
}
