import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/operators';
import { LenderService } from "../lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-encargados-financiera',
  templateUrl: './encargados-financiera.component.html',
  styleUrls: ['./encargados-financiera.component.css'],
  providers: [ LenderService]
})
export class EncargadosFinancieraComponent implements OnInit {
  sessionData: SessionData;
  title: string = 'Ejecutivos 360';
  cliente: any = {};
  administradores: any = [];
  administradoresEncargados: any = [];
  adminFinanciera: any = null;
  accion: string = 'Detalle';
  financieras: any = null;

  loadings: any = {
    financieras: true,
    administradoresFinanciera: true,
    administradoresEncargados: true
  };

  requireds: any = {
    financiera: false,
    nombre: false
  };

  tablaAdministradores: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      {
          name: 'Nombre',
          key: 'nombre',
          hide: false
      }, {
          name: 'Primer Apellido',
          key: 'apellidoPaterno',
          hide: false
      }, {
          name: 'Segundo Apellido',
          key: 'apellidoMaterno',
          hide: false
      }, {
          name: 'Email',
          key: 'email',
          hide: false
      }, {
        name: ' ',
        key: 'delete',
        hide: false,
        icono:'delete_forever',
        class: 'Eliminar',
        tooltip: "Eliminar",
        type: 'icono',
      }
    ]
  };

  constructor(
    private appService: ApplicationService,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    if(localStorage.getItem('lenderTemp')){
      this.cliente = JSON.parse(localStorage.getItem('lenderTemp')??'');
      this.title = this.cliente.nombreFinanciera;

      this.loadAdministradoresEncargados();
      this.loadAdministradoresFinanciera();
    }else{
      this.location.back();
    }
  }

  validaRequeridos(){
    let requridos = false;
    if(!this.administradoresEncargados){
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar por lo menos un encargado.', 3000, 'warning');
      requridos = true;
    }
    return requridos;
  }

  cancel() {
    localStorage.removeItem('lenderTemp');
    this.router.navigate([`/clientes`]);
    //this.location.back();
  }

  save() {
    if(this.validaRequeridos()){
      return;
    }

    const loading = this.appService.showLoading(
      `Guardando información...`
    );

    this.lenderService.guardarEncargadosCliente(this.cliente.idFinanciera, {encargados: this.administradoresEncargados}).subscribe(() => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('Aviso', 'Se ha guardado la información correctamente', 3000, 'success');
      this.cancel();
    }, error => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    });
  }

  agregarAdministrador() {
    let existe: boolean = false;
    [].some
    if(this.adminFinanciera){
      if(this.administradoresEncargados.some(administrador => administrador.idClienteAdministrador == this.adminFinanciera.idClienteAdministrador)){
        this.appService.showSnackbar('¡Aviso!', 'El ejecutivo seleccionado ya se encuentra como encargado de este cliente.', 3000, 'warning');
        this.adminFinanciera = null;
      }
      else{
        this.administradoresEncargados = [...this.administradoresEncargados, this.adminFinanciera];
        this.adminFinanciera = null;
      }
    }
    else{
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar un administrador para agregarlo.', 3000, 'warning');
    }
  }

  borrarAdministrador(itemClick: any){
    //console.log(itemClick);
    if(itemClick.columnPressed == 'delete'){
      let index = this.administradores.findIndex((administrador) => administrador.idAdministradorFinanciera == itemClick.row.idAdministradorFinanciera);
      if(index !== -1){
        this.administradoresEncargados.splice(index, 1);
        this.administradoresEncargados = [...this.administradoresEncargados];
      }
        
    }
  }

  loadAdministradoresEncargados(){
    this.loadings.administradoresEncargados = true;
    let query: string = '';
    this.administradoresEncargados = [];
    this.lenderService.getAdministradoresEncargados(this.cliente.idFinanciera)
    .pipe(
      take(1)
    ).subscribe((res: any) => {
      let encargados: any = [];
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        element.nombreCompleto = `${element.nombre}${element.apellidoPaterno ? ' ' + element.apellidoPaterno : ''}${element.apellidoMaterno? ' ' + element.apellidoMaterno : ''}`;
        encargados.push(element);
      }
      this.administradoresEncargados = [...encargados];

      this.loadings.administradoresEncargados = false;
    }, (error: any) => {
      this.app.showError(error);

      this.loadings.administradoresEncargados = false;
    });
  }

  loadAdministradoresFinanciera(){
    this.loadings.administradoresFinanciera = true;

    if(this.cliente){
      this.lenderService.getEjecutivos360()
      .pipe(
        take(1)
      ).subscribe((res: any) => {

        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          element.nombreCompleto = `${element.nombre}${element.apellidoPaterno ? ' ' + element.apellidoPaterno : ''}${element.apellidoMaterno? ' ' + element.apellidoMaterno : ''}` + ` (${element.email})`;
          this.administradores.push(element);
        }

        this.loadings.administradoresFinanciera = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loadings.administradoresFinanciera = false;
      });
    }
    else{
      this.loadings.administradoresFinanciera = false;
    }
  }

}
