export class DebtCollectionChecklist {
  id: number;
  contact: boolean;
  createdAt: Date;
  name: string;
  paymentPromise: boolean;
  paymentPromiseAmount: number;
  paymentPromiseDate: Date;
  relationship: string;
  revisit: boolean;
  revisitDate: Date;
}