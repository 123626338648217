import { ServicesVisitasService } from './../../core/http/services-visitas/services-visitas.service';
import { Injectable } from '@angular/core';

@Injectable()
export class GeoCercaService {
    private resource: string = '/v2/accounts';
    private leanServicesURL = '/cuentas';

    constructor(
        private serviciosVisitas: ServicesVisitasService,
    ) { }

    public obtenerGeoCercas(idCliente: number,queryParam:string) {
        return this.serviciosVisitas.get(`/clientes/${idCliente}/geocercas${queryParam}`);
    }

    public guardarGeoCercas(idCliente: number, geoCerca) {
        return this.serviciosVisitas.post(`/clientes/${idCliente}/geocercas`, geoCerca);
    }

    public editarGeoCercas(idCliente: number, geoCerca) {
        return this.serviciosVisitas.put(`/clientes/${idCliente}/geocercas/${geoCerca.idGeocerca}`, geoCerca);
    }

    public borrarGeoCercas(idCliente: number, idGeocerca: number) {
        return this.serviciosVisitas.delete(`/clientes/${idCliente}/geocercas/${idGeocerca}`);
    }

    public obtenerAgentes(idCliente: number,idGeocerca) {
        return this.serviciosVisitas.get(`/clientes/${idCliente}/geocercas/${idGeocerca}/agentes`);
    }

    public guardarAgentes(idCliente: number,idGeocerca,agentes) {
        return this.serviciosVisitas.post(`/clientes/${idCliente}/geocercas/${idGeocerca}/agentes`,agentes);
    }

    public borrarAgenteGeocerca(idCliente: number, idGeocerca: number, idAgente:number ) {
        return this.serviciosVisitas.delete(`/clientes/${idCliente}/geocercas/${idGeocerca}/agentes/${idAgente}`);
    }

}
