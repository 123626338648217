<mat-form-field *ngIf="type === 'string'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ placeholder }}</mat-label>

  <input
  	matInput textSanitizer 
  	[placeholder]="placeholder"
  	formControlName="value"
    ngDefaultControl
    [maxLength]="maxLenghtOptions"
  	[required]="required"
  />

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">
    El campo {{ placeholder.toLowerCase() }} es requerido
  </mat-error>

  <mat-error *ngIf="fieldForm.get('value').hasError('maxlength')">
    El campo acepta máximo {{fieldForm.get('value').errors['maxlength'].requiredLength}} caracteres
  </mat-error>
  
</mat-form-field>

<mat-form-field *ngIf="type === 'choice'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ placeholder }}</mat-label>

  <mat-select
    formControlName="value"
    ngDefaultControl
    name="item"
    [required]="required"
    
  >
    <mat-option [value]="null">
      Seleccione
    </mat-option>
    <mat-option *ngFor="let item of items" [value]="item['value']">
      {{item['name']}}
    </mat-option>
  </mat-select>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">El campo {{ placeholder.toLowerCase() }} es requerido</mat-error>
</mat-form-field>

<mat-form-field *ngIf="type === 'number'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ placeholder }}</mat-label>

  <input
    matInput textSanitizer 
    type="number"
    formControlName="value"
    ngDefaultControl
     max="maxValue"
    
    (input)="onInputLimite()"
    [required]="required"
    [disabled]="disabled"
  />

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">El campo {{ placeholder.toLowerCase() }} es requerido</mat-error>
</mat-form-field>

<ng-container *ngIf="type === 'boolean'" [formGroup]="fieldForm">
  <mat-checkbox formControlName="value" ngDefaultControl class="kobra-checkbox"></mat-checkbox>
</ng-container>

<mat-form-field *ngIf="type === 'upload'" class="field" [formGroup]="fieldForm" (click)="fileInput.click()" appFileDragDrop (drop)="onDrop($event)">
  <mat-label>{{ placeholder }}</mat-label>

  <button *ngIf="fieldForm.get('value').value" mat-icon-button matPrefix (click)="removeFile($event); $event.stopPropagation();">
    <mat-icon>close</mat-icon>
  </button>

  <input hidden type="file" [accept]="fileAccept.join(',')" [multiple]="false" (change)="onFileSelected($event)" #fileInput [required]="required" />

  <input matInput textSanitizer  disabled [value]="fieldForm.get('value').value?.name" [required]="required" />

  <button mat-icon-button matSuffix (click)="fileInput.click(); $event.stopPropagation();">
    <mat-icon >attach_file</mat-icon>
  </button>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">El campo {{ placeholder.toLowerCase() }} es requerido</mat-error>

  <mat-error *ngIf="fieldForm.get('value').hasError('upload')"> {{ fieldForm.get('value').getError('upload') }} </mat-error>
</mat-form-field>

<ng-container *ngIf="type === 'toggle'" [formGroup]="fieldForm">
  <mat-slide-toggle formControlName="value" ngDefaultControl class="kobra-slide-toggle">
    <ng-content></ng-content>
  </mat-slide-toggle>
</ng-container>

<mat-form-field *ngIf="type === 'list'" [formGroup]="fieldForm" style="width: 100%;">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let v of fieldForm.get('value').value"
                  (removed)="removeFromList(v)">
      {{v.label}}
      <button matChipRemove *ngIf="!disabledCombo">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input [placeholder]="placeholder"
          [maxLength]="maxLenghtOptions"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [disabled]="disabledCombo"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addToList($event)"/>
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of items" [value]="item">
      {{item['label']}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="fieldForm.get('value').hasError('required')">El campo {{ placeholder.toLowerCase() }} es requerido</mat-error>
</mat-form-field>

<div *ngIf="type === 'radio'" [formGroup]="fieldForm" style="width: 100%; display: flex; flex-direction: column; margin-bottom: 8px;">
  <mat-label>{{ placeholder }}</mat-label>

  <mat-radio-group style="margin: 4px;" [disabled]="disabled">
    <mat-radio-button
      class="kobra-radio"
      *ngFor="let item of items"
      [checked]="fieldForm.get('value').value === item['value']"
      [value]="item['value']"
      (change)="onRadioGroupChange(item['value'])"
      style="margin-right: 8px;"
    >
      {{item['name']}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div *ngIf="type === 'checkbox'" [formGroup]="fieldForm" style="width: 100%; margin-bottom: 8px;">
  <mat-label>{{ placeholder }}</mat-label>
  <br>
  <ng-container *ngFor="let item of items">
    <mat-checkbox style="margin-left: 15px;" class="kobra-checkbox" [checked]="selectedItemsChecked.includes(item.value)"
    (change)="onCheckBoxChange(item.value)">
      {{ item.name }}
    </mat-checkbox>
  </ng-container>
</div>
