import { Pagination } from "../../../pagination/pagination.model";

export class ApiKobraResponse {
  constructor(data ?: any) {
    if (data) this.data = data;
  }

  success: boolean;
  data: any;
  message: string;
  pagination: Pagination;
}