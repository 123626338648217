import { Visit } from './../../visit/visit.model';
import { environmentSelector } from '../../../environments/environment.selector';
import { Component, OnInit, Input, EventEmitter, OnChanges } from '@angular/core';
import mapboxgl from 'mapbox-gl';



declare var $: any;
//declare var mapboxgl: any;

@Component({
    // moduleId: module.id,
    selector: 'mapbox-visitas',
    templateUrl: 'mapbox-visitas.component.html',
    styleUrls: [
        'mapbox-visitas.component.css'
    ]
})
export class MapboxVisitasComponent implements OnInit, OnChanges {
  private environment = environmentSelector();

    @Input() public visitas: Visit[];
    public loading: boolean;

    geoJsonvisitas = [];
    geoJsonSeleccionada = [];
    start = 0;
    rows = 1000;
    map = null;

    constructor(
    ) {
        mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg';
    }

    public ngOnInit() {
        this.loading = true;
        this.crearMapa();
    }

    public ngOnChanges(changes) {
        //this.showMap();
    }

    crearMapa(){
        // Crea un mapa

        const poiOptions = {
            "width": 32,
            "height": 32,
            "x": 0,
            "y": 0,
            "pixelRatio": 1
        };

        this.map = new mapboxgl.Map({
            container: 'mapVisitas', // container id
            style: 'mapbox://styles/mapbox/streets-v12', //stylesheet location
            center: [this.environment.paisLng, this.environment.paisLat], // starting positiontarting zoom,
            zoom: 4,
           ...poiOptions
        });
        
        this.visitas.forEach(visita => {
            const lat = visita.latFotos || visita.latInicio;
            const lng = visita.lngFotos || visita.lngInicio;
            const coordenadas: [number, number] = [parseFloat(lng), parseFloat(lat)];

            if (!isNaN(coordenadas[0]) && !isNaN(coordenadas[1]))  {
                const feature = {
                    type: 'Feature',
                    properties: {
                        title: visita.client.fullName,
                        description: `<table>
                        <tr>
                          <td style="width: 180px">
                            <h3><b>${visita.client.fullName}<b/></h3>
                          </td>
                        </tr>
                      </table><br>
                      <b>Folio:</b> ${visita.folio}
                      <br><b>ID Cuenta:</b> ${visita.client.externalID}
                      ${(visita.client.subExternalID)?`<br><b>ID Subcuenta:</b> ${visita.client.subExternalID}`:''}
                      ${(visita.grupo)?`<br><b>Grupo:</b> ${visita.grupo}`:''}
                      ${(visita.sucursal)?`<br><b>Sucursal:</b> ${visita.sucursal}`:''}
                      <br><b>Folio Domicilio:</b> ${visita.clientAddress.folioDomicilio}
                      <br><b>Domicilio:</b> ${visita.fullAddres}
                      <br><b>Agente:</b> ${visita.agent.id} - ${visita.agent.fullName}<br>
                      <b>Ubicación:</b> ${lat}, ${lng}<br>
                      `
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: coordenadas
                    }
                };
                  // Agrega el objeto GeoJSON a la matriz de features en la fuente 'places'
                this.geoJsonvisitas.push(feature);
            }
        });

        this.map.on('load', () => {
            this.map.addSource('places', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: this.geoJsonvisitas
            }
          });
    
          this.map.addLayer({
            id: 'places',
            type: 'circle',
            source: 'places',
            paint: {
              'circle-color': '#00c977',
              'circle-radius': 6,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#ffffff'
            }
          });
    
          const popup = new mapboxgl.Popup({
            closeOnClick: false,
          });
    
          this.map.on('click', 'places', (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;
    
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
          });
    
            this.map.on('mouseenter', 'places', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });
             
            // Change it back to a pointer when it leaves.
            this.map.on('mouseleave', 'places', () => {
                this.map.getCanvas().style.cursor = '';
            });
        });
    }

}
