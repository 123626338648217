<app-modal [title]="(!data.nuevo ? 'Editar':'Agregar') + ' Referencia Personal'" (onClose)="onClose()" [onlyClose]="true">
  <div *ngIf="form" class="container">
    <form [formGroup]="form" (submit)="save()">
      <div class="kobra two columns">
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: reltionshipValidation.hasError()}">
            <mat-label>Parentesco referencia</mat-label>
            <mat-select
              formControlName="relationship"
              placeholder="Seleccione..."
            >
              <mat-option *ngFor="let relationship of relationships" [value]="relationship.id">{{relationship.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix>handshake</mat-icon>
            <mat-error>{{reltionshipValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: nameValidation.hasError()}">
            <mat-label>Nombre(s)</mat-label>
            <input matInput textSanitizer  formControlName="name"
              placeholder="Ingrese el nombre">
            <mat-icon matSuffix>person</mat-icon>
            <mat-error>{{nameValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: surnameValidation.hasError()}">
            <mat-label>Apellido(s)</mat-label>
            <input matInput textSanitizer  formControlName="surname"
              placeholder="Ingrese el apellido">
            <mat-icon matSuffix>person</mat-icon>
            <mat-error>{{surnameValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: phoneNumberValidation.hasError()}">
            <mat-label>Número de teléfono</mat-label>
            <input matInput textSanitizer  formControlName="phoneNumber"
              placeholder="Ingrese el número de teléfono">
            <mat-icon matSuffix>call</mat-icon>
            <mat-error>{{phoneNumberValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: phoneTypeValidation.hasError()}">
            <mat-label>Tipo de teléfono</mat-label>
            <mat-select
              formControlName="phoneType"
              placeholder="Seleccione..."
            >
              <mat-option *ngFor="let type of phoneTypes" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix>call</mat-icon>
            <mat-error>{{phoneTypeValidation.error}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <!-- <button mat-stroked-button m-actions class="boton-accion-eliminar" *ngIf="!data.nuevo" (click)="delete()"><mat-icon>delete_forever</mat-icon>Eliminar</button> -->
  <button mat-stroked-button m-actions class="boton-guardar" (click)="save()" [disabled]="form.invalid"><mat-icon>
    save
  </mat-icon>Guardar</button>
</app-modal>