<div class="app content kobra">
  <mat-card>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
    </mat-card-header>
    <mat-card-content class="container">
      
      <!-- Primera Columna - General -->
      <mat-card class="tile">
        <mat-card-header>
          <mat-card-title>
            General
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="personalInfoForm">
            <mat-form-field class="input-conf">
              <mat-label>Nombre</mat-label>
              <input matInput textSanitizer  formControlName="nombre" placeholder="Capture el nombre del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Primer apellido</mat-label>
              <input matInput textSanitizer  formControlName="apellidoPaterno" placeholder="Capture el apellido del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>
              <mat-error>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Segundo apellido</mat-label>
              <input matInput textSanitizer  formControlName="apellidoMaterno" placeholder="Capture el apellido del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>
            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Correo</mat-label>
              <input matInput textSanitizer  formControlName="email" placeholder="Capture el correo del administrador">
              <mat-icon matSuffix>mail</mat-icon>
              <mat-error>
              </mat-error>
            </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-footer >
            <div mat-card-actions align="right" style="margin: 10px;  ">
              <button mat-stroked-button class="boton-guardar" (click)="guardarDatos()" m-actions>
                <mat-icon>
                  save
                </mat-icon>Guardar</button>
            </div>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>

        <!-- Segunda Columna - Saldos -->
        <mat-card class="tile">
          <mat-card-header>
            <mat-card-title>
              Cambiar Contraseña
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form [formGroup]="passwordForm">

            <mat-form-field class="input-conf">
              <mat-label>Contraseña actual</mat-label>
              <input matInput textSanitizer  formControlName="contrasenaActual" placeholder="Introduce la contraseña"
                [type]="'password'">
              <mat-icon class="ver-password" matSuffix></mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Nueva contraseña</mat-label>
              <input matInput textSanitizer  placeholder="Confirma la contraseña" [type]="hideContra ? 'password' : 'text'"
                formControlName="contrasenaNueva" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$">
              <mat-icon class="ver-password" matSuffix (click)="hideContra = !hideContra">
                {{hideContra ? 'visibility_off': 'visibility'}}
              </mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Confirmar nueva contraseña</mat-label>
              <input matInput textSanitizer  placeholder="Confirma la contraseña" [type]="hideConfirm ? 'password' : 'text'"
                formControlName="contrasenaRepetida" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$">
              <mat-icon class="ver-password" matSuffix (click)="hideConfirm = !hideConfirm">
                {{hideConfirm ? 'visibility_off' : 'visibility'}}
              </mat-icon>

            </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-footer class="kobra-card-footer">

            <div mat-card-actions align="right" style="margin: 10px; ">
              <button mat-stroked-button class="boton-guardar" (click)="cambiarContrasenia()" m-actions>
                <mat-icon>
                  save
                </mat-icon>Guardar</button>
            </div>
          </mat-card-footer>
        </mat-card>
    </mat-card-content>
  </mat-card>
</div>