
import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { AgentService } from "../../agent.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'estatus-agente.component',
  templateUrl: 'estatus-agente.component.html',
  styleUrls: ["estatus-agente.component.css"],
  providers: [
    AgentService
  ]
})
export class ModalEstatusAgenteComponent {
  private destroy$ = new Subject<void>();
  public selectedStatus: any;
  public cargando = false;
  constructor(
    private agentService: AgentService,
    public dialogRef: MatDialogRef<ModalEstatusAgenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
  ){

  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {
    
    }

    public actualizaEstatus(): void {

        if (!this.selectedStatus) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar el estatus.",
                3000,
                "warning"
            );
            return;
        }else {
            this.actualizaEstatusMasivo();
        }


    }
    
    public actualizaEstatusMasivo(): void {
        this.cargando = true;
        let contador = 0;
        let loading: number = this.app.showLoading(`Actualizando estatus...`);
        const observables: Observable<any>[] = this.data.seleccionAgentes.map(subArray => {
            return this.sendAccountForUpdateStatus(subArray);
        });

        //console.log(observables);
    
        forkJoin(observables).subscribe(
        (results: any[]) => {
            contador = results.filter(result => result === true).length;
            this.cargando = false;
            this.selectedStatus = null;
            this.app.showSnackbar('¡Aviso!', `${contador} agentes fueron cambiados de estatus.`, 3000, 'success');
            this.app.hideLoading(loading);
            this.dialogRef.close(true);
        },
        (error) => {
            this.cargando = false;
            this.app.hideLoading(loading);
            this.app.showSnackbar('Error', 'Hubo un problema al actualizar los agentes.', 3000, 'error');
        }
        );
        
      }
      
    
      public sendAccountForUpdateStatus(arrayAgente: any): Observable<boolean> {
        let agente: any = {
            id: arrayAgente.id,
            idCobradorEstatus: this.selectedStatus
        };

        return new Observable<boolean>(observer => {
          this.agentService.update(agente)
            .subscribe(
              respuesta => {
                if (!respuesta.success && respuesta.error.message != '') {
                  this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
                }
                observer.next(true);
                observer.complete();
              },
              error => {
                this.dialogRef.close(false);
                this.app.showError(error);
                observer.next(false);
                observer.complete();
              }
            );
        });
      }

    limpiarEstatus(){
      this.selectedStatus = null;
    }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    }

}
