import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environmentSelector } from '../../../../environments/environment.selector';
import { ApiKobraResponse } from './api-kobra-response';

@Injectable()
export class ApiKobraService {
  private baseURL: string = environmentSelector().kobraServices.api;

  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.api;
  }

  private headers(): HttpHeaders {
    let header: any = {'Content-Type': 'application/json'};
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Access-Token'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    return new HttpHeaders(header);
  }

  private request(url: string, req: Observable<any>, login: boolean = false): Observable<ApiKobraResponse> {
    return req.pipe(
      map((res) => {
        res = <ApiKobraResponse> res;

        if (res.success === undefined) {
          if (!res) {
            throw 'Error procesando respuesta de servicio: ' + url;
          } else {
            res = new ApiKobraResponse(res);
          }
        } else if (res.success == false) {
          throw (res.message || 'Error en el servicio: ' + url);
        }
        return res;
      }),
      catchError((err: HttpErrorResponse|string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();

              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            } else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            } else {
              console.log(err);
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(
                `Backend returned code ${err.status}, ` +
                `body was: ${err.message}`);
              message = err.message;
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete( path: string ): Observable<ApiKobraResponse> {
    let url = this.baseURL + path;
    return this.request(url, this.httpClient.delete(url, {headers: this.headers()}));
  }

  public get( path: string ): Observable<ApiKobraResponse> {
    let url = this.baseURL + path;
    return this.request(url, this.httpClient.get(url, {headers: this.headers()}));
  }

  public patch( path: string, data: any ): Observable<ApiKobraResponse> {
    let url = this.baseURL + path;
    return this.request(url, this.httpClient.patch(url, data, {headers: this.headers()}));
  }

  public post( path: string, data: any ): Observable<ApiKobraResponse> {
    let url = this.baseURL + path;
    return this.request(url, this.httpClient.post(url, data, {headers: this.headers()}));
  }
}
