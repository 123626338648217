import { Injectable } from '@angular/core';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { ICoberturaPagination, IAgentesCoberturaVinculadosPagination } from './ICobertura.interface';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';
import { take } from 'rxjs/internal/operators/take';
import { Account } from "../../account/account.model";
import { ServicesGestoresService } from '@servicesGestores/services-gestores.service';

@Injectable({
  providedIn: 'root'
})
export class CoberturaService {

  public filtrosForm: FormGroup;
  public loader: boolean;
  public data$: Observable<any[]>;
  public indicadoresCuentas: any;
  public indicadoresFinancierasGestores: any;
  public pagination: ICoberturaPagination;
  public agentesCoberturaVinculadosPagination: IAgentesCoberturaVinculadosPagination;
  public fromRowPageAgentes: number;
  public toRowPageAgentes: number;
  public pageSizeAgentes: number = 10;
  public pageSize: number = 20;
  public fromRowPage: number;
  public toRowPage: number;
  public idExternoCtrl: FormControl;
 // public tiempoAsignadaCtrl: FormControl;
  public tiempoApartadaCtrl: FormControl;

  public cobertura: any = [
    { idCobertura: 6, nombre: 'Todos' },
    { idCobertura: 5, nombre: 'Dinámica' },
    { idCobertura: 4, nombre: 'Total' },
    { idCobertura: 3, nombre: 'Completa' },
    { idCobertura: 2, nombre: 'Parcial' },
    { idCobertura: 1, nombre: 'Sin cobertura' }
  ];

  public coberturaVinculacion: any = [
    { idCobertura: 6, nombre: 'Todos' },
    { idCobertura: 5, nombre: 'Dinámica' },
    { idCobertura: 4, nombre: 'Total' },
    { idCobertura: 3, nombre: 'Completa' },
    { idCobertura: 2, nombre: 'Parcial' }
  ];

  public etapasCuenta: any = [
    { id: 6, nombre: 'Todas' },
    { id: 1, nombre: 'Por Vincular' },
    { id: 2, nombre: 'Por Asignar' },
    { id: 3, nombre: 'Por Apartar' },
    { id: 4, nombre: 'Por Visitar' },
    { id: 5, nombre: 'Visitada' }
  ];

  constructor(
    private lServ: ServiciosKobraService,
    private gServ: ServicesGestoresService,
    private app: ApplicationService,
    private fb: FormBuilder
  ) {

    this.filtrosForm = this.fb.group({
      idFinanciera: ['', Validators.required],
      idTipoCobertura: ['', Validators.required],
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      idEtapaCuenta: ['', Validators.required]
    });

    this.idExternoCtrl = this.fb.control("", Validators.required);
    this.tiempoApartadaCtrl = this.fb.control("", Validators.required);
  }

  buscarUbicacionMunicipioEstado(termino: string) {
    let queryParam: string = "";

    if (termino) {
      queryParam = `?termino=${termino}`;
    }

    return this.lServ.get(`/ubicacion-estado-municipio${queryParam}`);
  }

  obtenerTableroCoberturaIndicadoresCuentas() {
    this.indicadoresCuentas = undefined;
    let queryParam = '?_paginate=0';

    queryParam += this.generarQueryParams();

    this.lServ.get(`/tablero-cobertura-indicadores-cuentas${queryParam}`)
      .pipe(
        take(1)
      ).subscribe((res: any) => this.indicadoresCuentas = res.data);
  }

  obtenerTableroCoberturaIndicadoresFinancierasGestores() {
    this.indicadoresFinancierasGestores = undefined;
    let queryParam = '?_paginate=0';

    queryParam += this.generarQueryParams();

    this.lServ.get(`/tablero-cobertura-indicadores-financieras-gestores${queryParam}`)
      .pipe(
        take(1),
        map((res: any) => {
          res.data.totalFinancieras = String(res.data.totalFinancieras);
          res.data.totalGestores = String(res.data.totalGestores);

          return res.data;
        })
      ).subscribe((res: any) => this.indicadoresFinancierasGestores = res);
  }

  obtenerTableroCobertura(page: number) {
    this.indicadoresCuentas = undefined;
    this.indicadoresFinancierasGestores = undefined
    this.loader = true;

    const campos = '"financiera", "tipoCobertura", "estado", "municipio", "totalCuentas", "totalGestores", "idTipoCobertura", "cuentasAsignadas", "cuentasPorAsignar", "cuentasApartadas", "cuentasPorApartar", "gestoresVinculados"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}`;

    queryParams += this.generarQueryParams();

    this.data$ = this.lServ.get(`/tablero-cobertura${queryParams}`)
      .pipe(
        map((res: any) => {
          this.loader = false;

          this.pagination = res.data;
          this.calcFromToRowPage();

          this.obtenerTableroCoberturaIndicadoresCuentas();
          this.obtenerTableroCoberturaIndicadoresFinancierasGestores();

          return this.pagination.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
  }

  obtenerTableroVinculacion(page: number) {
    this.loader = true;

    const campos = '"idExterno","idFinanciera,subIdExterno, "idCuenta", "nombreProducto", "nombreAcreditado", "nombreFinanciera", "estado", "municipio", "totalGestores", "gestoresVinculados", "asignada", "apartada", "dias", "tiempoAsignada", "tiempoApartada", "idExternoAgenteAsignado", "idExternoAgenteApartado", "idTipoCobertura", "idCliente", "idAcreditado", "idCuentaPG"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=estado,municipio`;

    queryParams += this.generarQueryParams();

    this.data$ = this.lServ.get(`/tablero-cobertura-vinculacion${queryParams}`)
      .pipe(
        map((res: any) => {
          this.loader = false;

          this.pagination = res.data;
          this.calcFromToRowPage();

          return this.pagination.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
  }

  generarQueryParams() {
    let queryParams: string = "";

    if (this.filtrosForm.get('idFinanciera').valid && this.filtrosForm.get('idFinanciera').value != 'cli') {
      queryParams += this.filtrosForm.get('idFinanciera').valid ? `&idFinanciera=${this.filtrosForm.get('idFinanciera').value}` : '';
    }

    if (this.filtrosForm.get('estado').valid) {
      queryParams += this.filtrosForm.get('estado').valid ? `&estado=${this.filtrosForm.get('estado').value}` : '';
    }

    if (this.filtrosForm.get('municipio').valid) {
      queryParams += this.filtrosForm.get('municipio').valid ? `&municipio=${this.filtrosForm.get('municipio').value}` : '';
    }

    if (this.filtrosForm.get('idTipoCobertura').valid && this.filtrosForm.get('idTipoCobertura').value !== 6) {
      queryParams += `&idTipoCobertura=${this.filtrosForm.get('idTipoCobertura').value}`;
    }

    if (this.filtrosForm.get('idEtapaCuenta').valid && this.filtrosForm.get('idEtapaCuenta').value !== 6) {
      queryParams += `&etapaCuenta=${this.filtrosForm.get('idEtapaCuenta').value}`;
    }

    if (this.idExternoCtrl.valid && this.idExternoCtrl.value.toString().trim().length > 0) {
      queryParams += `&idExterno=${this.idExternoCtrl.value}`;
    }

    // if(this.tiempoAsignadaCtrl.valid && this.tiempoAsignadaCtrl.value.toString().trim().length > 0){
    //   queryParams += `&tiempoAsignada=${this.tiempoAsignadaCtrl.value}`;
    // }

    if(this.tiempoApartadaCtrl.valid && this.tiempoApartadaCtrl.value.toString().trim().length > 0){
      queryParams += `&tiempoApartada=${this.tiempoApartadaCtrl.value}`;
    }

    return queryParams;
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  obtenerAgentesCoberturaVinculados(page: number, idCliente: any, idAcreditado: any, vinculado: boolean) {
    let campos: string = '"idCobrador", "idAgente", "nombreAgente", "telefono", "correo", "disponible", "operando", "ranking", "visitas"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSizeAgentes}&_slt=${campos}&idCliente=${idCliente}&idAcreditado=${idAcreditado}`;
    let url = (vinculado) ? 'asignacion' : 'vinculacion';

    if (this.filtrosForm.get('municipio').valid && this.filtrosForm.get('municipio').value != 'cli') {
      queryParams += this.filtrosForm.get('municipio').valid ? `&municipio=${this.filtrosForm.get('municipio').value}` : '';
    }

    if (this.filtrosForm.get('idTipoCobertura').valid && this.filtrosForm.get('idTipoCobertura').value !== 6) {
      queryParams += `&idTipoCobertura=${this.filtrosForm.get('idTipoCobertura').value}`;
    }

    return this.lServ.get(`/cobertura-agentes-${url}${queryParams}`)
      .pipe(
        map((res: any) => {
          this.agentesCoberturaVinculadosPagination = res.data;
          this.calcFromToRowPageAgentes();
          this.agentesCoberturaVinculadosPagination.pageData.map((item: any) => item.selected = false);

          return this.agentesCoberturaVinculadosPagination.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
  }

  calcFromToRowPageAgentes() {
    if (this.agentesCoberturaVinculadosPagination.pageData.length <= 0) {
      this.fromRowPageAgentes = 0;
      this.toRowPageAgentes = 0;
    } else {
      this.fromRowPageAgentes = (this.pageSizeAgentes * this.agentesCoberturaVinculadosPagination.page) - (this.pageSizeAgentes - 1);

      if (this.agentesCoberturaVinculadosPagination.page === this.agentesCoberturaVinculadosPagination.pages) {
        this.toRowPageAgentes = this.agentesCoberturaVinculadosPagination.totalRows;
      } else {
        this.toRowPageAgentes = this.agentesCoberturaVinculadosPagination.page * this.pageSizeAgentes;
      }
    }
  }

  public assignAgent( accounts: Account[], agentID: number, comment: string = '' ): Observable<any> {
    let data: any = {
      accounts: accounts,
      agentID: agentID,
      comment: comment
    };

    return this.lServ.post('/cuentas/asignar', data).pipe(
      map( res => {
        let response: any = {
          success: res.success,
          accounts: res.data.accounts,
          message: res.data.message,
          idAgent: agentID,
        }
        return response;
      })
    );
  }

  public vincularAgentes(idsCobradores: number[], idCuenta: number, idCliente: number): Observable<any>{
    let data: any = {
      idsCobradores: idsCobradores,
      idCliente: idCliente,
      idCuenta: idCuenta
    };

    return this.lServ.post('/cobradores/vinculacion', data);
  }

  public obtenerRanking(idCobrador: number): Observable<any>{
    return this.gServ.get(`/agentes/${idCobrador}/ranking`);
  }
}
