import { Agent } from "../agent/agent.model";

export class TakenAccount {
  id: Number;
  accountID: Number;
  agent: Agent;
  createdAt: Date;
  expirationDate: Date;
  takenDate: Date;

  constructor() {
    this.agent = new Agent();
  }
};