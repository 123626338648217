import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../application/shared/application.service';
import { environmentSelector } from '../../../environments/environment.selector';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';

@Component({
  selector: 'app-cargar-archivo-pdf',
  templateUrl: 'cargar-archivo-pdf.component.html',
  styleUrls: ['cargar-archivo-pdf.component.css']
})
export class CargarArchivoComponent implements OnInit {
  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  acceptFiles: Array<string> = ['.pdf'];
  file: File[];
  private environment = environmentSelector();
  urlHelp = this.environment.kobraHelp ;
  public isFile:boolean;

  constructor(private appService: ApplicationService) { }

  ngOnInit() {}

  onUpload(files: File[]) {
    if(files.length == 0){
      return;
    }
    
    this.file = files;
    this.isFile = true;
    this.onChange.emit(this.file);
  }

  onRemoveFile() {
    this.isFile = false;
    this.file = [];
    this.onChange.emit(false);
    this.fileUploadComponent.onQuitar();
  }

  onErrorFile(error) {
    this.appService.showError(error);
  }
}
