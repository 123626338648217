<app-modal [title]="title" (onClose)="onClose()" [onlyClose]="true">
  <div class="kobra two columns">
    <div class="kobra column">
      <mat-label>
        <b>Nombre</b>
      </mat-label>
      <mat-label>
        {{this.lender.nombre}}
      </mat-label>
    </div>
    <div class="kobra column">
      <mat-label>
        <b>Razón social</b>
      </mat-label>
      <mat-label>
        {{this.lender.razonSocial}}
      </mat-label>
    </div>
    <div class="kobra column">
      <mat-label>
        <b>Correo</b>
      </mat-label>
      <mat-label>
        {{this.lender.email}}
      </mat-label>
    </div>
    <div class="kobra column">
      <mat-label>
        <b>Teléfono</b>
      </mat-label>
      <mat-label>
        {{this.lender.telefono}}
      </mat-label>
    </div>
    <div class="kobra column">
      <mat-label>
        <b>Sitio web</b>
      </mat-label>
      <mat-label>
        {{this.lender.sitioWeb}}
      </mat-label>
    </div>
    <div class="kobra column">
      <mat-label>
        <b>Encargado</b>
      </mat-label>
      <mat-label>
        {{this.lender.nombreEncargado}}
      </mat-label>
    </div>
  </div>
</app-modal>
