import { Component, OnInit, OnDestroy } from '@angular/core';
import { switchMap, map, catchError, takeUntil, filter, tap } from 'rxjs/operators';
import { ApplicationService } from '../application/shared/application.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
/*import { SuiLocalizationService } from 'ng2-semantic-ui';
import es from 'ng2-semantic-ui/locales/es';*/
import { DashboardService } from './dashboard.service';
import { of, timer, Subject } from 'rxjs';
//import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  loadingTVR = true;
  totalVisitasRealizadas = 0;
  loadingTVEP = true;
  totalVisitasEnProceso = 0;
  loadingTVPP = true;
  totalVisitasPromesasPago = 0;
  loadingTCEE = true;
  totalCuentasEnEsperaConfirmacion = 0;
  loadingTCA = true;
  totalCuentasApartadas = 0;
  loadingTCN = true;
  totalCuentasNuevas = 0;
  loadingTCAn = true;
  totalCuentasAntiguas = 0;
  loadingCT = true;
  loadingCP = true;
  loadingSC = true;

  coberturaTotal = 0;
  coberturaParcial = 0;
  sinCobertura = 0;
  ctPorVincular = 0;
  ctPorAsignar = 0;
  ctPorApartar = 0;
  ctPorVisitar = 0;
  ctVisitadas = 0;
  cpPorVincular = 0;
  cpPorAsignar = 0;
  cpPorApartar = 0;
  cpPorVisitar = 0;
  cpVisitadas = 0;
  public today: string = new Date().toISOString();

  detenerIndicadores: Subject<void> = new Subject<void>();
  detenerCobertura: Subject<void> = new Subject<void>();
  onDestroy: Subject<void> = new Subject<void>();
  timeReq = 1000 * 20;

  filtrosForm: FormGroup;
  //today = moment(new Date()).format('YYYY-MM-DD');
  maxInicio = moment(new Date()).format('YYYY-MM-DD');
  minFin = moment(new Date()).format('YYYY-MM-DD');
  maxFin = moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private dashServ: DashboardService,
    private app: ApplicationService,
    //public localizationService: SuiLocalizationService,
    private formBuilder: FormBuilder,
    private router: Router
    

    //private dateAdapter: DateAdapter<Date>
  ) { 
    /*localizationService.load('es', es);
    localizationService.setLanguage('es');*/
    //this.dateAdapter.setLocale('es-MX');
    this.filtrosForm = this.formBuilder.group({
      fecInicio: new Date(),
      fecFin: new Date()
    });
  }

  ngOnInit() {
    const fechaInicial = moment(new Date()).format('YYYY-MM-DD');
    this.obtenerIndicadoresDinamicos(fechaInicial, fechaInicial);
    this.obtenerIndicadoresEstaticos();

    this.filtrosForm.valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(({fecInicio, fecFin}) => {
      this.loadingTVR = true;
      this.loadingTVEP = true;
      this.loadingTVPP = true;
      this.loadingTCEE = true;
      this.loadingTCA = true;
      this.loadingTCN = true;

      this.detenerIndicadores.next();
      this.detenerIndicadores.complete();

      this.detenerIndicadores = new Subject<void>();      
      const fechaInicio = moment(fecInicio).format('YYYY-MM-DD');
      const fechaFin = moment(fecFin).format('YYYY-MM-DD');
      this.obtenerIndicadoresDinamicos(fechaInicio, fechaFin);

    });

    this.router.events
      .pipe(
        takeUntil(this.onDestroy),
        filter((event: any) => event instanceof NavigationStart)
      ).subscribe((event: NavigationStart) => {
        if (this.detenerIndicadores.isStopped && event.url === '/') {
          this.detenerIndicadores = new Subject<void>();
          this.detenerCobertura = new Subject<void>();
          this.obtenerIndicadoresDinamicos(fechaInicial, fechaInicial);
          this.obtenerIndicadoresEstaticos();
        }
      });

      if (this.router.url !== '/') {
        this.detenerIndicadores.next();
        this.detenerIndicadores.complete();
        this.detenerCobertura.next();
        this.detenerCobertura.complete();
      }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.detenerIndicadores.next();
    this.detenerIndicadores.complete();

    this.detenerCobertura.next();
    this.detenerCobertura.complete();
  }

  cambioInicio($event){
    this.minFin = moment($event.value).format('YYYY-MM-DD');
  }

  cambioFin($event){
    this.maxInicio = moment($event.value).format('YYYY-MM-DD');
  }

  obtenerIndicadoresDinamicos(fecInicio, fecFin) {
    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalVisitasRealizadas(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTVR = false;
          return res.data.visitasRealizadas;
        }),
        catchError((err: any) => {
          this.loadingTVR = false;
          this.app.showError(err);
          return of(0);
        })
      ).subscribe((vr: number) => {
        this.totalVisitasRealizadas = (vr != null) ? vr : 0;
      });

    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalVisitasEnProceso(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTVEP = false;
          return res.data.visitasEnProceso;
        }),
        catchError((err: any) => {
          this.loadingTVEP = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((vep: number) => {
        this.totalVisitasEnProceso = (vep != null) ? vep : 0;
      });

    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalVisitasPromesasPago(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTVPP = false;
          return res.data.promesasPago;
        }),
        catchError((err: any) => {
          this.loadingTVPP = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((vpp: number) => {
        this.totalVisitasPromesasPago = (vpp != null) ? vpp : 0;
      });

    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalCuentasEnesperaConfirmacion(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTCEE = false;
          return res.data.totalCuentasEstatus;
        }),
        catchError((err: any) => {
          this.loadingTCEE = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((cec: number) => {
        this.totalCuentasEnEsperaConfirmacion = (cec != null) ? cec : 0;
      });

    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalCuentasApartadas(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTCA = false;
          return res.data.apartadas;
        }),
        catchError((err: any) => {
          this.loadingTCA = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((ca: number) => {
        this.totalCuentasApartadas = (ca != null) ? ca : 0;
      });

    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalCuentasNuevas(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTCN = false;
          return res.data.nuevas;
        }),
        catchError((err: any) => {
          this.loadingTCN = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((cn: number) => {
        this.totalCuentasNuevas = (cn != null) ? cn : 0;
      });
      
    timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerIndicadores),
        switchMap(() => this.dashServ.obtenerTotalCuentasAntiguas(fecInicio, fecFin)),
        map((res: any) => {
          this.loadingTCAn = false;
          return res.data.antiguas;
        }),
        catchError((err: any) => {
          this.loadingTCAn = false;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe((can: number) => {
        this.totalCuentasAntiguas = (can != null) ? can : 0;
      });
  }

  obtenerIndicadoresEstaticos(){
    /*timer(0, this.timeReq)
      .pipe(
        takeUntil(this.detenerCobertura),
        switchMap(() => this.dashServ.obtenerCuentasCoberturaVinculacion()),
        map((res: any) => {
          this.loadingCT = false;
          this.loadingCP = false;
          this.loadingSC = false;
          return res.data;
        }),
        catchError((err: any) => {
          this.loadingCT = true;
          this.loadingCP = true;
          this.loadingSC = true;
          this.app.showError(err);

          return of(0);
        })
      ).subscribe(data => {
        this.coberturaTotal = (data.coberturaTotal != null) ? data.coberturaTotal : 0;
        this.coberturaParcial = (data.coberturaParcial != null) ? data.coberturaParcial : 0;
        this.sinCobertura = (data.sinCobertura != null) ? data.sinCobertura :0;
        this.ctPorVincular = (data.ctPorVincular != null) ? data.ctPorVincular : 0;
        this.ctPorAsignar = (data.ctPorAsignar != null) ? data.ctPorAsignar : 0;
        this.ctPorApartar = (data.ctPorApartar != null) ? data.ctPorApartar : 0;
        this.ctPorVisitar = (data.ctPorVincular != null) ? data.ctPorVisitar : 0;
        this.ctVisitadas = (data.ctVisitadas != null) ? data.ctVisitadas : 0;
        this.cpPorVincular = (data.cpPorVincular != null) ? data.cpPorVincular : 0;
        this.cpPorAsignar = (data.cpPorAsignar != null) ? data.cpPorAsignar : 0;
        this.cpPorApartar = (data.cpPorApartar != null) ? data.cpPorApartar : 0;
        this.cpPorVisitar = (data.cpPorVincular != null) ? data.cpPorVisitar : 0;
        this.cpVisitadas = (data.cpVisitadas != null) ? data.cpVisitadas : 0;
      });*/
      this.loadingCT = false;
      this.loadingCP = false;
      this.loadingSC = false;
  }

  goToAyA() {
    this.router.navigate(['aya']).then(() => {
      this.detenerIndicadores.next();
      this.detenerIndicadores.complete();
    });
  }
}