import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class MessageService {
  private timeOut: any;
  public $element: any;
  public message: string;
  public title: string;
  public type: string;

  constructor() { }

  public close() {
    clearTimeout(this.timeOut);
    this.$element.find('.ui.message').transition('fade down');
  }

  public show( type: string, title: string, message?: string ): void {
    this.message = message || title;
    this.title = !message ? null:title;
    this.type = type;
    clearTimeout(this.timeOut);
    if (this.$element) {
      this.$element.find('.ui.message').transition('hide');
      this.$element.find('.ui.message').transition('fade down');
      let $element: any = this.$element;
      this.timeOut = setTimeout(() => {
        $element.find('.ui.message').transition('fade down');
      }, this.message.length * 150);
    }
  }

  public success( title: string, message?: string ): void {
    this.show('success', title, message);
  }

  public error( title: string, message?: string ): void {
    this.show('error', title, message);
  }

  public httpError( error: any ) {
    if (error.showMessage) {
      this.error(error.message);
    }
  }
}
