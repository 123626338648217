<h1 mat-dialog-title>Producto y etiqueta</h1>
<mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Producto</mat-label>
      <mat-select [(ngModel)]="idProductoNvo">
          <mat-option *ngFor="let producto of data.productos" [value]="producto.idProducto" >
            {{producto.nombre || producto.nomProducto}}
          </mat-option>
      </mat-select >
      <mat-icon matSuffix  class="icono-limpiar-solo"
      *ngIf="idProductoNvo" 
      (click)="limpiarProducto()">clear</mat-icon>
    </mat-form-field>
    <!-- <div align="left"class="limpiar-seleccion-kobra" *ngIf="idProductoNvo">
      <a (click)="limpiarProducto()"><i>Limpiar selección</i></a>
    </div> -->

    <mat-form-field class="example-full-width">
      <mat-label>Etiqueta</mat-label>
      <mat-select [(ngModel)]="selectedEtiqueta">
          <mat-option *ngFor="let etiqueta of data.etiquetasCuenta" [value]="etiqueta.idEtiquetaCuenta" >
            {{etiqueta.nombre}}
          </mat-option>
      </mat-select>
      <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="selectedEtiqueta" 
                  (click)="limpiarEtiqueta()">clear</mat-icon>
    </mat-form-field>
    <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedEtiqueta">
      <a (click)="limpiarEtiqueta()"><i>Limpiar selección</i></a>
    </div> -->

    <mat-form-field class="example-full-width" *ngIf="selectedEtiqueta">
      <mat-label>Comentario</mat-label>
      <textarea matInput textSanitizer  placeholder="Favor de escribir un comentario..." rows="3" [(ngModel)]="comentario" maxlength="255" ></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" [disabled] ="!idProductoNvo && !selectedEtiqueta"  (click)="actualizaEtiqueta()"><mat-icon >sync</mat-icon>Actualizar</button>
</mat-dialog-actions>