import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConceptoSaldo, ConceptoSaldoPagination, TiposConceptos } from './conceptos-saldo.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ConceptosSaldosService {

  public conceptos$: Observable<ConceptoSaldo[]>;
  public concepto: ConceptoSaldo;
  public tiposConceptos: Observable<TiposConceptos[]>;
  public loader: boolean;

  public pagination: ConceptoSaldoPagination;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;
  public filter = false;

  public status = [
    { id: 1, nombre: 'Todos' }, { id: 2, nombre: 'Activos' }, { id: 3, nombre: 'Inactivos' }
  ];
  public filtros = {
    nombre: null,
    idTipoConcepto: null,
    activo: null,
    page: 1,
    sortField: 'idConcepto',
    sortAsc: ''
  }

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder
  ) {

  }

  obtenerConceptosSaldos(queryParams: string) {
    return this.saldosKobraServ.get(`/conceptos-saldos${queryParams}`);
  }

  obtenerConceptosProductos(query: string) {
    return this.saldosKobraServ.get(`/conceptos-productos${query}`);
  }

  obtenerTiposConceptos(next?: () => void): void {
    if (this.tiposConceptos) {

      if (next) {
        setTimeout(() => {
          next();
        });
      }

      return;
    }

    this.tiposConceptos = this.saldosKobraServ.get('/tipos-conceptos')
      .pipe(
        map(res => {

          if (next) {
            next();
          }

          return res.data;
        }),
        catchError((error: any) => {
          this.app.showError(error);

          return of([]);
        })
      );
  }

  obtenerTiposConceptosNuevo(): Observable<any> {
    return this.saldosKobraServ.get('/tipos-conceptos');
  }

  searchConceptos(term: string, idTipoConcepto?: number) {
    const query = `?nombre=${term}&activo=true&idTipoConcepto=${idTipoConcepto ? idTipoConcepto : 2}`;

    return this.saldosKobraServ.get(`/conceptos-saldos${query}`)
      .pipe(
        map(res => res.data),
        catchError((error: any) => {
          this.app.showError(error);

          return of([]);
        })
      );
  }

  buscarConceptos(term: string, idTipoConcepto?: number) {
    const query = `?nombre=${term}&activo=true&idTipoConcepto=${idTipoConcepto ? idTipoConcepto : 2}`;

    return this.saldosKobraServ.get(`/conceptos-saldos${query}`);
  }

  obtenerProductosSinConcepto(): Observable<any>{
    return this.saldosKobraServ.get('/productos');
  }

  agregarConcepto(concepto: ConceptoSaldo): Observable<any> {
    return this.saldosKobraServ.post('/concepto', concepto);
  }

  agregarConceptoProducto(idProducto: number, dataConcepto: any): Observable<any>{
    return this.saldosKobraServ.post(`/conceptos/productos/${idProducto}`, dataConcepto);
  }

  actualizarConcepto(idConcepto: number, concepto: ConceptoSaldo): Observable<any> {
    return this.saldosKobraServ.put(`/conceptos/${idConcepto}`, concepto);
  }

  eliminarConcepto(idConcepto: number, query: string = ''): Observable<any> {
    return this.saldosKobraServ.delete(`/conceptos/${idConcepto}${query}`);
  }

  obtenerConcepto(idConcepto: number): Observable<any> {
    return this.saldosKobraServ.get(`/concepto/${idConcepto}`);
  }

  setConceptoData(concepto: ConceptoSaldo) {
    this.concepto = concepto;
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  removerFiltros(){
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
  }

}
