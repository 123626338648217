import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisitClarificationService } from "./visit-clarification.service";
import { ApplicationService } from "src/app/application/shared/application.service";

@Component({
  selector: 'app-visit-clarification',
  templateUrl: './visit-clarification.component.html',
  styleUrls: ['./visit-clarification.component.css'],
  providers: [
    VisitClarificationService
  ]
})
export class VisitClarificationComponent implements OnInit {
  reason: string = '';
  title: string = 'Motivo de la aclaración';

  CLARIFICATION_STATUS = {
    REVISION: 1,
    APPROVED: 2,
    REJECTED: 3,
    CANCELED: 4
  };

  constructor(
    private visitClarificationService: VisitClarificationService,
    private appService: ApplicationService,
    public dialogRef: MatDialogRef<VisitClarificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.idStatus === this.CLARIFICATION_STATUS.APPROVED) {
      this.title = 'Motivo de aprobación para la aclaración';
    } else if (this.data.idStatus === this.CLARIFICATION_STATUS.REJECTED) {
      this.title = 'Motivo de rechazo para la aclaración';
    }

  }

  onClose() {
    this.dialogRef.close(false);
  }

  save() {
    const loading = this.appService.showLoading('Guardando aclaración...');

    this.visitClarificationService.save(
      this.data.idVisit,
      this.data.idAgent,
      this.data.idClient,
      this.reason
    )
      .subscribe(data => {
        this.dialogRef.close(this.CLARIFICATION_STATUS.REVISION);
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('¡Aviso!', 'Se ha guardado con exito el motivo de la aclaración.', 3000, 'success');
      }, (error: string) => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      });
  }

  approved() {
    const loading = this.appService.showLoading('Guardando la aprobación de la aclaración...');
    const message = 'Se ha aprobado la aclaración.';

    this.update(this.CLARIFICATION_STATUS.APPROVED, loading, message);
  }

  rejected() {
    const loading = this.appService.showLoading('Guardando el rechazo de la aclaración...');
    const message = 'Se ha rechazado la aclaración.';

    this.update(this.CLARIFICATION_STATUS.REJECTED, loading, message);
  }

  update(idStatus: number, loading: number, message: string) {
    this.visitClarificationService.update(
      this.data.idVisit,
      this.data.idAgent,
      this.data.idClient,
      this.reason,
      idStatus
    )
      .subscribe(data => {
        this.dialogRef.close(idStatus);
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('¡Aviso!', message, 3000, 'success');
      }, (error: string) => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      });
  }
}
