import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { CuentaService } from "../../../cuentas/shared/cuenta.service";
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'etiqueta-operaciones.component',
  templateUrl: 'etiqueta-operaciones.component.html',
  styleUrls: ["etiqueta-operaciones.component.css"],
  providers: [
    CuentaService
  ]
})
export class ModalEtiquetaOperacionesComponent {
  public selectedTag: any;
  constructor(
    public dialogRef: MatDialogRef<ModalEtiquetaOperacionesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private cuentaService: CuentaService,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
  
  }

  public actualizaEtiqueta(): void {
    let contador = 0;
    let loading: number = this.app.showLoading(`Actualizando etiquetas.`);

    if (this.selectedTag) {
      const observables: Observable<any>[] = this.data.selectedAccount.map(subArray => {
        return this.sendAccountsForUpdateTag(subArray);
      });

      forkJoin(observables).subscribe(
        (results: any[]) => {
          contador = results.filter(result => result === true).length;
          this.app.showSnackbar('¡Aviso!', `${contador} cuentas fueron cambiadas de etiqueta.`, 3000, 'success');
          this.app.hideLoading(loading);
          this.dialogRef.close(true);
        },
        (error) => {
          this.app.hideLoading(loading);
          this.app.showSnackbar('Error', 'Hubo un problema al actualizar las cuentas.', 3000, 'error');
        }
      );
    } else {
      this.app.showSnackbar('Aviso', 'Favor de seleccionar la etiqueta.', 3000, 'warning');
      this.app.hideLoading(loading);
    }
  }


  public sendAccountsForUpdateTag(arrayCuenta: any): Observable<boolean> {
      let cuenta = {
        idFinanciera: arrayCuenta.lenderID,
        idCliente: arrayCuenta.idCliente,
        idAcreditado: arrayCuenta.idAcreditado,
        idExterno: arrayCuenta.externalID,
        idAcreditadoPG: arrayCuenta.idAcreditadoPG,
        idCuentaPG: arrayCuenta.idCuentaPG,
        idAcreditadoCuentaDomicilio: arrayCuenta.idAcreditadoCuentaDomicilio,
        idEtiquetaOperacion: this.selectedTag,
        idEtiquetaCuenta: null,
        idVisita: null,
        idAgente: null
      };
    return new Observable<boolean>(observer => {
      this.cuentaService.actualizarCuenta(arrayCuenta.id, cuenta)
        .subscribe(
          respuesta => {
            if (!respuesta.success && respuesta.error.message != '') {
              this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
            }
            observer.next(true);
            observer.complete();
          },
          error => {
            this.dialogRef.close(false);
            this.app.showError(error);
            observer.next(false);
            observer.complete();
          }
        );
    });
  }


}
