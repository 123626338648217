import { ChecklistRespuestaOpcion } from "./checklist-respuesta-opcion.model";
import { CondicionRespuesta } from "./condiciones/condicion-respuesta.model";

export class ChecklistRespuesta {
  idChecklistRespuesta: number;
  idTipoRespuesta: number;
  idAccionRespuesta: number;
  accion: String;
  nombre: String;
  mostrarPreguntasCuando: boolean;
  minCaracteres: number;
  maxCaracteres: number;
  nombreRespuesta: String;
  options: ChecklistRespuestaOpcion[];
  requerida: boolean;
  editando: boolean;
  condiciones: CondicionRespuesta[];
  blasters: any[];

  constructor() {
    this.options = [];
    this.condiciones = [];
  }

  public static map(data: any): ChecklistRespuesta {
    let instance: ChecklistRespuesta = new ChecklistRespuesta();
    instance.idChecklistRespuesta = data.idChecklistRespuesta || null;
    instance.idTipoRespuesta = data.idTipoRespuesta || null;
    instance.idAccionRespuesta = data.idAccionRespuesta || null;
    instance.accion = data.accion || null;
    instance.nombre = data.nombre || null;
    instance.mostrarPreguntasCuando = data.mostrarPreguntasCuando || false;
    instance.minCaracteres = data.minCaracteres || 0;
    instance.maxCaracteres = data.maxCaracteres || 0;
    instance.nombreRespuesta = data.nombreRespuesta || null;
    instance.requerida = data.requerida || false;
    instance.editando = false;
    instance.options = data.options ? ChecklistRespuestaOpcion.mapArray(data.options) : [];
    instance.condiciones = data.condiciones ? CondicionRespuesta.mapArray(data.condiciones) : [];
    instance.blasters = data.blasters ? data.blasters : [];

    return instance;
  }

  public static mapArray(data: any[]): ChecklistRespuesta[] {
    let instances: ChecklistRespuesta[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(ChecklistRespuesta.map(data[i]));
    }
    return instances;
}
}
