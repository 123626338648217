import { CuentaAgrupada } from "./cuenta-agrupada.model";
import { Pagination } from "../../../pagination/pagination.model";

export class CuentaAgrupadaPaginator {
  pagination: Pagination;
  data: CuentaAgrupada[];

  constructor() {
    this.pagination = new Pagination();
  }
}