<h1 mat-dialog-title>Precargar promesas de pago</h1>
<mat-dialog-content>
  <alerta-informativa 
            clase="info"
            titulo="¡Aviso!" 
            texto="Si deseas precargar las visitas, llena los campos para filtrar, de lo contrario déjalos vacíos.">
    </alerta-informativa>
    <form [formGroup]="formFiltros" >
      <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px">
        <mat-grid-tile colspan="1" rowspan="1">
          <mat-date-picker-formater
              class="ancho"
              formControlName="fecInicio"
              [label]="'Fecha Inicio'"
              [placeHolder]="'Capture la fecha'"
              [max]="maxInicio"
              (ngModelChange)="cambioInicio($event)"
            />
          <!-- <a (click)="formFiltros.get('fecInicio').reset()" *ngIf="formFiltros.get('fecInicio').value" class="limpiar-seleccion-kobra" ><i>Limpiar selección</i></a> -->
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <mat-date-picker-formater
            class="ancho"
            formControlName="fecFin"
            [label]="'Fecha Fin'"
            [placeHolder]="'Capture la fecha'"
            [max]="maxFin"
            [min]="formFiltros.get('fecInicio').value"
            (ngModelChange)="cambioFin($event)"
          />
          
          <!-- <a (click)="formFiltros.get('fecFin').reset()" *ngIf="formFiltros.get('fecFin').value" class="limpiar-seleccion-kobra"><i>Limpiar selección</i></a> -->
        </mat-grid-tile>
      </mat-grid-list-responsive>
      <app-loader *ngIf="loadingLotes" [message]="cargaLotes"></app-loader>
      <div *ngIf="lotes.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>{{ lotes ? 'Lote' : 'Cargando lotes...' }}</mat-label>
          <mat-select formControlName="lote" multiple >
            <mat-option *ngFor="let lote of lotes" [value]="lote">{{ lote.nombre }}</mat-option>
          </mat-select> 
          <mat-icon matSuffix class="icono-limpiar-select" 
                  *ngIf="formFiltros.value.lote != null" 
                  (click)="clearFilter('lote');
                  $event.stopPropagation()">clear

          </mat-icon>        
        </mat-form-field>
      </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="salir()">
    <mat-icon class="kobra-icon">
      close
    </mat-icon> Cancelar
  </button>
  <button mat-stroked-button class="boton-accion-front" (click)="download()">
    <mat-icon>download</mat-icon> Descargar
  </button>
</mat-dialog-actions>

