import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class MapboxService {
  public baseURL: string = 'https://api.mapbox.com/directions/v5/mapbox/walking/';
  constructor(
    private httpClient: HttpClient,
  ) { }

  public matchStreet(coordinates, token): Observable<any> {
    return this.httpClient.get(`${this.baseURL}${coordinates}?access_token=${token}&geometries=geojson`).pipe(
      map((res) => res )
    );;
  }
}