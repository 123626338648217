import { Component, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationService } from "../../../../../application/shared/application.service";
import { take } from "rxjs/internal/operators/take";
import { VisitService } from "../../../../../visit/visit.service";
import moment from 'moment';
import {ConfirmacionDialog} from "../../../../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-listado-bloques',
	templateUrl: './listado-bloques.component.html',
	styleUrls: ['./listado-bloques.component.css'],
  providers: [VisitService],
})
export class ListadoBloquesComponent implements OnInit {
  public ayuda: boolean = false;
  public cargando: boolean = false;
  public eliminando: boolean = false;
  public cardAyuda: boolean = false;
  public variables:any;
  public descripcion: string;
  public momentFecha;
  public itemsSelected: any[] = [];
  cargandoVariables: boolean = false;
  public hijosActivos: boolean = false;


	public tablaArchivos: any;
  idCliente: number;
  idChecklist: number;
  idProducto: number;
  numVersion:number;

	constructor(
    public dialog: MatDialog,
    private app: ApplicationService,
    private visitService: VisitService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.variables = [];
    this.crearTablaArchivos();
    this.momentFecha = moment;
	}

	ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idChecklist = +this.route.snapshot.queryParamMap.get('idChecklist');
    this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
    this.numVersion = +this.route.snapshot.queryParamMap.get('numVersion');

    if (!this.idCliente || !this.idChecklist || !this.idProducto || !this.numVersion) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`/catalogos/checklists/formato-visita/bloques/`]);
    }else{
      this.obtenerVariables();
    }
    
	}

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  crearTablaArchivos() {
    this.tablaArchivos= {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columns: [
        {
          name: 'Clave',
          key: 'clave',
          hide: false
        }, {
          name: 'Descripción',
          key: 'descripcion',
          hide: false
        },{
          name: 'Fecha Registro',
          key: 'fechaRegistro',
          hide: false,
          type: 'date'
        }
      ]
    }
  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }
 

  public obtenerVariables() {
    this.cargandoVariables = true;
    this.visitService.obtenerVariablesDinamicas(this.idCliente, this.idChecklist, this.numVersion)
        .pipe(take(1))
        .subscribe((respuesta: any) => {
          this.cargandoVariables = false;
          this.variables = respuesta.data;

        },
            (error: any) => {
                this.cargandoVariables = false;
                this.app.showError(error);
            }
        );
  }



  openDialogConfirmacion(variable:any): void {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas eliminar la variable?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                texto: variable.nombre,
                claseAccion: "boton-accion-eliminar"
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.eliminar(variable);
        }   
    });
  }

  public eliminar(variable: any) {
    let loading: number = this.app.showLoading('Eliminando variable...');
    this.eliminando = true;
    
    this.visitService.eliminarVarible(this.idCliente, variable.idFormatoVariable)
      .pipe(take(1))
      .subscribe((respuesta) => {
        this.eliminando = false;
        this.app.hideLoading(loading);
        
        if(respuesta.success) {   
          // Eliminar la variable del array this.variables
          this.variables = this.variables.filter(v => v.idFormatoVariable !== variable.idFormatoVariable);
          this.cdr.detectChanges();
          
          this.app.showSnackbar(
            "Aviso",
            "La variable se ha eliminado correctamente.",
            3000,
            "success"
          );
        } else {
          this.app.showSnackbar(
            "Aviso",
            "Ocurrió un problema al eliminar la variable.",
            3000,
            "error"
          );
        }
      }, error => {
        this.eliminando = false;
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }
  

  public abrirBloquesNuevo() {
    this.router.navigate([`catalogos/checklists/formato-visita/bloques/nuevo`], {
      queryParams: 
      {
        idCliente: this.idCliente,
        idChecklist: this.idChecklist,
        numVersion: this.numVersion,
        idProducto: this.idProducto           
      },
      queryParamsHandling: 'merge'
    });
    
  }

  public abrirBloquesEditar(variable) {
    this.router.navigate([`catalogos/checklists/formato-visita/bloques/editar`], {
      queryParams: 
      {
        idCliente: this.idCliente,
        idChecklist: this.idChecklist,
        numVersion: this.numVersion,
        idProducto: this.idProducto,
        idVariable: variable.idFormatoVariable             
      },
      queryParamsHandling: 'merge'
    });
    
  }

  onDoubleClick({ row }) {
    this.abrirBloquesEditar(row);
  }
  
}
