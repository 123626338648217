<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            (click)="obtenerMovimientosClientesExcel()" 
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="obtieneMovimientos(filtros.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayudaComentario(!ayuda)">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="movimientoNuevo()">
        <mat-icon class="kobra-icon">add_thick</mat-icon>
        <span>Nuevo</span>
      </button>
      <button mat-menu-item (click)="subirExcel()">
        <mat-icon class="kobra-icon">cloud_upload</mat-icon>
        <span>Subir Excel</span>
      </button>
    </mat-menu>
  
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tablaCuentas.columns"
      [items]="movs"
      [headers]="tablaCuentas.headers"
      [multiSelect]="tablaCuentas.multiSelect"
      (sortChange)="setSortField($event)"
      >

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
       
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Movimientos Clientes" es una herramienta crucial para el seguimiento detallado de todas las transacciones y movimientos de saldo asociados a cada cliente. Es esencial para proporcionar una visión completa y actualizada de las actividades financieras de los clientes, lo que incluye diversas operaciones como la compra de paquetes, ajustes en los saldos, cobros por visitas, entre otros.


          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo aplicar filtros basados en diversos criterios.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de las visitas para su análisis o uso fuera de línea.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Permite añadir ajustes o nuevos movimientos a cada cliente.
          </p>
          
        </mat-card-content>
      </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="filtros.idFinanciera"
              (ngModelChange)="contabilizadorDeFiltros('superAdmin');"
            >
            </mat-select-search>
          
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generales') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Tipo movimiento</mat-label>
              <mat-select
                [(ngModel)]="filtros.tipoMovimiento"
                (ngModelChange)="contabilizadorDeFiltros('generales');"
                placeholder="Seleccione..."
              >
                <ng-container *ngFor="let mov of clientesMovsServ.tiposMovimientos; index as ti">
                  <mat-option [value]="mov.id">{{mov.nombre}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.tipoMovimiento != null" 
                  (click)="filtros.tipoMovimiento = null;$event.stopPropagation();contabilizadorDeFiltros('generales')">clear</mat-icon> 
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>
          </li>
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Concepto'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="buscarConceptos"
              [(ngModel)]="filtros.concepto"
              (ngModelChange)="contabilizadorDeFiltros('generales');"

            >
            </mat-select-search>
          
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Fechas
          <span *ngIf="contabilizadorDeFiltros('fechasTotal') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasTotal') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-accordion [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Fechas registro
                  <span *ngIf="contabilizadorDeFiltros('fechasR') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasR') }}</span>

                </mat-expansion-panel-header>
                <ol class="lista-filtros-side">
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha inicio'"
                      [placeHolder]="'Capture la fecha inicio'"
                      [max]="filtros.fechaFin ? filtros.fechaFin : hoy"
                      [(ngModel)]="filtros.fechaInicio"
                      (ngModelChange)="cambioInicio($event, 'reg')"
                    />
                   
                  </li>
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha fin'"
                      [placeHolder]="'Capture la fecha fin'"
                      [min]="filtros.fechaInicio"
                      [max]="hoy"
                      [(ngModel)]="filtros.fechaFin"
                      (ngModelChange)="cambioFin($event, 'reg')"
                    />
                    
                  </li>
                </ol>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Fechas movimiento
                  <span *ngIf="contabilizadorDeFiltros('fechasM') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasM') }}</span>

                </mat-expansion-panel-header>
                <ol class="lista-filtros-side">
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha inicio'"
                      [placeHolder]="'Capture la fecha inicio'"
                      [max]="filtros.fechaMovFin ? filtros.fechaMovFin : hoy"
                      [(ngModel)]="filtros.fechaMovInicio"   
                      (ngModelChange)="cambioInicio($event, 'mov')"
                    />
                   
                  </li>
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha fin'"
                      [placeHolder]="'Capture la fecha fin'"
                      [min]="filtros.fechaMovInicio"
                      [max]="hoy"
                      [(ngModel)]="filtros.fechaMovFin"
                      (ngModelChange)="cambioFin($event, 'mov')"
                    />
                    
                  </li>
                </ol>
              </mat-expansion-panel>
            </mat-accordion>
          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filterModal(true);">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="filterModal(false)">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>