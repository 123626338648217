<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            *ngIf="sessionData.isSuperAdmin"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="filters.financiera" class="notification-dot-filtros notification-dot-accion">1</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="this.loadProductos(this.filters.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="detalleSucursal(itemsSelected[0])">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
    </mat-menu>
    
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="loadings.productos"
      [columns]="tabla.columns"
      [items]="productos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (onLinkRow)="onLinkRow($event)"
      (sortChange)="sortChange($event)"
      (filtersChange)="filtersChange($event)"
      (searchChange)="searchChange($event)"
      (onDownload)="download()"
      (onDoubleClick)="onDoubleClick($event)"
    >

    <div t-header>
      <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
         <p><mat-icon color="primary">help</mat-icon> 
          El módulo "Permisos por producto" es una herramienta crucial en el sistema para administrar y controlar el acceso de los usuarios a la información de las cuentas ligadas a su respectivo producto. Este módulo permite establecer permisos específicos a nivel de producto, ofrece un listado completo de todos los productos disponibles en el sistema a nivel general. Permite a los administradores del sistema delimitar los permisos de los usuarios para que solo puedan acceder a la información de las cuentas asociadas a los productos para los cuales tienen permiso. Esto es crucial para mantener la seguridad y la relevancia de la información accesible a cada usuario.
        </p> 
         Acciones:
         <p>
           <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar:</b> Mejora la búsqueda y el acceso a los conceptos, permitiendo aplicar filtros basados en diversos criterios.
         </p>
         <p>
           <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar:</b> Te permite recargar tu listado con los criterios de filtros aplicados.
         </p>
         <p>
           <mat-icon class="kobra-icon">edit</mat-icon><b> Editar:</b> Los administradores pueden configurar y modificar productos a los que tendrán permisos los usuarios, lo que ofrece una gestión de acceso personalizada y adaptada a las necesidades del negocio y del personal.

         </p>
         <p>
          Nota: Si no se configura ningún permiso específico en este módulo, por defecto, todos los usuarios tienen permisos para acceder a la información de todos los productos. Esta configuración predeterminada asegura que no haya interrupciones en el acceso a la información a menos que se requiera una restricción específica.
        </p>
       </mat-card-content>
     </mat-card> 
   </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="datosConsulta.rows"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'idCliente'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="idCliente"
              (ngModelChange)="onChangeLender($event)"
            >
            </mat-select-search>
           
          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter();">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>