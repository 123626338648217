import { Injectable } from "@angular/core";

@Injectable()
export class LoaderService {
  private httpStack:number[] = [];

  public httpLoader:boolean = false;
  public message: string;

  public showHttpLoader( message: string = 'Cargando...' ): void {
    this.httpStack.push(1);
    this.httpLoader = true;
    this.message = message;
  }

  public hideHttpLoader(): void {
    this.httpStack.splice(0, 1);
    this.httpLoader = this.httpStack.length ? true : false;
  }
}