 
  <div class="app content kobra">
  
    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>Nueva Etiqueta</mat-card-title>
      </mat-card-header>
      <mat-card-content class="kobra-card-content">
        <form [formGroup]="etiquetaForm">
          <mat-grid-list-responsive [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="4:1" *ngIf="sessionData.isSuperAdmin">
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-select-search
                style="width: 95%"
                class="id-card-field pago-gestor"
                [label]="'Cliente'"
                [placeHolder]="'Selecciona un cliente'"
                [value]="'idCliente'"
                [labelField]="'nombre'"
                [icon]="'account_balance'"
                [options]="lenders"
                [disabled]="loadings.financieras"
                formControlName="idCliente"
                [(ngModel)]="etiquetaClienteVisita.idCliente"
                (ngModelChange)="loadProducts()"
              >
              </mat-select-search>
            </mat-grid-tile>
          </mat-grid-list-responsive>

          <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px">
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-select-search
                style="width: 95%"
                class="id-card-field pago-gestor"
                [label]="'Producto'"
                [placeHolder]="'Selecciona un producto'"
                [value]="'idProducto'"
                [labelField]="'nomProducto'"
                [icon]="'lightbulb_circle'"
                [options]="products"
                [disabled]="loadings.productos"
                formControlName="idProducto"
                [(ngModel)]="etiquetaClienteVisita.idProducto"
              >
              </mat-select-search>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-select-search
                style="width: 95%"
                class="id-card-field pago-gestor"
                [label]="'Etiqueta'"
                [placeHolder]="'Selecciona una etiqueta'"
                [value]="'idEtiquetaVisita'"
                [labelField]="'etiquetaVisita'"
                [icon]="'sell'"
                [options]="etiquetas"
                [disabled]="loadings.etiquetas"
                formControlName="idEtiquetaVisita"
                [(ngModel)]="etiquetaClienteVisita.idEtiquetaVisita"
              >
              </mat-select-search>
            </mat-grid-tile>
          </mat-grid-list-responsive>
        </form>

        <div class="button-center">
          <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" routerLink="../" queryParamsHandling="preserve" [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos">
            <mat-icon class="kobra-icon">
              close
            </mat-icon> Cancelar</button>
          <button mat-stroked-button class="kobra-button" type="submit" (click)="onSubmit()" [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos">
            <mat-icon>
              save
            </mat-icon> Guardar</button>
        </div>
      </mat-card-content>
      <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadings.financieras || loadings.productos || loadings.etiquetas">
        <i *ngIf="loadings.financieras">Obteniendo clientes...</i>
        <i *ngIf="loadings.productos">Obteniendo productos...</i>
        <i *ngIf="loadings.etiquetas">Obteniendo etiquetas...</i>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
  