<div class="app content kobra">
  <mat-card>
    <!-- <mat-card-header>
      <mat-card-title>Sucursales</mat-card-title>
    </mat-card-header> -->
    <mat-card-content style="max-height: 550px;">
      <div class="d-flex justify-content-between" style="gap: 10px;">
        <mat-form-field style="width: 40%;">
          <mat-label>Productos</mat-label>
          <mat-select [(ngModel)]="producto">
            <mat-option *ngFor="let producto of productos" [value]="producto">
              {{producto.nomProducto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-stroked-button class="boton-accion-front"  (click)="agregarProducto()">
          <mat-icon >add</mat-icon> Agregar
        </button>
      </div>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Productos Vinculadas</mat-card-title>
        </mat-card-header>
        <mat-card-content style="max-height: 380px; overflow: auto;">
          <app-table 
            [loading]="loadings.productos"
            [columns]="tabla.columns"
            [items]="productosGrid"
            [headers]="tabla.headers"
            [tableHeaders]="tabla.tableHeaders"
            [ignoreSortChange]="true"
            [multiSelect]="tabla.multiSelect"
            (onLinkRow)="onClick($event)"
              >
          </app-table>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
    <mat-card-actions align="end" style="gap: 10px;">
      <button class="boton-cancelar" mat-flat-button (click)="cancel()">
        <mat-icon class="kobra-icon">
          close
        </mat-icon> Cancelar</button>
      <button mat-stroked-button 
        [ngClass]="{'boton-guardar': !compararObjetos(productosGrid, productosGridAux)}" 
        [disabled]="compararObjetos(productosGrid, productosGridAux)" 
        (click)="save()">
        <mat-icon>
          save
        </mat-icon> Guardar</button>
    </mat-card-actions>
  </mat-card>
</div>
