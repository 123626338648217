import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { CobradoresMovimientosService } from '../cobradores-movimientos.service';
import { CobradoresFacturacionesPeriodosService } from '../../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { CobradorFacturacionPeriodo } from '../../cobradores-facturaciones-periodos/cobrador-facturacion-periodo.interface';
import { map, take } from 'rxjs/operators';
import { ConceptoSaldo } from '../../conceptos-saldos/conceptos-saldo.interface';
import { ConceptosSaldosService } from '../../conceptos-saldos/conceptos-saldos.service';
import { fromEvent, Subscription } from 'rxjs';
import { CobradorMovimiento, Cobrador } from '../cobrador-movimiento.interface';
import { AgentService } from '@agent/agent.service';

@Component({
  selector: 'app-cobradores-movimientos-form',
  templateUrl: './cobradores-movimientos-form.component.html',
  styleUrls: ['./cobradores-movimientos-form.component.css'],
  providers: [
    LenderService
  ]
})
export class CobradoresMovimientosFormComponent {

  public hijosActivos: boolean = false;
  public title = 'Agregar';
  public movimientoForm: FormGroup;
  public lenders: Lender[];
  get movimiento(): CobradorMovimiento {
    return this.movServ.movimiento;
  }

  public filtros = {
    idCobradorFacturacionPeriodo: null,
    nombrePeriodo: null,
    idCobrador: null,
    nombreCobrador: null,
    idConcepto: null,
    nombreConcepto: null,
    movStat: null,
    fechaInicio: null,
    fechaFin: null,
    page: 1,
    agenteActivo: null
  }

  conceptos: any;

  @ViewChild('periodosInput') private periodosInput: any;

  private periodoLabelSelected: string;
  public hoy = new Date();
  public isCargo: boolean;

  @ViewChild('cobradoresInput') private cobradoresInput: any;

  private cobradorLabelSelected: string;

  @ViewChild('conceptosInput') private conceptosInput: any;

  private conceptoLabelSelected: string;

  constructor(
    public movServ: CobradoresMovimientosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private app: ApplicationService,
    private lenderService: LenderService,
    private agenteServ: AgentService,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private conceptosServ: ConceptosSaldosService,
  ) {

    this.movimientoForm = this.fb.group(
      {
        concepto: [null, []],
        idCobradorFacturacionPeriodo: ['', Validators.required],
        idCobrador: ['', Validators.required],
        idConcepto: ['', Validators.required],
        isCargo: [''],
        nombreMovimiento: ['', Validators.required],
        monto: ['', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)]]
      }
    );

  }



  checkControl(control: string) {
    return this.movimientoForm.controls[control].invalid && this.movimientoForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.movimientoForm.controls[control].hasError('required') ? 'Este campo es requerido.' :
      this.movimientoForm.controls[control].hasError('pattern') ? 'Solamente valores numéricos' : '';
  }



  loadLenders(): void {
    this.lenderService.getAll('all').subscribe((lenders) => {
      lenders.sort(this.compare);
      this.lenders = lenders;
    }, (err: any) => {
      this.app.showError(err);
    });
  }

  onPeriodoSelected(periodo: CobradorFacturacionPeriodo) {
    // this.periodosFlag = true;
    this.movimientoForm.get('idCobradorFacturacionPeriodo').value;

  }

  resetPeriodo() {
    this.movimientoForm.get('idCobradorFacturacionPeriodo').reset();
    this.periodosInput._element.nativeElement.firstChild.firstChild.value = '';
    this.periodoLabelSelected = undefined;
  }



  public periodosSearch = function (term: string) {

    const query = `?nombre=${term}`;

    return this.periodoServ.obtenerPeriodos(query)
      .pipe(
        take(1),
        map((res: any) => res.data.map(

          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: nombre }
          )
        ))
      );
  }.bind(this);

  public agenteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAgentes(term, null)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
      );
  }.bind(this);

  public buscarConceptos = function (term: string) {

    if (this.conceptosFilterFlag) {
      this.conceptosFilterFlag = false;
      return this.conceptosFilterFounded;
    }
    return this.conceptosServ.buscarConceptos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idConcepto, nombre, idTipoConcepto, cargo }) => (
            { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto, cargo: cargo }
          )
        ))
      );
  }.bind(this);



  onCobradorSelected(cobrador: Cobrador) {
    this.movimientoForm.get('idCobrador').patchValue(cobrador.idCobrador);
    this.cobradoresInput._element.nativeElement.firstChild.firstChild.value = cobrador.nombre;
    this.cobradorLabelSelected = cobrador.nombre;
  }

  resetCobrador() {
    this.movimientoForm.get('idCobrador').reset();
    this.cobradoresInput._element.nativeElement.firstChild.firstChild.value = '';
    this.cobradorLabelSelected = undefined;
  }



  onConceptoSelected(concepto: ConceptoSaldo) {
    this.isCargo = concepto ? concepto.cargo : undefined;
    this.movimientoForm.get('idConcepto').patchValue(concepto ? concepto.idConcepto : null);
    this.movimientoForm.get('nombreMovimiento').patchValue(concepto ? concepto.nombre : null);
    this.movimientoForm.get('isCargo').patchValue(this.isCargo);

  }

  resetConcepto() {
    this.isCargo = undefined;

    this.movimientoForm.get('idConcepto').reset();
    this.conceptosInput._element.nativeElement.firstChild.firstChild.value = '';
    this.conceptoLabelSelected = undefined;
  }

  onSubmit() {
    if (this.movimientoForm.valid) {
      const montoValue = this.movimientoForm.get('monto').value;

      if (montoValue && +montoValue <= 0) {
        this.app.showSnackbar('¡Aviso!', 'El monto debe ser mayor a cero.', 3000, 'warning');
        return;
      }

      this.insert();
    } else {
      Object.values(this.movimientoForm.controls).forEach((control) => {
        if (control && control instanceof FormControl) {
          control.markAsTouched();
        }
      });
    }
  }


  insert() {
    this.movServ.agregarMovimiento(this.movimientoForm.value)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
       // console.log(res.success)

        if (res.success) {
          this.app.showSnackbar('¡Aviso!', 'Movimiento agregado correctamente.', 3000, 'success');
          this.movimientoForm.reset()
        } else {
          this.app.showSnackbar('¡Aviso!', 'Error al guardar, revisar los datos.', 3000, 'success');
        }


      }, (error: any) => {
        this.app.showError(error);
      });
  }



  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

}
