
<h1 mat-dialog-title>Seleccionar Cuenta</h1>
<mat-dialog-content>
    <app-table
        [loading]="cargando" 
        [columns]="tablaAcreditados.columnas" 
        [items]="acreditados"
        [headers]="tablaAcreditados.headers" 
        [tableHeaders]="tablaAcreditados.tableHeaders" 
        [ignoreSortChange]="true"
        [multiSelect]="tablaAcreditados.multiSelect" 
        (onSelectedRows)="onSelected($event)">
    </app-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button mat-stroked-button class="boton-accion-front"  *ngIf="acreditadoSeleccionado" (click)="onDone()"><mat-icon>person_add</mat-icon>Seleccionar</button>
</mat-dialog-actions>