<h1 mat-dialog-title>Plantillas</h1>
<mat-dialog-content>
  <alerta-informativa 
    clase="danger"
    titulo="¡Aviso!" 
    texto="Las plantillas mostradas se han visto afectadas por los cambios en el cuestionario. Por favor, verifica y corrige las plantillas correspondientes.">
  </alerta-informativa>
  <div content style="max-height: 250px; overflow: auto;">
    <div *ngFor="let archivo of archivos; let i = index;">                    
      <mat-card class="card">  
        <mat-card-content>
          <div style="display: flex; margin-bottom: 5px;">
            <mat-icon 
              matTooltip="Descargado por el agente"
              mat-tooltip-panel-above
              class="iconoDoc iconoWord kobra-icon margen-icon">
              description
            </mat-icon>
            <span class="nombre-archivo">{{archivo.nombreArchivo}}</span> 
            <div class="iconoGrid">
              <button mat-icon-button       
                matTooltip="Descripción"
                mat-tooltip-panel-above
                (click)="ayudaDescripcion(archivo)">
                <mat-icon color="primary">help</mat-icon>
              </button>
              <button mat-icon-button
                [matMenuTriggerFor]="menu"
                matTooltip="Acciones"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item 
                  (click)="abrirDialogoVariables(archivo)">
                  <mat-icon class="kobra-icon">playlist_add_check</mat-icon>
                  <span>Variables</span>
                </button>
                <button mat-menu-item 
                  (click)="descargarArchivo(archivo)">
                  <mat-icon class="kobra-icon">cloud_download</mat-icon>
                  <span>Descargar</span>
                </button>
              </mat-menu>
            </div> 
          </div>
          <div *ngIf="archivo.isDescripcion" class="margen-icon">
            <span class="letra-negrita">Descripción</span>
            <p class="descripcion">{{archivo.descripcion}}</p>
          </div>
          <div style="display: flex;" class="iconoGrid">
            <i style="color: #38373782;">{{momentFecha(archivo.fechaRegistro).format('DD/MM/YYYY')}}</i>
          </div>
        </mat-card-content>                  
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
</mat-dialog-actions>
<mat-card-footer *ngIf="descargandoArchivo"  class="kobra-card-footer" >
  <i>Descargando archivo...</i>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card-footer>

