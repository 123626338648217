import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { environmentSelector } from '../../../../environments/environment.selector';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { EtiquetasVisitasService } from './../etiquetas-visitas.service';
import { Subscription, Observable } from 'rxjs';
import { IPaginatorGeneral } from '../../../shared/components/table/interfaces/tabla.interface';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { saveAs } from 'file-saver';
import { LoadingService } from '../../../shared/components/loading-bar/loading-bar.service';
import moment from 'moment';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-etiquetas-visitas',
  templateUrl: './etiquetas-visitas.component.html',
  styleUrls: ['./etiquetas-visitas.component.css'],
  providers: [
  ]
})
export class CatalogoEtiquetasVisitasComponent implements OnInit {
  private environment = environmentSelector();

  public saveButton = false;
  private querySub: Subscription;
  filters: any = {
    nombre: null,
    estatus: null,
    page: 1,
    sortField: 'idEtiquetaVisita',
    sortAsc: ''
  };
  filtrosAbiertos: boolean = false;
  hijosActivos: boolean = false;
  ayuda: boolean = false;
  public etiquetaForm: FormGroup;
  public etiquetas$: Observable<any>;
  public loaderText: string = '';
  public filter = false;
  public etiquetas: Array<any>;
  estatus:any;
  nombre:string;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [
        {
            name: 'ID Etiqueta',
            key: 'idEtiquetaVisita',
            hide: true
        },
        {
          name: 'Etiqueta Visita',
          key: 'etiquetaVisita',
          hide: false
        },
        {
          name: 'Estatus',
          type: 'estatus',
          key: 'estatus',
          hide: false
        }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: ApplicationService,
    public etiquetasVisitasService: EtiquetasVisitasService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private http: HttpClient,


  ) {
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
   }

  ngOnInit() {
    this.etiquetasVisitasService.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  ngOnDestroy() {
  }

  inactivarHijo(){
    this.etiquetasVisitasService.filtrosService.asignarOpcion();
    if(this.filter){
      this.etiquetasVisitasService.filtrosService.guardarFiltros(this.filters, true);
    }
    this.etiquetasVisitasService.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  public descargarListado(): void {
    this.loadingService.setLoading(true);

    let queryParams = `?token=${localStorage.getItem('access-token')}`;

    if(this.nombre){
        queryParams += `&nombre=${encodeURIComponent(this.nombre)}`;
    }

    if(this.estatus){
        queryParams += `&activo=${encodeURIComponent(this.estatus)}`;
    }
    
   this.etiquetasVisitasService.descargarEtiquetasVisitas(queryParams).subscribe(event => {
        if (event.type === HttpEventType.Response) {
            this.loadingService.setLoading(false);
            const blob = new Blob([event.body], { type: event.body.type });
            const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
            saveAs(blob, `Etiquetas-visitas-${fechaActual}`);
        }
    }, error => {
        this.loadingService.setLoading(false);
        this.app.showSnackbar(
            "Aviso",
            "Ocurrió un problema al intentar descargar el archivo.",
            3000,
            "error"
        );
        console.log(error);
    });
}


  
  private agregarContabilizadoresDeFiltros(){
   
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'estatus',
        'nombre'
       
    ]);

  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }
  

  public contabilizadorDeFiltrosTotales():void{
      

      let totalFiltrosArray: number[] = [];
    
      totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );


      this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  resultadoFiltros(filtrosNuevos:any) {
    if(filtrosNuevos){
      this.filter = true;
      this.filters = filtrosNuevos;

      if(this.filters.nombre){
        this.nombre = this.filters.nombre;
      }
  
      if(this.filters.estatus){
        this.estatus = this.filters.estatus;
      }
    }

    this.etiquetasVisitasService.obtenerEtiquetas(this.filters.page || 1,
      this.filters);
  }

  consultarEtiquetas(page: number){
    
    if(this.filters.nombre !== null){
      this.nombre = this.filters.nombre;
    }

    if(this.filters.estatus !== null){
      this.estatus = this.filters.estatus;
    }
    this.etiquetasVisitasService.filtrosService.guardarFiltros(this.filters);
    this.etiquetasVisitasService.obtenerEtiquetas(this.filters.page || 1,
      this.filters);
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.etiquetasVisitasService.pagination.page || newPagina.pageSize !== this.etiquetasVisitasService.pagination.pageSize){
      this.etiquetasVisitasService.pagination.pageSize = newPagina.pageSize;
      this.etiquetasVisitasService.obtenerEtiquetas(newPagina.pageIndex+1,
        this.filters);
    }
  }

  sortChange(sort: any) {
    this.filters.sortAsc = sort.direction;
    if (sort.active != this.filters.sortField) {
      this.filters.sortField = sort.active;
    }
    this.etiquetasVisitasService.obtenerEtiquetas(1, this.filters);
  }

  limpiarFiltros(){
    this.filters = {
      nombre: null,
      estatus: null,
      page: 1,
      sortField: 'idEtiquetaVisita',
      sortAsc: ''
    };
    this.filter = false;
    //this.etiquetasVisitasService.filtrosService.guardarFiltros(this.filters);
    //this.etiquetasVisitasService.obtenerEtiquetas(1, this.filters);
  }

  setFilter(){
    this.filter = true;
    this.etiquetasVisitasService.obtenerEtiquetas(1, this.filters);
    this.contabilizadorDeFiltrosTotales();
  }

  openFilterModal() {
    /*const config = new TemplateModalConfig<{}, string, string>(this.modalFilter);
    config.closeResult = 'closed';
    config.size = 'small';

    this.modalService
      .open(config)
      .onApprove(result => {
        Object.keys(this.filters).forEach(key => {
          if(key != 'page' && this.filters[key]){
            this.filter = true;
          }
        });

        if (this.filter) {
          this.etiquetasVisitasService.filtrosService.guardarFiltros(this.filters);
          this.filters.page = 1
          this.etiquetas$ = this.etiquetasVisitasService.obtenerEtiquetas(1, this.filters);
        }
      })
      .onDeny(result => {
        if (result === 'denied') {
          this.filter = false;
          Object.keys(this.filters).forEach(key => {
            this.filters[key] = null;
          });
          this.filters.page = 1;
          this.etiquetasVisitasService.filtrosService.removerFiltros();
          this.etiquetas$ = this.etiquetasVisitasService.obtenerEtiquetas(1, this.filters);
        }
      });*/
  }
}
