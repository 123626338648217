import { Bank } from '../../bank/bank.model';

export class AgentBank {
  id: number;
  agentID: number;
  account: string;
  clabe: string;
  bankID: number;
  bank: Bank;

  constructor() {
    this.bank = new Bank();
  }

  public static map( data:any): AgentBank{
    var instance: AgentBank = new AgentBank();
    instance.bank = new Bank();
    instance.id = data.id;
    instance.account = data.account ? data.account : data.cuentaBancaria ? data.cuentaBancaria : null;
    instance.clabe = data.clabe;
    instance.bankID = data.bankID ? data.bankID : data.idBanco ? data.idBanco : null;
    instance.bank.id = data.bankID ? data.bankID : data.idBanco ? data.idBanco : null;
    instance.bank.name = data.bankName ? data.bankName : data.nombre ? data.nombre : null;
    return instance;
  }
}