<div class="app content">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="kobra-card-content">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Ejecutivos 360</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="kobra two columns">
            <div class="kobra column">
              <mat-select-search
                class="id-card-field"
                [label]="loadings.administradoresFinanciera ? 'Cargando Administradores...' : 'Ejecutivo 360'"
                [placeHolder]="'Selecciona un ejecutivo 360'"
                [value]="'option'"
                [labelField]="'nombreCompleto'"
                [icon]="'account_balance'"
                [options]="administradores"
                [(ngModel)]="adminFinanciera">
              </mat-select-search>
            </div>
            <div class="kobra column">
              <div class="action-buttons" align="left">
                <button mat-stroked-button class="boton-accion-front" (click)="agregarAdministrador()">
                  <mat-icon>add</mat-icon>
                  Agregar
                </button>
              </div>
            </div>
          </div>
          <div>
            <h4>Ejecutivos encargados</h4>
            <mat-divider></mat-divider>
            <div style="margin: 10px 0">
              <app-table [loading]="loadings.administradoresEncargados" [columns]="tablaAdministradores.columnas"
                [items]="administradoresEncargados" [headers]="tablaAdministradores.headers"
                [tableHeaders]="tablaAdministradores.tableHeaders" [ignoreSortChange]="true"
                (onLinkRow)="borrarAdministrador($event)"
                [multiSelect]="tablaAdministradores.multiSelect">
              </app-table>
            </div>

          </div>
        </mat-card-content>

      </mat-card>
      <div class="button-center">
        <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()">
          <mat-icon class="kobra-icon">
            close
          </mat-icon> Cancelar</button>
        <button mat-stroked-button class="kobra-button" type="submit" (click)="save()">
          <mat-icon>
            save
          </mat-icon> Guardar</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
