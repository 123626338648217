export class Admin {
  nombre: string | null;
  apellidoPaterno: string | null;
  apellidoMaterno: string | null;
  email: string | null;
  isAdmin: boolean | null;
  isSuperAdmin: boolean | null;
  idFinanciera: number | null;
  idCliente: number | null;
}
