import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { PaquetesSaldosService } from './paquetes-saldos.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, take, catchError } from 'rxjs/operators';
import {ConfirmacionDialog} from "../../modales-genericos/confirmacion/confirmacion-dialog.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-paquetes-saldos',
  templateUrl: './paquetes-saldos.component.html',
  styleUrls: ['./paquetes-saldos.component.css']
})
export class PaquetesSaldosComponent implements OnInit {
  private querySub: Subscription;
  public selectedRow: any;
  public loaderText: string;
  hijosActivos: boolean = false;
  public itemsSelected: any[] = [];
  ayuda: boolean = false;
  paquetes: any;
  pagination: any;
  cargandoLista: boolean = false;
  sortField: string = 'nombre';
  sortAsc: string = '';
  public pageSize = 50;

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [
        {
            name: 'Nombre',
            key: 'nombre',
            hide: false,
            sortName: 'p.nombre'
        },
        {
            name: 'Precio',
            key: 'precio',
            hide: false,
            type: 'money',
            sortName: 'p.nombre'

        }
    ]
}

  constructor(
    private route: ActivatedRoute,
    public paquetesSaldosService: PaquetesSaldosService,
    private router: Router,
    private app: ApplicationService,
    public dialog: MatDialog
  
  ) {
      this.paquetes = [];
      this.pagination = {
        page: 1,
        pageData: null,
        pages: null,
        totalRows: null
      };
   }

  ngOnInit() {
    this.readQueryParams();
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }


  readQueryParams() {
    this.querySub = this.route.queryParams.subscribe((params: Params) => {
      const page: number = params['_page'] || 1;

      this.obtenerPaquetes(page);
    });
  }

  setSortField(sort: any){
    this.sortAsc = sort.direction;
    if (sort.active != this.sortField) {
      this.sortField = sort.active;
    }
    this.obtenerPaquetes(1);
  }

  public obtenerPaquetes(page: number) {
    this.cargandoLista = true;

    this.paquetesSaldosService.obtenerPaquetes(page, this.sortField, this.sortAsc).subscribe(
      (res: any) => {
        if(res.success){
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          for (let i = 0; i < datos.length; i++) {
            datos[i].cargo = datos.cargo ? 'Abono' : 'Cargo';
          } 
          this.paquetes = datos;
        }
        this.cargandoLista = false;
        
      },(error) => {
            this.app.showError(error);
            this.cargandoLista = false;
        },
    );
  }



  onAdd() {
    this.router.navigate([`catalogos/paquetes/nuevo`], {
      queryParamsHandling: 'preserve'
    });
  }

  onEdit(registroSeleccionado: any) {
      const msg = 'Debes seleccionar un registro para editarlo.';

      if( registroSeleccionado ) {

          if( registroSeleccionado.idPaquete !== 0 && !(registroSeleccionado.idPaquete > 0)) {
              return this.app.showSnackbar('¡Aviso!', 'Este paquete no es editable.', 3000, 'warning');
          }

          this.router.navigate(['catalogos/paquetes/editar'], { queryParams: { idPaquete: registroSeleccionado.idPaquete } });
      } else {
          this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
      }
  }

  onDoubleClick({ row }) {
    this.onEdit(row);
  }

  onDelete(registroSeleccionado: any) {
    const msg = 'Debes seleccionar un registro para borrarlo.';

    if( registroSeleccionado ) {
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas borrar el paquete?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                texto: registroSeleccionado.nombre,
                claseAccion: "boton-accion-eliminar"
            }
        });
  
        dialogRef.afterClosed().subscribe(result => {
  
            if(result) {
              this.borrarPquete(registroSeleccionado);
            }  
        });
    } else {
      this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }

    
  }

  borrarPquete(registroSeleccionado: any) {
    const loading = this.app.showLoading(
      `Elminado paquete...`
    );
   
    this.paquetesSaldosService.borrarPaquete(registroSeleccionado.idPaquete)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Paquete elminado correctamente.', 3000, 'success');
        this.obtenerPaquetes(1);
        /*this.router.navigate([`catalogos/paquetes`], {
          queryParamsHandling: 'preserve'
        });*/
      }, (error: any) => {
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.pagination.pagination.page || newPagina.pageSize !== this.pageSize){
        this.pageSize = newPagina.pageSize;
        this.obtenerPaquetes(newPagina.pageIndex+1);
    }
  }

  inactivarHijo(){
    setTimeout(() => {
      this.obtenerPaquetes(this.pagination.page);
    }, 150);
  }
}
