import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder } from "@angular/forms";
import { Visit } from '../visit.model';

@Component({
  selector: 'detalle-masiva-dialog.component',
  templateUrl: 'detalle-masiva-dialog.component.html',
  styleUrls: ["detalle-masiva-dialog.component.css"],
  providers: [
  ]
})

export class ModalVisitaDetalleMasivaComponent {
    public formAsignacion: FormGroup;
    public visitasACalificar: Visit[];
    public idCliente: number;
    public idFinanciera: number;
    constructor(
        public dialogRef: MatDialogRef<ModalVisitaDetalleMasivaComponent>,
        
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.visitasACalificar = [];
        this.visitasACalificar = data.visitasACalificar;
        this.idCliente = data.idCliente;
        this.idFinanciera = data.idFinanciera;
    }

    salirCalificar(): void {
        this.dialogRef.close(false);
    } 

}
