import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { currentEnvironment } from '../../environments/current/current-environment';
import { LoginService } from './login.service';

declare var $: any;
@Component({
  selector: 'validacion-token',
  templateUrl: 'validacion-token.component.html',
  styleUrls: [],
  providers: [LoginService]
})

export class ValidacionTokenComponent implements OnInit {
  private respuestaIPBounded: Function;
  private respuestaIPErrorBounded: Function;
  private paisActual = currentEnvironment.paises[0];
  private paises = currentEnvironment.paises;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loginService: LoginService,
  ) {}

  private asignarPaisActual(abreviacion:string = "mx"){
    let abreviacionValidar = abreviacion.toLocaleLowerCase();
    this.paises.forEach(pais => {
      if(pais.abreviacion == abreviacion){
        this.paisActual = pais;
      }
    });
  }

  ngOnInit() {
    this.respuestaIPBounded = this.respuestaIP.bind(this);
    this.respuestaIPErrorBounded = this.respuestaIPError.bind(this);
    $.get("https://ipinfo.io", this.respuestaIPBounded, "jsonp").fail(this.respuestaIPErrorBounded);
  }

  respuestaIPError(){
    this.respuestaIP({country: 'mx'});
  }

  respuestaIP(response){
    this.asignarPaisActual(response.country);
    this.activeRoute.queryParams.subscribe(params => {
      const { next = '/', token, cc } = params;
      var parametros = {};

      Object.keys(params).map(function(key, index) {
        if(key != 'token' && key != 'next' && key != 'cc'){
          parametros[key] = params[key];
        }
      });

      if(cc){
        this.asignarPaisActual(cc);
      }
      this.loginService.reloadBaseUrl(this.paisActual.abreviacion);

      if (Boolean(token)) {
        let subs = (cc ? this.loginService.logIn(token, cc) : this.loginService.logIn(token))
        .subscribe(
          res => {
            localStorage.setItem('userID', res.data.idUsuario.toString());
            localStorage.setItem('access-token', res.data.token);
            localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
            if(parametros){
              this.router.navigate([next], {queryParams: parametros}).then(()=>{
                if(cc){
                  document.location.reload();
                }
              });
            }
            else{
              this.router.navigate([next]).then(()=>{
                if(cc){
                  document.location.reload();
                }
              });
            }
          },
          err => {
            this.router.navigate(['/auth']);
            subs.unsubscribe();
          },
          () => subs.unsubscribe()
        );
      } else {
        this.router.navigate(['/auth']);
      }
    });
  }
}
