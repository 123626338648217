<div class="app content tabla kobra">
  <div class="contenedor-toggle">
    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
      <mat-button-toggle value="actualizar"
        (click)="obtenerArchivos();"
        matTooltip="Refrescar"
        mat-tooltip-panel-above>
        <mat-icon class="kobra-icon">sync</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="subMenu"  
        matTooltip="Acciones"
        mat-tooltip-panel-above
        [matMenuTriggerFor]="menu">
        <mat-icon class="kobra-icon">more_vert</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="ayuda" 
        matTooltip="Ayuda"
        mat-tooltip-panel-above
        (click)="ayuda = !ayuda;">
        <mat-icon color="primary">help</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
    <!-- <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="actualizar"
            (click)="obtenerArchivos();"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div> -->
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openDialogVariables()">
        <mat-icon class="kobra-icon">playlist_add_check</mat-icon>
        <span>Variables</span>
      </button>
      <button mat-menu-item (click)="openDialogCargarArchivo()">
        <mat-icon class="kobra-icon">cloud_upload</mat-icon>
        <span>Cargar nueva plantilla</span>
      </button>
      <button [disabled]="itemsSelected.length == 0" mat-menu-item (click)="descargarArchivo(itemsSelected[0])"> 
        <mat-icon class="kobra-icon">cloud_download</mat-icon>
        <span>Descargar plantilla</span>
      </button>
      <button [disabled]="itemsSelected.length == 0" mat-menu-item (click)="openDialogConfirmacion(itemsSelected[0])">
        <mat-icon color="warn">delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoArchivos"
      [columns]="tablaArchivos.columns"
      [items]="archivos"
      [headers]="tablaArchivos.headers"
      [multiSelect]="tablaArchivos.multiSelect"
      (onSelectedRows)="onSelected($event)"
    >

      <div t-header>     
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-header>
            <mat-card-title>Ayuda</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div style="margin-left: 10px; margin-right: 10px; margin-top: 10px;">
              <p>En esta opción podrás subir tus plantillas con extensión ".docx" para que se generen en automático con la información de la cuenta.</p>
              <p>¿Cómo le hacemos? A continuación se enlistan los pasos para que puedas configurar tus plantillas:</p>
              <ol>
                <li>
                  <p>Crea tu plantilla en Word y donde quieras que se llene la información dinámica la vas a reemplazar con las variables que te enlistamos en el catálogo de variables.</p>
                </li>
                <li>
                  <p>Sube tus plantillas ya listos con las variables correspondientes y listo, en cuanto guardes todas las cuentas con este producto podran generar estos documentos.</p>
                </li>
              </ol>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </app-table>
</div>
