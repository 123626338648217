<div *ngIf="!ready && !error" class="d-flex justify-content-center text-dark">
  <div class="ui huge active centered inline loader"></div>
</div>

<!-- <canvas
  *ngIf="ready && showChart && chartData?.length > 0 && chartType!='table'"
  baseChart
  responsive
  style="position: relative; height: 100% !important; width: 100% !important; background-color: white;"
  [datasets]="chartData"
  [labels]="chartLabels"
  [options]="chartOptions"
  [datasets]="barChartData"
  [chartType]="chartType"
  [plugins]="barChartPlugins"
>
</canvas> -->

<div *ngIf="ready && showChart && !chartData?.length" style="font-size: 10pt;">
  <br>
  <div class="icon-medium center"><i class="thumbs down icon"></i></div>
  <br>
  <div *ngIf="isVisible" class="center">No hay datos que mostrar.</div> 
  <div *ngIf="!isVisible" class="center">No tiene permisos para visualizar.</div> 
</div>
  
<div *ngIf="ready && !showChart && chartType!='table'">
  
    <!-- <div class="label-title" >{{ chartData }}</div>  
    
    <div class="label-custom">
    {{ title }}
    </div> -->
    <div class="singleValue">
      {{ chartData }}
    </div>
  
</div>

<div *ngIf="error">
  <div class="singleValue">
    Error al cargar el informe, favor de intentar más tarde.
  </div>
</div>

<div *ngIf="ready && !showChart && chartType=='table'" style="width: 100%; height: 100%;">
  <table id="tableData" class="ui selectable celled table" style="font-size: 10pt;">
    <thead>
      <tr>
        <th *ngFor="let title of tableTitles" class="ui center aligned" [innerHTML]="title"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of tableData">
      <td *ngFor="let column of row" [class]="column.classes">{{ column.value }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
          <th *ngIf="tableData?.length > 0" [attr.colspan]="tableData[0].length"></th>
        </tr>
        <tr *ngFor="let row of tableFoot;">
          <td *ngFor="let column of row; first as isFirst;" [class]="column.classes" [attr.colspan]="!isFirst ? column.colspan : 0">{{ column.value }}</td>
        </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="ready && !showChart && chartType=='tree'" style="width: 100%; height: 100%;">
  <mat-tree #tree [dataSource]="treeDataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <div class="tree-label">
        <span>{{node.name}}</span>
        <span>{{node.value}}</span>
      </div>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node;when: treeHasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <div class="tree-label">
        <span>{{node.name}}</span>
        <span>{{node.value}}</span>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>

  



