import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { LoaderService } from "../../../../loader/loader.service";
import { MessageService } from "../../../../shared/message/message.service";
import { WorkReference } from "../work-reference.model";
import { WorkReferenceService } from "../work-reference.service";
import { WorkReferenceModalFormComponent } from "../work-reference-modal-form/work-reference-modal-form.component";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ApplicationService } from 'src/app/application/shared/application.service';

declare let $: any;

@Component({
  selector: 'work-reference-editable-list',
  templateUrl: './work-reference-editable-list.component.html',
  styleUrls: ['./work-reference-editable-list.component.css'],
  providers: [
    WorkReferenceService
  ]
})
export class WorkReferenceEditableListComponent implements OnInit, OnChanges {
  private $form: any;
  public reference: WorkReference | null;
  public references: WorkReference[] = [];
  public showSegmentLoader: boolean = false;
  public sessionData: SessionData;
  cargandoReferenciaLaboral: boolean = true;
  isRegistros = 0;
  @Input() loader: boolean;
  @Input() agentID: number;
  @Input() deleted: boolean;

  ngOnChanges() {
    this.reference = new WorkReference();
    this.loadReferences(this.agentID);
  }

  @ViewChild(WorkReferenceModalFormComponent) private workReferenceModalFormComponent: WorkReferenceModalFormComponent;

  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private workReferenceService: WorkReferenceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private app: ApplicationService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
   }

  ngOnInit() {
    this.$form = $('.ui.form.modal');
  }

  public addReference( workReference: WorkReference | null ): void {
    if(workReference != null){
      for (let i = 0; i < this.references.length; i++) {
        if (this.references[i].id == workReference.id) {
          this.references.splice(i, 1, workReference);
          workReference = null;
          break;
        }
      }
    }

    if (workReference) {
      this.references.push(workReference);
    }
  }

  public deleteReference( workReference: WorkReference ): void {
    let index = this.references.findIndex(referencia => referencia.id == workReference.id);
    this.references.splice(index, 1);
  }

  public hideForm(): void {
    //this.$form.modal('hide');
  }

  private hideLoader(): void {
    if (this.loader) {
      this.loaderService.hideHttpLoader();
    } else {
      this.showSegmentLoader = false;
    }
  }

  public loadReferences( agentID: number ) {
    this.workReferenceService.all(agentID).subscribe(
      (references) => {
        this.isRegistros = references.length;
        this.references = references;
        this.cargandoReferenciaLaboral = false;
      },
      (error) => {
        this.cargandoReferenciaLaboral = false;
        this.messageService.httpError(error);
      }
    );
  }

  public showForm( reference?: WorkReference ): void {
    this.reference = reference || new WorkReference();
    const dialogRef = this.dialog.open(WorkReferenceModalFormComponent, {
      disableClose: false,
      width: '50%',
      data: {
        workReference: this.reference,
        agentID: this.agentID,
        totalReferencias: this.references?.length,
        afterSave: this.addReference.bind(this),
        afterDelete: this.deleteReference.bind(this),
        nuevo: false
      }
      
    });

    dialogRef.afterClosed().subscribe(data => {});

    //this.workReferenceModalFormComponent.show();
  }

  eliminar(reference: WorkReference){
    const loader = this.app.showLoading('Eliminando referencia laboral...')
    reference.totalReferenciasLaborales = this.references?.length - 1;
    this.workReferenceService.delete(reference.id, reference).subscribe({
        next: () => {
          this.app.showSnackbar('¡Aviso!', 'Referencia laboral eliminada correctamente.', 3000, 'success');
          this.app.hideLoading(loader);
          this.deleteReference(reference);
        },
        error: (error) => {
            this.app.hideLoading(loader);
            this.app.showError(error);
        }
    });
  }
}
