<div class="timeline">
  <div class="timeline-bar"></div>
  <!-- ngFor para iterar sobre las experiencias laborales -->
  <div *ngFor="let item of historialNoDisponible; index as e" class="timeline-inner clearfix">
    <!-- Verifica el índice para alternar entre izquierda y derecha -->
    <div class="timeline-box" [ngClass]="{'timeline-box-left': e % 2 === 0, 'timeline-box-right': e % 2 !== 0}">
      <span class="dot"></span>
      <div class="timeline-box-inner animate-right animated">
        <span class="arrow"></span>
        <div class="date">{{item.createdAt  | date:'dd/MM/yyyy HH:mm'}} </div>
        <div style="text-align: center;">
          <span style="color: #414141; font-weight: bold;">Disponible</span>
          <p class="p-time-line">{{item.disponible?'Sí':'No'}}</p>
        </div>
        <div *ngIf="item.nombre" style="text-align: center;">
          <span style="color: #414141; font-weight: bold;">Motivo</span>
          <p class="p-time-line">{{item.nombre?item.nombre:'N/A'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

