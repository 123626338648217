import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { WorkReference } from "./work-reference.model";
import { map } from 'rxjs/operators';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';

@Injectable()
export class WorkReferenceService {

  constructor(
    private leanServ: ServiciosKobraService,
  ) { }

  public add( reference: WorkReference ): Observable<number> {
    return this.leanServ.post(`/cobradores/${reference.agentID}/referencia-laboral`, reference).pipe(
      map(res => res.data)
    );
  }

  public all(agentID: number): Observable<WorkReference[]> {
    return this.leanServ.get(`/cobradores/${agentID}/referencia-laboral?deleted=0`).pipe(
      map((res) => {
        // return res.json() as WorkReference[];
          let referencesData = res.data;
          let references: WorkReference[] = [];
          for (let i = 0; i < referencesData.length; i++) {
            let reference: WorkReference = new WorkReference();
            reference.id = referencesData[i].id;
            reference.name = referencesData[i].name;
            reference.surname = referencesData[i].surname;
            reference.fullName = referencesData[i].fullName;
            reference.company = referencesData[i].company;
            reference.phoneNumber = referencesData[i].phoneNumber;
            reference.phoneTypeID = +referencesData[i].phoneTypeID;
            references.push(reference);
          }
          return references;
        })
    );
  }

  public delete( id: number, reference: WorkReference ): Observable<any> {
    reference.deleted = 1;

    return this.leanServ.patch(`/cobradores/referencia-laboral/${id}`, reference).pipe(
      map(res => res.data)
    );
  }

  public update( reference: WorkReference ): Observable<number> {
    reference.deleted = 0;

    return this.leanServ.patch(`/cobradores/referencia-laboral/${reference.id}`, reference).pipe(
      map(res => res.data)
    );
  }
}