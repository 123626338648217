import { ItemTabla } from '../../../shared/components/table/modelos/tabla-parametros.model';

export class CuentaAgrupada extends ItemTabla {
  idCliente: number;
  cliente: string;
  idExterno: string;
  idAcreditado: number;
  nombre: string;
  fechaRegistro: Date;
  fechaImportacion: Date;
  visibilidad: string
  detalle: any[] = [];
  
  constructor() {
    super();
  }

  public static map(data: any): CuentaAgrupada {
    let instance = new CuentaAgrupada();
    instance.idExterno = data.idExterno;
    instance.idCliente = data.idCliente;
    instance.cliente = data.cliente;
    instance.idAcreditado = data.idAcreditado;
    instance.nombre = data.nombre;
    instance.fechaRegistro = data.fechaRegistro;
    instance.fechaImportacion = data.fechaImportacion;
    instance.visibilidad = data.visibilidad;
    
    return instance;
  }
}
