<mat-form-field style="width: 100%;">
    <mat-label>{{label}}</mat-label>
    <input matInput textSanitizer 
        readonly
        type="text"
        placeholder="{{placeHolder}}"
        [(ngModel)]="fechaFormateada"
    />
    <input matInput textSanitizer 
        [matDatepicker]="datepickerFormater"
        [(ngModel)]="valorControl"
        (ngModelChange)="formatearFecha()"
        [max]="max"
        [min]="min"
        [disabled]="disabled"
        readonly hidden>
    <mat-icon class="clear-icon" *ngIf="hasValue() && limpiar" (click)="clearValue()">clear</mat-icon>
    <mat-datepicker-toggle  matSuffix class="toogle-fecha" [for]="datepickerFormater"></mat-datepicker-toggle>
    <mat-datepicker #datepickerFormater></mat-datepicker>
</mat-form-field>
