<app-modal [title]="'Experiencia Laboral'" (onClose)="onClose()" [onlyClose]="true">
  <div *ngIf="form" class="container">
    <form [formGroup]="form" (submit)="save()">
      <div class="kobra two columns">
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: companyValidation.hasError()}">
            <mat-label>Empresa</mat-label>
            <input matInput textSanitizer  formControlName="company"
              placeholder="Ingrese el nombre de la empresa">
            <mat-icon matSuffix>enterprise</mat-icon>
            <mat-error>{{companyValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: titleValidation.hasError()}">
            <mat-label>Puesto</mat-label>
            <input matInput textSanitizer  formControlName="title"
              placeholder="Ingrese el puesto que desempeñó">
            <mat-icon matSuffix>radar</mat-icon>
            <mat-error>{{titleValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: bossValidation.hasError()}">
            <mat-label>Jefe inmediato</mat-label>
            <input matInput textSanitizer  formControlName="boss"
              placeholder="Ingrese jefe inmediato">
            <mat-icon matSuffix>assignment_ind</mat-icon>
            <mat-error>{{bossValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-date-picker-formater
            class="grid-tile-form-field"
            [ngClass]="{error: startDateValidation.hasError()}"
            [label]="'Fecha de inicio laboral'"
            [placeHolder]="'Capture la fecha en la que inicio a laborar en esta empresa'"
            [min]="minDate" [max]="form.value.endDate ? form.value.endDate : maxDate"
            formControlName="startDate" [limpiar]="false"
          />
          <mat-error *ngIf="startDateValidation.hasError()">{{startDateValidation.error}}</mat-error>
        </div>
        <div class="kobra column">
          <mat-date-picker-formater
            class="grid-tile-form-field"
            [ngClass]="{error: endDateValidation.hasError()}"
            [label]="'Fecha de finalización laboral'"
            [placeHolder]="'Capture la fecha en la que dejó de laborar para la empresa'"
            [min]="form.value.startDate ? form.value.startDate : minDate" [max]="maxDate"
            formControlName="endDate" [limpiar]="false"
          />
          <mat-error *ngIf="endDateValidation.hasError()">{{endDateValidation.error}}</mat-error>
        </div>
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field">
            <mat-label>Comentarios</mat-label>
            <textarea matInput textSanitizer  rows="5" formControlName="comment"></textarea>
            <mat-icon matSuffix>comment</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <!-- <button mat-stroked-button m-actions class="boton-accion-eliminar" *ngIf="workExperience.id" (click)="delete()"><mat-icon>delete_forever</mat-icon>Eliminar</button> -->
  <button mat-stroked-button m-actions class="boton-guardar" (click)="save()" [disabled]="form.invalid"><mat-icon>
    save
  </mat-icon>Guardar</button>
</app-modal>