import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '../application/shared/application.service';
import { SessionData } from '../shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';




@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css'],
})
export class DashboardMainComponent implements OnInit, OnDestroy {
    public sessionData: SessionData;
    public isSuperAdmin;
    
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {

  };

  ngOnInit() {
    this.isSuperAdmin = this.localStorageService.getSessionData().isSuperAdmin;
  }

  ngOnDestroy() {

  }

}