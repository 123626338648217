import { MostrarPreguntas } from "./mostrar-preguntas.model";

export class Condicion {
  response: String;
  then: MostrarPreguntas;

  constructor() {
  }

  public static map(data: any): Condicion {
    let instance: Condicion = new Condicion();
    instance.response = (data.response || data.response === 0) ? data.response : null;
    instance.then = data.then || null;

    return instance;
  }

  public static mapArray(data: any[]): Condicion[] {
    let instances: Condicion[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(Condicion.map(data[i]));
    }
    return instances;
}
}
