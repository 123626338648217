import { environment as ennviromentdMX } from './environments/environment-mx';
import { environment as ennviromentCO } from './environments/environment-co';
import { environment as ennviromentPE } from './environments/environment-pe';;
import { currentEnvironment } from './current/current-environment';//Contiene los datos del ambiente actual en el cual vienen los paises y el ambiente

function reacargarEnviroment(archivo = ""){
    let archivoPais = archivo ? archivo : (localStorage.getItem('paisSeleccionado') ? JSON.parse(localStorage.getItem('paisSeleccionado')).abreviacion : currentEnvironment.paises[0].abreviacion);//Se deja por defecto el primer pais del arreglo del ambiente actual
    let enviromentAUsar = ennviromentdMX;
    switch(archivoPais){
        case 'co':
            enviromentAUsar = ennviromentCO;
            break;
        case 'pe':
            enviromentAUsar = ennviromentPE;
            break;
    }
    return enviromentAUsar;
};
export const environmentSelector = reacargarEnviroment;