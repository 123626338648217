<router-outlet (activate)="hijosActivos = true;" (deactivate)="hijosActivos = false;"></router-outlet>
<div class="app content kobra" *ngIf="!hijosActivos">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="movimientoForm">
        <!-- Primera fila -->
        <div class="row" style="margin: 10px;">
          <div class="column" style="width: 45%;">
            <mat-select-search class="id-card-field" [label]="'Periodo de facturación'" [placeHolder]="'Buscar periodo'"
              [value]="'idCobradorFacturacionPeriodo'" [labelField]="'nombre'" [icon]="'search'"
              [realizarBusqueda]="periodosSearch" formControlName="idCobradorFacturacionPeriodo">
            </mat-select-search>
          </div>
          <div class="column" style="width: 9%; margin-top: 10px; ">
            <a mat-stroked-button color="white" [routerLink]="['./periodos']" queryParamsHandling="preserve"
              matTooltip="Agrega un nuevo periodo de facturación" mat-tooltip-panel-above class="boton-accion-front">
              <mat-icon >add_thick</mat-icon>
            </a>
          </div>
          <div class="column" style="width: 45%;">
            <mat-select-search class="id-card-field" [label]="'Agente'" [placeHolder]="'Buscar agente'"
              [value]="'idCobrador'" [labelField]="'nombre'" [icon]="'search'" [realizarBusqueda]="agenteFilterSearch"
              formControlName="idCobrador" [minBusqueda]="1">
            </mat-select-search>
          </div>
        </div>

        <!-- Segunda fila -->
        <div class="row" style="margin: 10px;">
          <div class="column" style="width: 45%;">
            <mat-select-search class="id-card-field" [label]="'Concepto'" [placeHolder]="'Busca por id o nombre'"
              [value]="'option'" [labelField]="'nombre'" [icon]="'account_circle'" [realizarBusqueda]="buscarConceptos"
              formControlName="concepto" (ngModelChange)="onConceptoSelected(movimientoForm.get('concepto').value)">
            </mat-select-search>

          </div>
          <div class="column" style="width: 9%; margin-top: 10px; ">
            <a mat-stroked-button color="white" [routerLink]="['./conceptos']" queryParamsHandling="preserve"
              matTooltip="Agrega un nuevo concepto" mat-tooltip-panel-above class="boton-accion-front">
              <mat-icon >add_thick</mat-icon>
            </a>
          </div>
          <div class="column" style="width: 45%;">
            <mat-form-field style="width: 100%;">
              <mat-label>Nombre Movimiento</mat-label>
              <input matInput textSanitizer  type="text" formControlName="nombreMovimiento">
            </mat-form-field>
          </div>
        </div>

        <!-- Tercera fila -->
        <div class="row" style="margin: 10px;">
          <div class="column" style="width: 100%;">
            <mat-form-field floatLabel="always" style="width: 30%;">
              <mat-label>Monto</mat-label>
              <input matInput textSanitizer  type="number" placeholder="0" formControlName="monto">
              <span matTextPrefix>$&nbsp;</span>
              <span matTextPrefix *ngIf="isCargo">- &nbsp;</span>
            </mat-form-field>
          </div>
        </div>

        <!-- Cuarta fila (Botones) -->
        <div align="right" >
          <button class="boton-cancelar" mat-flat-button routerLink="../" queryParamsHandling="preserve">
            <mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>

          <button mat-stroked-button class="boton-guardar" (click)="onSubmit()" style="margin: 10px;"><mat-icon
              style="color:white !important; ">save</mat-icon>Guardar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>