<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
  <alerta-informativa 
    *ngIf="data.type.indexOf('up') !== -1 && data.lastMovement"
    clase="danger"
    titulo="¡Aviso!" 
    [texto]="getMensaje()">
  </alerta-informativa>
  <alerta-informativa 
    *ngIf="data.type == 'down-punishment'"
    clase="warning"
    titulo="¡Aviso!" 
    texto="El castigo por desasignación inactiva al agente por 7 días y desasignará todas las cuentas que tiene apartadas.">
  </alerta-informativa>
  <div>
    <mat-form-field style="width: 100%" *ngIf="data.type == 'down-general'">
      <mat-label>Motivo</mat-label>
      <mat-select [formControl]="tagControl" required>
        <mat-option *ngFor="let tag of tags" [value]="tag.idTag">
          {{tag.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="tagControl.hasError('required')">Es requerido</mat-error>
    </mat-form-field>
  </div>
  
  <div>
    <mat-form-field style="width: 100%">
      <mat-label>Comentario</mat-label>
      <textarea matInput textSanitizer  [(ngModel)]="comment" rows="3"></textarea>
    </mat-form-field>
  </div>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onClose()" ><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button
  *ngIf="data.type === 'down-punishment'"
  mat-stroked-button
  class="boton-accion-eliminar"
  (click)="down()"
  >
  <mat-icon>save</mat-icon>
    Aplicar castigo
  </button>

  <button
    *ngIf="data.type === 'up-punishment'"
    mat-stroked-button
    class="boton-accion-guardar"
    (click)="up()"
  >
  <mat-icon>save</mat-icon>
    Quitar castigo
  </button>

  <button
    *ngIf="data.type === 'down-general'"
    mat-stroked-button
    class="boton-accion-eliminar"
    (click)="down()"
    [disabled]="tagControl.invalid"
  >
  <mat-icon>save</mat-icon>
    Dar de baja
  </button>

  <button
    *ngIf="data.type === 'up-general'"
    mat-stroked-button
    class="boton-accion-guardar"
    (click)="up()"
  >
  <mat-icon>save</mat-icon>
    Dar de alta
  </button>
</mat-dialog-actions>

