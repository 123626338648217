<div class="rating-elements-container" [formGroup]="form">
    <div class="stars-container">
      <ngx-stars 
      [maxStars]="5" 
      [initialStars]="calificacion" 
      [readonly]="disabled" 
      (ratingOutput)="asignarCalificacion($event)"
      [customPadding]="'1rem'" 
      [size]="2"
      [color]="'#FFD700'"
      [wholeStars]="true"
      >
    </ngx-stars>
    </div>
  
    <div class="cntr" *ngIf="estrellas">
      <div class="container">
        <mat-form-field>
          <mat-label>Etiqueta cuenta</mat-label>
          <mat-select formControlName="idEtiquetaCuenta">
            <mat-option *ngFor="let etiqueta of etiquetasCuenta" [value]="etiqueta.idEtiquetaCuenta">
              {{ etiqueta.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field class="coment-input">
          <mat-label>Comentario</mat-label>
          <textarea matInput textSanitizer  placeholder="Favor de escribir un comentario..." rows="1" formControlName="comentLibre" maxlength="500"></textarea>
        </mat-form-field>
  
        <button mat-stroked-button class="boton-guardar margen-abajo" *ngIf="validaSubmit()" (click)="onCalificarVisita()"><mat-icon >star</mat-icon>Calificar</button>
      </div>
    </div>
  </div>
  