import { StorageKobraService } from 'src/app/core/http/storage-kobra/storage-kobra.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DescargasService {
  public apiService = '/v2/agents';


  constructor(
    private servicesStorage: StorageKobraService,

  ) { }


  public obtenerDescargas(idClienteAdministrador:number, queryParams: string){
    return this.servicesStorage.get(`/usuarios/${idClienteAdministrador}/descargas${queryParams}`);
  }

  public descargarArchivosVisitas(idDescarga:number){
    return this.servicesStorage.get(`/descargas/${idDescarga}`);
  }

}
