<div class="img-zoom-container" [ngClass]="{'ui rounded image zoom-in': esDialogo, 'ui medium rounded image pointer': !esDialogo}" [attr.id]="idZoomContainer"
  matTooltip="{{esDialogo ? '' : 'Haz clic para ampliar'}}"
  mat-tooltip-panel-above align="center"
>
  <app-loader *ngIf="!mostrarDescarga"></app-loader>
  <img [src]="urlFotoMostrar" (error)="onErrorImageLoad()" class="radius" *ngIf="!height" [attr.id]="idImagen" (load)="imageLoad()">
  <img [src]="urlFotoMostrar" (error)="onErrorImageLoad()" class="radius" *ngIf="height" [ngStyle]="{'max-height': height+'px'}" [attr.id]="idImagen" (load)="imageLoad()">
  <mat-icon
    mat-list-icon 
    class="download image"
    matTooltip="Descargar imagen"
    mat-tooltip-panel-above
    *ngIf="mostrarDescarga && mostrarBotonDescargar"
    (click)="downloadImage();$event.stopPropagation();">
      cloud_download
  </mat-icon>
  <div class="img-zoom-result" [attr.id]="idImagenResult" [hidden]="!mostrarZoom"></div>
</div>
