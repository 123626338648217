<form [formGroup]="form">
  <!-- <span class="titulo">Editar</span> -->
  <mat-grid-list-responsive style="margin-top: 20px;" [colsMd]="2" [colsSm]="1" rowHeight="80px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-select-search
        style="width: 95%;"
        [label]="loadingBanks ? 'Cargando bancos...' : 'Banco'"
        [placeHolder]="'Selecciona un banco'"
        [value]="'id'"
        [labelField]="'name'"
        [icon]="'account_balance'"
        [options]="banks"
        [error]="bankValidation.hasError()"
        [errorMessage]="bankValidation.error"
        formControlName="bank"
        
      >
      </mat-select-search>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field [ngClass]="{error: accountValidation.hasError()}" style="width: 95%;">
        <mat-label>Cuenta</mat-label>
        <input matInput 
          formControlName="account"
          [disabled]="loadingBanks"
          placeholder="Ingrese el número de cuenta">
        <mat-icon  matSuffix>credit_card</mat-icon>
        <mat-error *ngIf="accountValidation.hasError()">{{accountValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list-responsive>
  <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="80px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field [ngClass]="{error: clabeValidation.hasError()}" style="width: 95%;">
        <mat-label>CLABE</mat-label>
        <input matInput 
          formControlName="clabe"
          maxlength="18"
          [disabled]="loadingBanks"
          placeholder="Ingrese el número de cuenta">
        <mat-icon  matSuffix>account_balance_wallet</mat-icon>
        <mat-error *ngIf="clabeValidation.hasError()">{{clabeValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list-responsive>
</form>
<mat-card-actions align="end" class="action-card">
  <button class="boton-cancelar" mat-flat-button  (click)="cancel()">
    <mat-icon class="kobra-icon">
      close
    </mat-icon> Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="save()" [disabled]="form.invalid">
    <mat-icon>
      save
    </mat-icon> Guardar
  </button>
</mat-card-actions>

