import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';
import { environmentSelector } from 'src/environments/environment.selector';

@Injectable({
    providedIn: 'root'
})
export class SucursalesService {
    baseURL: string = environmentSelector().kobraServices.servicios;
    private serviceURL: string = '/sucursales';
    private serviceAdministradoresURL: string = '/administradores';
    private serviceAdministradoresSucursalesURL: string = '/administradores/sucursales';

    constructor(
        private serviciosKobra: ServiciosKobraService,
        private httpClient: HttpClient) { }

    private headers(): HttpHeaders {
        let header: any = {'Content-Type': 'application/json'};
        let token = localStorage.getItem('access-token');
        let paisSeleccionado = localStorage.getItem('paisSeleccionado');
        if(paisSeleccionado){
            header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
        }
        if (token) {
            header['Authorization'] = token;
        }
        return new HttpHeaders(header);
    }

    public getBranches( params: string ): Observable<any> {

        return this.serviciosKobra.get(this.serviceURL + params).pipe(
            map(res => {
                return res;
            })
        );
    }

    public saveBranch( params: any ): Observable<any> {

      return this.serviciosKobra.post(this.serviceURL, params).pipe(
        map((res) => res)
      );
    }

    public editBranch( idSucursal: number, params: any ): Observable<any> {

      return this.serviciosKobra.patch(`${this.serviceURL}/${idSucursal}`, params).pipe(
          map(res => {
              return res;
          })
      );
    }

    public deleteBranch( idSucursal: number, idFinanciera: number ): Observable<any> {
      return this.serviciosKobra.delete(`${this.serviceURL}/${idSucursal}?idFinanciera=${idFinanciera}`);
    }

    public getAdministradorFinanciera( params: string ): Observable<any> {

      return this.serviciosKobra.get(this.serviceAdministradoresURL + params).pipe(
          map(res => {
              return res;
          })
      );
    }

    public getAdministradoresFinancieraSucurasl( params: string ): Observable<any> {

      return this.serviciosKobra.get(this.serviceAdministradoresSucursalesURL + params).pipe(
          map(res => {
              return res;
          })
      );
    }
}
