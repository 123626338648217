import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
//import { FiltersComponent } from './filters/filters.component';
import * as moment from 'moment';
import { Column } from './interfaces/tabla.interface';
//import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
  //providers: [ ClipboardService]
})
export class TablaComponent implements OnInit, OnDestroy, OnChanges {
	@Input() columns: Array<Column> = [];
	@Input() items: Array<any> = [];
  @Input() filters: Array<any> = [];
  @Input() loading: boolean = false;
  //Valida si se ocultaran los headers por defecto de la tabla para capturar los propios
  //(Para utilizar los propios headers utilizar la clase ui mini icon buttons)
  @Input() headers: boolean = true;
  @Input() tableHeaders: boolean = true;
  @Input() multiSelect: boolean = true;
  @Input() ignoreSortChange: boolean = false;
  @Input() classContainer: string = 'table-container';

	@Output() onSelectedRows: EventEmitter<any> = new EventEmitter();
	@Output() onLinkRow: EventEmitter<any> = new EventEmitter();
  @Output() sortChange: EventEmitter<any> = new EventEmitter();
  @Output() filtersChange: EventEmitter<any> = new EventEmitter();
  @Output() searchChange: EventEmitter<any> = new EventEmitter();
  @Output() onDownload: EventEmitter<any> = new EventEmitter();
  @Output() onDoubleClick: EventEmitter<any> = new EventEmitter();

	displayedColumns: string[];
	dataSource;
  selection = new SelectionModel<any>(true, []);
  selectedRow: any;
  subscription: Subscription;
  subscriptionSearch: Subscription;
  filterFormGroup: FormGroup;
  momentDate;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.momentDate = moment;
  }

  ngOnInit() {
    this.filterFormGroup = this.formBuilder.group({
      search: ['']
    });

    this.subscriptionSearch = this.filterFormGroup.valueChanges.pipe(debounceTime(350), distinctUntilChanged())
      .subscribe((value) => {
        this.searchChange.emit(value.search);
      });

  	this.displayedColumns = ['select'].concat(this.columns.map(c => c.name));
  	this.dataSource = new MatTableDataSource<any>(this.items);
    

  	this.subscription = this.selection.changed.subscribe(data => {
  		this.onSelectedRows.emit(data.source.selected);
  	});
  }

  ngOnChanges(changes: any) {
    if (Boolean(changes.items)) {
      this.dataSource = new MatTableDataSource<any>(changes.items.currentValue);
      this.selection.clear();
    }
  }

  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
      this.subscriptionSearch.unsubscribe();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  seleccionar(row){
    if(!this.multiSelect){
      this.selection.clear();
      this.selectedRow = row;
    }
    this.selection.toggle(row);
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  linkRow($event, row?: any, columnPressed?: any) {
  	$event.stopPropagation();
  	this.onLinkRow.emit({ row, columnPressed });
  }

  onDblClick(row){
    this.onDoubleClick.emit({row});
  }

  sortData(sort: Sort) {
    if(this.items && this.items.length> 0){
      const column  = this.columns.find((c: any) => c.name === sort.active);
      if(!this.ignoreSortChange){
        this.sortChange.emit({
          active: column?.sortName || column?.key,
          direction: sort.direction
        });
      }
      else{
        if(sort.direction == 'asc'){
          this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a,b) => {
            var keyA = a[column.key];
            var keyB = b[column.key];
            if (keyA > keyB) {
              return 1;
            }
            if (keyA < keyB) {
              return -1;
            }
            return 0;
          }));
        }
        else if (sort.direction == 'desc'){
          this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a,b) => {
            var keyA = a[column.key];
            var keyB = b[column.key];
            if (keyA > keyB) {
              return -1;
            }
            if (keyA < keyB) {
              return 1;
            }
            return 0;
          }));
        }
      }
    }
  }

  openFilters() {
    /*const dialogRef = this.dialog.open(FiltersComponent, {
      minWidth: 400,
      disableClose: true,
      data: this.filters
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.filtersChange.emit(data);
      }
    });*/
  }

  
}
