import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPaginatorGeneral } from 'src/app/shared/components/table/interfaces/tabla.interface';
import { FiltrosService } from 'src/app/application/shared/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class ProductosSaldosService {
  public concepto: Object;
  public tiposConceptos: Observable<Object[]>;
  public loader: boolean;
  public pagination: IPaginatorGeneral;
  public paginationInfo: IPaginatorGeneral;

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder
  ) {

    this.pagination = {
      page: 1,
      pageData: [],
      pages: 0,
      totalRows: 0,
      pageSize: 50
    };
    this.paginationInfo = {
      page: 1,
      pageData: [],
      pages: 0,
      totalRows: 0,
      pageSize: 50
    };
  }

  obtenerPaquetes(page: number, filters: any) {
    this.loader = true;
    let nombre = filters.nombre || '';

    if (nombre != '') {
      nombre = `&nombreConcepto=${filters.nombre}`;
    }

    const campos = '"idConcepto", "nombre", "precio", "idTarifa"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pagination.pageSize}&_slt=${campos}${nombre}&_orderBy=${filters.sortField}`;

    let tarifas = this.saldosKobraServ.get(`/tarifas/conceptos${queryParams}`)
      .pipe(
        map((res: any) => {
          this.loader = false;
          let datos = res.data;

          this.paginationInfo = datos;
          this.pagination.page = this.paginationInfo.page;
          this.pagination.totalRows = this.paginationInfo.totalRows;
          this.pagination.pages = this.paginationInfo.pages;

          return this.paginationInfo.pageData;

        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
    tarifas.subscribe((res: any) => {
      this.loader = false;
    }, (err: any) => {
      this.loader = false;
      this.app.showError(err);
    })

  }

  editarProducto(idConcepto: number, productos: any): Observable<any> {
    return this.saldosKobraServ.put('/tarifas/concepto/' + idConcepto, productos);
  }


}
