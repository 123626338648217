export class OperationTag {
  id: number;
  name: string;
  color: string;

  constructor() {
  }

  public static map(data: any): OperationTag {
    var instance: OperationTag = new OperationTag();
    
    instance.id = data.idEtiquetaOperacion ? data.idEtiquetaOperacion: data.id ? data.id : null;
    instance.name = data.name ? data.name : data.nombre ? data.nombre : null;
    instance.color = data.color ? data.color : null;

    return instance;
  }

  public static mapArray(data: any[]): OperationTag[] {
    var instanceArray: OperationTag[] = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      instanceArray.push(OperationTag.map(element));
    }
    return instanceArray;
  }
}