import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { Paquete } from "./paquete.model";
import { ServicesKobraService } from '../core/http/services-kobra/services-kobra.service';
import { ServiciosSaldosKobraService } from "../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service";
import { map } from 'rxjs/operators';

@Injectable()
export class PaqueteService {
  private serviciosSaldosURL: string = '/paquetes';

  constructor(
    private serviciosSaldosKobra: ServiciosSaldosKobraService,
  ) { }

  // public all(): Observable<Paquete[]> {
  //   return this.servicesKobra.get(this.serviceURL).pipe(
  //     map((res) => <Paquete[]> res.data)
  //   )
  // }
  public all(): Observable<Paquete[]> {
    return this.serviciosSaldosKobra.get(this.serviciosSaldosURL).pipe(
      map((res) => <Paquete[]> res.data)
    )
  }
}
