<router-outlet (activate)="this.hijosActivos = true;" (deactivate)="this.hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            (click)="descargarListado('xls')" 
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            [disabled]="!listFiltered"
            (click)="loadVisits(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tablaVisitas.columns"
      [items]="visits"
      [headers]="tablaVisitas.headers"
      [multiSelect]="tablaVisitas.multiSelect"
      [ignoreSortChange]="true"
      (onLinkRow)="onClick($event)"
      >

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
       
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Solicitudes de Revisitas" en el sistema es una vista enfocada en presentar un listado de todas las visitas
            en las que se ha solicitado una revisita.
            Este listado es especialmente útil para poder revisar aquellas visitas donde se ha requerido una revisita para
            recopilar información adicional o en casos donde el objetivo inicial de la visita no se pudo cumplir, como cuando no
            se encuentra a la persona que se debía visitar.
          
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las visitas realizadas con revisita solicitada.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de las solicitudes de revisitas para su análisis o uso fuera de línea.
          </p>
        </mat-card-content>
      </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="visitPaginator.pagination?.totalCount"
          [pageIndex]="visitPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros
    </mat-toolbar>
    <div class="accordion-filtros">
      <mat-accordion [multi]="false">
        <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
          <mat-expansion-panel-header>
            Súper Administrador
            <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>

          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Cliente'"
                [placeHolder]="'Selecciona un cliente'"
                [value]="'idFinanciera'"
                [labelField]="'name'"
                [icon]="'account_balance'"
                [options]="lenders"
                [(ngModel)]="idLender"
                (ngModelChange)="onChangeLender($event);"
              >
              </mat-select-search>
             
            </li>
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Excluir Demo</mat-label>
                <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                  <mat-option [value]="true">Sí</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
              </mat-form-field>
            </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
          <mat-expansion-panel-header>
            Generales
            <span *ngIf="contabilizadorDeFiltros('generales') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>

          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Folio Visita</mat-label>
                <input matInput textSanitizer 
                [(ngModel)]="filtros.folioVisita"
                (ngModelChange)="this.contabilizadorDeFiltros('generales')"
                placeholder="Capture el folio de la visita">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="filtros.folioVisita" 
                (click)="filtros.folioVisita = null;this.contabilizadorDeFiltros('generales')">clear</mat-icon>
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
           
            </li>
            <li class="filtro-side">
                <mat-form-field  style="width: 100%;">
                  <mat-label>{{loadings.productos ? 'Cargando productos...' : 'Producto'}}</mat-label>
                  <mat-select  [(ngModel)]="filtros.idProducto" (ngModelChange)="this.contabilizadorDeFiltros('generales')">
                    <mat-option *ngFor="let producto of products" [value]="producto.idProducto">
                        {{producto.nombre || producto.nomProducto}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.idProducto" 
                (click)="filtros.idProducto = null;this.contabilizadorDeFiltros('generales')">clear</mat-icon>
                </mat-form-field>
                
              </li>
              <li class="filtro-side">
                <mat-form-field  style="width: 100%;">
                  <mat-label>{{loadings.origenes ? 'Cargando origenes...' : 'Origen'}}</mat-label>
                  <mat-select  [(ngModel)]="filtros.idOrigenRevisita" (ngModelChange)="this.contabilizadorDeFiltros('generales')">
                    <mat-option *ngFor="let origen of origenes" [value]="origen.idOrigenRevisita" >
                        {{origen.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.idOrigenRevisita" 
                (click)="filtros.idOrigenRevisita = null;this.contabilizadorDeFiltros('generales')">clear</mat-icon>
                </mat-form-field>
               
              </li>
              <li class="filtro-side">
                <mat-form-field  style="width: 100%;">
                  <mat-label>{{loadings.estatus ? 'Cargando estatus...' : 'Estatus'}}</mat-label>
                  <mat-select  [(ngModel)]="filtros.idEstatus" (ngModelChange)="this.contabilizadorDeFiltros('generales')">
                    <mat-option *ngFor="let estatus of estatusRevisitas" [value]="estatus.idEstatus">
                        {{estatus.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.idEstatus" 
                (click)="filtros.idEstatus = null;this.contabilizadorDeFiltros('generales')">clear</mat-icon>
                </mat-form-field>
               
              </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Cuenta
            <span *ngIf="this.contabilizadorDeFiltros('cuenta') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('cuenta') }}</span>

          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Cuenta'"
                [placeHolder]="'Busca por id o nombre'"
                [value]="'option'"
                [labelField]="'nombre'"
                [icon]="'account_circle'"
                [realizarBusqueda]="acreditadoFilterSearch"
                [(ngModel)]="filtros.acreditado"
                (ngModelChange)="this.contabilizadorDeFiltros('cuenta')"
              >
              </mat-select-search>
              
            </li>
          </ol>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
              Fechas
              <span *ngIf="this.contabilizadorDeFiltros('fechasTotal') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasTotal') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-accordion [multi]="true">
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                        Fecha solicitud
                        <span *ngIf="this.contabilizadorDeFiltros('fechasS') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasS') }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fecRegistroFin ? filtros.fecRegistroFin : today"
                          [(ngModel)]="filtros.fecRegistroInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasS');"
                        />
                       
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fecRegistroInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fecRegistroFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasS');"
                        />
                        
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="false">
                    <mat-expansion-panel-header>
                        Fecha visita
                        <span *ngIf="this.contabilizadorDeFiltros('fechasV') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasV') }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fecFinalizadaFin ? filtros.fecFinalizadaFin : today"
                          [(ngModel)]="filtros.fecFinalizadaInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasV');"
                        />
                        
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fecFinalizadaInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fecFinalizadaFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasV');"
                        />
                       
                      </li>
                    </ol>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </ol>
          </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Agente
            <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>

          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-select-search
                class="id-card-field"
                [label]="'Agente'"
                [placeHolder]="'Busca por id o nombre'"
                [value]="'option'"
                [labelField]="'nombre'"
                [icon]="'account_circle'"
                [realizarBusqueda]="agenteFilterSearch"
                [(ngModel)]="filtros.agente"
                [minBusqueda]="1"
                (ngModelChange)="this.contabilizadorDeFiltros('agente');"

              >
              </mat-select-search>
          
            </li>
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Agentes activos</mat-label>
                <mat-select [(ngModel)]="filterAgentAvailable" (selectionChange)="this.contabilizadorDeFiltros('agente');">
                  <mat-option [value]="true">Sí</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-icon matSuffix class="icono-limpiar-solo"
                *ngIf="filterAgentAvailable != null" 
                (click)="filterAgentAvailable = null;$event.stopPropagation();">clear</mat-icon>
              </mat-form-field>
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    
    
    <ol class="lista-filtros-buttons">
      <button
        style="width: 100%;"
        mat-stroked-button
        class="boton-accion-front"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
      <button
        style="width: 100%; margin-top: 10px;"
        mat-stroked-button
        (click)="clearFilter()">
          <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
          Limpiar Filtros
      </button>
    </ol>
  </mat-sidenav>