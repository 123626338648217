<div class="modal-container">

  <h1 mat-dialog-title class="title">{{ title }}</h1>
  <h3 class="subtitle">{{ subtitle }}</h3>

  <mat-dialog-content class="modal-content">
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="boton-cancelar" *ngIf="!custom && onlyClose" mat-flat-button (click)="onClose.emit()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>

    <button class="boton-cancelar" *ngIf="!custom && !onlyClose" mat-flat-button (click)="onCancel.emit()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button *ngIf="!custom && !onlyClose" mat-stroked-button color="primary" (click)="onAccept.emit()" cdkFocusInitial>Aceptar</button>

    <ng-content select="[m-actions]"></ng-content>
  </mat-dialog-actions>
</div>
