import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../../application/shared/application.service";
import { VisitService } from "../../../../visit/visit.service";
import * as moment from 'moment';
import { HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import {VariablesDialog} from "../catalogo-variables/variables-dialog.component";

@Component({
  selector: 'formatos-afectados.component',
  templateUrl: 'formatos-afectados.component.html',
  styleUrls: ["formatos-afectados.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalFormatosAfectadosComponent {
  public cargandoArchivos: boolean = false;
  public descargandoArchivo: boolean = false;
  public cardAyuda: boolean = false;
  public archivos: any;
  public momentFecha;

  constructor(
    public dialogRef: MatDialogRef<ModalFormatosAfectadosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private visitService: VisitService,
    private app: ApplicationService,
    public dialog: MatDialog
  ) {
    this.archivos = this.data.archivos;
    this.momentFecha = moment;
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit() {
    //this.obtenerArchivos();
  }

  public descargarArchivo(archivo: any) {
    this.descargandoArchivo = true;
    let queryparams = `?ruta=${archivo.ruta}&nombreArchivo=${archivo.nombreArchivoServidor}`;

    this.visitService.descargarArchivoStorage(queryparams ,this.data.idCliente).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, archivo.nombreArchivo);
      }
    }, error => {
      this.descargandoArchivo = false;
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
    });
  }
  
  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

  abrirDialogoVariables(archivo:any){  
    this.openDialogVariables(true, archivo.varsAfectadas);
  }

  openDialogVariables(isAfectado: boolean, variablesAfectadas: any): void {

    const dialogRef = this.dialog.open(VariablesDialog, {
        //height: '400px',
        width: '1000px',
        disableClose: true,
        data:{
          variables: [],
          tipoFormato: 1,
          variablesAfectadas: variablesAfectadas,
          isVersioNamiento: isAfectado
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            //this.filtrosModal = result;
            //this.cargarCuentas(this.pagina);

        }
    });
  }  

}
