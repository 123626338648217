export class ProductosFinanciera{
    idFinanciera: number;
    idProducto: number;
    idCobrador: number;
    nombreProducto: String;
    idCobradorProducto: String;
    fechaRegistroProducto: Date;

    public static map(data: any): ProductosFinanciera{
        let instancia = new ProductosFinanciera();

        instancia.idFinanciera = data.idFinanciera;
        instancia.idProducto = data.idProducto;
        instancia.idCobrador = data.idCobrador;
        instancia.nombreProducto = data.nombreProducto;
        instancia.idCobradorProducto = data.idCobradorProducto;
        instancia.fechaRegistroProducto =  data.fechaRegistroProducto

        return instancia;
    }
}