import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

//import { environment } from "../../../../environments/environment";
import { environmentSelector } from '../../../../environments/environment.selector';
import { ServiciosKobraResponse } from './servicios-kobra-response';

@Injectable()
export class ServiciosKobraService {
  private baseURL: string = environmentSelector().kobraServices.servicios;

  constructor(
    private httpClient: HttpClient,
  ) { }

  private headers(): HttpHeaders {
    let header: any = {'Content-Type': 'application/json'};
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Authorization'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    header['Country'] = paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, login: boolean = false): Observable<ServiciosKobraResponse> {
    return req.pipe(
      map(data => {
        let res = <ServiciosKobraResponse> data;
        if(!res.success) {
          throw res.error.message;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse|string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            } else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            } else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              console.log(err);
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(
                `Backend returned code ${err.status}, ` +
                `body was: ${err.message}`);
              message = err.message;
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.servicios;
  }

  public delete( path: string ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.delete(this.baseURL + path, {headers: this.headers()}));
  }

  public get( path: string ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.get(this.baseURL + path, {headers: this.headers()}));
  }

  public getFile( path: string ): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(this.baseURL + path, {headers: this.headers(),  responseType: 'blob',
    reportProgress: true,
    observe: 'events'});
  }

  public patch( path: string, data: any ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, {headers: this.headers()}));
  }

  public post( path: string, data: any, login: boolean = false ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.post(this.baseURL + path, data, {headers: this.headers()}), login);
  }
}
