<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
          <mat-label>Nombre concepto</mat-label>
          <input matInput textSanitizer 
            [(ngModel)]="filters.nombreConcepto"
            (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"

            placeholder="Capture el concepto a buscar">
            <mat-icon matSuffix  class="icono-limpiar"
            *ngIf="filters.nombreConcepto" 
            (click)="filters.nombreConcepto = null; this.contabilizadorDeFiltros('generales')">clear</mat-icon>
          <mat-icon matSuffix>lightbulb_circle</mat-icon>
        </mat-form-field>
    
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; filtrar(1)">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
  
  <div class="app content tabla kobra" *ngIf="conceptosProductos">

    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar administradores"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadConceptosProductos(filters.page ? filters.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
           <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle> 
        </mat-button-toggle-group>
      </div>
  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="agregarConcepto()" queryParamsHandling="preserve">
          <mat-icon class="kobra-icon">add</mat-icon>
          <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1 || loadings.borrando"
        (click)="openDialogConfirmacion(itemsSelected[0])" queryParamsHandling="preserve">
        <mat-icon color="warn">delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
  </mat-menu>
    <app-table class="table-margen"
      [classContainer]="'tabla-completa'"
      [loading]="loadings.conceptosProductos"
      [columns]="tabla.columns"
      [items]="conceptosProductos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (sortChange)="sortChange($event)"
      (filtersChange)="filtersChange($event)"
      (searchChange)="searchChange($event)"
      (onDownload)="download()"
    >
    <!--div class="list-actions" t-left>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          (click)="agregarConcepto()"
      >
          <mat-icon class="kobra-icon">add</mat-icon>
          Agregar
      </button>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          [disabled]="itemsSelected.length !== 1 || loadings.borrando"
          (click)="openDialogConfirmacion(itemsSelected[0])"
      >
          <mat-icon class="kobra-delete-icon">delete</mat-icon>
          Borrar
      </button>
    </div>
  
  
      <div class="ui mini icon buttons">
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin" [ngClass]="{primary: filters.filtered}"
          matTooltip="Filtrar"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos;"
        >
          <mat-icon class="kobra-icon" *ngIf="!filters.filtered">filter_alt_off</mat-icon>
          <mat-icon class="kobra-icon" *ngIf="filters.filtered">filter_alt</mat-icon>
        </button>
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin"
          matTooltip="Ayuda"
          mat-tooltip-panel-above
          class="botonAyuda"
          (click)="ayudaComentario(!ayuda)"
        >
          <mat-icon color="primary">help</mat-icon>
        </button>
      </div-->
      <div t-header>
         <mat-card *ngIf="ayuda" class="cardAyuda">
           <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon> 
              El módulo "Conceptos de productos" permite asociar cada producto con su concepto correspondiente para el módulo de saldos. Esta asociación es esencial para definir la estructura y el tratamiento financiero de cada producto.
              Al realizar el ligue entre el producto y su concepto, se establece un precio base para el pago al gestor. Esta configuración es crucial para determinar la compensación que se le pagará al gestor por la gestión del producto.
              El concepto ligado al producto se utiliza para agregar productos a los paquetes de saldos. Dentro de estos paquetes, se puede establecer el precio a nivel de paquete y/o a nivel de financiera, lo que ofrece flexibilidad en la gestión de precios.            
            </p> 
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b>Filtrar:</b> Mejora la búsqueda y el acceso a los conceptos, permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b>Refrescar:</b> Te permite recargar tu listado con los criterios de filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">add</mat-icon><b>Nuevo:</b> Los usuarios pueden agregar nuevos conceptos de productos
            </p>
            <p>
              <mat-icon color="warn">delete_forever</mat-icon><b>Borrar:</b> Los usuarios pueden eliminar conceptos que ya no son necesarios o relevantes.
            </p>
          </mat-card-content>
        </mat-card> 
      </div>
      
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="datosConsulta.rows"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
    
    <mat-card-footer class="kobra-footer-catalogo" *ngIf="loadings.borrando">
      <i>Eliminando registro...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </div>
  