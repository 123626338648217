import { AbstractControl } from "@angular/forms";

export class FormValidation {
  fieldName: string;
  formControl: AbstractControl;
  error: string;
  message: any;
  rule: any;

  public observeFromControl( updateControls: AbstractControl[] = [] ): void {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe(value => {
        this.error = '';
        if (this.formControl.dirty && !this.formControl.valid) {
          for (var key in this.formControl.errors) {
            this.error += this.message[key] + ' ';
          }
        }
        for (let i = 0; i < updateControls.length; i++) {
          updateControls[i].updateValueAndValidity();
        }
      });
    }
  }

  public hasError(): boolean {
    let error: boolean = false;
    if (this.formControl.touched) {
      if (this.error) {
        error = true;
      }
    }
    return error;
  }
}