import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { PaqueteVisitasPrecios } from "./paquete-visitas-precios.model";
import { ServiciosSaldosKobraService } from "../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service";
import { map } from 'rxjs/operators';

@Injectable()
export class PaqueteVisitasPreciosService {
  private serviciosSaldosURL: string = '/paquetes';

  constructor(
    private serviciosSaldosKobra: ServiciosSaldosKobraService,
  ) { }

  public obtenerPaqueteVisitasPrecios(idPaquete: number): Observable<PaqueteVisitasPrecios[]> {
    return this.serviciosSaldosKobra.get(this.serviciosSaldosURL + '/' + idPaquete + '/conceptos').pipe(
      map((res) => <PaqueteVisitasPrecios[]> res.data)
    )
  }
}
