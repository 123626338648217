import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { Client } from "./client.model";
import { Account } from "../account/account.model";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { ServicesKobraService } from "../core/http/services-kobra/services-kobra.service";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { map } from "rxjs/operators";

@Injectable()
export class ClientService {
  private apiService = + '/v2/clients';
  private serviceURL: string = '/acreditados';

  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService
  ) { }

  public getOne( id: number ): Observable<Client> {
    return this.apiKobra.get(`${this.apiService}/${id}`).pipe(
      map(response => <Client> response.data)
    );
  }
  public getAccount( id: number ): Observable<any> {
    return this.servicesKobra.get(`${this.serviceURL}/${id}/cuenta`).pipe(
      map(response => <Account> response.data)
    );
  }
}
