import { Lender } from "../../../lender/lender.model";
import { FiltrosMap } from'src/app/application/shared/filtros.service';


export class FiltrosChecklist extends FiltrosMap {
    public financiera: Lender;

    constructor() {
      super();
    }

    public override map(data: any) {
      this.financiera = data.financiera ? Lender.map(data.financiera) : null;
    }
}
