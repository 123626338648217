import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { AgentBank } from "../../modelos/agent-bank.model";
import { AgentBankService } from "../../modelos/agent-bank.service";
import { Bank } from "../../../bank/bank.model";
import { BankService } from "../../../bank/bank.service";
import { FormValidation } from "../../../shared/form-validation/form-validation.model";
import { LoaderService } from "../../../loader/loader.service";
import { MessageService } from "../../../shared/message/message.service";
import { Observable } from 'rxjs';

@Component({
  selector: 'agent-bank-form',
  templateUrl: './agent-bank-form.component.html',
  styleUrls: ['./agent-bank-form.component.css'],
  providers: [
    AgentBankService,
    BankService
  ]
})
export class AgentBankFormComponent implements OnChanges, OnInit {
  public banks: Bank[];
  public loadingBanks: boolean = true;
  
  public form: FormGroup;
  public bankValidation: FormValidation;
  public accountValidation: FormValidation;
  public clabeValidation: FormValidation;

  @Input() agentBank: AgentBank;
  @Input() agentID: number;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<AgentBank> = new EventEmitter();
  @Output() saveProceess: EventEmitter<boolean> = new EventEmitter();
  @Output() loadingBancosChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private agentBankService: AgentBankService,
    private app: ApplicationService,
    private bankService: BankService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnChanges() {
    this.resetForm();
  }

  ngOnInit() {
    this.agentBank = this.agentBank || new AgentBank();
    this.initForm();
    this.loadBanks();
    this.initValidations();
    this.resetForm();
  }

  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
  }

  public findBankInArray( array: Bank[], id: number ): Bank | null {
    let bank: Bank | null = null;
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        bank = array[i];
      }
    }
    return bank;
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      bank: ['', [
        Validators.required
      ]],
      account: ['', [
        Validators.required,
        Validators.pattern(/^\d+$/),
      ]],
      clabe: ['', [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.maxLength(18),
        Validators.minLength(18)
      ]],
    });
  }

  private initValidations(): void {
    this.bankValidation = new FormValidation();
    this.bankValidation.formControl = this.form.controls['bank'];
    this.bankValidation.message = {
      required: 'Información requerida.'
    }
    this.bankValidation.observeFromControl();

    this.accountValidation = new FormValidation();
    this.accountValidation.formControl = this.form.controls['account'];
    this.accountValidation.message = {
      required: 'Información requerida.',
      pattern: 'Deben ser puros números.'
    }
    this.accountValidation.observeFromControl();

    this.clabeValidation = new FormValidation();
    this.clabeValidation.formControl = this.form.controls['clabe'];
    this.clabeValidation.message = {
      required: 'Información requerida.',
      maxlength: 'Debe contener 18 caracteres.',
      minlength: 'Debe contener 18 caracteres.',
      pattern: 'Deben ser puros números.'
    }
    this.clabeValidation.observeFromControl();
  }

  private loadBanks(): void {
    this.loadingBanks = true;
    this.loadingBancosChange.emit(true);
    this.bankService.all().subscribe(
      banks => {
        this.banks = banks;
       // console.log(this.banks);
        this.loadingBanks = false;
        this.loadingBancosChange.emit(false);
      },
      error => {
        this.loadingBanks = false;
        this.loadingBancosChange.emit(false);
      }
    );
  }

  private prepareFormData(): AgentBank {
    let agentBank: AgentBank = new AgentBank();
    //console.log(this.form.value.bank);
    agentBank.bankID = this.form.value.bank;
    agentBank.account = this.form.value.account;
    agentBank.clabe = this.form.value.clabe;
    return agentBank;
  }

  private resetForm(): void {
    if (this.form) {
      this.form.reset({
        bank: this.agentBank.bank.id,
        account: this.agentBank.account,
        clabe: this.agentBank.clabe
      });
    }
  }

  public save(): void {
    let agentBank: AgentBank = this.prepareFormData();
    let response: Observable<AgentBank>;

    agentBank.agentID = this.agentID;
    agentBank.id = this.agentBank.id;
    //console.log(agentBank.bankID)
    agentBank.bankID = agentBank.bankID;
    response = this.agentBankService.add(agentBank);
    let loading: number = this.app.showLoading('Guardando datos bancarios...');

    this.saveProceess.emit(true);
    response.subscribe(
      agentBank => {
        agentBank.bank = this.findBankInArray(this.banks, agentBank.bankID);
        this.agentBank = agentBank;
        this.app.hideLoading(loading);
        this.onSave.emit(this.agentBank);
        this.saveProceess.emit(false);
      },
      error =>{
        this.app.hideLoading(loading);
        this.saveProceess.emit(false);
      }
    );
  }
}
