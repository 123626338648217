

<h1 mat-dialog-title>Carga de excel</h1>
<mat-dialog-content>
  <app-upload-file [allowMultiple]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="handleFiles($event)"></app-upload-file>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="subirArchivo()">
    <mat-icon>save</mat-icon>Guardar
  </button>
</mat-dialog-actions>