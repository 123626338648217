import { OperationTag } from './../operation-tag/operation-tag.model';
import { OperationTagService } from "../operation-tag/operation-tag.service";
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from './../pagination/pagination.model';
import { Validator } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

import { Account } from '../account/account.model';
import { GeneralChecklist } from '../general-checklist/general-checklist.model';
import { Lender } from '../lender/lender.model';
import { LenderService } from '../lender/lender.service';
import { TakenAccount } from '../taken-account/taken-account.model';
import { Visit } from './visit.model';
import { VisitFilter } from './visit-filter.model';
import { VisitPaginator } from './visit.paginator';
import { VisitService } from './visit.service';
import { environmentSelector } from '../../environments/environment.selector';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Subscription } from 'rxjs';
import { AgentService } from '@agent/agent.service';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { MapboxVisitasComponent } from '../mapbox/visitas/mapbox-visitas.component';
import { ModalVisitaDetalleComponent } from "./detalle-dialog/visit-detail-dialog.component";
import { ModalVisitaDetalleMasivaComponent } from './detalle-masiva-dialog/detalle-masiva-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { StateService } from '@state/state.service';
import { MunicipalityService } from '@municipality/municipality.service';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


declare var $: any;

@Component({
    // moduleId: module.id,
    selector: 'visit-list',
    templateUrl: 'visit-list.component.html',
    styleUrls: [
        'visit-list.component.css'
    ],
    providers: [
        LenderService,
        VisitService,
        AgentService,
        OperationTagService,
        StateService,
        MunicipalityService
    ]
})

export class VisitListComponent implements OnInit {
    private environment = environmentSelector();
    public totalFiltros: number = 0;

    public isLoadVisitasMapa = false;
    filtrosAbiertos: boolean = false;
    ayuda: boolean = false;
    hijosActivos: boolean = false;
    sessionData: SessionData;
    public checklistForm: FormGroup;
    public pageSize: number = 50;
    private rows: number = 100;
    private slt: string = 'idFinanciera, idVisita,folioVisita, tipoProducto, inicioVisita, finVisita, idCuenta, idAcreditado, idExterno, nombreAcreditado,' +
        ' nombreCliente, creacionCuenta, idCobrador, nombreCobrador, comentarios, promesaPago, mock, repunteo, calificacionAdministradorFinanciera, calificacionSuperAdministrador,' +
        ' agenteInterno, idCliente, cancelada, estado, municipio,calle,colonia,numeroExt,numeroInt, codigoPostal, clienteActivo, nombreEtiqueta, grupo, sucursal,' +
        ' latInicio, lngInicio, latFotos, lngFotos, coordenadasVisita, tiposCoordenada,nombreEtiquetaCuenta,nombreEtiquetaOperacion, subIdExterno, dispositivo, folioDomicilio, domicilioCompleto, en_revision';
    public cargaChecklist: string = 'Cargando checklist';
    public errorChecklist: string = null;
    public visibility: string = null;
    public idLender: number = null;
    public flagFilter: number;
    public httpError: any;
    public lenders: Lender[];
    public listFields: any = {};
    public listFiltered: boolean;
    public sortField: string;
    public sortAsc: string = '';
    public omitirChecklistFinanciera: boolean = false;
    public omitirChecklistProducto: boolean = false;
    public today: Date = new Date();
    public visitPaginator: VisitPaginator;
    public visits: Visit[];
    public visitFilter: VisitFilter;
    public isAllSelected = false;
    public showCalifButton = false;
    public mapHeight: number = 600;
    public loadingEtiquetasCuenta: boolean = false;
    public etiquetasCuenta = [];
    public tags: OperationTag[];
    public totalRows: number;
    itemsSelected: Array<any>[] = [];
    public altoVentana: number = 0;
    public estados: any[];
    public municipios: any[];
    public contabilizadorFiltros: FiltersContabilizer;

    public pais: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelCodigoPostal: string;

    public loadings: any = {
        municipios: false,
        estados: false
    }

    @ViewChild('mapaVisitas') private mapaVisitas: MapboxVisitasComponent;
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;

    /*@ViewChild('calificarModal') public calificarModal: ModalTemplate<{}, string, string>;*/
    public visitasACalificar: Visit[];

    @ViewChild('agenteFilterInput') private agenteFilterInput: any;
    private agenteFilterFlag = false;
    private agenteFilterFounded: any;

    @ViewChild('acreditadoFilterInput') private acreditadoFilterInput: any;
    private acreditadoFilterFlag = false;
    private acreditadoFilterFounded: any;

    @ViewChild('grupoFilterInput') private grupoFilterInput: any;
    private grupoFilterFlag = false;
    private grupoFilterFounded: any;

    public products: Array<any>;
    public etiquetas: Array<any>;
    public sucursales: Array<any>;
    public loadingProducts: boolean;
    public loadingEtiquetas: boolean;
    public loadingSucursales: boolean;
    public cambiarNombreFiltroBounded: Function;
    public subscribeRouterParamsBounded: Function;
    public tablaVisitas: any = [];
    public loadingVisitas: boolean = false;
    public queryParamsGlobal:string;

    public filtros = {
        idVisita: null,
        financiera: null,
        agente: null,
        idProducto: null,
        checklistFechaInicio: null,
        checklistFechaFin: null,
        promesaPago: null,
        visitaFechaInicio: null,
        visitaFechaFin: null,
        calificacion: null,
        calificacionSuperAdmin: null,
        folioVisita: null,
        idCuenta: null,
        acreditado: null,
        idCliente: null,
        idGrupo: null,
        idEstado: null,
        estado: null,
        idMunicipio: null,
        municipio: null,
        ciudad: null,
        checklist: null,
        mock: null,
        repunteo: null,
        estatusAclaracion: null,
        visibilidad: null,
        excludeFinancierasDemo: true,
        idEtiqueta: null,
        idEtiquetaCuenta: null,
        idEtiquetaOperacion: null,
        idSucursal: null,
        page: 1,
        mostrarMapa: false,
        enRevision: null
    }

    constructor(
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private lenderService: LenderService,
        private rootElement: ElementRef,
        private dialog: MatDialog,
        private router: ActivatedRoute,
        private routeNavigate: Router,
        private visitService: VisitService,
        private stateService: StateService,
        private municipalityService: MunicipalityService,
        private agenteServ: AgentService,
        private accountTagService: OperationTagService,
    ) {
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.pais = this.environment.pais;
        this.labelCodigoPostal = this.environment.labelCodigoPostal;
        this.altoVentana = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.altoVentana = this.altoVentana * (1 - 0.3);
        this.visits = [];
        this.crearTabla();
        this.sessionData = this.localStorageService.getSessionData();
        this.visitFilter = new VisitFilter();
        this.visitPaginator = new VisitPaginator();
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
        this.contabilizadorDeFiltrosTotales();
        
    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'financiera',
            'excludeFinancierasDemo',
            'idEtiquetaOperacion',
            'calificacionSuperAdmin'
        ]);
       
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'folioVisita',
            'visibilidad',
            'promesaPago',
            'idProducto',
            'idSucursal',
            'repunteo',
            'idEtiquetaCuenta',
            'idEtiqueta',
            'mock',
            'estatusAclaracion'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'acreditado'
        ]);

        this.contabilizadorFiltros.add('Grupo', 'grupo', [
            'idGrupo'
        ]);

        this.contabilizadorFiltros.add('Ubicación', 'ubicacion', [
            'estado',
            'municipio',
            'idEstado',
            'idMunicipio'
        ]);
        this.contabilizadorFiltros.add('Ubicación', 'ubicacionMX', [
            'idEstado',
            'idMunicipio'
        ]);

        this.contabilizadorFiltros.add('Fechas', 'fechas', [
            'checklistFechaInicio',
            'checklistFechaFin'
        ]);
        this.contabilizadorFiltros.add('Agente', 'agente', [
            'agente'
        ]);
    }

    public contabilizadorDeFiltrosTotales():void{
        

        let totalFiltrosArray: number[] = [];
        if(this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('cuenta') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('grupo') );
        if(this.pais == 'MX'){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('ubicacionMX') );
        }else{
            totalFiltrosArray.push( this.contabilizadorDeFiltros('ubicacion') );
        }
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechas') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
      }

    private subscribeRouterParams(filtrosNuevos: any): void {
        var loadVisits = true;
        if (this.visitPaginator) {
            this.visitPaginator.pagination.currentPage = 0;
            loadVisits = false;
        }

        if (filtrosNuevos) {
            this.listFiltered = true;
            this.filtros = filtrosNuevos;
            loadVisits = true;

            this.filtros = filtrosNuevos;
            if (this.filtros.mostrarMapa) {
                this.pageSize = 1000;
            }

            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;

            if (this.filtros.checklist) {
                this.omitirChecklistFinanciera = true;
                this.omitirChecklistProducto = true;
            }

            /*if (this.filtros.idAgente) {
                this.agenteFilterSearch(this.filtros.idAgente + '', true);
            }

            if (this.filtros.idAcreditado) {
                this.acreditadoFilterSearch(this.filtros.idAcreditado + '', true);
            }

            if (this.filtros.idGrupo) {
                this.grupoFilterSearch(this.filtros.idGrupo + '', true);
            }*/

            if (this.filtros.idMunicipio) {
                this.loadMunicipios(true);
            }
            else if (this.filtros.idEstado) {
                this.loadEstados(true);
            }

            this.visitPaginator.pagination.currentPage = this.filtros.page || 1;
        }
        else{
            this.loadEstados();
        }

        if (!(this.sessionData.isSuperAdmin)) {
            this.filtros.excludeFinancierasDemo = false;
        }
        this.initListColumns();
        this.loadLenders(!this.listFiltered);
        if (this.sessionData.isSuperAdmin) {
            this.cargarCosasFinanciera();
            this.readHashParams(loadVisits);
        }
    }

    ngOnInit() {
        this.sortField = 'qv.finVisita';
        this.sortAsc = '';
        this.cambiarNombreFiltroBounded = this.cambiarNombreFiltro.bind(this);
        this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParamsBounded);
        this.mapHeight = window.innerHeight * .70;
    }

    crearTabla() {
        this.tablaVisitas = {
            headers: false,
            multiSelect: true,
            columns: [
                {
                    name: 'Cliente',
                    key: 'lender.name',
                    hide: false,
                    sortName: 'qv.nombreCliente'
                },
                {
                    name: 'Folio Visita',
                    key: 'folio',
                    hide: false,
                    link: '/visits/visit/',
                    type: 'link',
                    sortName: 'qv.folioVisita'
                },
                {
                    name: 'ID Cuenta',
                    key: 'client.externalID',
                    hide: false,
                    type: 'link',
                    sortName: 'qv.idExterno'
                },
                {
                    name: 'ID Subcuenta',
                    key: 'client.subExternalID',
                    hide: false,
                    sortName: 'qv.subIdExterno'
                }, {
                    name: 'Folio Domicilio',
                    key: 'clientAddress.folioDomicilio',
                    hide: false,
                    sortName: 'qv.folioDomicilio'
                }, {
                    name: 'Nombre Cuenta',
                    key: 'client.fullName',
                    hide: false,
                    sortName: 'qv.nombreAcreditado'
                }, {
                    name: 'Producto',
                    key: 'account.product.name',
                    hide: false,
                    sortName: 'qv.tipoProducto'
                }, {
                    name: 'Grupo',
                    key: 'grupo',
                    hide: false,
                    sortName: 'qv.grupo'
                }, {
                    name: 'ID Agente',
                    key: 'agent.id',
                    hide: false
                }, {
                    name: 'Agente',
                    key: 'agent.fullName',
                    hide: false,
                    sortName: 'qv.nombreCobrador'
                }, {
                    name: 'Dispositivo',
                    key: 'dispositivo',
                    hide: false,
                    sortName: 'qv.dispositivo'
                }, 
                // {
                //     name: 'Teléfono Agente',
                //     key: 'telCel',
                //     hide: false,
                //     sortName: 'qv.telCelVisita'
                // }, 
                {
                    name: 'Creción de Cuenta',
                    key: 'account.createdAt',
                    hide: false,
                    type: 'date',
                    sortName: 'qv.inicioVisita'
                }, {
                    name: 'Inicio Visita',
                    key: 'createdAt',
                    hide: false,
                    type: 'dateTime',
                    sortName: 'qv.inicioVisita'
                }, {
                    name: 'Fin Visita',
                    key: 'generalChecklist.createdAt',
                    hide: false,
                    type: 'dateTime',
                    sortName: 'qv.finVisita'
                }, {
                    name: 'Sucursal',
                    key: 'sucursal',
                    hide: false,
                    sortName: 'qv.sucursal'
                }, {
                    name: 'Etiqueta Visita',
                    key: 'nombreEtiqueta',
                    hide: false,
                    sortName: 'qv.nombreEtiqueta'
                }, {
                    name: 'Etiqueta Kobra',
                    key: 'nombreEtiquetaOperacion',
                    hide: false,
                    sortName: 'eo.nombre'
                }, {
                    name: 'Etiqueta Cuenta',
                    key: 'nombreEtiquetaCuenta',
                    hide: false,
                    sortName: 'ec.nombre'
                }, {
                    name: 'Domicilio',
                    key: 'fullAddres',
                    hide: false
                }, {
                    name: this.labelCodigoPostal,
                    key: 'clientAddress.zipCode',
                    hide: false
                }, {
                    name: this.labelMunicipio,
                    key: 'clientAddress.municipality',
                    hide: false,
                    sortName: 'qv.municipio'
                }, {
                    name: this.labelEstado,
                    key: 'clientAddress.state',
                    hide: false,
                    sortName: 'qv.estado'
                }, {
                    name: 'Comentario',
                    key: 'generalChecklist.comment',
                    hide: false
                }, {
                    name: 'Promesa de Pago',
                    key: 'generalChecklist.debtCollectionChecklist.paymentPromise',
                    hide: false,
                    type: 'boolean'
                }, {
                    name: 'Mock Loc.',
                    key: 'generalChecklist.mockLocation',
                    hide: false,
                    type: 'boolean'
                }, {
                    name: 'Repunteo',
                    key: 'rebound',
                    hide: false,
                    type: 'boolean'
                }, {
                    name: 'Red Interna',
                    key: 'visibility',
                    hide: false
                }, {
                    name: 'Calif. Admin.',
                    key: 'calificacionAdministradorFinanciera',
                    hide: false
                }, {
                    name: 'Calif. Super Admin.',
                    key: 'calificacionSuperAdministrador',
                    hide: false
                }, {
                    name: 'Geolocalización',
                    key: 'coordenadas',
                    hide: false,
                    type: 'link'
                }
            ]
        }
    }

    private cargarCosasFinanciera() {
        this.loadProducts();
        this.loadEtiquetas();
        this.loadSucursales();
        this.cargarEtiquetasCuenta();
        this.loadOperationTags();
    }

    private calculateAccountDays(visits: Visit[]): void {
        for (var i = 0; i < visits.length; i++) {
            var dateDiff: number = 0;
            if (visits[i].account && visits[i].checklist) {
                var initDate = new Date(visits[i].account.createdAt);
                var finishDate = new Date(visits[i].checklist.createdAt);
                dateDiff = finishDate.getTime() - initDate.getTime();
            }
            visits[i].accountDays = Math.trunc(dateDiff / 1000 / 60 / 60 / 24);
        }
    }

    private calculateDaysTaken(visits: Visit[]): void {
        for (var i = 0; i < visits.length; i++) {
            var dateDiff: number = 0;
            if (visits[i].takenAccount && visits[i].checklist) {
                var initDate: Date = new Date(visits[i].takenAccount.createdAt);
                var finishDate: Date = new Date(visits[i].checklist.createdAt);
                dateDiff = finishDate.getTime() - initDate.getTime();
            }
            visits[i].daysTaken = Math.trunc(dateDiff / 1000 / 60 / 60 / 24);
        }
    }

    private calculateDaysUntilFirstVisit(visits: Visit[]): void {
        for (var i = 0; i < visits.length; i++) {
            var visit: Visit = visits[i];
            if (visit.account) {
                var dateDiff: number = 0;
                var initDate: Date = new Date(visits[i].account.createdAt);
                var finishDate: Date = new Date(visits[i].accountVisits[0].createdAt);
                dateDiff = finishDate.getTime() - initDate.getTime();
                visits[i].daysUntilFisrtVisit = Math.trunc(dateDiff / 1000 / 60 / 60 / 24);
            }
        }
    }

    public clearFilter(): void {
        Object.keys(this.filtros).forEach(key => { 
                this.filtros[key] = null;
        });
        this.resetFinancieraFilter();
        this.filtros.excludeFinancierasDemo = this.sessionData.isSuperAdmin ? true : false;
        this.filtros.promesaPago = null;
        this.filtros.page = 1;
        this.filtrosService.removerFiltros();
        //this.loadVisits();
        this.listFiltered = false;
    }

    private crearURLQuery(): string {
        this.queryParamsGlobal = '';

        if (this.slt) {
             this.queryParamsGlobal += '&slt=' + this.slt;
        }

        if (this.sortField) {
             this.queryParamsGlobal += '&orderBy=' + this.sortField + (this.sortAsc ? ' ' + this.sortAsc : ' desc');
        }

        if (this.filtros.visibilidad === 0 || this.filtros.visibilidad === 1) {
             this.queryParamsGlobal += '&redInterna=' + this.filtros.visibilidad;
        }

        if (this.filtros.idVisita) {
             this.queryParamsGlobal += `&idVisita=${this.filtros.idVisita}`;
        }

        if (this.filtros.folioVisita) {
             this.queryParamsGlobal += `&folioVisita=${this.filtros.folioVisita.toUpperCase()}`;
        }

        if (this.filtros.visitaFechaInicio) {
            let strDate: string = this.filtros.visitaFechaInicio.toISOString();
             this.queryParamsGlobal += '&inicioVisita=' + strDate.split('T')[0];
        }

        if (this.filtros.visitaFechaFin) {
            let date = new Date(this.filtros.visitaFechaFin.getTime());
            // date.setDate(date.getDate() + 1);
            let strDate: string = date.toISOString();
             this.queryParamsGlobal += '&finVisita=' + strDate.split('T')[0];
        }

        if (this.filtros.checklistFechaInicio) {
            let strDate: string = this.filtros.checklistFechaInicio.toISOString();
             this.queryParamsGlobal += '&inicioRegistroVisita=' + strDate.split('T')[0];
        }

        if (this.filtros.checklistFechaFin) {
            // date.setDate(date.getDate() + 1);
            let strDate: string = this.filtros.checklistFechaFin.toISOString();
             this.queryParamsGlobal += '&finRegistroVisita=' + strDate.split('T')[0];
        }

        if (this.filtros.agente) {
             this.queryParamsGlobal += `&idCobrador=${this.filtros.agente.idCobrador}`;
        }

        if (this.filtros.promesaPago != -1 && this.filtros.promesaPago != null) {
             this.queryParamsGlobal += '&promesaPago=' + (this.filtros.promesaPago == 1 ? '1' : '0');
        }

        if (this.filtros.repunteo != null) {
             this.queryParamsGlobal += '&rebound=' + (this.filtros.repunteo == 1 ? '1' : '0');
        }

        if (this.filtros.mock != null) {
             this.queryParamsGlobal += `&mockLocation=${this.filtros.mock}`;
        }

        if (this.filtros.financiera != null) {
             this.queryParamsGlobal += `&idCliente=${this.filtros.financiera.idCliente}`;
             this.queryParamsGlobal += `&idFinanciera=${this.filtros.financiera.idFinanciera}`;
        }


        if (this.filtros.estatusAclaracion != null) {
             this.queryParamsGlobal += `&estatusAclaracion=${this.filtros.estatusAclaracion}`;
        }

        //NUEVOS

        if (this.filtros.idProducto != null) {
             this.queryParamsGlobal += `&idProduct=${this.filtros.idProducto}`;
        }

        if (this.filtros.idEtiqueta != null) {
             this.queryParamsGlobal += `&idEtiqueta=${this.filtros.idEtiqueta}`;
        }
        if (this.filtros.idEtiquetaCuenta) {
             this.queryParamsGlobal += `&idEtiquetaCuenta=${this.filtros.idEtiquetaCuenta}`;
        }
        if (this.filtros.idEtiquetaOperacion) {
             this.queryParamsGlobal += `&idEtiquetaOperacion=${this.filtros.idEtiquetaOperacion}`;
        }
        if (this.filtros.idSucursal != null) {
             this.queryParamsGlobal += `&idSucursal=${this.filtros.idSucursal}`;
        }

        if (this.filtros.idCuenta && this.filtros.idCuenta != '') {
             this.queryParamsGlobal += `&idCuenta=${this.filtros.idCuenta}`;
        }

        if (this.filtros.acreditado) {
             this.queryParamsGlobal += `&idAcreditado=${this.filtros.acreditado.idAcreditado}`;
        }

        if (this.filtros.idGrupo) {
             this.queryParamsGlobal += `&idGrupo=${this.filtros.idGrupo}`;
        }

        if (this.pais == 'MX') {
            if (this.filtros.idEstado)  this.queryParamsGlobal += '&estado=' + this.estados.find(estado => estado.idEstado == this.filtros.idEstado).nombre;
            if (this.filtros.idMunicipio)  this.queryParamsGlobal += '&municipio=' + this.municipios.find(municipio => municipio.idMunicipio == this.filtros.idMunicipio).nombre;
        }
        else {
            if (this.filtros.estado)  this.queryParamsGlobal += '&estado=' + this.filtros.estado.toString();
            if (this.filtros.ciudad)  this.queryParamsGlobal += '&ciudad=' + this.filtros.ciudad.toString();
            if (this.filtros.municipio)  this.queryParamsGlobal += '&municipio=' + this.filtros.municipio.toString();
        }

        /*if (this.filtros.estado && this.filtros.estado != '') {
             this.queryParamsGlobal += `&estado=${this.filtros.estado}`;
        }

        if (this.filtros.municipio && this.filtros.municipio != '') {
             this.queryParamsGlobal += `&municipio=${this.filtros.municipio}`;
        }*/

        if (this.filtros.ciudad && this.filtros.ciudad != '') {
             this.queryParamsGlobal += `&ciudad=${this.filtros.ciudad}`;
        }

        if (this.filtros.calificacion != null) {
             this.queryParamsGlobal += '&calificacionAdminFinanciera=' + (this.filtros.calificacion == 1 ? '1' : '0');
        }
        if (this.filtros.calificacionSuperAdmin != null) {
             this.queryParamsGlobal += '&calificacionSuperAdminFinanciera=' + (this.filtros.calificacionSuperAdmin == 1 ? '1' : '0');
        }

        if (this.filtros.enRevision != null) {
             this.queryParamsGlobal += '&enRevision=' + (this.filtros.enRevision == 1 ? '1' : '0');
        }

        if (this.sessionData.isSuperAdmin && this.filtros.excludeFinancierasDemo) {
             this.queryParamsGlobal += `&excludeFinancierasDemo=${this.filtros.excludeFinancierasDemo}`;
        }

        //CHECKLIST
        if (this.filtros.checklist) {
             this.queryParamsGlobal += this.obtenerRespuestasAFiltrar();
        }


        return  this.queryParamsGlobal;
    }

    obtenerRespuestasAFiltrar() {
        let checkListParams: string = '';
        var filtrosChecklist = [];
        this.filtros.checklist.preguntas.forEach(pregunta => {
            if (pregunta.filtrarPregunta) {
                pregunta.respuestas.forEach(respuesta => {
                    filtrosChecklist.push({
                        idChecklist: this.filtros.checklist.idChecklist,
                        numVersionChecklist: this.filtros.checklist.numVersionChecklist,
                        idChecklistPreguntaPadre: pregunta.idChecklistPreguntaPadre,
                        idPregunta: pregunta.idChecklistPregunta,
                        idRespuesta: respuesta.idChecklistRespuesta,
                        idTipoRespuesta: respuesta.idTipoRespuesta,
                        accion: respuesta.accion,
                        valor: this.convertirADateSiLoEs(respuesta.valor, respuesta.idTipoRespuesta == 5 ? respuesta.idTipoRespuesta : 1, true)
                    });
                });
            }

            if (pregunta.preguntasHijas) {
                pregunta.preguntasHijas.forEach(preguntaHija => {
                    if (preguntaHija.filtrarPregunta) {
                        preguntaHija.respuestas.forEach(respuestaHija => {
                            filtrosChecklist.push({
                                idChecklist: this.filtros.checklist.idChecklist,
                                numVersionChecklist: this.filtros.checklist.numVersionChecklist,
                                idChecklistPreguntaPadre: preguntaHija.idChecklistPreguntaPadre,
                                idPregunta: preguntaHija.idChecklistPregunta,
                                idRespuesta: respuestaHija.idChecklistRespuesta,
                                idTipoRespuesta: respuestaHija.idTipoRespuesta,
                                accion: respuestaHija.accion,
                                valor: this.convertirADateSiLoEs(respuestaHija.valor, respuestaHija.idTipoRespuesta == 5 ? respuestaHija.idTipoRespuesta : 1, true)
                            });
                        });
                    }
                });
            }
        });

        if (filtrosChecklist && filtrosChecklist.length > 0) {
            checkListParams += `&checklist=${JSON.stringify(filtrosChecklist)}`;
        }
        return checkListParams;
    }

    cambiarNombreFiltro(checklist) {
        checklist.filtrosChecklistActual = [];
        checklist.preguntas.forEach((pregunta, index) => {
            if (pregunta.filtrarPregunta) {
                checklist.filtrosChecklistActual.push(`${pregunta.nombrePregunta} = ${this.obtenerValorRespuesta(pregunta.respuestas)}`);
            }

            if (pregunta.preguntasHijas) {
                pregunta.preguntasHijas.forEach((preguntaHija, indexHija) => {
                    if (preguntaHija.filtrarPregunta) {
                        checklist.filtrosChecklistActual.push(`${preguntaHija.nombrePregunta} = ${this.obtenerValorRespuesta(preguntaHija.respuestas)}`);
                    }
                });
            }
        });
    }

    obtenerValorRespuesta(respuestas) {
        let res: any;
        let posiblesRespuestas = [];

        if (respuestas.length > 1) {
            respuestas.forEach(respuesta => {
                posiblesRespuestas.push(`${respuesta.nombreRespuesta}: ${this.convertirADateSiLoEs(respuesta.valor, respuesta.idTipoRespuesta, false)}`);
            });
        }
        if (respuestas.length == 1) {
            res = respuestas[0].nombreRespuesta ? respuestas[0].nombreRespuesta + ": " + this.convertirADateSiLoEs(respuestas[0].valor, respuestas[0].idTipoRespuesta, false) :
                this.convertirADateSiLoEs(respuestas[0].valor, respuestas[0].idTipoRespuesta, false) + "";
        }

        if (posiblesRespuestas.length > 0) {
            for (let index = 0; index < posiblesRespuestas.length; index++) {
                const respuesta = posiblesRespuestas[index];
                if (index == 0) {
                    res = respuesta;
                }
                else {
                    res += `, ${respuesta}`;
                }
            }
        }

        return res;
    }

    convertirADateSiLoEs(valor, tipoRespuesta, booleano) {
        if (tipoRespuesta == 5) {
            let strDate: string = valor.toISOString();
            return strDate.split('T')[0];
        }
        else {
            return tipoRespuesta == 8 || tipoRespuesta == 6 ? (valor == 'true' || valor == '1' ? (booleano ? 'true' : 'Sí') : (booleano ? 'false' : 'No')) :
                valor || '';
        }
    }

    public descargarListado(conCuestionario: number): void {
        let queryParams = `?start=0&cuestionario=${conCuestionario}&idClienteAdministrador=${this.sessionData.idClienteAdministrador}` +  this.queryParamsGlobal;
        queryParams += '&rows=' + this.pageSize;
        if (this.visits) {
            if (this.visits.length > 0) {
                this.visitService.descargarVisitaConCuestionario(queryParams)
                    .pipe(take(1))
                    .subscribe((respuesta) => {
                        this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
                    }, (error: any) => {
                        this.app.showError(error);
                    });
            } else {
                this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
            }
        } else {
            this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
        }

    }

    private initListColumns(): void {
        var listFields: any = JSON.parse(localStorage.getItem('visitListFields')) || {};

        //this.listFields.visitID = listFields.visitID == false ? false : true;
        this.listFields.visitFolio = listFields.visitFolio == false ? false : true;
        this.listFields.accountID = listFields.accountID == false ? false : true;
        this.listFields.clientID = listFields.clientID == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.clientFullName = listFields.clientFullName == false ? false : true;
        this.listFields.lender = listFields.lender == false ? false : true;
        this.listFields.visitGroup = listFields.visitGroup == false ? false : true;
        this.listFields.visitSucursal = listFields.visitSucursal == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
       // this.listFields.telCelVisita = listFields.telCelVisita == false ? false : true;
        this.listFields.dispositivo = listFields.dispositivo == false ? false : true;
        this.listFields.accountCreatedAt = listFields.accountCreatedAt == false ? false : true;
        this.listFields.location = listFields.location == false ? false : true;
        //this.listFields.city = listFields.city == false ? false : true;
        this.listFields.municipality = listFields.municipality == false ? false : true;
        this.listFields.state = listFields.state == false ? false : true;
        this.listFields.zipCode = listFields.zipCode == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.visitDate = listFields.visitDate == false ? false : true;
        this.listFields.checklistDate = listFields.checklistDate == false ? false : true;
        this.listFields.comment = listFields.comment == false ? false : true;
        this.listFields.paymentPromise = listFields.paymentPromise == false ? false : true;
        this.listFields.paymentPromiseAmount = listFields.paymentPromiseAmount == false ? false : true;
        this.listFields.accountDays = listFields.accountDays == false ? false : true;
        this.listFields.visitsCount = listFields.visitsCount == false ? false : true;
        this.listFields.daysUntilFisrtVisit = listFields.daysUntilFisrtVisit == false ? false : true;
        this.listFields.takenAccountDate = listFields.takenAccountDate == false ? false : true;
        this.listFields.daysTaken = listFields.daysTaken == false ? false : true;
        this.listFields.productType = listFields.productType == false ? false : true;
        this.listFields.visibility = listFields.visibility == false ? false : true;
        this.listFields.calification = listFields.calification == false ? false : true;
        this.listFields.calificationSuperAdministrador = listFields.calificationSuperAdministrador == false ? false : true;
        this.listFields.nombreEtiqueta = listFields.nombreEtiqueta == false ? false : true;
        this.listFields.nombreEtiquetaOperacion = listFields.nombreEtiquetaOperacion == false ? false : true;
        this.listFields.nombreEtiquetaCuenta = listFields.nombreEtiquetaCuenta == false ? false : true;
    }

    private initNulls(visits: Visit[]): void {
        for (var i = 0; i < visits.length; i++) {
            var visit: Visit = visits[i];
            visit.account = visit.account || new Account();
            visit.takenAccount = visit.takenAccount || new TakenAccount();
            visit.generalChecklist = visit.generalChecklist || new GeneralChecklist();
        }
    }

    public loadLenders(cargarFechasDefault: boolean = true): void {
        //let loading = this.app.showLoading('Cargando financieras...');

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;
                if (!this.sessionData.isSuperAdmin) {
                    this.loadFiltersDefault(cargarFechasDefault);
                    this.onChangeLender(this.idLender);
                    this.cargarCosasFinanciera();
                    this.loadVisits(this.visitPaginator.pagination.currentPage > 0 ? this.visitPaginator.pagination.currentPage : 1);
                }
            },
            err => {
                this.app.showError(err);
                //this.app.hideLoading(loading);
            }
        );
    }

    loadFiltersDefault(cargarFechasDefault: boolean = true) {
        this.idLender = this.sessionData.idFinanciera;
        if (cargarFechasDefault) {
            this.filtros.checklistFechaFin = new Date();
            this.filtros.checklistFechaInicio = new Date();
            this.filtros.checklistFechaInicio.setMonth(this.filtros.checklistFechaInicio.getMonth() - 1);
        }
        this.filtros.excludeFinancierasDemo = false;
        this.contabilizadorDeFiltrosTotales();
    }

    loadProducts() {
        this.loadingProducts = true;
        if (this.sessionData.isSuperAdmin) {
            if (this.filtros.financiera) {
                this.lenderService.obtenerProductosCliente(this.filtros.financiera.idCliente)
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            } else {
                this.lenderService.obtenerProductos()
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            }
        } else {
            this.lenderService.obtenerProductosCliente(this.sessionData.idCliente)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadingProducts = false;
                    this.products = res.data;
                }, (err: any) => {
                    this.loadingProducts = false;
                    this.app.showError(err);
                });
        }
    }


    loadEtiquetas() {
        this.etiquetas = [];

        if (this.filtros.financiera) {

            let uri = '/etiquetas';
            this.loadingEtiquetas = true;
            uri += `?idFinanciera=${this.filtros.financiera.idFinanciera}`;

            if (this.filtros.idProducto) {
                uri += `&idProducto=${this.filtros.idProducto}`
            }

            this.visitService.get(uri, true)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadingEtiquetas = false;
                    this.etiquetas = res;
                }, (err: any) => {
                    this.loadingEtiquetas = false;
                    this.app.showError(err);
                });
        }
    }

    loadSucursales() {
        this.sucursales = [];
        let uri = '/sucursales';
        this.loadingEtiquetas = true;

        if (this.filtros.financiera) {
            uri += `?idCliente=${this.filtros.financiera.idCliente}`;
        }

        this.visitService.get(uri, true)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadingEtiquetas = false;
                this.sucursales = res;
            }, (err: any) => {
                this.loadingEtiquetas = false;
                this.app.showError(err);
            }); this.sucursales = [];
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                    if (lender.financieraDemo) {
                        this.filtros.excludeFinancierasDemo = false;
                    }
                }
            });
            if (!this.omitirChecklistFinanciera) {
                this.loadChecklist();
            }
            this.omitirChecklistFinanciera = false;
            this.loadEtiquetas();
            this.cargarEtiquetasCuenta();
            this.loadSucursales();
            this.loadProducts();
        }
        else {
            this.filtros.financiera = null;
        }
        this.contabilizadorDeFiltros('superAdmin');
    }

    public onChangeProduct() {
        if (!this.omitirChecklistProducto) {
            this.loadChecklist();
        }

        this.omitirChecklistProducto = false;
        this.loadEtiquetas();
    }

    public loadChecklist() {
        if (this.sessionData.isSuperAdmin) {
            this.filtros.checklist = null;
            this.errorChecklist = null;

            if (this.filtros.idProducto && (this.filtros.financiera || !this.sessionData.isSuperAdmin)) {
                let lender: number = this.sessionData.isSuperAdmin ? this.filtros.financiera.idCliente : this.sessionData.idCliente;
                let subscription: Subscription = this.visitService.getCheckList(lender, this.filtros.idProducto).subscribe(
                    (res: any) => {
                        this.filtros.checklist = res;
                        this.filtros.checklist.numVersionChecklist = this.filtros.checklist.idVersion;
                        this.filtros.checklist.filtrosChecklistActual = [];
                    },
                    (error) => {
                        this.filtros.checklist = null;
                        this.errorChecklist = error;

                        subscription.unsubscribe();
                    },
                    () => {
                        subscription.unsubscribe();
                    }
                );
            }
        }
    }


    public loadVisits(page?: number, next?: () => void): void {

        this.loadingVisitas = true;
        if (!this.sortField) {
            this.sortField = 'qv.inicioVisita';
        }
        let queryParams: string = '&rows=' + this.pageSize;
        queryParams += this.crearURLQuery();
        this.visitPaginator.pagination.nextPage = page || 1;

        this.visitPaginator.pagination.currentPage = page;
        if (!page) {
            page = 1;
        }

        queryParams += '&page=' + page;

        if (page != this.filtros.page) {
            this.filtros.page = page;
            this.filtrosService.guardarFiltros(this.filtros);
        }

        let subscription: Subscription = this.visitService.obtenerListado(this.visitPaginator.pagination, queryParams, this.pageSize).subscribe(
            (paginator: VisitPaginator) => {
                this.visitPaginator = paginator;
                var visits: Visit[] = paginator.data;
                this.initNulls(visits);
                visits.map(visit => visit.selected = false);
                this.visits = visits;
                this.listFiltered = visits.length > 0;
             

                if (next) {
                    next();
                }
                this.loadingVisitas = false;
            },
            (error) => {
                this.loadingVisitas = false;
                this.app.showError(error);
                subscription.unsubscribe();
            },
            () => {
                this.loadingVisitas = false;
                subscription.unsubscribe();
            }
        );
    }

    public readHashParams(load: boolean): boolean {
        if (!load) {
            return false;
        }

        this.loadVisits(this.visitPaginator.pagination.currentPage > 0 ? this.visitPaginator.pagination.currentPage : 1);

        return true;
    }

    public saveInStorage(): void {
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    public setFilter(): void {

        this.filtros.page = 1;
        this.filtrosService.guardarFiltros(this.filtros);

    
        this.contabilizadorDeFiltrosTotales();

        if (this.totalFiltros <= 1) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return; 
        }

        if (this.filtros.mostrarMapa && this.mapaVisitas) {
            this.visits = [];
            this.consultarVisitasMapa(0, 1);
        } else {
            this.loadVisits(1);
        }
    }

    public setSortField(sort: any) {
        if(sort.isOrder){
            this.sortAsc = sort.direction;
            if (sort.active != this.sortField) {
                this.sortField = sort.active;
            }
            this.loadVisits(1);
        }
    }


    masterToggle() {
        this.isAllSelected ?
            this.visits.filter(v => v.client.activo).forEach(visit => visit.selected = false) :
            this.visits.filter(v => v.client.activo).forEach(visit => visit.selected = true);

        this.isAllSelected = !this.isAllSelected;
        this.showCalifButton = this.isAllSelected && this.visits.filter(v => v.selected).length > 0;
    }


    onCalificar() {

        const dialogRef = this.dialog.open(ModalVisitaDetalleComponent, {
            /*maxWidth: '80vw',
             maxHeight: '74vh',
             height: '90%',*/
            width: 'calc(100% - 100px)',
            //panelClass: 'full-screen-modal',
            data: this.itemsSelected
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadVisits(this.filtros.page);
            }

        });
    }

    onCalificarMasivas() {
        this.visitasACalificar = [];

        if (this.filtros.financiera === null) {
            this.app.showSnackbar('¡Aviso!', 'Se debe filtrar por cliente', 3000, 'warning');
            return;
        }

        this.itemsSelected.forEach(visita => {
            if (
                (this.sessionData.isSuperAdmin && typeof (visita as any).calificacionSuperAdministrador !== 'number') ||
                (!this.sessionData.isSuperAdmin && typeof (visita as any).calificacionAdministradorFinanciera !== 'number')
            ) {
                this.visitasACalificar.push(visita as any);
            }
        });

        if (this.visitasACalificar.length === 0) {
            this.app.showSnackbar('¡Aviso!', 'Las visitas seleccionadas ya se encuentran calificadas.', 3000, 'warning');
            return;
        }

        const dialogRef = this.dialog.open(ModalVisitaDetalleMasivaComponent, {
            height: '500px',
            width: '800px',
            data: {
                visitasACalificar: this.visitasACalificar,
                idCliente: this.filtros.financiera.idCliente,
                idFinanciera: this.filtros.financiera.idFinanciera
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadVisits(this.filtros.page);
            }

        });
    }

    checkSelected() {
        this.showCalifButton = this.visits.findIndex(x => x.selected === true) >= 0;
    }

    salirCalificar(modal: any) {
        this.isAllSelected = false;
        this.visits.forEach(visit => visit.selected = false);
        this.showCalifButton = false;

        modal.approve('approved');
    }

    cargarEtiquetasCuenta() {
        if (this.filtros.financiera) {
            this.loadingEtiquetasCuenta = true;
            this.visitService.obtenerEtiquetasCuenta(this.filtros.financiera.idCliente)
                .pipe(
                    take(1)
                ).subscribe((respuesta: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.etiquetasCuenta = respuesta.data;
                }, (err: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.app.showError(err);
                });
        }
    }

    public loadOperationTags(): void {
        let etiquetas: OperationTag[] = <OperationTag[]>JSON.parse(localStorage.getItem('etiquetasOperaciones'));
        if (etiquetas) {
            this.tags = etiquetas;
        } else {
            let loadingID: number = this.app.showLoading('Cargando etiquetas...');
            this.accountTagService.getAll().subscribe(
                res => {
                    this.tags = res;
                    localStorage.setItem('etiquetasOperaciones', JSON.stringify(res));
                    this.app.hideLoading(loadingID);
                },
                err => {
                    this.app.showError(err);
                    this.app.hideLoading(loadingID);
                },
                () => this.app.hideLoading(loadingID)
            );
        }
    }

    public showMap(mostrar: boolean): void {
        this.visits = [];
        this.filtros.mostrarMapa = true;
        this.consultarVisitasMapa(0, 1);
    }

    public consultarVisitas() {
        this.visits = [];
        //this.mapaVisitas.reniniciarTodosLosLayer(this.visits);
        this.filtros.mostrarMapa = false;
        this.loadVisits(1, () => {
            this.filtrosService.guardarFiltros(this.filtros);
        });
    }

    consultarVisitasMapa(inicio: number, contadorPagina: number) {
        this.isLoadVisitasMapa = true;
        let queryParams: string = this.crearURLQuery();
        let rows = 1000;
        let start = inicio;
        let page = contadorPagina;
        //let cargando = this.app.showLoading('Cargando visitas...');
        this.visitService.obtenerVisitasMapa(start, rows, page, queryParams)
            .pipe(take(1))
            .subscribe((response: any) => {
                for (let i = 0; i < response.pageData.length; i++) {
                    if ((response.pageData[i].latFotos && response.pageData[i].lngFotos) || (response.pageData[i].latInicio && response.pageData[i].lngInicio)) {
                        let visit: Visit = new Visit();
                        // = response.pageData[i].visit.id;
                        // console.log(response.pageData[i]);
                        visit.id = response.pageData[i].idVisita;
                        visit.folio = response.pageData[i].folioVisita;
                        visit.account.id = response.pageData[i].idCuenta;
                        visit.agent.id = response.pageData[i].idCobrador;
                        visit.agent.fullName = response.pageData[i].nombreCobrador;
                        visit.client.idAcreditado = response.pageData[i].idAcreditado;
                        visit.client.fullName = response.pageData[i].nombreAcreditado;
                        visit.client.externalID = response.pageData[i].idExterno;
                        visit.client.subExternalID = response.pageData[i].subIdExterno;
                        visit.generalChecklist.id = response.pageData[i].idVisita;
                        visit.latInicio = response.pageData[i].latInicio;
                        visit.latFin = response.pageData[i].latFin;
                        visit.lngInicio = response.pageData[i].lngInicio;
                        visit.lngFin = response.pageData[i].lngFin;
                        visit.latFotos = response.pageData[i].latFotos;
                        visit.lngFotos = response.pageData[i].lngFotos;
                        visit.grupo = response.pageData[i].grupo;
                        visit.sucursal = response.pageData[i].sucursal;
                        visit.fullAddres = visit.fullAddres = (response.pageData[i].calle ? response.pageData[i].calle + ' ' : '') +
                            (response.pageData[i].numeroExt ? response.pageData[i].numeroExt + ' ' : '') +
                            (response.pageData[i].numeroInt ? response.pageData[i].numeroInt + ', ' : '') +
                            (response.pageData[i].colonia ? response.pageData[i].colonia + ' ' : '') +
                            (response.pageData[i].colonia ? response.pageData[i].codigoPostal + ', ' : '') +
                            (response.pageData[i].municipio ? response.pageData[i].municipio + ', ' : '') +
                            (response.pageData[i].estado ? response.pageData[i].estado + '.' : '');
                        visit.clientAddress.folioDomicilio = response.pageData[i].folioDomicilio;
                        this.visits.push(visit);
                    }
                }

                start += rows;
                page = page + 1;

                if (response.pageData.length < rows) {

                    if (this.filtros.mostrarMapa && this.mapaVisitas) {

                        //this.mapaVisitas.reniniciarTodosLosLayer(this.visits);
                    }

                } else {
                    this.consultarVisitasMapa(start, page);
                }

                this.isLoadVisitasMapa = false;

            }, (err: any) => {
                this.isLoadVisitasMapa = false;
                this.app.showError(err);
                //this.app.hideLoading(cargando)
            },
                () => this.isLoadVisitasMapa = false
            );
    }


    onGrupoFilterSelected(grupo: any) {
        this.grupoFilterFlag = true;
        this.filtros.idGrupo = grupo.id;
    }

    resetGrupoFilter() {
        this.filtros.idGrupo = null;

    }

    resetFinancieraFilter() {
        this.idLender = null;
        this.filtros.financiera = null;
        this.filtros.excludeFinancierasDemo = true;
    }

    onPage(newPagina: any): void {
        if (newPagina.pageIndex + 1 !== this.visitPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize) {
            this.visitPaginator.pagination.currentPage = newPagina.pageSize;
            this.loadVisits(newPagina.pageIndex + 1);
        }
    }

    public onSelected(rows) {
        this.itemsSelected = rows.slice();
    }

    onClick({ row, columnPressed }) {
        if (columnPressed == 'coordenadas') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        } else if (columnPressed === 'client.externalID') {
            this.linkDetalleCuenta(row);
        } else if (columnPressed === 'folio') {
            this.LinkVisitas(row);
        }
    }

    linkDetalleCuenta(cuenta: any) {
        const queryParams = cuenta.account.urlDetalleCuenta;
        this.routeNavigate.navigateByUrl('/cuentas/domicilios/account' + queryParams);

    }

    onCtrlClick({ row, columnPressed }) {
        if (columnPressed === 'client.externalID') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }

    ctrlClickLinkDetalleCuenta(cuenta: any) {
        const url = '/cuentas/domicilios/account' + cuenta.account.urlDetalleCuenta;
        window.open(url, '_blank');

    }

    LinkVisitas(visita: any) {
        this.routeNavigate.navigate(['/visits/visit/' + visita.folio]);
    }

    acreditadoFilterSearch = function (term: string) {

        //this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.blur();
        //return new Promise<any>(resolve => {
        return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera && this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera : null)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAcreditado, nombreCompleto, idCliente, idExterno }) => (
                        { idAcreditado: idAcreditado, nombre: idExterno + ' - ' + nombreCompleto, idCliente: idCliente }
                    )
                ))
            )/*.subscribe((res: any) => {
                    if(callback){
                        callback(res);
                    }
                    //this.acreditadoFilterFounded = res;
                    //resolve(res);

                    setTimeout(() => {
                        if (seleccionarPrimero) {
                            this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.value = this.acreditadoFilterFounded[0].nombre;
                        }
                        else {
                            this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.focus();
                        }
                    }, 200);
                }, (error: any) => {
                    this.app.showError(error);
                })*/;
        //});
    }.bind(this);

    public grupoFilterSearch = function (term: string) {
        let uri = '/clientes-grupos-grid';

        uri += `?nombre=${term}`;
        if (this.filtros.financiera && this.filtros.financiera.idFinanciera) {
            uri += `&idFinanciera=${this.filtros.financiera.idFinanciera}`;
        }

        return this.visitService.get(uri, true)
            .pipe(
                take(1)
            );
    }.bind(this);

    public agenteFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAgentes(term)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idCobrador, nombreCompleto, idAgente }) => (
                        { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                    )
                ))
            );
    }.bind(this);

    LinkCrearVisita() {
        this.routeNavigate.navigate(['/visits/create']);
    }

    resetFilterByName(controlName) {
        this.filtros[controlName] = null;
    }

    public onChangeEstate(event) {
        this.resetFilterByName('municipio');
        this.resetFilterByName('idMunicipio');
        this.loadMunicipios();
    }

    loadEstados(lanzarVisitas: boolean = false): void {
        this.loadings.estados = true;
        this.estados = [];
        this.stateService.get().subscribe(
            (res: any) => {
                this.estados = res;
                this.loadings.estados = false;
                if (lanzarVisitas) {
                    this.loadVisits(this.filtros.page);
                }
            },
            (err: any) => {
                this.app.showError(err);
                this.loadings.estados = false;
            }
        );
    }

    loadMunicipios(lanzarVisitas: boolean = false): void {
        var query: string = '';
        this.loadings.municipios = true;
        this.municipios = [];
        if (this.filtros.idEstado) {
            query = `?idEstado=${this.filtros.idEstado}`

            this.municipalityService.all(query).subscribe(
                (res: any) => {
                    this.municipios = res;
                    this.loadings.municipios = false;
                    if (lanzarVisitas) {
                        this.loadEstados(lanzarVisitas);
                    }
                },
                (err: any) => {
                    this.app.showError(err);
                    this.loadings.municipios = false;
                }
            );
        }
        else {
            this.loadings.municipios = false;
        }
    }

    inactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filtros && this.filtros.financiera){
            this.filtrosService.guardarFiltros(this.filtros, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParamsBounded);
    }
}
