import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Loading } from '../loaders/shared/loading';

declare const jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private errorBS: BehaviorSubject<string>;
  private alertBS: BehaviorSubject<MessageOptions>;
  private loadingBS: BehaviorSubject<Loading>;
  private funcionMostrarLoading: Function;
  private funcionOcultarLoading: Function;
  public snackbarBS: BehaviorSubject<MessageOptions> = new BehaviorSubject<MessageOptions>({ title: '', message: '' });

  public $: any = jQuery;
  public error: Observable<string>;
  public alert: Observable<MessageOptions>;
  public loading: Observable<Loading>;

  constructor(
    private snackBar: MatSnackBar
  ) {
    this.errorBS = new BehaviorSubject(null);
    this.error = this.errorBS.asObservable();
    this.alertBS = new BehaviorSubject(null);
    this.alert = this.alertBS.asObservable();
    this.loadingBS = new BehaviorSubject(null);
    this.loading = this.loadingBS.asObservable();
  }

  public setFunciones(loading: Function, ocultar: Function){
    this.funcionMostrarLoading = loading;
    this.funcionOcultarLoading = ocultar;
  }

  public showError(msg: string): void {
    this.showSnackbar('', msg, 0, 'error');
  }

  public showAlert(title: string, msg: string): void {
    let mess: MessageOptions = new MessageOptions();
    mess.title = title;
    mess.message = msg;
    this.alertBS.next(mess);
  }

  public showLoading(msg: string): number {
    /*let loading = new Loading(null, msg);
    this.loadingBS.next(loading);
    return loading.id;*/
    this.funcionMostrarLoading(msg);
    return 0;
  }

  public hideLoading(id: number = 0): void {
    this.funcionOcultarLoading();
    /*let loading = new Loading(id, null);
    this.loadingBS.next(loading);*/
  }

  public showSnackbar(title: string, msg: string, duration: number, cssClass: string) {
    const mess: SnackBarOpts = { title: title, message: msg, duration: duration, cssClass: cssClass };

    let durationMessage: number = 4000;
    switch(cssClass){
      case 'error':
        durationMessage = 6000;
        break;
      case 'warning':
        durationMessage = 3000;
        break;
      case 'success':
        durationMessage = 4000;
        break;
    }
    this.snackBar.open(`${msg}`, 'X', {
      duration: durationMessage,
      panelClass: cssClass});
    //this.snackbarBS.next(mess);
  }
}

export class MessageOptions {
  title: string;
  message: string;
}

export interface SnackBarOpts {
  title: string;
  message: string;
  duration: number;
  cssClass: string; // success warning negative
}
