<h1 mat-dialog-title>Formatos</h1>
<mat-dialog-content>
  <div content style="max-height: 250px; overflow: auto;">
    <div *ngFor="let archivo of formatosDescargados; let i = index;">                    
      <mat-card class="card">  
        <mat-card-content>
          <div style="display: flex; margin-bottom: 5px;">
            <mat-icon *ngIf="archivo.idAgente" 
              matTooltip="Descargado por el agente"
              mat-tooltip-panel-above
              class="iconoDoc kobra-icon margen-icon">
              file_download_done
            </mat-icon>
            <mat-icon *ngIf="!archivo.idAgente"
              matTooltip="No descargado por el agente"
              mat-tooltip-panel-above
              color="warn" 
              class="iconoDoc margen-icon">
              file_download_off
            </mat-icon>
            <span class="nombre-archivo">{{archivo.nombreArchivo}}</span> 
            <div class="iconoGrid">
              <button mat-icon-button       
                matTooltip="Descripción"
                mat-tooltip-panel-above
                (click)="ayudaDescripcion(archivo)">
                <mat-icon color="primary">help</mat-icon>
              </button>
              <button mat-icon-button
                [matMenuTriggerFor]="menuDescarga"
                matTooltip="Descargar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">cloud_download</mat-icon>
              </button>
            </div> 
          </div>
          <div *ngIf="archivo.isDescripcion" class="margen-icon">
            <span class="letra-negrita">Descripción</span>
            <p class="descripcion">{{archivo.descripcion}}</p>
          </div>
          <div style="display: flex;" class="iconoGrid">
            <i style="color: #38373782;">{{momentFecha(archivo.fechaRegistro).format('DD/MM/YYYY')}}</i>
          </div>
          <mat-menu #menuDescarga="matMenu">
            <button mat-menu-item 
              (click)="descargarArchivo(archivo, 'pdf')">
              <mat-icon class="iconoPdf">picture_as_pdf</mat-icon>
              <span>Pdf</span>
            </button>
            <button mat-menu-item 
              (click)="descargarArchivo(archivo, 'word')">
              <mat-icon class="iconoWord">description</mat-icon>
              <span>Word</span>
            </button>
          </mat-menu>
        </mat-card-content>                  
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
</mat-dialog-actions>
<mat-card-footer *ngIf="descargandoArchivo"  class="kobra-card-footer" >
  <i>Descargando archivo...</i>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card-footer>

