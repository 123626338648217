import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';

import { LoaderService } from "../../../../loader/loader.service";
import { MessageService } from "../../../../shared/message/message.service";
import { PersonalReference } from "../personal-reference.model";
import { PersonalReferenceService } from "../personal-reference.service";
import { PersonalReferenceModalFormComponent } from "../personal-reference-modal-form/personal-reference-modal-form.component";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationService } from 'src/app/application/shared/application.service';

declare let $: any;

@Component({
  selector: 'personal-reference-editable-list',
  templateUrl: './personal-reference-editable-list.component.html',
  styleUrls: ['./personal-reference-editable-list.component.css'],
  providers: [
    PersonalReferenceService,
    PersonalReferenceModalFormComponent
  ]
})
export class PersonalReferenceEditableListComponent implements OnInit, OnChanges {
  public reference: PersonalReference;
  public references: PersonalReference[];
  public showSegmentLoader: boolean = false;
  public sessionData: SessionData;
  isRegistros = 0;

  @Input() public loader: boolean;
  @Input() agentID: number;
  @Input() deleted: boolean;

  ngOnChanges() {
    this.reference = new PersonalReference();
    this.loadReferences(this.agentID);
  }

  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private personalReferenceService: PersonalReferenceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private app: ApplicationService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
   }

  ngOnInit() {
  }

  public deleteReference( personalReference: PersonalReference ): void {
    let index = this.references.findIndex(referencia => referencia.id == personalReference.id);
    this.references.splice(index, 1);
  }

  private hideLoader(): void {
    if (this.loader) {
      this.loaderService.hideHttpLoader();
    } else {
      this.showSegmentLoader = false;
    }
  }

  public loadReferences( agentID: number ) {
    this.showLoader();
    this.personalReferenceService.all(agentID).subscribe(
      (references) => {
        this.hideLoader();
        this.isRegistros = references.length;
        this.references = references;
      },
      (error) => {
        this.hideLoader();
        this.messageService.httpError(error);
      }
    );
  }

  public showForm( reference?: PersonalReference ): void {
    this.reference = reference || new PersonalReference();;
    const dialogRef = this.dialog.open(PersonalReferenceModalFormComponent, {
      disableClose: false,
      width: '50%',
      data: {
        personalReference: this.reference,
        agentID: this.agentID,
        totalReferencias: this.references?.length,
        afterSave: this.updateReference.bind(this),
        afterDelete: this.deleteReference.bind(this),
        nuevo: false
      }
      
    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  private showLoader(): void {
    if (this.loader) {
      this.loaderService.showHttpLoader();
    } else {
      this.showSegmentLoader = true;
    }
  }

  public updateReference( personalReference: PersonalReference ): void {
    let index = this.references.findIndex(referencia => referencia.id == personalReference.id);

    if (index >= 0) {
      this.references.splice(index, 1, personalReference);
    } else {
      this.references.push(personalReference);
    }
  }

  eliminar(reference: PersonalReference){
    const loader = this.app.showLoading('Eliminando referencia personal...')
    reference.totalReferenciasPersonales = this.references?.length - 1;
    this.personalReferenceService.delete(reference.id, reference).subscribe({
        next: () => {
          this.app.showSnackbar('¡Aviso!', 'Referencia personal eliminada correctamente.', 3000, 'success');
          this.app.hideLoading(loader);
          this.deleteReference(reference);
        },
        error: (error) => {
            this.app.hideLoading(loader);
            this.app.showError(error);
        }
    });
  }
}
