<form [formGroup]="form" class="ui form" (submit)="save()">
  <div class="contenidoConScroll" style="height: 255px; overflow-x: hidden;">
    <div class="kobra three columns">
      <div class="kobra column">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput textSanitizer  type="text" formControlName="name" maxlength="150">
          <mat-error *ngIf="form.controls['name'].invalid && form.controls['name'].touched">
            {{ nameValidation.error }}
          </mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-form-field>
          <mat-label>Apellido paterno</mat-label>
          <input matInput textSanitizer  type="text" formControlName="firstSurname" maxlength="100">
          <mat-icon matSuffix>person</mat-icon>
          <mat-error *ngIf="form.controls['firstSurname']?.invalid && form.controls['firstSurname']?.touched">
            {{ firstSurnameValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-form-field>
          <mat-label>Apellido materno</mat-label>
          <input matInput textSanitizer  type="text" formControlName="secondSurname" maxlength="50">
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
      </div>

      <div class="kobra column">
        <mat-form-field>
          <mat-label>CURP</mat-label>
          <input matInput textSanitizer  type="text" formControlName="curp" maxlength="20"
            pattern="[A-Z]{4}\d{6}[HM][A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9][0-9]" (input)="onCurpInputChange($event)">
          <mat-icon matSuffix>badge</mat-icon>
          <mat-error *ngIf="form.controls['curp']?.invalid && form.controls['curp']?.touched">
            {{ curpValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-form-field>
          <mat-label>RFC</mat-label>
          <input matInput textSanitizer  type="text" formControlName="rfc" maxlength="20"
            pattern="^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$"
            (input)="onRfcInputChange($event)">
          <mat-icon matSuffix>badge</mat-icon>
          <mat-error *ngIf="form.controls['rfc']?.invalid && form.controls['rfc']?.touched">
            {{ rfcValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-date-picker-formater [label]="'Fecha nacimiento'" [placeHolder]="'Capture la fecha'"
          formControlName="birthdate" [limpiar]="false"/>
      </div>

      <div class="kobra column">
        <mat-form-field>
          <mat-label>Teléfono fijo</mat-label>
          <input matInput textSanitizer  type="text" formControlName="phone" maxlength="20">
          <mat-icon matSuffix>call</mat-icon>
          <mat-error *ngIf="form.controls['phone']?.invalid && form.controls['phone']?.touched">
            {{ phoneValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-form-field>
          <mat-label>Teléfono celular</mat-label>
          <input matInput textSanitizer  type="text" formControlName="cellphone" maxlength="20">
          <mat-icon matSuffix>phone_iphone</mat-icon>
          <mat-error *ngIf="form.controls['cellphone']?.invalid && form.controls['cellphone']?.touched">
            {{ cellphoneValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="kobra column">
        <mat-form-field>
          <mat-label>INE</mat-label>
          <input matInput textSanitizer  type="text" formControlName="clvIfe" maxlength="50">
          <mat-icon matSuffix>badge</mat-icon>
          <mat-error *ngIf="form.controls['clvIfe']?.invalid && form.controls['clvIfe']?.touched">
            {{ clvIfeValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="kobra column">
        <mat-form-field>
          <mat-label>Correo</mat-label>
          <input matInput textSanitizer  type="text" formControlName="email" maxlength="50">
          <mat-icon matSuffix>mail</mat-icon>
          <mat-error *ngIf="form.controls['email']?.invalid && form.controls['email']?.touched">
            {{ emailValidation.error }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

   <div class="field" align="right" style=" margin-top: -10px;">
    <button  mat-flat-button class="boton-cancelar" (click)="cancel()" style="margin-right: 10px;">
      <mat-icon  class="kobra-icon">
        close
      </mat-icon>Cancelar</button>
    <button mat-stroked-button class="kobra-button" [disabled]="form.invalid">
      <mat-icon>
        save
      </mat-icon>Guardar</button>
  </div>

</form>