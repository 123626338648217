import { FiltrosService } from './../../application/shared/filtros.service';
import { FiltrosCuentas } from './../../account/modelos/filtros-cuentas.model';
import { AccountService } from './../../account/account.service';
import { AccountPaginator } from './../../account/account.paginator';
import { OperationTag } from './../../operation-tag/operation-tag.model';
import { AgentService } from './../../agent/agent.service';
import { Lender } from './../../lender/lender.model';
import { LenderService } from './../../lender/lender.service';
import { VisitService } from './../../visit/visit.service';
import { MessageService } from './../../shared/message/message.service';
import { StateService } from './../../state/state.service';
import { MunicipalityService } from './../../municipality/municipality.service';
import { environmentSelector } from './../../../environments/environment.selector';
import { FormValidation } from './../../core/form-validation/form-validation';
import { ApplicationService } from './../../application/shared/application.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { SessionData } from './../../shared/interfaces/session-data';
import { ModalAsignarCuentaComponent } from './../../account/modales/asignar/asignar-cuenta.component';
import { ModalEstatusCuentaComponent } from './../../account/modales/estatus/estatus-cuenta.component';
import { ModalEtiquetaOperacionesComponent } from './../../account/modales/etiqueta-operaciones/etiqueta-operaciones.component';

import { Component, ElementRef, OnInit, HostListener, ViewChild } from '@angular/core';

import { Account } from "../../account/account.model";
import { CuentaAgrupadaPaginator } from "./modelos/cuenta-agrupada.paginator";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';   
import moment from 'moment';
import * as turf from '@turf/turf';
import { CuentaService } from "../../cuentas/shared/cuenta.service";
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router , NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { TablaParametros } from 'src/app/shared/components/table/modelos/tabla-parametros.model';
import { CuentaAgrupada } from './modelos/cuenta-agrupada.model';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

declare let $: any;

@Component({
    selector: 'cuentas-agrupadas',
    templateUrl: 'cuentas-agrupadas-list.component.html',
    styleUrls: [
        'cuentas-agrupadas-list.component.css'
    ],
    providers: [
        AccountService,
        AgentService,
        LenderService,
        VisitService,
        AgentService,
        StateService,
        MunicipalityService,
        CuentaService
    ]
})

export class CuentasAgrupadasListComponent implements OnInit {
    private environment = environmentSelector();
    sessionData: SessionData;
    filtrosAbiertos: boolean = false;
    public pageSize: number = 50;
    private selectedAccount: any;
    private resizeObserver: ResizeObserver;

    public accountPaginator: AccountPaginator;
    cuentaAgrupadaPaginator: CuentaAgrupadaPaginator;
    cuentasAgrupadas: CuentaAgrupada[] = [];
    public accounts:any;
    public accountsSelected: Account[];
    public agentSearchText: string;
    public assignComment: string;
    public assignMessage: string;
    public idLender: any = null;
    public filterLender: any = null;
    public filterVisibility: any = null;
    public filterAccountAssignedEndDate: Date;
    public filterAccountAssignedStartDate: Date;
    public filterAccountEndDate: Date;
    public filterAccountStartDate: Date;
    public filterEstatus: any = null;
    public filterGeoloc: any = null;
    public filterProductType: any = null;
    public filterTag: any = null;
    public dibujando = false;
    public eventoCreado = false;

    public httpError: any;
    public lenders: Lender[];
    public listFields: any = {};
    public listFiltered: boolean;
    public search: string;
    public selectedAgent: any = null;
    public selectedStatus: any = null;
    public selectedTag: any = null;
    public showActions: boolean;
    public sortField: string;
    public sortAsc: boolean;
    public tags: OperationTag[];
    public etiquetas: Array<any>;
    public sucursales: Array<any>;
    public today: string = new Date().toISOString();

    public fechaCreacionMaxDate: Date;
    public fechaCreacionMinDate: Date;
    public fechaAsignacionMaxDate: Date;
    public fechaAsignacionMinDate: Date;
    public fechaImportacionMaxDate: Date;
    public fechaImportacionMinDate: Date;
    public fechaAsignacionCuentaMinDate: Date = new Date();
    public fechaAsignacionCuentaMaxDate: Date;

    public financieraVld: FormValidation;
    public visibilidadVld: FormValidation;
    public etiquetaVld: FormValidation;
    public estatusVld: FormValidation;
    public geolocVld: FormValidation;
    public tipoCuentaVld: FormValidation;
    public fechaCreacionVld: FormValidation;
    public fechaAsignacionVld: FormValidation;

    public tagLoader = false;
    public setTagMsg: string;

    public labelColonia: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelCodigoPostal: string;
    public pais: string;
    public nonSelectedTagWarning: boolean = false;
    public estados: any[];
    public establecerVisita: boolean = true;
    public establecerFecha: boolean = true;
    public styleModel: string = "hidden";
    public formAsignacion: FormGroup;
    public horariosVisitas = {};
    public fechaVisita: string;
    public fechaLimite: string;
    public numDiasLimite: number;
    public idCobrador: number;
    public momentFecha;
    public diasVencimiento: number;
    public mapHeight: number = 600;
    public asignarTodasLasCuentas = false;
    public loadingEtiquetasCuenta: boolean = false;
    public etiquetasCuenta = [];
    public geoCercaACrear:any = {};
    public contadorCuentas:number = 0;
    public contadorSeleccion:number = 0;
    public cuentasSeleccionadas: number = 0;
    public asignarSeleccion: boolean =  true;
    public redInterna: boolean= false;
    public motivos: any;
    public etiquetasOperaciones: any;
    public ayuda: boolean = false;
    private isLoad = true;
    public hijosActivos: boolean = false;
    public tabla: any;
    public tablaCuentas: TablaParametros;
    public isLoadCuentasMapa = false;
    public altoVentana: number = 0;
    itemsSelected: Array<any>[] = [];
    clienteActivo: boolean = false;
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    public queryParamsGlobal: string;

    public products: Array<any>;
    public loadingProducts: boolean;
    slt: string = '"idCliente", "cliente", "idExterno", "idAcreditado", "nombre", "fechaRegistro", "fechaImportacion", visibilidad';
    adip: string = '"accountDaysInPlatform"';

    public subscribeRouterParamsBounded: Function;

    public loadings: any = {
        sucursales: false,
        etiquetas: false,
        municipios: false,
        estados: true
    }

    public filtros: FiltrosCuentas;
    public fechas = {
        fechaCreacionInicioFormateada: ''
    }

    public totalFiltros: number = 0;
    public contabilizadorFiltros: FiltersContabilizer;

    constructor(
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private localStorageService: LocalStorageService,
        private accountService: AccountService,
        private lenderService: LenderService,
        private visitService: VisitService,
        private messageService: MessageService,
        private rootElement: ElementRef,
        private stateService: StateService,
        private municipalityService: MunicipalityService,
        private agenteServ: AgentService,
        public dialog: MatDialog,
        private cuentaService: CuentaService,
        private dateAdapter: DateAdapter<Date>,
        private router: Router,
        private location: Location
    ) {
        this.accounts = [];
        this.labelColonia = this.environment.labelColonia;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.labelCodigoPostal = this.environment.labelCodigoPostal;
        this.pais = this.environment.pais;
        this.crearTabla();
        this.altoVentana = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.fechaCreacionMaxDate = new Date();
        this.fechaAsignacionMaxDate = new Date();
        this.fechaImportacionMaxDate = new Date();
        this.sessionData = this.localStorageService.getSessionData();
        this.dateAdapter.setLocale('es-MX');
        this.filtros = new FiltrosCuentas();
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
        this.contabilizadorDeFiltrosTotales();
    }

    ngOnInit() {
        this.altoVentana = this.altoVentana * (1 - 0.3);
        this.search = '';
        this.sortField = 'fechaRegistro';
        this.sortAsc = false;
        this.accountPaginator = new AccountPaginator();
        this.cuentaAgrupadaPaginator = new CuentaAgrupadaPaginator();
        this.mapHeight = window.innerHeight * .70;
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              this.actualizarTamaño();
            }
          });
      
          this.resizeObserver.observe(this.rootElement.nativeElement);
        this.actualizarTamaño();
    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'idCliente',
            'excludeFinancierasDemo'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'idExterno',
            'acreditado',
            'visibilidad'
        ]);

        this.contabilizadorFiltros.add('Fechas creación', 'fechasC', [
            'fechaCreacionInicio',
            'fechaCreacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas importación', 'fechasI', [
            'fechaImportacionInicio',
            'fechaImportacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas total', 'fechasTotal', [
            'fechaCreacionInicio',
            'fechaCreacionFin',
            'fechaImportacionInicio',
            'fechaImportacionFin'
        ]);
    }

    public contabilizadorDeFiltrosTotales():void{
        
        let totalFiltrosArray: number[] = [];
        if(this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArray.push( this.contabilizadorDeFiltros('cuenta') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasC') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasI') );

        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
    }

    private loadMotivos(): void {
        this.visitService.obtenerEtiquetasOperaciones()
            .subscribe(
                motivos => {
                    this.motivos = motivos;
                },
                error => this.app.showError(error)

            );
    }

    actualizarTamaño() {
        const width = this.rootElement.nativeElement.offsetWidth;
        this.tablaCuentas.anchoMaximoDetalle = width - 60;
    }

    crearTabla() {
        this.tablaCuentas = {
            headers: false,
            multiSelect: false,
            loading: false,
            onShowDetail: this.onLoadDetalle.bind(this),
            sortChange: this.setSortField.bind(this),
            classContainer: 'tabla-completa',
            expandProperty: 'detalle',
            typeExpandTable: "app-grid",
            columns: [
                {
                    name: 'Cliente',
                    key: 'cliente',
                    hide: false
                }, {
                    name: 'ID Cuenta',
                    key: 'idExterno',
                    hide: false,
                }, {
                    name: 'Cuenta',
                    key: 'nombre',
                    hide: false
                }, {
                    name: 'Fecha Creación',
                    key: 'fechaRegistro',
                    type: 'dateTime',
                    hide: false
                }, {
                    name: 'Última Fecha Importación',
                    key: 'fechaImportacion',
                    type: 'dateTime',
                    hide: false
                }, {
                    name: 'Visibilidad',
                    key: 'visibilidad',
                    hide: false
                }
            ],
            expandParams: {
                headers: false,
                multiSelect: true,
                loading: false,
                classContainer: 'sub-tabla',
                onSelectedRows: this.onSelected.bind(this),
                onLinkRow: this.onClick.bind(this),
                onCtrlClicLinkRow: this.onCtrlClick.bind(this),
                columns: [
                    {
                        name: 'Cliente',
                        key: 'lender.name',
                        hide: false
                    },
                    {
                        name: 'ID Cuenta',
                        key: 'client.externalID',
                        hide: false,
                        type: 'link',
                    },
                    {
                        name: 'ID Subcuenta',
                        key: 'client.subExternalID',
                        hide: false
                    },
                    {
                        name: 'Folio Domicilio',
                        key: 'client.address.folioDomicilio',
                        hide: false
                    }, {
                        name: 'Cuenta',
                        key: 'client.fullName',
                        hide: false
                    }, {
                        name: 'Fecha de Creación',
                        key: 'createdAt',
                        type: 'dateTime',
                        hide: false
                    }, {
                        name: 'Producto',
                        key: 'product.name',
                        hide: false
                    }, {
                        name: 'Estatus',
                        key: 'status',
                        hide: false,
                        type: 'estatus'
                    }, {
                        name: 'Etiqueta Kobra',
                        key: 'tag.name',
                        hide: false
                    }, {
                        name: 'Etiqueta Cuenta',
                        key: 'nombreEtiquetaCuenta',
                        hide: false
                    }, {
                        name: 'Grupo',
                        key: 'grupo',
                        hide: false
                    }, {
                        name: 'Visitas',
                        key: 'totalVisitas',
                        hide: false
                    }, {
                        name: 'Sucursal',
                        key: 'sucursal',
                        hide: false
                    }, {
                        name: 'ID Agente',
                        key: 'taken.agent.id',
                        hide: true
                    }, {
                        name: 'Agente',
                        key: 'taken.agent.fullName',
                        hide: false
                    }, {
                        name: 'Fecha Apartada',
                        key: 'taken.takenDate',
                        hide: false,
                        type: 'dateTime'
                    }, {
                        name: 'Fecha Vencimiento',
                        key: 'taken.expirationDate',
                        hide: false,
                        type: 'date',
                        sortName: 'aca.fec_limite'
                    }, {
                        name: 'Fecha Asignada',
                        key: 'assigned.assignedDate',
                        hide: false,
                        type: 'dateTime'
                    }, {
                        name: 'Fecha Visita',
                        key: 'assigned.fecVisita',
                        hide: false,
                        type: 'dateTime'
                    }, {
                        name: 'Días Restantes',
                        key: 'daysUntilExpiration',
                        hide: false,
                        sortName: 'aca.fec_limite'
                    }, {
                        name: 'Días en Plataforma',
                        key: 'daysInPlatform',
                        hide: false
                    }, {
                        name: 'Días en Apartada',
                        key: 'daysTaken',
                        hide: false
                    }, {
                        name: 'Días Moratorios',
                        key: 'daysPastDue',
                        hide: false
                    }, {
                        name: 'Domicilio',
                        key: 'client.address.fullAddress',
                        hide: false
                    }, {
                        name: this.labelMunicipio,
                        key: 'client.address.municipality',
                        hide: false
                    }, {
                        name: this.labelEstado,
                        key: 'client.address.state',
                        hide: false
                    }, {
                        name: this.labelColonia,
                        key: 'client.address.suburb',
                        hide: false
                    }, {
                        name: 'Teléfono Celular',
                        key: 'telefonoCelular',
                        hide: false,
                        sortName: 'a.tel_cel'
                    }, {
                        name: 'Tipo Cobertura',
                        key: 'tipoCobertura',
                        hide: false
                    }, {
                        name: 'Geolocalización',
                        key: 'coordenadas',
                        hide: false,
                        type: 'link'
                    },{
                        name: 'Visibilidad',
                        key: 'visibility',
                        hide: false
                    }
                ]
            }
        }
    }

    formatearFecha(){
        this.fechas.fechaCreacionInicioFormateada = moment(this.filtros.fechaCreacionInicio).format('DD/MM/YYYY');
    }

    public onInputLimite(): void{
        if(+this.filtros.diasMoratorios > 9999){
            setTimeout(()=>{
              this.filtros.diasMoratorios = 9999+'';
            }, 10);
        }
      }

    public obtenerClienteRedInterna() {

        if (!this.sessionData.isSuperAdmin) {

            this.lenderService.obtenerClienteRedInterna(this.sessionData.idCliente)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.redInterna = res.data.redInterna;
            }, (err: any) => {
                this.app.showError(err);
            });
        }
    }

    private subscribeRouterParams(filtrosNuevos: FiltrosCuentas): void {
        var iniciar = false;
        if (filtrosNuevos) {
            this.listFiltered = true;
            iniciar = true;
            this.filtros = filtrosNuevos;
            if (this.filtros.mostrarMapa) {
                this.pageSize = 1000;
            }
            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;
            this.accountPaginator.pagination.currentPage = this.filtros.page || 1;

            if (this.filtros.idMunicipio) {
                iniciar = false;
            }
            else if (this.filtros.idEstado) {
                iniciar = false;
            }
        }

        if (!(this.sessionData.isSuperAdmin)) {
            this.filtros.excludeFinancierasDemo = false;
        }

        this.initListColumns();
        this.loadMotivos();


        this.loadLenders();
        if (this.sessionData.isSuperAdmin) {
            this.loadCombos(iniciar);
        }

        this.isLoad = false;
    }

    private loadCombos(iniciar = true) {
        if (iniciar) {
            this.readHashParams();
        }
    }


    public selectedAgentDropdown(agent: number): void {
        if (agent) {
            this.selectedAgent = +agent;
        }
    }

    public clearFilter(): void {
        Object.keys(this.filtros).forEach(key => {
            if((this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera'))){
              this.filtros[key] = null;
            }
          });
        this.filtros.excludeFinancierasDemo = this.sessionData.isSuperAdmin ? true : false;
        this.filtros.agenteActivo = true;
        this.filtros.page = 1;
        this.filtrosService.removerFiltros();
        this.idLender = null;
        this.listFiltered = false;

    }

    createExpandQueryFilter(slt: string, orderBy: string, filtros: FiltrosCuentas){
        let query: string = '';

        query += '&' + orderBy;

        if (filtros.idExterno) query += '&idExterno=' + filtros.idExterno.toString().replace(/\+/g, '%2B');
        if (filtros.visibilidad) query += '&visibilidad=' + filtros.visibilidad.toString();
        if (filtros.acreditado) query += '&idAcreditado=' + filtros.acreditado.idAcreditado.toString();
        if (filtros.idCliente) query += '&idCliente=' + filtros.idCliente.toString();
        if (filtros.excludeFinancierasDemo) query += '&excludeFinancierasDemo=' + filtros.excludeFinancierasDemo.toString();

        if (filtros.fechaCreacionInicio) query += '&cuentaCreacionFechaInicio=' + moment(filtros.fechaCreacionInicio).format('YYYY-MM-DD');
        if (filtros.fechaCreacionFin) {
            /*let date: Date = new Date(filtros.fechaCreacionFin.getTime());
            date.setDate(filtros.fechaCreacionFin.getDate() + 1);*/
            query += '&cuentaCreacionFechaFin=' + moment(filtros.fechaCreacionFin).format('YYYY-MM-DD');
        }
        if (filtros.fechaImportacionInicio) query += '&cuentaImportadaFechaInicio=' + moment(filtros.fechaImportacionInicio).format('YYYY-MM-DD');
        if (filtros.fechaImportacionFin) {
            /*let date: Date = new Date(filtros.fechaImportacionFin.getTime());
            date.setDate(filtros.fechaImportacionFin.getDate() + 1);*/
            query += '&cuentaImportadaFechaFin=' + moment(filtros.fechaImportacionFin).format('YYYY-MM-DD');
        }

        query += `&slt=${slt}`;

        return query;
    }

    public createQueryFilter(): string {
        this.queryParamsGlobal = '';

        // query += '&query=' + this.formBusqueda.value.buscar;

        if (this.sortField) {
            this.queryParamsGlobal += '&orderBy=' + this.sortField + (this.sortAsc ? ' asc' : ' desc');
        }
        else{
            this.queryParamsGlobal += '&orderBy=fechaRegistro desc';
        }

        if (this.filtros.financiera) this.queryParamsGlobal += '&idFinanciera=' + this.filtros.financiera.idFinanciera.toString();
        if (this.filtros.idExterno) this.queryParamsGlobal += '&idExterno=' + this.filtros.idExterno.toString().replace(/\+/g, '%2B');
        if (this.filtros.visibilidad) this.queryParamsGlobal += '&visibilidad=' + this.filtros.visibilidad.toString();
        if (this.filtros.acreditado) this.queryParamsGlobal += '&idAcreditado=' + this.filtros.acreditado.idAcreditado.toString();
        if (this.filtros.financiera) this.queryParamsGlobal += '&idCliente=' + this.filtros.financiera.idCliente.toString();
        if (this.filtros.excludeFinancierasDemo) this.queryParamsGlobal += '&excludeFinancierasDemo=' + this.filtros.excludeFinancierasDemo.toString();

        if (this.filtros.fechaCreacionInicio) this.queryParamsGlobal += '&cuentaCreacionFechaInicio=' + moment(this.filtros.fechaCreacionInicio).format('YYYY-MM-DD');
        if (this.filtros.fechaCreacionFin) {
            /*let date: Date = new Date(this.filtros.fechaCreacionFin.getTime());
            date.setDate(this.filtros.fechaCreacionFin.getDate() + 1);*/
            this.queryParamsGlobal += '&cuentaCreacionFechaFin=' + moment(this.filtros.fechaCreacionFin).format('YYYY-MM-DD');
        }
        if (this.filtros.fechaImportacionInicio) this.queryParamsGlobal += '&cuentaImportadaFechaInicio=' + moment(this.filtros.fechaImportacionInicio).format('YYYY-MM-DD');
        if (this.filtros.fechaImportacionFin) {
            /*let date: Date = new Date(this.filtros.fechaImportacionFin.getTime());
            date.setDate(this.filtros.fechaImportacionFin.getDate() + 1);*/
            this.queryParamsGlobal += '&cuentaImportadaFechaFin=' + moment(this.filtros.fechaImportacionFin).format('YYYY-MM-DD');
        }

        this.queryParamsGlobal += `&slt=${this.slt}`;

        return this.queryParamsGlobal;
    };

    public descargarListado(): void {
        /*if(!this.idLender){
            this.app.showSnackbar('Aviso', 'No se puede descargar el listado, no se ha seleccionado una cliente', 3000, 'warning');
            return;
        }*/
        let queryParams = `?start=0` + this.queryParamsGlobal;

        if (this.cuentasAgrupadas) {
            if (this.cuentasAgrupadas.length > 0) {
                this.accountService.descargarExcelCuentasAgrupadas(queryParams)
                    .pipe(take(1))
                    .subscribe((respuesta) => {
                        this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 10000, 'success');
                    }, (error: any) => {
                        this.app.showError(error);
                    });
            } else {
                this.app.showSnackbar("Aviso", "No hay resultados para descargar", 5000, 'warning');
            }
        } else {
            this.app.showSnackbar("Aviso", "No hay resultados para descargar", 5000, 'warning');
        }
    }

    /*private getSelectedAccounts(): Account[] {
        let accounts = this.itemsSelected;

        for (var i = 0; i < this.accounts.length; i++) {
            let account: Account = new Account();
                account.id = this.accounts[i].id;
                account.lenderID = this.accounts[i].lender.id;
                account.idCliente = this.accounts[i].idCliente;
                account.client.idAcreditado = this.accounts[i].client.idAcreditado;
                account.client.externalID = this.accounts[i].client.externalID;
                account.idAcreditadoPG = this.accounts[i].idAcreditadoPG;
                account.idCuentaPG = this.accounts[i].idCuentaPG;
                account.domicilios = [{
                    idDomicilioAcreditado: this.accounts[i].client.address.idDomicilioAcreditado,
                    idAcreditadoCuentaDomicilio:this.accounts[i].idAcreditadoCuentaDomicilio
                }];
                account.idAcreditadoCuentaDomicilio = this.accounts[i].idAcreditadoCuentaDomicilio
                accounts.push(account);
        }
        return accounts;
    }*/

    private initListColumns(): void {
        let listFields: any = JSON.parse(localStorage.getItem('accountListFields')) || {};
        this.listFields.accountCreatedAt = listFields.accountCreatedAt == false ? false : true;
        this.listFields.lenderName = listFields.lenderName == false ? false : true;
        this.listFields.nameProduct = listFields.nameProduct == false ? false : true;
        this.listFields.clientID = listFields.clientID == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.clientFullName = listFields.clientFullName == false ? false : true;
        this.listFields.accountStatus = listFields.accountStatus == false ? false : true;
        this.listFields.accountTag = listFields.accountTag == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.accountTakenDate = listFields.accountTakenDate == false ? false : true;
        this.listFields.accountAssignedDate = listFields.accountAssignedDate == false ? false : true;
        this.listFields.accountExpirationDate = listFields.accountExpirationDate == false ? false : true;
        this.listFields.accountDaysInPlatform = listFields.accountDaysInPlatform == false ? false : true;
        this.listFields.accountUntilFirstVisit = listFields.accountUntilFirstVisit == false ? false : true;
        this.listFields.accountDaysTaken = listFields.accountDaysTaken == false ? false : true;
        this.listFields.accountMoraDays = listFields.accountMoraDays == false ? false : true;
        this.listFields.clientLocation = listFields.clientLocation == false ? false : true;
        this.listFields.clientCity = listFields.clientCity == false ? false : true;
        this.listFields.clientMunicipality = listFields.clientMunicipality == false ? false : true;
        this.listFields.clientState = listFields.clientState == false ? false : true;
        this.listFields.clientSuburb = listFields.clientSuburb == false ? false : true;
        this.listFields.clientAddressGeoloc = listFields.clientAddressGeoloc == false ? false : true;
        this.listFields.visibility = listFields.visibility == false ? false : true;
        this.listFields.fecVisita = listFields.fecVisita == false ? false : true;
        this.listFields.visitGroup = listFields.visitGroup == false ? false : true;
        this.listFields.countVisits = listFields.countVisits == false ? false : true;
        this.listFields.sucursal = listFields.sucursal == false ? false : true;
        this.listFields.nombreEtiquetaCuenta = listFields.nombreEtiquetaCuenta == false ? false : true;
    }

    public cuentasAgrupadasObtener(page?: number, next?: () => void, deactivateHijos: boolean = false): void {
        
        page = page || 1;
        let queryParams: string = '&rows=' + this.pageSize + '&page=' + page;
        this.tablaCuentas.loading = true;
        //Se agrega para validar que no haya loadings antes de cargar las visitas en caso de que se vayan a consultar
        queryParams += this.createQueryFilter();
        this.cuentaAgrupadaPaginator.pagination.nextPage = page || 1;
        
        if (page != this.filtros.page || deactivateHijos) {
            this.filtros.page = page;
            this.filtrosService.guardarFiltros(this.filtros);
        }
        // this.loaderService.showHttpLoader();
        // this.httpError = null;
        //let cargando = this.app.showLoading('Cargando cuentas...');
        this.accountService
            .obtenerAgrupadas(this.accountPaginator.pagination, queryParams, this.pageSize)
            .subscribe(
                (paginator: CuentaAgrupadaPaginator) => {
                    this.cuentaAgrupadaPaginator = paginator;
                    let accounts: CuentaAgrupada[] = paginator.data;
                    this.cuentasAgrupadas = accounts;
                    this.listFiltered = paginator.data.length > 0;

                    // this.loaderService.hideHttpLoader();
                    //this.allAccountsSelected = false;
                    //this.app.hideLoading(cargando)
                    //this.checkSelecteds();
                    if (next) {
                        next();
                    }
                    
                },
                (error) => {
                    this.app.showError(error);
                    this.tablaCuentas.loading = false;
                },
               () => {
                this.tablaCuentas.loading = false
               }
            );
    }

    public loadAccounts(slt:string, orderBy: string, filtros: FiltrosCuentas, selectedRow: CuentaAgrupada): void {
        
        let queryParams: string = '&rows=1000000&page=1';
        selectedRow.loading = true;
        //Se agrega para validar que no haya loadings antes de cargar las visitas en caso de que se vayan a consultar
        queryParams += this.createExpandQueryFilter(slt, orderBy, filtros);
        selectedRow.isExpanded = true;
        this.accountService
            .obtenerListado(this.accountPaginator.pagination, queryParams, this.pageSize)
            .subscribe(
                (paginator: AccountPaginator) => {
                    this.accountPaginator = paginator;
                    let accounts: Account[] = paginator.data;

                    setTimeout(() => {
                        selectedRow.detalle = accounts;
                        selectedRow.loading=false;
                    }, 100);

                    
                },
                (error) => {
                    this.app.showError(error);
                    selectedRow.loading=false;
                },
               () => {
                selectedRow.loading=false;
               }
            );
    }

    public loadSucursales() {
        if (this.filtros.financiera) {
            let uri = '/sucursales';
            this.loadings.sucursales = true;

            uri += `?idCliente=${this.filtros.financiera.idCliente.toString()}`;

            this.visitService.get(uri, true)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadings.sucursales = false;
                    this.sucursales = res;
                }, (err: any) => {
                    this.loadings.sucursales = false;
                    this.app.showError(err);
                });
        }
        else {
            this.sucursales = [];
        }
    }

    onAcreditadoSelectionChange(idAcreditado: any) {
        this.filtros.idAcreditado = idAcreditado;
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                    this.filtros.idCliente = lender.idCliente;
                    this.filtros.financiera.idCliente = lender.idCliente;
                    if (lender.financieraDemo) {
                        this.filtros.excludeFinancierasDemo = false;
                    }
                }
            });
        }
        else {
            this.filtros.financiera = null;
        }

    }

    public validarMoratorios(event) {
        if (event.key == '.') {
            // invalid character, prevent input
            event.preventDefault();
        }
        var valor: string = null;
        var numero: number = 0;
        if (this.filtros.diasMoratorios) {
            valor = this.filtros.diasMoratorios;
            valor = valor + event.key;
            numero = parseInt(valor);
        }
        if (numero > 9999) {
            this.filtros.diasMoratorios = "9999";
            event.preventDefault();
        }

    }

    public onChangeEstate(event) {
        this.resetFilterByName('municipio');
        this.resetFilterByName('idMunicipio');
    }

    loadingLenders: boolean = false;
    public loadLenders(): void {
        this.loadingLenders = true;
        this.lenderService.getAll('all').subscribe(
            lenders => {
                this.lenders = lenders;
                if (!this.sessionData.isSuperAdmin) {
                    this.idLender = this.sessionData.idFinanciera;
                    this.onChangeLender(this.idLender);
                    this.loadCombos();
                }
                this.loadingLenders = false;
            },
            err => {
                this.app.showError(err);
                this.loadingLenders = false;
            },
            () => this.loadingLenders = false
        );
    }

    public readHashParams(): void {
        this.cuentasAgrupadasObtener(this.filtros.page);
    }

    public saveInStorage(): void {
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    /*public selectAllAccounts(): void {
        for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].clienteActivo) {
                this.accounts[i].selected = this.allAccountsSelected;
            }
        }

        this.checkSelecteds();
    }*/

    public setFilter(): void {
        if(this.filtros.financiera == null){
            this.filtros.idCliente = null
        }
        
        if (this.filtros.idCuenta && !Number(this.filtros.idCuenta)) {
            this.app.showSnackbar('¡Aviso!', 'Solo se permiten números en el filtro ID Cuenta.', 3000, 'warning');

            return;
        }

        this.listFiltered = true;
        this.filtros.page = 1;
        this.filtrosService.guardarFiltros(this.filtros);

        //console.log(this.filtros)
        const camposDiferentesANull = Object.keys(this.filtros).filter(campo => this.filtros[campo] !== null);

        if (camposDiferentesANull.length < 5) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return; 
            //Visitas (Todas las visitas) Por lo menos un filtro - OJO: no aplica el checkbox Excluir demo
        }

        this.contabilizadorDeFiltrosTotales();

        this.cuentasAgrupadasObtener(1);

    }

    hijoDesactivado(){
        this.filtrosService.asignarOpcion();
        if(this.filtros && this.filtros.financiera){
            this.filtrosService.guardarFiltros(this.filtros, true);
        }
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
    }

    public setSortField(field: any) {
        if (field.active == this.sortField) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortField = field.active;
            this.sortAsc = true;
        }

        this.cuentasAgrupadasObtener(1);
    }

    public setTag(): void {
        /*let accounts: Account[] = [];

        if (!this.selectedTag) {
            this.app.showSnackbar(
                "Aviso",
                "Es necesario elegir una etiqueta",
                3000,
                "warning"
            );
            return;
        }
   
        if (this.selectedAccount) {
            accounts.push(this.selectedAccount);
        } else {
            accounts = this.getSelectedAccounts();
        }

        if (accounts.length) {
            this.accountsSelected = accounts;
            this.$tagModal.modal('hide');
            this.tagLoader = true;

            this.sendAccountsForUpdateTag(0);
        }*/
    }



    public showAgentTodasModal(): void {
        this.asignarTodasLasCuentas = true;
        this.showAgentModal(false);
    }

    public showAgentModal(asignarTodas: boolean = true): void {
       
        if (asignarTodas) {
            this.asignarTodasLasCuentas = false;
        }

        let cuentasAsignar = this.asignarTodasLasCuentas ? this.accounts : this.accounts.filter((cuenta) => cuenta.selected);

        if (cuentasAsignar.length == 0) {
            this.app.showSnackbar('Aviso', 'Favor de seleccionar por lo menos una cuenta para poder asignar.', 3000, 'warning');
            return;
        }

        if (!this.filtros.mostrarMapa || (cuentasAsignar.length <= 300)) {
            this.asginarCuentasMapa(cuentasAsignar);
        }
        else {
            this.app.showSnackbar('Aviso', 'No se pueden asingar más de 300 cuentas en el mapa, por favor remueve algunos registros o reduce tu búsqueda.', 3000, 'warning');
        }
    }

    asginarCuentasMapa(cuentasAsignar: any){
        const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {
            //height: '400px',
            //width: '800px',
            //disableClose: true,
            data:{
                    opcion: 3,
                    accounts: cuentasAsignar,
                    domiciliosSeleccionados: [],
                    idCobrador: null,
                    nombreCobrador: null,
                    idAgente: null,
                    nombreAcreditado: null,
                    folioVisita: null,
                    idVisitaPsg: null,
                    domicilios: []
                }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.borrarSeleccion();
            if(result) {
                this.cuentasAgrupadasObtener();
            }
        });
    }

    public borrarConteo() {
        this.contadorCuentas = 0;
        this.contadorSeleccion = 0;
        this.cuentasSeleccionadas = 0;
        this.asignarSeleccion =  true;
    }

    public borrarSeleccion(){
       this.borrarConteo();
    }


    public showMap(mostrar: boolean): void {
        this.accounts= [];
        this.filtros.mostrarMapa = mostrar;
        this.dibujando = mostrar;
        this.eventoCreado = mostrar;
        //this.consultarCuentasMapa(0, 1);
    }

    public consultarCuentas() {
        this.borrarConteo();
        this.accounts = [];
        this.filtros.mostrarMapa = false;
        this.dibujando = false;
        this.eventoCreado = false;
        this.cuentasAgrupadasObtener();
    }

    public showTagModal(account?: Account): void {
        if (account && !account.clienteActivo) {
            return;
        }

        this.selectedAccount = account;
        this.selectedTag = !account ? null : account.tag.id;
    }


    public agenteFilterSearch = function(term: string) {
        return this.agenteServ.obtenerAgentes(term, this.filtros.agenteActivo)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idCobrador, nombreCompleto, idAgente }) => (
                        { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                    )
                ))
            );
    }.bind(this);

    public acreditadoFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera && this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera : null)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAcreditado, nombreCompleto, idCliente, idExterno }) => (
                        { idAcreditado: idAcreditado, nombre: idExterno + ' - ' + nombreCompleto, idCliente: idCliente }
                    )
                ))
            );
    }.bind(this);

    resetFinancieraFilter() {
        this.filtros.financiera = null;
        this.filtros.excludeFinancierasDemo = true;
        this.idLender = null;
    }

    resetFilterByName(controlName) {
        this.filtros[controlName] = null;
    }

    cargarEtiquetasCuenta() {
        if (this.filtros.financiera) {
            this.loadingEtiquetasCuenta = true;
            this.visitService.obtenerEtiquetasCuenta(this.filtros.financiera.idCliente)
                .pipe(
                    take(1)
                ).subscribe((respuesta: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.etiquetasCuenta = respuesta.data;
                }, (err: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.app.showError(err);
                });
        }
    }

   openDialogAsignar(): void {
    const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {
            //height: '400px',
            //width: '800px',
            //disableClose: true,
            data:{
                    opcion: 3,
                    accounts: this.itemsSelected,
                    domiciliosSeleccionados: [],
                    idCobrador: null,
                    nombreCobrador: null,
                    idAgente: null,
                    nombreAcreditado: null,
                    folioVisita: null,
                    idVisitaPsg: null,
                    domicilios: [],
                }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.cuentasAgrupadasObtener();
            }
        });
    }

    public outputEmiter(seleccionadas: number) {
        this.contadorSeleccion = seleccionadas;
        this.cuentasSeleccionadas = this.contadorCuentas + this.contadorSeleccion;


        if(this.cuentasSeleccionadas > 300) {
            this.asignarSeleccion =  false;
            this.app.showSnackbar("Aviso", "No se permite seleccionar más de 300 cuentas, favor de reducir tu selección.", 6000, 'warning');
        }else {
            this.asignarSeleccion =  true;
        }

    }

    openDialogEstatus(): void {

        const dialogRef = this.dialog.open(ModalEstatusCuentaComponent, {
            //height: '400px',
            width: '500px',
            //disableClose: true,
            data:{
                    opcion: 2,
                    accounts: this.accounts,
                    motivos: this.motivos,
                    domiciliosSeleccionados: [],
                    selectedAccount: this.itemsSelected,
                    domicilios: []
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
                this.cuentasAgrupadasObtener();
            }
        });
    }

    openDialogEtiquetaOperaciones(): void {

        const dialogRef = this.dialog.open(ModalEtiquetaOperacionesComponent, {
            //height: '400px',
            width: '500px',
            //disableClose: true,
            data:{
                    motivos: this.motivos,
                    selectedAccount: this.itemsSelected,
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
                this.cuentasAgrupadasObtener();

            }
        });
    }

    ayudaComentario(bool: boolean){
        this.ayuda = bool;
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !== this.accountPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize){
            this.accountPaginator.pagination.currentPage = newPagina.pageSize;
            this.cuentasAgrupadasObtener(newPagina.pageIndex+1);
        }
    }

    public onSelected( rows ){
        this.itemsSelected = rows.slice();
        
        this.clienteActivo = this.itemsSelected[0]?.["clienteActivo"]

    }

    // #region Eventos Tabla Principal

    onLoadDetalle(selectedRow: any){
        let slt: string = 'lenderID,lenderName,productName,accountID,clientID,idCliente,idAcreditadoPG,idCuentaPG,clientFullName,externalID,accountStatus,accountCreatedAt,agentID,agentFullName,takenAccountTakenDate,takenAccountExpirationDate,accountDaysInPlatform,accountDaysTaken,city, municipality,state, suburb,clientAddressGeoloc,operationTagID,operationTagName,accountAssignedDate,visibility, clienteActivo, fecVisita, hrInicio1, hrFin1, hrInicio2, hrFin2, totalVisitas, sucursal, grupo, diasMoratorios, lat, lng, calle, numeroInterior, numeroExterior, cp,nombreEtiquetaCuenta, subExternalID, folioDomicilio, idDomicilioAcreditado, folioDomicilio, tipoCobertura, idAcreditadoCuentaDomicilio, domicilioCompleto, telefonoCelular';
        let orderBy: string = 'orderBy=ac.fec_registro desc'
        let filtrosCuentas = new FiltrosCuentas();
        filtrosCuentas.idCliente = selectedRow.idCliente;
        filtrosCuentas.idExterno = selectedRow.idExterno;
        filtrosCuentas.excludeFinancierasDemo = false;
        //filtrosCuentas.idAcreditado = selectedRow.idAcreditado;
        this.loadAccounts(slt, orderBy, filtrosCuentas, selectedRow);
    }

    // #endregion

    onClick({ row, columnPressed }) {
        if (columnPressed == 'coordenadas') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        } else if(columnPressed === 'client.externalID') {
            this.linkDetalleCuenta(row);
        }
    }

    onCtrlClick({ row, columnPressed }) {
        if(columnPressed === 'client.externalID') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }

    ctrlClickLinkDetalleCuenta(cuenta: any) {
        const queryParams = cuenta.urlDetalleCuenta.replace(/\+/g, '%2B');
        window.open('/cuentas/principales/account' + queryParams, '_blank');
        
    }
     
        
    linkDetalleCuenta(cuenta: any) {
        const queryParams = cuenta.urlDetalleCuenta.replace(/\+/g, '%2B');
        this.router.navigateByUrl('/cuentas/principales/account' + queryParams);
        
    }

    btnDetalleCuenta() {
        
        if (this.itemsSelected.length > 0) {
            const url = this.itemsSelected.map(item => item);
            const queryParams = url[0]['urlDetalleCuenta'].replace(/\+/g, '%2B');
            this.router.navigateByUrl('/cuentas/principales/account' + queryParams);
        } else {
            console.error('No hay elementos.');

        }
    }
        

}
