export class ConfiguracionAgente{
    idConfiguracion: number;
    grupo: string;
    clave: string;
    valor: string | number;

    public static map(data: any): ConfiguracionAgente{
        let instance: ConfiguracionAgente = new ConfiguracionAgente();
        instance.idConfiguracion = data.idConfiguracion;
        instance.grupo = data.grupo;
        instance.clave = data.clave;
        instance.valor = isNaN(parseInt(data.valor)) ? data.valor : parseInt(data.valor);
        return instance;
    }
}