<div style="display: flex; align-items: center;">
    <mat-button-toggle-group style="margin-top: -15px" #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="filtros" 
          (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
    
    <div style="margin-left: auto;">
        <p class="montoTotal">Cuentas cargadas: {{geoJsonActiva.length + geoJsonApartada.length + geoJsonEspera.length + geoJsonEsperaConfirmacion.length}} </p>
    </div>
</div>


<div id="map2">
    <div *ngIf="loading" class="centrado">
        <app-loading></app-loading>
    </div>
</div>

<mat-sidenav style="margin-left: -33px !important" #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
      <div class="div-filtros-side">
        <li class="filtro-side">
            <mat-form-field style="width: 100%;">
                <mat-label>Estatus</mat-label>
                <mat-select
                  [(ngModel)]="filtros.estatus"
                  placeholder="Seleccione..."
                >
                  <mat-option [value]="1">Activa</mat-option>
                  <mat-option [value]="2">En Espera de Confirmación</mat-option>
                  <mat-option [value]="3">Apartada</mat-option>
                </mat-select>
                <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.estatus != null" 
                  (click)="filtros.estatus = null;$event.stopPropagation();">clear</mat-icon> 
            </mat-form-field>
          <!-- <div align="left" *ngIf="filtros.estatus" class="limpiar-seleccion-kobra">
            <a (click)="filtros.estatus = null"><i>Limpiar</i></a>
          </div> -->
        </li>
        <li class="filtro-side">
            <mat-date-picker-formater
                style="width: 100%;"
                [label]="'Fecha inicio'"
                [placeHolder]="'Capture la fecha inicio'"
                [min]="obtenerMinDate()"
                [max]="filtros.fechaFin ? filtros.fechaFin : today"
                [(ngModel)]="filtros.fechaInicio"
            />
         
        </li>
        <li class="filtro-side">
            <mat-date-picker-formater
                style="width: 100%;"
                [label]="'Fecha fin'"
                [placeHolder]="'Capture la fecha fin'"
                [min]="filtros.fechaInicio"
                [max]="obtenerMaxDate()"
                [(ngModel)]="filtros.fechaFin"
            />
      
          
        </li>

        <li class="filtro-side">
            <mat-form-field  style="width: 100%;">
                <mat-label>Producto</mat-label>
                <mat-select [(ngModel)]="filtros.idProducto">
                    <mat-option [value]="null">Seleccione...</mat-option>
                    <mat-option *ngFor="let product of products" [value]="product.idProducto">
                        {{product.nomProducto}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.idProducto != null" 
                  (click)="filtros.idProducto = null;$event.stopPropagation();">clear</mat-icon> 
            </mat-form-field>
          <!-- <div align="left" *ngIf="filtros.idProducto" class="limpiar-seleccion-kobra">
            <a (click)="filtros.idProducto = null"><i>Limpiar</i></a>
          </div> -->
        </li>

        <li class="filtro-side">
            <mat-form-field  style="width: 100%;">
                <mat-label>Sucursal</mat-label>
                <mat-select [(ngModel)]="filtros.sucursal">
                    <mat-option [value]="null">Seleccione...</mat-option>
                    <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal">
                        {{sucursal.nombreSucursal}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.sucursal != null" 
                  (click)="filtros.sucursal = null;$event.stopPropagation();">clear</mat-icon> 
            </mat-form-field>
          <!-- <div align="left" *ngIf="filtros.sucursal" class="limpiar-seleccion-kobra">
            <a (click)="filtros.sucursal = null"><i>Limpiar</i></a>
          </div> -->
        </li>
      </div>
    </ol>
    <ol class="lista-filtros-buttons">
        <button
        class="boton-accion-front"
          style="width: 100%;"
          mat-stroked-button
          color="primary"
          (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; prepararConsultaCuenta();">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
        </button>
        <button
          style="width: 100%; margin-top: 10px;"
          mat-stroked-button
          (click)="limpiarCuentasMapa()">
            <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
            Limpiar Filtros
        </button>
      </ol>
</mat-sidenav>