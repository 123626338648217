import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApplicationService } from '../../application/shared/application.service';
import { StorageKobraService } from '../../core/http/storage-kobra/storage-kobra.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UploadFileComponent } from '../../shared/components/upload-file-v2/upload-file.component';
import { FotoModalComponent } from '../../componentes/fotos/modal/foto-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environmentSelector } from "../../../environments/environment.selector";
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { VisitService } from "../../visit/visit.service";

@Component({
  selector: 'app-checklist-dynamic-question',
  templateUrl: './checklist-dynamic-question.component.html',
  styleUrls: ['./checklist-dynamic-question.component.css'],
  providers: [
    StorageKobraService,
    VisitService
]
})
export class ChecklistDynamicQuestionComponent implements OnInit {
  private environment = environmentSelector();

  @Input() pregunta: any;
  @Input() checklist: any;
  @Input() agente: any;
  @Input() checkFiltrado: boolean;
  @Input() changeFiltrado: Function;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;
  respuestas: any;
  respuestasActual: any;
  foto: any;
  acceptFiles: string = '.png,.gif,.jpeg,.jpg';
  dynamicForm: FormGroup; 

  constructor(
    private app: ApplicationService,
    private storageServ: StorageKobraService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private visitService: VisitService
  ) { 
    
  }

  ngOnInit() {
    this.respuestas = this.pregunta.respuestas;
    this.crearFormGruop();
  }

  onFilterQuestion() {
    setTimeout(() => {
      if(this.checkFiltrado){
        this.changeFiltrado(this.checklist);
      }
    });
  }

  onChangeValue(respuesta: any, pregunta: any) {
    setTimeout(() => {
      if((respuesta.requerida || (pregunta.preguntasHijas && pregunta.preguntasHijas.length > 0)) && !this.checkFiltrado){
        this.checklistValid();
      }
      if(this.checkFiltrado && this.changeFiltrado){
        this.changeFiltrado(this.checklist);
      }
    });
  }

  subirFoto(files: File[], respuesta: any, pregunta: any) {
    //var files = event.target.files;
    setTimeout(() => {
      if (files && files[0]) {
        var reader = new FileReader();

        reader.onload = this._handleReaderLoaded.bind(this);
        this.respuestasActual = respuesta;
        this.foto = files[0];

        reader.readAsBinaryString(this.foto);
    }
      if((respuesta.requerida || (pregunta.preguntasHijas && pregunta.preguntasHijas.length > 0)) && !this.checkFiltrado){
        this.checklistValid();
      }
      if(this.checkFiltrado && this.changeFiltrado){
        this.changeFiltrado(this.checklist);
      }
    });
  }

  private _handleReaderLoaded(readerEvt: any): void {
    var binaryString = readerEvt.target.result;
    var base64textString = `data:${this.foto.type};base64,${btoa(binaryString)}`;

    let dataCreatePhoto = {
        idAgente: this.agente.idAgente,
        idCobrador: this.agente.idCobrador,
        archivo: base64textString
    };

    let loading = this.app.showLoading('Subiendo imagen...');

    this.storageServ.post(`/checklists/foto/${this.checklist.idCliente}`, dataCreatePhoto)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.uploadFileComponent.bloquearComponete(true);
        this.app.hideLoading(loading);
        this.respuestasActual.valor = res.data.nombre;
        if(this.respuestasActual.requerida && !this.checkFiltrado){
          this.checklistValid();
        }
        if(this.checkFiltrado && this.changeFiltrado){
          this.changeFiltrado(this.checklist);
        }
        
        this.app.showSnackbar("¡Éxito!", "La foto se ha subido correctamente.", 3000, "success");
    }, (error: any) => {
        this.app.hideLoading(loading);
        this.app.showError(error);
        this.app.showSnackbar("¡Error!", "Ocurrio un error al intentar subir la foto.", 3000, "error");
    });
  }

  checklistValid(){
    var seValidara = true;
    for (let p = 0; p < this.checklist.preguntas.length; p++) {
      const pregunta = this.checklist.preguntas[p];
      if(!this.validarValor(pregunta)){
        seValidara = false;
        p = this.checklist.preguntas.length;
      }
    }
    this.checklist.invalid = !seValidara;
  }

  validarValor(pregunta){
    var response = true;
    for (let r = 0; r < pregunta.respuestas.length; r++) {
      const respuesta = pregunta.respuestas[r];
      if(this.mostrarPregunta(pregunta) && respuesta.requerida && !respuesta.valor && respuesta.idTipoRespuesta != 8){
        response = false;
        r = pregunta.respuestas.length;
      }
    }
    return response;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  formatDate(control: FormControl) {
    if (control.value != "") {

      try {
        control.patchValue(control.value.toISOString().slice(0, 10));
      } catch (error) {

      }
    }

    return null;
  }

  mostrarPregunta(pregunta) {
    let mostrarEstaPregunta = pregunta.idChecklistPreguntaPadre ? false : true;
    if (mostrarEstaPregunta) {
      pregunta.visible = true;
      return mostrarEstaPregunta;
    }

    let preguntaPadre = this.obtenerPreguntaPadrePorId(pregunta.idChecklistPreguntaPadre);

    for (let i = 0; i < preguntaPadre.respuestas.length; i++) {
      let respuetaActual = preguntaPadre.respuestas[i];
      let valorRespuesta = respuetaActual.valor;
      let condicionRespueta = this.obtenerCondicionRespuesta(valorRespuesta, preguntaPadre.respuestas[i]);
      if (!condicionRespueta) {
        pregunta.visible = false;
        return false;
      }

      for (let j = 0; j < condicionRespueta.when.then.show.length; j++) {
        if (condicionRespueta.when.then.show[j] == pregunta.idChecklistPreguntaCondicion) {
          pregunta.visible = true;
          return true;
        }
      }
    }
    pregunta.visible = mostrarEstaPregunta;
    return mostrarEstaPregunta;
  }

  obtenerPreguntaPadrePorId(idChecklistPreguntaPadre) {
    let preguntaPadre = null;

    preguntaPadre =
      this.checklist.preguntas.find(pregunta => pregunta.idChecklistPreguntaCondicion == idChecklistPreguntaPadre);

    return preguntaPadre;
  }

  obtenerCondicionRespuesta(valorRespuesta, respuestaChecklist) {
    for (let i = 0; i < respuestaChecklist.condiciones.length; i++) {
      const condicionActual = respuestaChecklist.condiciones[i];
      if (valorRespuesta == condicionActual.when.response ||
        (valorRespuesta && condicionActual.when.response == 1) ||
        (!valorRespuesta && condicionActual.when.response == 0)) {
        return condicionActual;
      }
    }
    return null;
  }

  validarControl(control: string) {
    return this.dynamicForm.controls[control].invalid && this.dynamicForm.controls[control].touched;
  }


  /*crearFormGruop(){
    this.dynamicForm = this.fb.group({});

    // Recorre las respuestas y agrega controles al FormGroup
    for (const respuesta of this.respuestas) {

      if(respuesta.requerida){
        this.dynamicForm.addControl(respuesta.idChecklistRespuesta.toString(), new FormControl(respuesta.valor || '', [Validators.required]));
      }else{
        this.dynamicForm.addControl(respuesta.idChecklistRespuesta.toString(), new FormControl(respuesta.valor || ''));
      }
    }
  }*/

  crearFormGruop() {
    this.dynamicForm = this.fb.group({});
  
    // Recorre las respuestas y agrega controles al FormGroup
    for (const respuesta of this.respuestas) {
      const validators = [];
      if (respuesta.requerida) {
        validators.push(Validators.required);
      }
      if (respuesta.idTipoRespuesta === 4) { // Suponiendo que el tipo 4 es para números decimales
        validators.push(Validators.pattern(/^\d+(\.\d{1,2})?$/));
      }
      this.dynamicForm.addControl(
        respuesta.idChecklistRespuesta.toString(),
        new FormControl(respuesta.valor || '', validators)
      );
    }
  }
    
  validarFormulario() {
    if (this.dynamicForm.valid) {
      this.app.showSnackbar("¡Aviso!", "Favor de revisar el cuestionario.", 3000, "warn");
    } 
  }

  public cargarFotoCompleta(urlFoto: string){
    const dialogRef = this.dialog.open(FotoModalComponent, {
        data: {
            urlFoto: urlFoto
        }
    });
  
    dialogRef.afterClosed().subscribe(data => {});
  }

  obtenerFotoChecList(ruta){
    let country = "&Country=" + this.environment.country;
    return `${this.environment.kobraStorage}/checklists/foto?nombre=${ruta}&token=${localStorage.getItem('access-token')}${country}`;
  }

  eliminarFotoCheckLists(ruta: string) {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data:{
          titulo: "Confirmar",
          mensaje: `¿Deseas eliminar la foto?`,
          icono:"delete_forever",
          boton1: "No",
          boton2: "Sí",
          claseAccion: "boton-accion-eliminar"
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result){
          //this.cargando = true;
          const loading: number = this.app.showLoading('Eliminando foto...');
          this.visitService.eliminarFotoCheckLists(ruta, this.agente.idAgente)
          .pipe(take(1))
          .subscribe((respuesta) => {
          if(respuesta.success) {    
              this.app.showSnackbar(
                "Aviso",
                "La foto se ha eliminado correctamente.",
                3000,
                "success"
              );
              this.onChange.emit(ruta);
              this.app.hideLoading(loading);
          }else {
              //this.eliminando = false;
              this.app.showSnackbar(
              "Aviso",
              "Ocurrio un problema al eliminar la foto.",
              3000,
              "error"
              );
              this.app.hideLoading(loading);
          }
          }, error => {
              //his.eliminando = false;
              this.app.hideLoading(loading);
              this.app.showError(error);
          });
        }
    });
  }

}

