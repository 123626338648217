import { TiposConceptos } from './../conceptos-saldo.interface';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConceptosSaldosService } from '../conceptos-saldos.service';
import { take, map } from 'rxjs/operators';
import { ConceptoSaldo } from '../conceptos-saldo.interface';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'app-conceptos-saldos-form',
  templateUrl: './conceptos-saldos-form.component.html',
  styleUrls: ['./conceptos-saldos-form.component.css']
})
export class ConceptosSaldosFormComponent implements OnInit, OnDestroy {

  public title: string;
  public pathTitle: string = this.route.snapshot.data['title'];
  public pathLabel: string = this.route.snapshot.data['label'];
  public pathUrl: string = this.route.snapshot.data['url'];
  public conceptoForm: FormGroup;
  public saveButton = false;
  get concepto(): ConceptoSaldo {
    return this.cSaldosServ.concepto;
  }
  tiposConceptos :any;
  private loadRegsFlag = false;
  @ViewChild('tipoConceptoInput') private tipoConceptoInput: any;

  constructor(
    public cSaldosServ: ConceptosSaldosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private app: ApplicationService,
    private router: Router
  ) {

    this.conceptoForm = this.fb.group(
      {
        'idConcepto': [{ value: '', disabled: true }],
        'idTipoConcepto': ['', Validators.required],
        'nombre': ['', [Validators.required, Validators.maxLength(40)]],
        'cargo': false,
        'activo': true,
        'editable': true
      }
    );

  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('id')) {
      if (this.concepto) {
        this.title = `Editar "${this.concepto.nombre}"`;

        this.conceptoForm.patchValue(this.concepto);
      } else {
        this.getConcepto(() => {
          this.title = `Editar "${this.concepto.nombre}"`;
        });
      }
    } else {
      this.title = 'Agregar';
    }
    this.obtenerTiposConceptos();
  }

  public obtenerTiposConceptos() {

    this.cSaldosServ.obtenerTiposConceptosNuevo().subscribe(
      (res: any) => {
        if(res.success){
          this.tiposConceptos = res.data;
        }
        
      },(error) => {
            this.app.showError(error);
        },
    );
  }

  ngOnDestroy() {
    if (this.loadRegsFlag) {
      /*const page = +this.route.snapshot.queryParamMap.get('_page') || 1;
      this.cSaldosServ.obtenerConceptosSaldos(page);*/
    }

    this.cSaldosServ.setConceptoData(undefined);
  }

  checkControl(control: string) {
    return this.conceptoForm.controls[control].invalid && this.conceptoForm.controls[control].touched;
  }

  onSubmit() {

    this.conceptoForm.get('nombre').patchValue(this.conceptoForm.get('nombre').value.trim());

    if (this.conceptoForm.valid) {

      this.saveButton = true;

      if (this.concepto) {
        this.update();
      } else {
        this.insert();
      }

    } else {
      (<any>Object).values(this.conceptoForm.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }

  insert() {
    const loading = this.app.showLoading(
      `Guardando información...`
    );
    this.cSaldosServ.agregarConcepto(this.conceptoForm.value)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.loadRegsFlag = this.pathTitle === 'Movimientos' ? false : true;
        this.saveButton = false;

        this.app.showSnackbar('¡Aviso!', 'Concepto agregado correctamente.', 3000, 'success');

        this.conceptoForm.reset();
        this.conceptoForm.get('cargo').patchValue(false);
        this.conceptoForm.get('activo').patchValue(true);
        this.conceptoForm.get('editable').patchValue(true);
        this.router.navigate(['/conceptos'], { queryParamsHandling: 'preserve' });
        this.app.hideLoading(loading);
        //this.tipoConceptoInput._element.nativeElement.focus();

      }, (error: any) => {
        this.saveButton = false;
        this.app.showError(error);
        this.app.hideLoading(loading);
      });
  }

  update() {
    const loading = this.app.showLoading(
      `Actualizando información...`
    );
    this.cSaldosServ.actualizarConcepto(this.concepto.idConcepto, this.conceptoForm.value)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.saveButton = false;
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Concepto actualizado correctamente.', 3000, 'success');
        this.router.navigate(['/conceptos'], { queryParamsHandling: 'preserve' }).then(() => {
          /*const page = +this.route.snapshot.queryParamMap.get('_page') || 1;
          this.cSaldosServ.obtenerConceptosSaldos(page);*/
        });
      }, (error: any) => {
        this.app.hideLoading(loading);
        this.saveButton = false;
        this.app.showError(error);
      });
  }

  getConcepto(next?: () => void) {
    this.cSaldosServ.obtenerConcepto(+this.route.snapshot.paramMap.get('id'))
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.cSaldosServ.setConceptoData(res.data[0]);
        this.conceptoForm.patchValue(this.concepto);

        if (next) {
          next();
        }
      }, (error) => {
        this.app.showError(error);
      });
  }

  getErrorMessage(control: string) {
    return this.conceptoForm.controls[control].hasError('required') ? 'Este campo es ' :
      this.conceptoForm.controls[control].hasError('maxlength') ? 'Solo se permiten 40 caracteres.' : '';
  }

}
