<h1 mat-dialog-title>Formatos</h1>
<mat-dialog-content>
  <div *ngIf="formatosCuentas.length > 0">
    <div content style="max-height: 250px; overflow: auto;">
      <div *ngFor="let archivo of formatosCuentas; let i = index;">                    
        <mat-card class="card">  
          <mat-card-content>
            <div style="display: flex; margin-bottom: 5px;">
              <mat-icon class="iconoDoc kobra-icon" style="margin-top: 7px;">description</mat-icon>
              <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{archivo.nombreArchivo}}</span> 
              <div class="iconoGrid">
                <button mat-icon-button       
                  matTooltip="Descripción"
                  mat-tooltip-panel-above
                  (click)="ayudaDescripcion(archivo)">
                  <mat-icon color="primary">help</mat-icon>
                </button>
                <button mat-icon-button
                  [matMenuTriggerFor]="menuDescarga"
                  matTooltip="Descargar"
                  mat-tooltip-panel-above>
                  <mat-icon class="kobra-icon">cloud_download</mat-icon>
                </button>
              </div> 
            </div>
            <div *ngIf="archivo.isDescripcion" style="margin-top: 10px;">
              <span style="font-weight: bold;">Descripción</span>
              <p style="margin-left: 10px; word-break: break-all; flex: 1; ">{{archivo.descripcion}}</p>
            </div>
            <div style="display: flex;" class="iconoGrid">
              <i style="color: #38373782;">{{momentFecha(archivo.fechaRegistro).format('DD/MM/YYYY')}}</i>
            </div>
            <mat-menu #menuDescarga="matMenu">
              <button mat-menu-item 
                (click)="validarDomicilio(archivo, 'pdf')">
                <mat-icon class="iconoPdf">picture_as_pdf</mat-icon>
                <span>Pdf</span>
              </button>
              <button mat-menu-item 
                (click)="validarDomicilio(archivo, 'word')">
                <mat-icon class="iconoWord">description</mat-icon>
                <span>Word</span>
              </button>
            </mat-menu>
          </mat-card-content>                  
        </mat-card>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
</mat-dialog-actions>
<mat-card-footer *ngIf="descargandoArchivo"  class="kobra-card-footer" >
  <i>Descargando archivo...</i>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card-footer>

