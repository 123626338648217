import { Lender } from "../../lender/lender.model";
import { FiltrosMap } from'src/app/application/shared/filtros.service';



export class FiltrosCuentas extends FiltrosMap {
  financiera: Lender;
  visibilidad: number;
  etiqueta: number;
  estatus: number;
  geoloc: number;
  tipoCuenta: number;
  idCuenta: number;
  idAgente: number;
  idCobrador: number;
  idCobradorAsignado: number;
  idAcreditado: number;
  idCliente: number;
  idEstado: number;
  estado: string;
  ciudad: string;
  idMunicipio: number;
  municipio: string;
  colonia: string;
  codigoPostal: string;
  fechaCreacionInicio: Date;
  fechaCreacionFin: Date;
  fechaAsignacionInicio: Date;
  fechaAsignacionFin: Date;
  fechaImportacionInicio: Date;
  fechaImportacionFin: Date;
  agenteActivo: boolean;
  excludeFinancierasDemo: boolean;
  fecVisita: Date;
  idExterno: number;
  sucursal: number;
  etiquetaKobra: number;
  idGrupo: number;
  diasMoratorios: string;
  mostrarMapa: boolean;
  page: number;
  idEtiquetaCuenta = null;
  agente: any;
  acreditado:any;

    constructor() {
      super();
      this.map({});
    }

    public override map(data: any): void {
        this.financiera = data.financiera ? Lender.map(data.financiera) : null;
        this.visibilidad = data.visibilidad || null;
        this.etiqueta = data.etiqueta || null;
        this.estatus = data.estatus || null;
        this.geoloc = data.geoloc || null;
        this.sucursal = data.sucursal || null;
        this.tipoCuenta = data.tipoCuenta || null;
        this.idCuenta = data.idCuenta || null;
        this.idAgente = data.idAgente || null;
        this.idCobrador = data.idCobrador || null;
        this.idCobradorAsignado = data.idCobradorAsignado || null;
        this.idAcreditado = data.idAcreditado || null;
        this.idCliente = data.idCliente || null;
        this.idEstado = data.idEstado || null;
        this.estado = data.estado || null;
        this.ciudad = data.ciudad || null;
        this.idMunicipio = data.idMunicipio || null;
        this.municipio = data.municipio || null;
        this.colonia = data.colonia || null;
        this.codigoPostal = data.codigoPostal || null;
        this.fechaCreacionInicio = data.fechaCreacionInicio ? new Date(data.fechaCreacionInicio) : null;
        this.fechaCreacionFin = data.fechaCreacionFin ? new Date(data.fechaCreacionFin) : null;
        this.fechaAsignacionInicio = data.fechaAsignacionInicio ? new Date(data.fechaAsignacionInicio) : null;
        this.fechaAsignacionFin = data.fechaAsignacionFin ? new Date(data.fechaAsignacionFin) : null;
        this.fechaImportacionInicio = data.fechaImportacionInicio ? new Date(data.fechaImportacionInicio) : null;
        this.fechaImportacionFin = data.fechaImportacionFin ? new Date(data.fechaImportacionFin) : null;
        this.fecVisita = data.fecVisita ? new Date(data.fecVisita) : null;
        this.agenteActivo = data.agenteActivo === false ? data.agenteActivo : true;
        this.excludeFinancierasDemo = data.excludeFinancierasDemo === false ? data.excludeFinancierasDemo : true;
        this.mostrarMapa = data.mostrarMapa ? data.mostrarMapa : false;
        this.idExterno = data.idExterno || null;
        this.sucursal = data.sucursal || null;
        this.etiquetaKobra = data.etiquetaKobra || null;
        this.idGrupo = data.idGrupo || null;
        this.diasMoratorios = data.diasMoratorios || null;
        this.page = data.page || 1;
        this.idEtiquetaCuenta = data.idEtiquetaCuenta || null;
        this.agente = data.agente || null;
        this.acreditado = data.acreditado || null;
    }
}
