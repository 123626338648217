import { Admin } from "../administrator/administrator.model";

export class User {
  id: number;
  email: string;
  password: string;
  accessToken: string;
  admin: Admin;
  idUsuario: number;
  correo: string;
  contrasena: string;
  tipo: string;
  token: string;
}