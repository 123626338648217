import { ConceptosSaldosService } from './../../../saldos/conceptos-saldos/conceptos-saldos.service';
import { LenderService } from './../../../lender/lender.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { take, filter } from 'rxjs/operators';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environmentSelector } from '../../../../environments/environment.selector';
import { I } from '@angular/cdk/keycodes';
import { ConceptosProductos } from '../conceptos-productos.model';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

declare var $: any;

@Component({
    selector: 'app-conceptos-productos',
    templateUrl: './conceptos-productos-list.component.html',
    styleUrls: ['./conceptos-productos-list.component.css'],
    providers: [LenderService]
})
export class ConceptosProductosComponent implements OnInit {
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    private $filterModal: any;
    sessionData: SessionData;
    public branches: any[] = [];
    public conceptosProductos: ConceptosProductos[] = [];
    public itemsSelected: any[] = [];
    public pagination: IPaginatorSucursales;
    filtrosAbiertos: boolean = false;
    private environment = environmentSelector();

    public queryParams: URLSearchParams;
    public listFields: any = {};
    public lenders: any[] = [];
    public idLender: number = 0;
    private slt: string = '*';
    public ayuda: boolean = false;
    public hijosActivos: boolean = false;
    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;

    tabla: any = {
        headers: false,
        multiSelect: false,
        columns: [
            {
                name: 'ID Producto',
                key: 'idProducto',
                hide: false
            },
            {
                name: 'ID Concepto',
                key: 'idConcepto',
                hide: false
            },
            {
                name: 'Concepto',
                key: 'nombre',
                hide: false
            },
            {
                name: 'Tipo Concepto',
                key: 'nombreTipoConcepto',
                hide: true
            }, {
                name: 'Cargo',
                key: 'cargo',
                type: 'boolean',
                hide: true
            }
        ]
    }

    public filters: any = {
        financiera: null,
        nombreConcepto: null,
        activa: true,
        excluirDemo: false,
        filtered: false,
        page: 1
    }

    public datosConsulta: any = {
        pageSize: 50,
        rows: 50,
        sortField: 'idProducto',
        sortAsc: true,
        query: "",
        fromRowPage: 0,
        toRowPage: 50
    }

    public loadings: any = {
        conceptosProductos: true,
        borrando: false,
        financieras: true
    }

    constructor(
        private lenderService: LenderService,
        private conceptosSaldosService: ConceptosSaldosService,
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        // private sucursalesService: SucursalesService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private dialog: MatDialog,
        private router: Router,
        private rootElement: ElementRef
    ) {
        this.sessionData = this.localStorageService.getSessionData();
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.contabilizadorDeFiltrosTotales();
    }

    ngOnInit() {
        this.$filterModal = $(this.rootElement.nativeElement).find('.ui.filter.modal');
        if (!this.sessionData.isSuperAdmin) {
            this.filters.excluirDemo = false;
        }
        this.initListColumns();
        this.loadLenders();

        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));

    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'nombreConcepto'
        ]);
      }
    
      public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
      }
    
      public contabilizadorDeFiltrosTotales():void{
        let totalFiltrosArray: number[] = [];
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    resultadoFiltros(filtrosNuevos: any) {
        if (filtrosNuevos) {
            this.filters = filtrosNuevos;
            this.filters.filter = true;
            this.filters.filtered = true;
        }
        this.idLender = this.filters.financiera ? this.filters.financiera.idFinanciera : null;
        this.loadConceptosProductos(this.filters.page || 1);
    }

    private initListColumns(): void {
        // var listFields: any = JSON.parse(localStorage.getItem('visitListFields')) || {};

        // this.listFields.lender = listFields.lender == false ? false : true;
        // this.listFields.branchName = listFields.branchName == false ? false : true;
        // this.listFields.status = listFields.status == false ? false : true;
        // this.tabla.columns[0].hide = !this.listFields.lender;
        // this.tabla.columns[2].hide = !this.listFields.branchName;
        // this.tabla.columns[4].hide = !this.listFields.status;
    }

    public saveInStorage(index: number, value: boolean): void {
        this.tabla.columns[index].hide = !value;
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    //INICIO EVENTOS TABLA
    onSelected(rows) {
        this.itemsSelected = rows.slice();
    }

    /*onLinkRow({ row, columnPressed }) {
        if (columnPressed === 'nombreSucursal') {
            this.detalleSucursal(row, false);
        }
    }

    onDoubleClick({ row }) {
        this.detalleSucursal(row, false);
    }*/

    sortChange(sort: any) {
        if (sort.active == this.datosConsulta.sortField) {
            this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
        } else {
            this.datosConsulta.sortField = sort.active;
            this.datosConsulta.sortAsc = true;
        }
        this.loadConceptosProductos(1);
    }

    filtersChange(values) {
    }

    searchChange(text) {
    }

    public download(): void {
        const token = localStorage.getItem('access-token');
        let paisSeleccionado = localStorage.getItem('paisSeleccionado');
        let country = "&Country=";
        country+= paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
        const url = `/sucursales/csv${this.crearURLQuery(1)}&token=${token}${country}`;
        window.open(this.environment.kobraServices.servicios + url);
    }
    //FIN EVENTOS TABLA

    //INICIO Eventos botones

    //Muestra el detalle del producto ademas de validar si será para agregar, editaro o ver info solamente
    agregarConcepto() {
        if(this.filtrosAbiertos)
        {
            this.filtrosSideNav.toggle();
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
        this.router.navigate(
            ['nuevo', {}],
            {
                relativeTo: this.route,
                queryParamsHandling: 'preserve'
            }).then(() => {
                this.itemsSelected = [];
        });
        /*this.router.navigate([`catalogos/sucursales/detalle`], {
          queryParamsHandling: 'preserve'
        });*/
    }

    openDialogConfirmacion(conceptoProducto: ConceptosProductos = null): void {

        if(this.filtrosAbiertos)
        {
            this.filtrosSideNav.toggle();
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            data:{
                    titulo: "Confirmar",
                    mensaje: "¿Deseas eliminar el concepto seleccionado?",
                    icono: "delete_forever",
                    colorIcono: "warn",
                    boton1: "No",
                    boton2: "Sí",
                    texto: conceptoProducto.nombre,
                    claseAccion: "boton-accion-eliminar"
                }
        });
    
        dialogRef.afterClosed().subscribe(result => {
    
            if(result) {
                this.borrarConcepto(conceptoProducto);
            }   
        });
      }

    //Elimina el conceptop producto actual
    borrarConcepto(conceptoProducto: ConceptosProductos = null) {
        this.loadings.borrando = true;

        let query: string = `?idProducto=${conceptoProducto.idProducto}`;
        
        this.conceptosSaldosService.eliminarConcepto(conceptoProducto.idConcepto, query)
        .pipe(
            take(1)
        ).subscribe((res: any) => {
            this.loadings.borrando = false;
            this.loadConceptosProductos(1);
        }, (err: any) => {
            this.loadings.borrando = false;
            this.app.showError(err);
        });
    }

    public setFilter(): void {
        this.filters.filtered = true;
        this.filters.page = 1;
        this.filtrosService.guardarFiltros(this.filters);
        this.$filterModal.modal('hide');
        this.loadConceptosProductos(1);
    }

    public clearFilter(): void {
        Object.keys(this.filters).forEach(key => {
            if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')) {
                this.filters[key] = null;
            }
        });
        this.filters.excluirDemo = true;
        this.filters.activa = true;
        this.filters.filtered = false;
        this.filters.page = 1;
        this.filtrosService.removerFiltros();
       // this.loadConceptosProductos(1);
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filters.financiera = lender;
                }
            });
        }
        else {
            this.filters.financiera = null;
        }

    }

    //FIN Metodos del modal filtro

    //Metodos Generales
    public filtrar(page: number){
        this.loadConceptosProductos(page);
    }

    public limpiarFiltros(){
        this.filters = {financiera: null,
            nombreConcepto: null,
            activa: true,
            excluirDemo: false,
            filtered: false,
            page: 1
        }
        this.filtrosService.guardarFiltros(this.filters);
        //this.loadConceptosProductos(1);
    }

    public loadConceptosProductos(page: number): void {
        this.loadings.conceptosProductos = true;

        let query: string = this.crearURLQuery(page);
        this.conceptosSaldosService.obtenerConceptosProductos(query)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadings.conceptosProductos = false;
                this.pagination = res.data;
                this.conceptosProductos = ConceptosProductos.mapArray(res.data.pageData);
                this.calcFromToRowPage();
            }, (err: any) => {
                this.loadings.conceptosProductos = false;
                this.app.showError(err);
            });
            this.contabilizadorDeFiltrosTotales();
    }

    private crearURLQuery(page: number): string {
        let queryParams: string = '';
        let guardarFiltros: boolean = false;
        if (page != this.filters.page) {
            this.filters.page = page;
            guardarFiltros = true;
        }

        queryParams += `?slt=${this.slt}&paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;

        if (this.datosConsulta.sortField) {
            queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
        }

        if(this.filters.nombreConcepto){
            queryParams += `&nombreConcepto=${this.filters.nombreConcepto}`;
            this.filters.filtered = true;
            guardarFiltros = true
        }

        if(guardarFiltros){
            this.filtrosService.guardarFiltros(this.filters);
        }

        return queryParams;
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
            this.datosConsulta.rows = newPagina.pageSize;
            this.loadConceptosProductos(newPagina.pageIndex+1);
        }
    }

    calcFromToRowPage() {
        if (this.pagination.pageData.length <= 0) {
            this.datosConsulta.fromRowPage = 0;
            this.datosConsulta.toRowPage = 0;
        } else {
            this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

            if (this.pagination.page === this.pagination.pages) {
                this.datosConsulta.toRowPage = this.pagination.totalRows;
            } else {
                this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
            }
        }
    }

    public loadLenders(): void {
        this.loadings.financieras = true;

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;

                this.idLender = this.sessionData.idFinanciera;
                this.onChangeLender(this.idLender);

            },
            err => {
                this.app.showError(err);
                this.loadings.financieras = false;
            },
            () => this.loadings.financieras = false
        );
    }

    ayudaComentario(bool: boolean){
        this.ayuda = bool;
    }

    resetFinancieraFilter() {
        this.idLender = null;
        this.filters.financiera = null;
    }

      inactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filters && this.filters.filtered){
          this.filtrosService.guardarFiltros(this.filters, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
      }
    //Fin Metodos Generales

}

export interface ISucursales {
    idFinanciera: number
    nombreFinanciera: string;
    idSucursal: number
    nombreSucursal: string;
    alias: string;
    descripcion: string,
    createdAt: string,
    updatedAt: string,
    activa: boolean;
    estatus: string;
}

export interface IPaginatorSucursales {
    page: number;
    pageData: [ISucursales];
    pages: number;
    totalRows: number;
}
