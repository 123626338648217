import * as moment from 'moment';
import { FiltrosMap } from './../../application/shared/filtros.service';
import { Lender } from './../../lender/lender.model';
export class InformacionRecopiladaFilter extends FiltrosMap {
    financiera: any;
    idAcreditado:number;
    fechaInicio:any;
    fechaFin:any;
    page:number;
    filter:boolean;
    momentVar: any;
    idAcreditadoTelefonos:number;
    fechaInicioTelefonos:any;
    fechaFinTelefonos:any;
    pageTelefonos:number;

    constructor() {
        super();
        this.map({});
        this.momentVar = moment;
      }

      public override map(data: any): void {
       this.financiera = data.financiera?Lender.map(data.financiera): null;
       this.idAcreditado = data.idAcreditado || null;
       this.idAcreditadoTelefonos = data.idAcreditadoTelefonos || null;
       this.fechaInicio = data.fechaInicio? this.momentVar(new Date(data.fechaInicio)).format('YYYY-MM-DD'): null;
       this.fechaFin = data.fechaFin? this.momentVar(new Date(data.fechaFin)).format('YYYY-MM-DD'): null;
       this.fechaInicioTelefonos = data.fechaInicioTelefonos? this.momentVar(new Date(data.fechaInicioTelefonos)).format('YYYY-MM-DD'): null;
       this.fechaFinTelefonos = data.fechaFinTelefonos? this.momentVar(new Date(data.fechaFinTelefonos)).format('YYYY-MM-DD'): null;
       this.page = data.page || 1;
       this.filter = data.filter || false;
      }
  }