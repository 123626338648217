<div class="app content kobra"> 
  <div>
    <mat-stepper [linear]="true" #stepper (selectionChange)="onStepChanged($event)" class="table-container">
      <mat-step [stepControl]="firstFormGroup" state="account_circle" >
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Crear</ng-template>
          <div class="centered-content">
            <mat-card class="card-ancho" [ngClass]="{'bloqueado': primerPaso}">
              <mat-card-header>
                <mat-card-title>Datos</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <mat-select-search
                    formControlName="lenderID"
                    [label]="'Cliente'"
                    [placeHolder]="'Selecciona un cliente'"
                    [value]="'idFinanciera'"
                    [labelField]="'name'"
                    [icon]="'account_balance'"
                    [options]="lenders"
                    (ngModelChange)="onChangeLender($event);">
                  </mat-select-search>
                  
                </div>
                <div>
                  <mat-select-search
                    formControlName="idAcreditado"
                    #matSelectSearchCuenta
                    [disabled]="!lenderID"             
                    [label]="'Cuenta'"
                    [placeHolder]="'Busca por id o nombre'"
                    [value]="'idAcreditado'"
                    [labelField]="'nombre'"
                    [icon]="'account_circle'"
                    [realizarBusqueda]="acreditadoFilterSearch"
                    (ngModelChange)="openDialog();">
                  </mat-select-search>
                 
                </div>
                <div>
                  <mat-select-search
                    formControlName="agente"
                    #matSelectSearchAgente
                    [label]="'Agente'"
                    [placeHolder]="'Busca por id or nombre'"
                    [value]="'option'"
                    [labelField]="'nombre'"
                    [icon]="'person'"
                    [realizarBusqueda]="agenteFilterSearch"
                    (ngModelChange)="asignarAgente();">
                  </mat-select-search>
                  
                </div> 
              </mat-card-content>
              <!-- <mat-card-actions align="end">
                <button mat-stroked-button 
                  [ngClass]="{'boton-guardar': firstFormGroup.valid}" 
                  [disabled]="firstFormGroup.invalid" 
                  (click)="verificarVisita()">
                  <mat-icon>save</mat-icon>
                  Guardar
                </button>
              </mat-card-actions> -->
              <!-- <mat-card-footer *ngIf="isVerificaBorrador" class="kobra-card-footer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </mat-card-footer> -->
            </mat-card>
          </div> 
        </form>
      </mat-step>
      <mat-step  state="assignment">
        <ng-template matStepLabel>Cuestionario</ng-template>
        <div class="centered-content margin-cuestionario" *ngIf="!firstStep">
          <mat-card class="card-ancho" >
            <mat-card-header>
              <mat-card-title>Se generó una visita con el folio: {{folioVisita}}</mat-card-title>
              <mat-card-subtitle>La cuenta tiene registrado un cuestionario de tipo: {{nombreProducto}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="contenido-cuestionario">
              <div *ngFor="let pregunta of checklist.preguntas; let i = index;">
                <app-checklist-dynamic-question *ngIf="mostrarPregunta(pregunta.idChecklistPreguntaCondicion, pregunta.idChecklistPreguntaPadre )" [pregunta]="pregunta"
                  [checklist]="checklist" [agente]="agente" (onChange)="eliminarFotoCheckList($event)"></app-checklist-dynamic-question>
              </div>
              
              <mat-form-field style="margin-top: 20px;">
                <mat-label>Comentario</mat-label>
                <textarea [(ngModel)]="checklist.comentarios" matInput textSanitizer  placeholder="Por favor escribe un comentario..." rows="4" maxlength="255" required></textarea>
                <mat-error *ngIf="checklist.comentarios.invalid && checklist.comentarios.touched">
                  El comentario es <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
              <div *ngIf="idProduct == 1">
                <span style="font-weight: bold;">Interes de pago
                  <ng-container>
                    <span style="color: red;">*</span>
                  </ng-container>
                </span>
                <ngx-stars
                  [maxStars]="5"
                  [initialStars]="1"
                  [readonly]="false"
                  [customPadding]="'1rem'"
                  [size]="2"
                  [color]="'#FFD700'"
                  [wholeStars]="true"
                  (ratingOutput)="calificacion($event)"
                >
                </ngx-stars>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="centered-content margin-cuestionario" *ngIf="!firstStep">
          <mat-card class="card-ancho">
            <mat-card-header>
              <mat-card-title>Correos</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Correo</mat-label>
                <input [(ngModel)]="email" matInput textSanitizer  placeholder="kobra@ejemplo.com">
                <button mat-icon-button matSuffix (click)="agregarEmail(email)">
                  <mat-icon matTooltip="Agregar" mat-tooltip-panel-above class="kobra-icon">add</mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="checklist.extraInfo.emails.length > 0" style="overflow: auto; max-height: 300px;">
                <div *ngFor="let email of checklist.extraInfo.emails; index as ei">
                  <mat-card style="margin-bottom: 20px;">
                    <mat-card-content>                      
                      <div style="display: flex;">
                        <mat-icon class="iconoDoc kobra-icon">email</mat-icon>
                        <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{email}}</span> 
                        <div class="iconoGrid">
                          <button mat-icon-button
                            (click)="removerEmail(ei)"
                            matTooltip="Eliminar"
                            mat-tooltip-panel-above>
                            <mat-icon color="warn">delete_forever</mat-icon>
                          </button>                        
                        </div>
                      </div>
                    </mat-card-content>
                    
                  </mat-card>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="centered-content margin-cuestionario" *ngIf="!firstStep">
          <mat-card class="card-ancho">
            <mat-card-header>
              <mat-card-title>Teléfonos</mat-card-title>
          </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input [(ngModel)]="telefono" matInput textSanitizer  placeholder="introduce número de teléfono">
                <button mat-icon-button matSuffix (click)="agregarTelefono(telefono)">
                  <mat-icon matTooltip="Agregar" mat-tooltip-panel-above class="kobra-icon">add</mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="checklist.extraInfo.telefonos.length > 0" style="overflow: auto; max-height: 300px;">
                <div *ngFor="let tel of checklist.extraInfo.telefonos; index as ti">
                  <mat-card style="margin-bottom: 20px;">
                    <mat-card-content>                      
                      <div style="display: flex;">
                        <mat-icon class="iconoDoc kobra-icon">phone</mat-icon>
                        <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{tel}}</span> 
                        <div class="iconoGrid">
                          <button mat-icon-button
                            (click)="removerTelefono(ti)"
                            matTooltip="Eliminar"
                            mat-tooltip-panel-above>
                            <mat-icon color="warn">delete_forever</mat-icon>
                          </button>                        
                        </div>
                      </div>
                    </mat-card-content>
                    
                  </mat-card>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>  
      </mat-step>
      <mat-step >
        <ng-template matStepLabel>Finalizar</ng-template>
        <div class="contenido-finalizar">
          <div class="centered-content">
            <mat-card class="card-ancho" style="margin-bottom: 20px;">
              <mat-card-content>
                <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="tabSeleccionado($event)" >
                  <mat-tab>
                    <ng-template mat-tab-label >
                      <mat-icon class="kobra-icon">backup</mat-icon>
                      <mat-card-header>
                        <mat-card-title>Subir Foto</mat-card-title>
                      </mat-card-header>
                      <!-- <span class="titulo-tab">Subir Archivos</span> -->
                    </ng-template>
                    <mat-card-content>
                      <div class="subir">
                        <span class="subir-fotos">Fotos</span>
                      </div>
                      <app-upload-file #imagen [allowMultiple]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="handleFiles($event)"></app-upload-file>
                    </mat-card-content>
                    <mat-card-actions align="end">
                      <button mat-stroked-button
                        *ngIf="!listado" 
                        class="boton-guardar" 
                        (click)="uploadImage()"
                      >
                        <mat-icon>cloud_upload</mat-icon>
                        Subir
                      </button>
                    </mat-card-actions>
                  </mat-tab>
                
                  <mat-tab *ngIf="fotosBorrador.length > 0">
                    <ng-template mat-tab-label >
                      <mat-icon  class="kobra-icon">topic</mat-icon>
                      <mat-card-header>
                        <mat-card-title>Fotos Cargadas</mat-card-title>
                      </mat-card-header>
                      <!-- <span  class="titulo-tab">Archivos Cargados</span> -->
                    </ng-template>
                    <mat-card-content style="overflow: auto; max-height: 300px;">
                      <div *ngFor="let foto of fotosBorrador; let i = index;">
                        <mat-card style="margin-bottom: 20px;">
                          <mat-card-content>                      
                            <div style="display: flex;">
                              <mat-icon class="iconoDoc kobra-icon">image</mat-icon>
                              <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{foto.nombre}}</span> 
                              <div class="iconoGrid">
                                <button mat-icon-button
                                  (click)="cargarFotoCompleta(foto.fullPath)"
                                  matTooltip="Mostrar imagen"
                                  mat-tooltip-panel-above>
                                  <mat-icon class="kobra-icon">visibility</mat-icon>
                                </button>  
                                <button mat-icon-button
                                  (click)="eliminarFoto(foto)"
                                  matTooltip="Eliminar"
                                  mat-tooltip-panel-above>
                                  <mat-icon color="warn">delete_forever</mat-icon>
                                </button>                        
                              </div>
                            </div>
                            <div>
                              <i style="color: #38373782;">{{foto.fecha | date:'dd/MM/yyyy HH:mm'}} </i>
                            </div>
                          </mat-card-content>
                          
                        </mat-card>
                      </div>
                    </mat-card-content>
                  </mat-tab>
                </mat-tab-group>
              </mat-card-content>
            </mat-card>
          </div> 
          <!--  -->
          <div class="centered-content" *ngIf="gestorSubeArchivo || gestorSubeArchivoObligatorio">
            <mat-card class="card-ancho" style="margin-bottom: 20px;">
              <mat-card-content>
                <mat-tab-group>
                  <mat-tab>
                    <ng-template mat-tab-label >
                      <mat-icon class="kobra-icon">backup</mat-icon>
                      <mat-card-header>
                        <mat-card-title>Subir Contrato</mat-card-title>
                      </mat-card-header>
                      <!-- <span class="titulo-tab">Subir Archivos</span> -->
                    </ng-template>
                    <mat-card-content>
                      <div class="subir">
                        <span class="subir-contrato">Contratos</span>
                      </div>
                      <div *ngIf="gestorSubeArchivoObligatorio">
                        <alerta-informativa 
                          clase="warning"
                          titulo="¡Aviso!" 
                          texto="Para finalizar la visita, es necesario subir un contrato.">
                        </alerta-informativa>
                      </div>
                      <app-upload-file [ngClass]="{'bloqueado': contratos.length > 0}" #pdf [allowMultiple]="false" [acceptedTypes]="acceptFilesPdf" (filesSelectedEmitter)="handleFilesPdf($event)"></app-upload-file>
                    </mat-card-content>
                    <mat-card-actions align="end">
                      <button mat-stroked-button 
                        class="boton-guardar" 
                        (click)="uploadPdf()"
                      >
                        <mat-icon>cloud_upload</mat-icon>
                        Subir
                      </button>
                    </mat-card-actions>
                  </mat-tab>
                
                  <mat-tab *ngIf="contratos.length > 0">
                    <ng-template mat-tab-label >
                      <mat-icon  class="kobra-icon">topic</mat-icon>
                      <mat-card-header>
                        <mat-card-title>Contratos Cargados</mat-card-title>
                      </mat-card-header>
                      <!-- <span  class="titulo-tab">Archivos Cargados</span> -->
                    </ng-template>
                    <mat-card-content style="overflow: auto; max-height: 300px;">
                      <div *ngFor="let contrato of contratos; let i = index;">
                        <mat-card style="margin-bottom: 20px;">
                          <mat-card-content>                      
                            <div style="display: flex;">
                              <mat-icon class="iconoPdf">picture_as_pdf</mat-icon>
                              <div style="word-break: break-all; flex: 1; margin-top: 10px;">
                                <span>{{contrato.nombreOriginal ? contrato.nombreOriginal : contrato.nombre}}</span> 
                                <span *ngIf="contrato.peso" class="peso-archivo">({{contrato.peso}})</span> 
                              </div>
                              <div class="iconoGrid">
                                <button mat-icon-button
                                  (click)="descargarArchivo(contrato)"
                                  matTooltip="Descargar contrato"
                                  mat-tooltip-panel-above>
                                  <mat-icon class="kobra-icon">cloud_download</mat-icon>
                                </button>  
                                <button mat-icon-button
                                  (click)="openDialogConfirmacion(contrato)"
                                  matTooltip="Eliminar"
                                  mat-tooltip-panel-above>
                                  <mat-icon color="warn">delete_forever</mat-icon>
                                </button>                        
                              </div>
                            </div>
                            <div>
                              <i style="color: #38373782;">{{contrato.fecRegistro | date:'dd/MM/yyyy HH:mm'}} </i>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </mat-card-content>                
                  </mat-tab>
                </mat-tab-group>
              </mat-card-content>
            </mat-card>
          </div>   
        </div>
        
      </mat-step>
      <ng-template matStepperIcon="account_circle">
        <mat-icon class="kobra-icon">account_circle</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="assignment">
        <mat-icon class="kobra-icon">assignment</mat-icon>
      </ng-template>
    </mat-stepper>
    <div class="step-actions">
      <button class="boton-cancelar" mat-flat-button [routerLink]="['../']">
        <mat-icon class="kobra-icon">close</mat-icon>Cancelar
      </button>
      <button mat-stroked-button style="background-color: white;" class="boton-accion-front" [hidden]="stepper.selectedIndex === 0" (click)="goPrevious()">
        <mat-icon>arrow_back</mat-icon>Anterior
      </button>
      <button mat-stroked-button 
        *ngIf="selectedIndex != 2"
        (click)="goNext()" 
        style="background-color: white;"
        [disabled]="!currentStepIsValid()"
        [ngClass]="{'boton-accion-front': currentStepIsValid()}"
      >
        <mat-icon>arrow_forward</mat-icon>Siguiente
      </button>
      <button mat-stroked-button 
        *ngIf="selectedIndex == 2"
        (click)="onTerminarVisita()"
        style="background-color: white;"
        [disabled]="!validarFinalizar()"
        [ngClass]="{'boton-accion-guardar': validarFinalizar()}" 
      >
        <mat-icon>save</mat-icon>
        Finalizar
      </button>       
    </div>
  </div>
</div>
