<div class="app content" style="background-color: #68686814; padding-top: 0px; border-top: 5px solid #68686801; height: 300px !important;">

    <div class="ui active inverted dimmer" *ngIf="loader">
        <div class="ui large text loader">{{textLoader}}</div>
    </div>

    <div class="super-rating" *ngIf="((!calificacionSuperAdministrador && sessionData.isSuperAdmin)
    || (!calificacionAdministradorFinanciera && !sessionData.isSuperAdmin))
    && !visit.cancelled">
        <div>
            <h1 *ngIf="sessionData.isSuperAdmin">Calificación súper administrador</h1>
            <h1 *ngIf="!sessionData.isSuperAdmin">Califica la visita</h1>
            <calificacion-visitas-estrellas [etiquetasCuenta]="etiquetasCuenta" [idVisita]="idVisita"
                [idAgent]="idAgent" [idFinanciera]="idFinanciera" [visitaIndex]="visitaIndex" [calificacion]="resetRate"
                [isSuperAdmin]="sessionData.isSuperAdmin" [visitasTotales]="visitasTotales"
                (siguienteVisita)="obtenerVisita($event)">
            </calificacion-visitas-estrellas>
        </div>
        <h3>Calificando un total de {{visitasACalificar.length}} visitas</h3>
    </div>
</div>