import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener  } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';
import * as moment from 'moment';
import { Column } from '../table/interfaces/tabla.interface';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() columns: Array<Column> = [];
  @Input() items: Array<any> = [];
  @Input() filters: Array<any> = [];
  @Input() loading: boolean = false;
  @Input() headers: boolean = true;
  @Input() tableHeaders: boolean = true;
  @Input() multiSelect: boolean = true;
  @Input() ignoreSortChange: boolean = false;
  @Input() classContainer: string = 'table-container';
  @Input() matSort: MatSort;

  @Output() onSelectedRows: EventEmitter<any> = new EventEmitter();
  @Output() onLinkRow: EventEmitter<any> = new EventEmitter();
  @Output() onCtrlClicLinkRow: EventEmitter<any> = new EventEmitter();
  @Output() sortChange: EventEmitter<any> = new EventEmitter();
  @Output() filtersChange: EventEmitter<any> = new EventEmitter();
  @Output() searchChange: EventEmitter<any> = new EventEmitter();
  @Output() onDownload: EventEmitter<any> = new EventEmitter();
  @Output() onDoubleClick: EventEmitter<any> = new EventEmitter();
  //@HostListener('document:click', ['$event'])

  displayedColumns: string[];
  dataSource;
  selection = new SelectionModel<any>(true, []);
  selectedRow: any;
  subscription: Subscription;
  subscriptionSearch: Subscription;
  filterFormGroup: FormGroup;
  momentDate;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private paginatorIntl: MatPaginatorIntl
  ) {
    this.momentDate = moment;
  }

  ngOnInit() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página:';
    this.paginatorIntl.nextPageLabel = 'Siguiente';
    this.paginatorIntl.previousPageLabel = 'Anterior';
    this.paginatorIntl.firstPageLabel = 'Primera página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    this.paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      const startIndex = page * pageSize;
      const endIndex = startIndex + pageSize > length ? length : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };

    this.filterFormGroup = this.formBuilder.group({
      search: [''],
    });

    this.subscriptionSearch = this.filterFormGroup.valueChanges.pipe(debounceTime(350), distinctUntilChanged())
      .subscribe((value) => {
        this.searchChange.emit(value.search);
      });

    this.displayedColumns = ['select'].concat(this.columns.map(c => c.name));

    this.dataSource = new MatTableDataSource<any>(this.items);
    this.seleccionarPorDefecto(this.items);
    

    this.subscription = this.selection.changed.subscribe(data => {
      this.onSelectedRows.emit(data.source.selected);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) { // Corrección aquí
      this.dataSource = new MatTableDataSource<any>(changes['items'].currentValue); // Corrección aquí
      this.selection.clear();
      this.seleccionarPorDefecto(changes['items'].currentValue); // Corrección aquí
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionSearch.unsubscribe();
  }

  private seleccionarPorDefecto(items: any[]) {
    if (this.items) {
      items.forEach(item => {
        if (item.seleccionado === false || item.seleccionado === true) {
          item.seleccionado = !item.seleccionado;
        }
        if (item.seleccionada === false || item.seleccionada === true) {
          item.seleccionada = !item.seleccionada;
        }
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  seleccionar(row) {
    if (row.seleccionado === false || row.seleccionado === true) {
      row.seleccionado = !row.seleccionado;
    }
    if (row.seleccionada === false || row.seleccionada === true) {
      row.seleccionada = !row.seleccionada;
    }
    if (!this.multiSelect) {
      this.selection.clear();
      this.selectedRow = row;
    }
    this.selection.toggle(row);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  linkRow($event, row?: any, columnPressed?: any) {
    $event.stopPropagation();
    this.onLinkRow.emit({ row, columnPressed });
  }

  onDblClick(row) {
    this.onDoubleClick.emit({ row });
  }

  sortData(sort: Sort) {
    if(this.items && this.items.length> 0){
      const column = this.columns.find(c => c.name === sort.active);
      if (!this.ignoreSortChange) {
        this.sortChange.emit({
          active: column?.sortName || column?.key,
          direction: sort.direction,
          isOrder: column?.sortName ? true : false 
        });
      } else {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const keyA = this.getValueByKey(a, column.key);
          const keyB = this.getValueByKey(b, column.key);
          return sort.direction === 'asc' ? this.compareValues(keyA, keyB) : this.compareValues(keyB, keyA);
        });
      }
    }
  }

  private compareValues(a: any, b: any): number {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else {
      return 0;
    }
  }

  /*getValueByKey(item: any, key: string): any {
    // Split the keys and traverse the object to get the final value
    const keys = key.split('.');
    let value = item;
    let contador = keys.length;
    let i = 0;

    for (let index = 0; index < keys.length; index++) {
        console.log(keys[index]);
        if (contador > i) {
            value = value[keys[index]];
        }
        i++;
    }
   
    return value;
  }*/

  getValueByKey(item: any, keys: string): any {
    
    // Traverse the object to get the final value
    const array = keys.split('.');
    let value = item;
  
    for (const key of array) {
      if (value && typeof value === 'object') {
        value = value[key];
      } else {
        // If the value is undefined or not an object, return undefined
        return undefined;
      }
    }
    return value;
  }

  handleLinkClick(event: MouseEvent, item: any, key: string): void {
    if (event.ctrlKey) {
      this.handleCtrlLinkClick(event, item, key);
    } else {
      this.linkRow(event, item, key);
    }
  }

  handleCtrlLinkClick($event, row?: any, columnPressed?: any): void {
    $event.stopPropagation();
    this.onCtrlClicLinkRow.emit({ row, columnPressed });
  }


  openFilters() {
    /*const dialogRef = this.dialog.open(FiltersComponent, {
      minWidth: 400,
      disableClose: true,
      data: this.filters
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.filtersChange.emit(data);
      }
    });*/
  }
}
