import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { VisitService } from "../../../visit/visit.service";
@Component({
  selector: 'etiqueta.component',
  templateUrl: 'etiqueta.component.html',
  styleUrls: ["etiqueta.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalEtiquetaComponent {
  public loader: boolean = false;
  public etiquetas = [];
  public nvaEtiqueta: any = null;
  constructor(
    public dialogRef: MatDialogRef<ModalEtiquetaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private visitService: VisitService,
  ) {
    this.etiquetas = this.data.etiquetas;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    //this.obtenerArchivos();
  }

  public cambiarEtiqueta() {
    this.loader = true;

    if(this.nvaEtiqueta === null){
      this.app.showSnackbar('Aviso', 'Favor de elegir una etiqueta.', 3000, 'warning');
      return;
    }
    let loading: number = this.app.showLoading('Actualizando etiqueta...');
    var objecto = {
      idCliente: this.data.idCliente,
      idAgente: this.data.idAgente,
      etiquetaActual: this.data.etiquetaActual,
      etiquetaNva: {
          idEtiqueta: this.nvaEtiqueta.idEtiquetaVisita
      }
    };

    this.visitService.editEtiquetas(this.data.id, objecto).subscribe((res: any) => {
        this.loader = false;
        this.app.showSnackbar('Aviso', 'Se ha actualizado la etiqueta correctamente.', 3000, 'success');
        this.app.hideLoading(loading);
        this.dialogRef.close(true);
    }, (err: any) => {
      this.app.hideLoading(loading);
        this.loader = false;
        this.app.showError(err);
    });
  }

}
