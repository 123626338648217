import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ServiciosKobraService } from "../../core/http/servicios-kobra/servicios-kobra.service";
import { Menu } from '../modelos/menu.model';

@Injectable()
export class LayoutService {
  constructor(private kobraService: ServiciosKobraService) { }

  reloadBaseUrl(archivo:string = ""){
    this.kobraService.reloadBaseUrl(archivo);
  }

  public getMenu(): Observable<Array<Menu>> {
    return this.kobraService.get('/menu?idAplicacion=2').pipe(
      map(res => <Array<Menu>> res.data)
    );
  }
}