import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Agent } from "../../agent.model";
import { AgentService } from "../../agent.service";
import { FormValidation } from "../../../shared/form-validation/form-validation.model";
import { LoaderService } from "../../../loader/loader.service";
import { MessageService } from "../../../shared/message/message.service";
import { Observable } from 'rxjs';
import { ApplicationService } from '../../../application/shared/application.service';
import { environmentSelector } from "../../../../environments/environment.selector";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import moment from 'moment';

declare let $: any;

@Component({
  selector: 'agent-form',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.css']
})
export class AgentFormComponent implements OnChanges, OnInit {
  private environment = environmentSelector();
  public form: FormGroup;
  public nameValidation: FormValidation;
  public firstSurnameValidation: FormValidation;
  public secondSurnameValidation: FormValidation;
  public curpValidation: FormValidation;
  public rfcValidation: FormValidation;
  public birthdateValidation: FormValidation;
  public genderValidation: FormValidation;
  public phoneValidation: FormValidation;
  public cellphoneValidation: FormValidation;
  public emailValidation: FormValidation;
  public nssValidation: FormValidation;
  public contrasenaValidation: FormValidation;
  public pais: String;
  public fieldClass: String;
  public labels: any = {};
  public minDate: Date;
  public maxDate: Date;
  public hidePwd: boolean = true;
  pixelesContrasena: string = '60px';
  margenContrasena: number = 0;
  guardando: boolean = false;
  sessionData: SessionData;

  @Input() agent: Agent;
  @Input() redInterna: boolean;
  @Input() agentID: number;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<Agent> = new EventEmitter();

  constructor(
    private agentService: AgentService,
    private app: ApplicationService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private rootElement: ElementRef,
    private localStorageService: LocalStorageService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.pais = this.environment.pais;
    this.fieldClass = this.pais == 'MX' ? 'three fields' : 'two fields';
  }

  ngOnChanges() {
    this.resetForm();
  }

  ngOnInit() {
    this.labels = {
      labelRFC: this.environment.labelRFC,
      labelCURP: this.environment.labelCURP
    }
    this.initForm();
    this.initValidations();
    this.createDatePicker();
    if (this.agent) {
      this.resetForm();
    }
  }

  private createDatePicker(): void {
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 100);

    // Define la fecha máxima como dentro de un año desde hoy
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18)
  }

  private initForm(): void {
    this.form = this.environment.pais == 'MX' ? this.formMXExtructura() : this.formEstructura();
  }

  private formMXExtructura(): any{
    return this.formBuilder.group({
      name: ['', [
        Validators.required
      ]],
      firstSurname: ['', [
        Validators.required
      ]],
      secondSurname: [''],
      curp: ['', [
        Validators.pattern
      ]],
      rfc: ['', [
        Validators.pattern
      ]],
      birthdate: [''],
      gender: [''],
      phone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      cellphone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      email: ['', [
        Validators.email
      ]],
      buroLaboral: [''],
      disponible: [''],
      primerContacto: [''],
      nss: ['', [
        Validators.pattern(/^\d+$/),
        Validators.maxLength(11),
        Validators.minLength(11)
      ]],
      contrasena: ['', [
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d@$!%*?&]{8,}$')
      ]]
    });
  }

  private formEstructura(): any{
    return this.formBuilder.group({
      name: ['', [
        Validators.required
      ]],
      firstSurname: ['', [
        Validators.required
      ]],
      secondSurname: [''],
      curp: [''],
      rfc: [''],
      birthdate: [''],
      gender: [''],
      phone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      cellphone: ['', [
        Validators.pattern(/^\d+$/)
      ]],
      email: ['', [
        Validators.email
      ]],
      buroLaboral: [''],
      disponible: [''],
      primerContacto: [''],
      contrasena: ['', [
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d@$!%*?&]{8,}$')
      ]]
    });
  }

  private initValidations(): void {
    this.nameValidation = new FormValidation();
    this.nameValidation.formControl = this.form.controls['name'];
    this.nameValidation.message = {
      required: 'Campo requerido.'
    };
    this.nameValidation.observeFromControl();

    this.firstSurnameValidation = new FormValidation();
    this.firstSurnameValidation.formControl = this.form.controls['firstSurname'];
    this.firstSurnameValidation.message = {
      required: 'Campo requerido.'
    };
    this.firstSurnameValidation.observeFromControl();


    this.genderValidation = new FormValidation();
    this.genderValidation.formControl = this.form.controls['gender'];
    this.genderValidation.message = {
      required: 'Campo requerido.'
    };
    this.genderValidation.observeFromControl();

    this.birthdateValidation = new FormValidation();

    this.phoneValidation = new FormValidation();

    this.cellphoneValidation = new FormValidation();

    if(!this.redInterna){
      this.birthdateValidation.formControl = this.form.controls['birthdate'];
      this.birthdateValidation.message = {
        required: 'Campo requerido.'
      };
      this.birthdateValidation.observeFromControl();

      this.phoneValidation.formControl = this.form.controls['phone'];
      this.phoneValidation.message = {
        required: 'Campo requerido.',
        pattern: 'Deben ser solo números.'
      };
      this.phoneValidation.observeFromControl();

      this.cellphoneValidation.formControl = this.form.controls['cellphone'];
      this.cellphoneValidation.message = {
        required: 'Campo requerido.',
        pattern: 'Deben ser solo números.'
      };

      this.cellphoneValidation.observeFromControl();
    }

    

    this.emailValidation = new FormValidation();
    this.emailValidation.formControl = this.form.controls['email'];
      this.emailValidation.message = {
        required: 'Campo requerido.',
        email: 'Formato incorrecto.'
      };
      this.emailValidation.observeFromControl();


    this.curpValidation = new FormValidation();
    this.nssValidation = new FormValidation();
    this.rfcValidation = new FormValidation();
    if(this.pais=='MX' && !this.redInterna){
      this.curpValidation.formControl = this.form.controls['curp'];
      this.curpValidation.message = {
        pattern: 'El formato de la curp es incorrecto.'
      };
      this.curpValidation.observeFromControl();

      this.nssValidation.formControl = this.form.controls['nss'];
      this.nssValidation.message = {
        //required: 'Campo requerido.',
        pattern: 'Deben ser solo números.',
        maxlength: 'Debe contener 11 caracteres.',
        minlength: 'Debe contener 11 caracteres.',
      };
      this.nssValidation.observeFromControl();

      this.rfcValidation.formControl = this.form.controls['rfc'];
      this.rfcValidation.message = {
        //required: 'Campo requerido.',
        pattern: 'El formato del rfc es incorrecto.'
      };
      this.rfcValidation.observeFromControl();
    }

    this.contrasenaValidation = new FormValidation();
    this.contrasenaValidation.formControl = this.form.controls['contrasena'];
    this.contrasenaValidation.message = {
      pattern: 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número.'
    };    
    this.contrasenaValidation.observeFromControl();
    this.form.controls['contrasena'].statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.pixelesContrasena = '60px';
          this.margenContrasena = 0;
        
      } else if (status === 'INVALID') {
        this.pixelesContrasena = '130px';
          this.margenContrasena = -30;
      }
    });
  }

  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
  }

  private prepareFormData(): Agent {

    const agent: Agent = new Agent();
    agent.firstName = this.form.value.name || null;
    agent.firstSurname = this.form.value.firstSurname || null;
    agent.secondSurname = this.form.value.secondSurname || null;
    agent.curp = this.form.value.curp || null;
    agent.rfc = this.form.value.rfc || null;
    agent.birthdate = this.form.value.birthdate || null;
    agent.gender = this.form.value.gender || null;
    agent.phoneNumber = this.form.value.phone || null;
    agent.cellphoneNumber = this.form.value.cellphone || null;
    agent.email = this.form.value.email || null;
    agent.buroLaboral = this.form.value.buroLaboral;
    agent.disponible = this.form.value.disponible;
    agent.primerContacto = this.form.value.primerContacto;
    agent.nss = this.form.value.nss || null;
    agent.contrasena = this.form.value.contrasena || null;
    return agent;
  }

  private resetForm(): void {
    if (this.form) {
      this.form.reset({
        name: this.agent.firstName,
        firstSurname: this.agent.firstSurname,
        secondSurname: this.agent.secondSurname,
        gender: this.agent.gender,
        phone: this.agent.phoneNumber,
        cellphone: this.agent.cellphoneNumber,
        email: this.agent.email,
        buroLaboral: this.agent.buroLaboral,
        disponible: this.agent.disponible,
        primerContacto: this.agent.primerContacto,
        nss: this.agent.nss,
        curp: this.agent.curp,
        rfc: this.agent.rfc,
        contrasena: ''
      });

      this.form.patchValue({
        birthdate: new Date(moment(this.agent.birthdate).format('YYYY-MM-DDTHH:mm:ss'))
      });
    }
  }

  public save(): void {    
    if (this.form.get('name').value) {
      this.form.get('name').patchValue(this.form.get('name').value.trim().toUpperCase());
    }
    if (this.form.get('firstSurname').value) {
      this.form.get('firstSurname').patchValue(this.form.get('firstSurname').value.trim().toUpperCase());
    }
    if (this.form.get('secondSurname').value) {
      this.form.get('secondSurname').patchValue(this.form.get('secondSurname').value.trim().toUpperCase());
    };

    if(!this.form.get('gender')) {
      this.app.showSnackbar(
          "Aviso",
          "Es necesario elegir el sexo.",
          3000,
          "warning"
      );
      return;
    }
    

    if (this.form.valid) {
      const agent: Agent = this.prepareFormData();
      let response: Observable<Agent>;
      if (this.agent.id) {
        agent.id = this.agent.id;
        response = this.agentService.update(agent);
      } else {
        agent.id = this.agentID;
        response = this.agentService.add(agent);
      }

      this.guardando = true;
      // this.loaderService.showHttpLoader();
      const loading: number = this.app.showLoading('Guardando información del agente.');
      response.subscribe(
        res => {
          agent.idAgente = this.agent.idAgente;
          this.agent = agent;
          this.onSave.emit(this.agent);
          this.guardando = false;
          this.app.hideLoading(loading);
        },
        error => {
          this.app.showError(error);
          this.guardando = false;
          this.app.hideLoading(loading);
        }
      );
    }
  }

  convertirAMinusculas(event: any) {
    this.form.controls['email'].setValue(this.form.controls['email'].value.toLowerCase());
  }
  
}
