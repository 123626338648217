<form action="">
  <!-- <app-loader *ngIf="loaderText" [message]="loaderText"></app-loader> -->
    <div class="contenidoConScroll" style="height: 239px; overflow-x: hidden; padding-left: 0;">
      <div *ngFor="let detalle of listaCuentaDetalle" [ngSwitch]="detalle.idCuentaCampoTipoDato">
        <div *ngSwitchCase="1">
          <mat-form-field class="ancho">
            <mat-label>{{detalle.descripcion}}</mat-label>
            <input matInput textSanitizer  type="number" [(ngModel)]="detalle.valor" [name]="detalle.descripcion"
              [maxlength]="10">
          </mat-form-field>
        </div>
        <div *ngSwitchCase="2">
          <mat-form-field class="ancho">
            <mat-label>{{detalle.descripcion}}</mat-label>
            <input matInput textSanitizer  type="number" [(ngModel)]="detalle.valor" [name]="detalle.descripcion"
              [maxlength]="10">
          </mat-form-field>
        </div>
        <div *ngSwitchCase="3">
          <mat-form-field class="ancho">
            <mat-label>{{detalle.descripcion}}</mat-label>
            <input matInput textSanitizer  type="text" [(ngModel)]="detalle.valor" [name]="detalle.descripcion"
              [minlength]="detalle.minimoCaracteres" [maxlength]="detalle.maximoCaracteres">
          </mat-form-field>
        </div>
        <div *ngSwitchCase="4">
            <mat-date-picker-formater
              class="ancho"
              [label]="detalle.descripcion"
              [placeHolder]="'Capture la fecha'"
              [(ngModel)]="detalle.valor"
              [limpiar]="false"
              [name]="detalle.descripcion"
            />
        </div>
        <div *ngSwitchCase="5">
          <mat-form-field class="ancho">
            <mat-label>{{detalle.descripcion}}</mat-label>
            <input matInput textSanitizer  [ngxMatDatetimePicker]="picker5" placeholder="Fecha y hora" [(ngModel)]="detalle.valor" [name]="detalle.descripcion + detalle.valor" >
            <mat-datepicker-toggle matSuffix [for]="$any(picker5)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker5  >
                <ngx-mat-datepicker-actions>
                    <button class="boton-cancelar" mat-flat-button ngxMatDatepickerCancel>Cancelar</button>
                    <button mat-stroked-button color="primary" ngxMatDatepickerApply>Aceptar</button>
                </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
         </mat-form-field>
        </div>
        <div *ngSwitchCase="6">
          <mat-checkbox 
            [(ngModel)]="detalle.valor" [name]="detalle.descripcion">
            {{detalle.descripcion}}
          </mat-checkbox>
        </div>
        <div *ngSwitchCase="7">
          <mat-form-field class="ancho">
            <mat-label>{{detalle.descripcion}}</mat-label>
            <input matInput textSanitizer  
              [(ngModel)]="detalle.valor" 
              [ngxTimepicker]="picker7" 
              placeholder="Selecciona una hora"
              [name]="detalle.descripcion + detalle.valor">
              <mat-icon matSuffix>access_time</mat-icon>
            <ngx-material-timepicker #picker7></ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-card-actions align="end" style="gap: 10px;">
      <button mat-stroked-button class="boton-cancelar" (click)="cancel()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
      <button mat-stroked-button  class="boton-guardar" (click)="guardar()"><mat-icon>save</mat-icon>Guardar</button>
    </mat-card-actions> 
</form>