import { Indicadores } from "./agent-ranking-indicadores.model";

export class Ranking {
    puntos: any;
    globalRank: number;
    localRank: number;
    indicadores: Indicadores[];


    // constructor() {
    //     this.indicadores = new Indicadores();
    //   }
  }

