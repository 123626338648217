<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false;"></router-outlet>
<div class="app content kobra" *ngIf="!hijosActivos">
  <div style="margin-bottom: 30px;"> 
    <strong style="font-size: 20px;">{{financieraName}}</strong>
  </div>
  
  <mat-card style="margin-bottom: 30px;"> 
    <mat-card-header>
      <mat-card-title>Contratar paquete</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <paquete-financiera-form [idFinanciera]="idFinanciera" (save)="paqueteAgregado($event)"></paquete-financiera-form>
    </mat-card-content>
  </mat-card>

  <mat-card >
    <mat-card-header>
      <mat-card-title>Paquetes contratados</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <div *ngFor="let paqueteContratado of paquetesContratados; index as i">
          <h2 class="ui header">
            {{paqueteContratado.nombre}} &nbsp; <small>{{paqueteContratado.createdAt | date: 'dd/MM/yy hh:mm a'}}</small>
          </h2>
          
          <div style="margin-bottom: 30px;">
            <a *ngIf="paqueteContratado.paqueteActual" class="label-actual">Paquete actual</a>
            <a *ngIf="!paqueteContratado.activo" class="label-cancelado">Paquete cancelado</a>
          </div>
      
          <mat-card>
            <mat-card-content>
              <div align="right">
                <button class="boton-cancelar" mat-flat-button *ngIf="paqueteContratado.activo && ( paqueteContratado.saldo * 1 ) > 0 && ( paqueteContratado.precio * 1 ) == ( paqueteContratado.saldo * 1 )"  (click)="cancelarPaquete(paqueteContratado.idFinancieraPaquete);">
                 <mat-icon class="kobra-icon">close</mat-icon> Cancelar paquete</button>
              </div>
              <div class="kobra four columns">
                
                <div class="kobra column">
                    <mat-label for="">Paquete</mat-label>
                    <div style="margin-top: 5px;">{{paqueteContratado.nombre}}</div>
                </div>
                <div class="kobra column">
                  <mat-label for="">Saldo inicial</mat-label>
                  <div style="margin-top: 5px;">{{paqueteContratado.idPaquete == 0 ? 'N/A' : ( paqueteContratado.precio | currency:'USD':true )}}</div>
                </div>
              
                <div class="kobra column">
                  <mat-label for="">Saldo actual</mat-label>
                  <div style="margin-top: 5px;"> {{paqueteContratado.idPaquete == 0 ? 'N/A' : (paqueteContratado.saldo | currency:'USD':true)}}</div>
                </div>
                <div class="kobra column">
                  <mat-label for="">Consumo</mat-label>
                  <div style="margin-top: 5px;"> {{ paqueteContratado.idPaquete == 0 ? 'N/A' : ( paqueteContratado.saldo == 0 ? 100 : ( 100 - ( ( paqueteContratado.saldo * 100 ) / paqueteContratado.precio ) ).toFixed(2) ) + '%' }}</div>           
                </div>
              </div>
              <br />
              <mat-divider style="margin-left: 0px !important; " inset ></mat-divider>
              <app-loader *ngIf="conceptosPorPaquete[i]?.cargando"></app-loader>
              
              <div class="kobra four columns" style="margin-top: 20px;" *ngIf="!conceptosPorPaquete[i]?.cargando">
                <div class=" kobra column" *ngFor="let conceptoPorPaquete of conceptosPorPaquete[i]?.conceptos; index as x">
                    <mat-label>{{conceptoPorPaquete.nombreConcepto}}</mat-label>
                    <div style="margin-top: 5px;">{{conceptoPorPaquete.precio | currency: 'USD': true}}</div>       
                </div>
              </div>
              <div align="right">
                <button mat-stroked-button *ngIf="paqueteContratado.activo && ( paqueteContratado.idPaquete == 0 || ( paqueteContratado.idPaquete > 0 && paqueteContratado.saldo > 0 ) || paqueteContratado.paqueteActual == true )" (click)="preciosPorProducto(paqueteContratado);"><mat-icon class="kobra-icon">edit</mat-icon> Editar precios</button>
              </div>
            </mat-card-content>
          </mat-card>
          <div style="margin-bottom: 20px;"></div>
        </div>
        <div *ngIf="!paquetesContratados.length">
          <i>No se ha contratado nigún paquete</i>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
