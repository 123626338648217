import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';


@Component({
  selector: 'app-custom-snackbar',
  templateUrl:'./snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class CustomSnackbarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<any>
      ) { }

      dismiss() {
        this.snackBarRef.dismiss();
      }
}