<div class="app content kobra">
  <mat-card class="kobra-card">
    <mat-card-header>
      <mat-card-title>Nuevo</mat-card-title>
    </mat-card-header>
    <mat-card-content class="kobra-card-content">

        <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" rowHeight="80px">
          
          <!-- <mat-grid-tile colspan="1" rowspan="1">
            <mat-select-search  class="nombreDefault"
              [label]="'Nombre *'" [value]="'name'" [labelField]="'name'" 
              [options]="layoutFields"
              disable="!layoutFields"
              [(ngModel)]="nombreNuevo" (ngModelChange)="onChangeNombre(nombreNuevo);"
              [ngModelOptions]="{standalone: true}">
            </mat-select-search>
          </mat-grid-tile> -->
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field *ngIf="!mostrarNombre" class="nombreDefault">
              <mat-label>Nombre *</mat-label>
              <mat-select [(ngModel)]="nombreNuevo" (ngModelChange)="onChangeNombre(nombreNuevo)">
                <mat-option (click)="toggleMostrarNombre()">Nuevo campo...</mat-option>
                <mat-option *ngFor="let nombre of layoutFields" [value]="nombre.name">
                  {{nombre.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="mostrarNombre" class="nombreDefault">
              <input #inputNombre matInput textSanitizer placeholder="Ingrese el nombre" [(ngModel)]="nombreNuevo" (ngModelChange)="onChangeNombre(nombreNuevo)">
              <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleMostrarNombre(); resetFieldValues();">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </mat-grid-tile>
          
          
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="input-catalogo">
              <mat-label>Clave *</mat-label>
              <input matInput textSanitizer  disabled [(ngModel)]="claveKey">          
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="input-catalogo">
              <mat-label>Tipo de dato *</mat-label>
              <mat-select [disabled]="default" [(ngModel)]="tipoNuevo" placeholder="Seleccione...">
                <mat-option *ngFor="let tipo of itemsFieldType" [value]="tipo.value">{{tipo.name}}

                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <!-- <mat-grid-tile colspan="1" rowspan="1">
                        <mat-form-field class="input-catalogo">
                          <mat-label>Segundo apellido</mat-label>
                          <input matInput textSanitizer 
                            formControlName="apellidoMaterno"
                            placeholder="Capture el apellido del administrador">
                          <mat-icon matSuffix>shield_person</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile> -->

          <mat-grid-tile colspan="1" rowspan="1" >
            <mat-checkbox class="kobra-checkbox responsive" [(ngModel)]="reqNuevo">Requerido</mat-checkbox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-checkbox class="kobra-checkbox responsive" [(ngModel)]="mostrarNuevo">Mostrar en APP</mat-checkbox>
          </mat-grid-tile>
          <!-- <mat-grid-tile colspan="1" rowspan="1">
                        <mat-checkbox class="kobra-checkbox responsive" formControlName="notificacionReporteRedeco">Enviar reporte REDECO</mat-checkbox>
                    </mat-grid-tile> -->


        </mat-grid-list-responsive>
      <div class="button-center">
        <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()"
          >
          <mat-icon class="kobra-icon">
            close
          </mat-icon> Cancelar</button>
        <button mat-stroked-button class="kobra-button" type="submit" (click)="onSubmit()"
          >
          <mat-icon>
            save
          </mat-icon> Guardar</button>
      </div>
    </mat-card-content>
    <!-- <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadingLenders">
      <i>Obteniendo clientes...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer> -->
  </mat-card>

</div>