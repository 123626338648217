import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from '../../../../application/shared/application.service';
import { AgentService } from "../../../agent.service";
import { Subscription } from "rxjs";
import { LoaderService } from "../../../../loader/loader.service";
import { VisitService } from "../../../../visit/visit.service";
import { SessionData } from 'src/app/shared/interfaces/session-data';

@Component({
    selector: 'app-gestores-no-vinculados-dialog-dialog',
    templateUrl: 'gestores-no-vinculados-dialog.component.html',
    styleUrls: ['gestores-no-vinculados-dialog.component.css'],
    providers: [AgentService, VisitService, LoaderService]
  })
  export class GestoresNoVinculadosDialog {
    sessionData: SessionData;
    public productoNoVinculado: any = [];
    public agentesInvalidos: any = [];
    public agente: any [];
    public cuenta: any [];

    tablaProductoNoVinculado: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        columnas: [
            {
                name: 'ID Cliente',
                key: 'idCliente',
                hide: true
            }, {
                name: 'ID Financiera',
                key: 'lenderID',
                hide: true
            }, {
                name: 'ID Cuenta',
                key: 'externalID',
                hide: false
            }, {
                name: 'ID Subcuenta',
                key: 'subExternalID',
                hide: false
            }, {
                name: 'Cuenta',
                key: 'nombreAcreditado',
                hide: false
            }, {
                name: 'Producto',
                key: 'nameProduct',
                hide: false
            }, {
                name: 'ID Cobrador',
                key: 'idCobrador',
                hide: true
            }, {
                name: 'Agente',
                key: 'nombreCompleto',
                hide: false
            }, {
                name: 'ID Producto',
                key: 'idProducto',
                hide: true
            }, {
                name: 'Folio Domicilio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Domicilio',
                key: 'domicilio',
                hide: false
            }, {
                name: ' ',
                key: 'Vincular y Asignar',
                type: 'button',
                hide: false
            }, {
                name: 'ID agente',
                key: 'idAgente',
                hide: true
            }, {
                name: 'ID Acreditado PG',
                key: 'idAcreditadoPG',
                hide: true
            }, {
                name: 'ID Cuenta PG',
                key: 'idCuentaPG',
                hide: true
            }
        ]        
    }

    tablaAgentesInvalidos: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        columnas: [
             {
                name: 'ID Cuenta',
                key: 'idExterno',
                hide: false
            }, {
                name: 'ID Subcuenta',
                key: 'subIdCuenta',
                hide: false
            }, {
                name: 'Cuenta',
                key: 'nombreAcreditado',
                hide: false
            }, {
                name: 'Folio Domicilio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Domicilio',
                key: 'domicilio',
                hide: false
            }, {
                name: 'ID Cobrador',
                key: 'idCobrador',
                hide: true
            }, {
              name: 'Agente',
              key: 'nombreAgente',
              hide: false
            }, {
                name: 'Tipo Red',
                key: 'red',
                hide: false
              }
        ]
    }
    constructor(
        private visitService: VisitService,
        private loaderService: LoaderService,
        private agenteService: AgentService,
        private app: ApplicationService,
        public dialogRef: MatDialogRef<GestoresNoVinculadosDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        //this.agentes = data;
    }

    ngOnInit() {
        this.productoNoVinculado = this.data.productoNoVinculado;
        this.agentesInvalidos = this.data.agentesInvalidos; 
    }
  
    onNoClick(): void {
        this.dialogRef.close();
    }

    onClick({ row, columnPressed }) {
        //vincular producto.
        /*
        this.agente.push({
            idAgente: row.idAgente,
            idCobrador: row.idCobrador,
            nombreCompleto : row.nombreCompleto
        });

        this.cuenta.push({
            idCliente: row.idCliente,
            lenderID: row.idFinanciera,
            externalID : row.idExterno,
            subExternalID: row.subIdCuenta,
            nombreAcreditado: row.nombreAcreditado,
            idAcreditadoPG: row.idAcreditadoPG,
            idCuentaPG: row.idCuentaPG,
            nameProduct: row.nombreProducto,
            idProducto: row.idProducto,
        });*/
        if(!this.validarAgente(row)){
            
            let producto: any;
            producto = {
                idsProductos: [row.idProducto]
            }
            
            const loading: number = this.app.showLoading('Vinculando cuenta.');
            this.agenteService.vincularProducto(row.idCobrador, row.lenderID, producto).subscribe(
                res => {
                    this.app.hideLoading(loading);
                    if (res.success) {
                        this.limpiarAgente(row);
                        this.asignar(row);
                        
                    } else {
                        this.app.showSnackbar(
                            "Aviso",
                            "Ocurrio un Error: " + res.error.message,
                            5000,
                            "error"
                        );
                    }
                },
                error => {
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                }
            );
        }       
    }

    validarAgente(objecto: any):boolean{
        let respuesta:boolean = false
        var newArray = this.agentesInvalidos.filter((item) => item.idCobrador == objecto.idCobrador);
        
        if(newArray.length > 0){
            this.app.showSnackbar(
                "Aviso",
                "El agente no pertenece a la red.",
                3000,
                "warning"
            );
            respuesta = true;
        }

        return respuesta;
    }

    public asignar(objecto: any):void {
        
        let loading: number = this.app.showLoading(
            "Asignando cuentas a agentes..."
        );
        
        let subscription: Subscription = this.visitService
            
            .asignarCuentaAgente(
                [objecto],
                [objecto],
                objecto.idCliente
            )
            .subscribe(
                (response) => {
                    this.loaderService.hideHttpLoader();
                    if (response.success) {
                        
                        if(response.data.vinculados > 0){
                            this.app.showSnackbar(
                                "Aviso",
                                "La cuentas se vinculo y se asigno correctamente.",
                                3000,
                                "success"
                            );
                           
                        }else{
                            this.app.showSnackbar(
                                "Aviso",
                                "Ocurrió un inconveniente en asignar las cuentas.",
                                3000,
                                "warning"
                            );
                        }

                        if(this.agentesInvalidos.length === 0 && this.productoNoVinculado.length === 0) {
                            this.dialogRef.close();
                        }
                        
                    } else {
                        this.app.showSnackbar(
                            "Error",
                            "Ocurrió un error al asignar.",
                            3000,
                            "error"
                        );
                    }
                },
                (error) => {
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                },
                () => {
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                }
            );
    }

    limpiarAgente(objecto: any){
        var newArray = this.productoNoVinculado.filter((item) => item.idCobrador !== objecto.idCobrador && item.idProducto !== objecto.idProducto);
        this.productoNoVinculado =  newArray;
    }

  }