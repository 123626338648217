import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ImportService } from '../../import.service';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class FilterModalComponent implements OnInit {
  public formFiltros: FormGroup;
  public lotes: any[] = [];
  public lote: any[] = [];
  public cargaLotes = 'Cargando Lotes';
  maxInicio = moment(new Date()).format('YYYY-MM-DD');
  minFin = moment(new Date()).format('YYYY-MM-DD');
  maxFin = moment(new Date()).format('YYYY-MM-DD');
  public loadingLotes = false;

  constructor(
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private importService: ImportService,
    private appService: ApplicationService,
  ) {
    this.createForms();
    this.clearFilter('');
  }

  ngOnInit() {}

  private createForms(): void {
    this.formFiltros = this.formBuilder.group({
      fecInicio: [null],
      fecFin: [null],
      lote: [[]]
    });
  }

  cambioFin($event: any) {
    if ($event) {

      if (!this.formFiltros.value.fecInicio) {
        this.limpiarFechaFin();
        this.appService.showSnackbar('¡Aviso!', 'Favor de seleccionar la fecha inicio.', 3000, 'warning');
        return;
      }
      
      this.loadingLotes = true;
      this.maxInicio = moment($event.value).format('YYYY-MM-DD');
      const params = new URLSearchParams();
      params.set('fromDate', this.formFiltros.value.fecInicio.toISOString().substr(0, 10));
      params.set('toDate', this.formFiltros.value.fecFin.toISOString().substr(0, 10));
      params.set('idTipoImportacion', "1");
      const idTipoImportacion = '1';

      this.clearFilter('lote');

      this.importService.getPreCollect(params, idTipoImportacion).pipe(take(1)).subscribe(
        (data) => {
          this.lotes = data;
          this.loadingLotes = false;
        },
        (err) => {
          this.loadingLotes = false;
          console.error(err);
        }
      );
    }
  }

  cambioInicio($event: any) {
    this.minFin = moment($event.value).format('YYYY-MM-DD');
    if(this.formFiltros.get('fecFin').value){
      this.cambioFin($event);
    }
    
  }

  download() {
    const selectedLotes = this.formFiltros.value.lote || [];
    let fechaInicio = null;
    let fechaFin = null;
  
    if (this.formFiltros.value.fecInicio) {
      fechaInicio = this.formFiltros.value.fecInicio.toISOString().substr(0, 10);
      
      if (!this.formFiltros.value.fecFin) {
        this.appService.showSnackbar('¡Aviso!', 'Favor de seleccionar la fecha fin.', 3000, 'warning');
        return;
      }
    }
  
    if (this.formFiltros.value.fecFin) {
      fechaFin = this.formFiltros.value.fecFin.toISOString().substr(0, 10);
      if (!this.formFiltros.value.fecInicio) {
        this.appService.showSnackbar('¡Aviso!', 'Favor de seleccionar la fecha inicio.', 3000, 'warning');
        return;
      }
    }
  
    this.dialogRef.close({
      success: true,
      selectedLotes: selectedLotes,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin
    });
  }

  clearFilter(filter = ''): void {
    if (filter === 'lote') {
      this.formFiltros.get('lote').reset();
    } else {
      this.formFiltros.reset();
      this.lotes = [];
      this.lote = [];
    }
  }

  salir(): void {
    this.dialogRef.close({
      success: false
    });
  }

  limpiarFechaInicio(){
    this.formFiltros.get('fecInicio').reset();
    this.formFiltros.get('lote').reset();
    this.lotes = [];
  }

  limpiarFechaFin(){
    this.formFiltros.get('fecFin').reset();
    this.formFiltros.get('lote').reset();
    this.lotes = [];
  }
}
