export class EmailCollected {
  email: string;

  public static mapArrayData(data: any[]): EmailCollected[] {
    let array: EmailCollected[] = [];
    let object: any;

    data.forEach(element => {
      array.push(EmailCollected.map(element));
    });

    return array;
  }

  public static map(data: any): EmailCollected {
    let instance = new EmailCollected();
    instance.email = data.correo;
    return instance;
  }

}
