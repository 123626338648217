<nav aria-label="breadcrumb" *ngIf="!esDash">
  
  <ol class="breadcrumb">
    <li *ngIf="!tamanioGrande && breadcrumbs.length > 1" class="breadcrumb-item color-gris" [matMenuTriggerFor]="menuBreadcrumb">...</li>
    <li *ngIf="!tamanioGrande" class="breadcrumb-item" [routerLink]="'/'+concatenarRuta(breadcrumbs[breadcrumbs.length - 1].route)" queryParamsHandling="preserve" style="font-size:24px;">
    <a>{{ breadcrumbs[breadcrumbs.length - 1].label }}</a>
    </li>
    <ng-container *ngIf="tamanioGrande">
      <li class="breadcrumb-item" [ngClass]="{'active': item.activo}" *ngFor="let item of breadcrumbs; index as bi">
        <a [routerLink]="'/'+concatenarRuta(item.route)" style="font-size:24px;" queryParamsHandling="preserve" [ngClass]="{'color-gris': bi < breadcrumbs.length-1}">{{ item.label }}</a>
      </li>
    </ng-container>
  </ol>
  <mat-menu #menuBreadcrumb="matMenu">
    <ng-container *ngFor="let item of breadcrumbs; index as bi">
      <button *ngIf="bi < breadcrumbs.length-1" mat-menu-item [routerLink]="'/'+concatenarRuta(item.route)" queryParamsHandling="preserve" >{{ item.label }}</button>
    </ng-container>
  </mat-menu>
</nav>