
<h1 mat-dialog-title>Etiqueta cuenta</h1>
<mat-dialog-content >
  <mat-form-field class="example-full-width">
    <mat-label>Etiqueta</mat-label>
    <mat-select [(ngModel)]="selectedTag">
        <mat-option *ngFor="let tag of data.motivos" [value]="tag.idEtiquetaOperacion" >
          {{tag.nombre}}
        </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="actualizaEtiqueta()"><mat-icon style="color:white !important">save</mat-icon>Guardar</button>
</mat-dialog-actions>