export class ClientAddress {
  id: number;
  homeAddress: string;
  city: string;
  state: string;
  municipality: string;
  zipCode: string;
  location: string;
  fullAddress: string;
  lat: string;
  lng: string;
  suburb: string;
  geoloc: boolean;
  numeroInt: string;
  numeroExt: string;
  calle: string;
  idMunicipio: number;
  idEstado: number;
  idColonia: number;
  idFinanciera: number;
  idCliente: number;
  idExterno: string;
  idCodigoPostal: number;
  entreCalles: string;
  idAcreditado: number;
  idCuenta: number;
  idDomicilioAcreditado: number;
  folioDomicilio: string;
  idAcreditadoCuentaDomicilio: number;



  public getFullAddress(): string {
    return this.homeAddress + ', ' + this.city + ', ' + this.state;
  }

  public static map(data: any): ClientAddress {
    let instance = new ClientAddress();
    instance.id = data.idDomicilioAcreditado;
    instance.homeAddress =`${data.calle ? data.calle + ' ' : ''}
                          ${data.numeroExt ? data.numeroExt + ' ' : ''} 
                          ${data.numeroInt ? data.numeroInt + ' ' : ''}
                          ${data.colonia ? data.colonia + ' ' : ''}
                          ${data.codigoPostal ? data.codigoPostal + ' ' : ''}`;
    instance.city = data.ciudad;
    instance.state = data.estado;
    instance.municipality = data.municipio;
    instance.zipCode = data.codigoPostal;
    // instance.location = data.attr;
    instance.fullAddress = `${instance.homeAddress.trim()}
                            ${instance.city ? instance.city + ', ':''}
                            ${instance.municipality ? instance.municipality + ', ':''}
                            ${instance.state ? instance.state : ''}`;
    instance.lat = data.lat;
    instance.lng = data.lng;
    instance.suburb = data.colonia ? data.colonia : '';
    instance.numeroExt = data.numeroExt ? data.numeroExt : '';
    instance.numeroInt = data.numeroInt ? data.numeroInt : '';
    instance.calle = data.calle ? data.calle : '';
    instance.idMunicipio = data.idMunicipio;
    instance.idEstado = data.idEstado;
    instance.idColonia = data.idColonia ? data.idColonia : null;
    instance.idFinanciera = data.idFinanciera;
    instance.idCliente = data.idCliente;
    instance.idExterno = data.idExterno;
    instance.idAcreditado = data.idAcreditado;
    instance.idCodigoPostal = data.idCodigoPostal ? data.idCodigoPostal: null ;
    instance.entreCalles = data.entreCalles ? data.entreCalles: '';
    instance.folioDomicilio = data.folioDomicilio;
    return instance;

  }
}
