import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private changeBreadcrumbTitleOrigin: Function
  private obtenerQueryParamsOrigin: Function

  constructor() { }

  setChangeBreadcrumbTitle(changeMethod: Function, queryParams: Function){
    this.changeBreadcrumbTitleOrigin = changeMethod;
    this.obtenerQueryParamsOrigin = queryParams;

  }

  changeBreadcrumbTitle(title: string){
    if(this.changeBreadcrumbTitleOrigin){
      this.changeBreadcrumbTitleOrigin(title);
    }
  }

  obtenerQueryParams() : any{
    if(this.obtenerQueryParamsOrigin){
      return this.obtenerQueryParamsOrigin();
    }
    return null;
  }
}
