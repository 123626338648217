import { Component, OnInit } from '@angular/core';
import {  FormGroup, FormControl } from '@angular/forms';
import { LenderService } from './../../../../lender/lender.service';
import { Checklist } from "../../modelos/checklist.model";
import { ApplicationService } from '../../../../application/shared/application.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';





@Component({
  selector: 'config-cuestionario',
  templateUrl: './config-cuestionario.component.html',
  styleUrls: ['./config-cuestionario.component.css'],
   providers: [LenderService]
})
export class ConfigCuestionarioComponent implements OnInit {
  formGroupGeneral: FormGroup | null = null;
  title: string = '';
  dynamicFields: any[] = [];
  products: any[] = [];
  hijosActivos: boolean = false;
  data = null;
  cargandoGeneral: boolean = true;
  public nombreChecklist: String; 
  public checklist: Checklist;
  public nombreFinanciera: string;
  idCliente: number;
  idChecklist: number;

  constructor(
    private lenderService: LenderService,
    private appService: ApplicationService,    
    private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) { 
   
  }

  ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idChecklist = +this.route.snapshot.queryParamMap.get('idChecklist');
    this.nombreChecklist  = this.route.snapshot.queryParamMap.get('nombreCheckList');
    this.nombreFinanciera = this.route.snapshot.queryParamMap.get('nombreCliente');

    if (!this.idCliente || !this.idChecklist || !this.nombreChecklist || !this.nombreFinanciera) {
      this.appService.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos/sucursales`]);
    }else{
      this.init();
    }

  }


  formatPropertyName(name: string): string {
    // Divide la cadena en palabras
    const words = name.split(/(?=[A-Z])/);
  
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  
    for (let i = 1; i < words.length; i++) {
      words[i] = words[i].charAt(0).toLowerCase() + words[i].slice(1).toLowerCase();
    }
  
    return words.join(' ');
  }

  async init() {
    try {
      const fields = await this.lenderService
        .getConfiguracionChecklist(this.idCliente, this.idChecklist)
        .toPromise();
  
  
      let dynamicFields: any[] = [];
      let dynamicFormGroup: { [key: string]: FormControl } = {};
  
      // Procesar cada propiedad del objeto recibido
      Object.keys(fields[0]).forEach(key => {
        const formattedName = this.formatPropertyName(key);
        const fieldName = `field_${key}`;
        // Configurar propiedades del campo dinámico
        dynamicFields.push({
          name: key,
          nombre: formattedName,
          value: fields[0][key] // Corregir esta línea
        });
  
        // Crear un control de formulario dinámico y agregarlo al grupo de formulario dinámico
        dynamicFormGroup[fieldName] = new FormControl(fields[0][key]);
      });
  
      // Almacenar la configuración dinámica y crear el grupo de formulario dinámico
      this.dynamicFields = dynamicFields.slice();
      this.formGroupGeneral = new FormGroup(dynamicFormGroup);
  
    } catch (error) {
      this.cargandoGeneral = false;
      // Manejar el error
      // Puedes mostrar un mensaje de error o realizar alguna acción específica
    }
    this.cargandoGeneral = false;
  }
  
  getFormControl(controlName: string): FormControl | null {
    return this.formGroupGeneral?.get(controlName) as FormControl | null;
  }
  
  
 

  saveConfiguraciones() {
    const loading = this.appService.showLoading(`Guardando la configuración para ${this.nombreChecklist}`);
    
    const saveConfiguration = (values: any) => {
      return this.lenderService.saveConfiguracionChecklist(this.idCliente, this.idChecklist, values);
    };
    
    const handleSaveSuccess = () => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('Aviso', 'Se ha guardado la configuración correctamente', 3000, 'success');
      this.location.back();
    };
    
    const handleSaveError = (error: any) => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    };
    
    const values = Object.keys(this.formGroupGeneral.value).reduce((acc, key) => {
      const fieldKey = key.replace(/^field_/, ''); // Elimina el prefijo "field_"
      acc[fieldKey] = this.formGroupGeneral.value[key];
      return acc;
    }, {});

    saveConfiguration(values).subscribe(handleSaveSuccess, handleSaveError);
    
  }

  cancelar(){
    this.hijosActivos = false;
    this.location.back();
  }

}
