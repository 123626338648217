import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { VisitaCalificadaPaginator } from '../visit/visita-calificada.paginator';
import { CalificadasVisitasServices } from './calificadas-visitas.service';
import { ActivatedRoute,Router } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { VisitaCalificada } from '../visit/visita-calificada.model';
import { ApplicationService } from '../application/shared/application.service';
import { FormGroup } from '@angular/forms';
import { AgentService } from '@agent/agent.service';
import { LenderService } from '@lender/lender.service';
import { Lender } from '@lender/lender.model';
import { Visit } from '../visit/visit.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';



@Component({
  selector: 'app-calificadas-visitas',
  templateUrl: './calificadas-visitas.component.html',
  styleUrls: ['./calificadas-visitas.component.css']
  ,
  providers: [
    AgentService,
    LenderService
  ]
})
export class CalificadasVisitasComponent implements OnInit {
  public tablaVisitasCalificadas: any = [];
  public visitaCalificadaPaginator: VisitaCalificadaPaginator;
  public visitasCalificadas: VisitaCalificada[];
  public sortField: string;
  public sortAsc: string = '';
  public listFiltered: boolean;
  public pageSize = 50;
  public currentPage: number;
  public lenders: Lender[];
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  today: Date;
  hijosActivos: boolean = false;
  sessionData: SessionData;
  idFinanciera: number;
  private slt: string = '"nombreCliente","folioVisita","fechaCalificacion","nombreCompletoAgenteVisita","calificacion","comentario","nombreAgenteCalifica","nombreCompletoFinancieraCalificador","nombreCompletoKobraCalificador"';
  filtrosAbiertos = false;
  ayuda = false;
  loadingVisitas = false;
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;


  public filtros = {
    fechaInicio: null,
    fechaFin: null,
    folioVisita: null,
    idFinanciera: null,
    agenteCalificadoAvailable: null,
    agenteCalificado: null,
    agenteCalificadorAvailable: null,
    agenteCalificador: null,
    page: 1
  }

  constructor(
    public visitasCalificadasServ: CalificadasVisitasServices,
    private filtrosService: FiltrosService,
    private agenteServ: AgentService,
    private lenderServ: LenderService,
    private router: ActivatedRoute,
    private app: ApplicationService,
    private routeNavigate: Router,
    private localStorageService: LocalStorageService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.visitaCalificadaPaginator = new VisitaCalificadaPaginator();
    this.visitasCalificadas = [];
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();


  }

  ngOnInit() {
    this.sortField = 'vc.fec_registro';
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultadosFiltros.bind(this));

  }

  private agregarContabilizadoresDeFiltros(){
      this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
          'idFinanciera'
      ]);
    
      this.contabilizadorFiltros.add('Folio', 'folio', [
          'folioVisita'
      ]);

      this.contabilizadorFiltros.add('Fechas', 'fechas', [
          'fechaInicio',
          'fechaFin'
      ]);
      this.contabilizadorFiltros.add('Agente', 'agente', [
          'agenteCalificado',
          'agenteCalificador'
      ]);
  }

  public contabilizadorDeFiltrosTotales():void{
      

      let totalFiltrosArray: number[] = [];
      if(this.sessionData.isSuperAdmin){
          totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
      }
      totalFiltrosArray.push( this.contabilizadorDeFiltros('folio') );
      totalFiltrosArray.push( this.contabilizadorDeFiltros('fechas') );
      totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

      this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }


  resultadosFiltros(filtrosNuevos: any) {
    if(filtrosNuevos){
      this.listFiltered = true;
      this.filtros = filtrosNuevos;
    }
    else{
      this.inicializaFechas();
    }
  

    this.visitaCalificadaPaginator.pagination.currentPage = this.filtros.page || 1;
    this.loadLenders();
    this.loadVisitasCalificadas(this.visitaCalificadaPaginator.pagination.currentPage);
  }


  inicializaFechas(){
    this.today = new Date();
    this.filtros.fechaInicio = new Date();
    this.filtros.fechaInicio.setDate(this.filtros.fechaInicio.getDate() - 10);
    this.filtros.fechaFin = new Date();

  }

  crearTabla() {
    this.tablaVisitasCalificadas = {
      headers: false,
      multiSelect: false,
      columns: [
        {
          name: 'Cliente',
          key: 'nombreCliente',
          hide: false,
          sortName: 'c.nombre'
        },
        {
          name: 'Folio Visita',
          key: 'folioVisita',
          hide: false,
          link: '/visitas-calificadas/visit/',
          type: 'link',
          sortName: 'v.folio'
        },
        {
          name: 'Fecha Calificación',
          key: 'fechaCalificacion',
          hide: false,
          type: 'dateTime',
          sortName: 'vc.fec_registro'
        },
        {
          name: 'Agente Visita',
          key: 'nombreCompletoAgenteVisita',
          hide: false,
          sortName: 'ag.nombre_completo'
        },
        {
          name: 'Calificación',
          key: 'calificacion',
          hide: false
        },
        {
          name: 'Comentario',
          key: 'comentario',
          hide: false
        },
        {
          name: 'Agente Califica',
          key: 'nombreAgenteCalifica',
          hide: false,
          sortName: 'ag2.nombre_completo'
        },
        {
          name: 'Administrador Califica',
          key: 'nombreCompletoFinancieraCalificador',
          hide: false,
          sortName: 'ca.nombre'
        },
        {
          name: 'Super Administrador Califica',
          key: 'nombreCompletoKobraCalificador',
          hide: false,
          sortName: 'ca2.nombre'
        },

      ]
    }
  }
  
  public loadLenders(): void {
    this.lenderServ.getAll('all').subscribe(
        res => {
            this.lenders = res;
        },
        err => {
            this.app.showError(err);
        }
    );
  }

  public onChangeLender(event) {
    if (event) {
        this.lenders.forEach(lender => {
            if (lender.idFinanciera == event) {
                this.filtros.idFinanciera = lender;
            }
        });
    }
    else {
        this.filtros.idFinanciera = null;
    }
    this.contabilizadorDeFiltros('superAdmin');
}

  loadVisitasCalificadas(page?: number): void {
    this.contabilizadorDeFiltrosTotales();
    this.listFiltered = true;
    page = page || 1;
    
    if((this.filtros.fechaInicio && !this.filtros.fechaFin)
      || (this.filtros.fechaFin && !this.filtros.fechaInicio)){
      
          this.app.showSnackbar('¡Aviso!','Es necesario ingresar un rango válido de fechas',3000,'warning');
          return;
      }
      
    let queryParams: string = '&rows=' + this.pageSize;
    queryParams += this.crearURLQuery();

    this.visitaCalificadaPaginator.pagination.nextPage = page || 1;
    this.currentPage = this.visitaCalificadaPaginator.pagination.nextPage;

    if (page != this.filtros.page) {
      this.filtros.page = page;

      this.filtrosService.guardarFiltros(this.filtros);
    }

    queryParams += '&page=' + page;
    this.loadingVisitas = true;
    this.visitasCalificadasServ.getVisitasCalificadas(this.visitaCalificadaPaginator.pagination, queryParams, this.pageSize)
      .pipe(
        take(1)
      ).subscribe((paginator: VisitaCalificadaPaginator) => {
        

        this.visitaCalificadaPaginator = paginator;
        this.visitasCalificadas = paginator.data;
        this.loadingVisitas = false;
        this.visitasCalificadas = this.visitasCalificadas;
      }, (err: any) => {
       this.loadingVisitas = false;

        this.app.showError(err);
      });
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.visitaCalificadaPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize) {
      this.visitaCalificadaPaginator.pagination.currentPage = newPagina.pageSize;
      this.loadVisitasCalificadas(newPagina.pageIndex + 1);
    }
  }

  crearURLQuery(): string {
    let queryParams = '';

    if (this.slt) {
      queryParams += '&slt=' + this.slt;
    }

    if (this.sortField) {
      queryParams += '&orderBy=' + this.sortField + (this.sortAsc ? ' ' + this.sortAsc : ' desc');
    }

    if (this.filtros.fechaInicio) {
      queryParams += `&fechaInicioCalificacion=${this.filtros.fechaInicio.toISOString().substr(0, 10)}`;
    }

    if (this.filtros.fechaFin) {
      queryParams += `&fechaFinCalificacion=${this.filtros.fechaFin.toISOString().substr(0, 10)}`;
    }

    if (this.filtros.idFinanciera && this.filtros.idFinanciera != 'cli') {
      queryParams += `&idFinanciera=${this.filtros.idFinanciera.idFinanciera}`;
    }

    if (this.filtros.agenteCalificado) {
      queryParams += `&idCobradorVisita=${this.filtros.agenteCalificado.idCobrador}`;
    }

    if (this.filtros.agenteCalificador) {
      queryParams += `&idCobradorCalifica=${this.filtros.agenteCalificador.idCobrador}`;
    }

    if (this.filtros.folioVisita) {
      queryParams += `&folio=${this.filtros.folioVisita}`;
    }

    return queryParams;
  }

  activarHijo(){
    this.hijosActivos = true;
  }

  setFilter(): void {
    
    this.listFiltered = true;
    this.filtros.page = 1;
    this.filtrosService.guardarFiltros(this.filtros);
    const obligatorios = ['fechaFin','fechaInicio' ]; 
    const camposNulos = obligatorios.filter(campo => this.filtros[campo] === null);

    if (camposNulos.length === obligatorios.length) {
        this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar las fechas de visita.`, 3000, 'warning');
        this.filtrosSideNav.toggle();
        //${camposNulos.join(', ')}
        return; 
    }
    this.loadVisitasCalificadas(1);
   this.contabilizadorDeFiltrosTotales();
  }

  inactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.idFinanciera){
        this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultadosFiltros.bind(this));
  }

  clearFilter(): void {
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });

    if(this.idFinanciera){
      this.idFinanciera = null
    }

    this.inicializaFechas();
    this.filtros.page = 1;
    this.filtrosService.removerFiltros();
    //this.loadVisitasCalificadas(1);

  }

  onClick({ row, columnPressed }) {
    if(columnPressed === 'folioVisita') {
          this.LinkVisitas(row);
    }
  }

  LinkVisitas(visita: any) {
    this.routeNavigate.navigate(['/visits/visit/' + visita.folioVisita]);
  }

  setSortField(sort: any) {
    this.sortAsc = sort.direction;
    if (sort.active !== this.sortField) {
      this.sortField = sort.active;
    }
    this.loadVisitasCalificadas(1);
  }

  public agenteCalificadoFilterSearch = function(term: string) {
    return this.agenteServ.obtenerAgentes(term, this.filtros.agenteActivo)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
    );
  }.bind(this);

  public agenteCalificadorFilterSearch = function(term: string) {
    return this.agenteServ.obtenerAgentes(term, this.filtros.agenteActivo)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
    );
  }.bind(this);

}
