import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, Output, EventEmitter, ViewChild, OnInit, Inject } from '@angular/core';
import { CobradoresFacturacionesPeriodosService } from '../../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { ApplicationService } from '../../../application/shared/application.service';
import { CobradoresMovimientosService } from '../../cobradores-movimientos/cobradores-movimientos.service';
import { CobradorFacturacionPeriodo } from '../../cobradores-facturaciones-periodos/cobrador-facturacion-periodo.interface';
import { map, take } from 'rxjs/operators';




@Component({
  selector: 'asignar-periodo.component',
  templateUrl: 'asignar-periodo.component.html',
  styleUrls: ["asignar-periodo.component.css"],
  providers: [CobradoresFacturacionesPeriodosService, CobradoresMovimientosService]
})
export class ModalAsignarPeriodoComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  
  public title = 'Agregar';
  myForm: FormGroup;

  public idCobradorFacturacionPeriodo: any;
  
  fb: FormBuilder;
  idPeriodo: number;
  fechaInicio: any;
  fechaFin: any;
  idCobrador: any;
  idConcepto: any;
 

 

  constructor(
    public dialogRef: MatDialogRef<ModalAsignarPeriodoComponent>,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private app: ApplicationService,
    public movsServ: CobradoresMovimientosService,
    private appService: ApplicationService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.myForm = new FormGroup({
      idCobradorFacturacionPeriodo: new FormControl(),
    });
  }

  ngOnInit(): void {
   
    this.fechaInicio = this.data.fechaInicio.toISOString().substr(0, 10)
    this.fechaFin = this.data.fechaFin.toISOString().substr(0, 10)
    this.idCobrador = this.data.idCobrador !== null ? this.data.idCobrador : this.idCobrador;
    this.idConcepto = this.data.idConcepto !== null ? this.data.idConcepto : this.idConcepto;


  }

  onNoClick(): void {
    this.dialogRef.close({
      success: false,
    });
  }


  public periodosSearch = function (term: string) {
    const query = `?nombre=${term}`;
    return this.periodoServ.obtenerPeriodos(query)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: nombre }
          )
        ))
      );
  }.bind(this);

  onPeriodoFilterSelected(periodo: CobradorFacturacionPeriodo) {
    this.idPeriodo = this.myForm.value.idCobradorFacturacionPeriodo;
  }
 
  
  

  public asignarPeriodo() {
    this.movsServ.asignarPeriodoMovs(this.idPeriodo, this.fechaFin, this.fechaInicio, this.idCobrador, this.idConcepto)
      .pipe(
        take(1)
      )
      .subscribe((res: any) => {
        const hasUnassignedPeriod = res.data.some((item: any) => !item.cobradores_periodos_asignar);
  
        if (hasUnassignedPeriod) {
          this.app.showSnackbar('¡Aviso!', 'Al menos un período no ha sido asignado correctamente.', 5000, 'warning');
          this.salir(false);
        } else {
          this.app.showSnackbar('¡Aviso!', 'Periodo de facturación actualizado correctamente.', 3000, 'success');
          this.salir(true);
        }
  
      }, (error: any) => {
        this.app.showError(error);
      });
  }
  

  salir(flag: boolean) {
    this.dialogRef.close(flag);
  }

 
}
