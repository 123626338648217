import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalificacionVisita } from '../../app/calificaciones-visita/calificacion-visita';
import { ServicesKobraService } from 'src/app/core/http/services-kobra/services-kobra.service';

@Injectable()
export class CalificacionVisitaService {
  private serviceURL = '/calificaciones-visitas';
  constructor(
    private servicesKobra: ServicesKobraService,
  ) { }

  public all( query: string ): Observable<CalificacionVisita[]> {
    return this.servicesKobra.get(this.serviceURL + query).pipe(
      map((res) => <CalificacionVisita[]> res.data)
    );
  }

  public post(calificacion: CalificacionVisita): Observable<CalificacionVisita> {
    return this.servicesKobra
      .post(this.serviceURL, calificacion).pipe(
        map((res) => {
          calificacion.idCalificacionVisita = res.data.idCalificacionVisita;
          return calificacion;
        })
      );
  }
}
