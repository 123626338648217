import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { PhoneType } from "./phone-type.model";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';

@Injectable()
export class PhoneTypeService {
  private apiService = `/v2/phone-types`;

  constructor(
    private apiKobra: ApiKobraService,
  ) { }

  public all( query: string = '' ): Observable<PhoneType[]> {
   const tiposTelefono: PhoneType[] = [
    { id: 1, name: 'Fijo' },
    { id: 2, name: 'Celular' }
    ];

    return of(tiposTelefono);
  }
}