import { SessionData } from '../../../shared/interfaces/session-data';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '../../../application/shared/application.service';
import { Observable } from 'rxjs';
import { AgentService } from '../../agent.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'desvinculacion.component',
  templateUrl: 'desvinculacion.component.html',
  styleUrls: ['desvinculacion.component.css'],
  providers: [AgentService],
})
export class ModalDesvinculacionComponent {
  public cargando = false;
  public idCliente: number;
  lenders: any;
  desvinculacionMasiva: boolean;
  isCheckBloqueo: boolean;
  isClienteBloqueo: boolean;


  constructor(
    private agentService: AgentService,
    public dialogRef: MatDialogRef<ModalDesvinculacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private dialog: MatDialog,
  ) {
    //this.idCliente = this.data.idCliente
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    
  }

  onchageCliente(event){
    if(event){
      this.isCheckBloqueo = true;
      this.desvinculacionMasiva = false;
    }else{
      this.isCheckBloqueo = false;
    }
  }

  onchageMasivo(event){
    if(event){
      this.isClienteBloqueo = true;
      this.idCliente = null;
    }else{
      this.isClienteBloqueo = false;
    }
  }


  desvincular() {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data:{
          titulo: "Confirmar",
          mensaje: `¿Deseas desvincular a los agentes seleccionados.?`,
          icono:"delete_forever",
          boton1: "No",
          boton2: "Sí",
          claseAccion: "boton-accion-eliminar"
      }
  });

  dialogRef.afterClosed().subscribe(result => {

    if(result){
        this.cargando = true;
        const loading: number = this.app.showLoading('Eliminando vinculación...');
        const idsCobradores = this.data.seleccionAgentes.map(datos => datos.id).join(',');
        this.agentService.desvincularcionMasivaAgentes(this.idCliente, this.desvinculacionMasiva, idsCobradores).pipe(
            take(1)
        ).subscribe(
            res => {
                if (res.success) {
                    this.app.showSnackbar('¡Aviso!', 'Los agentes han sido desvinculados correctamente.', 3000, 'success');
                    this.cargando = false;
                }
                else {
                    this.cargando = false;
                    this.app.showError(res.error.message);
                }
                this.app.hideLoading(loading);
                this.dialogRef.close(true);
            },
            error => {
                this.cargando = false;
                this.app.hideLoading(loading);
                this.app.showSnackbar('¡Aviso!', 'Ha ocurrido un error al desvincular a los agentes.', 3000, 'error');
            },
            () => {
                this.app.hideLoading(loading);
                this.cargando = false;
            });
      }
  });

}

  validar(){
    if(this.idCliente || this.desvinculacionMasiva){
      return false;
    }else{
      return true;
    }
  }
    
}
