import {Component, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-confirmacion-dialog-dialog',
    templateUrl: 'confirmacion-dialog.component.html',
    styleUrls: ['confirmacion-dialog.component.css'],
    providers: []
  })
  export class ConfirmacionDialog {
    
    
    constructor(
        public dialogRef: MatDialogRef<ConfirmacionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        //this.agentes = data;
    }

    ngOnInit() {
        
    }
  
    onNoClick(): void {
        this.dialogRef.close(false);
    }

    Aceptar(): void {
        this.dialogRef.close(true);
    }

  }