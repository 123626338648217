<div class="app content">
  <form [formGroup]="detalleForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-loader *ngIf="!items"></app-loader>
        <div class="kobra three columns" *ngIf="items" formArrayName="items">
  
          <div class="kobra column" *ngFor="let item of detalleForm.get('items')['controls']; let i = index;" [ngClass]="{error: detalleForm.get('items')['controls'][i].get('precio').errors}">
            <mat-form-field [formGroupName]="i">
              <mat-label>{{ detalleForm.get('items')['controls'][i].get('nombreConcepto').value }} {{detalleForm.get('items')['controls'][i].get('nuevo').value ? '(nuevo)' : ''}}</mat-label>
              <input matInput textSanitizer  type="number"
                class="example-right-align"
                min="0"
                max="99999"
                (input)="onInputLimite(i)"
                [id]="detalleForm.get('items').get(i.toString()).get('idConcepto').value"
                formControlName="precio"
                placeholder="Capture el costo del concepto">
                <span matTextPrefix>$&nbsp;</span>
              <mat-icon matSuffix class="delete-icon"
                color="warn"
                matTooltip="Borrar concepto"
                mat-tooltip-panel-above
                (click)="borrarConcepto(item, i)"
              >
                delete
              </mat-icon>
              <mat-error>Es necesario capturar un valor entre 1 y 99999</mat-error>
            </mat-form-field>
          </div>

          <div class="kobra column" *ngIf="conceptos && conceptos.length > 0">
            <mat-select-search
              [label]="'Nuevo concepto'"
              [placeHolder]="'Selecciona un nuevo concepto'"
              [value]="'option'"
              [labelField]="'nombreConcepto'"
              [icon]="'magic_button'"
              [options]="conceptos"
              [(ngModel)]="conceptoActual"
              (ngModelChange)="agregarConcepto()"
              [ngModelOptions]="{standalone: true}"
            >
            </mat-select-search>
          </div>
        </div>
    
      </mat-card-content>
      <mat-card-actions align="end" style="gap:10px">
        <button class="boton-cancelar" mat-flat-button type="button" routerLink="/paquetes-cliente/{{idFinanciera}}" queryParamsHandling="preserve"><mat-icon class="kobra-icon">close</mat-icon> Cancelar</button>
        <button  mat-stroked-button [ngClass]="{'boton-guardar': detalleForm.valid || detalleForm.get('items').valid, 'buton-disabled': detalleForm.invalid || detalleForm.get('items').invalid  }" [disabled]="detalleForm.invalid || detalleForm.get('items').invalid "
          [disabled]="saveButton"><mat-icon >save</mat-icon>Guardar</button>
      </mat-card-actions>
    </mat-card>
  </form>

  </div>
