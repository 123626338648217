

<h1 mat-dialog-title>Asignar Periodo</h1>
<mat-dialog-content>
  <div class="header">
    Esta acción asignará el periodo que elija a los movimientos dentro del rango de fechas elegido.
  </div>
  <div [formGroup]="myForm" style="margin-top: 20px;">
    <mat-select-search style="width: 100%; " class="id-card-field" [label]="'Periodo de facturación'"
      [placeHolder]="'Buscar periodo'" [value]="'idCobradorFacturacionPeriodo'" [labelField]="'nombre'" [icon]="'search'"
      [realizarBusqueda]="periodosSearch" formControlName="idCobradorFacturacionPeriodo"
      (ngModelChange)="onPeriodoFilterSelected($event)">
    </mat-select-search>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="asignarPeriodo()">
    <mat-icon>save</mat-icon>Guardar
  </button>
</mat-dialog-actions>