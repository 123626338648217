import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { GeneralChecklist } from "./general-checklist.model";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { MobileKobraService } from "../core/http/mobile-kobra/mobile-kobra.service";
import { map } from "rxjs/operators";

@Injectable()
export class GeneralChecklistService {
  private mobileAPI: string = '/v2/general-checklists';
  private serviceAPI = '/v2/general-checklist';

  constructor(
    private apiKobra: ApiKobraService,
    private mobileKobra: MobileKobraService,
  ) { }

  public one( generalChecklistID: number, query?: string ): Observable<GeneralChecklist> {
    return this.apiKobra.get(`${this.serviceAPI}/${generalChecklistID}${query}`).pipe(
      map((res) => <GeneralChecklist> res.data)
    );
  }

  public create (data: any): Observable<any> {
    return this.mobileKobra.post(`${this.mobileAPI}`, data).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
