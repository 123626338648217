import { NumericInput } from './../../../shared/directives/numric-input.directive';
import { IDescargas } from './../../../descargas/descargas.interface';

import { Component, ElementRef, OnInit, ChangeDetectorRef  } from '@angular/core';
import {VariablesDialog} from "../../checklists/modales/catalogo-variables/variables-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { ModalCargarArchivoWordComponent } from '../modales/cargar-archivo-word/cargar-archivo-word.component';
import { ApplicationService } from "../../../application/shared/application.service";
import { take } from "rxjs/internal/operators/take";
import { VisitService } from "../../../visit/visit.service";
import { Checklist } from "../modelos/checklist.model";
import { environmentSelector } from "../../../../environments/environment.selector";
import moment from 'moment';
import {ConfirmacionDialog} from "../../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
declare var $: any;

export interface IFormatosPagination {
  page: number;
  pages: number;
  totalRows: number;
}

@Component({
	selector: 'app-checklists-formato',
	templateUrl: './checklists-formato.component.html',
	styleUrls: ['./checklists-formato.component.css'],
  providers: [VisitService],
})
export class ChecklistsFormatoComponent implements OnInit {
	public tieneArchivos = false;
  public ayuda: boolean = false;
  public cargando: boolean = false;
  public eliminando: boolean = false;
  public cardAyuda: boolean = false;
  //public checklist: Checklist;
  public cargandoArchivos: boolean = false;
  public file: File[] | null = null;
  public archivos:any;
  public variables:any;
  public loadVariables: boolean = false;
  public descripcion: string;
  public momentFecha;
  public itemsSelected: any[] = [];
  public tipoFormato = 1;
  public descargandoArchivo: boolean = false;
  private environment = environmentSelector();
  public hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;
  isCorregir: boolean = false;


	public tablaArchivos: any;
  public nombreChecklist: String; 
  public nombreCliente: string;
  idCliente: number;
  idChecklist: number;
  idProducto: number;
  numVersion:number;
  idFinanciera: number;
  public totalFiltros: number = 0;
  public contabilizadorFiltros: FiltersContabilizer;
  public filters: any = {
    idEstatus: 1,
    page: 1
  };
  sortField: string = 'f.nombre_original';
  sortAsc: string = '';
  public pagination: IFormatosPagination;
  public pageSize = 50;
  variablesAfectadas:any[] = [];

	constructor(
    public dialog: MatDialog,
    private app: ApplicationService,
    private visitService: VisitService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    this.contabilizadorFiltros = new FiltersContabilizer();
    this.archivos = [];
    this.crearTablaArchivos();
    this.momentFecha = moment;
	}

	ngOnInit() {

    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idFinanciera = +this.route.snapshot.queryParamMap.get('idFinanciera');
    this.idChecklist = +this.route.snapshot.queryParamMap.get('idChecklist');
    this.nombreChecklist = this.route.snapshot.queryParamMap.get('nombreCheckList');
    this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
    this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
    this.numVersion = +this.route.snapshot.queryParamMap.get('numVersion');

    if (!this.idCliente || !this.idChecklist || !this.nombreChecklist || !this.nombreCliente || !this.idProducto || !this.idFinanciera || !this.numVersion) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos`]);
    }else{
      //this.obtenerCliente();
      this.obtienerVariable();
      this.obtenerArchivos(this.filters.page);
    }
    
	}


  onSelected(rows) {
    this.itemsSelected = rows.slice();

    if(this.itemsSelected.length > 0 && this.itemsSelected[0].idEstatus == 3){
      this.isCorregir = true;
    } else {
      this.isCorregir = false;
    }
  }

  onActivate(event) {
    this.hijosActivos = true;
    this.cdr.detectChanges(); // Fuerza la detección de cambios
  }

  onDeactivate(event) {
    this.hijosActivos = false;
    this.obtienerVariable();
    this.cdr.detectChanges(); // Fuerza la detección de cambios
  }

  crearTablaArchivos() {
    this.tablaArchivos= {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columns: [
        {
          name: 'Nombre',
          key: 'nombreArchivo',
          hide: false,
          sortName: 'f.nombre_original',
          rowIcon:true,
          icono: 'warning',
          tooltip: "Este formato contiene irregularidades con las variables. Haz clic aquí para ver el listado completo.",
          class: 'icono-warning'
        }, {
          name: 'Descripción',
          key: 'descripcion',
          hide: false,
          sortName: 'f.descripcion'
        },{
          name: 'Fecha Carga',
          key: 'fechaRegistro',
          hide: false,
          type: 'date',
          sortName: 'f.fec_registro'
        },{
          name: 'Estatus',
          key: 'estatus',
          type: 'estatus',
          hide: false
        }
      ]
    }
  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

  openDialogVariables(isAfectado: boolean): void {

    const dialogRef = this.dialog.open(VariablesDialog, {
        //height: '400px',
        width: '1000px',
        disableClose: true,
        data:{
          variables: this.variables,
          tipoFormato: this.tipoFormato,
          variablesAfectadas: this.variablesAfectadas,
          isVersioNamiento: isAfectado
        }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            //this.filtrosModal = result;
            //this.cargarCuentas(this.pagina);

        }
    });
  }  

  queryParamFiltros(page: number) {

    const campos = '"nombreArchivo","descripcion","fechaRegistro","nombreArchivoServidor","ruta", "idFormato","estatus", "idEstatus", "idCliente", "idCheckList", "numVersion", "varsAfectadas" ';
    let queryParams = `?idTipoFormato=${this.tipoFormato}&_page=${page}&_rows=${this.pageSize}&_paginate=true&_slt=${campos}&_orderBy=${this.sortField}${this.sortAsc ? ' ' + this.sortAsc : ''}`;
    
    if(this.filters.idEstatus == null){
      queryParams+= `&idEstatus=1`;
    }else {
      queryParams+= `&idEstatus=${this.filters.idEstatus}`;
    }

    if(this.filters.idEstatus != 3){
      queryParams+= `&numeroVersion=${this.numVersion}`;
    }

    return queryParams;
  }

  public obtenerArchivos(page: number) {
    this.cargandoArchivos = true;
    const queryParams = this.queryParamFiltros(page);
    this.visitService.obtenerFormatosGrid(this.idCliente, this.idChecklist, queryParams)
        .pipe(take(1))
        .subscribe((respuesta: any) => {
          
          this.pagination = {
            page: respuesta.data['page'],
            pages: respuesta.data['pages'],
            totalRows: respuesta.data['totalRows'],
          };

          this.archivos = respuesta.data['pageData'];
          this.archivos.forEach(element => {
            element.activarIcono = false;

            if(element.idEstatus == 3){
              element.activarIcono = true;
            } 
          });
          this.cdr.detectChanges();
          this.cargandoArchivos = false;

        },(error: any) => {
              this.cargandoArchivos = false;
              this.app.showError(error);
          }
        );
  }

  obtienerVariable(): void {
    this.loadVariables = true;
    this.variables = [];
    this.visitService.obtienerVariable(this.idCliente, this.idChecklist, this.numVersion, this.idProducto, this.tipoFormato)
        .subscribe(
          variables => {
                this.variables = variables.data;
                this.loadVariables = false;
            },
            error => this.app.showError(error)
        );
  }

  public descargarArchivo(archivo: any) {
    this.descargandoArchivo = true;
  
    let queryparams = `?ruta=${archivo.ruta}&nombreArchivo=${archivo.nombreArchivoServidor}`;

    this.visitService.descargarArchivoStorage(queryparams ,this.idCliente).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, archivo.nombreArchivo);
      }
    }, error => {
      this.descargandoArchivo = false;
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
    });
  }

  public eliminarArchivo(archivo: any): void {
    this.eliminando = true;
    this.visitService.obtienerDocumentosDescargados(this.idCliente, archivo.idFormato)
        .subscribe(
          respuesta => {
                if(respuesta.data.length > 0){

                  this.app.showSnackbar(
                    "Aviso",
                    "El documento no puede ser eliminado por que ya cuenta con descargas por parte del agente.",
                    4000,
                    "warning"
                  );

                  this.eliminando = false;
                  return;
                }else{
                  this.eliminarArchivoS3(archivo);
                }
                //this.loadVariables = false;
            }, error => {
              this.eliminando = false;
              this.app.showError(error);
          });
  }

  public eliminarArchivoS3(archivo: any) {
    this.eliminando = true;
    this.visitService.eliminarArchivo(this.idCliente, archivo.ruta, archivo.nombreArchivoServidor)
        .pipe(take(1))
        .subscribe((respuesta) => {
          if(respuesta.success) {    
            this.eliminarFormato(archivo);
          }else {
            this.eliminando = false;
            this.app.showSnackbar(
              "Aviso",
              "Ocurrio un problema al eliminar el archivo.",
              3000,
              "error"
            );
          }
        }, error => {
            this.eliminando = false;
            this.app.showError(error);
        });
  }

  public eliminarFormato(archivo: any) {
    let loading: number = this.app.showLoading('Eliminando archivo...');
    this.eliminando = true;
    this.visitService.eliminarFormatoVisita(this.idCliente, archivo.idFormato, this.idChecklist, this.numVersion)
        .pipe(take(1))
        .subscribe((respuesta) => {
          if(respuesta.success) {   
            this.eliminando = false; 
              this.app.showSnackbar(
                "Aviso",
                "El documento se ha eliminado correctamente.",
                3000,
                "success"
            );
            this.app.hideLoading(loading);
            //this.obtenerArchivos();  
            this.archivos = this.archivos.filter(a => a.idFormato !== archivo.idFormato);
            this.cdr.detectChanges();
          }else {
            this.eliminando = false;
            this.app.showSnackbar(
              "Aviso",
              "Ocurrio un problema al eliminar el archivo.",
              3000,
              "error"
            );
            this.app.hideLoading(loading);
          }
        }, error => {
            this.app.hideLoading(loading);
            this.eliminando = false;
            this.app.showError(error);
        });
  }

  openDialogConfirmacion(archivo:any): void {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas eliminar la plantilla seleccionada?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                texto: archivo.nombreArchivo,
                claseAccion: "boton-accion-eliminar"
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.eliminarFormato(archivo);
        }   
    });
  }

  openDialogCargarArchivo(actualizar: boolean): void {

    const dialogRef = this.dialog.open(ModalCargarArchivoWordComponent, {
        //height: '400px',
        //width: '300px',
        //disableClose: true,
        data:{
          idCliente: this.idCliente,
          idFinaciera: this.idFinanciera,
          idChecklist: this.idChecklist,
          numVersion: this.numVersion,
          idProducto: this.idProducto,
          tipoFormato: this.tipoFormato,
          formatoConError: actualizar ? this.itemsSelected : []
        }
    });

    dialogRef.afterClosed().subscribe(result => {
       if (result.success) {
        // Verificamos si `bloques` o `checklist` tienen datos
        const bloquesExisten = result.data?.bloques?.length > 0;
        const checklistExiste = result.data?.checklist?.length > 0;
  
        // Si `bloques` o `checklist` tienen elementos, llamamos a `variablesConfirmacion`
        if (bloquesExisten || checklistExiste) {
          this.variablesAfectadas = result.data;
          this.openDialogVariables(true);
        }
  
        this.obtenerArchivos(this.filters.page);
      }
    });
  }

  /*variablesConfirmacion(data: any): void {

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
                titulo: "Confirmar",
                mensaje: "Hemos encontrado algunas irregularidades en las variables de la plantilla. ¿Te gustaría revisarlas?",
                icono: "done",
                //colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                //texto: archivo.nombreArchivo,
                claseAccion: "boton-accion-front"
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
          this.variablesAfectadas = data;
          this.openDialogVariables(true);
        }   
    });
  }*/

  public abrirBloques() {
    this.router.navigate(
      ['bloques', {}],
      {
        relativeTo: this.route,
        queryParams: 
          {
            idCliente: this.idCliente,
            idChecklist: this.idChecklist,
            numVersion: this.numVersion,
            idProducto: this.idProducto           
          },
          queryParamsHandling: 'merge'      
      })
  }

  filterModal(filtro: boolean) {
    if (filtro) {
      this.obtenerArchivos(1);
    }else {
      this.filters.idEstatus = 1;
    }
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
      this.filters.page = newPagina.pageIndex+1;
      this.obtenerArchivos(newPagina.pageIndex+1);
    }
  }

  public setSortField(sort: any) {
    this.sortAsc = sort.direction;
    if (sort.active != this.sortField) {
        this.sortField = sort.active;
    }

    this.obtenerArchivos(1);
  }

  onClick({ row, columnPressed }){  
    if(columnPressed == 'nombreArchivo'){
      this.variablesAfectadas = JSON.parse(row.varsAfectadas);
      this.openDialogVariables(true);
    }
  }

}
