
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Visit } from "./visit.model";
import { VisitService } from "./visit.service";
import { CalificacionVisita } from "../calificaciones-visita/calificacion-visita";
import { CalificacionVisitaService } from "../calificaciones-visita/calificacion-visita.service";
import { ApplicationService } from "src/app/application/shared/application.service";
import { Financiera } from "../financieras/shared/financiera.model";
import { take } from 'rxjs/internal/operators/take';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { FormGroup, FormBuilder } from "@angular/forms";



declare const $: any;

@Component({
    selector: 'visita-masiva-detail',
    templateUrl: 'visita-masiva-detail.component.html',
    styleUrls: ['visita-masiva-detail.component.css'],
    providers: [
        VisitService,
        CalificacionVisitaService,
    ]
})

export class VisitaMasivaDetailComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Input() visitasACalificar: Visit[];
    @Input() idCliente: number;
    @Input() idFinanciera: number;
    @ViewChild("asignarAgenteFilterInput")

    public $confirmModal: any;
    public $cambiarEtiquetaModal: any;
    sessionData: SessionData;
 
    public idAgent: number;
    public folioVisita: any;
    public visit: Visit;
    public calificacionAcreditado: CalificacionVisita;
    public calificacionAdministradorFinanciera: CalificacionVisita;
    public calificacionSuperAdministrador: CalificacionVisita;
    public financiera: Financiera;
    public etiquetas: any[] = [];
    public etiquetasCuenta = [];
    public etiquetaActual: any = null;
    public nvaEtiqueta: any = null;
    public url = null;
    public formAsignacion: FormGroup;
    public assignComment: string;
    public nombreAgente: string;
    public etiquetasCuentaHistorial = [];
    public loadingEtiquetasCuenta: boolean = false;
    public loadingHistorialEtiquetasCuenta = false;
    public selectedEtiqueta: string = null;
    public loader: boolean;
    public textLoader: string;
    public visitaIndex: number;
    public visitasTotales: any;
    public loadingEtiquetas: boolean;
    public resetRate = 0;
    public itemsSelected: any;
    public idVisita: number;

   

    tablaEtiquetasCuentas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fecFinalizada',
        columnas: [
            {
                name: 'ID',
                key: 'idHisAcreditadoCuentaEtiqueta',
                hide: true
            }, {
                name: 'Folio Visita',
                key: 'folioVisita',
                type: 'link',
                hide: false
            }, {
                name: 'Nombre Administrador',
                key: 'nombreAdministradorFullName',
                hide: false
            }, {
                name: 'Nombre Etiqueta',
                key: 'nombreEtiquetaCuenta',
                hide: false
            }, {
                name: 'Comentarios',
                key: 'comentarios',
                hide: false
            }, {
                name: 'Fecha',
                key: 'fecRegistro',
                hide: false,
                type: 'dateTime'
            }
        ]
    }
  




    constructor(
        private app: ApplicationService,
        private visitService: VisitService,
        private localStorageService: LocalStorageService,

    ) {

        this.financiera = new Financiera();
        this.sessionData = this.localStorageService.getSessionData();
    }

    ngOnInit() {
       
        this.visit = new Visit();
    

        if (this.visitasACalificar) {

            this.visitaIndex = 0;
            this.obtenerVisita(this.visitaIndex);
        }
        this.cargarEtiquetasCuenta();

    }


    obtenerVisita(pos: any) {

        if (pos === this.visitasACalificar.length) {
            this.closeModal.emit();
        } else {
            this.resetRate = this.resetRate === 0 ? undefined : 0;
            this.visitaIndex = pos;
            this.folioVisita = this.visitasACalificar[pos].folio;
        }
        this.visitasTotales = this.visitasACalificar.map(visita => {


            return {
                calificacion: null,
                comentLibre: null,
                comentario: null,
                idAgent: visita.idAgenteM,
                idAgente: visita.idAgenteM,
                idEtiquetaCuenta: this.etiquetaActual,
                idFinanciera: this.idFinanciera,
                idSuperAdministrador: this.sessionData.idUsuario,
                idVisita: visita.id,
            };
        });

    }

    cargarEtiquetasCuenta() {
        this.loadingEtiquetasCuenta = true;
        this.visitService.obtenerEtiquetasCuenta(this.idCliente)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingEtiquetasCuenta = false;
                this.etiquetasCuenta = respuesta.data;
            }, (err: any) => {
                this.loadingEtiquetasCuenta = false;
                this.app.showError(err);
            });
    }





}
