import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { OperationTag } from "./operation-tag.model";
import { HttpErrorService } from "../shared/http-error/http-error.service";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { GenericReservedActiveResponse } from "@reservedActive/generic-reserved-active";
import { GenericReservedActive } from "@reservedActive/generic-reserved-active";
import { map } from 'rxjs/operators';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable()
export class OperationTagService {
  private apiService: string = '/v2/operation-tags';
  private resourceAppsServices: string = '/etiquetas-operaciones';
  private resourceVisitsServices: string = '/etiquetas-operaciones';
  constructor(
    private apiKobra: ApiKobraService,
    private serviciosKobra: ServiciosKobraService,
    private httpErrorService: HttpErrorService,
    private visitasServ: ServicesVisitasService
  ) { }

  public getAll( query: string = '' ): Observable<OperationTag[]> {
    return this.serviciosKobra.get(`/etiquetas-operaciones${query}`).pipe(
      map((res) => OperationTag.mapArray(res.data))
    )
  }

  //obtiene las cuentas apartadas activas por etiqueta
  public getAccountReservedActive(idCliente: any): Observable<GenericReservedActive> {
    let query: string = ''

    if (idCliente) {
      query += '?idCliente=' + idCliente;
    }

    return this.visitasServ.get(this.resourceVisitsServices + '/cuentas-apartadas-activas' + query).pipe(
      map(res => {
        let sumActivas = 0;
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
            sumActivas += +res.data[i].totalCuentasActivas;
            sumApartadas += +res.data[i].totalCuentasApartadas;

            let porcentage = 0;

            if ( +res.data[i].totalCuentasActivas && +res.data[i].totalCuentasApartadas) {
              porcentage = (+res.data[i].totalCuentasApartadas * 100 /(+res.data[i].totalCuentasActivas + +res.data[i].totalCuentasApartadas))
            }

            let tagReservedActiveResponse: GenericReservedActiveResponse = {
              nombre: res.data[i].nombreEtiqueta,
              totalActivas: +res.data[i].totalCuentasActivas,
              totalApartadas: +res.data[i].totalCuentasApartadas,
              porcentajeApartadas: porcentage,
              idOperationTag: res.data[i].idEtiquetaOperacion
            };

            genericsReservedActiveResponse.push(tagReservedActiveResponse);
        }

        let sumPorcentage = 0;

        if ( +sumApartadas && sumActivas) {
          sumPorcentage = (+sumApartadas * 100 /(+sumApartadas + sumActivas))
        }

        let stateReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumActivas: sumActivas,
          sumApartadas: sumApartadas,
          sumPorcentajeApartadas: sumPorcentage
        };

        return stateReservedActive;
      })
    );
  }

}
