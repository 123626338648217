import { Tarifa } from "../../../tarifa-conceptos/tarifa-conceptos.model";

export class Producto {
    idConcepto: number;
    idTarifa: number; 
    nombre: string; 
    precio: number;
    tarifasCobradores: Array<Tarifa>;

    constructor() {
        this.tarifasCobradores = [];
    }
}
