<div class="app content kobra" >
  <div class="dashboard-main-container">
    <form [formGroup]="filtrosForm" class="formulario">
      <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="80px">
   
        <mat-grid-tile colspan="1" rowspan="1">
          <mat-date-picker-formater
            [label]="'Fecha inicio'"
            [placeHolder]="'Capture la fecha inicio'"
            [max]="filtrosForm.value.fecFin"
            (ngModelChange)="cambioInicio($event)"
            formControlName="fecInicio"
            [limpiar]="false"
          />
        </mat-grid-tile>
    
        <mat-grid-tile colspan="1" rowspan="1">
          <mat-date-picker-formater
            [label]="'Fecha fin'"
            [placeHolder]="'Capture la fecha fin'"
            [max]="today"
            [min]="filtrosForm.value.fecInicio"
            (ngModelChange)="cambioFin($event)"
            formControlName="fecFin"
            [limpiar]="false"
          />
        </mat-grid-tile>
        
    
      </mat-grid-list-responsive>
    </form>
  
    <mat-card>
      <mat-card-content>
  
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="160px">
  
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Visitas finalizadas en el periodo de fechas seleccionado" mat-tooltip-panel-above>
              <mat-card-content>
                <app-loader *ngIf="loadingTVR" [message]="'Cargando visitas realizadas...'"></app-loader>
                <div *ngIf="!loadingTVR" class="valor valor-centered">
                  {{totalVisitasRealizadas | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTVR">
                  Visitas realizadas
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Visitas que se iniciaron pero no se han finalizado en el periodo de fechas seleccionado" mat-tooltip-panel-above> 
              <mat-card-content>
                <app-loader *ngIf="loadingTVEP" [message]="'Cargando visitas en proceso...'"></app-loader>
                <div *ngIf="!loadingTVEP" class="valor valor-centered">
                  {{totalVisitasEnProceso | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTVEP">
                  Visitas en proceso
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Promesas de pago hechas en el periodo de fechas seleccionado" mat-tooltip-panel-above> 
              <mat-card-content>
                <app-loader *ngIf="loadingTVPP" [message]="'Cargando promesas de pago...'"></app-loader>
                <div *ngIf="!loadingTVPP" class="valor valor-centered">
                  {{totalVisitasPromesasPago | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTVPP">
                  Promesas de pago
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Cuentas que están pendientes de confirmación a la fecha" mat-tooltip-panel-above> 
              <mat-card-content>
                <app-loader *ngIf="loadingTCEE" [message]="'Cargando pendientes de confirmación...'"></app-loader>
                <div *ngIf="!loadingTCEE" class="valor valor-centered">
                  {{totalCuentasEnEsperaConfirmacion | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTCEE">
                  pendientes de confirmación
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
    
      
        </mat-grid-list-responsive>
  
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="160px">
   
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Cuantas cuentas se apartaron en el periodo seleccionado" mat-tooltip-panel-above>
              <mat-card-content>
                <app-loader *ngIf="loadingTVR" [message]="'Cargando cuentas apartadas...'"></app-loader>
                <div *ngIf="!loadingTVR" class="valor valor-centered">
                  {{totalCuentasApartadas | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTVR" >
                  Cuentas Apartadas
                </div>
                <!-- <div class="inner">
                  Ver Reporte AyA
                </div> -->
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
    
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Cuentas nuevas que se subieron a la plataforma en el periodo de fechas seleccionado" mat-tooltip-panel-above> 
              <mat-card-content>
                <app-loader *ngIf="loadingTCN" [message]="'Cargando cuentas nuevas...'"></app-loader>
                <div *ngIf="!loadingTCN" class="valor valor-centered">
                  {{totalCuentasNuevas | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTCN">
                  Cuentas Nuevas
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
    
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card " matTooltip="Cuentas que pasaron de inactivas a activas (mediante importación) en el periodo de fechas seleccionado" mat-tooltip-panel-above> 
              <mat-card-content>
                <app-loader *ngIf="loadingTCN" [message]="'Cargando cuentas antiguas...'"></app-loader>
                <div *ngIf="!loadingTCN" class="valor valor-centered">
                  {{totalCuentasAntiguas | number }}
                </div>
                <div class="titulo" *ngIf="!loadingTCN">
                  Cuentas Antiguas
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
      
        </mat-grid-list-responsive>
  
      </mat-card-content>
    </mat-card>
    
    <mat-card class="card-pricipal ">
      <mat-card-content>
      
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="200px">
      
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 3, idEtapaCuenta: 1}" >
                <mat-card-content >
                  <app-loader *ngIf="loadingCT" [message]="'Cargando cuentas por vincular...'"></app-loader>
                  <div *ngIf="!loadingCT" class="valor valor-centered">
                    {{ctPorVincular | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCT">
                    Por vincular
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 3, idEtapaCuenta: 2}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCT" [message]="'Cargando cuentas por asignar...'"></app-loader>
                  <div *ngIf="!loadingCT" class="valor valor-centered">
                    {{ctPorAsignar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCT">
                    Por asignar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 3, idEtapaCuenta: 3}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCT" [message]="'Cargando cuentas por apartar...'"></app-loader>
                  <div *ngIf="!loadingCT" class="valor valor-centered">
                    {{ctPorApartar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCT">
                    Por apartar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 3, idEtapaCuenta: 4}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCT" [message]="'Cargando cuentas por visitar...'"></app-loader>
                  <div *ngIf="!loadingCT" class="valor valor-centered">
                    {{ctPorVisitar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCT">
                    Por visitar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
        </mat-grid-list-responsive>
      </mat-card-content>
    </mat-card>
    
  
    <mat-card class="card-pricipal ">
      <mat-card-content>
        <!-- <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="160px">
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/general" [queryParams]="{idTipoCobertura: 2}" matTooltip="Cuentas que no tienen ni un agente activo pero tienen por lo menos un agente inactivo alrededor de 5km de su ubicación" mat-tooltip-panel-above>
                <mat-card-content>
                  <app-loader *ngIf="loadingCP" [message]="'Cargando cuentas con cobertura parcial...'"></app-loader>
                  <div *ngIf="!loadingCP" class="valor-amarrillo valor-centered" style="font-size: 6rem!important;">
                    {{coberturaParcial | number }}
                  </div>
                  <div class="titulo-2">
                    Cuentas con cobertura parcial
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de cobertura</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
        </mat-grid-list-responsive> -->
      
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="1" rowHeight="160px">
       
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 2, idEtapaCuenta: 2}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCP" [message]="'Cargando cuentas por asignar...'"></app-loader>
                  <div *ngIf="!loadingCP" class="valor-amarrillo valor-centered">
                    {{cpPorAsignar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCP">
                    Por asignar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 2, idEtapaCuenta: 1}">
              <mat-card-content>
                <app-loader *ngIf="loadingCP" [message]="'Cargando cuentas por vincular...'"></app-loader>
                <div *ngIf="!loadingCP" class="valor-amarrillo valor-centered">
                  {{cpPorVincular | number }}
                </div>
                <div class="titulo" *ngIf="!loadingCP">
                  Por vincular
                </div>
                <div class="inner">
                  <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
      
          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 2, idEtapaCuenta: 3}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCP" [message]="'Cargando cuentas por apartar...'"></app-loader>
                  <div *ngIf="!loadingCP" class="valor-amarrillo valor-centered">
                    {{cpPorApartar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCP">
                    Por apartar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" rowspan="1">
              <mat-card class="card  cursor" routerLink="/cobertura/vinculacion" [queryParams]="{idTipoCobertura: 2, idEtapaCuenta: 4}">
                <mat-card-content>
                  <app-loader *ngIf="loadingCP" [message]="'Cargando cuentas por visitar...'"></app-loader>
                  <div *ngIf="!loadingCP" class="valor-amarrillo valor-centered">
                    {{cpPorVisitar | number }}
                  </div>
                  <div class="titulo" *ngIf="!loadingCP">
                    Por visitar
                  </div>
                  <div class="inner">
                    <span style="font-size: 1.6rem;">Ver tablero de vinculación</span>
                  </div>
                </mat-card-content>
              </mat-card>
          </mat-grid-tile>
      
          
        </mat-grid-list-responsive>
      </mat-card-content>
    
    </mat-card>
  
    </div>
</div>

<router-outlet></router-outlet>
