import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransitionService {
  private transitionSubject = new BehaviorSubject<boolean>(false);

  getTransitionState() {
    return this.transitionSubject.asObservable();
  }

  setTransitionState(state: boolean) {
    this.transitionSubject.next(state);
  }
}
