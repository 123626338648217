import { ServiciosKobraService } from './../../core/http/servicios-kobra/servicios-kobra.service';
import { ApiKobraService } from './../../core/http/api-kobra/api-kobra.service';
import { ServicesKobraService } from './../../core/http/services-kobra/services-kobra.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { environmentSelector } from 'src/environments/environment.selector';

@Injectable({
    providedIn: 'root'
})
export class ProductosService {
  baseURL: string = environmentSelector().kobraServices.visitas;

  private apiService: string = '/v2/visits';
  private serviceURL: string = '/visitas';
  private resource: string = '/visitas';
  private leanServicesURL = '/visitas';


  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private serviciosKobra: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
    private httpClient: HttpClient

  ) { }

  public obtenerAdministradoresProductos(idCliente, idProducto){
    return this.serviciosVisitas.get(`/administradores/productos?${idCliente?'idCliente='+idCliente+'&':''}idProducto=${idProducto}`);
  }

  public obtenerProductos(idCliente, queryParams: string){
    return this.serviciosVisitas.get(`/clientes/${idCliente}/catalogo-productos${queryParams}`);
  }

  public obtenerProducto(idCliente: number, idProducto: number){
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos/${idProducto}`);
    
  }

  public obtenerTiposImportacion(){
    return this.serviciosVisitas.get(`/catalogos/tipos-importaciones`);
  }

  public obtenerTiposProductos(){
    return this.serviciosVisitas.get(`/catalogos/tipos-productos`);
  }

  public agregarProducto(idCliente: number, productos: any){
    return this.serviciosVisitas.post(`/clientes/${idCliente}/productos`, productos);
  }

  public editarProducto(idCliente: number, productos: any){
    return this.serviciosVisitas.patch(`/clientes/${idCliente}/productos`, productos);
  }

  public eliminarProducto(idCliente: number, idProducto: number){
    return this.serviciosVisitas.delete(`/clientes/${idCliente}/productos/${idProducto}`);
  }
  
}