import { AddressValidationChecklist } from "../address-validation-checklist/address-validation-checklist.model";
import { BusinessValidationChecklist } from "../business-validation-checklist/business-validation-checklist.model";
import { DebtCollectionChecklist } from "../debt-collection-checklist/debt-collection-checklist.model";

export class GeneralChecklist {
  id: number;
  comment: string;
  createdAt: Date;
  addressValidationChecklist: AddressValidationChecklist;
  businessValidationChecklist: BusinessValidationChecklist;
  debtCollectionChecklist: DebtCollectionChecklist;
  mockLocation: boolean;

  constructor() {
    this.addressValidationChecklist = new AddressValidationChecklist();
    this.businessValidationChecklist = new BusinessValidationChecklist();
    this.debtCollectionChecklist = new DebtCollectionChecklist();
  }
}