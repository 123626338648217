export class Financiera {
  idFinanciera: number;
  nombre: String;
  nombreFinanciera: String;

  public static mapArrayData(data: any[]): Financiera[] {
    let array: Financiera[] = [];
    let object: any;

    data.forEach(element => {
      array.push(Financiera.map(element));
    });

    return array;
  }

  public static map(data: any): Financiera {
    let instance = new Financiera();
    instance.nombre = data.nombre;
    instance.nombreFinanciera = data.nombreFinanciera;
    return instance;
  }
}
