import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from "rxjs/internal/operators/map";
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from "../../../application/shared/application.service";
import { Subscription } from "rxjs";
import { LoaderService } from "../../../loader/loader.service";
import { VisitService } from "../../../visit/visit.service";
import { environmentSelector } from "../../../../environments/environment.selector";
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from '../../../shared/interfaces/session-data';

@Component({
  selector: 'listado-contratos.component',
  templateUrl: 'listado-contratos.component.html',
  styleUrls: ["listado-contratos.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalListadoContratosComponent {
  public sessionData: SessionData;
  public cargandoArchivos: boolean = false;
  public descargandoArchivo: boolean = false;
  public cardAyuda: boolean = false;
  private environment = environmentSelector();
  public archivos = [];
  constructor(
    public dialogRef: MatDialogRef<ModalListadoContratosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private visitService: VisitService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.archivos = this.data.archivos;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    //this.obtenerArchivos();
  }

  public descargarArchivo(archivo:any) {
    this.descargandoArchivo = true;
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    let country = "&Country=";
    country+= paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
    window.open(this.environment.kobraServices.storage+`/clientes/${archivo.idCliente}/descargar-archivos?token=${localStorage.getItem('access-token')}&ruta=${archivo.ruta}&nombreArchivo=${archivo.nombre}${country}`);
    this.descargandoArchivo = false;
  }

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

}
