<h1 mat-dialog-title>Contractos</h1>
<mat-dialog-content>
  <div>
    <mat-card class="cardContenido">
      <mat-card-content>
        <div *ngIf="archivos.length > 0">
          <div content class="contenido">
              <div *ngFor="let archivo of archivos; let i = index;">
                <div>
                  <mat-card class="card">
                    <div>                      
                      <div style="display: flex;">
                        <mat-icon class="iconoDoc">description</mat-icon>
                        <span style="word-break: break-all; flex: 1; margin-top: 10px;">{{archivo.nombre}}</span> 
                        <div class="iconoGrid">
                          <button mat-icon-button
                            (click)="descargarArchivo(archivo)"
                            matTooltip="Descargar documento"
                            mat-tooltip-panel-above>
                            <mat-icon lass="kobra-icon">cloud_download</mat-icon>
                          </button>                         
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
</mat-dialog-actions>