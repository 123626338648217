import { ServiciosKobraService } from './../../core/http/servicios-kobra/servicios-kobra.service';
import { ApiKobraService } from './../../core/http/api-kobra/api-kobra.service';
import { ServicesKobraService } from './../../core/http/services-kobra/services-kobra.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { environmentSelector } from 'src/environments/environment.selector';
import { IProductosAdministrador} from '../usuarios/productos/productos.interface';
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';

@Injectable({
    providedIn: 'root'
})
export class ProductosService {
  baseURL: string = environmentSelector().kobraServices.visitas;

  private apiService: string = '/v2/visits';
  private serviceURL: string = '/visitas';
  private resource: string = '/visitas';
  private leanServicesURL = '/visitas';


  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private serviciosKobra: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
    private httpClient: HttpClient

  ) { }

  public obtenerAdministradoresProductos(idCliente, idProducto){
    return this.serviciosVisitas.get(`/administradores/productos?${idCliente?'idCliente='+idCliente+'&':''}idProducto=${idProducto}`);
  }

  public obtenerAdministradoresCliente(idCliente){
    return this.serviciosVisitas.get(`/clientes/${idCliente}/administradores`);
  }

  public guardarProductos(idProducto,idCliente,administradores){
    return this.serviciosVisitas.patch(`/productos/${idProducto}`,{idCliente:idCliente,administradores:administradores});
  }

  public obtenerProducto(idCliente: number, idProducto: number){
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos/${idProducto}`);
    
  }

  public obtenerProductosPorCliente(idCliente: number): Observable<IProductosAdministrador[]> {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos`).pipe(
        map((res: ServiciosKobraResponse) => res.data)
    );
}

public obtieneProductoPorAdministrador(idCliente: number, idClienteAdministrador: number): Observable<IProductosAdministrador[]> {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos/administradores/${idClienteAdministrador}`).pipe(
        map((res: ServiciosKobraResponse) => res.data)
    );
}

public editarProductosPorAdministrador(idCliente:number, idClienteAdministrador: number, ProductoAdiministrador: IProductosAdministrador[] = [] ): Observable<any> {
    const body = { productos: ProductoAdiministrador }; 
    return this.serviciosVisitas.patch(`/clientes/${idCliente}/administradores/${idClienteAdministrador}/productos`, body).pipe(
        map(res => {
            return res;
        })
    );
}
}