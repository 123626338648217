import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CubejsClient } from "@cubejs-client/ngx";
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Subject } from "rxjs";
import moment from "moment";
import cubejsOptions from '../../../../cubejsOptions';

interface ChartPivotFunc {
    (): any[];
}

interface SeriesNamesFunc {
    (): any[];
}

interface rawDataFunc {
    (): any[];
}

interface ResultSet {
    chartPivot: ChartPivotFunc;
    seriesNames: SeriesNamesFunc;
    rawData: rawDataFunc;
    loadResponse: any;
}

interface TreeNode {
  name: string;
  value: string;
  children?: TreeNode[];
}

interface TreeFlatNode {
  expandable: boolean;
  name: string;
  value: string;
  level: number;
}

moment.locale('es');
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
  providers: [DecimalPipe]
})
export class ChartComponent implements OnInit {

  @Input() chartType;
  @Input() query;
  @Input() title;
  @Input() rango;
  @Input() granularity: string;
  @Input() measure: string;
  @Input() measure_display: boolean;
  @Input() dummy: boolean;
  @Input() isVisible: boolean = true;
  @Input() visibility: string;
  @Input() trend: string;

  @Output() onLoadEnd: EventEmitter<any> = new EventEmitter();
  
  constructor(private cubejs: CubejsClient, private decimalPipe: DecimalPipe) {}

  public queries: any = [];
  public loader: boolean = true;
  public chartData = [];
  public chartLabels;
  public barChartPlugins = [pluginDataLabels];

  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };
  public baseChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };
  public chartColors;
  public lineChartColors = [
    {
      borderColor: "#7DB3FF",
      
    },
    {
      borderColor: "#49457B",
      
    },
    {
      borderColor: "#FF7C78",
      
    }
  ];

  public pieChartColors = [
    {
      backgroundColor: [
        "#7DB3FF",
        "#49457B",
        "#FF7C78",
        "#FED3D0",
        "#6F76D9",
        "#9ADFB4",
        "#2E7987"
      ]
    }
  ];
  public barChartColors = [
    {
      borderColor: "#7DB3FF",
      backgroundColor: "#7DB3FF"
    },
    {
      borderColor: "#49457B",
      backgroundColor: "#49457B"
    },
    {
      borderColor: "#FF7C78",
      backgroundColor: "#FF7C78"
    }
  ];

  public tableTitles = [];
  public tableData = [];
  public tableFoot = [];

  public treeControl = new FlatTreeControl<TreeFlatNode>(node => node.level, node => node.expandable);
  public treeFlattener = new MatTreeFlattener(this.treeTransformer, node => node.level, node => node.expandable, node => node.children);
  public treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  private yAxes = [{}];
  public ready = false;
  public error = false;
  public showChart = false;
  
  private dateFormatter = ({ x }) => moment(x).format("DD MMM YYYY");
  private numberFormatter = x => x.toLocaleString();
  private capitalize = ([first, ...rest]) =>
    // first.toUpperCase() + rest.join("").toLowerCase();
    first.toUpperCase() + rest;
    
  private etiqueting = ({ x }) => x;


  commonSetup(resultSet: ResultSet) {
    if(this.chartType != 'pie'){
      this.chartLabels = resultSet.chartPivot().map(this.dateFormatter);
    }else{
      this.chartLabels = resultSet.chartPivot().map(this.etiqueting);
    }

    this.chartData = [];

    let seriesNames = resultSet.seriesNames();

    if (this.chartType == 'bar' && Boolean(this.measure) && seriesNames.length>0) {
      seriesNames = seriesNames.filter(({ key, title }) => key!==this.measure);
      const linearSerieName = resultSet.seriesNames().find(({ key, title }) => key===this.measure);
      
      const formatter = (item) => {
        let value = item.y || 0;
        const { annotation } = resultSet.loadResponse;

        if (annotation.measures[linearSerieName.key].format === 'percent') {
          value = this.decimalPipe.transform(Math.round(value), '1.')+' %';
        } else if (annotation.measures[linearSerieName.key].format === 'currency') {
          value = '$ '+this.decimalPipe.transform(value, '2.2-2');
        } else if (value>10) {
          value = this.decimalPipe.transform(value, '2.');
        }

        return value;
      };

      const label = linearSerieName.title.split(",")[0];

      this.chartData = [{
        data: resultSet.chartPivot().map(element => ({ x: element.x, y: element[this.measure]})),
        type: 'line',
        fill: false,
        label,
        datalabels: {
          backgroundColor: context => context.dataset.backgroundColor,
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter
        }
      }];
    }
  
    this.chartData = [].concat([], this.chartData, seriesNames.map(({ key, title }) => {
      let dataset = {
        data: resultSet.chartPivot().map(element => element[key]),
        label: this.capitalize(title.split(",")[0]),
        datalabels: {
          formatter: (item) => {
            let value = item || 0;
            const { annotation } = resultSet.loadResponse;

            if (Boolean(annotation.measures[key]) && annotation.measures[key].format === 'percent') {
              value = this.decimalPipe.transform(Math.round(value), '1.')+' %';
            } else if (Boolean(annotation.measures[key]) && annotation.measures[key].format === 'currency') {
              value = '$ '+this.decimalPipe.transform(value, '2.2-2');
            } else if (value>10) {
              value = this.decimalPipe.transform(value, '2.');
            }

            return value;
          }
        }
      };

      if (this.chartType == 'bar' && Boolean(this.measure)) {
        dataset['yAxisID'] = 'generic';
      }

      return dataset;
    }));
  }

  setLineChartData() {
    // this.chartType = "line";
    this.chartColors = this.lineChartColors;
    this.chartOptions = {
      ...this.baseChartOptions,
      scales: {
        xAxes: [
          {
            
            ticks: {
              maxTicksLimit: 52,
              maxRotation: 0
            }
          }
        ],
        yAxes: [{  }]
      },
      legend: {
        position: "bottom"
      },
      borderWith: 1,
      plugins: {
        datalabels: {
          backgroundColor: function(context) {
            return context.dataset.backgroundColor;
          },
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter: (item) => item.y || item,
          padding: 6
        }
      }
    };
    this.chartColors = this.lineChartColors;
  }

  setPieChartData() {
    this.chartColors = this.pieChartColors;
    this.chartOptions = {
      ...this.baseChartOptions,
      plugins: {
        datalabels: {
          anchor: 'center',
          align: 'center',
          color: 'white',
          font: {
            size: 12,
            weight: 'bold'
          }
        }
      }
    };
  }

  setStackedBarChartData() {
    this.chartType = "bar";
    this.chartColors = this.barChartColors;
    this.chartOptions = {
      ...this.baseChartOptions,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              maxTicksLimit: 52,
              maxRotation: 0
            }
          }
        ],
        yAxes: [{ stacked: true }]
      },
      legend: {
        position: "bottom"
      },
      plugins: {
        datalabels: {
          anchor: 'center',
          align: 'center',
          font: {
            size: 12,
          },
          padding: 8
        }
      },
      layout: {
        padding: {
          top: 64
        }
      }
    };
  }

  setBarChartData(){
    this.chartType = "bar";
    this.chartColors = this.barChartColors;

    this.chartOptions = {
      ...this.baseChartOptions,
      scales: {
        xAxes: [
          {
        
            ticks: {
              maxTicksLimit: 52,
              maxRotation: 0
            }
          }
        ],
        yAxes: this.yAxes
      },
      legend: {
        position: "bottom"
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          font: {
            size: 12,
          },
          padding: 8
        }
      },
      layout: {
        padding: {
          top: 64,
        }
      }
    };
  }

  setHorizontalBarChartData() {
    this.chartOptions = {
      ...this.baseChartOptions,
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
  }

  setTableData(resultSet: ResultSet) {
    const { dimensions, measures, timeDimensions } = resultSet.loadResponse.annotation;
    const annotation = Object.assign({}, timeDimensions, dimensions, measures);
    let fields = Object.keys(annotation);

    if (Boolean(this.granularity)) {
      fields = Object.keys(annotation).filter(key => key.indexOf(`.${this.granularity}`) === -1);
    }

    if (Boolean(this.visibility)) {
      const visibility = JSON.parse(this.visibility) || {};

      fields = fields.filter(key => !Boolean(visibility[key]) || visibility[key]!=='hidden');
    }

    const data = resultSet.loadResponse.data.map(item => {
      return fields.map(key => {
        let value = item[key];
        let classes = 'ui center aligned ';

        if (Boolean(measures[key])) {
          value = value || 0;

          if (Boolean(this.trend) && this.trend.indexOf(key) !== -1) {
            if (value>0) {
              classes += 'positive';
            } else if (value<-5) {
              classes += "error";
            } else if (value>-5 && value<=0) {
              classes += 'warning'
            }
          }

          if (measures[key].format === 'percent') {
            value = this.decimalPipe.transform(Math.round(value), '1.')+' %';
          } else if (measures[key].format === 'currency') {
            value = '$ '+this.decimalPipe.transform(value, '2.2-2');
          } else if (value>10) {
            value = this.decimalPipe.transform(value, '2.');
          }
        }

        if (Boolean(timeDimensions[key])) {
          value = this.dateFormatter({ x: value });
        }

        return { value, classes };
      });
    });

    if (this.tableTitles.length===0) {
      this.tableTitles = fields.map(field => annotation[field]).map((item: any) => item.shortTitle);
      this.tableData = data;
    } else {
      this.tableFoot = this.tableFoot.concat(data.map(r => {
        if (this.tableData.length>0) {
          r = r.map(i => ({ ...i, colspan: (this.tableData[0].length - 1) / r.length }))

          if (Boolean(r[0].value) || r[0].value === 0) {
            r.unshift({
              value: fields.map(field => annotation[field]).map((item: any) => item.shortTitle)[0],
              classes: 'ui center aligned'
            });
          }
        }

        return r;
      }));
    }
  }

  setTreeData(resultSet: ResultSet) {
    const { dimensions, measures, timeDimensions } = resultSet.loadResponse.annotation;
    const annotation = Object.assign({}, timeDimensions, dimensions, measures);
    let fields = Object.keys(annotation);

    if (Boolean(this.granularity)) {
      fields = Object.keys(annotation).filter(key => key.indexOf(`.${this.granularity}`) === -1);
    }

    let fieldCumulative = null;

    if (Boolean(this.visibility)) {
      const visibility = JSON.parse(this.visibility) || {};

      fieldCumulative = fields.find(key => Boolean(visibility[key]) && visibility[key]==='cumulative');
      fields = fields.filter(key => !Boolean(visibility[key]) || visibility[key]!=='hidden');
    }

    fields = fields.filter(key => !Boolean(measures[key]));

    const buildRecursiveItems = (items, level, parentName = null): TreeNode[] => {
      if (items.length === 0) {
        return [];
      }

      let itemsAsObject = {};

      const itemsFiltered = items.filter(item => 
        !Boolean(fields[level - 1]) || item[fields[level - 1]] === parentName
      );

      itemsFiltered.forEach(item => {
        const name = item[fields[level]];
        let children: TreeNode[] = null;

        if (Boolean(fields[level + 1])) {
          children = buildRecursiveItems(itemsFiltered, level + 1, name);
        }

        let value = '';

        if (Boolean(fieldCumulative)) {
          if (Boolean(children) && children.length>0) {
            value = String(children.map(c => +c.value).reduce((t, n) => t + n, 0));
          } else {
            value = item[fieldCumulative];
          }
        }

        itemsAsObject[name] = <TreeNode> { name, value, children };
      });

      return <TreeNode[]> Object.values(itemsAsObject).sort((a: TreeNode, b: TreeNode) => 
        +b.value - +a.value
      );
    };
   
    const data = buildRecursiveItems(resultSet.loadResponse.data, 0);

    this.treeDataSource.data = data;
  }

  treeTransformer(node: TreeNode, level: number) {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      value: node.value,
      level: level,
    };
  }

  treeHasChild(_: number, node: TreeFlatNode) {
    return node.expandable
  }

  resultChanged(resultSet) {
    if (this.chartType === "table") {
      this.setTableData(resultSet);

      return;
    }

    if (this.chartType === "tree") {
      this.setTreeData(resultSet);

      return;
    }

    this.commonSetup(resultSet);

    if (this.chartType === "line") {
      this.setLineChartData();
    } else if (this.chartType === "pie") {
      
      this.setPieChartData();
    } else if (this.chartType === "stackedBar") {
      this.setStackedBarChartData();
    } else if (this.chartType === "singleValue") {
      this.chartData = this.numberFormatter(
        resultSet.chartPivot()[0][resultSet.seriesNames()[0].key]
      );
    } else if (this.chartType === "bar") {
      this.setBarChartData();
    } else if (this.chartType === "horizontalBar") {
      this.setHorizontalBarChartData();
    }
  }

  ngOnInit(): void {
    if (!this.isVisible) {
      this.ready = true;
      this.showChart = true;
      return;
    }

    if (this.chartType == 'bar') {
      if (Boolean(this.measure)) {
          this.yAxes = [{
          display: Boolean(this.measure_display),
          stacked: true,
          ticks: {
            beginAtZero: true
          }
        }, {
          id: 'generic',
          display: !Boolean(this.measure_display),
          stacked: false,
          ticks: {
            beginAtZero: true
          }
        }];
      } else {
        this.yAxes = [{
          display: true,
          ticks: {
            beginAtZero: true
          }
        }];
      }
    }

    this.dateFormating(this.granularity);
    this.showChart = this.chartType !== "singleValue" && this.chartType != "table" && this.chartType != "tree";

    if (this.dummy) {
      if (this.chartType === 'tree') {
        this.ready = true;
        return;
      }

      const dummyData = [];
      for (var i = 0; i < 3; i++) {
        const data = [];
        for (var j = 0; j < 5; j++) {
          data.push(Math.floor(Math.random() * 100));
        }

        dummyData.push({ data, label: 'total' });
      }

      this.chartData = dummyData;
      this.chartLabels = ['Etiqueta 1', 'Etiqueta 2', 'Etiqueta 3'];

      if (this.chartType === "line") {
        this.setLineChartData();
      } else if (this.chartType === "pie") {
        this.setPieChartData();
      } else if (this.chartType === "stackedBar") {
        this.setStackedBarChartData();
      } else if (this.chartType === "singleValue") {
        this.chartData = [0];
      } else if (this.chartType === "bar"){
        this.setBarChartData();
      }

      this.ready = true;
      return;
    }

    let queries = JSON.parse(this.query);

    if (!Array.isArray(queries)) {
      queries = [queries];
    }

    this.ready = false;
    this.resultChanged = this.resultChanged.bind(this);
    this.tableTitles = [];
    this.tableData = [];
    this.tableFoot = [];

    const promises = [];

    const cubejs = new CubejsClient(cubejsOptions);

    for (let query of queries) {
      promises.push(new Promise((resolve, reject) => {
        const subject = new Subject();

        cubejs.watch(subject, (err, result) => {
          if (Boolean(err)) {
            reject(err);
          } else {
            resolve(result);
          }
        }).subscribe((response) => {}, err => {});

        subject.next(JSON.stringify(Object.assign({}, query, { timezone: 'America/Mexico_City' })));
      }));
    }

    Promise.all(promises).then((results: any) => {
      for (let result of results.flat()) {
        this.resultChanged(result);
      }

      this.ready = true;
      setTimeout(() => this.onLoadEnd.emit(), 500);
    }).catch(err => {
      console.log(err);
      this.error = true;
    });
  }
  
  ngOnChanges(){
    if (!this.dummy) {
      if(this.ready) {
        this.ngOnInit();
      }
    }
  }

  dateFormating(granularity){
    switch (granularity) {
      case 'hour':
        // 7,13, 18
        
        this.dateFormatter = ({ x }) => x;
        
        // if(this.rango == 'Yesterday' || this.rango == 'Today'){
        //   this.dateFormatter = ({ x }) => moment(x).format("ha");
        // }else{
        //   this.dateFormatter = ({ x }) => moment(x).format("h");
        // }
        
        break;
      case 'month':
        // Jun 20
        this.dateFormatter = ({ x }) => moment(x).format("MMM YY");
        break;
    
      case 'week':
        // 1 / 2020 Semana 1 del 2020
        this.dateFormatter = ({ x }) => moment(x).format("w");
        break;
      case 'day':
        //Sábado Junio 20
        
        this.dateFormatter = ({ x }) => moment(x).format("DD-YY");
        break;
      case 'year':
        //2020
        this.dateFormatter = ({ x }) => moment(x).format("YYYY");
        break;
      default:
        this.dateFormatter = ({ x }) => x;
        break;
    }
    
  }

}
