import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from './snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private queue: any[] = [];
  private isDisplaying = false;

  constructor(private snackBar: MatSnackBar) { }

  private processQueue(): void {
    if (!this.isDisplaying && this.queue.length > 0) {
      const notification = this.queue.shift();
      if (notification) {
        this.isDisplaying = true;
        const config = new MatSnackBarConfig();
        config.duration = notification.duration || 3000;
        const isMobile = window.innerWidth < 768; 
        config.horizontalPosition = isMobile ? 'center' : (notification.position.horizontal || 'center');
        config.verticalPosition = isMobile ? 'top' : (notification.position.vertical || 'bottom');
        config.panelClass = ['snackbar-' + notification.type];

        const snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: {
            message: notification.data,
            type: notification.type
          },
          duration: config.duration,
          horizontalPosition: config.horizontalPosition,
          verticalPosition: config.verticalPosition,
          panelClass: config.panelClass
        });

        snackBarRef.afterDismissed().subscribe(() => {
          this.isDisplaying = false;
          this.processQueue();
        });

        snackBarRef.instance.snackBarRef = snackBarRef;
      }
    }
  }

  newToast(notification: {
    data: string,
    duration?: number,
    position?: { horizontal: 'start' | 'center' | 'end' | 'left' | 'right', vertical: 'top' | 'bottom' },
    type?: 'success' | 'error' | 'alert'
  }): void {
    this.queue.push(notification);
    this.processQueue();
  }
}
