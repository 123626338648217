import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.css']
})
export class DynamicInputComponent implements OnInit, OnChanges {
  @Input() tipoRespuesta: any;
  @Input() respuesta: any;
  @Input() operador: string; 
  @Output() valueChange = new EventEmitter<any>();
  @Input() idControl: string;

  dynamicForm: FormGroup;
  momentFecha;
  public today: string = new Date().toISOString();

  respuestaOptions = [
    { id: 1, nombre: 'Sin respuesta' },
    { id: 2, nombre: 'Con respuesta' }
  ];

  checkOptions = [
    { id: 1, nombre: 'Sí' },
    { id: 2, nombre: 'No' }
  ];

  constructor(private fb: FormBuilder) {
    this.momentFecha = moment;
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['operador'] && !changes['operador'].isFirstChange()) {
      this.updateFormGroup(); // Regenerar o actualizar el formulario cuando el operador cambie
    }
  }

  createFormGroup(): void {
    const responseValidators = this.respuesta.requerida ? [Validators.required] : [];
    if (this.respuesta.idTipoRespuesta === 4) { 
      responseValidators.push(Validators.pattern(/^\d+(\.\d{1,2})?$/));
    }

    // Valor para el primer control
    let valorInicial = this.respuesta.valor || '';
    let valorSegundo = ''; // Valor para el segundo control (si aplica)

    // Si el operador es "between" y los valores son válidos
    if (this.operador === 'between' && typeof this.respuesta.valor === 'object' && this.respuesta.valor !== null) {
      valorInicial = this.respuesta.valor.minimo || ''; 
      valorSegundo = this.respuesta.valor.maximo || ''; 
    }

    if (this.respuesta.idTipoRespuesta === 9 && typeof this.respuesta.valor === 'object' && this.respuesta.valor !== null) { 
      valorInicial = this.respuesta.valor.id || null; 
    }

    this.dynamicForm = this.fb.group({
      [this.idControl]: new FormControl(valorInicial, responseValidators),
      [this.idControl + '-tipoRespuesta']: new FormControl(this.tipoRespuesta || '', [Validators.required])
    });

    if (this.operador === 'between') {
      this.dynamicForm.addControl(
        this.respuesta.idChecklistRespuesta.toString() + '-2',
        new FormControl(valorSegundo, responseValidators)
      );

      // Escuchar cambios en el segundo control si el operador es 'between'
      this.dynamicForm.get(this.idControl + '-2')?.valueChanges.subscribe(() => {
        this.cambiarValor();
      });
    }

    // Escuchar cambios en el control principal
    this.dynamicForm.get(this.idControl)?.valueChanges.subscribe(() => {
      this.cambiarValor();
    });

    // Escuchar cambios en el select de tipo de respuesta
    this.dynamicForm.get(this.idControl + '-tipoRespuesta')?.valueChanges.subscribe(value => {
      this.onTipoRespuestaChange(value);
    });
  }

  updateFormGroup(): void {
    if (this.operador === 'between') {
      this.addBetweenControls();
    } else {
      this.removeBetweenControls();
    }
  }

  addBetweenControls(): void {
    const responseValidators = this.respuesta.requerida ? [Validators.required] : [];
    if (!this.dynamicForm.contains(this.idControl + '-2')) {
      const valorSegundo = typeof this.respuesta.valor === 'object' && this.respuesta.valor !== null
        ? this.respuesta.valor.maximo || ''
        : '';
      this.dynamicForm.addControl(
        this.idControl + '-2',
        new FormControl(valorSegundo, responseValidators)
      );

      // Escuchar cambios en el segundo control si el operador es 'between'
      this.dynamicForm.get(this.idControl + '-2')?.valueChanges.subscribe(() => {
        this.cambiarValor();
      });
    }
  }

  removeBetweenControls(): void {
    if (this.dynamicForm.contains(this.idControl + '-2')) {
      this.dynamicForm.removeControl(this.idControl + '-2');
      this.cambiarValor();
    }
  }

  cambiarValor(): void {
    let value = this.dynamicForm.get(this.idControl)?.value;

    if(this.respuesta.idTipoRespuesta == 5 && value) {
      value = this.momentFecha(value).format('YYYY-MM-DD');
    }

    if (this.respuesta.idTipoRespuesta === 9 && value) {
      const opcion = this.respuesta.options.find(op => op.id === value); 
      value = opcion || null; 
    }

    if (this.operador === 'between') {
      let value2 = this.dynamicForm.get(this.idControl + '-2')?.value;
      
      if(this.respuesta.idTipoRespuesta == 5 && value2) {
        value2 = this.momentFecha(value2).format('YYYY-MM-DD');
      }

      this.valueChange.emit({ 
        id: this.respuesta.idChecklistRespuesta, 
        idTipoRespuesta: this.respuesta.idTipoRespuesta, 
        value: {
          minimo: value,
          maximo: value2
        },
        idTipoValor: this.respuesta.requerida ? 2 : this.dynamicForm.get(this.idControl + '-tipoRespuesta')?.value
      });
    } else {
      this.valueChange.emit({ 
        id: this.respuesta.idChecklistRespuesta, 
        idTipoRespuesta: this.respuesta.idTipoRespuesta, 
        value: value,
        idTipoValor: this.respuesta.requerida ? 2 : this.dynamicForm.get(this.idControl + '-tipoRespuesta')?.value
      });
    }
  }

  onTipoRespuestaChange(value: any): void {
    const respuestaControl = this.dynamicForm.get(this.idControl);
    if (value === 2) { 
      respuestaControl?.setValidators([Validators.required]);
    } else {
      respuestaControl?.clearValidators();
    }
    respuestaControl?.updateValueAndValidity();
  }

  validarSegundoControl(): boolean {
    const control = this.dynamicForm.get(this.idControl + '-2');
    return control?.invalid && (control?.touched || control?.dirty);
  }

  validarControl(): boolean {
    const control = this.dynamicForm.get(this.idControl);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  mostrarControl(): boolean {
    const tipoRespuestaControl = this.dynamicForm.get(this.idControl + '-tipoRespuesta');
    return this.respuesta.requerida || (tipoRespuestaControl && tipoRespuestaControl.value === 2);
  }

  onOperadorChange(newOperador: string): void {
    this.operador = newOperador;
    this.updateFormGroup(); 
  }

  limpiarRespuesta(controlName: string): void {
    this.dynamicForm.get(controlName)?.setValue(null);
  }
  
}
