
import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { ServicesGestoresService } from '@servicesGestores/services-gestores.service';


@Component({
  selector: 'limite-cuentas.component',
  templateUrl: 'limite-cuentas.component.html',
  styleUrls: ["limite-cuentas.component.css"],
  providers: [
    ServicesGestoresService
  ]
})
export class ModalLimiteCuentasComponent {
  limiteDeCuentasApartadas: number;
  constructor(
    private serviciosGestores: ServicesGestoresService,
    public dialogRef: MatDialogRef<ModalLimiteCuentasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
  ){
    this.limiteDeCuentasApartadas = this.data.limiteDeCuentasApartadas;
  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {
    
    }

    public guardarLimiteCuentas(){
      let loading: number = this.app.showLoading('Guardando información.');
      this.serviciosGestores.saveLimiteCuentasApartadas({
          idAgente: this.data.idAgente,
          valor: this.limiteDeCuentasApartadas
      }).subscribe(
          res => {
              this.app.showSnackbar('¡Aviso!', `Se ha actualizado el limite de cuentas a ${this.limiteDeCuentasApartadas}.`, 3000, 'success');
              this.app.hideLoading(loading);
          },
          error => {
              this.app.showError(error);
              this.app.hideLoading(loading);
          }
      );
  }

  public onInputLimite(): void{
    if(this.limiteDeCuentasApartadas > 999){
        setTimeout(()=>{
            this.limiteDeCuentasApartadas = 999;
        }, 10);
    }
    else if(this.limiteDeCuentasApartadas == null || this.limiteDeCuentasApartadas < 1 || !this.limiteDeCuentasApartadas){
        setTimeout(()=>{
            this.limiteDeCuentasApartadas = 1;
        }, 10);
    }
}

}
