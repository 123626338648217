<h1 mat-dialog-title>Cuentas vencidas</h1>
<mat-dialog-content style="overflow: hidden;">
    <div class="contenidoConScroll" style="max-height: 500px;">
        <app-tabla [loading]="false" [columns]="tablaAcreditados.columnas" [items]="acreditados"
            [headers]="tablaAcreditados.headers" [tableHeaders]="tablaAcreditados.tableHeaders" [ignoreSortChange]="true"
            [multiSelect]="tablaAcreditados.multiSelect">
        </app-tabla>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon>close</mat-icon>Cancelar</button>
    <button mat-stroked-button (click)="descargarExcel()"><mat-icon>description</mat-icon>Exportar</button>
</mat-dialog-actions>