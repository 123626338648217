<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start"
    [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()">
        <mat-icon>cancel</mat-icon>
      </button>
      Filtros de listado
    </mat-toolbar>
    <ol class="lista-filtros-side">
      <li class="filtro-side">
        <mat-form-field style="width: 100%;">
          <mat-label>Nombre campo</mat-label>
          <input matInput textSanitizer placeholder="Buscar por nombre" [(ngModel)]="filtros.search">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </li>
      <li class="filtro-side">
        <mat-form-field class="tipo-campo">
          <mat-label>Filtro de campo</mat-label>
          <mat-select [(ngModel)]="filtros.filtro">
            <mat-option *ngFor="let filter of filters" [value]="filter.value">
              {{filter.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ol>

    <ol class="lista-filtros-buttons">
      <button class="boton-accion-front" style="width: 100%;" mat-stroked-button class="boton-accion-front"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; applyFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
      </button>
      <button style="width: 100%; margin-top: 10px;" mat-stroked-button
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; clearFilter();">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
      </button>
    </ol>
  </mat-sidenav>

  <mat-card>
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtros" mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="nuevoCampoSwitch();">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Agregar campo</span>
      </button>
    </mat-menu>
    <app-loader *ngIf="loading" [message]="'Cargando Layout'"></app-loader>

    <ng-container *ngIf="!loading">
      <form [formGroup]="formGroup" class="form-sticky-oculto">
        <table mat-table *ngIf="dataSourceFiltered?.length" [dataSource]="dataSourceFiltered" class="tabla-fields"
          formArrayName="fields">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre *</th>
            <td mat-cell class="td-padding" *matCellDef="let element" [formGroupName]="element.index">
              <div style="margin-top:20px; margin-left: 10px;">
                {{ element.name }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef> Clave *</th>
            <td mat-cell class="td-padding" *matCellDef="let element" [formGroupName]="element.index">
              <div style="margin-top:20px; margin-left: 10px;">
                {{ element.key }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="idFieldType">
            <th mat-header-cell *matHeaderCellDef> Tipo de dato *</th>
            <td mat-cell class="td-padding" *matCellDef="let element" [formGroupName]="element.index">
              <div style="margin-top:20px; margin-left: 10px;">
                <ng-container *ngIf="element.customized; else showText">
                  <app-field type="choice" [items]="itemsFieldType" formControlName="idFieldType"></app-field>
                </ng-container>
                <ng-template #showText>
                  {{ getFieldTypeName(element.idFieldType) }}
                </ng-template>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="required">
            <th mat-header-cell *matHeaderCellDef> Requerido </th>
            <td mat-cell class="td-padding" *matCellDef="let element" [formGroupName]="element.index">
              <app-field type="boolean" formControlName="required"></app-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="showOnApp">
            <th mat-header-cell *matHeaderCellDef> Mostrar en APP </th>
            <td mat-cell class="td-padding" *matCellDef="let element" [formGroupName]="element.index">
              <app-field type="boolean" formControlName="showOnApp"></app-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class="td-padding" *matCellDef="let element; index as i" [formGroupName]="element.index">
              <button
                *ngIf="!isFieldDisabledRequired(element.key)"
                mat-icon-button
                color="warn"
                class="delete-icon"
                matTooltip="Borrar"
                mat-tooltip-panel-above
                (click)="deleteField(i)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </form>
    </ng-container>

    <div class="botones-card-derecha">
      <button class="boton-cancelar" mat-flat-button (click)="onClose()">
        <mat-icon class="kobra-icon">close</mat-icon>
        Cancelar
      </button>
      <button *ngIf="formGroup" mat-stroked-button class="boton-guardar" [disabled]="!canSave" (click)="save()">
        <mat-icon>save</mat-icon>
        Guardar
      </button>
    </div>
  </mat-card>
</div>
