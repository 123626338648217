import { Injectable } from '@angular/core';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { onboardingDistribuidor } from "./onboarding-distribuidor.model";

@Injectable({
  providedIn: 'root'
})
export class OnboardingDistribuidorService {

  constructor(
    private leanServ: ServiciosKobraService
  ) { }


  public all( agentID: number): Observable<onboardingDistribuidor> {
    return this.leanServ.get(`/cobradores/${agentID}/onboarding-distribuidor`).pipe(
      map(res => <onboardingDistribuidor> res.data)
      );
      
    
  }

  public update (agentID,estatus, paso){
    let data = {"estatus": estatus, "paso": paso};
    return this.leanServ.patch(`/cobradores/${agentID}/onboarding-distribuidor`, data).pipe(
      map((res) => {
          return res;  
        }
        
      )
    )
  }

  
}
