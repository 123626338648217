<div class="app content tabla kobra">
  <form [formGroup]="paquetesConceptosForm">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{this.idPaquete !== 0 && !(this.idPaquete > 0) ? 'Nuevo' : 'Editar'}} Paquete</mat-card-title>
      </mat-card-header>
      <mat-card-content class="contenido"> 
        <app-loader *ngIf="cargarPaquete" [message]="'Cargando paquete...'"></app-loader>
        <div class="row" *ngIf="!cargarPaquete">
          <div class="column">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Nombre</mat-label>
              <input matInput textSanitizer  placeholder="Escribir nombre del paquete"  formControlName="nombre">
              <mat-error *ngIf="checkControl('nombre')">
                {{getErrorMessage('nombre')}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field floatLabel="always" style="width: 100%;">
              <mat-label>Costo del paquete</mat-label>
              <input matInput textSanitizer  type="number" placeholder="0" formControlName="precioPaquete">
              <span matTextPrefix>$&nbsp;</span>
              <mat-error *ngIf="checkControl('precioPaquete')">
                {{getErrorMessage('precioPaquete')}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
          </div>
        </div>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Conceptos del paquete
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-loader *ngIf="cargando" [message]="loaderText"></app-loader>
            <div class="row" *ngIf="!cargando">
              <div class="column">
                <mat-form-field style="width: 100%;">
                  <mat-label>Concepto</mat-label>
                  <mat-select formControlName="idConcepto">
                    <mat-option *ngFor="let concepto of conceptos" [value]="concepto.idConcepto">
                      {{concepto.nombreConcepto}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field floatLabel="always" style="width: 100%;">
                  <mat-label>Precio</mat-label>
                  <input matInput textSanitizer  type="number" placeholder="0" formControlName="precioConcepto">
                  <span matTextPrefix>$&nbsp;</span>
                </mat-form-field>
              </div>
              <div class="column">
                <button mat-stroked-button (click)="agregarConcepto()" class="boton-accion-front">
                  <mat-icon >add</mat-icon>Agregar
                </button>
              </div>
            </div>
            <div style="margin-bottom:-90px">
              <h2>Conceptos agregados</h2>
            </div>
  
          <app-table
            [classContainer]="'tabla-completa'"
            [loading]="cargandoLista"
            [columns]="tabla.columns"
            [items]="conceptosAgregados"
            [headers]="tabla.headers"
            [multiSelect]="tabla.multiSelect"
            (onLinkRow)="onClick($event)"
          >
          </app-table>
          </mat-card-content>
        </mat-card>
        
      </mat-card-content>
      <mat-card-actions align="end" style="gap:10px">
        <button class="boton-cancelar" mat-flat-button routerLink="../" queryParamsHandling="preserve"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
        <button mat-stroked-button 
        class="boton-guardar" 
        [disabled]="paquetesConceptosForm.invalid || paquetesConceptosForm.get('items').invalid"
        (click)="onSubmit()"
        >
        <mat-icon style="color:white !important">save</mat-icon>
        Guardar
      </button>
      </mat-card-actions>
    </mat-card>
  </form>
  
</div>
