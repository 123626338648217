import { AbstractControl } from "@angular/forms";
import { Observable, BehaviorSubject } from "rxjs";


export class FormValidation {
  public formControl: AbstractControl;
  public isInvalid: Observable<boolean>;
  public isInvalidSubject: BehaviorSubject<boolean>
  public message: string;
  public invalidMessage: any;
  public touched: boolean;

  constructor(formControl: AbstractControl = null) {
    this.formControl = formControl;
    this.isInvalidSubject = new BehaviorSubject(false);
    this.isInvalid = this.isInvalidSubject.asObservable();
  }

  public start( updateControls: AbstractControl[] = [] ): void {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe(value => {
        this.message = '';
        if (this.formControl.dirty && !this.formControl.valid) {
          for (var key in this.formControl.errors) {
            this.message += this.invalidMessage[key] + ' ';
          }
        }
        for (let i = 0; i < updateControls.length; i++) {
          updateControls[i].updateValueAndValidity();
        }
        this.triggerIsInvalid();

        // this.invalid.next(this.message && this.formControl.touched);
      });
    }
  }

  public setTouched(value: boolean): void {
    this.touched = value;
    this.triggerIsInvalid();
  }

  private triggerIsInvalid(): void {
    this.isInvalidSubject.next(this.message && this.touched);
  }
}
