<router-outlet (activate)="activarHijo()" (deactivate)="desactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            [disabled]="!listFiltered"
            (click)="loadVisits(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0"
        (click)="onCalificar()"
        >
        <mat-icon class="kobra-icon">star</mat-icon>
        <span>Calificar</span>
      </button>
      <button mat-menu-item 
        [disabled]="itemsSelected.length < 2"
        (click)="onCalificarMasivas()"
        >
        <mat-icon class="kobra-icon">stars_6</mat-icon>
        <span>Calificar Masivas</span>
      </button>
    </mat-menu>

    <app-grid 
      [loading]="loadingVisitas"
      [columns]="tablaVisitas.columns"
      [items]="visits"
      [headers]="tablaVisitas.headers"
      [multiSelect]="tablaVisitas.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (onLinkRow)="onClick($event)">
      <!-- (sortChange)="setSortField($event)"
      (onCtrlClicLinkRow)="onCtrlClick($event)" -->

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
       
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Calificar Obligatorias" es una herramienta para evaluar las visitas realizadas por los agentes dentro de fechas específicas y obligatorias.
            Los usuarios pueden seleccionar un rango de fechas para identificar las visitas que deben ser calificadas de manera obligatoria. Basados en el rango de fechas las visitas obligatorias será el número de visita por agente: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 21, 38, 49, 61 y 62.
            
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las visitas realizadas.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <!-- <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de
            las visitas para su análisis o uso fuera de línea.
          </p> -->
          <p>
            <mat-icon class="kobra-icon">star</mat-icon><b> Calificar: </b> Te permite revisar cada visita y darle una calificación del 1 al 5.
          </p>
          <p>
            <mat-icon class="kobra-icon">stars_6</mat-icon><b> Calificar masiva: </b> Con esta acción podrás dar una calificación masivamente a todas las visitas que selecciones.
          </p>
        </mat-card-content>
      </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="visitPaginator.pagination?.totalCount"
          [pageIndex]="visitPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-grid>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="contabilizadorFiltro('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorFiltro('superAdmin') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="idFinanciera"
              (ngModelChange)="onChangeLender($event); contabilizadorFiltro('superAdmin');"
            >
            </mat-select-search>
            
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorFiltro('generales') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('generales') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Folio Visita</mat-label>
              <input matInput textSanitizer 
              [(ngModel)]="filtros.VisitID"
              (ngModelChange)="this.contabilizadorFiltro('generales');"
              placeholder="Capture el folio de la visita">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.VisitID" 
              (click)="filtros.VisitID = null; this.contabilizadorFiltro('generales');">clear</mat-icon>
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Promesa de Pago</mat-label>
              <mat-select
                [(ngModel)]="filtros.PaymentPromise"
                (ngModelChange)="this.contabilizadorFiltro('generales');"
                placeholder="Seleccione..."
              >
                <mat-option [value]="1">Sí</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.PaymentPromise != null" 
                  (click)="filtros.PaymentPromise = null;$event.stopPropagation();this.contabilizadorFiltro('generales');">clear</mat-icon> 
              <mat-icon matSuffix>azm</mat-icon>
            </mat-form-field>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Cuenta
          <span *ngIf="contabilizadorFiltro('cuenta') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('cuenta') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cuenta'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="acreditadoFilterSearch"
              [(ngModel)]="filtros.acreditado"
              (ngModelChange)="this.contabilizadorFiltro('cuenta');"
            >
            </mat-select-search>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Ubicación
          <span *ngIf="contabilizadorFiltro('ubicacion') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('ubicacion') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>{{labelEstado}}</mat-label>
              <input matInput textSanitizer 
              [(ngModel)]="filtros.Estado"
              (ngModelChange)="this.contabilizadorFiltro('ubicacion');"
              placeholder="Capture el {{labelEstado}}">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.Estado" 
              (click)="filtros.Estado = null;this.contabilizadorFiltro('ubicacion');">clear</mat-icon>
              <mat-icon matSuffix>location_city</mat-icon>
            </mat-form-field>
          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>{{labelMunicipio}}</mat-label>
              <input matInput textSanitizer 
              [(ngModel)]="filtros.Municipio"
              (ngModelChange)="this.contabilizadorFiltro('ubicacion');"
              placeholder="Capture el {{labelMunicipio}}">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.Municipio" 
              (click)="filtros.Municipio = null;this.contabilizadorFiltro('ubicacion');">clear</mat-icon>
              <mat-icon matSuffix>location_city</mat-icon>
            </mat-form-field>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Fechas
          <span *ngIf="contabilizadorFiltro('fechas') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('fechas') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-accordion [multi]="true">
              <mat-expansion-panel  [expanded]="true">
                <mat-expansion-panel-header>
                  Fechas visita          
                  <span *ngIf="contabilizadorFiltro('fechas') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('fechas') }}</span>
                </mat-expansion-panel-header>
                <ol class="lista-filtros-side">
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha inicio'"
                      [placeHolder]="'Capture la fecha inicio'"
                      [max]="filtros.VisitEndDate"
                      [(ngModel)]="filtros.VisitStartDate"
                      (ngModelChange)="this.contabilizadorFiltro('fechas');"
                    />
                   
                  </li>
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha fin'"
                      [placeHolder]="'Capture la fecha fin'"
                      [min]="filtros.VisitStartDate"
                      [max]="today"
                      [(ngModel)]="filtros.VisitEndDate"
                      (ngModelChange)="this.contabilizadorFiltro('fechas');"
                    />
                   
                  </li>
                </ol>
              </mat-expansion-panel>
            </mat-accordion>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
          <span *ngIf="contabilizadorFiltro('agente') >= 1" class="notification-dot-filtros">{{ contabilizadorFiltro('agente') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Agente'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="agenteFilterSearch"
              [(ngModel)]="filtros.agente"
              (ngModelChange)="this.contabilizadorFiltro('agente');"
              [minBusqueda]="1"
            >
            </mat-select-search>
            
          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>