<h1 mat-dialog-title>Domicilios</h1>
<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <mat-label>Domicilio</mat-label>
    <mat-select [(ngModel)]="idDomicilio">
      <mat-option *ngFor="let domicilio of data.domicilios" [value]="domicilio.idAcreditadoCuentaDomicilio" >
        {{domicilio.selectDomicilio}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button  [ngClass]="{'boton-guardar': idDomicilio}" [disabled]="!idDomicilio" (click)="regresarDomicilio()"><mat-icon>cloud_download</mat-icon>Descargar</button>
</mat-dialog-actions>