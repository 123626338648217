<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros de listado
    </mat-toolbar>
    <ol class="lista-filtros-side" *ngIf="!filtros.mostrarMapa">
      <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="lenders"
          [(ngModel)]="idLender"
          (ngModelChange)="onChangeLender($event);"
          [ngModelOptions]="{standalone: true}"
        >
        </mat-select-search>
       
      </li>
      <li class="filtro-side">
        <mat-select-search
            class="id-card-field"
            [label]="'Cuenta'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="acreditadoFilterSearch"
            [(ngModel)]="filtroAcreditado"
            (ngModelChange)="onAcreditadoFilterSelectedModal($event)"
            >
        </mat-select-search>
      </li>
      <li class="filtro-side">
        <mat-select-search
            class="id-card-field"
            [label]="'Agente'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'idAgente'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="agenteFilterSearch"
            [(ngModel)]="filtrosModal.idAgente"
            [minBusqueda]="1"
        >
        </mat-select-search>
      </li>
    </ol>
    <div class="accordion-filtros" *ngIf="filtros.mostrarMapa">
        <mat-accordion [multi]="false">
          <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
            <mat-expansion-panel-header>
              Súper Administrador
              <span *ngIf="contabilizadorDeFiltrosMapa('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('superAdmin') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cliente'"
                  [placeHolder]="'Selecciona un cliente'"
                  [value]="'idFinanciera'"
                  [labelField]="'name'"
                  [icon]="'account_balance'"
                  [options]="lenders"
                  [(ngModel)]="idLender"
                  (ngModelChange)="onChangeLender($event);"
                >
                </mat-select-search>
                
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Excluir Demo</mat-label>
                  <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
            <mat-expansion-panel-header>
              Generales
              <span *ngIf="contabilizadorDeFiltrosMapa('generalesMapa') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('generalesMapa') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>ID Cuenta</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.idExterno"
                  placeholder="Capture el id de la cuenta">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.idExterno" 
                  (click)="filtros.idExterno = null">clear</mat-icon>
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.idExterno" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.idExterno = null"><i>Limpiar</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadingProducts ? 'Cargando productos...' : 'Producto'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.tipoCuenta"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let product of products; index as ti">
                      <mat-option [value]="product.idProducto">{{product.nombre || product.nomProducto}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.tipoCuenta != null" 
                  (click)="filtros.tipoCuenta = null;$event.stopPropagation();">clear</mat-icon> 
                  <mat-icon matSuffix>conveyor_belt</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.tipoCuenta" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.tipoCuenta = null"><i>Limpiar selección</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadings.sucursales ? 'Cargando sucursales...' : 'Sucursal'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.sucursal"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let sucursal of sucursales; index as ti">
                      <mat-option [value]="sucursal.idSucursal">{{sucursal.nombreSucursal}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.sucursal != null" 
                  (click)="filtros.sucursal = null;$event.stopPropagation();">clear</mat-icon> 
                  <mat-icon matSuffix>apartment</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.sucursal" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.sucursal = null"><i>Limpiar selección</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Etiqueta Kobra</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.etiquetaKobra"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let tag of tags; index as ti">
                      <mat-option [value]="tag.id">{{tag.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.etiquetaKobra != null" 
                  (click)="filtros.etiquetaKobra = null;$event.stopPropagation();">clear</mat-icon> 
                  <mat-icon matSuffix>tag</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.etiquetaKobra" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.etiquetaKobra = null"><i>Limpiar selección</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{loadingEtiquetasCuenta ? 'Cargando etiquetas...' : 'Etiqueta Cuenta'}}</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.idEtiquetaCuenta"
                    placeholder="Seleccione..."
                  >
                    <ng-container *ngFor="let etiqueta of etiquetasCuenta; index as ti">
                      <mat-option [value]="etiqueta.idEtiquetaCuenta">{{etiqueta.nombre}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.idEtiquetaCuenta != null" 
                  (click)="filtros.idEtiquetaCuenta = null;$event.stopPropagation();">clear</mat-icon> 
                  <mat-icon matSuffix>tag</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.idEtiquetaCuenta" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.idEtiquetaCuenta = null"><i>Limpiar selección</i></a>
                </div> -->
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Cuenta
              <span *ngIf="contabilizadorDeFiltrosMapa('cuenta') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('cuenta') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cuenta'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="acreditadoFilterSearch"
                  [(ngModel)]="filtroAcreditado"
                  (ngModelChange)="onAcreditadoFilterSelected($event)"
                  >
                </mat-select-search>
               
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Días Moratorios</mat-label>
                  <input
                    matInput textSanitizer 
                    type="number"
                    [(ngModel)]="filtros.diasMoratorios"
                    placeholder="Días Moratorioss"
                    maxlength="4"
                    pattern="[0-9]"
                    max="9999"
                    min="1"
                    (keypress)="validarMoratorios($event)"
                  />
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.diasMoratorios" 
                  (click)="filtros.diasMoratorios = null">clear</mat-icon>
                  <mat-icon matSuffix>date_range</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.diasMoratorios" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.diasMoratorios = null"><i>Limpiar</i></a>
                </div> -->
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Grupo
              <span *ngIf="contabilizadorDeFiltrosMapa('grupo') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('grupo') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cuenta'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'id'"
                  [labelField]="'nombre'"
                  [icon]="'groups'"
                  [realizarBusqueda]="grupoFilterSearch"
                  [(ngModel)]="filtros.idGrupo"
                >
                </mat-select-search>
              </li>
             
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Ubicación
              <span *ngIf="contabilizadorDeFiltrosMapa('ubicacion') >= 1 && pais != 'MX' "class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('ubicacion') }}</span>
              <span *ngIf="contabilizadorDeFiltrosMapa('ubicacionMX') >= 1 && pais == 'MX'"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('ubicacionMX') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side" *ngIf="pais != 'MX'">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelEstado}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.estado"
                  placeholder="Capture el {{labelEstado}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.estado" 
                  (click)="filtros.estado = null">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.estado" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.estado = null"><i>Limpiar</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelMunicipio}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.municipio"
                  placeholder="Capture el {{labelMunicipio}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.municipio" 
                  (click)="filtros.municipio = null">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.municipio" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.municipio = null"><i>Limpiar</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelColonia}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.colonia"
                  placeholder="Capture la {{labelColonia}}">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.colonia" 
                  (click)="filtros.colonia = null">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.colonia" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.colonia = null"><i>Limpiar</i></a>
                </div> -->
              </li>
            </ol>
            <ol class="lista-filtros-side" *ngIf="pais == 'MX'">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="labelEstado"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idEstado'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="estados"
                  [(ngModel)]="filtros.idEstado"
                  (ngModelChange)="onChangeEstate($event);"
                >
                </mat-select-search>
               
              </li>
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="this.loadings.municipios ? 'Cargando municipios...' : labelMunicipio"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idMunicipio'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="municipios"
                  [(ngModel)]="filtros.idMunicipio"
                >
                </mat-select-search>
                
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelCodigoPostal}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.codigoPostal"
                  placeholder="Capture el código postal">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.codigoPostal" 
                  (click)="filtros.codigoPostal = null">clear</mat-icon>
                  <mat-icon matSuffix>package</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.codigoPostal" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.codigoPostal = null"><i>Limpiar</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>{{labelColonia}}</mat-label>
                  <input matInput textSanitizer 
                  [(ngModel)]="filtros.colonia"
                  placeholder="Capture la colonia">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.colonia" 
                  (click)="filtros.colonia = null">clear</mat-icon>
                  <mat-icon matSuffix>location_city</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.colonia" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.colonia = null"><i>Limpiar</i></a>
                </div> -->
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Fechas
              <span *ngIf="contabilizadorDeFiltrosMapa('fechasTotal') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('fechasTotal') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-accordion [multi]="false">
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas creación
                      <span *ngIf="contabilizadorDeFiltrosMapa('fechasC') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('fechasC') }}</span>

                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaCreacionFin ? filtros.fechaCreacionFin : today"
                          [(ngModel)]="filtros.fechaCreacionInicio"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaCreacionInicio" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaCreacionInicio = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaCreacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaCreacionFin"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaCreacionFin" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaCreacionFin = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas asignación
                      <span *ngIf="contabilizadorDeFiltrosMapa('fechasA') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('fechasA') }}</span>

                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaAsignacionFin ? filtros.fechaAsignacionFin : today"
                          [(ngModel)]="filtros.fechaAsignacionInicio"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaAsignacionInicio" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaAsignacionInicio = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaAsignacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaAsignacionFin"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaAsignacionFin" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaAsignacionFin = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas importación
                      <span *ngIf="contabilizadorDeFiltrosMapa('fechasI') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('fechasI') }}</span>

                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaImportacionFin ? filtros.fechaImportacionFin : today"
                          [(ngModel)]="filtros.fechaImportacionInicio"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaImportacionInicio" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaImportacionInicio = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaImportacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaImportacionFin"
                        />
                        <!-- <div align="left" *ngIf="filtros.fechaImportacionFin" class="limpiar-seleccion-kobra">
                          <a (click)="filtros.fechaImportacionFin = null"><i>Limpiar selección</i></a>
                        </div> -->
                      </li>
                    </ol>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Agente
              <span *ngIf="contabilizadorDeFiltrosMapa('agente') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltrosMapa('agente') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Agente'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="agenteFilterSearch"
                  [(ngModel)]="filtros.agente"
                  [minBusqueda]="1"
                >
                </mat-select-search>
                
              </li>
             
            </ol>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    <ol class="lista-filtros-buttons">
      <button
        style="width: 100%;"
        mat-stroked-button
        class="boton-accion-front"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; !filtros.mostrarMapa ? cargarCuentas(1) : consultarCuentasMapa(0, 1)">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
      <button
        style="width: 100%; margin-top: 10px;"
        mat-stroked-button
        (click)="clearFilter()">
          <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
          Limpiar Filtros
      </button>
    </ol>
</mat-sidenav>



<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros" 
                (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
                <span *ngIf="totalMapaFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalMapaFiltros }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="actualizar"
              (click)="!filtros.mostrarMapa ? cargarCuentas(1) : consultarCuentasMapa(0,1);"
              matTooltip="Refrescar"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="mapa"
              *ngIf="!filtros.mostrarMapa"
              (click)="mostrarMapa()"
              matTooltip="Mostrar Mapa"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">map</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="mapa"
              *ngIf="filtros.mostrarMapa"
              (click)="ocultarMapa()"
              matTooltip="Ocultar Mapa"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">map</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu"
              matTooltip="Acciones"
              mat-tooltip-panel-above
              [matMenuTriggerFor]="menu">
              <mat-icon class="kobra-icon">more_vert</mat-icon>
            </mat-button-toggle>
            <!--mat-button-toggle value="ayuda" 
              matTooltip="Ayuda"
              mat-tooltip-panel-above
              (click)="ayuda = !ayuda;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle-->
          </mat-button-toggle-group>
        </div>
    </div>
  
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="asignarCuentasPorRango()" queryParamsHandling="preserve" *ngIf="filtros.mostrarMapa">
            <mat-icon class="kobra-icon">edit</mat-icon>
            <span>Seleccionar rango de cuentas</span>
        </button>
        <button mat-menu-item (click)="visualizarCuentasDialog()" queryParamsHandling="preserve" *ngIf="filtros.mostrarMapa">
          <mat-icon class="kobra-icon">remove_red_eye</mat-icon>
          <span>Visibilidad</span>
        </button>
        <button mat-menu-item (click)="borrarSeleccion()" queryParamsHandling="preserve" *ngIf="filtros.mostrarMapa">
            <mat-icon [color]="'warn'">do_not_disturb_on</mat-icon>
          <span>Borrar selección</span>
        </button>
        <button mat-menu-item queryParamsHandling="preserve" [disabled]="!bloquearEliminar" *ngIf="!filtros.mostrarMapa" (click)="openDialogConfirmacion()">
            <mat-icon [color]="'warn'">delete</mat-icon>
          <span>Eliminar</span>
        </button>
    </mat-menu>
    
    <div style="margin-top: 5px" *ngIf="filtros.mostrarMapa">
        <app-loader class="loader-mapa" *ngIf="loadCuentasMapa" [message]="'Cargando cuentas...'"></app-loader>
        <div style="height: calc(100vh - 125px)">
            <mapbox-cuentas [cuentas]="accounts" (outputEmiter)="outputEmiter($event)" #mapaCuentas></mapbox-cuentas>
        </div>
    </div>
    <app-table [columns]="tablaCuentas.columnas" *ngIf="!filtros.mostrarMapa"
        [loading]="cargandoCuentas"
        [items]="cuentas" [headers]="tablaCuentas.headers"
        [tableHeaders]="tablaCuentas.tableHeaders" [ignoreSortChange]="true"
        [multiSelect]="tablaCuentas.multiSelect" (onLinkRow)="onClick($event)"
        (onCtrlClicLinkRow)="onCtrlClick($event)"
        (onSelectedRows)="onSelected($event)">
        <ng-container t-paginator>
            <mat-paginator 
              showFirstLastButtons
              [length]="accountPaginator.pagination?.totalCount"
              [pageIndex]="accountPaginator.pagination?.currentPage-1"
              [pageSize]="accountPaginator.pagination?.perPage"
              (page)="onPage($event)"
              aria-label="Select page of periodic elements">
            </mat-paginator>
          </ng-container>
    </app-table>
</div>