<app-loading-bar></app-loading-bar>
<app-loading [message]="mensajeCargando" *ngIf="mostrarCargando"></app-loading>

<mat-sidenav-container class="content-gris">
  <mat-sidenav #sidenav fixedInViewport autosize class="sidenav"
    [ngClass]="{'superadmin': sessionData.isSuperAdmin, 'admin': !sessionData.isSuperAdmin}" mode="side"
    [opened]="true">
    <app-loader [message]="'Cargando menú'" *ngIf="loadingMenu"></app-loader>
    <span style="margin-left: 50px;"><!--a class="home" (click)="sidenavRight.close()" routerLink="/dash"-->
      <a class="home" routerLink="/">
        <img [src]="logo" class="img-toolbar" (error)="manejarErrorImagen()" *ngIf="!imagenRota" />
        <div *ngIf="imagenRota">
          <img [src]="logoDefault" class="img-toolbar" />
        </div>
      </a>
    </span>
    <mat-nav-list class="nav-kobra" #menuList *ngIf="!loadingMenu">
      <mat-accordion [multi]="true">
        <ng-container *ngFor="let item of menuItems">
          <div *ngIf="!item.banExpandible && item.banVisible">
            <a mat-list-item [routerLink]="item.txtUrl" [queryParams]="item.txtQueryParams" class="item-menu-padre"
              routerLinkActive="active-link">
              <mat-icon class="icono-menu"
                [ngClass]="{'superadmin': sessionData.isSuperAdmin, 'admin': !sessionData.isSuperAdmin}">{{item.txtIcono}}</mat-icon>
              <span class="item-padre" [ngClass]="{'superadmin': sessionData.isSuperAdmin}">{{item.txtNomMenu}}</span>
            </a>
          </div>
          <mat-expansion-panel [expanded]="item.banExpandir" *ngIf="item.banExpandible" class="sin-border"
            [ngClass]="{'superadmin': sessionData.isSuperAdmin, 'admin': !sessionData.isSuperAdmin}">
            <mat-expansion-panel-header style="font-size: larger;"
              [ngClass]="{'indicador-blanco': sessionData.isSuperAdmin}">
              {{item.txtNomMenu}}
            </mat-expansion-panel-header>
            <div *ngIf="item.menuHijos.length>0" style="margin-left: -20px;">
              <div>
                <mat-nav-list class="nav-kobra-item">
                  <ng-container *ngFor="let itemHijo of item.menuHijos; index as i">
                    <a mat-list-item class="item-menu" *ngIf="itemHijo.banVisible" [routerLink]="itemHijo.txtUrl"
                      routerLinkActive="active-link">
                      <mat-icon class="icono-menu"
                        [ngClass]="{'superadmin': sessionData.isSuperAdmin, 'admin': !sessionData.isSuperAdmin}">{{itemHijo.txtIcono}}</mat-icon>
                      <span class="item-wrap"
                        [ngClass]="{'superadmin': sessionData.isSuperAdmin }">{{itemHijo.txtNomMenu}}</span>
                    </a>
                  </ng-container>
                </mat-nav-list>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
      <!--ng-container *ngFor="let item of menuItems">
            <div *ngIf="(!item.menu || item.menu.length == 0) && item.visible">
              <a mat-list-item [routerLink]="item.url" [queryParams]="item.queryParams" routerLinkActive="active-link" >
                <mat-icon>{{item.icono}}</mat-icon>
                <span class="item-padre">{{item.nombre}}</span>
              </a>
            </div>

            <div *ngIf="item.menu.length>0">
              <div>
                <a mat-list-item><b>{{item.nombre}}</b></a>
                <div>
                  <mat-nav-list class="nav-kobra-item">
                    <ng-container *ngFor="let itemHijo of item.menu; index as i">
                      <a mat-list-item class="item-menu" *ngIf="itemHijo.visible" [routerLink]="itemHijo.url" routerLinkActive="active-link">
                        <mat-icon>{{itemHijo.icono}}</mat-icon>
                        <span class="item-wrap">{{itemHijo.nombre}}</span>
                      </a>
                    </ng-container>
                  </mat-nav-list>
                </div>
              </div>
            </div>
          </ng-container-->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="header toolbar" #toolbar>
      <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle(); sideAbierto=!sideAbierto"
          style="color: black; margin-left: -15px">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
            class="eva eva-menu-2-outline" fill="currentColor">
            <g data-name="Layer 2">
              <g data-name="menu-2">
                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                <circle cx="4" cy="12" r="1"></circle>
                <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect>
                <rect x="3" y="6" width="18" height="2" rx=".94" ry=".94"></rect>
              </g>
            </g>
          </svg>
        </button>

        <breadcrumb [tamanioGrande]="tamanioGrande">
        </breadcrumb>
        <span class="flex-spacer"></span>


        <div *ngIf="tamanioGrande" class="toolbar-right">
          <a class="ui dropdown item" style="margin-right:10px; color:black;" *ngIf="isSuperAdmin"
            [matMenuTriggerFor]="menuPaises">
            <img [src]="paisActual.img" style="height: 15px;" />
            <span class="nombre" style="font-size: 15px;"> {{paisActual.nombre}}</span>
            <mat-icon class="header-arrow-down">keyboard_arrow_down</mat-icon>
          </a>

          <a class="ui dropdown item" style="margin-right:10px; color:black;" *ngIf="!isSuperAdmin">
            <img [src]="paisActual.img" style="height: 15px;" />
            <span class="nombre" style="font-size: 15px;"> {{paisActual.nombre}}</span>
          </a>

          <div class="notification-bell" [matMenuTriggerFor]="menuNotificaciones">
            <mat-icon [ngClass]="{'vibrating-icon': unreadNotifications > 0}">notifications</mat-icon>
            <span *ngIf="unreadNotifications > 0" class="notification-dot">{{ unreadNotifications }}</span>
          </div>


          <div [matMenuTriggerFor]="menu">
            <button mat-icon-button class="usuario" matTooltip="{{user.admin ? user.admin.nombre:''}}"
              mat-tooltip-panel-above>
              <mat-icon class="header-arrow-down">account_circle</mat-icon>
            </button>
            <mat-label style="cursor:pointer">{{user.admin ? user.admin.nombre.trim():''}}</mat-label>
          </div>
          
        </div>
        <div *ngIf="!tamanioGrande">
          <mat-icon class="header-arrow-down"  matTooltip="Opciones" mat-tooltip-panel-above
            [matMenuTriggerFor]="menuOpciones">
            keyboard_arrow_down
          </mat-icon>
          <span *ngIf="unreadNotifications > 0" class="notification-dot-tamanio-chico">{{ unreadNotifications }}</span>
        </div>
      </mat-toolbar-row>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/user']">Editar perfil <mat-icon>account_circle</mat-icon></button>
        <button mat-menu-item (click)="cerrarSesion()">Cerrar sesión <mat-icon>logout</mat-icon></button>
      </mat-menu>

      <mat-menu #menuOpciones="matMenu">
        <button mat-menu-item (click)="$event.stopPropagation()" *ngIf="isSuperAdmin" [matMenuTriggerFor]="menuPaises">
          <a class="ui dropdown item" style="margin-right:10px; color:black;">
            <img [src]="paisActual.img" style="height: 15px;" />
            <span class="nombre" style="font-size: 15px;"> {{paisActual.nombre}}</span>
          </a>
          <mat-icon [hidden]="!isSuperAdmin">keyboard_arrow_down</mat-icon>
        </button>

        <button mat-menu-item (click)="$event.stopPropagation()" *ngIf="!isSuperAdmin">
          <a class="ui dropdown item" style="margin-right:10px; color:black;">
            <img [src]="paisActual.img" style="height: 15px;" />
            <span class="nombre" style="font-size: 15px;"> {{paisActual.nombre}}</span>
          </a>
        </button>

        <button mat-menu-item (click)="$event.stopPropagation()" [matMenuTriggerFor]="menuNotificaciones">
            Notificaciones
          <mat-icon [ngClass]="{'vibrating-icon': unreadNotifications > 0}">notifications</mat-icon>
          <span *ngIf="unreadNotifications > 0" class="notification-dot-menu">{{ unreadNotifications }}</span>
        </button>

        <button mat-menu-item [routerLink]="'/user'">Editar perfil <mat-icon>account_circle</mat-icon></button>

        <button mat-menu-item (click)="cerrarSesion()">Cerrar sesión <mat-icon>logout</mat-icon></button>
      </mat-menu>
      <mat-menu #menuPaises="matMenu">
        <button mat-menu-item *ngFor="let pais of filtrarPaises()" (click)="cambioPais(pais)"><img [src]="pais.img"
            style="height: 15px;" /> {{pais.nombre}}</button>
      </mat-menu>

      <mat-menu #menuNotificaciones="matMenu" class="custom-menu">
        <div class="notificacion-div">
          <h3 style="margin: 0px;">Notificaciones</h3>
          <button mat-button *ngIf="notifications.length > 0" (click)="markAllNotificationsAsRead()" style="font-size: 12px;">
            Marcar todas como leídas
          </button>
        </div>
        <hr>
        <div style="overflow-y: auto; max-height: 500px;" #scrollContainer>
          <h3 *ngIf="notifications.length == 0" class="notificacion-h3">Sin Notificaciones</h3>
          <ng-container *ngFor="let notificacion of notifications">
            <div [routerLink]="notificacion.uri" [queryParams]="notificacion.extraData" class="notificacion-button"
              [ngClass]="{'notificacion-leida': notificacion.banLeida}" (click)="markAsRead(notificacion)">
              <div>
                <div class="notificacion-fecha-container">
                  <mat-icon class="kobra-icon" style="padding-right: 5px;">
                    {{ notificacion.tipoNotificacion === 'DOWNLOAD' ? 'download' : (notificacion.tipoNotificacion === 'ALERT' ?
                    'warning' : 'info') }}
                  </mat-icon>
                  <span style="width: 100% !important;">{{ notificacion.mensaje }}</span>
                </div>
                <div class="notificacion-fecha-container">
                  <span class="notificacion-fecha">{{ notificacion.date }}</span>
                  <a class="sombreado-link" style="padding: 5px;  font-size: 12px; margin-left: 5px; "
                    (click)="downloadFile(notificacion, $event)" *ngIf="hasIdDescarga(notificacion.extraData)">Descargar
                    archivo</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        
      </mat-menu>
    </mat-toolbar>
    <router-outlet (deactivate)="filtrosServicios.asignarOpcion();"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>