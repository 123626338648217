import { Component, OnInit, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ImportService } from '../import.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Product } from '../models/product.model';
import { Type } from '../models/type.model';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { environmentSelector } from '../../../environments/environment.selector';
import { MatDialog } from '@angular/material/dialog';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { ChangeDetectorRef } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit {
    private environment = environmentSelector();

    @Input() product: Product = null;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Input() idCliente: number;
    public formFiltros: FormGroup;
    public formFiltrosDefault: object;
    public mostrarFiltros: boolean;
    selectedLotes:any = [];
    fechaInicio:any;
    fechaFin:any;
    cargaLotes: string = "Cargando Lotes";
    sessionData: SessionData;
    isLoad = true;
    public filters: any = {
        financiera: null,
        nombre: null,
        activa: true,
        excluirDemo: false,
        filtered: false,
        page: 1
    };
    ayuda = false;
    form: FormGroup;
    types: Type[];
    urlHelp = this.environment.kobraHelp;

    constructor(
        private formBuilder: FormBuilder,
        private importService: ImportService,
        private localStorageService: LocalStorageService,
        private appService: ApplicationService,
        private rootElement: ElementRef,
        private dialog: MatDialog,
        private filtrosService: FiltrosService,
        private cdr: ChangeDetectorRef
    ) {
        
        this.form = this.formBuilder.group({ id: [false, Validators.required] });
        this.sessionData = this.localStorageService.getSessionData(); 
    }

    ngOnInit() {
        //const sessionData: SessionData = this.localStorageService.getSessionData();
        //const loading = this.appService.showLoading(`Cargando tipos de ${this.product.name.toLowerCase()}...`);
        this.mostrarFiltros = false;
        this.importService.getTypes(this.product.id).subscribe(
        (types: Type[]) => {
            this.types = types;
            this.isLoad = false;
            //this.appService.hideLoading(loading);
        }, err => {
            this.isLoad = false;
            //this.appService.hideLoading(loading);
            this.appService.showError(err);
        });

        
        
        this.form.valueChanges.subscribe(val => {
        this.onChange.emit(this.types.find(a => a.id === val.id));
        });
       
        //this.initSemantic();
    }

    someMethod() {
        // código que cambia una propiedad ligada a la vista
        this.cdr.detectChanges();
    }

    change(productType) {
        this.form.controls['id'].setValue(productType.id);
        this.mostrarFiltros = productType.id == 4;
    }

    download() {
        var query = "";

        if (!Boolean(this.form.controls['id'].value)) {
            this.appService.showSnackbar('¡Aviso!', 'Debe de seleccionar una acción.', 3000, 'warning');

            return;
        }
      
        if(this.selectedLotes.length > 0){
            query += `?lotes=${JSON.stringify(this.selectedLotes)}`;
        }

        if(query != '' && this.fechaInicio && this.fechaFin){
            query += '&fecInicio=' + this.fechaInicio;
            query += '&fecFin=' + this.fechaFin;
        }else if (query == '' && this.fechaInicio && this.fechaFin) {
            query += '?fecInicio=' + this.fechaInicio;
            query += '&fecFin=' + this.fechaFin;
        }

        //const productType = this.types.find(a => a.id === this.form.controls['id'].value);

        this.importService.downloadLayout(this.idCliente, this.product.id, this.form.controls['id'].value, query).subscribe(blob => {
            let extension = 'xlsx';

            if (blob.type === 'application/csv' || blob.type === 'text/csv') {
                extension = 'csv';
            } else if (blob.type === 'application/vnd.ms-excel') {
                extension = 'xls';
            }else if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                extension = 'xlsx';
            }
            
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = `Kobra_Layout_0${this.form.controls['id'].value}.${extension}`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
    }

    ///Metodos de los filtros por fechas
    public showFilter(): void {
        const dialogRef = this.dialog.open(FilterModalComponent, {
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result.success){
                this.selectedLotes = result.selectedLotes;
                this.fechaInicio = result.fechaInicio;
                this.fechaFin = result.fechaFin;
                this.download();
            }
          });
    }

    clearFilter(): void {
          this.selectedLotes = [];
          this.formFiltros.get('lote').reset();
          Object.keys(this.formFiltrosDefault).forEach((field) => {
            this.formFiltros.controls[field].patchValue(this.formFiltrosDefault[field]);
          });
     }
}
