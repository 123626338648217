export const NotificationTypes = {
    1: 'DOWNLOAD',
    2: 'ALERT',
    3: 'ADVICE',
    // Agrega más tipos según sea necesario
};

export const UriTypes = {
    1 : '/descargas',
    2 : '/cuentas/domicilios/account'
}