import { PaquetesFinancieraDetalleService } from 'src/app/saldos/paquetes-financiera/paquetes-financiera-detalle/paquetes-financiera-detalle.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, FormArray } from "@angular/forms";

import { FormValidation } from "../../shared/form-validation/form-validation.model";
import { FormValidationService } from "../../shared/form-validation/form-validation.service";
import { MessageService } from "../../shared/message/message.service";

import { Paquete } from "../../paquete/paquete.model";
import { PaqueteVisitasPrecios } from "../../paquete-visitas-precios/paquete-visitas-precios.model";
import { PaqueteService } from "../../paquete/paquete.service";
import { PaqueteVisitasPreciosService } from "../../paquete-visitas-precios/paquete-visitas-precios.service";

import { LenderService } from "../../lender/lender.service";

import { PaqueteFinanciera } from "../paquete-financiera.model";
// import { PaqueteFinancieraService } from "../paquete-financiera.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'paquete-financiera-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [
    FormValidationService,
    PaqueteService,
    PaqueteVisitasPreciosService,
    LenderService
  ]

})
export class PaqueteFinancieraFormComponent implements OnInit {
  conceptoActual: any = null;
  public conceptos: any[]= [];
  public conceptosTemp: any[]= [];
  public cargando: boolean;
  public mostrarSaldo: boolean;
  public paquetes: Paquete[];
  public paquetesVisitasPrecios: PaqueteVisitasPrecios[];
  public paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
  public items: FormArray;
  private conceptosCompletos: any[] = [];
  cargandoConcepto: boolean = false;
  public activarBoton: boolean = false;

  public form: FormGroup;
  public idPaqueteValidation: FormValidation;
  public saldoInicialValidation: FormValidation;
  public precioVisitaValidation: FormValidation;

  @Input() showAddButton: boolean = true;
  @Input() mostrarSaldoVencido: boolean = true;
  @Input() idFinanciera: number;
  @Output() save: EventEmitter<PaqueteFinanciera> = new EventEmitter();

  private saldoDisabled = false;

  constructor(
    private app: ApplicationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private validationService: FormValidationService,
    private paqueteService: PaqueteService,
    private lenderService: LenderService,
    // private paqueteFinancieraService: PaqueteFinancieraService,
    private paqueteVisitasPreciosService: PaqueteVisitasPreciosService,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService
  ) { }

  ngOnInit() {
    this.initForm();
    this.initValidation();
    this.cargarPaquetes();
  }

    private initForm(): void {
        this.form = this.formBuilder.group({
            'idPaquete': ['', [
                Validators.required
            ]],
            'saldoInicial': [0, [
                Validators.required,
                Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
                Validators.min(1)
            ]],
            'nombre': '',
            items: this.formBuilder.array(
                []
            )
        });
    };

    createItem(idPaquete: number, idConcepto: number, nombreConcepto: string, precio: number): FormGroup {
      return this.formBuilder.group({
        idPaquete: idPaquete,
        idConcepto: idConcepto,
        nombre: nombreConcepto,
        precio: [precio, [
          Validators.required,
          Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
          Validators.min(1)
        ]]
      });
    }

  private validarMayorCero(control: AbstractControl): { [key: string]: any } {
    let mayorCero = parseFloat(control.value) > 0;
    return mayorCero ? null : { 'mayorCero': { value: control.value } };
  }

  private initValidation(): void {
    this.idPaqueteValidation = new FormValidation();
    this.idPaqueteValidation.formControl = this.form.controls['idPaquete'];
    this.idPaqueteValidation.message = {
      required: 'Información requerida.'
    };
    this.idPaqueteValidation.observeFromControl();

    this.saldoInicialValidation = new FormValidation();
    this.saldoInicialValidation.formControl = this.form.controls['saldoInicial'];
    this.saldoInicialValidation.message = {
      required: 'Información requerida.',
      pattern: 'El saldo inicial debe ser un número mayor a cero.',
      min: 'El saldo inicial debe ser un número mayor a cero.'
    };
    this.saldoInicialValidation.observeFromControl();


  }

    public submit(): void {
        if (!this.items || !this.items.length) {
            this.app.showSnackbar('¡Aviso!', 'Este paquete no tiene conceptos.', 3000, 'warning');
            return;
        }
        // this.obtenerDatosForm();

        if (this.form.valid) {
          this.activarBoton = false;
            let paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
            paqueteFinanciera = this.obtenerDatosForm();
            if(!paqueteFinanciera.precio){
                paqueteFinanciera.precio = 0;
            }
            // this.loaderService.showHttpLoader('Agregando paquete...');
            let loading = this.app.showLoading('Agregando paquete...');
            // agregarPaquete
            this.lenderService.agregarPaquete(paqueteFinanciera).subscribe(
                paquete => {
                //this.paqueteFinanciera = paquete;
                this.limpiarFormArray();
                paqueteFinanciera.idFinancieraPaquete = paquete.idFinancieraPaquete;
                
                this.app.showSnackbar('¡Aviso!', 'Paquete contratado correctamente.', 3000, 'success');

                this.form.patchValue({
                    idPaquete: '',
                    saldoInicial: 0,
                    nombre: '',
                });
                this.save.emit(paqueteFinanciera);
                },
                error => {
                this.app.hideLoading(loading);
                this.app.showError(error);
                // this.loaderService.hideHttpLoader();
                this.messageService.httpError(error);
                },
                () => this.app.hideLoading(loading)
            );
        }
    }

  private cargarPaquetes(): void {
    //cargcar paquetes de postgres
    this.cargando = true;
    this.paqueteService.all().subscribe(
      paquetes => {
        this.paquetes = paquetes.filter(p => this.mostrarSaldoVencido || p.idPaquete !== 0);
        this.cargando = false;
      },
      error => {
        this.cargando = false;
        this.messageService.httpError(error);
      }
    );
  }

  public limpiarFormArray() {
    while (this.items.length) {
      this.items.removeAt(0);
    }

  }

    public actualizarSaldo(value) {
        this.activarBoton = true;
        if (this.form.controls['idPaquete'].value !== 0 && !(this.form.controls['idPaquete'].value > 0)) {
            this.mostrarSaldo = false;
            if (this.items && this.items.length) {
                this.limpiarFormArray();
            }
            return;
        }

        this.mostrarSaldo = true;

        //OBTENER precio Visita
        for (let i = 0; i < this.paquetes.length; i++) {
            if (this.paquetes[i].idPaquete == value) {
                this.form.controls['saldoInicial'].setValue(this.paquetes[i].precio);
                this.form.controls['saldoInicial'].enable();
                this.form.controls['nombre'].setValue(this.paquetes[i].nombre);
                if(this.paquetes[i].idPaquete === 0){
                    this.form.controls['saldoInicial'].disable();
                }
                break;
            }
        }

        this.cargandoConcepto = true;
        this.paqueteVisitasPreciosService.obtenerPaqueteVisitasPrecios(+this.form.controls['idPaquete'].value).subscribe(
            paquetesVisitasPrecios => {
                this.paquetesVisitasPrecios = paquetesVisitasPrecios;
                this.items = this.form.get('items') as FormArray;

                if (this.items.length) {
                    this.limpiarFormArray();
                }
                this.getConceptosFinanciera();
                for (let i = 0; i < this.paquetesVisitasPrecios.length; i++) {
                    this.items.push(this.createItem(paquetesVisitasPrecios[i].idPaquete, paquetesVisitasPrecios[i].idConcepto, paquetesVisitasPrecios[i].nombre, paquetesVisitasPrecios[i].precio));
                    //this.items.controls[i].get('idPaquete').setValue(paquetesVisitasPrecios[i].idPaquete);
                }
                this.cargandoConcepto = false;
            },
            error => {
                this.cargandoConcepto = false;
                this.messageService.httpError(error);
            }
        );
    }

    getConceptosFinanciera(){;
      this.paquetesFinancieraDetalleService.getConceptosPorFinanciera(this.idFinanciera)
        .pipe(take(1))
        .subscribe((res) => {
          this.conceptosCompletos = res;
          for (let index = 0; index < this.conceptosCompletos.length; index++) {
            const concepto = this.conceptosCompletos[index];
            if(!this.paquetesVisitasPrecios.some((item)=>{
              return item.idConcepto == concepto.idConcepto;
            })){
              concepto.precio = 0;
              this.conceptosTemp.push(concepto);
            }
            
          }
          this.conceptos = this.conceptosTemp;
          
        }, (err) => {
          this.app.showError(err);
        });
    }

  private obtenerDatosForm(): PaqueteFinanciera {
    let formModel = this.form.value;
    let paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
    paqueteFinanciera.idFinanciera = this.idFinanciera;
    paqueteFinanciera.idPaquete = formModel.idPaquete;
    paqueteFinanciera.precio = formModel.saldoInicial;
    paqueteFinanciera.nombre = formModel.nombre;
    paqueteFinanciera.paquetesVisitasPrecios = [];

    for (let i = 0; i < this.items.length; i++) {
      let paqueteVisitaPrecio: PaqueteVisitasPrecios = new PaqueteVisitasPrecios();
      paqueteVisitaPrecio.idPaquete = this.items.controls[i].value.idPaquete;
      paqueteVisitaPrecio.precio = this.items.controls[i].value.precio;
      paqueteVisitaPrecio.idConcepto = this.items.controls[i].value.idConcepto;
      paqueteFinanciera.paquetesVisitasPrecios.push(paqueteVisitaPrecio);
    }
    return paqueteFinanciera;
  }

  agregarConcepto(){
    this.items.push(this.createItem(this.form.controls['idPaquete'].value, this.conceptoActual.idConcepto, this.conceptoActual.nombreConcepto, this.conceptoActual.precio ));
    
    let index = this.conceptos.indexOf(this.conceptoActual);
    this.conceptos.splice(index, 1);
    setTimeout(() => {
      const control = document.getElementById(this.conceptoActual.idConcepto);
      this.conceptoActual = null;
    
    if(control){
      control.focus();
    }
    }, 100);
  }

  borrarConcepto(item: FormGroup, index: number){
    let conceptoBorrado: any = {
      idConcepto: item.controls['idConcepto'].value,
      nombreConcepto: item.controls['nombre'].value,
      precio: item.controls['precio'].value
    };
    this.items.removeAt(index);
    this.conceptos = [...this.conceptos, conceptoBorrado];
  }

  public onInputLimite(index: number): void{
    if(this.items.controls[index].value.precio > 99999){
        setTimeout(()=>{
          this.items.controls[index].value.precio = 99999;
          this.items.controls[index].get('precio').patchValue(99999);
        }, 10);
    }
  }
}
