<h1 mat-dialog-title>Carga de plantillas</h1>
<mat-dialog-content>
  <app-upload-file [allowMultiple]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="handleFiles($event)"></app-upload-file>
  <mat-form-field style="width: 100%; margin-top: 20px;">
    <mat-label>Descripción:</mat-label>
    <textarea style="resize: none;" matInput textSanitizer  placeholder="Favor de escribir una descripción..." rows="2"
    [(ngModel)]="descripcion" maxlength="255"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="salir(false)" ><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button mat-stroked-button class="boton-accion-front" (click)="limpiar()">
      <mat-icon>autorenew</mat-icon>Limpiar
    </button>
  <button mat-stroked-button class="boton-guardar" (click)="subirFormato($event)">
    <mat-icon>save</mat-icon>Guardar
  </button>
</mat-dialog-actions>