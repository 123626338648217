<mat-card class="upload-card" 
          (click)="fileInput.click()" 
          (drop)="onDrop($event)" 
          (dragover)="onDragOver($event)" 
          (dragleave)="onDragLeave($event)">
    <input #fileInput type="file"
            [disabled]="bloquear" 
           [multiple]="allowMultiple" 
           [accept]="acceptedTypes" 
           (change)="onFilesSelected($event)" hidden>
    <mat-card-content *ngIf="!selectedFiles.length; else fileInfo" class="centered-content">
        <mat-icon class="upload-icon kobra-icon">cloud_upload</mat-icon>
        <p>Da clic o arrastra archivos aquí</p>
    </mat-card-content>
    <ng-template #fileInfo>
        <div class="files-container">
            <div *ngFor="let file of selectedFiles">
                <div class="file-info">
                    <span class="file-name">{{file.name}} ({{file.size | byteFormat}})</span>
                    <button *ngIf="mostrarBotonEliminar" mat-icon-button (click)="removeFile($event, file)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
            </div> 
        </div>       
    </ng-template>
</mat-card>
