
<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #columnasSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Cerrar"
      mat-tooltip-panel-above
      (click)="columnasSideNav.toggle();columnasAbiertas=!columnasAbiertas;"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button> 
    Ocultar columnas
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <li>
      <mat-checkbox
          [(ngModel)]="listFields.lender" 
          (change)="saveInStorage(0, listFields.lender)"
          [color]="'primary'"
        >
          Cliente
        </mat-checkbox>
    </li>
    <li>
      <mat-checkbox
        [(ngModel)]="listFields.branchName" 
        (change)="saveInStorage(2, listFields.branchName)"
        [color]="'primary'"
      >
        Sucursal
      </mat-checkbox>
    </li>
    <li>
      <mat-checkbox
        [(ngModel)]="listFields.status" 
        (change)="saveInStorage(4, listFields.status)"
        [color]="'primary'"
      >
        Estatus
      </mat-checkbox>
    </li>
  </ol>
</mat-sidenav>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <li class="filtro-side">
      <mat-select-search
        class="id-card-field"
        [label]="'Cliente'"
        [placeHolder]="'Selecciona un cliente'"
        [value]="'idFinanciera'"
        [labelField]="'nombre'"
        [icon]="'account_balance'"
        [options]="lenders"
        [(ngModel)]="idLender"
        (ngModelChange)="onChangeLender($event);"
        [ngModelOptions]="{standalone: true}"
      >
      </mat-select-search>
     
    </li>
    <li class="filtro-side">
      <mat-form-field class="pago-gestor" style="width: 100%;">
        <mat-label>Nombre</mat-label>
        <input matInput textSanitizer 
          [(ngModel)]="filters.nombreSucursal"
          placeholder="Capture la sucursal a buscar">
          <mat-icon matSuffix  class="icono-limpiar"
          *ngIf="filters.nombreSucursal" 
          (click)="filters.nombreSucursal = null">clear</mat-icon>
        <mat-icon matSuffix>lightbulb_circle</mat-icon>
      </mat-form-field>
      <!-- <div align="left" *ngIf="filters.nombreSucursal" class="limpiar-seleccion-kobra">
        <a (click)="filters.nombreSucursal = null"><i>Limpiar</i></a>
      </div> -->
    </li>
    <li>
      <mat-checkbox class="kobra-checkbox"
        [(ngModel)]="filters.activa"
        [color]="'primary'"
      >
        Activa
      </mat-checkbox>
    </li>
  </ol>
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter()">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>

<div class="app content tabla kobra" *ngIf="branches && !hijosActivos">
  <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar administradores"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadBranches(filters.page ? filters.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="agregarSucursal()">
          <mat-icon class="kobra-icon">add</mat-icon>
          <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="editarSucursal()">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="activarDesactivarSucursal(itemsSelected[0])">
        <mat-icon [ngClass]="{'kobra-delete-icon': itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Activa',
                              'kobra-icon': itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Inactiva'}">
        {{(itemsSelected?.length === 1 && itemsSelected[0]?.activa) ? 'do_not_disturb_on' : 'check'}}
        </mat-icon>
        <span>{{(itemsSelected?.length === 1 && itemsSelected[0]?.activa) ? 'Inactivar' : 'Activar'}}</span>
      </button>
  </mat-menu>
  <app-table
    [classContainer]="'tabla-completa'"
    [loading]="loadings.sucursales"
    [columns]="tabla.columns"
    [items]="branches"
    [headers]="tabla.headers"
    [multiSelect]="tabla.multiSelect"
    (onSelectedRows)="onSelected($event)"
    (onLinkRow)="onLinkRow($event)"
    (sortChange)="sortChange($event)"
    (filtersChange)="filtersChange($event)"
    (searchChange)="searchChange($event)"
    (onDownload)="download()"
    (onDoubleClick)="onDoubleClick($event)"
  >
    

    <div t-header>
      <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
         <p><mat-icon color="primary">help</mat-icon> 
          El módulo "Sucursales" es una sección clave en el sistema, diseñada para administrar y organizar las diferentes sucursales de la empresa. Esta herramienta permite una gestión eficaz de las sucursales, así como la asignación y el control del acceso a las cuentas en función de la sucursal. Proporciona un listado completo de todas las sucursales de la empresa, facilitando la visualización y el manejo de la estructura organizativa a nivel de sucursales.
        </p> 
        Las acciones claves de este módulo son:
        <p>
           <mat-icon class="kobra-icon">filter_alt</mat-icon><b>Filtrar:</b> Mejora la búsqueda y el acceso a los conceptos, permitiendo aplicar filtros basados en diversos criterios.
         </p>
         <p>
           <mat-icon class="kobra-icon">sync</mat-icon><b>Refrescar:</b> Te permite recargar tu listado con los criterios de filtros aplicados.
         </p>
         <p>
          <b>Alta, Edición y Borrado:</b> Los usuarios pueden dar de alta nuevas sucursales, editar información de las sucursales existentes o eliminarlas del sistema según sea necesario.

         </p>
         <p>
          Nota: Esta funcionalidad permite restringir el acceso de los usuarios a las cuentas, limitándolos a ver solo las cuentas ligadas a la sucursal a la que están asignados. Esto es esencial para mantener la seguridad de la información y asegurar que los usuarios accedan solo a los datos relevantes para su área de trabajo.
          Al dar de alta una cuenta, se puede ligar a una sucursal específica. Si hay usuarios agregados a una sucursal, solo podrán ver las cuentas asociadas a esa sucursal. En ausencia de esta asignación, los usuarios tendrán acceso a todas las cuentas, independientemente de la sucursal a la que pertenezcan.
         </p>
       </mat-card-content>
     </mat-card> 
   </div>

    <ng-container t-paginator>
      <!--strong style="margin-right: 12px;">{{datosConsulta.fromRowPage || 0}} - {{datosConsulta.toRowPage || 0}}
          de {{pagination?.totalRows || 0}}</strong>
      <sui-pagination [collectionSize]="pagination?.totalRows" [pageSize]="datosConsulta.rows" [maxSize]="4" [hasEllipses]="true"
        [page]="pagination?.page" (pageChange)="onPage($event)" [hasBoundaryLinks]="true">
      </sui-pagination-->
      <mat-paginator 
        showFirstLastButtons
        [length]="pagination?.totalRows"
        [pageIndex]="pagination?.page-1"
        [pageSize]="datosConsulta.rows"
        (page)="onPage($event)"
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </ng-container>
  </app-table>
</div>
