<h1 mat-dialog-title>Estatus</h1>
<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <mat-label>Estatus</mat-label>
    <mat-select [(value)]="selectedStatus">
        <mat-option value="Activa" *ngIf="data.opcion !=3">Activa</mat-option>
        <mat-option value="Inactiva">Inactiva</mat-option>
    </mat-select >
    <mat-icon matSuffix  class="icono-limpiar-solo"
                  *ngIf="selectedStatus" 
                  (click)="limpiarEstatus();">clear</mat-icon> 
  </mat-form-field>
  <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedStatus">
    <a (click)="limpiarEstatus()"><i>Limpiar selección</i></a>
  </div> -->

  
  <mat-form-field class="example-full-width" *ngIf="data.domiciliosSeleccionados.length === 0 && data.opcion === 1">
    <mat-label>Domicilio</mat-label>
    <mat-select [(ngModel)]="idDomicilio" (ngModelChange)="onChangeDomicilioAsignar()" >
      <mat-option *ngFor="let domicilio of data.domicilios" [value]="domicilio.idDomicilioAcreditado" >
        {{domicilio.selectDomicilio}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedStatus === 'Activa'">
    <p appearance="legacy">Esta cuenta pasará a estar activa, si se encuentra apartada por un gestor se
      anulará el contrato.</p>
  </div>

  <div *ngIf="selectedStatus === 'Inactiva'">
    <p appearance="legacy">Esta cuenta pasará a estar inactiva, si se encuentra apartada por un gestor se
            anulará el contrato.</p>

    <mat-form-field class="example-full-width">
      <mat-label>Motivo</mat-label>
      <mat-select [(ngModel)]="selectedTag" (ngModelChange)="changeMotivo()" >
        <mat-option *ngFor="let motivo of data.motivos" [value]="motivo.idEtiquetaOperacion" >
          {{motivo.nombre}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix  class="icono-limpiar-solo"
                  *ngIf="selectedTag" 
                  (click)="limpiarMotivo();">clear</mat-icon> 
    </mat-form-field>
    <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedTag">
      <a (click)="limpiarMotivo()"><i>Limpiar selección</i></a>
    </div> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button  class="boton-guardar" (click)="actualizaEstatus()"><mat-icon style="color:white !important">sync</mat-icon>Actualizar</button>
</mat-dialog-actions>