import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { VisitClarification } from './models/visit-clarification';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable({
  providedIn: 'root'
})
export class VisitClarificationService {

  private serviceURL: string = '/visitas';

  constructor(
    private serviciosVisitas: ServicesVisitasService,
  ) { }

  public get(idVisit: number, idAgent: number, idClient: number, idFinanciera: number): Observable<VisitClarification> {
    return this.serviciosVisitas.get(`${this.serviceURL}/${idVisit}/aclaracion?idAgente=${idAgent}&idCliente=${idClient}&idFinanciera=${idFinanciera}`).pipe(
      map((res) => {
        if (!Boolean(res.data)) {
          return null;
        }

        return {
          idStatus: res.data.idEstatus,
          status: res.data.estatus,
          description: res.data.descripcion,
          reason: res.data.motivo,
          date: res.data.fecha,
          fechaActualizado: res.data.fechaActualizado
        };
      })
    );
  }

  public save(idVisit: number, idAgent: number, idClient: number, description: string) {
    return this.serviciosVisitas.post(`${this.serviceURL}/${idVisit}/aclaracion`, {
      idCliente: idClient,
      idAgente: idAgent,
      descripcion: description
    }).pipe(
      map((res) => {
        if (!Boolean(res.data)) {
          return null;
        }

        return <VisitClarification> {
          idStatus: res.data.idEstatus,
          status: res.data.estatus,
          description: res.data.descripcion,
          reason: res.data.motivo,
          date: res.data.fecha
        };
      })
    );
  }

  public update(idVisit: number, idAgent: number, idClient: number, reason: string, idStatus: number) {
    return this.serviciosVisitas.patch(`${this.serviceURL}/${idVisit}/aclaracion`, {
      idCliente: idClient,
      idAgente: idAgent,
      idEstatus: idStatus,
      motivo: reason
    }).pipe(
      map((res) => {
        if (!Boolean(res.data)) {
          return null;
        }

        return <VisitClarification> {
          idStatus: res.data.idEstatus,
          status: res.data.estatus,
          description: res.data.descripcion,
          reason: res.data.motivo,
          date: res.data.fecha
        };
      })
    );
  }
}
