import { Component, OnInit } from '@angular/core';
import { PercentageDeallocation } from './percentage-deallocation.model';
import { IndicatorService } from "./indicator.service";
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css'],
  providers: [
    IndicatorService,
  ]
})
export class IndicatorsComponent implements OnInit {
  public loadingPercentageDeAllocation: boolean;
  public percentageDeallocation: PercentageDeallocation;
  public filterPeriodStartDate: Date;
  public filterPeriodEndDate: Date;
  public today: Date = new Date();

  constructor(
    private indicatorService: IndicatorService,
    private app: ApplicationService,
  ) { }

  ngOnInit() {
    this.percentageDeallocation = new PercentageDeallocation();
    this.loadPercentageDeAllocation();
  }


  public loadPercentageDeAllocation(): void {
    this.loadingPercentageDeAllocation = true;
    let queryParams: string = '?';
    if (this.filterPeriodStartDate) {
      let strDate: string = this.filterPeriodStartDate.toISOString();
      queryParams += 'fecInicio=' + strDate.split('T')[0];
    }

    if (this.filterPeriodEndDate) {
      let strDate: string = this.filterPeriodEndDate.toISOString();
      if (this.filterPeriodEndDate) {
        queryParams += '&';
      }
      queryParams += 'fecFin=' + strDate.split('T')[0];
    }

    if (queryParams == '?') {
      this.filterPeriodStartDate = new Date();
      this.filterPeriodEndDate = new Date();
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 15);
      this.filterPeriodStartDate = startDate;
      let maxDate = new Date();
      maxDate.setDate(maxDate.getDate());
      let strDate: string = startDate.toISOString();
      let endDate: string = maxDate.toISOString();
      queryParams += 'fecInicio=' + strDate.split('T')[0] + '&fecFin='  + endDate.split('T')[0];
    }
    let subscription: Subscription =  this.indicatorService.getPercentageDeAllocation(queryParams).subscribe(
      (percentageDeAllocation) => {
        this.loadingPercentageDeAllocation = false;
        this.percentageDeallocation.percentageDeallocation = percentageDeAllocation.percentageDeallocation;
        this.percentageDeallocation.totalAssignedAccounts = percentageDeAllocation.totalAssignedAccounts;
        this.percentageDeallocation.totalExpiredAccounts = percentageDeAllocation.totalExpiredAccounts;
      },
      (error) => {
        this.app.showError(error);
        this.loadingPercentageDeAllocation = false;
      }, () => {
        this.loadingPercentageDeAllocation = false;
        subscription.unsubscribe();
      }
    )
  }
}
