<div [formGroup]="dynamicForm">
  <!-- Selector de tipo de respuesta si la respuesta no es requerida -->
  <mat-form-field *ngIf="!respuesta.requerida" class="ancho-field">
    <mat-label>Seleccionar tipo de respuesta</mat-label>
    <mat-select formControlName="{{ idControl + '-tipoRespuesta' }}">
      <mat-option *ngFor="let option of respuestaOptions" [value]="option.id">
        {{ option.nombre }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix 
      *ngIf="dynamicForm.get(idControl + '-tipoRespuesta')?.value" 
      (click)="limpiarRespuesta(idControl + '-tipoRespuesta')">
      clear
    </mat-icon>
    <mat-error *ngIf="dynamicForm.get(idControl + '-tipoRespuesta')?.hasError('required')">
      Este campo es <strong>requerido</strong>
    </mat-error>
  </mat-form-field>
  
  <!-- Campo de entrada según el tipo de respuesta -->
  <ng-container [ngSwitch]="respuesta.idTipoRespuesta">
    <ng-container *ngSwitchCase="1">
      <div *ngIf="mostrarControl()">
        <mat-form-field class="ancho-field">
          <mat-label>Escribe una respuesta</mat-label>
          <input matInput formControlName="{{ idControl }}" maxlength="2000">
          <mat-icon matSuffix 
            *ngIf="dynamicForm.get(idControl)?.value" 
            (click)="limpiarRespuesta(idControl)">
            clear
        </mat-icon>
          <mat-error *ngIf="validarControl()">
            Esta respuesta es <strong>requerida</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <div *ngIf="mostrarControl()">
        <mat-form-field class="ancho-field">
          <mat-label>Escribe una respuesta</mat-label>
          <textarea matInput formControlName="{{ idControl }}" rows="3" maxlength="2000"></textarea>
          <mat-icon matSuffix 
            *ngIf="dynamicForm.get(idControl)?.value" 
            (click)="limpiarRespuesta(idControl)">
            clear
          </mat-icon>
          <mat-error *ngIf="validarControl()">
            Esta respuesta es <strong>requerida</strong>
          </mat-error>
        </mat-form-field>
      </div>  
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <div *ngIf="mostrarControl()">
        <div *ngIf="operador != 'between'">
          <mat-form-field class="ancho-field">
            <mat-label>Escribe una respuesta</mat-label>
            <input 
              matInput 
              type="number" 
              formControlName="{{ idControl }}"
            >
            <mat-icon matSuffix 
              *ngIf="dynamicForm.get(idControl)?.value" 
              (click)="limpiarRespuesta(idControl)">
              clear
            </mat-icon>
            <mat-error *ngIf="validarControl()">
              Esta respuesta es <strong>requerida</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="kobra two columns" *ngIf="operador === 'between'">
          <!-- Campo para el valor mínimo -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;">
            <mat-form-field>
              <mat-label>Escribe una respuesta</mat-label>
              <input 
                matInput 
                type="number" 
                placeholder="Incio de rango" 
                formControlName="{{ idControl }}"
                [max]="dynamicForm.get(idControl + '-2')?.value || null"
              >
              <mat-icon matSuffix 
                *ngIf="dynamicForm.get(idControl)?.value" 
                (click)="limpiarRespuesta(idControl)">
                clear
              </mat-icon>
              <mat-error *ngIf="validarControl()">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
          
          <!-- Campo para el valor máximo, sólo visible si el operador es 'between' -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;">
            <mat-form-field >
              <mat-label>Escribe una respuesta</mat-label>
              <input 
                matInput 
                type="number" 
                placeholder="Fin de rango" 
                formControlName="{{ idControl + '-2'}}"
                [min]="dynamicForm.get(idControl)?.value || null"
              >
              <mat-icon matSuffix 
                *ngIf="dynamicForm.get(idControl + '-2')?.value" 
                (click)="limpiarRespuesta(idControl + '-2')">
                clear
              </mat-icon>
              <mat-error *ngIf="validarSegundoControl()">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      
    </ng-container>
    
    <ng-container *ngSwitchCase="4">
      <div *ngIf="mostrarControl()">
        <div *ngIf="operador != 'between'">
          <mat-form-field class="ancho-field">
            <mat-label>Escribe una respuesta</mat-label>
            <input 
              matInput 
              type="number" 
              step="0.01" 
              formControlName="{{ idControl }}"
            >
            <mat-icon matSuffix 
              *ngIf="dynamicForm.get(idControl)?.value" 
              (click)="limpiarRespuesta(idControl)">
              clear
            </mat-icon>
            <mat-error *ngIf="validarControl()">
              Esta respuesta es <strong>requerida</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="kobra two columns" *ngIf="operador === 'between'">
          <!-- Campo para el valor mínimo -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;">
            <mat-form-field>
              <mat-label>Escribe una respuesta</mat-label>
              <input 
                matInput 
                type="number" 
                placeholder="Inicio de rango" 
                step="0.01" 
                formControlName="{{ idControl }}"
                [max]="dynamicForm.get(idControl + '-2')?.value || null"
              >
              <mat-icon matSuffix 
                *ngIf="dynamicForm.get(idControl)?.value" 
                (click)="limpiarRespuesta(idControl)">
                clear
              </mat-icon>
              <mat-error *ngIf="validarControl()">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
          
          <!-- Campo para el valor máximo, sólo visible si el operador es 'between' -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;">
            <mat-form-field>
              <mat-label>Escribe una respuesta</mat-label>
              <input 
                matInput 
                type="number"
                placeholder="Fin de rango" 
                step="0.01" 
                formControlName="{{ idControl + '-2'}}"
                [min]="dynamicForm.get(idControl)?.value || null"
              >
              <mat-icon matSuffix 
                *ngIf="dynamicForm.get(idControl + '-2')?.value" 
                (click)="limpiarRespuesta(idControl + '-2')">
                clear
              </mat-icon>
              <mat-error *ngIf="validarSegundoControl()">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>    

    <ng-container *ngSwitchCase="5">
      <div *ngIf="mostrarControl()">
        <div *ngIf="operador != 'between'" class="ancho-field">
          <mat-date-picker-formater 
              [label]="'Escribe una respuesta'"
              [placeHolder]="operador === 'between' ? 'Capture fecha inicio' : 'Capture la fecha'"
              formControlName="{{ idControl }}">
            </mat-date-picker-formater>
            <mat-error *ngIf="validarControl()" class="error">
              Esta respuesta es <strong>requerida</strong>
            </mat-error>
        </div>
        <div class="kobra two columns" *ngIf="operador === 'between'">
          <!-- Campo para el valor mínimo (fecha inicio) -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;">
            <mat-date-picker-formater 
              [label]="'Escribe una respuesta'"
              [placeHolder]="operador === 'between' ? 'Capture fecha inicio' : 'Capture la fecha'"
              formControlName="{{ idControl }}"
              [max]="dynamicForm.get(idControl + '-2')?.value || today">
            </mat-date-picker-formater>
            <mat-error *ngIf="validarControl()" class="error">
              Esta respuesta es <strong>requerida</strong>
            </mat-error>
          </div>
      
          <!-- Campo para el valor máximo (fecha fin), sólo visible si el operador es 'between' -->
          <div class="kobra column" style="width: 35% !important; padding-left: 0px;" >
            <mat-date-picker-formater 
              *ngIf="dynamicForm.contains(idControl + '-2')"
              [label]="'Escribe una respuesta'"
              [placeHolder]="'Capture fecha fin'"
              formControlName="{{ idControl + '-2'}}"
              [min]="dynamicForm.get(idControl)?.value"
              >
            </mat-date-picker-formater>
            <mat-error *ngIf="validarSegundoControl()" class="error">
              Esta respuesta es <strong>requerida</strong>
            </mat-error>
          </div>
        </div>
      </div>
      
    </ng-container>    

    <ng-container *ngSwitchCase="6">
      <div *ngIf="mostrarControl()">
        <mat-checkbox  class="ancho-field" formControlName="{{ idControl }}">
          {{ respuesta.nombreRespuesta }}
        </mat-checkbox>
        <mat-error *ngIf="validarControl()">
          Esta respuesta es <strong>requerida</strong>.
        </mat-error>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="7">
      <div *ngIf="mostrarControl()">
        <mat-radio-group  class="ancho-field" formControlName="{{ idControl }}">
          <mat-radio-button *ngFor="let opt of respuesta.options" [value]="opt.id">
            {{ opt.label }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="validarControl()">
          Esta respuesta es <strong>requerida</strong>
        </mat-error>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="8">
      <div *ngIf="mostrarControl()">
        <mat-form-field  class="ancho-field">
          <mat-label>Seleccione una respuesta</mat-label>
          <mat-select formControlName="{{ idControl }}">
            <mat-option *ngFor="let option of checkOptions" [value]="option.id">
              {{ option.nombre }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix 
            *ngIf="dynamicForm.get(idControl)?.value" 
            (click)="limpiarRespuesta(idControl)">
            clear
          </mat-icon>
          <mat-error *ngIf="validarControl()">
            Esta respuesta es <strong>requerida</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="9">
      <div *ngIf="mostrarControl()">
        <mat-form-field class="ancho-field">
          <mat-label>Seleccione una respuesta</mat-label>
          <mat-select formControlName="{{ idControl }}">
            <mat-option *ngFor="let opt of respuesta.options" [value]="opt.id">{{ opt.label }}</mat-option>
          </mat-select>
          <mat-icon matSuffix 
            *ngIf="dynamicForm.get(idControl)?.value" 
            (click)="limpiarRespuesta(idControl)">
            clear
          </mat-icon>
          <mat-error *ngIf="validarControl()">
            Esta respuesta es <strong>requerida</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>
</div>
