import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CobradorFacturacionPeriodo, CFPPagination } from './cobrador-facturacion-periodo.interface';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { CobradoresFacturacionesPeriodosService } from './cobradores-facturaciones-periodos.service';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {ConfirmacionDialog} from "../../modales-genericos/confirmacion/confirmacion-dialog.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

@Component({
  selector: 'app-cobradores-facturaciones-periodos',
  templateUrl: './cobradores-facturaciones-periodos.component.html',
  styleUrls: ['./cobradores-facturaciones-periodos.component.css']
})
export class CobradoresFacturacionesPeriodosComponent implements OnInit, OnDestroy {

  private querySub: Subscription;
  public selectedRow: CobradorFacturacionPeriodo;
  public pagination: CFPPagination;
  public pageSize = 50;
  public cargandoLista: boolean = false;
  hijosActivos: boolean = false;
  ayuda: boolean = false;
  public itemsSelected: any[] = [];
  tabla: any;
  periodos: any;
  filtrosAbiertos: boolean = false;
  public filter = false;
  
  public filtros = {
    nombre: null,
    page: 1,
    sortField: 'idCobradorFacturacionPeriodo',
    sortAsc: ''
  }

  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  constructor(
    private route: ActivatedRoute,
    private app: ApplicationService,
    public periodoServ: CobradoresFacturacionesPeriodosService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.periodos = [];
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
   }

  ngOnInit() {
    this.periodoServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resupuestaFiltros.bind(this));
  }

  crearTabla() {
    this.tabla = {
        headers: false,
        multiSelect: false,
        columns: [
            {
                name: 'Nombre',
                key: 'nombre',
                hide: false
            },
            {
                name: 'Fecha Inicio',
                key: 'fecInicio',
                hide: false,
                type: 'date'
            },
            {
                name: 'Fecha Fin',
                key: 'fecFin',
                hide: false,
                type: 'date'
            }
        ]
    }
  }

  ngOnDestroy() {
  }

  setSortField(sort: any){
    this.filtros.sortAsc = sort.direction;
    if (sort.active != this.filtros.sortField) {
      this.filtros.sortField = sort.active;
    }
    this.obtenerPeriodos(1);
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'nombre'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  queryParamFiltros(page: number) {
    const campos = '"idCobradorFacturacionPeriodo","fecInicio","fecFin","idUsuarioReg","nombre"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.filtros.sortField}${this.filtros.sortAsc ? ' ' + this.filtros.sortAsc : ''}`;
    queryParams += this.filtros.nombre ? `&nombre=${this.filtros.nombre}` : '';
    return queryParams;
  }

  public obtenerPeriodos(page: number) {
    this.itemsSelected= [];
    this.cargandoLista = true;
    const queryParams = this.queryParamFiltros(page);

    this.periodoServ.obtenerPeriodos(queryParams).subscribe(
      (res: any) => {
        if(res.success){
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos, 
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          this.periodos = datos;
        }
        this.cargandoLista = false;
        
      },(error) => {
            this.app.showError(error);
            this.cargandoLista = false;
        },
    );
  }

  resupuestaFiltros(filtrosNuevos: any) {
    if(filtrosNuevos){
      this.filter = true;
      this.filtros = filtrosNuevos;
    }
    this.obtenerPeriodos(this.filtros.page || 1);
  }


  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
        this.obtenerPeriodos(newPagina.pageIndex+1);
    }
  }

  onEdit(rowSeleccionado: any) {
    const msg = !rowSeleccionado ? 'Debes seleccionar un registro para editarlo.' : undefined;

    if (!msg) {
      this.periodoServ.setPeriodoData(this.selectedRow);

      this.router.navigate(
        ['editar', { 'id': rowSeleccionado.idCobradorFacturacionPeriodo }],
        {
          relativeTo: this.route,
          queryParamsHandling: 'preserve'
        }).then(() => {
          this.itemsSelected = undefined;
        });
    } else {
      this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }
  }

  onDelete(rowSeleccionado: any) {
    const msg = !rowSeleccionado ? 'Debes seleccionar un registro para borrarlo.' : undefined;

    if (!msg) {
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas borrar periodo de facturación?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                texto: rowSeleccionado.nombre,
                claseAccion: "boton-accion-eliminar"
            }
        });
  
        dialogRef.afterClosed().subscribe(result => {
  
            if(result) {
              this.eliminarRegistro(rowSeleccionado);
            }  
        });
    } else {
      this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }
  }

  eliminarRegistro(rowSeleccionado: any) {
    const loading = this.app.showLoading(
      `Eliminando información...`
    );
    this.periodoServ.eliminarPeriodo(rowSeleccionado.idCobradorFacturacionPeriodo)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.app.showSnackbar('¡Aviso!', 'Concepto borrado correctamente.', 3000, 'success');
        const page = +this.filtros.page || 1;
        this.obtenerPeriodos(page);
        this.app.hideLoading(loading);
      }, (error: any) => {
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  onDoubleClick({ row }) {
    this.onEdit(row);
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  public filterModal(parametro : boolean) {
    if(parametro){
      Object.keys(this.filtros).forEach(key => {
        if(key != 'page' && this.filtros[key]){
          this.filter = true;
        }
      });
      
      if (this.filter) {
        this.filtros.page = 1;
        this.periodoServ.filtrosService.guardarFiltros(this.filtros);
        this.obtenerPeriodos(1);
      }

    }else{
      if (!this.filter) {
        return;
      }

      this.filter = false;
      this.removerFiltros();
      this.filtros.page = 1;

      //this.obtenerPeriodos(1);
    }

    this.contabilizadorDeFiltrosTotales();

  }

  removerFiltros(){
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
    this.filter = false;
  }

  inactivarHijo(){
    this.periodoServ.filtrosService.asignarOpcion();
    if(this.filter){
      this.periodoServ.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.periodoServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resupuestaFiltros.bind(this));
  }

}
