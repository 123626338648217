<mat-card>
  <mat-card-content>
    <h3 class="import-title">¿Qué acción quieres realizar para cuentas de {{ product.name }}?</h3>
    <h5 class="import-title" style="font-size: 14px !important;">¿Necesitas ayuda para comenzar?</h5>

    <div class="import-helps">
        <a mat-button  href="{{urlHelp}}/guia-de-importacion" target="_blank">Ver guía de importación</a>
        <a mat-button  (click)="download()" *ngIf="!mostrarFiltros">Descargar hoja de cálculo de ejemplo</a>
        <a mat-button  (click)="showFilter()" *ngIf="mostrarFiltros">Descargar hoja de cálculo de ejemplo</a>
        <a mat-button  href="{{urlHelp}}/preguntas-frecuentes-en-la-importacion" target="_blank">Ver las preguntas frecuentes</a>
    </div>
    <app-loader *ngIf="isLoad" [message]="'Cargando informacíon...'"></app-loader>
    <form *ngIf="!isLoad" [formGroup]="form" class="contenido">
        <mat-radio-group formControlName="id">
            <div class="import-container">
              <mat-card [ngClass]="{ 'import-card': true, 'import-selected': form.controls['id'].value === type.id }" *ngFor="let type of types" (click)="change(type)">
                  <mat-radio-button [value]="type.id" [checked]="form.controls['id'].value === type.id" class="import-radio"></mat-radio-button>
                  <mat-card-content >
                    <div style="text-align: center;">
                      <img mat-card-sm-image
                      srcset="
                          /assets/import/types/{{ type.id }}@3x.png 3x,
                          /assets/import/types/{{ type.id }}@2x.png 2x
                      "
                      src="/assets/import/types/{{ type.id }}.png"
                      >
                    </div>
                  <span style="font-size: 15px; font-weight: bold;">{{ type.name }}</span>
                  </mat-card-content>
              </mat-card>
          </div>
      </mat-radio-group>
    </form>
  </mat-card-content>
</mat-card>

