import { VisitaDetalleCoordenadas } from "./../modelos/visita-detalle-cordenadas.model";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { environmentSelector } from "../../../environments/environment.selector";
import  moment from 'moment';
import mapboxgl from 'mapbox-gl';

declare var $: any;

@Component({
  // moduleId: module.id,
  selector: "mapa-detalle",
  templateUrl: "mapa-detalle.component.html",
  styleUrls: ["mapa-detalle.component.css"],
})
export class MapaDetalleComponent implements OnInit, OnChanges {
  private environment = environmentSelector();

  @Input() public coordenadas: VisitaDetalleCoordenadas[];
  //@Input() private tiposCoordenada: any;
  zoomGeneral = 4;
  geojson = [];
  geojsonLineal = [];
  private map: any;
  constructor() {
    mapboxgl.accessToken = this.environment.tokenMapbox;
  }

  public ngOnInit() {
    this.showMap();
  }

  public ngOnChanges(changes) {
    this.reloadSource();
  }

  private showMap(): void {
    let lontiguitud: any;
    let latitud: any;
    let feature = [];
    const poiOptions = {
      "width": 32,
      "height": 32,
      "x": 0,
      "y": 0,
      "pixelRatio": 1
  };

    //if(this.clienteCoordenadas)
    {
      lontiguitud = this.environment.paisLng;
      latitud = this.environment.paisLat;
      this.zoomGeneral = 4;
      if (this.coordenadas.length > 0) {
        if (this.coordenadas.length > 0) {
          lontiguitud = this.coordenadas[0].lng;
          latitud = this.coordenadas[0].lat;
          this.zoomGeneral = 16;
        } else {
          lontiguitud = this.environment.paisLng;
          latitud = this.environment.paisLat;
          this.zoomGeneral = 4;
        }
        let coordenadasAcomodadas = [];
        this.coordenadas.forEach((coordenada) => {
          this.acomodarGeoJson(coordenada);
          //console.log(this.geojson);
          /*8 es la ubicacion de la cuenta original no se debe tomar
          en cuenta en el trazo del agente ya que es el punto de 
          referencia para sus ubicaciones*/
          if(coordenada.tipoCoordenada != 8){
            coordenadasAcomodadas.push(coordenada);
          }
        });
        coordenadasAcomodadas.sort((a,b) =>{
          if ( a.fecDispositivo < b.fecDispositivo ){
            return -1;
          }
          if ( a.fecDispositivo > b.fecDispositivo ){
            return 1;
          }
          return 0;
        });
        coordenadasAcomodadas.forEach((visitaCoordenada) => {
          this.geojsonLineal.push([visitaCoordenada.lng, visitaCoordenada.lat]);
        });
      }

      this.map = new mapboxgl.Map({
        container: "map-detalle", // container id
        style: "mapbox://styles/mapbox/streets-v12", //stylesheet location
        //center: [-122.08279655087183, 37.420427798179354], // starting position
        center: [lontiguitud, latitud],
        zoom: this.zoomGeneral, // starting zoom,
        ...poiOptions
      });

      this.map.on("load", () => {
        var index = 0;
        this.map.loadImage('./assets/img/kobra-verde.png', (error, kobra) => {
          if (error) throw error;

          this.map.addImage(`kobra`, kobra);

          this.map.loadImage('./assets/img/kobra-amarilla.png', (error, kobraAmarilla) => {
            if (error) throw error;
    
            this.map.addImage(`kobraAmarilla`, kobraAmarilla);
            this.map.loadImage('./assets/img/arrow-map.png', (error, arrow) => {
              if (error) throw error;
              this.map.addImage('arrow', arrow);

              this.map.addSource('recorridoVisitaLinealSource', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        // These coordinates outline Maine.
                        'coordinates': this.geojsonLineal
                    }
                }
              });
    
              this.map.addLayer({
                'id': 'recorridoVisitaLineal',
                'type': 'line',
                'source': 'recorridoVisitaLinealSource',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#060D37',
                    'line-opacity': .20,
                    'line-width': 1
                }
              });
    
              this.map.addLayer({
                'id': 'recorridosDirectionLineales',
                'type': 'symbol',
                'source': 'recorridoVisitaLinealSource',
                'layout': {
                    'symbol-placement': 'line',
                    'symbol-spacing': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-image': 'arrow',
                    "icon-size": 0.30,
                    'visibility': 'visible'
                },
                'paint': {
                    'icon-opacity': 0.20
                }
              });
    
              this.map.addLayer(
                this.agregarLayer(
                  'recorridoVisita',
                  this.geojson
                )
              );
            });
          });
        });
      });
    }
  }

  public reloadSource() {
    if (this.map) {
      /*for (let index = 0; index < this.tiposCoordenada.length; index++) {
        const tipoCoordenada = this.tiposCoordenada[index];
        tipoCoordenada.geoJson = [];
      }*/

      this.geojson = [];
      this.geojsonLineal = [];
      let coordenadasAcomodadas = [];
      this.coordenadas.forEach((coordenada) => {
        this.acomodarGeoJson(coordenada);
        //console.log(this.geojson);
        /*8 es la ubicacion de la cuenta original no se debe tomar
          en cuenta en el trazo del agente ya que es el punto de 
          referencia para sus ubicaciones*/
        if(coordenada.tipoCoordenada != 8){
          coordenadasAcomodadas.push(coordenada);
        }
      });
      coordenadasAcomodadas.sort((a,b) =>{
        if ( a.fecDispositivo < b.fecDispositivo ){
          return -1;
        }
        if ( a.fecDispositivo > b.fecDispositivo ){
          return 1;
        }
        return 0;
      });
      coordenadasAcomodadas.forEach((visitaCoordenada) => {
        this.geojsonLineal.push([visitaCoordenada.lng, visitaCoordenada.lat]);
      });
      let source = this.map.getSource(`recorridoVisitaSource`);
      let sourceLinear = this.map.getSource(`recorridoVisitaLinealSource`);
      /*for (let index = 0; index < this.tiposCoordenada.length; index++) {
        const tipoCoordenada = this.tiposCoordenada[index];
        let source = this.map.getSource(`puntos${tipoCoordenada.palabraClave}Source`);*/
        if(source){
          source.setData({
            type: "FeatureCollection",
            features: this.geojson
          });
        }
      //}

      if(sourceLinear){
        sourceLinear.setData({
          type: "FeatureCollection",
          'features': [{
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': this.geojsonLineal
            }
          }]
        });
      }
      let ponerZoom = false;
      if (this.coordenadas.length > 0) {
        
        if(this.zoomGeneral == 4){
          this.zoomGeneral = 16;
          ponerZoom = true;
        }
        this.flyTo(
          this.coordenadas[this.coordenadas.length - 1].lat,
          this.coordenadas[this.coordenadas.length - 1].lng,
          this.zoomGeneral,
          ponerZoom
        );
      } else {
        this.zoomGeneral = 4;
        this.flyTo(
          this.environment.paisLat,
          this.environment.paisLng,
          this.zoomGeneral,
          true
        );
      }
    }
  }

  flyTo(lat, lng, zoom, ponerZoom){
    this.map.flyTo(ponerZoom ? {
      zoom: zoom,
      center: [lng,lat]
    } : {
      center: [lng,lat]
    });
  }

  /*loadImage(nombreLayer, nombreImagen, rutaImagen, geojson){
    this.map.loadImage(rutaImagen, (error, kobra) => {
      if (error) throw error;

      this.map.addImage(nombreImagen, kobra);
      this.map.addLayer(
        this.agregarLayer(
          nombreLayer,
          nombreImagen,
          geojson
        )
      );
    });
  }*/

  /*loadImage(index: number){
    if(index < this.tiposCoordenada.length){
      let tipoActual = this.tiposCoordenada[index];
      this.map.loadImage(tipoActual.icono, (error, kobra) => {
        if (error) throw error;
  
        this.map.addImage(`kobra${tipoActual.palabraClave}`, kobra);
        this.loadImage(index+1);
      });
    }
    else{
      this.map.addLayer(
        this.agregarLayer(
          'recorridoVisita',
          this.geojson
        )
      );
    }
  }*/

  acomodarGeoJson(coordenada: VisitaDetalleCoordenadas) {
    this.geojson.push(this.agregarGeoJson(coordenada));
  }

  agregarLayer(layer, geoJson) {
    this.map.addSource(layer + "Source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: geoJson,
      },
    });
    return {
      id: layer,
      type: "symbol",
      source: layer + "Source",
      layout: {
        'text-field': [
          'format',
          ['get', 'description'],
          { 'font-scale': 1.0 }, // Change the font size for the description
          '\n',
          {},
          ['get', 'fecha'],
          { 'font-scale': 0.75 }// Change the font size for the date
        ], 
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        //'text-size': ['concat', '16'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'icon-image': ['get', 'icon'],
        "icon-size": 0.05,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        'text-allow-overlap': true
      },
    };
  }

  agregarGeoJson(coordenada: VisitaDetalleCoordenadas) {
    var geoJsonToAdd = {
      'type': 'Feature',
      'properties': {
        'icon': coordenada.coordenadaOriginal ? `kobra` : 'kobraAmarilla',
        'description': coordenada.nombreCoordenada,
        'fecha': coordenada.tipoCoordenada == 8 ? '' : moment(coordenada.fecDispositivo).format('DD/MM/YYYY HH:mm:ss')
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          coordenada.lng,
          coordenada.lat
        ]
      }
    }
    return geoJsonToAdd;
  }
}
