
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder} from '@angular/forms';
import { Lender } from '../../lender/lender.model';
import { LenderService } from '../../lender/lender.service';
import { Revisita } from './models/revisita.model';
import { VisitFilter } from '../visit-filter.model';
import { RevisitaPaginator } from './paginators/revisita.paginator';
import { VisitService } from '../visit.service';
import { environmentSelector } from '../../../environments/environment.selector';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Subscription, retry } from 'rxjs';
import { AgentService } from '@agent/agent.service';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http'; // Importar HttpClient
import { saveAs } from 'file-saver';
import { LoadingService } from '../../shared/components/loading-bar/loading-bar.service';
import moment from 'moment';



declare var $: any;

@Component({
    // moduleId: module.id,
    selector: 'revisita-lista',
    templateUrl: 'revisita-lista.component.html',
    styleUrls: [
        'revisita-lista.component.css'
    ],
    providers: [
        LenderService,
        VisitService,
        AgentService
    ]
})

export class RevisitListComponent implements OnInit {
    filtrosAbiertos = false;
    sessionData: SessionData;
    hijosActivos: boolean = false;
    public checklistForm: FormGroup;
    private $filterModal: any;
    pageSize: number = 50;
    private rows: number = 100;
    private slt: string = '"idExternoCliente", "idFinanciera", "idCliente", subIdExterno", "idExterno", "nombreProducto","nombreAcreditado", "apePatAcreditado", "apeMatAcreditado"' +
        ', "nombreAgente", "apePatAgente", "apeMatAgente", "comentario", "fecVisitaRegistro", "folioVisita", "idCobrador", "nombreAdministrador"' +
        ', "nombreEstatus", "origenRevisitaNombre", "idEstatusPP", "revisitaGestor", "fecha", "idEtiquetaVisita", "nombreFinanciera"';
    public visibility: string = null;
    ayuda = false;
    public queryParamsGlobal: string;
    private environment = environmentSelector();
    public idLender: number = null;
    public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;
    public filtros = {
        folioVisita: null,
        idProducto: null,
        idOrigenRevisita: null,
        idEstatus: null,
        fecRegistroInicio: null,
        fecRegistroFin: null,
        fecFinalizadaInicio: null,
        fecFinalizadaFin: null,
        idCliente: null,
        agente: null,
        acreditado: null,
        financiera: null,
        excludeFinancierasDemo: true,
        page: 1
    };

    public loadings = {
        productos: false,
        origenes: false,
        estatus: false
    };

    public flagFilter: number;
    public httpError: any;
    public lenders: Lender[];
    public listFields: any = {};
    public listFiltered: boolean;
    public sortField: string;
    public sortAsc: boolean;
    public today: Date = new Date();
    public visitPaginator: RevisitaPaginator;
    public visits: Revisita[];
    public visitFilter: VisitFilter;
    public filterAgentAvailable: boolean;
    public isAllSelected = false;

    public pais: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelCodigoPostal: string;

    public products: Array<any>;
    public origenes: Array<any>;
    public estatusRevisitas: Array<any>;
    cargandoLista= false;
    tablaVisitas: any;

    constructor(
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private lenderService: LenderService,
        private rootElement: ElementRef,
        private router: ActivatedRoute,
        private routeNavigate: Router,
        private visitService: VisitService,
        private agenteServ: AgentService,
        private http: HttpClient,
        private loadingService: LoadingService
    ) {
        this.visits= [];
        this.crearTabla();
        this.visitFilter = new VisitFilter();
        this.visitPaginator = new RevisitaPaginator();
        this.sessionData = this.localStorageService.getSessionData();
        this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
    }

    private subscribeRouterParams(filtrosNuevos: any): void {
        this.initListColumns();

        if (filtrosNuevos) {
            this.listFiltered = true;
            this.filtros = filtrosNuevos;

            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;

            this.visitPaginator.pagination.currentPage = this.filtros.page || 1;
        }
        this.loadLenders();

        if (!this.sessionData.isSuperAdmin) {
            this.filtros.excludeFinancierasDemo = false;
        }
        if (this.sessionData.isSuperAdmin) {
            this.cargarCosasFinanciera();
            this.readHashParams(this.listFiltered);
        }
    }

    ngOnInit() {
        this.sortField = 'vsr.fec_registro';
        this.sortAsc = false;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.pais = this.environment.pais;
        this.labelCodigoPostal = this.environment.labelCodigoPostal;
        this.filterAgentAvailable = true;
    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'idCliente',
            'excludeFinancierasDemo'
        ]);
      
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'folioVisita',
            'idProducto',
            'idOrigenRevisita',
            'idEstatus'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'acreditado'
        ]);
  
        this.contabilizadorFiltros.add('Fechas solicitud', 'fechasS', [
            'fecRegistroInicio',
            'fecRegistroFin'
        ]);

        this.contabilizadorFiltros.add('Fechas visita', 'fechasV', [
            'fecFinalizadaInicio',
            'fecFinalizadaFin'
        ]);

        this.contabilizadorFiltros.add('Fechas total', 'fechasTotal', [
            'fecRegistroInicio',
            'fecRegistroFin',
            'fecFinalizadaInicio',
            'fecFinalizadaFin'
        ]);

        this.contabilizadorFiltros.add('Agente', 'agente', [
            'agente',
            'filterAgentAvailable'
        ]);
    }
  
    public contabilizadorDeFiltrosTotales():void{
        
  
        let totalFiltrosArray: number[] = [];
        if(this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('cuenta') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasS') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasV') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );
  
        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
  
    public contabilizadorDeFiltros(filtroName: string){
      return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
    }
  

    crearTabla() {
        this.tablaVisitas = {
            headers: false,
            multiSelect: false,
            columns: [{
                name: 'Cliente',
                key: 'nombreFinanciera',
                hide: false
            },
            {
                name: 'Folio',
                key: 'folio',
                hide: false,
                link: '/revisit-request/visit/',
                type: 'link'
            },
            {
                name: 'ID Cuenta',
                key: 'idExternoCliente',
                hide: false,
                type: 'link'
            },
            {
                name: 'ID SubCuenta',
                key: 'subIdExterno',
                hide: false
            }, {
                name: 'Cuenta',
                key: 'nombreCompletoAcreditado',
                hide: false
            }, {
                name: 'Folio Domicilio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Producto',
                key: 'nombreProducto',
                hide: false
            }, {
                name: 'Agente',
                key: 'nombreCompletoAgente',
                hide: false,
                type: 'link'
                
            }, {
                name: 'Estatus',
                key: 'nombreEstatus',
                hide: false,
                type: 'estatus'
            }, {
                name: 'Origen',
                key: 'origenRevisitaNombre',
                hide: false
            }, {
                name: 'Administrador',
                key: 'nombreAdministrador',
                hide: false
            }, {
                name: 'Comentario',
                key: 'comentario',
                hide: false
            }, {
                name: 'Fecha',
                key: 'fecha',
                hide: false,
                type: 'dateTime'
            }
            ]
        };
    }

    private cargarCosasFinanciera() {
        this.loadProducts();
        this.loadOrigenes();
        this.loadEstatusRevisitas();
    }

    public clearFilter(): void {

        Object.keys(this.filtros).forEach(key => {
            if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')) {
                this.filtros[key] = null;
            }
        });

        this.filtros.excludeFinancierasDemo = this.sessionData.isSuperAdmin ? true : false;
        this.filtros.page = 1;
        this.filtrosService.removerFiltros();
        this.idLender = null;
        this.listFiltered = false;
        //this.loadVisits(this.filtros.page);
    }

    private crearURLQuery(): string {
        this.queryParamsGlobal = '';

        if (this.slt) {
            this.queryParamsGlobal += '&slt=' + this.slt;
        }

        if (this.sortField) {
            this.queryParamsGlobal += '&orderBy=' + this.sortField + (this.sortAsc ? ' asc' : ' desc');
        }

        if (this.filtros.folioVisita) {
            this.queryParamsGlobal += `&folioVisita=${this.filtros.folioVisita.toUpperCase()}`;
        }

        if (this.filtros.fecRegistroInicio) {
            this.queryParamsGlobal += '&fromDate=' + this.filtros.fecRegistroInicio.toISOString().substr(0, 10);
        }

        if (this.filtros.fecRegistroFin) {
            this.queryParamsGlobal += '&toDate=' + this.filtros.fecRegistroFin.toISOString().substr(0, 10);
        }

        if (this.filtros.fecFinalizadaInicio) {
            this.queryParamsGlobal += '&fromDateVisit=' + this.filtros.fecFinalizadaInicio.toISOString().substr(0, 10);
        }

        if (this.filtros.fecFinalizadaFin) {
            this.queryParamsGlobal += '&toDateVisit=' + this.filtros.fecFinalizadaFin.toISOString().substr(0, 10);
        }

        if (this.filtros.agente) {
            this.queryParamsGlobal += `&idCobrador=${this.filtros.agente.idCobrador}`;
        }

        if (this.filtros.financiera) {
            this.queryParamsGlobal += `&idCliente=${this.filtros.financiera.idCliente}`;
        }

        if (this.filtros.idProducto) {
            this.queryParamsGlobal += `&idProduct=${this.filtros.idProducto}`;
        }

        if (this.filtros.idOrigenRevisita) {
            this.queryParamsGlobal += `&idOrigenRevisita=${this.filtros.idOrigenRevisita}`;
        }

        if (this.filtros.idEstatus) {
            this.queryParamsGlobal += `&idEstatus=${this.filtros.idEstatus}`;
        }

        if (this.filtros.acreditado) {
            this.queryParamsGlobal += `&idAcreditado=${this.filtros.acreditado.idAcreditado}`;
            //this.queryParamsGlobal += `&idCliente=${this.filtros.idCliente}`;
        }

        if (this.sessionData.isSuperAdmin && this.filtros.excludeFinancierasDemo) {
            this.queryParamsGlobal += `&excludeFinancierasDemo=${this.filtros.excludeFinancierasDemo}`;
        }


        return this.queryParamsGlobal;
    }

    public descargarListado(downloadType: string): void {
        if (this.visits.length == 0) {
            this.app.showSnackbar(
                "Aviso",
                "No se encontraron registros para descargar.",
                3000,
                "warning"
            );
            return;
        }
        this.loadingService.setLoading(true);  // Asegúrate de que loadingService esté inyectado en tu componente
    
        let queryParams = '?start=0' + this.queryParamsGlobal;
        queryParams += '&rows=' + this.pageSize;
        queryParams += `&downloadType=${downloadType}`;
    
       this.visitService.descargarRevisitaListado(queryParams).subscribe(event => {
            if (event.type === HttpEventType.Response) {
                this.loadingService.setLoading(false);
                const blob = new Blob([event.body], { type: event.body.type });
                const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
                saveAs(blob, `Solicitudes-revisita-${fechaActual}`);
            }
        }, error => {
            this.loadingService.setLoading(false);
            this.app.showSnackbar(
                "Aviso",
                "Ocurrió un problema al intentar descargar el archivo.",
                3000,
                "error"
            );
            console.log(error);
        });
    }

    private initListColumns(): void {
        var listFields: any = JSON.parse(localStorage.getItem('visitListFields')) || {};

        //this.listFields.visitID = listFields.visitID == false ? false : true;
        this.listFields.lender = listFields.lender == false ? false : true;
        this.listFields.visitFolio = listFields.visitFolio == false ? false : true;
        this.listFields.productType = listFields.productType == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.nombreAcreditado = listFields.nombreAcreditado == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.estatus = listFields.estatus == false ? false : true;
        this.listFields.origenRevisita = listFields.origenRevisita == false ? false : true;
        this.listFields.nombreAdministrador = listFields.nombreAdministrador == false ? false : true;
        this.listFields.comentario = listFields.comentario == false ? false : true;
        this.listFields.fechaSolicitud = listFields.fechaSolicitud == false ? false : true;
    }

    private initNulls(visits: Revisita[]): void {
        for (var i = 0; i < visits.length; i++) {
            var visit: Revisita = visits[i];
        }
    }

    public loadLenders(): void {

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;
                if (!this.sessionData.isSuperAdmin) {
                    this.loadFiltersDefault();
                    this.onChangeLender(this.idLender);
                    this.cargarCosasFinanciera();
                    this.readHashParams(true);
                }
            },
            err => {
                this.app.showError(err);
                
            }
        );
    }

    loadFiltersDefault() {
        this.idLender = this.sessionData.idFinanciera;
        this.filtros.fecRegistroFin = new Date();
        this.filtros.fecRegistroInicio = new Date();
        this.filtros.fecRegistroInicio.setMonth(this.filtros.fecRegistroInicio.getMonth() - 1);
        this.filtros.excludeFinancierasDemo = false;
    }

    loadProducts() {
        this.loadings.productos = true;

        if (this.sessionData.isSuperAdmin) {
            this.lenderService.obtenerProductos()
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadings.productos = false;
                    this.products = res.data;
                }, (err: any) => {
                    this.loadings.productos = false;
                    this.app.showError(err);
                });
        } else {
            this.lenderService.obtenerProductosCliente(this.sessionData.idCliente)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadings.productos = false;
                    this.products = res.data;
                }, (err: any) => {
                    this.loadings.productos = false;
                    this.app.showError(err);
                });
        }

    }

    loadOrigenes() {
        this.origenes = [];
        let uri = '/origenes-revisitas';
        this.loadings.origenes = true;

        this.visitService.get(uri, true)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadings.origenes = false;
                this.origenes = res;
            }, (err: any) => {
                this.loadings.origenes = false;
                this.app.showError(err);
            });
    }

    loadEstatusRevisitas() {
        this.estatusRevisitas = [];
        let uri = '/estatus?idGrupoEstatus=6';
        this.loadings.estatus = true;

        this.visitService.get(uri, true)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadings.estatus = false;
                this.estatusRevisitas = res;
            }, (err: any) => {
                this.loadings.estatus = false;
                this.app.showError(err);
            }); this.estatusRevisitas = [];
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                    if (lender.financieraDemo) {
                        this.filtros.excludeFinancierasDemo = false;
                    }
                    else {
                        this.filtros.excludeFinancierasDemo = true;
                    }
                }
            });
            this.loadOrigenes();
            this.loadEstatusRevisitas();
        }
        else {
            this.filtros.financiera = null;
        }

    }

    public loadVisits(page?: number): void {
        if (!this.sortField) {
            this.sortField = 'vsr.fec_registro';
        }
        let queryParams: string = '&rows=' + this.pageSize;
        queryParams += this.crearURLQuery();
        this.visitPaginator.pagination.nextPage = page || 1;

        this.visitPaginator.pagination.currentPage = page;
        if (!page) {
            page = 1;
        }

        if (page != this.filtros.page) {
            this.filtros.page = page;
            this.filtrosService.guardarFiltros(this.filtros);
        }

        queryParams += '&page=' + page;
        queryParams += '&paginate=true';
        //let loading: number = this.app.showLoading('Cargando visitas...');
        this.cargandoLista = true;

        let subscription: Subscription = this.visitService.obtenerRevisitas(this.visitPaginator.pagination, queryParams, this.pageSize).subscribe(

            (paginator: RevisitaPaginator) => {
                this.visitPaginator = paginator;
                var visits: Revisita[] = paginator.data;
                this.initNulls(visits);
                this.visits = visits;
                this.contabilizadorDeFiltrosTotales();

            },
            (error) => {
                this.app.showError(error);
                this.cargandoLista = false;
                subscription.unsubscribe();
            },
            () => {
                this.cargandoLista = false;
                subscription.unsubscribe();
            }
        );
    }

    public readHashParams(load: boolean) {

        /*if (!load) {
            return false;
        }*/
        if(load){
            this.loadVisits(this.visitPaginator.pagination.currentPage > 0 ? this.visitPaginator.pagination.currentPage : 1);
        }
        
    }

    public saveInStorage(): void {
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    public setFilter(): void {
        this.listFiltered = true;
        this.filtros.page = 1;
        this.filtrosService.guardarFiltros(this.filtros);
        this.loadVisits(1);
        this.contabilizadorDeFiltrosTotales();

    }

    inactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filtros && this.filtros.financiera){
            this.filtrosService.guardarFiltros(this.filtros, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
    }

    public setSortField(field: string) {
        if (field == this.sortField) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortField = field;
            this.sortAsc = true;
        }
        this.loadVisits(1);
    }

    public validateAgentID(event: any): void {
    }

    resetFinancieraFilter() {
        this.idLender = null;
        this.filtros.financiera = null;
        this.filtros.excludeFinancierasDemo = true;
    }

    onClick({ row, columnPressed }) {

        if(columnPressed === 'idExternoCliente') {
             this.linkDetalleCuenta(row);
         } else if(columnPressed === 'folio') {
             this.LinkVisitas(row);
         }else if(columnPressed === 'nombreCompletoAgente'){
             this. linkDetalleAgente(row);
         }
     }
 
     linkDetalleCuenta(cuenta: any) {
         let queryString = `?idExterno=${cuenta.idExternoCliente}`;
         queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";
 
         if(this.sessionData.isSuperAdmin){
             queryString += "&idCliente="  + cuenta.idCliente;
         };
         this.routeNavigate.navigateByUrl('/revisit-request/cuenta' + queryString);
         
     }
 
     LinkVisitas(visita: any) {
         this.routeNavigate.navigate(['/visits/visit/' + visita.folio]);
     }
 
     linkDetalleAgente(data: any) {
         this.routeNavigate.navigateByUrl('/revisit-request/agentes/' + data.idCobrador); 
     }

     onCtrlClick({ row, columnPressed }) {
        if(columnPressed === 'idExternoCliente') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }
    
    ctrlClickLinkDetalleCuenta(cuenta: any) {
        let queryString = `?idExterno=${cuenta.idExternoCliente}`;
         queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";
 
         if(this.sessionData.isSuperAdmin){
             queryString += "&idCliente="  + cuenta.idCliente;
         };
        window.open('/cuentas/domicilios/account' + queryString, '_blank');
    
    }
    

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !==  this.visitPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize){
            this.pageSize = newPagina.pageSize;
            this.loadVisits(newPagina.pageIndex+1);
        }
    }

    public agenteFilterSearch = function(term: string) {
        return this.agenteServ.obtenerAgentes(term, this.filterAgentAvailable)
          .pipe(
            take(1),
            map((res: any) => res.data.map(
              ({ idCobrador, nombreCompleto, idAgente }) => (
                { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
              )
            ))
        );
    }.bind(this);
      
    acreditadoFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera && this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera: null)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAcreditado, nombreCompleto, idCliente, idExterno}) => (
                    { idAcreditado: idAcreditado, nombre: idExterno+' - '+nombreCompleto, idCliente: idCliente }
                    )
                ))
            );
    }.bind(this);
}
