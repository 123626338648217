import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService implements CanActivate {

  constructor(
    private router: Router,
    private leanServ: ServiciosKobraService
  ) { }

  canActivate(): boolean {
    var loggedIn: boolean = false;
    if (localStorage.getItem('access-token')) {
      loggedIn = true;
    } else {
      this.router.navigate(['/auth']);
    }
    return loggedIn;
  }

  reloadBaseUrl(archivo:string = ""){
    this.leanServ.reloadBaseUrl(archivo);
  }

  logIn(email, contrasena): Observable<any>{
    let data = {
      email: email,
      contrasena: contrasena,
      idAplicacion: 2
    };
    
    let response = this.leanServ.post('/login', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }

  loginUpdate(): Observable<any>{
    let data = {
      idAplicacion: 2
    };

    let response = this.leanServ.post('/login/update', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }
}