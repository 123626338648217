export class Bank {
  id: number;
  name: string;

  public static map(data : any): Bank{
    let instance: Bank = new Bank();
    instance.id = data.id ? data.id : data.idBanco ? data.idBanco : null;
    instance.name = data.name ? data.name : data.nombre ? data.nombre : null;
    return instance;
  }

  public static mapArray(data: any[]): Bank[]{
    let instances: Bank[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(Bank.map(data[i]));
    }
    return instances;
  }
}