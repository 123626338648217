<mat-card>
	<h3 class="import-title">¿Qué tipo de producto desea importar?</h3>
	<mat-card-content>
		<app-loader *ngIf="isLoad" [message]="'Cargando productos...'"></app-loader>
		<form class="contenido"  *ngIf="!isLoad" [formGroup]="form">
		  <mat-radio-group formControlName="id">
			<div class="import-container">
			  <mat-card
				[ngClass]="{ 'import-card': true, 'import-selected': form.controls['id'].value === product.id }"
				*ngFor="let product of products"
				(click)="change(product)"
				[matTooltip] = "product.description"
                mat-tooltip-panel-above
			  >
				<div class="card-content">
				  <span class="idProducto">ID: {{ product.id }}</span>
				  <mat-radio-button [value]="product['id']" [checked]="form.controls['id'].value === product.id" class="import-radio"></mat-radio-button>
				  
				  
				  <mat-card-content >
					<div>
						<img mat-card-sm-image
						srcset="
							/assets/import/products/{{ product.image || 'cobranza' }}@3x.png 3x,
							/assets/import/products/{{ product.image || 'cobranza' }}@2x.png 2x
						"
						src="/assets/import/products/{{ product.image || 'cobranza' }}.png"
						>
				  	</div>

					<span style="font-size: 15px; font-weight: bold;">{{ product.name }}</span>
				  </mat-card-content>
				</div>
			  </mat-card>
			</div>
		  </mat-radio-group>
		</form>
	</mat-card-content>
	
</mat-card>
  