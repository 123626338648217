import { Injectable } from '@angular/core';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private serviciosVisitas: ServicesVisitasService,
    private leanService: ServiciosKobraService
  ) { }

  obtenerTotalVisitasRealizadas(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-visitas-realizadas${query}`);
  }

  obtenerTotalVisitasEnProceso(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-visitas-en-proceso${query}`);
  }

  obtenerTotalVisitasPromesasPago(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-visitas-promesa-pago${query}`);
  }

  obtenerTotalCuentasEnesperaConfirmacion(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-cuentas-en-espera-confirmacion${query}`);
  }

  obtenerTotalCuentasApartadas(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-cuentas-apartadas${query}`);
  }

  obtenerTotalCuentasNuevas(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-cuentas-nuevas${query}`);
  }

  obtenerTotalCuentasAntiguas(fecInicio: any, fecFin: any){
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.leanService.get(`/total-cuentas-antiguas${query}`);
  }

  obtenerTotalCuentasSinCobertura(fecInicio: any, fecFin: any) {
    let query = `?fecInicio=${fecInicio}&fecFin=${fecFin}`;

    return this.serviciosVisitas.get(`/total-cuentas-sin-cobertura${query}`);
  }

  obtenerCuentasPorAsignar(){
    return this.leanService.get('/cuentas-por-asignar-totales');
  }

  obtenerCuentasPorVincular(){
    return this.leanService.get('/cuentas-por-vincular-totales');
  }

  obtenerCuentasCoberturaVinculacion(){
    return this.leanService.get('/cuentas-cobertura-vinculacion');
  }
}
