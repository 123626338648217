import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { FormValidation } from "./form-validation";

@Directive({
  selector: '[formValidationMessage]'
})
export class FormValidationMessageDirective {
  @Input() set formValidationMessage( formValidation: FormValidation) {
    formValidation.isInvalid.subscribe(isInvalid => {
      this.element.nativeElement.innerText = isInvalid ? formValidation.message:'';
    });
  };

  constructor(private element: ElementRef) { }
}
