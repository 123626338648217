import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { CobradoresFacturacionesPeriodosService } from '../../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { ApplicationService } from '../../../application/shared/application.service';
import { CobradoresMovimientosService } from '../../cobradores-movimientos/cobradores-movimientos.service';
import { FileUploadComponent } from '../../../shared/components/file-upload/file-upload.component';
import { CobradorFacturacionPeriodo } from '../../cobradores-facturaciones-periodos/cobrador-facturacion-periodo.interface';
import { map, take } from 'rxjs/operators';
import { UploadFileComponent } from 'src/app/shared/components/upload-file-v2/upload-file.component';



@Component({
  selector: 'importar.component',
  templateUrl: 'importar.component.html',
  styleUrls: ["importar.component.css"],
  providers: [CobradoresFacturacionesPeriodosService, CobradoresMovimientosService]
})
export class ModalImportarComponent {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public archivos = null;
  file: File[] = [];
  public archivoXsl: any = null;
  public isFile: boolean;
  public title = 'Agregar';
  myForm: FormGroup;
 // @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;
  @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;

  public idCobradorFacturacionPeriodo: any;
  acceptFiles: string = '.xlsx';
  fb: FormBuilder;
  idPeriodo: number;

  constructor(
    public dialogRef: MatDialogRef<ModalImportarComponent>,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private app: ApplicationService,
    public movsServ: CobradoresMovimientosService,
    private appService: ApplicationService,
    public dialog: MatDialog
  ) {
    this.file = null;
    this.myForm = new FormGroup({
      idCobradorFacturacionPeriodo: new FormControl(),
    });
  }

  onNoClick(): void {
    this.dialogRef.close({
      success: false,
    });
  }

  onUpload(files: File []) {
    this.file = files;
    this.isFile = true;
    this.onChange.emit(this.file);
  }

  onRemoveFile() {
    this.isFile = false;
    this.file = null;
    this.onChange.emit(false);
    this.uploadFileComponent.removeFile();     
  }

  onErrorFile(error) {
    this.appService.showError(error);
  }

  public periodosSearch = function (term: string) {
    const query = `?nombre=${term}`;
    return this.periodoServ.obtenerPeriodos(query)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: nombre }
          )
        ))
      );
  }.bind(this);

  onPeriodoFilterSelected(periodo: CobradorFacturacionPeriodo) {
    //periodo.idCobradorFacturacionPeriodo = this.myForm.value;
    this.idPeriodo = this.myForm.value.idCobradorFacturacionPeriodo;
  //console.log(this.myForm.value.idCobradorFacturacionPeriodo)
  }
  handleFiles(files: File[]) {
    this.file = files;
  }

  
  

  public subirArchivo() {

    //console.log(this.idPeriodo)
    //console.log(this.file[0])
    if (!this.idPeriodo) {
      this.app.showSnackbar(
        '¡Aviso!',
        'Favor de seleccionar un periodo de facturación.',
        3000,
        'warning'
      );
      return;
    }



    if (!this.file && this.file.length > 0) {
      this.app.showSnackbar(
        '¡Aviso!',
        'Favor de cargar un archivo.',
        3000,
        'warning'
      );
      return;
    }

    const archivo = this.file[0];
    const extension = archivo.name.split('.').pop().toLowerCase();
    const tiposValidos = ['xls', 'xlsx', 'csv'];
  
    if (!tiposValidos.includes(extension)) {
      this.app.showSnackbar(
        '¡Aviso!',
        'El archivo no es un archivo de Excel válido.',
        3000,
        'warning'
      );
      return;
    }
    
    const loading: number = this.app.showLoading('Procesando datos...');
    
    this.movsServ.subirArchivo(this.file[0], this.idPeriodo)
      .pipe(take(1))
      .subscribe((respuesta) => {
        if (respuesta.success) {
          this.onRemoveFile();
          this.app.hideLoading(loading);
          this.app.showSnackbar('¡Aviso!', 'El archivo se ha subido correctamente.', 3000, 'success');
          this.dialogRef.close(true);
        } else {
          this.app.hideLoading(loading);
          this.dialogRef.close(false);
          this.app.showSnackbar('¡Aviso!', 'Error al subir el archivo.', 3000, 'error');
        }
      }, error => {
        this.app.hideLoading(loading);
        this.dialogRef.close(false);
        this.app.showError(error);
      });
  }

  salir(bol: boolean) {
    this.dialogRef.close(bol);
  }

  limpiar() {
    // Agrega la lógica de limpieza si es necesaria
  }
}
