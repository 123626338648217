
<h1 mat-dialog-title>Cambiar etiqueta</h1>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Seleccione la nueva etiqueta</mat-label>
    <mat-select [(ngModel)]="nvaEtiqueta">
      <mat-option *ngFor="let etiqueta of etiquetas" [value]="etiqueta" >
        {{etiqueta.etiquetaVisita}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="cambiarEtiqueta()"><mat-icon>sync</mat-icon>Actualizar</button>
</mat-dialog-actions>