import { Financiera } from './../../../financieras/shared/financiera.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/operators';
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SucursalesService } from '../sucursales.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-sucursales-modificar',
  templateUrl: './sucursales-modificar.component.html',
  styleUrls: ['./sucursales-modificar.component.css'],
  providers: [SucursalesService, LenderService]
})
export class SucursalesModificarComponent implements OnInit {
  sessionData: SessionData;
  title: string = '';
  sucursal: any = {};
  sucursalNva: any = {};
  administradores: any = [];
  adminFinanciera: any = null;
  accion: string = 'Detalle';
  financieras: any = null;

  loadings: any = {
    financieras: true,
    administradoresFinanciera: true,
    administradoresSucursal: false
  };

  requireds: any = {
    financiera: false,
    nombre: false
  };

  tablaAdministradores: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      {
          name: 'Nombre',
          key: 'nombre',
          hide: false
      }, {
          name: 'Primer Apellido',
          key: 'apellidoPaterno',
          hide: false
      }, {
          name: 'Segundo Apellido',
          key: 'apellidoMaterno',
          hide: false
      }, {
          name: 'Email',
          key: 'email',
          hide: false
      }, {
        name: ' ',
        key: 'delete',
        hide: false,
        icono:'delete_forever',
        tooltip: "Eliminar",
        type: 'icono',
      }
    ]
  };
  nombreCliente:string;
  editarPresente: boolean = false;
  constructor(
    private appService: ApplicationService,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService,
    private sucursalesService: SucursalesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.route.url.subscribe(urlSegments => {
      const urlSegmentStrings = urlSegments.map(segment => segment.path);
      this.editarPresente = urlSegmentStrings.includes('editar');
    });

    if(!this.editarPresente){
      this.sucursalNva.idFinanciera = null;
      this.sucursalNva.administradores = [];
    }else{
      this.sucursalNva.modificar = true;
      this.sucursalNva.idFinanciera = +this.route.snapshot.queryParamMap.get('idFinanciera');
      this.sucursalNva.idSucursal = +this.route.snapshot.queryParamMap.get('idSucursal');
      this.sucursalNva.nombreSucursal  = this.route.snapshot.queryParamMap.get('nombreSucursal');
      this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
      if (!this.sucursalNva.idFinanciera || !this.sucursalNva.idSucursal || !this.sucursalNva.nombreSucursal || !this.nombreCliente) {
        this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
        this.router.navigate([`catalogos/sucursales`]);
      }else{
        this.loadAdministradoresSucursal();
      }
      
    }
    this.loadAdministradoresFinanciera();
    this.loadLenders();
  
  }

  validaRequeridos(){
    let requridos = false;
    if(!this.sucursalNva.idFinanciera){
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar financiera.', 3000, 'warning');
      this.requireds.financiera = true;
      requridos = true;
    }

    if(!this.sucursalNva.nombreSucursal || this.sucursalNva.nombreSucursal == ''){
      if(!requridos)
        this.appService.showSnackbar('¡Aviso!', 'Es necesario capturar el nombre de la sucursal.', 3000, 'warning');
      this.requireds.nombre = true;
      requridos = true
    }
    return requridos;
  }

  cancel() {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];

    // Eliminamos todos los parámetros existentes y luego agregamos el parámetro 'f'
    this.router.navigate(['/catalogos/sucursales'], {
      queryParams: { f: fParam }
    });
  }

  save() {
    if(this.validaRequeridos()){
      return;
    }

    const loading = this.appService.showLoading(
      `Estamos guardado los datos de la sucursal ${this.sucursalNva.nombreSucursal}`
    );

    if(this.editarPresente){
      this.sucursalesService.editBranch(this.sucursalNva.idSucursal, this.sucursalNva).subscribe(() => {
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('Aviso', 'Se ha actualizado la sucursal correctamente', 3000, 'success');
        this.cancel();
      }, error => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      });
    }
    else{
      this.sucursalesService.saveBranch(this.sucursalNva).subscribe(() => {
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('Aviso', 'Se ha guardado la sucursal correctamente', 3000, 'success');
        this.cancel();
      }, error => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      });
    }
  }

  agregarAdministrador() {
    let existe: boolean = false;
    if(this.adminFinanciera){
      this.sucursalNva.administradores.forEach(administrador => {
        if(administrador.idAdministradorFinanciera == this.adminFinanciera.idAdministradorFinanciera)
        {
          existe = true;
        }
      });
      if(existe){
        this.appService.showSnackbar('¡Aviso!', 'El administrador seleccionado ya pertenece a esta sucursal.', 3000, 'warning');
      }
      else{
        this.sucursalNva.administradores = [...this.sucursalNva.administradores, this.adminFinanciera];
        this.adminFinanciera = null;
      }
    }
    else{
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar un administrador para agregarlo.', 3000, 'warning');
    }
  }

  borrarAdministrador(itemClick: any){
    //console.log(itemClick);
    if(itemClick.columnPressed == 'delete'){
      let index = this.sucursalNva.administradores.findIndex((administrador) => administrador.idAdministradorFinanciera == itemClick.row.idAdministradorFinanciera);
      if(index !== -1){
        this.sucursalNva.administradores.splice(index, 1);
        this.sucursalNva.administradores = [...this.sucursalNva.administradores];
      }
        
    }
  }

  loadAdministradoresSucursal(){
    this.loadings.administradoresSucursal = true;
    let query: string = '';
    if(this.sucursalNva.idFinanciera && this.sucursalNva.idSucursal){
      query += `?idFinanciera=${this.sucursalNva.idFinanciera}&idSucursal=${this.sucursalNva.idSucursal}`;
      this.sucursalesService.getAdministradoresFinancieraSucurasl(query)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.sucursalNva.administradores = res.data;

        this.loadings.administradoresSucursal = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loadings.administradoresSucursal = false;
      });
    }
    else{
      this.loadings.administradoresSucursal = false;
    }
  }

  loadAdministradoresFinanciera(){
    this.loadings.administradoresFinanciera = true;
    let query: string = '';
    let idFinanciera: number;

    if(!this.sessionData.isSuperAdmin) {
      idFinanciera =  this.sessionData.idFinanciera;
    }else {
      idFinanciera =  this.sucursalNva.idFinanciera;
    }

    if(idFinanciera){
      query += `?idFinanciera=${idFinanciera}&deletedFinanciera=false`;
      this.sucursalesService.getAdministradorFinanciera(query)
      .pipe(
        take(1)
      ).subscribe((res: any) => {

        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          element.nombreCompleto = `${element.nombre}${element.apellidoPaterno ? ' ' + element.apellidoPaterno : ''}${element.apellidoMaterno? ' ' + element.apellidoMaterno : ''}` + ` (${element.email})`;
          this.administradores.push(element);
        }
        //this.administradores = res.data;

        this.loadings.administradoresFinanciera = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loadings.administradoresFinanciera = false;
      });
    }
    else{
      this.loadings.administradoresFinanciera = false;
    }
  }

  public loadLenders(): void {
    this.loadings.financieras = true;

    this.lenderService.getAll('all').subscribe(
        res => {
            this.financieras = res;
            if(!this.sessionData.isSuperAdmin){
              this.sucursalNva.idFinanciera = this.sessionData.idFinanciera;
            }
        },
        err => {
            this.app.showError(err);
            this.loadings.financieras = false;
        },
        () => this.loadings.financieras = false
    );
  }

}
