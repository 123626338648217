import { AgentService } from "../../agent.service";
import { Component, Input, OnInit, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { IAgenteBitacora, IAgenteNvoComentario } from "../../interfaces/agente-bitacora.interface";
import { IPageData } from "src/app/core/http/servicio-general/servicio-general-response";
import { style } from "@angular/animations";

@Component({
  selector: 'historial-alta-baja',
  templateUrl: './historial-alta-baja.component.html',
  styleUrls: ['./historial-alta-baja.component.css'],
  providers: [
    AgentService
  ]
})
export class HistorialAltaBajaComponent implements OnInit, AfterViewInit {
  @Input() idAgente:number;
  @Output() modoAgregarChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('timeline') private timeline: any;
  @ViewChild('timelineBar') private timelineBar: any;
  movimientos: IAgenteBitacora[];
  loadBitacoras: boolean = true;
  modoAgregar:boolean = false;
  nuevoComentario: string = '';
  pagina: number = 1;
  registros: number = 20;
  totalPaginas: number = 0;

  constructor(
    private app: ApplicationService,
    private agentService: AgentService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    this.loadBitacora(1);
    //this.adjustTimeLineBar();
  }

  private loadBitacora(pagina: number) {
    this.loadBitacoras = true;
    this.agentService.getBitacora<IPageData<IAgenteBitacora[]>>(this.idAgente,1,1000).subscribe(
      res => {
        if(res.data.pageData){
          this.movimientos = res.data.pageData;
        }
        this.adjustTimeLineBar();
        this.loadBitacoras = false;
      },
      err => {
        this.loadBitacoras = false;
      },
      () => {
        this.loadBitacoras = false;
      }
    );
  }

  addComment(){
    if(!this.nuevoComentario){
      this.app.showSnackbar(
        "Aviso",
        "Es necesario capturar un comentario.",
        3000,
        "warning"
      );
      return;
    }
    let bitacora: IAgenteNvoComentario = {
      comentarios: this.nuevoComentario
    };
    let loading = this.app.showLoading("Guardando comentario en la bitácora...");
    this.agentService
        .guardarBitacora(this.idAgente, bitacora)
        .pipe(take(1))
        .subscribe
        (
            (respuesta: IAgenteBitacora) => {
              let usuario = JSON.parse(localStorage.getItem('user')).admin;
              let nvaBitacora: IAgenteBitacora = {
                idGrupoEtiqueta: null,
                etiqueta: null,
                nombre: `${usuario.nombre}`,
                apellidoPaterno: `${usuario.apellidoPaterno}`,
                apellidoMaterno: null,
                comentarios: bitacora.comentarios,
                accion: 'comentario',
                banUsuarioAdministrador: true,
                fecRegistro: respuesta.fecRegistro
              }
              this.movimientos.unshift(nvaBitacora);
              this.nuevoComentario = null;
              this.modoAgregar = false;
              this.modoAgregarChange.emit(this.modoAgregar); // Emitir el cambio
              this.app.hideLoading(loading);
            },
            (err: any) => {
                this.app.hideLoading(loading);
                this.app.showError(err);
            }
        );
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement; // Se obtiene el elemento que tiene el scroll
    const scrollHeight = element.scrollHeight;
    const scrollTop = element.scrollTop;
    const clientHeight = element.clientHeight;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight * 100;
    //this.checkColumnsVisibility(element);
    
    if (scrollPercentage >= 99.5 && !this.loadBitacoras && this.pagina < this.totalPaginas) {
      this.loadBitacora(this.pagina+1);
    }
  }

  private adjustTimeLineBar(){
    setTimeout(() => {
      if(this.timeline && this.timelineBar){
        const timeLineElement = this.timeline.nativeElement;
        const timeLineBarElement = this.timelineBar.nativeElement;
        timeLineBarElement.style.height = `${timeLineElement.scrollHeight}px`;
      }
    }, 0);
  }
}
