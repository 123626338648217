export const environment = {
    production: false,
    kobraServices: {
      api: 'https://development.api.kobra.red',
      mobile: 'https://development.mobile.kobra.red',
      services: 'https://development.services.kobra.red',
      servicios: 'https://dev-apps.kobra.red/s1/lean-services/admin',
      serviciosClientes: 'https://dev-apps.kobra.red/s1/lean-services/clientes',
      serviciosSaldosKobra: "https://dev-apps.kobra.red/s1/kobra-saldos/admin",
      storage: 'https://dev-apps.kobra.red/s1/storage/admin',
      visitas: 'https://dev-apps.kobra.red/s1/visitas/admin',
      gestores: 'https://dev-apps.kobra.red/s1/gestores/admin',
      bi: 'https://development.bi.kobra.red/permisos/api',
      notifications: 'https://dev-apps.kobra.red',
      notificationsUri: '/s2/notifications'
    },
    kobraStorage: "https://dev-apps.kobra.red/s1/storage/admin",
    kobraRed: "https://development.kobra.red",
    kobraDashboard: "https://development.dashboard.kobra.red",
    kobraHelp: "https://development.ayuda.kobra.red",
    dashboardLink: "https://development.dashboard.kobra.red/site/login",
    adminLink: "http://development.admin.kobra.red/login",
    tokenMapbox: "pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg",
    saldosAccess: ['all'],
    pais: 'PE',
    country: 'pe',
    labelColonia: 'Urbanización',
    labelColoniaPlural: 'Urbanizaciones',
    labelMunicipio: 'Distrito',
    labelCodigoPostal: 'Código Postal',
    labelMunicipioPlural: 'Distritos',
    labelCiudad: 'Distrito',
    labelEstado: 'Departamento',
    labelEstadoPlural: 'Departamentos',
    labelRFC: 'RUC',
    labelCURP: 'DNI',
    labelINE: 'INE',
    labelCedula: 'Cédula Fiscal',
    labelCLABE: 'CLABE',
    labelEligeMunicipio: 'Elige un distrito',
    labelEligeEstado: 'Elige un departamento',
    labelEligeColonia: 'Elige una urbanización',
    labelFiltraMunicipio: 'Filtrar por distrito...',
    labelFiltraEstado: 'Filtrar por departamento...',
    paisLat: -10.38475337613052,
    paisLng: -75.34033785899022,
    cubejsToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.K9PiJkjegbhnw4Ca5pPlkTmZihoOm42w8bja9Qs2qJg",
    cubejsApiUrl: "https://bi-pe.kobra.red/cubejs-api/v1"
  };
