<div>
  <div class="upload-container">
    <div class="upload-card">
      <app-file-upload [multiplesArchivos]="true" [accept]="[acceptFiles]" (onUpload)="onUpload($event)" (onRemove)="onRemoveFile()"
        (onError)="onErrorFile($event)">
        <div class="upload-card-container">
          <div class="upload-image">
            <mat-icon class="upload-icon">description</mat-icon>
          </div>
          <div class="upload-content">
            <div *ngIf="isFile" style="margin-right: 50px;">
              Archivo cargado y con el nombre de
              <div *ngFor="let fileItem of file">
                <p style="word-break: break-all; flex: 1; margin-right: 10px;">{{ fileItem.name }}</p>
              </div>
            </div>
            <p *ngIf="!isFile" style="margin-right: 20px;">
              Arrastra y suelta o elegir un archivo para cargar tu información.
              <br />
              Solo los archivos pdf son compatibles.
            </p>
          </div>
        </div>
      </app-file-upload>
    </div>
  </div>
</div>
