<mat-card>
  <mat-card-content style="height: calc(100% - 57px);">
    <!-- <h3  class="titulo import-title">Revisar y finalizar la importación</h3> -->
    <h3 *ngIf="currentBatch && currentBatch.idStatus === IMPORT_STATUS.QUEUE" class="titulo import-title">Importación encolada</h3>
    <h3 *ngIf="currentBatch && (currentBatch.idStatus >= IMPORT_STATUS.LOADED || currentBatch.idStatus === IMPORT_STATUS.ERROR || currentBatch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS)" class="titulo import-title">Revisar y finalizar la importación</h3>
    <h3 *ngIf="currentBatch && currentBatch.idStatus === IMPORT_STATUS.ERROR" class="titulo import-title">Importación con error</h3>
    <h3 *ngIf="currentBatch && currentBatch.idStatus === IMPORT_STATUS.LOADING" class="titulo import-title">Cargando importación</h3>
    <h3 *ngIf="!currentBatch" class="titulo import-title">Subiendo archivo</h3>

    <h5 *ngIf="!currentBatch || (currentBatch.idStatus < IMPORT_STATUS.LOADED && currentBatch.idStatus !== IMPORT_STATUS.ERROR && currentBatch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS)" class="titulo-sub import-title" style="margin: 0;">
      Espere un momento por favor...
    </h5>

    <h5 *ngIf="currentBatch && currentBatch.idStatus == IMPORT_STATUS.ERROR" class="titulo-sub import-title" style="margin: 0;">
      Favor de intentar de nuevo...
    </h5>

    <div class="preview-item">
      <app-loader style="margin-top: 32px;" *ngIf="!currentBatch || (currentBatch.idStatus < IMPORT_STATUS.LOADED && currentBatch.idStatus !== IMPORT_STATUS.ERROR && currentBatch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS)"></app-loader>
      <div *ngIf="currentBatch && (currentBatch.idStatus >= IMPORT_STATUS.LOADED || currentBatch.idStatus === IMPORT_STATUS.ERROR || currentBatch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS)">
        <mat-card class="preview-item-card">
          <mat-card-header>
            <mat-card-title>
              <mat-icon class="active-icono">check_circle</mat-icon>
              Cuentas
            </mat-card-title>
            
          </mat-card-header>
          <mat-card-content class="card-content">
            <div>
              <div style="text-align: center;">
                <img
                  width="32px"
                  height="32px"
                  srcset="
                    /assets/import/types/2@3x.png 3x,
                    /assets/import/types/2@2x.png 2x
                  "
                  src="/assets/import/types/2.png"
                >
                <h4 class="titulo-sub-2 import-title" style="margin: 0;">Cuentas en el archivo</h4>
                <a mat-button class="active" (click)="openAccounts('cuentas-archivo')">{{ currentBatch.total || 0 }}</a>
              </div>
  
              <div style="text-align: center;">
                <h4 class="titulo-sub-2 import-title" style="margin: 0;">Errores encontrados</h4>
                <a mat-button class="active" (click)="openAccounts('errors')">{{ currentBatch.totalErrors || 0 }}</a>
              </div>
  
              <div style="text-align: center;">
                <h4 class="titulo-sub-2 import-title" style="margin: 0;">Cuentas a importar</h4>
                <a mat-button class="active" (click)="openAccounts('imported')">{{ cuentasImportadas }}</a>
              </div>
            </div>
            <!-- <mat-icon class="active icono-like">thumb_up</mat-icon> -->
            <!-- <div class="icon-container">
              <mat-icon class="centered-icon">thumb_up</mat-icon>
            </div> -->
          </mat-card-content>
          
        </mat-card>
      </div>
      
      <!-- <img
        *ngIf="currentBatch && (currentBatch.idStatus >= IMPORT_STATUS.LOADED || currentBatch.idStatus === IMPORT_STATUS.ERROR || currentBatch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS)"
        style="width: 150px; padding-bottom: 16px;"
        srcset="
          /assets/thumbs_up@3x.png 3x,
          /assets/thumbs_up@2x.png 2x
        "
        src="/assets/thumbs_up.png"
      > -->
    </div>
  </mat-card-content>
  
</mat-card>