export class Batch {
  id: number;
  email: string;
  emails: string;
  resultado: string;
  idProduct: number;
  idStatus: number;
  total: number;
  totalErrors: number;
  totalImported: number;
  totalGeoExact: number;
  totalNoGeoloc: number;
  totalGeoInexact: number;
  totalVeryHigh: number;
  totalHigh: number;
  totalLow: number;
  totalVeryLow: number;
  totalWithoutCoverage: number;
}
