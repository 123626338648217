<h1 mat-dialog-title>{{data.isVersioNamiento ? 'Variables con error en la plantilla' : 'Catálogo de variables'}}</h1>
<mat-dialog-content>
  <alerta-informativa 
    *ngIf="data.isVersioNamiento"
    clase="danger"
    titulo="¡Aviso!" 
    texto="Algunas de las variables de la plantilla han sido eliminadas del cuestionario o de los bloques correspondientes. A continuación, se muestra una lista de las variables afectadas.">
  </alerta-informativa>
  <div class="contenido" *ngIf="!loadVariables">
    <mat-card>
      <mat-card-content>
        <mat-accordion>
          <mat-expansion-panel class="acordeon" *ngIf="!data.isVersioNamiento">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>Cuenta</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCuenta.columnas" [items]="cuenta" [headers]="tablaCuenta.headers"
              [tableHeaders]="tablaCuenta.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCuenta.multiSelect"
              (onLinkRow)="onClick($event)"></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="acordeon" *ngIf="!data.isVersioNamiento">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>Detalle de la cuenta</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCuentaDetalle.columnas" [items]="cuentaDetalle"
              [headers]="tablaCuentaDetalle.headers" [tableHeaders]="tablaCuentaDetalle.tableHeaders"
              [ignoreSortChange]="true" [multiSelect]="tablaCuentaDetalle.multiSelect">
            </app-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="acordeon" *ngIf="data.tipoFormato == 1 && !data.isVersioNamiento">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>Visita</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table  [classContainer]="'tabla-sin-scoll'" [columns]="tablaVisita.columnas" [items]="visita" [headers]="tablaVisita.headers"
              [tableHeaders]="tablaVisita.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaVisita.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="acordeon" *ngIf="data.tipoFormato == 1 && checkLists.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>CheckLists</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCheckList.columnas" [items]="checkLists" [headers]="tablaCheckList.headers"
              [tableHeaders]="tablaCheckList.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCheckList.multiSelect"
            ></app-table>
          </mat-expansion-panel>
          
          <mat-expansion-panel class="acordeon" *ngIf="data.tipoFormato == 1 && condicionales.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>Condicionales</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCondicionales.columnas" [items]="condicionales" [headers]="tablaCondicionales.headers"
              [tableHeaders]="tablaCondicionales.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCondicionales.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="acordeon" *ngIf="!data.isVersioNamiento">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4>Generales</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaGenerales.columnas" [items]="generales" [headers]="tablaGenerales.headers"
              [tableHeaders]="tablaGenerales.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaGenerales.multiSelect"
            ></app-table>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="salir()">
    <mat-icon class="kobra-icon">close</mat-icon>Cancelar
  </button>
</mat-dialog-actions>