<div class="app content kobra">
    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
      </mat-card-header>
        <mat-card-content class="kobra-card-content">
            <form [formGroup]="form">
                <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" rowHeight="80px" *ngIf="sessionData.isSuperAdmin">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-select-search
                          class="id-card-field input-catalogo"
                          [label]="'Cliente'"
                          [placeHolder]="'Selecciona un cliente'"
                          [value]="'idFinanciera'"
                          [labelField]="'nombre'"
                          [icon]="'account_balance'"
                          [options]="lenders"
                          formControlName="idFinanciera"
                        >
                        </mat-select-search>
                    </mat-grid-tile>
                </mat-grid-list-responsive>
                <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" rowHeight="80px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-form-field class="input-catalogo">
                          <mat-label>Nombre</mat-label>
                          <input matInput textSanitizer 
                            formControlName="nombre"
                            placeholder="Capture el nombre del administrador">
                          <mat-icon matSuffix>shield_person</mat-icon>
                          <mat-error *ngIf="checkControl('nombre')">
                            {{getErrorMessage('nombre')}}
                          </mat-error>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-form-field class="input-catalogo">
                          <mat-label>Primer apellido</mat-label>
                          <input matInput textSanitizer 
                            formControlName="apellidoPaterno"
                            placeholder="Capture el apellido del administrador">
                          <mat-icon matSuffix>shield_person</mat-icon>
                          <mat-error *ngIf="checkControl('apellidoPaterno')">
                            {{getErrorMessage('apellidoPaterno')}}
                          </mat-error>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-form-field class="input-catalogo">
                          <mat-label>Segundo apellido</mat-label>
                          <input matInput textSanitizer 
                            formControlName="apellidoMaterno"
                            placeholder="Capture el apellido del administrador">
                          <mat-icon matSuffix>shield_person</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list-responsive>
                <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" [rowHeight]="pixelesContrasena">
                  <mat-grid-tile colspan="1" rowspan="1">
                      <mat-form-field class="input-catalogo" [style.margin-top.px]="margenContrasena">
                        <mat-label>Correo</mat-label>
                        <input matInput textSanitizer
                          [readonly]="isReadonly" 
                          class="readonly-input"
                          readonly 
                          formControlName="email"
                          placeholder="Capture el correo del administrador">
                        <mat-icon matSuffix>mail</mat-icon>
                        <mat-error *ngIf="checkControl('email')">
                          {{getErrorMessage('email')}}
                        </mat-error>
                      </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1" rowspan="1">
                      <mat-form-field class="input-catalogo" [ngClass]="{error: checkControl('contrasena')}" [style.margin-top.px]="margenContrasena">
                        <mat-label>Contraseña</mat-label>
                        <input matInput textSanitizer 
                          formControlName="contrasena"
                          placeholder="Introduce la contraseña"
                          [type]="hideContra ? 'password' : 'text'"
                        >
                        <mat-icon class="ver-password" matSuffix (click)="hideContra = !hideContra">{{hideContra ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="checkControl('contrasena')">
                          <span  style="word-break: break-all !important;">{{getErrorMessage('contrasena')}}</span>
                        </mat-error>
                      </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1" rowspan="1">
                      <mat-form-field class="input-catalogo" [style.margin-top.px]="margenContrasena">
                        <mat-label>Confirmar contraseña</mat-label>
                        <input matInput textSanitizer 
                          placeholder="Confirma la contraseña"
                          [type]="hideConfirm ? 'password' : 'text'"
                          formControlName="confirmPass"
                        >
                        <mat-icon class="ver-password" matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="checkControl('confirmPass')">
                          {{getErrorMessage('confirmPass')}}
                        </mat-error>
                      </mat-form-field>
                  </mat-grid-tile>
              </mat-grid-list-responsive>
                <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" rowHeight="40px">
                  <mat-grid-tile colspan="1" rowspan="1" *ngIf="this.usuario">
                      <mat-checkbox class="kobra-checkbox responsive" formControlName="deleted">Activo</mat-checkbox>
                  </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-checkbox class="kobra-checkbox responsive" formControlName="notificacionVisita">Notificar cada visita</mat-checkbox>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-checkbox class="kobra-checkbox responsive" formControlName="notificacionReporteRedeco">Enviar reporte REDECO</mat-checkbox>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1" >
                        <mat-checkbox class="kobra-checkbox responsive" formControlName="notificacionSaldos">Notificar saldo vencido</mat-checkbox>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1" >
                        <mat-checkbox class="kobra-checkbox responsive" formControlName="notificacionCitas">Notificar citas</mat-checkbox>
                    </mat-grid-tile>
                </mat-grid-list-responsive>
            </form>
            <div class="button-center">
                <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()" [disabled]="loadingLenders">
                  <mat-icon class="kobra-icon">
                    close
                  </mat-icon> Cancelar</button>
                <button mat-stroked-button class="kobra-button" type="submit" (click)="onSubmit()" [disabled]="saveButton || loadingLenders">
                  <mat-icon>
                    save
                  </mat-icon> Guardar</button>
            </div>
        </mat-card-content>
        <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadingLenders">
            <i>Obteniendo clientes...</i>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>

</div>