export class ChecklistRespuestaOpcion {
  id: number;
  label: String;

  constructor() {
  }

  public static map(data: any): ChecklistRespuestaOpcion {
    let instance: ChecklistRespuestaOpcion = new ChecklistRespuestaOpcion();
    instance.id = data.id || null;
    instance.label = data.label || null;

    return instance;
  }

  public static mapArray(data: any[]): ChecklistRespuestaOpcion[] {
    let instances: ChecklistRespuestaOpcion[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(ChecklistRespuestaOpcion.map(data[i]));
    }
    return instances;
}
}
