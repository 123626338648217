<form [formGroup]="form" (ngSubmit)="save()">
  <!-- <div class="ui active inverted dimmer" *ngIf="loader || loaderEstados || loaderMunicipios || loaderColonias">
    <div class="ui text loader">Cargando informaicón...</div>
  </div> -->
  <!-- <span class="titulo">Editar</span> -->
  <mat-grid-list-responsive style="margin-top: 20px;" [colsMd]="1" [colsSm]="1" rowHeight="80px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field class="grid-tile-form-field" [ngClass]="{error: calleValidation.hasError()}" style="width: 97.5%;">
        <mat-label>Calle</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="calle"
          placeholder="Ingrese la calle">
          <mat-icon matSuffix  class="icono-limpiar"
          *ngIf="form.get('calle')?.value" 
          (click)=" this.form.get('calle')?.setValue('')">clear</mat-icon>
        <mat-icon  matSuffix>edit_road</mat-icon>
        <mat-error *ngIf="calleValidation.hasError()">{{calleValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list-responsive>

  <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="80px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field class="grid-tile-form-field" [ngClass]="{error: numeroExtValidation.hasError()}" style="width: 95%">
        <mat-label>Num. Ext.</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="numeroExt"
          placeholder="Ingrese el número exterior">
          <mat-icon matSuffix  class="icono-limpiar"
          *ngIf="form.get('numeroExt')?.value" 
          (click)=" this.form.get('numeroExt')?.setValue('')">clear</mat-icon>
        <mat-icon  matSuffix>numbers</mat-icon>
        <mat-error *ngIf="numeroExtValidation.hasError()">{{numeroExtValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field class="grid-tile-form-field"  style="width: 95%">
        <mat-label>Num. Int.</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="numeroInt"
          placeholder="Ingrese el número exterior">
          <mat-icon matSuffix  class="icono-limpiar"
          *ngIf="form.get('numeroInt')?.value" 
          (click)=" this.form.get('numeroInt')?.setValue('')">clear</mat-icon>
        <mat-icon  matSuffix>numbers</mat-icon>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list-responsive>

  <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="80px" style="margin-bottom: 20px !important;">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-select-search
        [label]="labelEstado"
        [placeHolder]="labelEligeEstado"
        [value]="'idEstado'"
        [labelField]="'nombre'"
        [icon]="'flag'"
        [options]="estados"
        [error]="estadoValidation.hasError() || this.form.controls['idEstado'].hasError('required')"
        [errorMessage]="estadoValidation.error"
        formControlName="idEstado"
        class="doble-col"
      >
      </mat-select-search>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-select-search
        [label]="labelMunicipio"
        [placeHolder]="labelEligeMunicipio"
        [value]="'idMunicipio'"
        [labelField]="'nombre'"
        [icon]="'flag'"
        [options]="municipios"
        [error]="municipioValidation.hasError() || this.form.controls['idMunicipio'].hasError('required')"
        [errorMessage]="municipioValidation.error"
        formControlName="idMunicipio"
        class="doble-col"
      >
      </mat-select-search>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
        <mat-select-search
        [label]="labelCodigoPostal"
        [placeHolder]="'Elige un codigo postal'"
        [value]="'codigoPostal'"
        [labelField]="'codigoPostal'"
        [icon]="'flag'"
        [options]="codigosPostales"
        formControlName="codigoPostal"
        class="doble-col"
      >
      </mat-select-search>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field *ngIf="!mostrarColonia" class="doble-col">
        <mat-label>{{labelColonia}}</mat-label>
        <mat-select 
        formControlName="idColonia"
        (ngModelChange)="changeColonias()">
          <mat-option (click)="toggleMostrarColonia()">Otra colonia...</mat-option>
          <mat-option *ngFor="let colonia of colonias" [value]="colonia.idColonia">
            {{colonia.nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="mostrarColonia" class="doble-col">
        <input matInput textSanitizer  placeholder="Ingrese la colonia" [(ngModel)]="nuevaColonia" formControlName="colonia">
        <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleMostrarColonia(); hideMatSelect()">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

  </mat-grid-list-responsive>
  <mat-card-actions align="end" class="action-card">
    <button class="boton-cancelar" mat-flat-button type="button" (click)="cancel()">
      <mat-icon class="kobra-icon" >
        close
      </mat-icon> Cancelar</button>
    <button mat-stroked-button class="boton-guardar" type="submit">
      <mat-icon>
        save
      </mat-icon> {{guardando ? 'Guardando...' : 'Guardar'}}</button>
  </mat-card-actions >
</form>
