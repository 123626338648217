export class VisitPhoto {
  id: number;
  fullPath: string;

  public static mapArrayData(data: any[]): VisitPhoto[] {
    let array: VisitPhoto[] = [];
    let object: any;

    data.forEach(element => {
      array.push(VisitPhoto.map(element));
    });
    return array;
  }

  public static map(data: any): VisitPhoto {
    let instance = new VisitPhoto();
    instance.id = data.idVisitaFoto;
    return instance;
  }
}