<app-modal [title]="'Agentes no asignados'" [custom]="true">
    <form>

      <div *ngIf="productoNoVinculado.length > 0">
        <h4 style="margin-left:20px">Agentes no vinculados</h4>
        
          <app-table [columns]="tablaProductoNoVinculado.columnas"
              [items]="productoNoVinculado" [headers]="tablaProductoNoVinculado.headers"
              [tableHeaders]="tablaProductoNoVinculado.tableHeaders" [ignoreSortChange]="true"
              [multiSelect]="tablaProductoNoVinculado.multiSelect"  (onLinkRow)="onClick($event)">
          </app-table>
        
      </div>
      
      <div *ngIf="agentesInvalidos.length > 0">
        <h4 style="margin-left:20px">Agentes no pertenecientes a la red</h4>
        
          <app-table [columns]="tablaAgentesInvalidos.columnas"
              [items]="agentesInvalidos" [headers]="tablaAgentesInvalidos.headers"
              [tableHeaders]="tablaAgentesInvalidos.tableHeaders" [ignoreSortChange]="true"
              [multiSelect]="tablaAgentesInvalidos.multiSelect">
          </app-table>
        
      </div>

    </form> 
    <div mat-dialog-actions class="acciones" m-actions>
      <button mat-flat-button class="boton-cancelar" (click)="onNoClick()">
        <mat-icon class="kobra-icon">close</mat-icon>Cerrar</button>
    </div>
</app-modal>