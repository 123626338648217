import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { FormValidation } from "./form-validation.model";

@Injectable()
export class FormValidationService {
  public formControl: AbstractControl;
  public formValidationError: FormValidation;

  constructor() { }

  public observeErrors( formControl: AbstractControl, formValidationError: FormValidation ): void {
    formControl.valueChanges.subscribe(value => {
      formValidationError.error = '';
      if (formControl && formControl.dirty && !formControl.valid) {
        for (var key in formControl.errors) {
          formValidationError.error += formValidationError.message[key] + ' ';
        }
      }
    });
  }
}