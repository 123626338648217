<app-modal [title]="title" [custom]="true">
  <form [formGroup]="formGroup">
    <app-fieldset style="margin: 8px;" title="Pregunta y respuestas" [fullWidth]="true">
      <div class="ask-container">
        <app-field formControlName="nombrePregunta" style="flex-basis: 70%;"></app-field>

        <button color="primary" *ngIf="validarAgregarRespuesta(pregunta)" [disabled]="hasAction" (click)="addAnswer()">Agregar respuesta</button>
      </div>

      <mat-list *ngIf="tiposRespuestas?.length">
        <mat-list-item *ngFor="let respuesta of pregunta.respuestas; index as j">
          <mat-icon mat-list-icon>question_answer</mat-icon>
          <h4 mat-line>
            {{respuesta.nombreRespuesta || 'Sin nombre de respuesta'}}
          </h4>

          <p mat-line>
            {{getTypeAnswerName(respuesta)}} ({{respuesta.requerida ? 'requerido' : 'opcional'}})
          </p>

          <mat-error
            mat-line
            *ngIf="formGroup.get('respuestas')['controls'][j].get('nombreRespuesta').hasError('required')"
          >
            Esta respuesta requiere un nombre
          </mat-error>

          <button mat-icon-button (click)="editAnswer(j)" title="Editar">
            <mat-icon aria-label="Editar">edit</mat-icon>
          </button>

          <button mat-icon-button [disabled]="hasAction" (click)="removeAnswer(j)" title="Eliminar">
            <mat-icon aria-label="Eliminar">delete</mat-icon>
          </button>
        </mat-list-item>

        <mat-error *ngIf="formGroup.get('respuestas').hasError('required')">
          Se requiere al menos una respuesta
        </mat-error>
      </mat-list>
    </app-fieldset>
  </form>

  <button
    class="boton-cancelar"
    mat-flat-button
    color="secondary"
    (click)="onClose()"
    m-actions
  >Cancelar</button>

  <button
    [disabled]="!formGroup.dirty || formGroup.get('respuestas').hasError('required') || formGroup.invalid"
    mat-stroked-button
    color="primary"
    (click)="save()"
    m-actions
  >
    <ng-container *ngIf="pregunta.nombrePregunta">Actualizar</ng-container>
    <ng-container *ngIf="!pregunta.nombrePregunta">Agregar</ng-container>
  </button>
</app-modal>
