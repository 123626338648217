import { Lender } from '@lender/lender.model';
import { Financiera } from './../../../financieras/shared/financiera.model';
import { LenderService } from './../../../lender/lender.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { take, filter } from 'rxjs/operators';
import { SessionData } from 'src/app/shared/interfaces/session-data';

import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { environmentSelector } from '../../../../environments/environment.selector';
import { I } from '@angular/cdk/keycodes';
declare var $: any;

@Component({
    selector: 'app-productos',
    templateUrl: './productos-list.component.html',
    styleUrls: ['./productos-list.component.css'],
    providers: [LenderService]
})
export class ProductosComponent implements OnInit {
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    private $filterModal: any;
    sessionData: SessionData;
    public branches: any[] = [];
    public productos: any[] = [];
    public itemsSelected: any[] = [];
    public pagination: IPaginatorSucursales;
    private environment = environmentSelector();

    public queryParams: URLSearchParams;
    public listFields: any = {};
    public lenders: any[] = [];
    public idCliente: number = null;
    public ayuda: boolean = false;
    public hijosActivos: boolean = false;
    filtrosAbiertos: boolean = false;

    tabla: any = {
        headers: false,
        multiSelect: false,
        columns: [
            {
                name: 'ID Cliente',
                key: 'idCliente',
                hide: true
            }, {
                name: 'Cliente',
                key: 'nombreCliente',
                hide: false
            }, {
                name: 'ID Producto',
                key: 'idProducto',
                hide: false
            }, {
                name: 'Producto',
                key: 'nomProducto',
                hide: false
            }, {
                name: 'Descripción',
                key: 'descripcion',
                hide: false
            }
        ]
    }



    public filters: any = {
        financiera: null,
        nombreSucursal: null,
        activa: true,
        excluirDemo: false,
        filtered: false,
        page: 1
    }

    public datosConsulta: any = {
        pageSize: 50,
        rows: 50,
        sortField: 'idProducto',
        sortAsc: true,
        query: "",
        fromRowPage: 0,
        toRowPage: 10
    }

    public loadings: any = {
        productos: true,
        financieras: true
    }

    constructor(
        private lenderService: LenderService,
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        // private sucursalesService: SucursalesService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private router: Router,
        private rootElement: ElementRef,
        private cdRef: ChangeDetectorRef
    ) {
        this.sessionData = this.localStorageService.getSessionData();
    }

    ngOnInit() {
        if (!this.sessionData.isSuperAdmin) {
            this.filters.excluirDemo = false;
        }
        this.initListColumns();
        this.loadLenders();

        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));

    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }

    actualizarCambios(){
        this.cdRef.detectChanges();

        this.loadProductos(this.filters.page || 1);
    }

    inactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filters && (this.filters.financiera || this.filters.filtered)){
            this.filtrosService.guardarFiltros(this.filters, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
    }

    resultadoFiltros(filtrosNuevos: any) {
        if (filtrosNuevos) {
            this.filters = filtrosNuevos;
            this.filters.filter = true;
            this.filters.filtered = true;
        }
        this.idCliente = this.filters.financiera ? this.filters.financiera.idCliente : null;
        if(this.idCliente){
            this.loadProductos(this.filters.page || 1);
        }
        else{
            this.loadings.productos = false;
        }
    }

    private initListColumns(): void {
        // var listFields: any = JSON.parse(localStorage.getItem('visitListFields')) || {};

        // this.listFields.lender = listFields.lender == false ? false : true;
        // this.listFields.branchName = listFields.branchName == false ? false : true;
        // this.listFields.status = listFields.status == false ? false : true;
        // this.tabla.columns[0].hide = !this.listFields.lender;
        // this.tabla.columns[2].hide = !this.listFields.branchName;
        // this.tabla.columns[4].hide = !this.listFields.status;
    }

    public saveInStorage(index: number, value: boolean): void {
        this.tabla.columns[index].hide = !value;
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

  

    //INICIO EVENTOS TABLA
    onSelected(rows) {
        this.itemsSelected = rows.slice();
    }

    onLinkRow({ row, columnPressed }) {
        if (columnPressed === 'nombreSucursal') {
            this.detalleSucursal(row, false);
        }
    }

    onDoubleClick({ row }) {
        this.detalleSucursal(row, false);
    }

    sortChange(sort: any) {
        if (sort.active == this.datosConsulta.sortField) {
            this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
        } else {
            this.datosConsulta.sortField = sort.active;
            this.datosConsulta.sortAsc = true;
        }
        this.loadProductos(1);
    }

    filtersChange(values) {
    }

    searchChange(text) {
    }

    public download(): void {
        const token = localStorage.getItem('access-token');
        let country = "&Country=";
        country+= this.environment.country;
        const url = `/sucursales/csv${this.crearURLQuery(1)}&token=${token}${country}`;
        window.open(this.environment.kobraServices.servicios + url);
    }
    //FIN EVENTOS TABLA

    //INICIO Eventos botones

    //Muestra el detalle del producto ademas de validar si será para agregar, editaro o ver info solamente
    detalleSucursal(producto: any = null, modificar: boolean = true) {
        /*let nuevoProducto = producto ? Object.assign({}, producto) : null;
        if (nuevoProducto) {
            localStorage.setItem('procutoNuevoTemp', JSON.stringify(nuevoProducto));
        } else {
            localStorage.removeItem('procutoNuevoTemp');
        }*/
        this.router.navigate(['editar'], { 
            relativeTo: this.route,
            queryParams: {
              idCliente: this.itemsSelected[0].idCliente,
              idProducto: this.itemsSelected[0].idProducto,
              nombreProducto: this.itemsSelected[0].nomProducto,
              nombreCliente: this.itemsSelected[0].nombreCliente
            },
            queryParamsHandling: 'merge'
        });

        /*this.router.navigate(
            ['editar', {}],
            {
                relativeTo: this.route,
                queryParamsHandling: 'preserve'
            }).then(() => {
                this.itemsSelected = [];
            });*/
        /*this.router.navigate([`catalogos/sucursales/detalle`], {
          queryParamsHandling: 'preserve'
        });*/
    }

    public setFilter(): void {
        if(this.idCliente){
            this.filters.filtered = true;
            this.filters.page = 1;
            this.filtrosService.guardarFiltros(this.filters);
            this.loadProductos(1);
        }else{
            this.filtrosSideNav.toggle();
            this.app.showSnackbar("Error", "Es necesario seleccionar un cliente.", 0, "warning");
        }    
    }

    public clearFilter(): void {
        Object.keys(this.filters).forEach(key => {
            if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')) {
                this.filters[key] = null;
            }
        });
        this.idCliente = null;
        this.filters.excluirDemo = true;
        this.filters.activa = true;
        this.filters.filtered = false;
        this.filters.page = 1;
        this.filtrosService.removerFiltros();
        //this.$filterModal.modal('hide');
        //this.loadProductos(1);
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idCliente == event) {
                    this.filters.financiera = lender;
                }
            });
        }
        else {
            this.filters.financiera = null;
            this.idCliente = null;
        }

    }

    //FIN Metodos del modal filtro

    //Metodos Generales
    public loadProductos(page: number): void {
        this.loadings.productos = true;

        if (page != this.filters.page) {
            this.filters.page = page;
            this.filtrosService.guardarFiltros(this.filters);
        }

        let query: string = this.crearURLQuery(page);
        
        this.lenderService.obtenerProductosCliente(this.idCliente, query)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadings.productos = false;
                this.pagination = res.data;
                this.productos = res.data.pageData;
                
                //this.calcFromToRowPage();
            }, (err: any) => {
                this.loadings.productos = false;
                this.app.showError(err);
            });
        //   this.sucursalesService.getBranches(query)
        //     .pipe(
        //       take(1)
        //     ).subscribe((res: any) => {
        //       this.pagination = res.data;
        //       this.branches = res.data.pageData;
        //       this.calcFromToRowPage();

        //       this.loadings.sucursales = false;
        //     }, (error: any) => {
        //       this.app.showError(error);

        //       this.loadings.sucursales = false;
        //     });
    }

    private crearURLQuery(page: number): string {
        let queryParams: string = '';

        queryParams += `?paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;

        if (this.datosConsulta.sortField) {
            queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
        }

        return queryParams;
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
            this.datosConsulta.rows = newPagina.pageSize;
            this.loadProductos(newPagina.pageIndex+1);
        }
    }

    calcFromToRowPage() {
        if (this.pagination.pageData.length <= 0) {
            this.datosConsulta.fromRowPage = 0;
            this.datosConsulta.toRowPage = 0;
        } else {
            this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

            if (this.pagination.page === this.pagination.pages) {
                this.datosConsulta.toRowPage = this.pagination.totalRows;
            } else {
                this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
            }
        }
    }

    public loadLenders(): void {
        this.loadings.financieras = true;

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;
                if(!this.sessionData.isSuperAdmin){
                    this.idCliente = this.sessionData.idCliente;
                    this.onChangeLender(this.sessionData.idCliente);
                }
            },
            err => {
                this.app.showError(err);
                this.loadings.financieras = false;
            },
            () => this.loadings.financieras = false
        );
    }



    resetFinancieraFilter() {
        this.idCliente = null;
        this.filters.financiera = null;
    }
    //Fin Metodos Generales

}

export interface ISucursales {
    idFinanciera: number
    nombreFinanciera: string;
    idSucursal: number
    nombreSucursal: string;
    alias: string;
    descripcion: string,
    createdAt: string,
    updatedAt: string,
    activa: boolean;
    estatus: string;
}

export interface IPaginatorSucursales {
    page: number;
    pageData: [ISucursales];
    pages: number;
    totalRows: number;
}
