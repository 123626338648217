import {Component, Inject,} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-tipo-condicional-dialog',
    templateUrl: 'tipo-condicional.component.html',
    styleUrls: ['tipo-condicional.component.css'],
    providers: []
  })
  export class TipoCondicionalDialog {

    condicionalesSQL = [
      { id: '&&', nombre: 'Y' },
      { id: '||', nombre: 'O' }
    ];
    tipoCondicion: string;
    
    constructor(
        public dialogRef: MatDialogRef<TipoCondicionalDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        //this.agentes = data;
    }

    ngOnInit() {
        
    }
  
    onNoClick(): void {
        this.dialogRef.close({
          success: false
      });
    }

    Aceptar(): void {
        this.dialogRef.close({
            success: true,
            tipoCondicion : this.tipoCondicion
        });
    }

  }