
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorService {

  constructor() { }

  public handlePromiseError( error: any ): Promise<any> {
    let showMessage = this.checkSessionStatus(error);
    error = error.json();
    error.showMessage = showMessage;
    return Promise.reject(error);
  }

  public handleOnservableError( error: any ): Observable<any> {
    let showMessage = this.checkSessionStatus(error);
    error = error.json();
    error.showMessage = showMessage;
    return observableThrowError(error);
  }

  private checkSessionStatus( error: any ): boolean {
    let hasSession = true;
    if (error.status === 401) {
      localStorage.removeItem('access-token');
      location.href = '/auth';
      hasSession = false;
    }
    return hasSession;
  }
}