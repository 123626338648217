import { Agent } from "./agent.model";
import { Pagination } from "../pagination/pagination.model";

export class AccountPaginator {
  pagination: Pagination;
  data: Agent[];

  constructor() {
    this.pagination = new Pagination();
  }
}