import { Component, Inject, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisitDetailComponent } from '../../visit/visit-detail.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'visit-detail-dialog',
  templateUrl: './visit-detail-dialog.component.html',
  styleUrls: ['./visit-detail-dialog.component.css']
})
export class ModalVisitaDetalleComponent implements AfterViewInit, OnDestroy {
  visitaActual: any;
  todasLasVisitas: any[];
  indiceActual: number = 0;
  public cargandoVisita: boolean = true;
  mostrarComponente: boolean = true;
  private componentRef: ComponentRef<VisitDetailComponent>;
  private componentSubscription: Subscription;
  contadorVisitas: number;

  @ViewChild('visitDetailContainer', { read: ViewContainerRef }) visitDetailContainer: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<ModalVisitaDetalleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef // Inyectar ChangeDetectorRef
  ) {
    this.todasLasVisitas = data;
    this.visitaActual = this.todasLasVisitas[this.indiceActual];
    this.contadorVisitas = 0;
  }

  ngAfterViewInit() {
    // Retrasar la creación del componente hasta que la detección de cambios haya completado
    setTimeout(() => {
      this.crearComponente();
    });
  }

  ngOnDestroy() {
    this.destroyComponent();
  }

  salirCalificar(): void {
    this.dialogRef.close(false);
  }

  siguiente() {
    if (this.indiceActual < this.todasLasVisitas.length - 1) {
      this.indiceActual++;
      this.visitaActual = this.todasLasVisitas[this.indiceActual];
      this.crearComponente();
    }
  }

  anterior() {
    if (this.indiceActual > 0) {
      this.indiceActual--;
      this.visitaActual = this.todasLasVisitas[this.indiceActual];
      this.crearComponente();
    }
  }

  mostrarAnterior(): boolean {
    return this.indiceActual > 0;
  }

  mostrarSiguiente(): boolean {
    return this.indiceActual < this.todasLasVisitas.length - 1;
  }

  calificarCompletado(completado: boolean) {
    //this.cargandoVisita = completado;
    if(completado){
      this.contadorVisitas++;
      if(this.todasLasVisitas.length == this.contadorVisitas){
        this.salirCalificar()
      }else{
        this.siguiente();
      }
      
    }
  }

  private crearComponente() {
    this.destroyComponent();
  
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VisitDetailComponent);
    this.componentRef = this.visitDetailContainer.createComponent(componentFactory);
    this.componentRef.instance.visitasACalificar = this.visitaActual;
    this.componentSubscription = this.componentRef.instance.operationComplete.subscribe(completado => this.calificarCompletado(completado));
    this.componentSubscription.add(this.componentRef.instance.closeModal.subscribe(() => this.salirCalificar()));
  }
  

  private destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentSubscription.unsubscribe();
    }
  }
}
