// import { VisitService } from 'src/app/visit/visit.service';
import { Injectable } from '@angular/core';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardUserService {

  constructor(
    private leanService: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
  ) { }

  obtenerTotalesCuenta(query) {
    return this.leanService.get(`/cuentas-total-cuentas${query}`);
  }

  obtenerCuentasMapa(start: any, rows: any,query = '') {
    query = query? (query+= `start=${start}&rows=${rows}`):`?start=${start}&rows=${rows}`;
    return this.leanService.get(`/cuentas-mapa${query}`);
  }

  obtenerTotalesVisitas(query) {
    return this.serviciosVisitas.get(`/visitas-total-visitas-realizadas${query}`);
  }

  obtenerTotalAcreditadosVisitados(query) {
    return this.serviciosVisitas.get(`/visitas-total-acreditados-visitados${query}`);
  }

  obtenerTotalCobradoresVinculados(query) {
    return this.leanService.get(`/cobradores/total-cobradores-vinculados${query}`);
  }

  obtenerTotalRevisitas(query) {
    return this.serviciosVisitas.get(`/visitas-total-revisitas${query}`);
  }

  obtenerTotalPromesasPagoAgentes(query) {
    return this.serviciosVisitas.get(`/visitas-total-promesas-pago-agentes${query}`);
  }

  obtenerTotalPromesasPagoAcreditados(query) {
    return this.serviciosVisitas.get(`/visitas-total-promesas-pago-acreditados${query}`);
  }

  obtenerProductos(idCliente) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos`);
  }

  obtenerSucursales(){
    return this.serviciosVisitas.get('/sucursales');
  }
}
