import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Subscription, Subject } from 'rxjs';
import { CobradorMovimiento, CoMovimientoPagination } from './cobrador-movimiento.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { CobradoresMovimientosService } from './cobradores-movimientos.service';
import { FormControl, Validators } from '@angular/forms';
import { CobradoresFacturacionesPeriodosService } from '../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { take, map, timestamp } from 'rxjs/operators';
import { ConceptosSaldosService } from '../conceptos-saldos/conceptos-saldos.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ModalImportarComponent } from '../modales/importarxsl/importar.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { LenderService } from '@lender/lender.service';
import { Lender } from '@lender/lender.model';
import { AgentService } from '@agent/agent.service';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { ModalAsignarPeriodoComponent } from '../modales/asignar-periodo/asignar-periodo.component';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';




@Component({
  selector: 'app-cobradores-movimientos',
  templateUrl: './cobradores-movimientos.component.html',
  styleUrls: ['./cobradores-movimientos.component.css'],
  providers: [
    LenderService,
    AgentService
  ]
})
export class CobradoresMovimientosComponent implements OnInit, OnDestroy {

  private querySub: Subscription;
  public selectedRow: CobradorMovimiento;
  public lenders: Lender[];
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;



  public ayuda: boolean = false;
  filtrosAbiertos: boolean = false;
  public hijosActivos: boolean = false;
  
  public showAsignarButton = false;
  public asignarButton = false;
  protected _onDestroy = new Subject<void>();
  public ajuste6Txt: string = 'Aplicar ajuste 6% nómina'
  public loaderAjuste6: boolean = false;
  public mostrarBotonAjuste6: boolean = false;
  private idCobradorFacturacionPeriodo: number;
  private idCobradorAjusteNomina: number;
  private fecInicioPeriodoAjuste: string;
  private fecFinPeriodoAjuste: string;
  private ajuste6Aplicado: boolean = false;
  public seValidoFechaInicio: boolean = false;
  public seValidoFechaFin: boolean = false;
  public today: Date = new Date();
  public pageSize = 50;
  public tablaMovimiento: any;
  cargandoLista: boolean = true;
  movs: any[];
  public pagination: CoMovimientoPagination;
  public filter: boolean = false;
  public hoy: Date = new Date();
  public fechaInicioDefault: Date = new Date(this.hoy.getTime() - 30 * 24 * 60 * 60 * 1000);  
  public filtros = {
    cobradorFacturacionPeriodo: null,
    nombrePeriodo: null,
    cobrador: null,
    nombreCobrador: null,
    concepto: null,
    nombreConcepto: null,
    movStat: 1,
    fechaInicio: this.fechaInicioDefault,
    fechaFin: this.hoy,
    page: 1,
    sortField: 'cm.created_at',
    sortDirection: 'desc'
  }
  sessionData: SessionData;
  fechaFin: string;
  fechaInicio: string; 
  queryParamsGlobal: string;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;



  constructor(
    private route: ActivatedRoute,
    public movsServ: CobradoresMovimientosService,
    private router: Router,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private conceptosServ: ConceptosSaldosService,
    private app: ApplicationService,
    public dialog: MatDialog,
    public filtrosService: FiltrosService,
    private saldosKobraServ: ServiciosSaldosKobraService,
    private lenderService: LenderService,
    public agenteServ: AgentService,
    private serviciosSaldosKobraService: ServiciosSaldosKobraService,
    private localStorageService: LocalStorageService,
  ) {
    this.crearTabla();
    this.movs = [];
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();

  }

  ngOnInit() {
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
    this.loadLenders();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  
  private agregarContabilizadoresDeFiltros(){
  
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'movStat',
        'concepto',
        'cobradorFacturacionPeriodo'
    ]);
    this.contabilizadorFiltros.add('Fechas registro', 'fechas', [
        'fechaInicio',
        'fechaFin'
    ]);
    this.contabilizadorFiltros.add('Fechas movimiento', 'agentes', [
        'cobrador'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
 
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('fechas') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('agentes') );

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}


  setTextoBotonAjuste6() {
    if (this.ajuste6Aplicado) {
      this.ajuste6Txt = 'Cancelar ajuste 6% nómina'
    } else {
      this.ajuste6Txt = 'Aplicar ajuste 6% nómina'
    }
  }

  crearTabla() {
    this.tablaMovimiento = {
      headers: false,
      multiSelect: false,
      columns: [
        {
          name: 'Periodo',
          key: 'nombrePeriodo',
          hide: false
        },
        {
          name: 'ID Agente',
          key: 'idCobrador',
          hide: false
        },
        {
          name: 'Agente',
          key: 'nombreCobrador',
          hide: false
        },
        {
          name: 'Concepto',
          key: 'nombreConcepto',
          hide: false
        },
        {
          name: 'Financiera',
          key: 'nombreFinanciera',
          hide: false
        },
        {
          name: 'Monto',
          key: 'monto',
          hide: false,
          type: 'money'
        },
        {
          name: 'Fecha Movimiento',
          key: 'createdAt',
          hide: false,
          type: 'dateTime'
        }
      ]
    }
  }


  loadLenders(): void {
    this.lenderService.getAll('all').subscribe((lenders) => {
      lenders.sort(this.compare);
      this.lenders = lenders;
    }, (err: any) => {
      this.app.showError(err);
    });
  }

  resultFiltros(filtrosNvos: any) {
    if (filtrosNvos) {
      this.filter = true;
      this.filtros = filtrosNvos;
    }
    this.obtieneMovimientos(this.filtros.page || 1);

    this.loaderAjuste6 = true;
    this.movsServ.obtenerAjuste6()
      .pipe(take(1))
      .subscribe((res) => {
        this.loaderAjuste6 = false;
        this.idCobradorFacturacionPeriodo = res.idCobradorFacturacionPeriodo;
        this.idCobradorAjusteNomina = res.idCobradorAjusteNomina;
        this.fecInicioPeriodoAjuste = res.fecInicio;
        this.fecFinPeriodoAjuste = res.fecFin;

        if (res && res.idCobradorAjusteNomina) {
          this.ajuste6Aplicado = true;
          this.setTextoBotonAjuste6();
        } else {
          this.ajuste6Aplicado = false;
          this.setTextoBotonAjuste6();
        }

        if (res && res.idCobradorFacturacionPeriodo) {
          this.mostrarBotonAjuste6 = true;
        } else {
          this.mostrarBotonAjuste6 = false;
        }
      }, (err) => {
        this.loaderAjuste6 = false;
        this.app.showError(err);
      });
  }

  setSortField(sort: any) {
    this.filtros.sortDirection = sort.direction;
    if (sort.active != this.filtros.sortField) {
      this.filtros.sortField = sort.active;
    }
    this.obtieneMovimientos(1);
  }

  validarTipoMov(){
    if(this.filtros.movStat == 3){
      this.filtros.cobradorFacturacionPeriodo = null;
      this.filtros.fechaInicio = this.fechaInicioDefault
      this.filtros.fechaFin = this.hoy
    }else if (this.filtros.movStat == 2) {
      this.filtros.fechaInicio = null
      this.filtros.fechaFin = null
    }
    
  }

  queryParamFiltros(page: number) {
    this.queryParamsGlobal = "";
    if(this.filtros.cobradorFacturacionPeriodo == null){
      
      this.queryParamsGlobal += this.filtros.fechaInicio ?
        `&fechaInicio=${this.filtros.fechaInicio.toISOString().substr(0, 10)}` : '';
  
      this.queryParamsGlobal += this.filtros.fechaFin ?
        `&fechaFin=${this.filtros.fechaFin.toISOString().substr(0, 10)}` : '';
    }
  
    if (this.filtros.movStat == null || this.filtros.movStat != 3) {
   
      this.queryParamsGlobal += this.filtros.cobradorFacturacionPeriodo ?
        `&idCobradorFacturacionPeriodo=${this.filtros.cobradorFacturacionPeriodo.idCobradorFacturacionPeriodo}` : '';
    }

    this.queryParamsGlobal += this.filtros.cobrador ? `&idCobrador=${this.filtros.cobrador.idCobrador}` : '';
    this.queryParamsGlobal += this.filtros.concepto ? `&idConcepto=${this.filtros.concepto.idConcepto}` : '';

    if (this.filtros.movStat && this.filtros.movStat !== 1) {
      this.queryParamsGlobal += `&movStat=${this.filtros.movStat}`;
    }

    if (page != this.filtros.page) {
      this.filtros.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }


    return this.queryParamsGlobal;
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.pageSize) {
      this.pageSize = newPagina.pageSize;
      this.obtieneMovimientos(newPagina.pageIndex + 1);
    }
  }

  public obtieneMovimientos(page: number) {
    this.cargandoLista = true;
    const campos = '"idCobradorMov","idCobradorFacturacionPeriodo","nombrePeriodo","idCobrador","nombreCobrador",' +
        '"idConcepto","nombreConcepto","nombreMovimiento","nombreFinanciera","monto","createdAt","cargo"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.filtros.sortField}${this.filtros.sortDirection ? ' ' + this.filtros.sortDirection : ''}`;
    queryParams += this.queryParamFiltros(page);
    this.movsServ.obtenerMovimientos(queryParams).subscribe(
      (res: any) => {
        if (res.success) {
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos,
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          for (let i = 0; i < datos.length; i++) {
            datos[i].cargo = datos[i].cargo ? 'Cargo' : 'Abono';
          }
          this.movs = datos;
        }
        this.cargandoLista = false;

      }, (error) => {
        this.app.showError(error);
        this.cargandoLista = false;
      },
    );
  }


  obtieneMovExcel(): void {
    const gridSize = this.pagination.totalRows

    if (gridSize > 30000){
      this.app.showSnackbar("Aviso", "El limite para la descarga es de 30,000 registros", 3000, 'warning')
      return;
    }

    if (gridSize >= 1) {
      let queryParams = "";
      queryParams += '?rows=' + this.pageSize;
      queryParams += '&idClienteAdministrador=' + this.sessionData.idClienteAdministrador + this.queryParamsGlobal;

      this.serviciosSaldosKobraService.descargarMovimientosAgentesExcel(queryParams)
        .pipe(take(1))
        .subscribe((respuesta) => {
          this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
        }, (error: any) => {
          this.app.showError(error);
        });
    } else {
      this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
    }
      
  }
  

  filterModal(parametro: boolean) {
    if (parametro) {
      Object.keys(this.filtros).forEach(key => {
        if (key != 'page' && this.filtros[key]) {
          this.filter = true;
        }
      });

      if (this.filtros.movStat == 3) {
        if (!this.filtros.fechaInicio || !this.filtros.fechaFin) {
          this.app.showSnackbar('Aviso', 'Es necesario elegir un rango de fechas.', 3000, 'warning');
          return;
        }
      }

      if (
        (this.filtros.fechaInicio &&
          !this.filtros.fechaFin)
        ||
        (!this.filtros.fechaInicio &&
          this.filtros.fechaFin)

      ) {
        this.app.showSnackbar('Aviso', 'Es necesario elegir rangos de fecha válidos', 3000, 'warning');
        return;
      }

      if (this.filtros.movStat === 3 && this.filtros.fechaInicio) {
        this.showAsignarButton = true;
      } else {
        this.showAsignarButton = false;
      }


      if (this.filter) {
        this.filtros.page = 1;
        //this.obtieneMovimientos(1);
        this.resultFiltros(null);
      }
    } else {

      this.removerFiltros();
      this.filter = false;
      this.filtros.page = 1;
      this.filtros.sortField = 'cm.created_at desc';
      this.filtros.cobradorFacturacionPeriodo;
      this.obtieneMovimientos(1);
    }
    this.filtrosAbiertos = !this.filtrosAbiertos;
    this.filtrosSideNav.toggle();
    this.contabilizadorDeFiltrosTotales();
  }

  removerFiltros() {
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
  }


  checkControl(control: string) {
    return !this.movsServ[control] && control == 'fechaInicio' ? this.seValidoFechaInicio : this.seValidoFechaFin;
  }

  public buscarConceptos = function (term: string) {
    return this.conceptosServ.buscarConceptos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idConcepto, nombre, idTipoConcepto }) => (
            { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto }
          )
        ))
      );
  }.bind(this);

  public periodosFilterSearch = function (term: string) {
    this.limpiarFechas();
    return this.periodoServ.searchPeriodos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(

          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo,nombre: `${idCobradorFacturacionPeriodo} - ${nombre}` }
          )

        ))
      );
  }.bind(this);

  public agenteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAgentes(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
      );
  }.bind(this);

  onAsignarPeriodo() {
    if (this.filtros.movStat != 3 || this.pagination.pageData.length <= 0) {
      this.app.showSnackbar('¡Aviso!', `Debes filtrar movimientos sin periodos de facturacion para poder realizar esta accion.`, 3000, 'warning');
      return;
    }
  
    const dialogRef2 = this.dialog.open(ModalAsignarPeriodoComponent, {
      data: {
        fechaInicio: this.filtros.fechaInicio,
        fechaFin: this.filtros.fechaFin,
        idCobrador: this.filtros.cobrador.idCobrador,
        idConcepto: this.filtros.concepto.idConcepto
      }
    });
    
    dialogRef2.afterClosed().subscribe(result => {
      //console.log(result)
      if (result) {
        this.filterModal(false);
        this.filtrosSideNav.toggle();
        this.obtieneMovimientos(1);
      }
    });
  }

  limpiarFechas() {
    this.filtros.fechaInicio = null;
    this.filtros.fechaFin = null;
  }

  setFechaFin(fecha: any) {
    if (!this.filtros.fechaFin) {
      this.filtros.fechaFin = fecha;
    }
  }



  public onAjuste6(): void {

    let fecha = this.fecInicioPeriodoAjuste.split('-');
    let fecha1 = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
    fecha = this.fecFinPeriodoAjuste.split('-');
    let fecha2 = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
    let texto = this.ajuste6Aplicado
      ? `La siguiente acción cancelará el ajuste del 6% para todos los gestores en el periodo ${fecha1} - ${fecha2}.`
      : `La siguiente acción generará un nuevo ajuste del 6% para todos los gestores en el periodo ${fecha1} - ${fecha2}.`;

    const dialogRef = this.dialog.open(ConfirmacionDialog, {

      data: {
        titulo: "Confirmar",
        mensaje: "¿Deseas continuar?",
        icono: "save",
        boton1: "Cancelar",
        boton2: "Guardar",
        texto: texto,
        claseAccion: "boton-accion-guardar"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.aplicarAjuste6();
      }
    });
  }

  aplicarAjuste6() {
    this.loaderAjuste6 = true;

    if (this.ajuste6Aplicado) {
      this.movsServ.cancelarAjuste6(this.idCobradorAjusteNomina)
        .pipe(take(1))
        .subscribe((res) => {
          this.loaderAjuste6 = false;
          this.ajuste6Aplicado = false;
          this.setTextoBotonAjuste6();

          this.app.showSnackbar('¡Aviso!', 'Ajuste cancelado correctamente.', 3000, 'success');
          this.obtieneMovimientos(1);
        }, (err) => {
          this.loaderAjuste6 = false;
          this.app.showError(err);
        });
    } else {
      this.movsServ.aplicarAjuste6({
        idCobradorFacturacionPeriodo: this.idCobradorFacturacionPeriodo,
        idConcepto: 96
      })
        .pipe(take(1))
        .subscribe((res) => {
          this.loaderAjuste6 = false;
          this.ajuste6Aplicado = true;
          this.idCobradorAjusteNomina = res.idCobradorAjusteNomina;
          this.setTextoBotonAjuste6();

          this.app.showSnackbar('¡Aviso!', 'Ajuste generado correctamente.', 3000, 'success');
          this.obtieneMovimientos(1);
        }, (err) => {
          this.loaderAjuste6 = false;
          this.app.showError(err);
        });
    }
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  onImportarXsl() {
    this.dialog.open(ModalImportarComponent);
  }

  inactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filter){
      this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
  }

}
