import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConceptoSaldo, ConceptoSaldoPagination } from './conceptos-saldo.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ConceptosSaldosService } from './conceptos-saldos.service';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {ConfirmacionDialog} from "../../modales-genericos/confirmacion/confirmacion-dialog.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { map } from 'rxjs/internal/operators/map';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


@Component({
  selector: 'app-conceptos-saldos',
  templateUrl: './conceptos-saldos.component.html',
  styleUrls: ['./conceptos-saldos.component.css']
})
export class ConceptosSaldosComponent implements OnInit, OnDestroy {

  private querySub: Subscription;
  ayuda: boolean = false;
  public selectedRow: ConceptoSaldo;
  hijosActivos: boolean = false;
  conceptos: any;
  public cargandoLista: boolean = false;
  public pageSize = 50;
  public itemsSelected: any[] = [];
  public pagination: ConceptoSaldoPagination;
  filtrosAbiertos: boolean = false;
  public filter = false;
  public filtros = {
    nombre: null,
    idTipoConcepto: null,
    activo: null,
    page: 1,
    sortField: 'idConcepto',
    sortAsc: ''
  }
  tabla: any;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  constructor(
    private route: ActivatedRoute,
    private app: ApplicationService,
    public cSaldosServ: ConceptosSaldosService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.conceptos = [];
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();

   }

  ngOnInit() {
    this.cSaldosServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
    this.cSaldosServ.obtenerTiposConceptos();
  }

  crearTabla() {
    this.tabla = {
        headers: false,
        multiSelect: false,
        columns: [
            {
                name: 'Folio',
                key: 'idConcepto',
                hide: false
            },
            {
                name: 'Concepto',
                key: 'nombre',
                hide: false
            },
            {
                name: 'Tipo Concepto',
                key: 'tipoConceptoNombre',
                hide: false
            },
            {
                name: 'Cargo',
                key: 'cargo',
                hide: false,
                type: 'boolean'
            },
            {
                name: 'Activo',
                key: 'activo',
                hide: false,
                type: 'boolean'
            }
        ]
    }
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'nombre',
        'idTipoConcepto',
        'activo'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}


  resultFiltros(filtrosNuevos: any){
    if(filtrosNuevos){
      this.cSaldosServ.filter = true;
      this.filter = true;
      this.filtros = filtrosNuevos;
    }
    this.cSaldosServ.filtros = this.filtros;
    this.obtenerConceptosSaldos(this.filtros.page || 1);
  }

  queryParamFiltros(page: number, descarga: boolean = false) {

    const campos = '"idConcepto", "idTipoConcepto","tipoConceptoNombre", "nombre", "cargo", "activo", "editable"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.filtros.sortField}${this.filtros.sortAsc ? ' ' + this.filtros.sortAsc : ''}`;

    queryParams += this.filtros.nombre ? `&nombre=${this.filtros.nombre}` : '';
    queryParams += this.filtros.idTipoConcepto ? `&idTipoConcepto=${this.filtros.idTipoConcepto}` : '';

    if (this.filtros.activo && this.filtros.activo !== 1) {
      queryParams += `&activo=${this.filtros.activo === 2 ? true : false}`;
    }

    if(page != this.filtros.page){
      this.filtros.page = page;
      this.cSaldosServ.filtrosService.guardarFiltros(this.filtros);
    }

    return queryParams;
  }

  setSortField(sort: any){
    this.filtros.sortAsc = sort.direction;
    if (sort.active != this.filtros.sortField) {
      this.filtros.sortField = sort.active;
    }
    this.obtenerConceptosSaldos(1);
  }

  public obtenerConceptosSaldos(page: number) {
    this.cargandoLista = true;
    const queryParams = this.queryParamFiltros(page);

    this.cSaldosServ.obtenerConceptosSaldos(queryParams).subscribe(
      (res: any) => {
        if(res.success){
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos, 
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          this.conceptos = datos;
        }
        this.cargandoLista = false;
        
      },(error) => {
            this.app.showError(error);
            this.cargandoLista = false;
        },
    );
  }


  ngOnDestroy() {
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
        this.obtenerConceptosSaldos(newPagina.pageIndex+1);
    }
  }

  onEdit(rowSeleccionado: any) {
    const msg = !rowSeleccionado ? 'Debes seleccionar un registro para editarlo.' :
      !rowSeleccionado.editable ? 'Este concepto no es editable.' : undefined;

    if (!msg) {
      this.cSaldosServ.setConceptoData(rowSeleccionado);

      this.router.navigate(
        ['editar', { 'id': rowSeleccionado.idConcepto }],
        {
          relativeTo: this.route,
          queryParamsHandling: 'preserve'
        }).then(() => {
          this.selectedRow = undefined;
        });
    } else {
      this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }
  }

  onDelete(rowSeleccionado: any) {
    const msg = !rowSeleccionado ? 'Debes seleccionar un registro para borrarlo.' :
      !rowSeleccionado.editable ? 'Este concepto no se puede borrar.' : undefined;

    if (!msg) {
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas borrar el concepto?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                claseAccion: "boton-accion-eliminar",
                texto: rowSeleccionado.nombre
            }
        });
  
        dialogRef.afterClosed().subscribe(result => {
  
            if(result) {
              this.eliminarRegistro(rowSeleccionado);
            }  
        });
    } else {
      this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }
  }

  eliminarRegistro(rowSeleccionado: any) {
    const loading = this.app.showLoading(
      `Eliminando información...`
    );
    this.cSaldosServ.eliminarConcepto(rowSeleccionado.idConcepto)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.app.showSnackbar('¡Aviso!', 'Concepto borrado correctamente.', 3000, 'success');
        const page = +this.filtros.page || 1;
        this.obtenerConceptosSaldos(page);
        this.app.hideLoading(loading);
      }, (error: any) => {
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  onDoubleClick({ row }) {
    this.onEdit(row);
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  public buscarConceptos = function(term: string) {
    let idTipoConceptoFinanciera: number = 1;

    if (this.conceptosFilterFlag) {
      this.conceptosFilterFlag = false;
      return this.conceptosFilterFounded;
    }
    return this.cSaldosServ.buscarConceptos(term, idTipoConceptoFinanciera)
        .pipe(
            take(1),
            map((res: any) => res.data.map(
                ({ idConcepto, nombre, idTipoConcepto }) => (
                    { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto }
                )
            ))
        );
  }.bind(this);



  public filterModal(parametro: boolean) {
    

    if(parametro){
      Object.keys(this.filtros).forEach(key => {
        if(key != 'page' && this.filtros[key]){
          this.filter = true;
        }
      });

      if (this.filter ) {
        this.filtros.page = 1;
        this.cSaldosServ.filtrosService.guardarFiltros(this.filtros);
        this.obtenerConceptosSaldos(1);
      }

    }else {

      if (!this.filter) {
        return;
      }

      this.filter = false;
      this.removerFiltros();
      this.filtros.page = 1;

      // const page = +this.route.snapshot.queryParamMap.get('_page') || 1;
      //this.obtenerConceptosSaldos(1);

    }

    this.contabilizadorDeFiltrosTotales();

  }

  removerFiltros(){
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
    this.filter = false;
  }

  inactivarHijo(){
    this.cSaldosServ.filtrosService.asignarOpcion();
    if(this.filter){
      this.cSaldosServ.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.cSaldosServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this), false);
  }
}
