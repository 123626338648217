import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Checklist } from "../modelos/checklist.model";
import { FiltrosChecklist } from "../modelos/filtros-checklist.model";
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ChecklistService } from '../../../checklist/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

declare var $: any;

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.css'],
  providers: [LenderService, ChecklistService]
})
export class ChecklistsComponent implements OnInit {
  private $filterModal: any;
  sessionData: SessionData;
  public idFinanciera: number;
  public financieras: any;
  public checklists: Checklist[];
  public filtros: FiltrosChecklist;
  public tableHeight: number = 600;
  public loadingClientes = false;
  public hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;
  cargandoChecklist: boolean = false;
  dejarSoloUltimoFiltro: boolean = false;
  ayuda: boolean = false;
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;


  public datosConsulta: any = {
    slt: '"idFinanciera", nombreFinanciera, "idSucursal", "nombreSucursal", alias, descripcion, createdAt, updatedAt, activa, estatus',
    pageSize: 100,
    rows: 10,
    sortField: 'idSucursal',
    sortAsc: true,
    query: "",
    fromRowPage: 0,
    toRowPage: 10
  }

  public loadings: any = {
    checklists: false,
    sucursales: true,
    financieras: true
  }

  constructor(
    private lenderService: LenderService,
    private checklistService: ChecklistService,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router,
    private rootElement: ElementRef
    )
  {
    this.sessionData = this.localStorageService.getSessionData();
    this.filtros = new FiltrosChecklist();
    this.checklists=[];
    
  }

  ngOnInit() {
    this.tableHeight = window.innerHeight * .70;
    localStorage.removeItem('checklistTemp');
    this.loadLenders();
  }

  respuestaFiltros(filtrosNuevos: FiltrosChecklist){
    if(filtrosNuevos){
      this.filtros = filtrosNuevos;
      this.idFinanciera = this.filtros.financiera.idFinanciera;
      if(this.dejarSoloUltimoFiltro){
        this.dejarSoloUltimoFiltro = false;
        this.filtrosService.guardarFiltros(this.filtros, true, null, false, '');
      }
      this.loadChecklists();
      return;
    }
    if(!this.sessionData.isSuperAdmin){
      this.idFinanciera = this.sessionData.idFinanciera;
      this.loadChecklists();
      return;
    }
  }

  public loadLenders(): void {
    this.loadingClientes = true;
    this.lenderService.getAll('all').subscribe(
      financieras => {
        this.financieras = financieras;
        if(!this.sessionData.isSuperAdmin){
          this.idFinanciera = this.sessionData.idFinanciera;
          this.onChangeLender(this.idFinanciera);
        }
        if(this.idFinanciera){
          this.onChangeLender(this.idFinanciera);
        }

        this.filtrosService.obtenerFiltrosGardados<FiltrosChecklist>(this.filtros, this.respuestaFiltros.bind(this));

        this.loadingClientes = false;
      },
      err => {
        this.app.showError(err);
        this.loadingClientes = false;
      },
      () => this.loadingClientes = false
    );
  }

  public onChangeLender(event){
    if(event){
      this.financieras.forEach(financiera => {
        if(financiera.idFinanciera == event){
          this.filtros.financiera = financiera;
        }
      });
    }
    else{
      this.filtros.financiera = null;
    }
  }

  public desactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.financiera){
      this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.dejarSoloUltimoFiltro = true;
    if(this.financieras){
      this.filtrosService.obtenerFiltrosGardados<FiltrosChecklist>(this.filtros, this.respuestaFiltros.bind(this));
    }
    else{
      this.loadLenders();
    }
  }

  public filtrar(){
    if (this.filtros.financiera === null) {
      this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un cliente.`, 3000, 'warning');
      this.filtrosSideNav.toggle();
      //${camposNulos.join(', ')}
      return; 
    }
    this.filtrosService.guardarFiltros(this.filtros);
    this.loadChecklists();
  }

  public limpiarFiltros(){
    if(this.filtros.financiera){
      this.idFinanciera = null;
      this.filtros.financiera = null;
    }
    this.filtrosService.removerFiltros();

  }

  public loadChecklists(){
    this.cargandoChecklist = true;
    this.checklists = [];
    this.checklistService
      .obtenerChecklistProductoPorCliente(this.filtros.financiera.idCliente)
      .subscribe(
        (res) => {
          this.checklists = res;
          this.cargandoChecklist = false;
        },
        (error) => {
          this.app.showError(error);
          this.cargandoChecklist = false;
        },
        () => {
          this.cargandoChecklist = false;
        }
      );
  }

  public desplegar(checklist: Checklist){
    if(checklist.checklistsInactivos.length > 0){
      checklist.desplegado = !checklist.desplegado;
    }
  }

  public configChecklist(checklist: Checklist){
    //let checklistParam = JSON.parse(JSON.stringify(checklist)) as Checklist;
   // checklistParam.checklistsInactivos = [];
    //localStorage.setItem('checklisTemp', JSON.stringify(checklist));
    //localStorage.setItem('nombre', this.filtros.financiera.nombre);
 
    this.router.navigate(
      ['configuracion', {}],
      {
        relativeTo: this.route,
        queryParams: 
          {
            nombreCliente: this.filtros.financiera.nombre,
            nombreCheckList: checklist.nombre,
            idChecklist: checklist.idChecklist,
            idCliente: checklist.idCliente           
          },
          queryParamsHandling: 'merge'      
      })
  }

  public editChecklist(checklist: Checklist){
    this.router.navigate(
      ['editar', {}],
      {
        relativeTo: this.route,
        queryParams:{
            idCliente: checklist.idCliente,
            nombreCliente: this.filtros.financiera.nombre,
            nombreCheckList: checklist.nombre,
            idTipoCredito: checklist.idTipoCredito,     
            idProducto: checklist.idProducto, 
            idChecklistCliente: checklist.idChecklistCliente
        },
        queryParamsHandling: 'merge'      
    })
  }

  public formato(checklist: Checklist, opcion: string) {
      this.router.navigate(
        [opcion, {}],
        {
          relativeTo: this.route,
          queryParams: 
            {
              nombreCliente: this.filtros.financiera.nombre,
              nombreCheckList: checklist.nombre,
              idChecklist: checklist.idChecklist,
              idCliente: checklist.idCliente,
              idProducto: checklist.idProducto,
              numVersion: checklist.numVersion, 
              idFinanciera: checklist.idFinanciera        
            },
            queryParamsHandling: 'merge'      
      })
  }

  abrirLayoutEditar(checklist: Checklist){
    /*let checklistParam = JSON.parse(JSON.stringify(checklist)) as Checklist;
    checklistParam.checklistsInactivos = [];
    localStorage.setItem('checklisTemp', JSON.stringify(checklist));*/

    this.router.navigate(
      ['layout',{}],
      {
        relativeTo: this.route,
        queryParams: 
          {
            nombreCliente: this.filtros.financiera.nombre,
            nombreCheckList: checklist.producto,
            idFinanciera: checklist.idFinanciera,
            idProducto: checklist.idProducto,
            idCliente: checklist.idCliente           
          },
          queryParamsHandling: 'merge'
        
      }
    );
  }
}
