<div [ngClass]="{'app': !metodoCancelarGuardarPropio, 'content': !metodoCancelarGuardarPropio, 'kobra': !metodoCancelarGuardarPropio}">
    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>Nuevo</mat-card-title>
      </mat-card-header>
        <mat-card-content class="kobra-card-content">
            <form [formGroup]="etiquetaForm">
                <mat-grid-list-responsive [colsXl]="3" [colsMd]="3" [colsSm]="1" rowHeight="80px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-form-field class="input-catalogo">
                          <mat-label>Nombre de etiqueta</mat-label>
                          <input matInput textSanitizer 
                            formControlName="nombre"
                            placeholder="Capture el nombre de la etiqueta">
                          <mat-icon matSuffix>sell</mat-icon>
                          <mat-error *ngIf="checkControl('nombre')">
                            {{getErrorMessage('nombre')}}
                          </mat-error>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list-responsive>
            </form>
            <div class="button-center">
                <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()">
                  <mat-icon class="kobra-icon">
                    close
                  </mat-icon> Cancelar</button>
                <button mat-stroked-button class="kobra-button" type="submit" (click)="onSubmit()" [disabled]="saveButton">
                  <mat-icon>
                    save
                  </mat-icon> Guardar</button>
            </div>
        </mat-card-content>
    </mat-card>

</div>