import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CobradorFacturacionPeriodo } from '../cobrador-facturacion-periodo.interface';
import { CobradoresFacturacionesPeriodosService } from '../cobradores-facturaciones-periodos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { take, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cobradores-facturaciones-periodos-form',
  templateUrl: './cobradores-facturaciones-periodos-form.component.html',
  styleUrls: ['./cobradores-facturaciones-periodos-form.component.css']
})
export class CobradoresFacturacionesPeriodosFormComponent implements OnInit, OnDestroy {

  public title: string;
  public pathTitle: string;
  public periodoForm: FormGroup;
  public saveButton = false;
  private loadRegsFlag = false;
  public cargando: boolean = false;
  @ViewChild('periodoFacInput') private periodoFacInput: ElementRef;
  get periodo(): CobradorFacturacionPeriodo {
    return this.periodoServ.periodo;
  }

  private fiSub: Subscription;
  private ffSub: Subscription;

  constructor(
    public periodoServ: CobradoresFacturacionesPeriodosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private app: ApplicationService,
    private router: Router
  ) {

    this.route.data
      .pipe(
        take(1),
        map(data => {
          return data['title'];
        })
      ).subscribe((title: string) => {
        this.pathTitle = title;
      });

    this.periodoForm = this.fb.group(
      {
        nombre: ['', Validators.required],
        fecInicio: ['', Validators.required],
        fecFin: ['', Validators.required]
      }
    );

  }

  ngOnInit() {
    /*setTimeout(() => {
      this.periodoFacInput.nativeElement.focus();
    });*/

    this.setFechasSubs();

    if (this.route.snapshot.paramMap.has('id')) {
      if (this.periodo) {
        this.title = `Editar "${this.periodo.nombre}"`;

        const fiArr = this.periodo.fecInicio.split('-');
        const fiDate = new Date(fiArr[0], fiArr[1] - 1, fiArr[2]);

        const ffArr = this.periodo.fecFin.split('-');
        const ffDate = new Date(ffArr[0], ffArr[1] - 1, ffArr[2]);

        this.periodoForm.get('nombre').patchValue(this.periodo.nombre);
        this.periodoForm.get('fecInicio').patchValue(fiDate);
        this.periodoForm.get('fecFin').patchValue(ffDate);
      } else {
        this.getPeriodo(() => {
          this.title = `Editar "${this.periodo.nombre}"`;
        });
      }
    } else {
      this.title = 'Agregar';
    }

  }

  ngOnDestroy() {
    //this.fiSub.unsubscribe();
  }

  checkControl(control: string) {
    return this.periodoForm.controls[control].invalid && this.periodoForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.periodoForm.controls[control].hasError('required') ? 'Este campo es requerido.' : '';
  }

  setFechasSubs() {
    this.fiSub = this.periodoForm.get('fecInicio').valueChanges.subscribe((fecha: any) => {
      if (this.periodoForm.get('fecFin').valid) {
        if (this.periodoForm.get('fecFin').value < fecha) {
          this.periodoForm.get('fecFin').patchValue(fecha);
        }
      }
    });

    this.ffSub = this.periodoForm.get('fecFin').valueChanges.subscribe((fecha: any) => {
      if (this.periodoForm.get('fecInicio').valid) {
        if (fecha < this.periodoForm.get('fecInicio').value) {
          this.periodoForm.get('fecFin').patchValue(this.periodoForm.get('fecInicio').value);
        }
      }
    });
  }

  onSubmit() {

    this.periodoForm.get('nombre').patchValue(this.periodoForm.get('nombre').value.trim());

    if (this.periodoForm.valid) {

      this.saveButton = true;

      if (this.title != 'Agregar') {
        this.update();
      } else {
        this.insert();
      }

    } else {
      (<any>Object).values(this.periodoForm.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }

  insert() {
    let loading: number = this.app.showLoading('Guardando información...');
    this.periodoServ.agregarPeriodo(this.periodoForm.value).subscribe((res: any) => {

          this.loadRegsFlag = this.pathTitle === 'Movimientos' ? false : true;
          this.saveButton = false;
          this.app.hideLoading(loading);
          this.app.showSnackbar('¡Aviso!', 'Periodo de factuación agregado correctamente.', 3000, 'success');
          this.router.navigate(['/cobradores-facturaciones-periodos'], { queryParamsHandling: 'preserve' });
        
      }, (error: any) => {
        this.app.showSnackbar('¡Aviso!', error, 3000, 'warning');
        this.saveButton = false;
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  update() {
    let loading: number = this.app.showLoading('Actualizando información...');
    this.periodoServ.actualizarPeriodo(this.periodo.idCobradorFacturacionPeriodo, this.periodoForm.value).subscribe((res: any) => {
    //  console.log("entro a update");
       // console.log(res);
        this.saveButton = false;
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Periodo de facturación actualizado correctamente.', 3000, 'success');
        this.router.navigate(['/cobradores-facturaciones-periodos'], { queryParamsHandling: 'preserve' });
      }, (error: any) => {
        this.saveButton = false;
        this.app.showSnackbar('¡Aviso!', error, 3000, 'warning');
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  getPeriodo(next?: () => void) {
    this.cargando = true;
    this.periodoServ.obtenerPeriodo(+this.route.snapshot.paramMap.get('id'))
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.periodoServ.setPeriodoData(res.data[0]);

        const fiArr = this.periodo.fecInicio.split('-');
        const fiDate = new Date(fiArr[0], fiArr[1] - 1, fiArr[2]);

        const ffArr = this.periodo.fecFin.split('-');
        const ffDate = new Date(ffArr[0], ffArr[1] - 1, ffArr[2]);

        this.periodoForm.get('nombre').patchValue(this.periodo.nombre);
        this.periodoForm.get('fecInicio').patchValue(fiDate);
        this.periodoForm.get('fecFin').patchValue(ffDate);

        if (next) {
          next();
        }
        this.cargando = false;
      }, (error) => {
        this.cargando = false;
        this.app.showError(error);
      });
  }

}
