import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DashboardUserService } from '../dashboard-user/dashboard-user.service';
import { take } from 'rxjs/internal/operators/take';
import { ApplicationService } from '../application/shared/application.service';
import { environmentSelector } from './../../environments/environment.selector';
import { VisitService } from './../visit/visit.service';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';

declare var $: any;
//declare var mapboxgl: any;

@Component({
    selector: 'mapbox-dashboard',
    templateUrl: 'mapbox-dashboard.component.html',
    styleUrls: ['mapbox-dashboard.component.css'],
    providers: [
        VisitService
    ]
  })

export class MapboxUsuariosComponent implements OnInit, OnChanges {
    private environment = environmentSelector();

    @Input() lat: number;
    @Input() lng: number;
    @Input() sucursales: any;
    @Input() products: any;
    public loading: boolean;
    filtrosAbiertos = false;

    geoJsonActiva = [];
    geoJsonEsperaConfirmacion = [];
    geoJsonApartada = [];
    geoJsonEspera = [];
    start = 0;
    rows = 1000;
    map = null;
    public today: string = new Date().toISOString();
    filtros: any = {
        fechaInicio: null,
        fechaFin: null,
        estatus: null,
        idProducto: null,
        sucursal: null
    };

    constructor(
        private app: ApplicationService,
        private visitService: VisitService,
        private dashboardUsuarioServices: DashboardUserService
    ){
        mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg';
    }

    public ngOnInit() {
        this.showMap();
        //this.consultarCuentasMapa();
    }

    public ngOnChanges(changes) {
        //this.showMap();
    }

    private showMap(): void {
        const poiOptions = {
            "width": 32,
            "height": 32,
            "x": 0,
            "y": 0,
            "pixelRatio": 1
        };
          
        this.map = new mapboxgl.Map({
        container: 'map2',
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [this.environment.paisLng, this.environment.paisLat],
        zoom: 4,
        ...poiOptions // spread operator para agregar las opciones de poi al objeto de opciones
        });
        

        this.map.on('load', () => {
            this.map.addSource('puntosActivaSource', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.geoJsonActiva
                }
            });
            this.map.addLayer({
                "id": "puntosActiva",
                "type": "circle",
                "source": 'puntosActivaSource',
                'paint': {
                    // Make circles larger as the user zooms from z12 to z22.
                    // Color circles by ethnicity, using a `match` expression.
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff',
                    'circle-color': [
                        'match',
                        ['get', 'ethnicity'],
                        'White',
                        '#fbb03b',
                        'Black',
                        '#223b53',
                        'Hispanic',
                        '#e55e5e',
                        'Asian',
                        '#3bb2d0',
            /* other */ '#00c977',
                    ]
                },
            });
            this.map.addSource('puntosEsperaSource', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.geoJsonEspera
                }
            });

            this.map.addLayer({
                "id": "puntosEspera",
                "type": "circle",
                "source": 'puntosEsperaSource',
                'paint': {
                    // Make circles larger as the user zooms from z12 to z22.
                    // Color circles by ethnicity, using a `match` expression.
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff',
                    'circle-color': [
                        'match',
                        ['get', 'ethnicity'],
                        'White',
                        '#fbb03b',
                        'Black',
                        '#223b53',
                        'Hispanic',
                        '#e55e5e',
                        'Asian',
                        '#3bb2d0',
            /* other */ '#ffe900',
                    ]
                },
            });
            this.map.addSource('puntosApartadaSource', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.geoJsonApartada
                }
            });
            this.map.addLayer({
                "id": "puntosApartada",
                "type": "circle",
                "source": 'puntosApartadaSource',
                'paint': {
                    // Make circles larger as the user zooms from z12 to z22.
                    // Color circles by ethnicity, using a `match` expression.
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff',
                    'circle-color': [
                        'match',
                        ['get', 'ethnicity'],
                        'White',
                        '#fbb03b',
                        'Black',
                        '#223b53',
                        'Hispanic',
                        '#e55e5e',
                        'Asian',
                        '#3bb2d0',
            /* other */ '#ea4686',
                    ]
                },
            });

            this.map.addSource('puntosEsperaConfirmacionSource', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.geoJsonEsperaConfirmacion
                }
            });
            this.map.addLayer({
                "id": "puntosEsperaConfirmacion",
                "type": "circle",
                "source": 'puntosEsperaConfirmacionSource',
                'paint': {
                    // Make circles larger as the user zooms from z12 to z22.
                    // Color circles by ethnicity, using a `match` expression.
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff',
                    'circle-color': [
                        'match',
                        ['get', 'ethnicity'],
                        'White',
                        '#fbb03b',
                        'Black',
                        '#223b53',
                        'Hispanic',
                        '#e55e5e',
                        'Asian',
                        '#3bb2d0',
            /* other */ '#00f1f4',
                    ]
                },

            });
        });
        this.popupHover('puntosActiva');
        this.popupHover('puntosEspera');
        this.popupHover('puntosApartada');
        this.popupHover('puntosEsperaConfirmacion');


    }

    obtenerMinDate(){
        if(this.filtros.fechaFin){
            let fecha = new Date(this.filtros.fechaFin);
            fecha.setMonth(fecha.getMonth()-1);
            return fecha;
        }
        return this.filtros.fechaFin;
    }

    obtenerMaxDate():any{
        if(this.filtros.fechaInicio){
            let fecha = new Date(this.filtros.fechaInicio);
            fecha.setMonth(fecha.getMonth()+1);
            return fecha;
        }
        return this.today;
    }

    limpiarCuentasMapa(){
        this.filtros = {
            fechaInicio: null,
            fechaFin: null,
            estatus: null,
            idProducto: null,
            sucursal: null
        };
        this.limpiarGeoJson();
    }

    prepararConsultaCuenta(){
        let query:string = '?';
        if(!this.filtros.estatus){
            this.app.showSnackbar('Seleccione estatus', 'Es necesario que seleccione el estatus de las cuentas', 3000, 'warning');
            return;
        }
        query+=`idEstatus=${this.filtros.estatus}&`;
        if(!this.filtros.fechaInicio){
            this.app.showSnackbar('Seleccione fecha inicio', 'Es necesario que seleccione la fecha inicio de las cuentas', 3000, 'warning');
            return;
        }
        query+=`fechaInicio=${moment(this.filtros.fechaInicio).format('YYYY-MM-DD')}&`;

        if(!this.filtros.fechaFin){
            this.app.showSnackbar('Seleccione fecha inicio', 'Es necesario que seleccione la fecha fin de las cuentas', 3000, 'warning');
            return;
        }
        query+=`fechaFin=${moment(this.filtros.fechaFin).format('YYYY-MM-DD')}&`;

        if(this.filtros.idProducto){
            query+=`idProducto=${this.filtros.idProducto}&`;
        }
        if(this.filtros.sucursal){
            query+=`idSucursal=${this.filtros.sucursal.idSucursal}&`;
            query+=`idGrupo=${this.filtros.sucursal.idGrupo}&`;
        }
        this.limpiarGeoJson();
        this.consultarCuentasMapa(query);
    }

    consultarCuentasMapa(query = '', filtro = true) {
        this.loading = true;
        this.visitService.obtenerCuentasMapa(this.start, this.rows, query)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.loading = false;
                if (!respuesta.error) {
                    respuesta.data.map((cuenta) => {
                        switch (cuenta['estatus']) {
                            case 'Activa':
                                this.geoJsonActiva.push(this.agregarGeoJson(cuenta));
                                break;
                            case 'Apartada':
                                this.geoJsonApartada.push(this.agregarGeoJson(cuenta));
                                break;
                            case 'Visitada':
                                this.geoJsonEspera.push(this.agregarGeoJson(cuenta));
                                break;
                            case 'En Espera de Confirmación':
                                this.geoJsonEsperaConfirmacion.push(this.agregarGeoJson(cuenta));
                                break;
                            default:
                                break;
                        }
                    })

                    if (respuesta.data.length < this.rows) {
                        if (filtro) {
                            this.reiniciarLayer();
                        } else {
                            this.showMap();
                        }
                        return
                    } else {
                        this.start += this.rows;
                        this.consultarCuentasMapa(query, filtro);
                    }

                    //this.showMap();



                }

            }, (err: any) => {
                this.loading = false;
                this.app.showError(err);
            });
    }

    agregarGeoJson(cuenta,) {
        var geoJsonToAdd = {
            'type': 'Feature',
            "geometry": {
                "type": "Point",
                "coordinates": [
                    cuenta['lng'],
                    cuenta['lat']
                ]
            },
            "properties": {
                'title': 'Prueba',
                'description':
                    `<table>
                <tr>
                  <td style="width: 180px">
                    <h3><b>${cuenta.nombreAcreditado}<b/></h3>
                  </td>
                </tr>
              </table><br>
              <b>ID Cuenta:</b> ${cuenta.idExterno}
              ${(cuenta.subIdExterno)?`<br><b>ID Subcuenta:</b> ${cuenta.subIdExterno}`:''}
              ${(cuenta.grupo)?`<br><b>Grupo:</b> ${cuenta.grupo}`:''}
              ${(cuenta.sucursal)?`<br><b>Sucursal:</b> ${cuenta.sucursal}`:''}
              <br><b>Folio Domicilio:</b> ${cuenta.folioDomicilio}
              <br><b>Domicilio:</b> ${cuenta.calle || ''} ${cuenta.numeroExterior || ''}${cuenta.numeroInterior ? ' ' : ''}${cuenta.numeroInterior || ''}, ${cuenta.colonia || ''}${cuenta.codigoPostal ? ' ' : ''}${cuenta.cp || ''}, ${cuenta.municipio || ''}, ${cuenta.estado || ''}
              <br><b>Estatus:</b>
              ${cuenta.estatus}
              ${(cuenta.idAgente)?`<br><b>Agente:</b> ${cuenta.idAgente} - ${cuenta.nombreAgente}`:''}
              <br><b>Ubicación:</b> ${cuenta.lat}, ${cuenta.lng}<br>
              `,
            }


            // 'title': 'Prueba',
            // "description": "" +
            //     "<h3><b>" + cuenta.nombreCompleto + "<b/></h3>" +
            //     "<br>" +
            //     "<b>ID Cuenta:</b> " + cuenta.idExterno + "" +
            //     "<br>" +
            //     "<b>Domicilio:</b> " + cuenta.domicilio +
            //     ", " +
            //     cuenta.ubicacion +
            //     "<br><b>Estatus:</b> " +
            //     cuenta.estatus

        }
        return geoJsonToAdd;
    }

    popupHover(layer) {
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        this.map.on('mousemove', layer, (e) => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = 'pointer';

            // Copy coordinates array.

            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            if (description) {
                popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
            }


            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.

        });

        this.map.on('mouseleave', layer, () => {
            this.map.getCanvas().style.cursor = '';
            popup.remove();
        });
    }

    reiniciarLayer() {
        this.map.getSource('puntosActivaSource').setData({
            "type": "FeatureCollection",
            "features": this.geoJsonActiva
        });

        this.map.getSource('puntosEsperaSource').setData({
            "type": "FeatureCollection",
            "features": this.geoJsonEspera
        });

        this.map.getSource('puntosApartadaSource').setData({
            "type": "FeatureCollection",
            "features": this.geoJsonApartada
        });

        this.map.getSource('puntosEsperaConfirmacionSource').setData({
            "type": "FeatureCollection",
            "features": this.geoJsonEsperaConfirmacion
        });



    }

    limpiarGeoJson() {
        this.start = 0;
        this.geoJsonActiva = [];
        this.geoJsonEspera = [];
        this.geoJsonApartada = [];
        this.geoJsonEsperaConfirmacion = [];
    }



}
