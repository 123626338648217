import { ServiciosSaldosKobraService } from '../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { Bank } from "./bank.model";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';

@Injectable()
export class BankService {
  private apiService: string = '/v2/banks';
  private servicesURL = '/cobradores';

  constructor(
    private http: ApiKobraService,
    private serviciosSaldos: ServiciosSaldosKobraService
  ) { }

  public all(): Observable<Bank[]> {
    return this.serviciosSaldos.get(this.servicesURL + "/bancos").pipe(
      map((res) => Bank.mapArray(res.data))
    );
  }
}