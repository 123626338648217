import { PhoneType } from "../phone-type/phone-type.model";

export class WorkReference {
  id: number
  agentID: number;
  cellphoneNumber: string;
  company: string;
  editMode: boolean;
  fullName: string;
  name: string;
  phoneTypeID: number;
  phoneType: PhoneType;
  phone: string;
  phoneNumber: string;
  surname: string;
  years: number;
  deleted?: number;
  idCobrador?: number;
  totalReferenciasLaborales?: number;

  constructor() {
    this.phoneType = new PhoneType();
  }
}