import { environmentSelector } from './../../../environments/environment.selector';
import { Account } from './../../account/account.model';
import { Component, OnInit, Input, EventEmitter, OnChanges, Output } from '@angular/core';
import { ApplicationService } from '../../application/shared/application.service';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';



declare var $: any;
//declare var mapboxgl: any;

@Component({
    // moduleId: module.id,
    selector: 'mapbox-cuentas',
    templateUrl: 'mapbox-cuentas.component.html',
    styleUrls: [
        'mapbox-cuentas.component.css'
    ]
})
export class MapboxCuentasComponent implements OnInit, OnChanges {
    private environment = environmentSelector();

    @Input() public cuentas: Account[];
    @Output() outputEmiter: EventEmitter<number> = new EventEmitter();
    //@Output() onSave: EventEmitter<contadorSeleccion> = number;

    public loading: boolean;

    geoJsonActiva = [];
    geoJsonEsperaConfirmacion = [];
    geoJsonApartada = [];
    geoJsonEspera = [];
    geoJsonInactiva = [];
    geoJsonSeleccionada = [];
    start = 0;
    rows = 1000;
    public map = null;
    public draw = null;
    public geoCercaACrear = null;
    public cuentasSeleccionadas =[];
    public contadorSeleccion: number = 0;

    constructor(
        private app: ApplicationService) {
        mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg';
        this.draw = new MapboxDraw.default({displayControlsDefault: false});

    }

    public ngOnInit() {
        this.loading = true;
        this.prepararCuentasMapa();
        this.setupMapEventListeners();
    }

    public ngOnChanges(changes) {
        //this.showMap();
    }

    public showMap(): void {
        const poiOptions = {
            "width": 32,
            "height": 32,
            "x": 0,
            "y": 0,
            "pixelRatio": 1
        };
          
        this.map = new mapboxgl.Map({
            container: 'mapCuentas', // container id
            style: 'mapbox://styles/mapbox/streets-v12', //stylesheet location
            center: [this.environment.paisLng, this.environment.paisLat], // starting positiontarting zoom,
            zoom: 4,
            attributionControl: false,
            ...poiOptions
        });

        this.map.on('load', () => {
            this.map.addLayer(this.agregarLayer("puntosActiva", '#00c977', this.geoJsonActiva));

            this.map.addLayer(this.agregarLayer("puntosApartada", '#ea4686', this.geoJsonApartada));

            this.map.addLayer(this.agregarLayer("puntosEspera", '#ffe900', this.geoJsonEspera));

            this.map.addLayer(this.agregarLayer("puntosEsperaConfirmacion", '#00f1f4', this.geoJsonEsperaConfirmacion));

            this.map.addLayer(this.agregarLayer("puntosInactivas", '#c2c2c2', this.geoJsonInactiva));

            this.map.addLayer(this.agregarLayer("puntosSeleccionados", '#000000', this.geoJsonSeleccionada));
            this.popupHover('puntosActiva');
            this.popupHover('puntosApartada');
            this.popupHover('puntosEspera');
            this.popupHover('puntosEsperaConfirmacion');
            this.popupHover('puntosInactivas');
            this.popupHover('puntosSeleccionados');
        });

        /*const draw = new MapboxDraw.default({
            displayControlsDefault: false,
            modes: {
              ...MapboxDraw.default.modes,
              draw_circle: CircleMode,
              drag_circle: DragCircleMode,
              direct_select: DirectMode,
              simple_select: SimpleSelectMode
            }
          });*/
        
        this.map.addControl(this.draw);

        
        this.loading = false;

    }

    private setupMapEventListeners() {
        this.map.on('draw.create', (e) => {
            this.handleDrawCreate(e);
        });
    }
    
private handleDrawCreate(event) {
    const selectedFeatures = event.features[0];
    const selectedPolygon = turf.polygon(selectedFeatures.geometry.coordinates);

    // Itera sobre tus cuentas y verifica si están dentro del polígono
    for (const cuenta of this.cuentas) {
        if (cuenta.lat && cuenta.lng) {
            const cuentaPoint = turf.point([cuenta.lng, cuenta.lat]);

            if (turf.booleanPointInPolygon(cuentaPoint, selectedPolygon)) {
                // La cuenta está dentro del polígono, marca como seleccionada
                cuenta.selected = true;
                this.contadorSeleccion += 1;
            }
        }
    }

    // Actualiza los layers para reflejar la selección
    this.reniniciarTodosLosLayer(this.cuentas);
    this.outputEmiter.emit(this.contadorSeleccion);

    // Borra el polígono
    this.draw.deleteAll();
}

    modoDibujar() {
        this.draw.changeMode(this.draw.modes.DRAW_POLYGON);    
    }

    public borrarSeleccion(){
        this.contadorSeleccion = 0;
        for (let i = 0; i < this.cuentas.length; i++) {
            const cuenta = this.cuentas[i];
            cuenta.selected = false;
        }

        this.reniniciarTodosLosLayer(this.cuentas);
    }

    private prepararCuentasMapa(mostrarMapa: boolean = true) {
        this.geoJsonActiva = [];
        if (this.cuentas) {
            for (let i = 0; i < this.cuentas.length; i++) {
                const cuenta = this.cuentas[i];
                if (cuenta.lat && cuenta.lng) {
                    switch (cuenta.status) {
                        case 'Activa':
                            this.geoJsonActiva.push(this.agregarGeoJson(cuenta));
                            break;
                        case 'Apartada':
                            this.geoJsonApartada.push(this.agregarGeoJson(cuenta));
                            break;
                        case 'Espera':
                            this.geoJsonEspera.push(this.agregarGeoJson(cuenta));
                            break;
                        case 'En Espera de Confirmación':
                            this.geoJsonEsperaConfirmacion.push(this.agregarGeoJson(cuenta));
                            break;
                        case 'Inactiva':
                            this.geoJsonInactiva.push(this.agregarGeoJson(cuenta));
                            break;
                        default:
                            break;
                    }
                }
                else {
                    this.cuentas.splice(i, 1);
                    i = i - 1;
                }
            }
        }

        this.showMap();

        //this.showMap();
        this.start += this.rows;
    }

    public reniniciarTodosLosLayer(cuentasNuevas: Account[]) {
        this.cuentas = cuentasNuevas;
        this.reiniciarLayer("Activa");
        this.reiniciarLayer("Apartada");
        this.reiniciarLayer("Visitada");
        this.reiniciarLayer("En Espera de Confirmación");
        this.reiniciarLayer("Inactiva");
        this.reiniciarLayer("Seleccionada");
    }

    private reiniciarLayer(estatus: String) {
        var geoJson = [];
        var source = "";
        switch (estatus) {
            case 'Activa':
                source = "puntosActivaSource";
                this.geoJsonActiva = [];
                break;
            case 'Apartada':
                source = "puntosApartadaSource";
                this.geoJsonApartada = [];
                break;
            case 'Visitada':
                source = "puntosEsperaSource";
                this.geoJsonEspera = [];
                break;
            case 'En Espera de Confirmación':
                source = "puntosEsperaConfirmacionSource";
                this.geoJsonEsperaConfirmacion = [];
                break;
            case 'Inactiva':
                source = "puntosInactivasSource";
                this.geoJsonInactiva = [];
                break;
            case 'Seleccionada':
                source = "puntosSeleccionadosSource";
                this.geoJsonSeleccionada = [];
                break;
            default:
                break;
        }

        let cuentasUsar = estatus == 'Seleccionada' ? this.cuentas.filter((cuenta) => cuenta.selected) : this.cuentas.filter((cuenta) => cuenta.status == estatus && !cuenta.selected);
        cuentasUsar.map((cuenta) => {
            geoJson.push(this.agregarGeoJson(cuenta));
        });

        var sourceMapa = this.map.getSource(source);

        if (sourceMapa) {
            sourceMapa.setData({
                "type": "FeatureCollection",
                "features": geoJson
            });
        } else {
            setTimeout(() => {
                this.map.getSource(source).setData({
                    "type": "FeatureCollection",
                    "features": geoJson
                });
            }, 4000);
        }
    }

    agregarGeoJson(cuenta: Account) {
        var geoJsonToAdd = {
            'type': 'Feature',
            "geometry": {
                "type": "Point",
                "coordinates": [
                    cuenta.lng,
                    cuenta.lat
                ]
            },
            "properties": {
                'title': cuenta.client.fullName,
                "circle-color": "#e55e5e",
                "description":
                    `<table>
            <tr>
              <td style="width: 180px">
                <h3><b>${cuenta.client.fullName}<b/></h3>
              </td>
            </tr>
          </table><br>
          <b>ID Cuenta:</b> ${cuenta.client.externalID}
          ${(cuenta.client.subExternalID) ? `<br><b>ID Subcuenta:</b> ${cuenta.client.subExternalID}` : ''}
          ${(cuenta.grupo) ? `<br><b>Grupo:</b> ${cuenta.grupo}` : ''}
          ${(cuenta.sucursal) ? `<br><b>Sucursal:</b> ${cuenta.sucursal}` : ''}
          <br><b>Folio Domicilio:</b> ${cuenta.client.address.folioDomicilio}
          <br><b>Domicilio:</b> ${cuenta.client.address.fullAddress}
          <br><b>Estatus:</b>
          ${cuenta.status}
          ${(cuenta.taken && cuenta.taken.agent && cuenta.taken.agent.id) ? `<br><b>Agente:</b> ${cuenta.taken.agent.id} - ${cuenta.taken.agent.fullName}` : ''}  
          <br><b>Seleccionada:</b>
          ${cuenta.selected ? 'Sí' : 'No'} <br>
          <b>Ubicación:</b> ${cuenta.lat}, ${cuenta.lng}<br>
          <div style="margin-top:10px">
            <button mat-stroked-button id="remover_${cuenta.id}"
                style="background: #d53708;
                color: white;
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #09080800;"
                >
                Remover cuenta
          </button>
          </div>
          `,
                "cuenta": cuenta
            }
        }
        return geoJsonToAdd;
    }

    popupHover(layer) {
        const popup = new mapboxgl.Popup({
            //closeButton: false,
            closeOnClick: false
        });

        //mousemove
        this.map.on('mousemove', layer, (e) => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('click', layer, (e) => {
            const cuentaActual: Account = Account.map(JSON.parse(e.features[0].properties.cuenta));
            if (cuentaActual) {
                popup.remove();
                this.map.getCanvas().style.cursor = '';
                this.seleccionarCuenta(cuentaActual);
            }
        });

        //mousemove
        this.map.on('contextmenu', layer, (e) => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = 'pointer';

            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;
            const cuentaActual: Account = Account.map(JSON.parse(e.features[0].properties.cuenta));

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            if (description) {
                popup.setLngLat(coordinates).setHTML(description).addTo(this.map);

                /*document.getElementById("cerrar_" + cuentaActual.id).addEventListener("click", (e) => {
                    popup.remove();
                    this.map.getCanvas().style.cursor = '';
                });*/
                document.getElementById("remover_" + cuentaActual.id).addEventListener("click", (e) => {
                    popup.remove();
                    this.map.getCanvas().style.cursor = '';
                    for (let i = 0; i < this.cuentas.length; i++) {
                        const element = this.cuentas[i];
                        if (element.client.externalID == cuentaActual.client.externalID && element.idCliente == cuentaActual.idCliente) {
                            this.cuentas.splice(i, 1);
                            this.reiniciarLayer(cuentaActual.selected ? 'Seleccionada' : cuentaActual.status);
                            i = this.cuentas.length;
                        }
                    }
                });
            }
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.

        });

        this.map.on('mouseleave', layer, () => {
            this.map.getCanvas().style.cursor = '';
            //popup.remove();
        });
    }

    seleccionarCuenta(cuentaActual: Account) {
        let cuenta = this.cuentas.find((cuenta) => cuenta.client.externalID == cuentaActual.client.externalID && cuenta.idCliente == cuentaActual.idCliente && cuenta.client.subExternalID == cuentaActual.client.subExternalID);
        if (cuenta) {
            cuenta.selected = !cuenta.selected;
            this.reiniciarLayer(cuentaActual.status);
            this.reiniciarLayer("Seleccionada");

            if(cuenta.selected) {
                this.contadorSeleccion = (this.contadorSeleccion + 1);
                this.outputEmiter.emit(this.contadorSeleccion);
            }else {
                this.contadorSeleccion = (this.contadorSeleccion - 1);
                this.outputEmiter.emit(this.contadorSeleccion);
                
            }
        }
    }

    agregarLayer(layer, color, geoJson) {
        this.map.addSource(layer + "Source", {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": geoJson
            }
        });
        return {
            "id": layer,
            "type": "circle",
            "source": layer + "Source",
            'paint': {
                'circle-radius': 6,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#ffffff',
                'circle-color': [
                    'match',
                    ['get', 'ethnicity'],
                    'Black',
                    '#223b53',
                    'Hispanic',
                    '#e55e5e',
                    'Asian',
                    '#ccc',
            /* other */ color
                ]
            }
        };
    }
}
