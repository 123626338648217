import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environmentSelector } from '../../../../environments/environment.selector';
import { IServicesTypeKobraResponse } from './servicio-general-response';
import { ConfiguracionAgente } from '../../../agent/modelos/configuracion-agente.model';
@Injectable({
  providedIn: 'root'
})
export class ServicesGeneralService {
  protected propertie: string = 'servicios'
  protected baseURL: string = environmentSelector().kobraServices['propertie'];
  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices['propertie'];
  }

  private headers(): HttpHeaders {
    let header: any = { 'Content-Type': 'application/json' };
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Authorization'] = token;
    }

    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    header['Country'] = paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
    return new HttpHeaders(header);
  }

  private request<T>(req: Observable<any>): Observable<IServicesTypeKobraResponse<T>> {

    return req.pipe(

      map(data => {
        let res = <IServicesTypeKobraResponse<T>>data;
        return res;
      }),
      catchError((err: HttpErrorResponse | string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            } 
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              message = err.error.error.message || 'Refresca la página e intentalo de nuevo.';
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete<T = any>(path: string): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.delete(this.baseURL + path, { headers: this.headers() }));
  }

  public get<T = any>(path: string): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.get(this.baseURL + path, { headers: this.headers() }));
  }

  public patch<T = any>(path: string, data: any): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.patch(this.baseURL + path, data, { headers: this.headers() }));
  }

  public put<T = any>(path: string, data: any): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.put(this.baseURL + path, data, { headers: this.headers() }));
  }

  public post<T = any>(path: string, data: any): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.post(this.baseURL + path, data, { headers: this.headers() }));
  }
}
