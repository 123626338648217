import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  breadcrumbs: Breadcrumb[] = [];
  esDash: boolean = true;
  @Input() tamanioGrande?: boolean = false;

  constructor(
    private route: ActivatedRoute
    , private servicioBreacrumb: BreadcrumbService
    , private router: Router
  ){
    this.router.events.subscribe(() => {
      this.breadcrumbs = [];
      let currentRoute = this.route.root;
      //console.log(this.breadcrumbs);
      this.checkBradcrumbForRoute(currentRoute.children);
      this.esDash = this.breadcrumbs.length == 0 || (this.breadcrumbs.length == 1 && this.breadcrumbs.some((breadcrumb)=> breadcrumb.route ==''));
    });
    this.servicioBreacrumb.setChangeBreadcrumbTitle(this.cambiarTituloDeBreadCrumb.bind(this), this.obtenerQueryParams.bind(this));
  }

  private cambiarTituloDeBreadCrumb(title: string){
    //console.log(this.breadcrumbs[this.breadcrumbs.length-1].label);
    setTimeout(() => {
      this.breadcrumbs[this.breadcrumbs.length-1].label = title;
      //console.log(this.breadcrumbs[this.breadcrumbs.length-1].label);
    }, 100);
  }

  public concatenarRuta(ruta: string){
    let respuesta:string = '';
 
    for (let index = 0; index < this.breadcrumbs.length; index++) {
      const element = this.breadcrumbs[index];
      
      if(element.route != ruta){
        respuesta += element.route + '/';
      }else{
        respuesta += element.route;
        index = this.breadcrumbs.length;
      }
    }
    return respuesta;
  }

  public obtenerQueryParams(){
    let breadcrumbActual = this.breadcrumbs[this.breadcrumbs.length-1]
    return breadcrumbActual.queryParams;
  }

  private checkBradcrumbForRoute(childrenRoutes: ActivatedRoute[]){
    let currentRoute:any = this.router.routerState.snapshot.root;
    while (currentRoute) {
      let title = currentRoute?.data?.['breadcrumb'];
      
      if (title) {
        if(!currentRoute.data.queryParams){
          currentRoute.data.queryParams = {}
        }
         
        if (currentRoute?.queryParams && Object.keys(currentRoute.queryParams).length > 0 && Object.keys(currentRoute.data.queryParams).length == 0 ) {
          currentRoute.data.queryParams = { ...currentRoute.queryParams };
        }

        
        Object.keys(currentRoute.data.queryParams).forEach(key => {
          const valor = currentRoute.data.queryParams[key];
          title = title.replace(`:${key}`, valor);
        });
          
          
        Object.keys(currentRoute.params).forEach(key => {
          const valor = currentRoute.params[key];
          title = title.replace(`:${key}`, valor);
        });

        this.breadcrumbs.push({
          label: title,
          route: currentRoute.url.map((segment: UrlSegment) => segment.path).join('/'),
          activo: false,
          queryParams: currentRoute?.data?.queryParams 
        });

        currentRoute.data.breadcrumb = title
      }

      currentRoute = currentRoute.firstChild;
    }


    if(this.breadcrumbs[this.breadcrumbs.length-1]){
      this.breadcrumbs[this.breadcrumbs.length-1].activo= true;
      

    }
  }
}
