import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CobradorFactura, CFPagination, CobradorMovsXFacturar, CMXFPagination, TotalPeriodosXFacturar, TotalPeriodosFacturados} from './cobrador-facturacion.interface';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CobradoresFacturacionesService {

  public movsXFacturar$: Observable<CobradorMovsXFacturar[]>;
  public totalPeriodosXFacturar$: Observable<TotalPeriodosXFacturar[]>;
  public totalPeriodosFacturados$: Observable<TotalPeriodosFacturados[]>;
  public CMXFpagination: CMXFPagination;
  public CMXFfromRowPage: number;
  public CMXFtoRowPage: number;
  public CMFXLoader: boolean;

  public cobFacturas$: Observable<CobradorFactura[]>;
  public CFpagination: CFPagination;
  
  public CFfromRowPage: number;
  public CFtoRowPage: number;
  public CFLoader: boolean;

  public estatusFacturaciones$: Observable<any>;
  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    private fb: FormBuilder
  ) {

  }

  obtenerPeriodosXFacturar(queryParams: string): Observable<any> {
    return this.saldosKobraServ.get(`/cobradores-periodos-por-facturar${queryParams}`);
  }

  obtenerPeriodosFacturados(queryParams: string): Observable<any>{
    return this.saldosKobraServ.get(`/cobradores-periodos-facturados${queryParams}`);
  }

  obtenerTotalesPeriodos(servicioUrl: string, queryParams:string): any {
    return this.saldosKobraServ.get(`/${servicioUrl}${queryParams}`);
  }

  obtenerEstatusFacturaciones() {
    if (this.estatusFacturaciones$) {
      return;
    }

    const param = 'facturacion';

    this.estatusFacturaciones$ = this.saldosKobraServ.get(`/estatus/${param}`)
      .pipe(
        map((res: any) => {
          res.data.unshift({ idEstatus: 'all', nombre: 'Todos' });
          return res.data;
        }),
        catchError((error: any) => {
          this.app.showError(error);

          return of([]);
        })
      );
  }

  obtenerPeriodosXFacturarExcel(queryParams:string): void {
    window.open(`${this.saldosKobraServ.baseURL}/cobradores-periodos-por-facturar-excel${queryParams}`);
  }

  obtenerPeriodosFacturadosExcel(queryParams:string) {
    window.open(`${this.saldosKobraServ.baseURL}/cobradores-periodos-facturados-excel${queryParams}`);
  }
}
