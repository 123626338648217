import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';
import { environmentSelector } from 'src/environments/environment.selector';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { ISucursalesAdministrador} from './sucursales.interface';

@Injectable({
    providedIn: 'root'
})
export class SucursalesService {
    baseURL: string = environmentSelector().kobraServices.servicios;
    private serviceURL: string = '/sucursales';
    private serviceAdministradoresURL: string = '/administradores';
    private serviceAdministradoresSucursalesURL: string = '/administradores/sucursales';

    constructor(
        private serviciosKobra: ServiciosKobraService,
        private serviciosVisitas: ServicesVisitasService,
        private httpClient: HttpClient) { }

    private headers(): HttpHeaders {
        let header: any = {'Content-Type': 'application/json'};
        let token = localStorage.getItem('access-token');
        if (token) {
            header['Authorization'] = token;
        }
        return new HttpHeaders(header);
    }

    public obtieneSucursalesPorCliente(idCliente: number): Observable<ISucursalesAdministrador[]> {
        return this.serviciosVisitas.get(`/sucursales?idCliente=${idCliente}`).pipe(
            map((res: ServiciosKobraResponse) => res.data)
        );
    }

    public obtieneSucursalesPorAdministrador(idCliente: number, idClienteAdministrador: number): Observable<ISucursalesAdministrador[]> {
        return this.serviciosVisitas.get(`/clientes/${idCliente}/administradores/${idClienteAdministrador}/sucursales`).pipe(
            map((res: ServiciosKobraResponse) => res.data)
        );
    }

    public editarAdministradorSucursales(idAdministradorFinanciera: number, sucursalesAdiministrador: ISucursalesAdministrador[] = [] ): Observable<any> {
        const body = { sucursales: sucursalesAdiministrador }; 
        return this.serviciosKobra.patch(`/administradores/${idAdministradorFinanciera}/sucursales`, body).pipe(
            map(res => {
                return res;
            })
        );
    }
    
}
