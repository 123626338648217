import { Period } from "../period/period.model";

export class AddressValidationChecklist {
  id: number;
  ageAddress: Period;
  companyName: string;
  ageJob: Period;
  relationship: string;
  relationshipName: string;
  isTheCustomer: boolean;
  createdAt: Date;
}