<app-modal [title]="(!data.nuevo ? 'Editar':'Agregar') + ' Referencia Laboral'" (onClose)="onClose()" [onlyClose]="true">
  <div *ngIf="form" class="container">
    <form [formGroup]="form" (submit)="save()">
      <div class="kobra two columns">
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: nameValidation.hasError()}">
            <mat-label>Nombre</mat-label>
            <input matInput textSanitizer  formControlName="name"
              placeholder="Ingrese el nombre">
            <mat-icon matSuffix>person</mat-icon>
            <mat-error>{{nameValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: surnameValidation.hasError()}">
            <mat-label>Apellido</mat-label>
            <input matInput textSanitizer  formControlName="surname"
              placeholder="Ingrese el apellido">
            <mat-icon matSuffix>person</mat-icon>
            <mat-error>{{surnameValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column two-colspan">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: companyValidation.hasError()}">
            <mat-label>Empresa</mat-label>
            <input matInput textSanitizer  formControlName="company"
              placeholder="Nombre de la empresa en que labora">
            <mat-icon matSuffix>enterprise</mat-icon>
            <mat-error>{{companyValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: phoneNumberValidation.hasError()}">
            <mat-label>Número de teléfono</mat-label>
            <input matInput textSanitizer  formControlName="phoneNumber"
              placeholder="Ingrese el número de teléfono">
            <mat-icon matSuffix>call</mat-icon>
            <mat-error>{{phoneNumberValidation.error}}</mat-error>
          </mat-form-field>
        </div>
        <div class="kobra column">
          <mat-form-field class="grid-tile-form-field" [ngClass]="{error: phoneTypeValidation.hasError()}">
            <mat-label>Tipo de teléfono</mat-label>
            <mat-select
              formControlName="phoneType"
              placeholder="Seleccione..."
            >
              <mat-option *ngFor="let type of phoneTypes" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix>call</mat-icon>
            <mat-error>{{phoneTypeValidation.error}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <!-- <button mat-stroked-button m-actions class="boton-accion-eliminar" *ngIf="!data.nuevo" (click)="delete()"><mat-icon>delete_forever</mat-icon>Eliminar</button> -->
  <button mat-stroked-button m-actions class="boton-guardar" (click)="save()" [disabled]="form.invalid"><mat-icon>
    save
  </mat-icon>Guardar</button>
</app-modal>

<!--form class="ui small modal" [formGroup]="form" (ngSubmit)="save()">
  <i class="close icon"></i>
  <div class="header">
    {{workReference.id ? 'Editar':'Agregar'}} referencia laboral
  </div>
  <div class="content">
    <div class="ui form">
      <div class="two fields">
        <div class="field" [ngClass]="{error: nameValidation.hasError()}">
          <label for="">Nombre</label>
          <input type="text" placeholder="Nombre de la referencia" formControlName="name">
          <label *ngIf="nameValidation.hasError()">{{nameValidation.error}}</label>
        </div>

        <div class="field" [ngClass]="{error: surnameValidation.hasError()}">
          <label for="">Apellido</label>
          <input type="text" placeholder="Apellido de la referencia" formControlName="surname">
          <label *ngIf="surnameValidation.hasError()">{{surnameValidation.error}}</label>
        </div>
      </div>

      <div class="field" [ngClass]="{error: companyValidation.hasError()}">
        <label for="">Empresa</label>
        <input type="text" placeholder="Nombre de la empresa en que labora" formControlName="company">
        <label for="" *ngIf="companyValidation.hasError()">{{companyValidation.error}}</label>
      </div>

      <div class="two fields">
        <div class="field" [ngClass]="{error: phoneNumberValidation.hasError()}">
          <label for="">Número de teléfono</label>
          <input type="text" placeholder="Numero de teléfono con lada" formControlName="phoneNumber" maxlength="10">
          <label for="" *ngIf="phoneNumberValidation.hasError()">{{phoneNumberValidation.error}}</label>
        </div>
        
        <div class="field" [ngClass]="{error: phoneTypeValidation.hasError()}">
          <label for="">Tipo de teléfono</label>
          <select formControlName="phoneType">
            <option [ngValue]="null">Seleccione...</option>
            <option *ngFor="let type of phoneTypes" [value]="type.id">{{type.name}}</option>
          </select>
          <label for="" *ngIf="phoneTypeValidation.hasError()">{{phoneTypeValidation.error}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ui button" type="button" (click)="$modal.modal('hide');">Cancelar</button>
    <button class="ui negative button" type="button" *ngIf="workReference.id" (click)="delete()">Eliminar</button>
    <button class="ui primary button" [disabled]="form.invalid">Guardar</button>
  </div>
</form-->