import { PaqueteVisitasPrecios } from "../paquete-visitas-precios/paquete-visitas-precios.model";
export class PaqueteFinanciera {
  idFinancieraPaquete: number;
  idPaquete: number;
  nombre: string;
  idFinanciera: number;
  precio: number;
  saldo: number;
  paqueteActual: boolean;
  paquetesVisitasPrecios: PaqueteVisitasPrecios[];
  activo: boolean;
  createdAt: Date;

  constructor() {
    this.idPaquete = null;
    this.precio = 0;
    this.saldo = 0;
  }
}
