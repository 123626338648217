<h1 mat-dialog-title>Limite de cuentas</h1>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <mat-label>Limite de cuentas apartadas</mat-label>
    <input matInput textSanitizer  [(ngModel)]="limiteDeCuentasApartadas" 
      placeholder="Capture el limite de cuentas apartadas"
      (input)="onInputLimite()"
      min="1" max="999"
      maxlength="3"
      minlength="1"
      type="number">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" >
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button
    *ngIf="data.isSuperAdmin"
    class="boton-guardar"
    (click)="guardarLimiteCuentas()">
    <mat-icon>save</mat-icon>
    Guardar
  </button>
</mat-dialog-actions>


