import { Injectable } from '@angular/core';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ApplicationService } from '../application/shared/application.service';
import { Pagination } from '../pagination/pagination.model';
import { VisitPaginator } from '../visit/visit.paginator';
import { Visit } from '../visit/visit.model';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { SessionData } from '../shared/interfaces/session-data';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CalificarObligatoriosService {

  public loader: boolean;
  public loaderTxt: string;
  sessionData: SessionData;

  constructor(
    private lServices: ServiciosKobraService,
    private app: ApplicationService,
    private serviciosVisitas: ServicesVisitasService,
    private localStorageService: LocalStorageService
    
  ) { this.sessionData = this.localStorageService.getSessionData();}

  callLoader(show: boolean, txt?: string) {
    this.loader = show;
    this.loaderTxt = txt;
  }

  getVisits(pagination: Pagination, extraParams: string, rows: number): Observable<VisitPaginator> {
    let start = 0;

    if (pagination.nextPage > 1) {
      start = ((pagination.nextPage - 1) * rows) + 1;
    }

    const query = `/visitas-obligatorias-calificar?start=${start}${extraParams}`;

    return this.serviciosVisitas.get(`${query}`).pipe(
      map(res => {
        const response: any = res.data;
        const visitPaginator: VisitPaginator = new VisitPaginator();
        visitPaginator.pagination = new Pagination();
        visitPaginator.pagination.currentPage = start ? Math.ceil(start / rows) : 1;
        visitPaginator.pagination.pageCount = Math.ceil(response.totalRows / rows);
        visitPaginator.pagination.perPage = rows;
        visitPaginator.pagination.totalCount = response.totalRows;
        visitPaginator.data = [];


        for (let i = 0; i < response.pageData.length; i++) {
          const visit: Visit = new Visit();
          let queryString = '';
          visit.id = response.pageData[i].idVisita;
          visit.folio = response.pageData[i].folioVisita;
          visit.cancellationReason = response.pageData[i].motivoCancelacion;
          visit.cancelled = response.pageData[i].cancelada;
          visit.createdAt = response.pageData[i].inicioVisita;
          visit.account.id = response.pageData[i].idCuenta;
          visit.idSubcuenta = response.pageData[i].idSubcuenta;
          visit.account.createdAt = response.pageData[i].creacionCuenta;
          visit.account.product.id = response.pageData[i].idProducto;
          visit.account.product.name = response.pageData[i].tipoProducto;
          visit.agent.id = response.pageData[i].idCobrador;
          visit.idAgenteM = response.pageData[i].idAgente;
          visit.agent.fullName = response.pageData[i].nombreCobrador;
          visit.client.idAcreditado = response.pageData[i].idAcreditado;
          visit.client.fullName = response.pageData[i].nombreAcreditado;
          visit.client.externalID = response.pageData[i].idExterno;

          //visit.clientAddress.id = response.pageData[i].clientAddressID;
          //visit.clientAddress.city = response.pageData[i].ciudad;
          visit.clientAddress.folioDomicilio = response.pageData[i].folioDomicilio;
          visit.clientAddress.municipality = response.pageData[i].municipio;
          visit.clientAddress.state = response.pageData[i].estado;
          //visit.clientAddress.location = response.pageData[i].clientAddressLocation;

          // visit.generalChecklist.id = response.pageData[i].generalChecklistID;
          // visit.generalChecklist.createdAt = response.pageData[i].generalChecklistCreatedAt;
          // visit.generalChecklist.comment = response.pageData[i].generalChecklistComment;
          // visit.generalChecklist.debtCollectionChecklist.id =
          //   response.pageData[i].debtCollectionChecklistID;
          visit.generalChecklist.debtCollectionChecklist.paymentPromise =
             response.pageData[i].promesaPago;
          // visit.generalChecklist.debtCollectionChecklist.paymentPromiseAmount =
          //   response.pageData[i].debtCollectionChecklistPaymentPromiseAmount;

          visit.lender.id = response.pageData[i].idCliente;
          visit.lender.name = response.pageData[i].nombreCliente;
          visit.rebound = +response.pageData[i].repunteo ? true : false;
          visit.generalChecklist.mockLocation = +response.pageData[i].mock ? true : false;
          visit.visibility = response.pageData[i].agenteInterno;
          visit.calificacionAdministradorFinanciera = response.pageData[i].calificacionAdministradorFinanciera;
          visit.calificacionSuperAdministrador = response.pageData[i].calificacionSuperAdministrador;
          queryString = response.pageData[i].subIdExterno != null ? `&subIdExterno=${response.pageData[i].subIdExterno}` : "";

          if(this.sessionData.isSuperAdmin){
              queryString += "&idCliente="  + response.pageData[i].idCliente;
          };

          visit.account.urlDetalleCuenta = `?idExterno=${response.pageData[i].idExterno}${queryString}`;

          visitPaginator.data.push(visit);
        }

        return visitPaginator;
      })
    );
  }
}
