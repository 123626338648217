import { Injectable } from '@angular/core';

import { AgentChecklist } from "./agent-checklist.model";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AgentChecklistService {
  private apiService: string = '/v2/agents-checklist';

  constructor(
    private http: ApiKobraService
  ) { }

  public checkPersonalReferences( checklist: AgentChecklist ): Observable<any> {
    return this.http
      .post(`${this.apiService}/personal-references`, checklist).pipe(
        map(res => res.data)
      );
  }

  public checkProfilePicture( checklist: AgentChecklist ): Observable<any> {
    return this.http
      .post(`${this.apiService}/profile-picture`, checklist).pipe(
        map(res => res.data)
      );
  }

  public checkWorkExperience( checklist: AgentChecklist ): Observable<any> {
    return this.http
      .post(`${this.apiService}/work-experience`, checklist).pipe(
        map(res => res.data)
      );
  }

  public checkWorkReferences( checklist: AgentChecklist ): Observable<any> {
    return this.http
      .post(`${this.apiService}/work-references`, checklist).pipe(
        map(res => res.data)
      );
  }
}