import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TarifaConceptosService } from 'src/app/tarifa-conceptos/tarifa-conceptos.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { take  } from 'rxjs/operators';
import { ProductosSaldosService } from '../productos-saldos.service';
import { Lender } from "../../../lender/lender.model";
import { LenderService } from "../../../lender/lender.service";
import { Producto } from "./producto.model";


@Component({
  selector: 'app-editar-tarifa',
  templateUrl: './editar-tarifa.component.html',
  styleUrls: ['./editar-tarifa.component.css'],
  providers: [TarifaConceptosService, LenderService]
})
export class TarifaProductosComponent implements OnInit {
  public loaderText: string;
  public tarifaConceptosForm: FormGroup;
  public desabilitaButon = false;
  public hijosActivos: boolean = false;
  public formTarifas: FormGroup;
  public lenders: Lender[];
  public idLender: number = null;
  public acuerdo: boolean = false;
  public selectedFinanciera: string;
  public loaderAdd: string;
  public globalIdFInanciera: number;
  public editarPrecio: number;
  public editarNombreFinanciera: string;
  public editar: boolean = false;
  public producto: Producto;
  public totalRegistros: number;
  modoEdicion: boolean = false;
  public cargandoLista = true;
  public tarifasAgregadas: any[] = [];
  tablatarifasCobradores: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      {
        name: 'Financiera',
        key: 'financiera',
        hide: false
      }, {
        name: 'Producto',
        key: 'nombre',
        hide: false
      }, {
        name: 'Precio',
        key: 'precio',
        hide: false
      }, {
        name: ' ',
        key: 'eliminar',
        hide: false,
        icono: 'delete_forever',
        tooltip: "Eliminar",
        type: 'icono',
      }
     
    ]
  };
  dialog: any;
  constructor(
    private acRoute: ActivatedRoute,
    private fb: FormBuilder,
    private tarifaConceptosService: TarifaConceptosService,
    private app: ApplicationService,
    private productosSaldosService: ProductosSaldosService,
    private router: Router,
    private lenderService: LenderService,    


  ) {
    this.tarifaConceptosForm = this.fb.group({
      'nombre': ['', [Validators.required, Validators.maxLength(40)]],
      'precioProducto': ['', Validators.required],
      'idConcepto': '',
      'precioConcepto': '',
      'financiera' : '',
      items: this.fb.array([])
    });
    this.tarifasAgregadas = [];
    this.createForms();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.producto = new Producto();
    this.producto.idConcepto = +this.acRoute.snapshot.queryParamMap.get('idConcepto');
    this.producto.idTarifa = +this.acRoute.snapshot.queryParamMap.get('idTarifa');
    this.producto.nombre = this.acRoute.snapshot.queryParamMap.get('nombre');
    this.producto.precio = +this.acRoute.snapshot.queryParamMap.get('precio');

    this.tarifaConceptosForm.controls['nombre'].setValue(this.producto.nombre);
    this.tarifaConceptosForm.controls['nombre'].disable();
    this.tarifaConceptosForm.controls['precioProducto'].setValue(this.producto.precio);
    this.tarifaConceptosForm.controls['precioConcepto'].setValue(0);
    this.tarifaConceptosForm.controls['precioProducto'].enable();
    this.loadLenders();
    this.cargarDatosEditar();
  }


  onClick({ row, columnPressed }) {
    if (columnPressed == 'eliminar') {
      this.borrarConcepto(row);
    }
    
   
  }


  checkControl(control: string) {
    return this.tarifaConceptosForm.controls[control].invalid && this.tarifaConceptosForm.controls[control].touched;
  }

  cargarDatosEditar() {
    if (this.producto.idConcepto > 0) {
      this.loaderText = 'Cargando información...';
      this.tarifaConceptosService.obtenerPaqueteVisitasPrecios(this.producto.idConcepto).subscribe(tarifasCobradores => {
        this.tarifasAgregadas = tarifasCobradores;
        this.producto.tarifasCobradores = tarifasCobradores;
        this.loaderText = null;
        this.totalRegistros = this.tarifasAgregadas.length;
        this.cargandoLista = false;

      },
      error => {
        this.app.showError(error);
        this.loaderText = null;
      });
    }
  }

  private createForms(): void {
    this.formTarifas = this.fb.group({
      financiera: [null, []],
      sucursal: [null, []],
    });
  }

  getErrorMessage(control: string) {
    return this.tarifaConceptosForm.controls[control].hasError('required') ? 'Este campo es requerido.' :
      this.tarifaConceptosForm.controls[control].hasError('maxlength') ? 'Sólo se permiten 40 caracteres.' : '';
  }


  agregarConcepto() {


    let msg: string | null = this.validarConcepto();

    if (msg) {
      return this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }

    let idxFinancieras = this.tarifasAgregadas.findIndex(x => x.idFinanciera === +this.idLender);

    if (idxFinancieras >= 0) {
      // Financiera ya existe, así que actualizamos en lugar de agregar
      const nombreFinanciera = this.editar ? this.editarNombreFinanciera : this.selectedFinanciera;

      // Buscamos la financiera existente y la actualizamos
      const financieraExistente = this.tarifasAgregadas[idxFinancieras];
      financieraExistente.nombre = this.producto.nombre;
      financieraExistente.precio = +this.tarifaConceptosForm.controls['precioConcepto'].value;
      financieraExistente.financiera = nombreFinanciera;

      // Actualizamos también en el objeto producto.tarifasCobradores
      const idxProducto = this.producto.tarifasCobradores.findIndex(x => x.idFinanciera === +this.idLender);
      if (idxProducto >= 0) {
        this.producto.tarifasCobradores[idxProducto] = financieraExistente;
      }

      this.app.showSnackbar('¡Aviso!', 'Producto actualizado correctamente.', 3000, 'success');
    } else {
      // Financiera no existe, la agregamos
      const nombreFinanciera = this.editar ? this.editarNombreFinanciera : this.selectedFinanciera;

      const nuevoConcepto = {
        idConcepto: this.producto.idConcepto,
        nombre: this.producto.nombre,
        precio: +this.tarifaConceptosForm.controls['precioConcepto'].value,
        idFinanciera: +this.idLender,
        financiera: nombreFinanciera
      };

      this.producto.tarifasCobradores = [...this.producto.tarifasCobradores, nuevoConcepto];
      this.tarifasAgregadas = [...this.tarifasAgregadas, nuevoConcepto];

      this.app.showSnackbar('¡Aviso!', 'Financiera agregada correctamente.', 3000, 'success');
    }

    //this.mostrarSnackbarResultado();

    this.totalRegistros = this.tarifasAgregadas.length;
    this.editar = false;
    this.limpiar();

  }
    

  private validarConcepto(): string | null {
    if (!this.idLender) {
      return 'Selecciona una financiera.';
    } else if (!/^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(this.tarifaConceptosForm.controls['precioConcepto'].value)) {
      return 'Precio del producto inválido.';
    } else if (this.tarifaConceptosForm.controls['precioConcepto'].value === 0 || this.tarifaConceptosForm.controls['precioConcepto'].value === '') {
      return 'Favor de agregar el precio.';
    }
    return null;
  }

  

  public limpiar(): void {
    this.formTarifas.get('financiera').patchValue(undefined);
    this.idLender = null;
    this.tarifaConceptosForm.controls['precioConcepto'].setValue(0);
    this.modoEdicion = false;

  }

  borrarConcepto(registroSeleccionado: any) {
    
    this.tarifasAgregadas = this.tarifasAgregadas.filter((item) => item.idFinanciera !== registroSeleccionado.idFinanciera);
    this.producto.tarifasCobradores = this.producto.tarifasCobradores.filter((item) => item.idFinanciera !== registroSeleccionado.idFinanciera);
    

  }

  editarConcepto(row) {
    this.editar = true;

    // Verifica si el control 'financiera' existe en el formulario
    const controlFinanciera = this.tarifaConceptosForm.controls['financiera'];
    if (controlFinanciera) {
      controlFinanciera.setValue(row.idFinanciera);
    } else {
      console.error('El control "financiera" no existe en el formulario.');
    }

    // Asigna el valor del control 'precioConcepto'
    this.tarifaConceptosForm.controls['precioConcepto'].setValue(+row.precio);

    // Elimina el elemento de la lista
    this.tarifasAgregadas.splice(this.tarifasAgregadas.indexOf(row), 1);

  }

  cancelarEdicion() {
    this.editar = false;
    this.tarifasAgregadas.push({
      idConcepto: this.producto.idConcepto,
      nombre: this.producto.nombre,
      precio: +this.editarPrecio,
      idFinanciera: +this.globalIdFInanciera,
      financiera: this.editarNombreFinanciera
    });
    this.limpiar();
  }

  onSubmit() {
    this.desabilitaButon = true;
    this.loaderText = 'Guardando información...';
    this.producto.precio = this.tarifaConceptosForm.controls['precioProducto'].value;
    this.editarProducto();
  }

  editarProducto() {
    
    this.productosSaldosService.editarProducto(this.producto.idConcepto, this.producto)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.desabilitaButon = false;
        if (res.success) {
          this.app.showSnackbar('¡Aviso!', 'información guardada correctamente.', 3000, 'success');
        } else {
          this.app.showSnackbar('¡Aviso!', 'Ocurrio un error al guardar la información.', 3000, 'error');
        }

        this.router.navigate([`catalogos/tarifas-agentes/lista-tarifa`]);
      }, (error: any) => {
        this.desabilitaButon = false;
        this.loaderText = null;
        this.loaderAdd = null;
        this.app.showError(error);
      });
  }

  public loadLenders(): void {
    this.loaderText = "Cargando financieras...";
    this.lenderService.getAll('all').subscribe(
      lenders => {
        this.lenders = lenders;
      },
      err => {
        this.app.showError(err);
        this.loaderText = null;
      },
      this.loaderText = null
    );
  }

  public onChangeLender(event: any) {
    if (event) {
      let index: number = this.lenders.findIndex((x: any) => x.idFinanciera == this.idLender);
      this.selectedFinanciera = this.lenders[index].nombre;
  
      // Verifica si la financiera ya existe en tablatarifasCobradores
      const indexEnTablatarifasCobradores = this.tarifasAgregadas.findIndex(item => item.idFinanciera === +this.idLender);
      this.modoEdicion = indexEnTablatarifasCobradores !== -1;
    }
  }
  

 

}
