<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; hijoDesactivado();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container-wrapper" [ngClass]="{'button-container': !filtros.mostrarMapa, 'button-container-mapa': filtros.mostrarMapa}">
        
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
          <mat-button-toggle value="filtros" 
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"  
            (click)="descargarListado()"
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"  
            (click)="cuentasAgrupadasObtener(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizarMapa"
            *ngIf="filtros.mostrarMapa"  
           
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayudaComentario(!ayuda)">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0 || !clienteActivo"
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogEstatus()">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Estatus</span>
      </button>
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0 || !clienteActivo "
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogEtiquetaOperaciones()">
        <mat-icon class="kobra-icon">bookmark_border</mat-icon>
        <span>Etiquetas</span>
      </button>
      <button mat-menu-item
        [disabled]="itemsSelected.length == 0 || !clienteActivo "
        *ngIf="!filtros.mostrarMapa"
        (click)="openDialogAsignar()">
        <mat-icon class="kobra-icon">assignment_ind</mat-icon>
        <span>Asignación</span>
      </button>
      <button mat-menu-item
        [disabled]="itemsSelected.length !== 1  "
        *ngIf="!filtros.mostrarMapa"
        (click)="btnDetalleCuenta()">
        <mat-icon class="kobra-icon">details</mat-icon>
        <span>Ver Detalle</span>
      </button>
      <!-- botones de mapa -->
      <button mat-menu-item
        *ngIf="filtros.mostrarMapa" 
        (click)="borrarSeleccion()">
        <mat-icon class="kobra-icon">delete</mat-icon>
        <span>Borrar selección</span>
      </button>
      <button mat-menu-item 
        *ngIf="filtros.mostrarMapa"
        (click)="showAgentTodasModal()">
        <mat-icon class="kobra-icon">assignment</mat-icon>
        <span>Asignar todas las cuentas</span>
      </button>
      <button mat-menu-item
        *ngIf="filtros.mostrarMapa"
        [disabled]="!asignarSeleccion"
        (click)="showAgentModal()">
        <mat-icon class="kobra-icon">assignment_ind</mat-icon>
        <span>Asignar selección</span>
      </button>
    </mat-menu>

    <mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
      <mat-toolbar>
        <button class="filter-button-side" mat-flat-button
          matTooltip="Ocultar filtros"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle()"
        >
          <mat-icon>cancel</mat-icon>
        </button> 
        Filtros
      </mat-toolbar>
      <div class="accordion-filtros">
        <mat-accordion [multi]="false">
          <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
            <mat-expansion-panel-header>
              Súper Administrador
              <span *ngIf="this.contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('superAdmin') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cliente'"
                  [placeHolder]="'Selecciona un cliente'"
                  [value]="'idFinanciera'"
                  [labelField]="'name'"
                  [icon]="'account_balance'"
                  [options]="lenders"
                  [(ngModel)]="idLender"
                  (ngModelChange)="onChangeLender($event);this.contabilizadorDeFiltros('superAdmin');"
                >
                </mat-select-search>
              
              </li>
              <li class="filtro-side">             
                <mat-form-field style="width: 100%;">
                  <mat-label>Excluir Demo</mat-label>
                  <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                </mat-form-field>
               </li>
              
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Cuenta
              <span *ngIf="contabilizadorDeFiltros('cuenta')  >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('cuenta')  }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>ID Cuenta</mat-label>
                  <input matInput textSanitizer 
                  (ngModelChange)="this.contabilizadorDeFiltros('cuenta') ;"
                  [(ngModel)]="filtros.idExterno"
                  placeholder="Capture el id de la cuenta">
                  <mat-icon matSuffix  class="icono-limpiar"
                  *ngIf="filtros.idExterno" 
                  (click)="filtros.idExterno = null;this.contabilizadorDeFiltros('cuenta') ;">clear</mat-icon>
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.idExterno" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.idExterno = null"><i>Limpiar</i></a>
                </div> -->
              </li>
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cuenta'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="acreditadoFilterSearch"
                  [(ngModel)]="filtros.acreditado"
                  (ngModelChange)="this.contabilizadorDeFiltros('cuenta') ;"
                  >
                </mat-select-search>
              
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Visibilidad</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.visibilidad"
                    placeholder="Seleccione..."
                    (ngModelChange)="this.contabilizadorDeFiltros('cuenta') ;"
                  >
                    <mat-option [value]="1">Red Kobra</mat-option>
                    <mat-option [value]="2">Red Interna</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.visibilidad != null" 
                  (click)="filtros.visibilidad = null;$event.stopPropagation();this.contabilizadorDeFiltros('cuenta') ;">clear</mat-icon> 
                  <mat-icon matSuffix>visibility</mat-icon>
                </mat-form-field>
                <!-- <div align="left" *ngIf="filtros.visibilidad != null" class="limpiar-seleccion-kobra">
                  <a (click)="filtros.visibilidad = null"><i>Limpiar selección</i></a>
                </div> -->
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Fechas
              <span *ngIf="this.contabilizadorDeFiltros('fechasTotal') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasTotal') }}</span>

            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-accordion [multi]="false">
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas creación
                      <span *ngIf="this.contabilizadorDeFiltros('fechasC') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechasC') }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaCreacionFin ? filtros.fechaCreacionFin : today"
                          [(ngModel)]="filtros.fechaCreacionInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasC') ;"
                        />
                       
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaCreacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaCreacionFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasC') ;"
                        />
                       
                      </li>
                    </ol>
                  </mat-expansion-panel>
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas importación
                      <span *ngIf="contabilizadorDeFiltros('fechasI')  >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasI')  }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fechaImportacionFin ? filtros.fechaImportacionFin : today"
                          [(ngModel)]="filtros.fechaImportacionInicio"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasI') ;"
                        />
                       
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fechaImportacionInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fechaImportacionFin"
                          (ngModelChange)="this.contabilizadorDeFiltros('fechasI') ;"
                        />
                        
                      </li>
                    </ol>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </ol>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      
      
      <ol class="lista-filtros-buttons">
        <button
        class="boton-accion-front"
          style="width: 100%;"
          mat-stroked-button
          color="primary"
          (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
        </button>
        <button
          style="width: 100%; margin-top: 10px;"
          mat-stroked-button
          (click)="clearFilter()">
            <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
            Limpiar Filtros
        </button>
      </ol>
    </mat-sidenav>
  
    
    
    <app-tabla-expandible
      [parametros]="tablaCuentas"
      [items]="cuentasAgrupadas">

      <div t-header>
        <mat-card *ngIf="ayuda && !filtros.mostrarMapa" class="cardAyuda">
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              Este módulo es una sección central en el sistema, donde se visualizan y gestionan las cuentas que han sido
              subidas por los usuarios. En esta vista, las cuentas se agrupan de manera general, sin tomar en cuenta el subid
              ni el domicilio. A su vez, se puede dar clic en una cuenta para ver su detalle a nivel subcuenta o domicilio.
      
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a las cuentas
              ya existentes.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios
              de filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los
              datos de los productos para su análisis o uso fuera de línea.
            </p>
            <p>
              <mat-icon class="kobra-icon">swap_vert</mat-icon><b> Estatus: </b> Puedes cambiar el estatus de una cuenta
              en
              su domicilio, ya sea Activo y Inactivo.
            </p>
            <p>
              <mat-icon class="kobra-icon">bookmark_border</mat-icon><b> Etiquetas: </b> Cada cuenta puede ser etiquetada de
              manera
              particular para un seguimiento más preciso.
            </p>
            <p>
              <mat-icon class="kobra-icon">assignment_ind</mat-icon><b> Asignación: </b> Los domicilios pueden ser asignados a
              un agente
              en particular para que gestione la cuenta.
            </p>
            <p>
              <mat-icon class="kobra-icon">details</mat-icon><b> Ver Detalle: </b> Puedes ver el detalle de una cuenta ya sea
              mediante esta acción o dando clic en el id y o subid de la cuenta.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="cuentaAgrupadaPaginator.pagination?.totalCount"
          [pageIndex]="cuentaAgrupadaPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-tabla-expandible>
</div>
  