import { ApplicationService } from './application.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { take, map, catchError } from "rxjs/operators";
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})

/**
 * Clase generada para servicios de guardado de filtros en url y migas de pan automatizado.
 * @Auth Uriel Yair Gámez Rosales
 */
export class FiltrosService {
  public enEjecucion: boolean = false;
  public opcion: string;
  subscribeParams: Subscription;
  constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private servicios: ServicesVisitasService,
    private app: ApplicationService
  ) { }


  /**
   * Se encarga de establecer la opción que se va a requerir utilizar
   */
  public asignarOpcion(){
    const queryParamsIndex = this.router.url.indexOf('?');
    this.opcion = queryParamsIndex === -1 ? this.router.url.replace('/','') : this.router.url.slice(0, queryParamsIndex).replace('/','');
    const catIndex = this.router.url.indexOf('#');
    this.opcion = catIndex === -1 ? this.opcion : this.opcion.slice(0, catIndex-1);
  }

  /**
   * Guarda los filtros de la opción actual o la opción a la que se dirigen.
   * @param {any} filtros Corresponden al objeto de filtros que se va a guardar.
   * @param {boolean} agregarNavigate Indica si los filtros se agregaran a la url para poder compartirlos (por defecto es true).
   * @param {string} opcion (Opcional) Es la opción para la que se guardaran los datos la cual corresponde al nombre de la ruta sin diagonal, ejemplo:
   * router = /visits se enviaría visits. (Si esta opción es enviada y agregarNavigate es true redirige automaticamente a la ruta de la opcion).
   * @para {boolean} irAHome es para que cuando no se envie la opcion se redirija a home
   * @Auth Uriel Yair Gámez Rosales
   */
  public guardarFiltros(filtros: any, agregarNavigate: boolean = true, opcion: string = null, irAHome: boolean = false, preserve: string = 'merge'): void {
    let filtrosGuardar = this.desabilitarFiltrosNulos(filtros);
    if(!irAHome){
      if(!opcion && !this.opcion){
        this.asignarOpcion();
      }
      this.servicios.post(`/filtros`, {opcion: opcion ? opcion : this.opcion, filtros: filtrosGuardar}).pipe(
        map(res => res.data)
      ).pipe(
        take(1)
      ).subscribe((res: any) => {
        var filtrosRedis = Filtros.map(res);
        //localStorage.setItem(`filtros-${opcion ? opcion : this.opcion}`, filtrosRedis.f);
  
        if(agregarNavigate){
          this.activaUri(opcion ? false : true, filtrosRedis, opcion, irAHome, preserve);
        }
      }, (err: any) => {
        this.app.showError(err);
      });
    }
    else{
      this.activaUri(true, {}, '', true);
    }
  }

  /**
   * Elimina los filtros de las migas de pan de la opción actual.
   * @Auth Uriel Yair Gámez Rosales
   */
  public removerFiltros(){
    //localStorage.removeItem(`filtros-${this.opcion}`);
    this.router.navigate(
      [this.opcion],
    {
      relativeTo: this.activateRouter,
      queryParams: {}
    });
  }

  /**
   * Obtiene los filtros de la opción actual validando los datos de la url o de las migas de pan.
   * @param {any} filtros Corresponde al objeto de filtros al que se le van a asignar los valores guardados.
   * @param {any} callback Metodo de respuesta donde se recibiran los parametros una vez obtenidos, es necesario reciba un any donde se indica si hubo cambios en los filtros.
   * @Auth Uriel Yair Gámez Rosales
   */
  public obtenerFiltrosIniciales(filtrosEstructura: any, callback: any, asingarOpcion: boolean = true){
    /*const queryParamsIndex = this.router.url.indexOf('?');
    this.opcion = queryParamsIndex === -1 ? this.router.url.replace('/','') : this.router.url.slice(0, queryParamsIndex).replace('/','');
    const catIndex = this.router.url.indexOf('#');
    this.opcion = catIndex === -1 ? this.opcion : this.opcion.slice(0, catIndex-1);
    if(asingarOpcion){
      this.asignarOpcion();
    }*/
    var filtrosNvos = null;
    var agregarNavigate = true;
    this.obtenerFiltrosQueryParams((md5Params: string, filtrosParams: any) =>{
      if(filtrosParams){
        filtrosNvos = this.generarNvosFiltros(filtrosEstructura, filtrosParams);
        if(callback){
          callback(filtrosNvos);
        }
      }
      else{
        if(callback){
          callback(null);
        }
      }
    });
  }

  /**
   * Obtiene los filtros de la opción actual validando los datos de la url o de las migas de pan.
   * @param {T} type Es el tipo que vamos a utilizar para mapear los objetos recibidos de los parametros.
   * @param {any} callback Metodo de respuesta donde se recibiran los parametros una vez obtenidos, es necesario reciba un {T} donde se indica si hubo cambios en los filtros.
   * @Auth Uriel Yair Gámez Rosales
   */
   public obtenerFiltrosGardados<T extends FiltrosMap>(filtroActuales: T, callback: any, asingarOpcion: boolean = true){
    /*if(asingarOpcion){
      this.asignarOpcion();
    }
    const queryParamsIndex = this.router.url.indexOf('?');
    this.opcion = queryParamsIndex === -1 ? this.router.url.replace('/','') : this.router.url.slice(0, queryParamsIndex).replace('/','');
    const catIndex = this.router.url.indexOf('#');
    this.opcion = catIndex === -1 ? this.opcion : this.opcion.slice(0, catIndex-1);*/
    this.obtenerFiltrosQueryParamsEstructurados((md5Params: string, filtrosParams: T) =>{
      if(filtrosParams){
        callback(filtrosParams);
      }
      else{
        callback(null);
      }
    });
  }

  /**
   * Mapea el objeto con el tipo seleccionado.
   * @param {any} type Es el tipo de dato que se va a mapear
   * @Auth Uriel Yair Gámez Rosales
   */
  private IniciarNuevoTipo<T extends FiltrosMap>(type: new () => T): T{
    return new type();
  }

  private obtenerFiltrosRedis(md5, callback){
    this.servicios.get(`/filtros/${md5}`).pipe(
      map(res => res.data)
    ).pipe(
      take(1)
    ).subscribe((res: any) => {
      callback(res);
    }, (err: any) => {
      callback(null);
      this.app.showError(err);
    });
  }

  /**
   * Obtiene los filtros que se encuentran en los query params
   * @param {any} callback Es el callback donde se van a devolver los resultados de los filtros
   * @Auth Uriel Yair Gámez Rosales
   */
   private obtenerFiltrosQueryParams(callback: any): any{
    this.subscribeParams = this.activateRouter.queryParams.subscribe((params: any) => {
      if( params.f ) {
        this.obtenerFiltrosRedis(params.f, (res: any) =>{
          callback(params.f, res.filtros);
        });
      }
      else{
        callback(null, null);
      }
    });
    this.subscribeParams.unsubscribe();

  }

  /**
   * Obtiene los filtros que se encuentran en los query params
   * @param {any} callback Es el callback donde se van a devolver los resultados de los filtros
   * @Auth Uriel Yair Gámez Rosales
   */
  private obtenerFiltrosQueryParamsEstructurados<T>(callback: any): any{
    this.subscribeParams = this.activateRouter.queryParams.subscribe((params: any) => {
      if( params.f ) {
        this.obtenerFiltrosRedis(params.f, (res: FiltrosResponse<T>) =>{
          callback(params.f, res.filtros);
        });
      }
      else{
        callback(null, null);
      }
    });
    this.subscribeParams.unsubscribe();

  }

  /**
   * Obtiene los filtros que se encuentran en las migas de pan
   * @param {any} callback Es el callback donde se van a devolver los resultados de los filtros
   * @Auth Uriel Yair Gámez Rosales
   */
  private obtenerFiltrosStorage(callback: any): any{
    var filtros = localStorage.getItem(`filtros-${this.opcion}`);
    if(filtros){
      this.obtenerFiltrosRedis(filtros, (res: any) =>{
        callback(filtros, res.filtros);
      });
    }
    else{
      callback(null, null);
    }
  }

  /**
   * Genera un objeto de filtros nuevos validando la estructura de los filtros inicial.
   * @param {any} filtrosEstructura Corresponde al objeto de filtros que contiene la estructura actual.
   * @param {any} filtrosNuevos Corresponde al objeto de filtros recibidos, podrian no coincidir las propiedades para eso es este metodo.
   * @Auth Uriel Yair Gámez Rosales
   */
  private generarNvosFiltros(filtrosEstructura: any, filtrosNuevos: any): any{
    var filtros = {};
    if(!filtrosNuevos){
      return null;
    }
    Object.keys(filtrosEstructura).forEach(key => {
      if(filtrosNuevos.hasOwnProperty(key)){
        if(moment(filtrosNuevos[key], moment.ISO_8601, true).isValid() && (filtrosNuevos[key]+'').includes('')){
          filtros[key] = moment(filtrosNuevos[key]);
        }
        else{
          filtros[key] = filtrosNuevos[key];
        }
      }
      else{
        filtros[key] = filtrosEstructura[key];
      }
    });
    return filtros;
  }

  /**
   * Genera un objeto de filtros solo con las propiedades con valor para evitar generar una url tan larga.
   * @param {any} filtrosActuales Es el objeto de filtros actuales.
   * @Auth Uriel Yair Gámez Rosales
   */
  private desabilitarFiltrosNulos(filtrosActuales): any{
    var filtrosGuardar = {};
    Object.keys(filtrosActuales).forEach(key => {
      if(filtrosActuales[key] !== null){
        if(moment.isMoment(filtrosActuales[key])){
          filtrosGuardar[key] = filtrosActuales[key].format('YYYY-MM-DD');
        }
        else{
          filtrosGuardar[key] = filtrosActuales[key];
        }
      }
    });
    return filtrosGuardar;
  }

  private activaUri(activateRouter: boolean, queryParams: any, opcion: string = null, home: boolean = false, preserve: any= 'merge'){
    this.router.navigate(
      [home ? '' : (opcion ? opcion : this.opcion)],
    {
      relativeTo: activateRouter ? null : this.activateRouter,
      queryParams: queryParams,
      queryParamsHandling: preserve, // remove to replace all query params by provided
    });
  }
}


export class Filtros {
  f: string;

  public static map(data: any): Filtros {
    let instance: Filtros = new Filtros();
    instance.f = data.f;
    return instance;
  }
}

export class FiltrosResponse<T> {
  filtros: T;
}

export class FiltrosMap {
  constructor() {
  }

  public map(data: any){

  }
}
