export class Request {
    idSolicitudCredito: number;
    folio: string;
    nombreTipoCredito: string;
    idTipoCredito: number;
    nombre: string;
    primerApellido: string;
    segundoApellido: string;
    idGenero: number;
    genero: string;
    fechaNacimiento: Date;
    telefonoCelular: string;
    telefonoTrabajo: string;
    telefonoCasa: string;
    email: string;
    curp: string;
    rfc: string;
    calle: string;
    numeroExterior: string;
    numeroInterior: string;
    entreCalles: string;
    idColonia: number;
    colonia: string;
    idCodigoPostal: number;
    codigoPostal: string;
    idMunicipio: number;
    municipio: string;
    idEstado: number;
    estado: string;
    latitudInicio: string;
    longitudInicio: string;
    latitudFin: string;
    longitudFin: string;
    archivoFirmaRuta: string;
    archivoFirmaNombre: string;
    montoSolicitado:string;
    montoPrestado:string;
    idEstatus: number;
    estatus: string;
    idAgente: number;
    nombreCompletoAgente: string;
    idCliente: number;
    nombreCliente: string;
    fechaRegistro: Date;
    selected: boolean;
    cancelled: boolean;
    redInterna: boolean;
    idFinanciera: number;
    estatusFinanciera: string;
    tipoPersona: string;
    nombreCompleto: string;
}