<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false;"></router-outlet>
<div class="app content kobra" *ngIf="!hijosActivos">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="container" >

      <!-- Primera Columna - General -->
      <mat-card class="tile" >
        <mat-card-header>
          <mat-card-title>
            General
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div >
            <form [formGroup]="formGroupGeneral" *ngIf="formGroupGeneral" class="field-container">
              <app-field 
              *ngFor="let field of dynamicFields" 
              [type]="field.type" 
              [formControlName]="field.name"
              [placeholder]="field.placeholder" 
              [required]="true"
              [maxValue]="999999"
              class="field-half-width"
              >
            </app-field>
            
            </form>
            
          </div>
        </mat-card-content>
        <mat-card-footer>
          <div mat-card-actions align="right" style="margin: 10px; gap:10px">
            <button *ngIf="formGroupGeneral" mat-stroked-button class="boton-guardar" 
            (click)="saveConfiguraciones()" m-actions >
              <mat-icon>
                save
              </mat-icon>Guardar</button>
          </div>  
            <mat-progress-bar  *ngIf="cargandoGeneral" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>
       
        </mat-card-footer>
      </mat-card>

      <!-- Segunda Columna - Saldos -->
      <mat-card class="tile" style="margin-bottom: 5px;">
        <mat-card-header>
          <mat-card-title>
            Saldos
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="formGroupSaldos" *ngIf="formGroupSaldos">
          <app-field 
            *ngFor="let field of camposDinamicos" 
            [type]="field.type" 
            [formControlName]="field.name"
            [placeholder]="field.placeholder" 
            [required]="field.required"
            [maxValue]="999999999"
            >
          </app-field>
          </form>
        </mat-card-content>
        <mat-card-footer class="kobra-card-footer">

        <div mat-card-actions align="right" style="margin: 10px; gap:10px">
          <button *ngIf="formGroupSaldos" mat-stroked-button class="boton-guardar"
            (click)="saveConfiguracionesSaldos()" m-actions >
            <mat-icon>
              save
            </mat-icon>Guardar</button>
        </div>
        <mat-progress-bar  *ngIf="cargandoSaldos" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

      <!-- Tercera Columna - Productos -->
      <!--mat-card class="tile">
        <mat-card-header>
          <mat-card-title>
            Productos
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item *ngFor="let product of products">
              <div class="productos" >
                <h4>{{product.name}}</h4>
  
                <button mat-icon-button title="Layout" class="boton-layout" (click)="editLayout(product)">
                  <mat-icon class="kobra-icon"  >view_list</mat-icon>
                </button>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <mat-card-footer>
        <mat-progress-bar  *ngIf="cargandoProductos" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
      </mat-card-->

    </mat-card-content>
  </mat-card>
</div>
