import { Period } from "../period/period.model";

export class BusinessValidationChecklist {
  id: number;
  period: Period;
  activity: string;
  schedule: string;
  monthlySales: number;
  monthlyExpenses: number;
  address: string;
  createdAt: Date;
}