import { ClientAddress } from "../client-address/client-addres.model";

export class Client {
  idAcreditado: number;
  externalID: string;
  idFinanciera: number;
  idCliente: number;
  address: ClientAddress;
  email: string;
  fullName: string
  activo: boolean;
  name: string;
  apellido_p: string;
  apellido_m: string;
  claveElectoralIfe: string;
  curp: string;
  fechaNacimiento: Date;
  rfc: string;
  telefonoCelular: string;
  telefonoFijo: string;
  nombreFinanciera: String;
  subExternalID: string;


  constructor () {
    this.address = new ClientAddress();
  }

  public static map( data:any ) {
    let instance = new Client();
    instance.idAcreditado = data.idAcreditado;
    instance.idFinanciera = +data.idFinanciera;
    instance.idCliente = +data.idCliente;
    instance.externalID = data.idExterno;
    instance.subExternalID = data.subExternalID || data.idSubcuenta || null;
    instance.address = data.address;
    instance.fullName =
      (data.nombre ? data.nombre:'') +
      (data.apellidoPaterno ? ' '+data.apellidoPaterno:'') +
      (data.apellidoMaterno ? ' '+data.apellidoMaterno:'');
    instance.name = (data.nombre ? data.nombre:'');
    instance.apellido_p = (data.apellidoPaterno ? data.apellidoPaterno:'');
    instance.apellido_m = (data.apellidoMaterno ? data.apellidoMaterno:'');
    instance.claveElectoralIfe = (data.claveElectoralIfe ? data.claveElectoralIfe:'');
    instance.curp = (data.curp ? data.curp:'');
    instance.fechaNacimiento = data.fechaNacimiento;
    instance.rfc = (data.rfc ? data.rfc:'');
    instance.telefonoCelular = (data.telefonoCelular ? data.telefonoCelular:'');
    instance.telefonoFijo = (data.telefonoFijo ? data.telefonoFijo:'');
    instance.email = data.email ? data.email:'';
    instance.nombreFinanciera = (data.nombreFinanciera ? data.nombreFinanciera:'');

    return instance;
  }
}