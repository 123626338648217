export class MostrarPreguntas {
  show: number[];

  constructor() {
  }

  public static map(data: any): MostrarPreguntas {
    let instance: MostrarPreguntas = new MostrarPreguntas();
    instance.show = data.show || null;

    return instance;
  }

  public static mapArray(data: any[]): MostrarPreguntas[] {
    let instances: MostrarPreguntas[] = [];
    for (let i = 0; i < data.length; i++) {
        instances.push(MostrarPreguntas.map(data[i]));
    }
    return instances;
  }
}
