import { VisitaCalificada } from "./visita-calificada.model";
import { Pagination } from "../pagination/pagination.model";

export class VisitaCalificadaPaginator {
  pagination: Pagination;
  data: VisitaCalificada[];

  constructor() {
    this.pagination = new Pagination();
  }
}