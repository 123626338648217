import { ConceptosSaldosService } from './../../../saldos/conceptos-saldos/conceptos-saldos.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/operators';
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'conceptos-productos',
  templateUrl: './conceptos-productos.component.html',
  styleUrls: ['./conceptos-productos.component.css'],
  providers: [LenderService]
})
export class ConceptosProductosNvoComponent implements OnInit {
  sessionData: SessionData;
  idProducto: number = 0;
  idTipoProducto: number = 0;
  productos: any[] = [];
  pagoAlGestor: number | null = null;
  pagoAlGestorPromesa: number | null = null;
  idProductoYNombre: string | null = null;

  private nombre: string = '';

  loadings: any = {
    productos: true,
    guardando: false
  }

  requireds: any = {
    financiera: false,
    nombre: false
  }

  constructor(
    private lenderService: LenderService,
    private productosService:ConceptosSaldosService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService,
    private router: Router
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
     /*this.producto = JSON.parse(localStorage.getItem('procutoNuevoTemp'));
     this.productoNvo = Object.assign({}, this.producto);
     this.productoNvo.administradores = [];*/
     this.loadProductos();
  }

  onSelectProduct(){

    let idProducto:number = this.idProducto;
    let index: number = this.productos.findIndex(x=> x.idProducto == idProducto);
    if(index >=0){
      let producto: any = this.productos[index];
      this.idTipoProducto = producto.idTipoProducto;
      this.idProductoYNombre = producto.nombre + " (Promesa de pago)";
    }
    else{
      this.idTipoProducto = 0;
      this.idProductoYNombre = null;
    }

  }

  public onInputLimite($event: any): void{

    if (!$event.target.value) {
      this.pagoAlGestor = null;
      return;
    }
    const value = parseFloat($event.target.value);
    const minValue = 1.00;
    const maxValue = 99999.99;
    const regex = /^\d+(\.\d{1,2})?$/;
    if(value > maxValue){
        setTimeout(()=>{
          this.pagoAlGestor = maxValue;
        }, 10);
    }
    else if(value == null || value < minValue || isNaN(value)){
        setTimeout(()=>{
          this.pagoAlGestor = minValue;
        }, 10);
    }
    if(!regex.test(value+"")){
      const newValue = parseFloat(parseFloat(value+"").toFixed(2));
      // Verificar si el nuevo valor no es NaN antes de asignarlo al campo de entrada
      if (!isNaN(newValue)) {
        setTimeout(()=>{
          this.pagoAlGestor = newValue;
        }, 10);
      }
    }
  }

  loadProductos(){
    this.loadings.productos = true;
    this.productosService.obtenerProductosSinConcepto()
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.productos = false;
        res.data.forEach(element => {
          element.idProductoYNombre = element.idProducto + " - " + element.nombre;
        });
        this.productos = res.data;
    }, (err: any) => {
        this.loadings.productos = false;
        this.app.showError(err);
    });
  }

  cancel() {
    this.router.navigate([`catalogos/conceptos-productos`], {
      queryParamsHandling: 'preserve'
    });
  }

  save() {
    this.nombre = '';
    if(this.idProducto == 0){
      this.app.showSnackbar('¡Aviso!', 'Es necesario seleccionar el producto.', 3000, 'warning');
      return;
    }
    if(this.idProducto > 0){
      this.nombre = this.productos.filter((producto)=> producto.idProducto == this.idProducto)[0].nombre;
    }

    if(!this.pagoAlGestor){
      this.app.showSnackbar('¡Aviso!', 'Es necesario colocar el pago al gestor.', 3000, 'warning');
      return;
    }else if(this.pagoAlGestor < 1) {
      this.app.showSnackbar('¡Aviso!', 'Es necesario que el pago de gestor sea mayor a 1.', 3000, 'warning');
      return;
    }

    let parametros = [{
      nombreConcepto: this.nombre,
      pagoGestor: this.pagoAlGestor,
      promesaPago: false
    }];

    if(this.idTipoProducto == 1){
      if(!this.pagoAlGestorPromesa){
        this.app.showSnackbar('¡Aviso!', 'Es necesario colocar el pago al gestor cuando sea promesa de cobranza.', 3000, 'warning');
        return;
      }else if(this.pagoAlGestor < 1) {
        this.app.showSnackbar('¡Aviso!', 'Es necesario que el pago de gestor sea mayor a 1 cuando sea promesa de cobranza.', 3000, 'warning');
        return;
      }
      parametros.push({
        nombreConcepto: this.idProductoYNombre,
        pagoGestor: this.pagoAlGestorPromesa,
        promesaPago: true
      });
    }
    let conceptos = {
      conceptos: parametros
    }

    this.app.showLoading("Guardando conceptos");
    //this.loadings.guardando = true;
    this.productosService.agregarConceptoProducto(this.idProducto, conceptos)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.app.hideLoading();
        this.app.showSnackbar('¡Aviso!', 'Se ha guardado el nuevo concepto correctamente.', 1000, 'success');
        this.router.navigate([`catalogos/conceptos-productos`], {
          queryParamsHandling: 'preserve'
        });
    }, (err: any) => {
        this.app.hideLoading();
        this.app.showError(err);
    });

  }
}
