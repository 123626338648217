<div>
  <div>
    <mat-checkbox *ngIf="checkFiltrado" [(ngModel)]="pregunta.filtrarPregunta" (ngModelChange)="onFilterQuestion()"></mat-checkbox>
  </div>

  <div class="negrita" style="margin-top: 10px !important;">
    <mat-label>
      {{pregunta.nombrePregunta}}
      <ng-container *ngIf="respuestas[0].requerida && respuestas[0].idTipoRespuesta != 8">
        <span style="color: red;">*</span>
      </ng-container>
    </mat-label>
  </div>
  <form [formGroup]="dynamicForm">
    <ng-container *ngFor="let respuesta of respuestas" style="margin-left: 20px;">
      <ng-container [ngSwitch]="respuesta.idTipoRespuesta">
          <ng-container *ngSwitchCase="1">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput textSanitizer   [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
    
          <ng-container *ngSwitchCase="2">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>   
              <textarea matInput textSanitizer   [formControlName]="respuesta.idChecklistRespuesta.toString()" rows="4" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)"></textarea>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
    
          <ng-container *ngSwitchCase="3">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput textSanitizer  type="number"  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="4">
            <mat-form-field [ngClass]="{'margen-Botton': dynamicForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')}">
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput
                     textSanitizer
                     type="number"
                     step="0.01"
                     [formControlName]="respuesta.idChecklistRespuesta.toString()"
                     [(ngModel)]="respuesta.valor"
                     (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
              <mat-error *ngIf="dynamicForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')">
                El valor debe ser un número decimal válido con hasta dos decimales.
              </mat-error>
            </mat-form-field>
          </ng-container>
    
          <ng-container *ngSwitchCase="5">
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <mat-date-picker-formater
                [label]="respuesta.nombreRespuesta"
                [placeHolder]="'Capture la fecha'"
                [(ngModel)]="respuesta.valor" 
                [formControlName]="respuesta.idChecklistRespuesta.toString()"
                (ngModelChange)="onChangeValue(respuesta, pregunta)"
                [limpiar]="false"
              />
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
          </ng-container>
    
          <ng-container *ngSwitchCase="6">
            <mat-form-field>
              <mat-checkbox class="kobra-checkbox" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                {{respuesta.nombreRespuesta}}
              </mat-checkbox>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>.
              </mat-error>
            </mat-form-field>
            
          </ng-container>
    
          <ng-container *ngSwitchCase="7">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <mat-radio-group  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <mat-radio-button class="kobra-radio" *ngFor="let opt of respuesta.options" [value]="opt.id">
                  {{opt.label}}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
    
          <ng-container *ngSwitchCase="8">
            <mat-slide-toggle class="kobra-slide-toggle"  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)"></mat-slide-toggle>
          </ng-container>
    
          <ng-container *ngSwitchCase="9">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Selecione una respuesta'}}</mat-label>
              <mat-select  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <!-- <mat-option [value]="null" disabled >Selecciona una opción</mat-option> -->
                <mat-option *ngFor="let opt of respuesta.options" [value]="opt.id">{{opt.label}}</mat-option>
              </mat-select>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
    
          <ng-container *ngSwitchCase="10">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <input matInput textSanitizer 
                [formControlName]="respuesta.idChecklistRespuesta.toString()" 
                [(ngModel)]="respuesta.valor"
                [ngxTimepicker]="picker" 
                placeholder="Selecciona una hora"
                (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
          
          <ng-container *ngSwitchCase="11">
            <div class="foto" >
              <span>{{respuesta.nombreRespuesta || ""}}</span>
            </div>
            <div *ngIf="respuesta.valor" style="text-align: center !important;">
              <foto-zoom [height]="200"  [mostrarBotonDescargar]="false"  *ngIf="respuesta.valor" [foto]="obtenerFotoChecList(respuesta.valor)" [posicion]="1"  (click)="cargarFotoCompleta(obtenerFotoChecList(respuesta.valor))"></foto-zoom>
              <button  mat-stroked-button class="boton-accion-eliminar" (click)="eliminarFotoCheckLists(respuesta.valor)">
                <mat-icon >delete_forever</mat-icon>Eliminar
              </button>
            </div>
            <app-upload-file *ngIf="!respuesta.valor" [allowMultiple]="false" [mostrarBotonEliminar]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="subirFoto($event, respuesta, pregunta)"></app-upload-file>
          </ng-container>
      </ng-container>
    </ng-container>
  </form>
  
</div>

