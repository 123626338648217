import { take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '../application/shared/application.service';
import { SessionData } from '../shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { DescargasService } from './descargas.service';
import { environmentSelector } from '../../environments/environment.selector';
import moment from 'moment';
import { IDescargasPagination } from './descargas.interface';




@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.css'],
  providers:[DescargasService]
})
export class DescargasComponent implements OnInit, OnDestroy {
    public sessionData: SessionData;
    public isSuperAdmin;
    public cargandoDescargas = false;
    public pagination: IDescargasPagination;
    public fromRowPage: number;
    public toRowPage: number;
    public pageSize = 50;
    public descargas = [];
    public ayuda: boolean = false;
    public tablaDescargas: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: [
        {
          name: 'idDescarga',
          key: 'idDescarga',
          hide: true
        }, {
          name: 'ID cliente administrador',
          key: 'idClienteAdministrador',
          hide: true
        }, {
          name: 'Usuario',
          key: 'nombre',
          hide: false
        }, {
          name: 'Opción',
          key: 'nombreOpcion',
          hide: false
        }, {
          name: 'Archivo',
          key: 'nombreArchivo',
          hide: false
        }, {
          name: 'Tamaño archivo',
          key: 'peso',
          hide: false
        }, {
          name: 'Ruta',
          key: 'ruta',
          hide: true
        },
        {
          name: 'Fecha registro',
          key: 'fecRegistro',
          hide: false
        }, {
          name: 'Fecha actualizado',
          key: 'fecActualizado',
          hide: true
        },{
          name: ' ',
          icono: 'cloud_download',
          key: 'descargar',
          type: 'icono',
          tooltip: 'Descargar',
          class: 'kobra-icon',
          hide: false
        }
      ]
    }


  constructor(
    private app: ApplicationService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private descargasService: DescargasService,
  ) {

  };

  ngOnInit() {
    this.sessionData = this.localStorageService.getSessionData();
    this.obtenerDescargas(1);
  }

  ngOnDestroy() {

  }

  public obtenerDescargas(page: number){
    this.cargandoDescargas = true;
    let campos = '"idDescarga","idClienteAdministrador","nombre","apellidoPaterno","nombreOpcion","nombreArchivo","ruta","activo","peso","fecRegistro","fecActualizado"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=d.fec_registro%20desc`;

    this.descargasService.obtenerDescargas(this.sessionData.idClienteAdministrador, queryParams).subscribe(
      (res: any) => {
        this.cargandoDescargas = false;
        const datos = res.data['pageData'];
        if (datos) {
          this.pagination = {
            page: res.data['page'],
            pageData: datos,
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          for (let i = 0; i < datos.length; i++) {
            datos[i].descargar = 'Descargar';
            datos[i].nombre = datos[i].nombre + ' ' + datos[i].apellidoPaterno;
            datos[i].fecRegistro = moment(new Date(datos[i].fecRegistro)).format('DD/MM/YYYY HH:mm');
          }
          this.descargas = datos;
        } else {
          console.error('No pageData');
        }
      },
      error => {
        this.cargandoDescargas = false;
        this.app.showError(error);
      }
    );
  }


  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.pageSize) {
      this.pageSize = newPagina.pageSize;
      this.obtenerDescargas(newPagina.pageIndex + 1);
    }
  }

  public onLinkRow(event) {
    if (event.columnPressed == 'descargar') {
      let queryParams = '?token=' + localStorage.getItem('access-token');

      let ambiente = environmentSelector();
      let country = "&Country=";
      country+= ambiente.country;
      // Realizar la solicitud para obtener el archivo
      fetch(ambiente.kobraServices.storage + `/descargas/${event.row.idDescarga}${queryParams}${country}`)
        .then(response => response.blob())
        .then(blob => {
          // Crear un enlace (a) para descargar el blob
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = event.row.nombreArchivo; // Especifica el nombre del archivo para descargar
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => console.error('Error al descargar el archivo:', error));
    }
  }


}
