<h1 mat-dialog-title>Actualización de cuentas</h1>
<mat-dialog-content style="min-width: 700px; position: relative;">
  <button mat-icon-button color="primary" matTooltip="Ayuda" mat-tooltip-panel-above class="botonAyuda"
        (click)="ayudaComentario(!ayuda)" style="position: absolute; top: 10px; right: 10px;">
        <mat-icon>help</mat-icon>
  </button>
  <div *ngIf="ayuda">
    <mat-card class="cardMargin">
      <mat-card-content>
        <p>El grid muestra las cuentas que pueden ser actualizadas sus coordenadas utilizando la última visita realizada en el domicilio asociado.</p>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-card style="margin-bottom: 20px;" class="cardContenido">
    <mat-card-content>
      <app-table 
          [classContainer]="'table-modal'"
          [columns]="tablaCuentas.columnas"
          [items]="cuentas" 
          [headers]="tablaCuentas.headers"
          [tableHeaders]="tablaCuentas.tableHeaders" 
          [ignoreSortChange]="true"
          [multiSelect]="tablaCuentas.multiSelect"
          (onLinkRow)="onLinkRow($event)"
          (onSelectedRows)="onSelected($event)">
      </app-table>
    </mat-card-content>
    <mat-card-footer style="margin-top: 3px !important;" *ngIf="actualizar || cargando">
      <i *ngIf="actualizar">Actualizando cuentas...</i>
      <i *ngIf="cargando">Obteniendo cuentas...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button [disabled]="actualizar || cargando" mat-stroked-button  class="boton-guardar" (click)="actualizaCoordenadas()"><mat-icon>sync</mat-icon>Actualizar</button>
</mat-dialog-actions>
