import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { LenderService } from "../../../lender/lender.service";
import { take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { PaquetesFinancieraDetalleService } from './paquetes-financiera-detalle.service';

@Component({
  selector: 'app-paquetes-financiera-detalle',
  templateUrl: './paquetes-financiera-detalle.component.html',
  styleUrls: ['./paquetes-financiera-detalle.component.css'],
  providers: [
    LenderService
  ]
})
export class PaquetesFinancieraDetalleComponent implements OnInit {
  conceptoActual: any = null;
  public title: string;
  public idFinanciera: number;
  public idFinancieraPaquete: number;
  public financieraName: string;
  public detalleForm: FormGroup;
  public conceptos: any[]= [];
  public form: FormGroup;
  public items: FormArray;
  public loaderText: string | null;
  public saveButton: boolean = false;
  private financieraPaqueteConceptos: any[]= [];
  private conceptosCompletos: any[] = [];
  private itemsBorrados: any[] = [];

  constructor(
    private acRoute: ActivatedRoute,
    private lenderService: LenderService,
    private app: ApplicationService,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.title = 'Editar precios por tipo de visitas';
    this.detalleForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.idFinanciera = +this.acRoute.parent?.snapshot.params['idFinanciera'];
    this.idFinancieraPaquete = this.acRoute.snapshot.params['idFinancieraPaquete'];
    if (!this.financieraName) {
      this.cargarFinanciera(this.idFinanciera);
    }

    this.paquetesFinancieraDetalleService.getConceptos(this.idFinanciera, this.idFinancieraPaquete)
      .pipe(take(1))
      .subscribe((res) => {
        this.items = this.detalleForm.get('items') as FormArray;
        this.financieraPaqueteConceptos = res;
        this.getConceptosFinanciera();
        for( let i = 0; i < res.length; i++ ) {
          this.items.push(this.createItem( res[i].idConcepto, res[i].nombreConcepto, res[i].precio ));
        }
      }, (err) => {
        this.app.showError(err);
      });
  }

  getConceptosFinanciera(){
    this.loaderText = 'Obteniendo conceptos...';
    this.paquetesFinancieraDetalleService.getConceptosPorFinanciera(this.idFinanciera)
      .pipe(take(1))
      .subscribe((res) => {
        this.loaderText = null;
        this.conceptosCompletos = res;
        for (let index = 0; index < this.conceptosCompletos.length; index++) {
          const concepto = this.conceptosCompletos[index];
          if(!this.financieraPaqueteConceptos.some((item)=>{
            return item.idConcepto == concepto.idConcepto;
          })){
            this.conceptos.push(concepto);
          }
          
        }
        
      }, (err) => {
        this.loaderText = null;
        this.app.showError(err);
      });
  }

  agregarConcepto(){
    let conceptoBorrado = this.itemsBorrados.find((itemBorrado) => {
      return itemBorrado.idConcepto == this.conceptoActual.idConcepto
    });

    if(conceptoBorrado){
      this.items.push(this.createItem( conceptoBorrado.idConcepto, conceptoBorrado.nombreConcepto, conceptoBorrado.precio, conceptoBorrado.nuevo ));
      this.itemsBorrados.splice(this.itemsBorrados.indexOf(conceptoBorrado), 1);
    }
    else{
      this.items.push(this.createItem( this.conceptoActual.idConcepto, this.conceptoActual.nombreConcepto, 0, true ));
    }
    
    let index = this.conceptos.indexOf(this.conceptoActual);
    this.conceptos.splice(index, 1);
    setTimeout(() => {
      const control = document.getElementById(this.conceptoActual.idConcepto);
      this.conceptoActual = null;
    
    if(control){
      control.focus();
    }
    }, 100);
  }

  borrarConcepto(item: any, index: number){
    let conceptoBorrado: any = {
      idConcepto: item.controls['idConcepto'].value,
      nombreConcepto: item.controls['nombreConcepto'].value,
      precio: item.controls['precio'].value,
      nuevo: item.controls['nuevo'].value
    };
    this.itemsBorrados.push(conceptoBorrado);
    this.items.removeAt(index);
    this.conceptos = [...this.conceptos, conceptoBorrado];
    
  }

  createItem(idConcepto: number, nombreConcepto: string, precio: number, nuevo: boolean = false): FormGroup {
    return this.formBuilder.group({
      idConcepto: idConcepto,
      nombreConcepto: nombreConcepto,
      precio: [precio, [
        Validators.required,
        Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
        Validators.min(1)
      ]],
      nuevo: nuevo
    });
  }

  private cargarFinanciera(idFinanciera: number): void {
    this.lenderService.obtenerFinanciera(idFinanciera)
    .pipe(
      take(1)
    ).subscribe((res: any) => {
      this.financieraName = res.data[0].nombre;
    }, (error: any) => {
      this.app.showError(error);
    });
  }

  onSubmit() {
    let conceptos: any[] = [];
    let conceptosBorrados: any[] = [];

    for( let i = 0; i < this.items.length; i++ ) {
      conceptos.push({
        idConcepto: this.items.controls[i].value.idConcepto,
        precio: this.items.controls[i].value.precio,
        nuevo: this.items.controls[i].value.nuevo
      });
    }

    for (let index = 0; index < this.itemsBorrados.length; index++) {
      const item = this.itemsBorrados[index];
      conceptosBorrados.push({
        idConcepto: item.idConcepto,
        nombreConcepto: item.nombreConcepto,
        precio: item.precio
      });
    }

    let data = {conceptos: conceptos, conceptosBorrados: conceptosBorrados};

    let loading: number = this.app.showLoading('Guardando información...');
    this.paquetesFinancieraDetalleService.editarConceptos(this.idFinanciera, this.idFinancieraPaquete, data)
      .pipe(take(1))
      .subscribe((res) => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Precios actualizados correctamente.', 3000, 'success');

        this.router.navigate([`paquetes-cliente/${this.idFinanciera}`], {
            queryParamsHandling: 'preserve'
          }
        );

      }, (err) => {
        this.app.hideLoading(loading);
        this.app.showError(err);
      })
  }

  public onInputLimite(index: number): void{
    if(this.items.controls[index].value.precio > 99999){
        setTimeout(()=>{
          this.items.controls[index].value.precio = 99999;
          this.items.controls[index].get('precio').patchValue(99999);
        }, 10);
    }
  }
}
