import { Component, OnInit } from '@angular/core';
import { CobradoresFacturacionesService } from './cobradores-facturaciones.service';
import { CobradoresFacturacionesPeriodosService } from '../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { take, map } from 'rxjs/operators';
import { CobradoresMovimientosService } from '../cobradores-movimientos/cobradores-movimientos.service';
import {  CFPagination, CMXFPagination} from './cobrador-facturacion.interface';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Router } from '@angular/router';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

@Component({
  selector: 'app-cobradores-facturaciones',
  templateUrl: './cobradores-facturaciones.component.html',
  styleUrls: ['./cobradores-facturaciones.component.css'],
  providers: [
    CobradoresMovimientosService,
    CobradoresFacturacionesService
  ]
})
export class CobradoresFacturacionesComponent implements OnInit {
  ID_TIPO_PERIODO_POR_FACTURAR = 1;
  ID_TIPO_PERIODO_FACTURADO = 2;
  public CMXFpagination: CMXFPagination;
  public CFpageSize = 50;
  public CMXFpageSize = 50;
  public CMXFfilter = false;
  public CFfilter = false;
  public filtros:any = {
    porFacturar: {
      idCobradorFacturacionPeriodo: null,
      idCobrador: null,
      page: 1,
      sortField: 'cm.id_cobrador_facturacion_periodo desc',
      sortDirection: ''
    },
    facturados: {
      idCobradorFacturacionPeriodo: null,
      idCobrador: null,
      idEstatus: null,
      page: 1,
      sortField: 'cfh.fecha_timbrado asc',
      sortDirection: ''
    }
  }
  public CMFXLoader: boolean;
  CFLoader:boolean;
  public totalPeriodosFacturados: number;
  public totalPeriodosXFacturar: number;
  public CFpagination: CFPagination;
  ayuda = false;
  ayudaFactura = false;
  tablaMovimientos:any;
  tablaFacturas:any;
  movimientos:any;
  facturas:any;
  filtrosAbiertos = false;
  filtrosAbiertosDos = false;
  sessionData: SessionData;
  queryParamsGlobal:string;
  queryParamsGlobalPeriodos:string;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltrosFacturar: number = 0;
  public totalFiltrosFacturados: number = 0;

  constructor(
    public cobFacturasServ: CobradoresFacturacionesService,
    public periodoServ: CobradoresFacturacionesPeriodosService,
    public movsServ: CobradoresMovimientosService,
    public filtrosService: FiltrosService,
    private app: ApplicationService,
    private routerNav: Router,
    private serviciosSaldosKobraService: ServiciosSaldosKobraService,
    private localStorageService: LocalStorageService,
  ) { 
    this.CMXFpagination = {
      page: 1,
      pageData: [],
      pages: 1,
      totalRows: 0
    };

    this.CFpagination = {
      page: 1,
      pageData: [],
      pages: 1,
      totalRows: 0
    };
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
  }

  ngOnInit() {
    this.sessionData = this.localStorageService.getSessionData();
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
  }

  private agregarContabilizadoresDeFiltros(){

    this.contabilizadorFiltros.add('Generales', 'generalesFacturar', [
        'idCobradorFacturacionPeriodo'
    ]);

    this.contabilizadorFiltros.add('Agentes', 'agentesFacturar', [
        'idCobrador'
    ]);

    this.contabilizadorFiltros.add('Generales', 'generalesFacturados', [
        'idCobradorFacturacionPeriodo',
        'idEstatus'
    ]);

    this.contabilizadorFiltros.add('Agentes', 'agentesFacturados', [
        'idCobrador'
    ]);
    
 
 
    
  }

  public contabilizadorDeFiltrosFacturar(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros.porFacturar);
  }
  public contabilizadorDeFiltrosFacturados(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros.facturados);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArrayFacturar: number[] = [];
    let totalFiltrosArrayFacturados: number[] = [];
    
    totalFiltrosArrayFacturar.push( this.contabilizadorDeFiltrosFacturar('generalesFacturar') );
    totalFiltrosArrayFacturar.push( this.contabilizadorDeFiltrosFacturar('agentesFacturar') );
   
    totalFiltrosArrayFacturados.push( this.contabilizadorDeFiltrosFacturados('generalesFacturados') );
    totalFiltrosArrayFacturados.push( this.contabilizadorDeFiltrosFacturados('agentesFacturados') );
    
    this.totalFiltrosFacturar = totalFiltrosArrayFacturar.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    this.totalFiltrosFacturados = totalFiltrosArrayFacturados.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  crearTabla() {
    this.tablaMovimientos = {
        headers: false,
        multiSelect: false,
        columns: [
            {
              name: 'Periodo de Facturación',
              key: 'nombrePeriodo',
              hide: false
            },
            {
              name: 'ID Agente',
              key: 'idCobrador',
              hide: false,
              type: 'link'
            },
            {
              name: 'Agente',
              key: 'nombreCobrador',
              hide: false,
            },
            {
              name: 'Banco',
              key: 'banco',
              hide: false
            }, {
              name: 'Número de Cuenta',
              key: 'cuentaBancaria',
              hide: false
          }, {
              name: 'CLABE',
              key: 'clabe',
              hide: false,
          }, {
            name: 'Monto',
            key: 'monto',
            hide: false,
            type: 'money'
          }
        ]
    };

    this.tablaFacturas = {
      headers: false,
      multiSelect: false,
      columns: [
          {
            name: 'Periodo de Facturación',
            key: 'nombrePeriodo',
            hide: false
          },
          {
            name: 'ID Agente',
            key: 'idCobrador',
            hide: false,
            type: 'link'
          },
          {
            name: 'Agente',
            key: 'nombreCobrador',
            hide: false,
          },
          {
            name: 'Banco',
            key: 'banco',
            hide: false
          }, {
            name: 'Número de Cuenta',
            key: 'cuentaBancaria',
            hide: false
        }, {
            name: 'CLABE',
            key: 'clabe',
            hide: false,
        }, {
          name: 'Estatus',
          key: 'nombreEstatus',
          hide: false,
          type: 'estatus'
      }, {
        name: 'Fecha Timbrado',
        key: 'fechaTimbrado',
        hide: false,
        type: 'dateTime'
    }, {
          name: 'Monto',
          key: 'monto',
          hide: false,
          type: 'money'
        }
      ]
  }
  }

  resultFiltros(filtrosNuevos: any){

    if(filtrosNuevos){
      this.filtros = filtrosNuevos;
    }
    Object.keys(this.filtros.porFacturar).forEach(key => {
      if(key != 'page' && this.filtros.porFacturar[key]){
        this.CMXFfilter = true;
      }
    });

    Object.keys(this.filtros.facturados).forEach(key => {
      if(key != 'page' && this.filtros.facturados[key]){
        this.CFfilter = true;
      }
    });

   this.obtenerPeriodosXFacturar(this.filtros.porFacturar.page || 1);
   this.obtenerPeriodosFacturados(this.filtros.facturados.page || 1);
   this.cobFacturasServ.obtenerEstatusFacturaciones();
  }

  setSortFieldXFacturar(sort: any){
    this.filtros.porFacturar.sortDirection = sort.direction;
    if (sort.active != this.filtros.porFacturar.sortField) {
      this.filtros.porFacturar.sortField = sort.active;
    }
    this.obtenerPeriodosXFacturar(1);
  }

  queryParamFiltros(page: number) {
    this.queryParamsGlobal = "";

    this.queryParamsGlobal += this.filtros.porFacturar.idCobradorFacturacionPeriodo ?
      `&idCobradorFacturacionPeriodo=${this.filtros.porFacturar.idCobradorFacturacionPeriodo}` : '';

    this.queryParamsGlobal += this.filtros.porFacturar.idCobrador ?
      `&idCobrador=${this.filtros.porFacturar.idCobrador}` : '';

    if(page != this.filtros.porFacturar.page){
      this.filtros.porFacturar.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }

    return this.queryParamsGlobal;
  }

  public obtenerPeriodosXFacturar(page: number) {
    this.CMFXLoader = true;
    let queryParams = "";
    const campos = '"idCobradorFacturacionPeriodo","nombrePeriodo","idCobrador","nombreCobrador","monto","banco","cuentaBancaria","clabe"';
    queryParams = `?_paginate=1&_page=${page}&_rows=${this.CMXFpageSize}&_slt=${campos}
    &_orderBy=${this.filtros.porFacturar.sortField}${this.filtros.porFacturar.sortDirection ? ' ' + this.filtros.porFacturar.sortDirection : ''}`;
    queryParams += this.queryParamFiltros(page);

    this.cobFacturasServ.obtenerPeriodosXFacturar(queryParams).subscribe(
      (res: any) => {
        if(res.success){
          this.CMXFpagination.page = res.data['page'];
          this.CMXFpagination.pages = res.data['pages'];
          this.CMXFpagination.pageData = res.data['pageData'];
          this.CMXFpagination.totalRows = res.data['totalRows'];
          
          this.obtenerTotalesPeriodos(this.ID_TIPO_PERIODO_POR_FACTURAR).subscribe(
            (res: any) => {
              if (res.success) {
                this.totalPeriodosXFacturar = res.data['montoTotal'];
              }
            },
            (error) => {
              this.app.showError('Hubo un error al obtener totales: ' + error.message);
            }
          );

        }
        this.CMFXLoader = false;
        
      },(error) => {
            this.app.showError(error);
            this.CMFXLoader = false;
        },
    );
  }

  setSortFieldFacturados(sort: any){
    this.filtros.facturados.sortDirection = sort.direction;
    if (sort.active != this.filtros.facturados.sortField) {
      this.filtros.facturados.sortField = sort.active;
    }
    this.obtenerPeriodosFacturados(1);
  }

  queryParamFiltrosPeriodosFacturados(page: number) {
    this.queryParamsGlobalPeriodos = "";

    this.queryParamsGlobalPeriodos += this.filtros.facturados.idCobradorFacturacionPeriodo ?
      `&idCobradorFacturacionPeriodo=${this.filtros.facturados.idCobradorFacturacionPeriodo}` : '';

    this.queryParamsGlobalPeriodos += this.filtros.facturados.idCobrador ?
      `&idCobrador=${this.filtros.facturados.idCobrador}` : '';

    if (this.filtros.facturados.idEstatus && this.filtros.facturados.idEstatus !== 'all') {
      this.queryParamsGlobalPeriodos += `&idEstatus=${this.filtros.facturados.idEstatus}`;
    }

    if(page != this.filtros.facturados.page){
      this.filtros.facturados.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }

    return this.queryParamsGlobalPeriodos;

  }

  obtenerPeriodosFacturados(page: number) {
    this.CFLoader = true;
    let queryParams = "";
    const campos = '"nombrePeriodo","idCobrador","nombreCobrador","nombreEstatus","monto","fechaTimbrado","banco","cuentaBancaria","clabe"';
    queryParams = `?_paginate=1&_page=${page}&_rows=${this.CFpageSize}&_slt=${campos}&_orderBy=${this.filtros.facturados.sortField}${this.filtros.facturados.sortDirection ? ' ' + this.filtros.facturados.sortDirection : ''}`;
    queryParams += this.queryParamFiltrosPeriodosFacturados(page);

    this.cobFacturasServ.obtenerPeriodosFacturados(queryParams).subscribe(
      (res: any) => {
        if(res.success){
          this.CFpagination.page = res.data['page'];
          this.CFpagination.pages = res.data['pages'];
          this.CFpagination.pageData = res.data['pageData'];
          this.CFpagination.totalRows = res.data['totalRows'];
          this.obtenerTotalesPeriodos(this.ID_TIPO_PERIODO_FACTURADO).subscribe(
            (res: any) => {
              if (res.success) {
                this.totalPeriodosFacturados = res.data['montoTotal'];
              }
            },
            (error) => {
              this.app.showError('Hubo un error al obtener totales: ' + error.message);
            }
          );
        
          this.CFLoader = false;
        }
      },(error) => {
            this.app.showError(error);
            this.CFLoader = false;
        }
    );
  }

  obtenerTotalesPeriodos(tipoPeriodoTotal: number): Observable<any>  {
    let queryParams = '?totales=true';
    let servicioUrl = '';

    switch(tipoPeriodoTotal) {
      case this.ID_TIPO_PERIODO_FACTURADO: {
        servicioUrl = 'cobradores-periodos-facturados-totales';
        queryParams += this.filtros.facturados.idCobradorFacturacionPeriodo ?
          `&idCobradorFacturacionPeriodo=${this.filtros.facturados.idCobradorFacturacionPeriodo}` : '';
        queryParams += this.filtros.facturados.idCobrador ?
          `&idCobrador=${this.filtros.facturados.idCobrador}` : '';
        break;
      }
      case this.ID_TIPO_PERIODO_POR_FACTURAR: {
        servicioUrl = 'cobradores-periodos-por-facturar-totales';
        queryParams += this.filtros.porFacturar.idCobradorFacturacionPeriodo ?
          `&idCobradorFacturacionPeriodo=${this.filtros.porFacturar.idCobradorFacturacionPeriodo}` : '';
        queryParams += this.filtros.porFacturar.idCobrador ?
          `&idCobrador=${this.filtros.porFacturar.idCobrador}` : '';
        break;
      }
    }

    return this.cobFacturasServ.obtenerTotalesPeriodos(servicioUrl, queryParams);
  }

  CMXFonPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.CMXFpagination.page || newPagina.pageSize !== this.CMXFpageSize){
      this.CMXFpageSize = newPagina.pageSize;
        this.obtenerPeriodosXFacturar(newPagina.pageIndex+1);
    }
  }

  CFonPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.CFpagination.page || newPagina.pageSize !== this.CFpageSize){
      this.CFpageSize = newPagina.pageSize;
        this.obtenerPeriodosFacturados(newPagina.pageIndex+1);
    }
  }

  obtenerPeriodosXFacturarExcel(): void {
      let queryParams = '?rows=' + this.CMXFpageSize;
      queryParams += '&idClienteAdministrador=' + this.sessionData.idClienteAdministrador + this.queryParamsGlobal;
    
      const gridSize = this.CMXFpagination.totalRows

      if (gridSize > 30000){
        this.app.showSnackbar("Aviso", "El limite para la descarga es de 30,000 registros", 3000, 'warning')
        return;
      }

      if (gridSize >= 1) {
        this.serviciosSaldosKobraService.obtenerPeriodosPorFacturarExcel(queryParams)
          .pipe(take(1))
          .subscribe((respuesta) => {
            console.error(respuesta)
            this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
          }, (error: any) => {
            this.app.showError(error);
          });
      } else {
        this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
      }
        //this.cobFacturasServ.obtenerPeriodosXFacturarExcel(queryParams);
  }

  obtenerPeriodosFacturadosExcel() {
    const gridSize = this.CFpagination.totalRows

    if (gridSize > 30000){
      this.app.showSnackbar("Aviso", "El limite para la descarga es de 30,000 registros", 3000, 'warning')
      return;
    }

    if (gridSize >= 1) {
      let queryParams = '?rows=' + this.CFpageSize;
      queryParams += '&idClienteAdministrador=' + this.sessionData.idClienteAdministrador + this.queryParamsGlobalPeriodos;
      this.serviciosSaldosKobraService.obtenerPeriodosFacturadosExcel(queryParams)
        .pipe(take(1))
        .subscribe((respuesta) => {
          console.error(respuesta)
          this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
        }, (error: any) => {
          this.app.showError(error);
        });
    } else {
      this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
    }

    //this.cobFacturasServ.obtenerPeriodosFacturadosExcel(queryParams);
  }

  public openCMXFFilterSet() {
  
    Object.keys(this.filtros.porFacturar).forEach(key => {
      if(key != 'page' && this.filtros.porFacturar[key]){
        this.CMXFfilter = true;
      }
    });

    if (this.CMXFfilter) {
      this.filtros.porFacturar.page = 1;
      this.filtrosService.guardarFiltros(this.filtros);
      this.obtenerPeriodosXFacturar(1);
    }

    this.contabilizadorDeFiltrosTotales();

  }

  clearFilterCMXFFilter(){
    this.filtros.porFacturar.idCobradorFacturacionPeriodo = null;
    this.filtros.porFacturar.idCobrador = null;
    this.CMXFfilter = false;
    this.filtros.porFacturar.page = 1;

  }


 public openCFFilterSet() {
    
    Object.keys(this.filtros.facturados).forEach(key => {
      if(key != 'page' && this.filtros.facturados[key]){
        this.CFfilter = true;
      }
    });

    if (this.CFfilter) {
      this.filtros.facturados.page = 1;
      this.filtrosService.guardarFiltros(this.filtros);
      this.obtenerPeriodosFacturados(1);
    }
    this.contabilizadorDeFiltrosTotales();

  }

  clearCFFilter(){
    this.CFfilter = false;
    this.filtros.facturados.idCobradorFacturacionPeriodo = null;
    this.filtros.facturados.idCobrador = null;
    this.filtros.facturados.idEstatus= null

    this.filtros.facturados.page = 1;

    this.filtrosService.guardarFiltros(this.filtros);
    //this.obtenerPeriodosFacturados(1);

  }

  resetCMXFPeriodoFilter() {
    this.filtros.porFacturar.idCobradorFacturacionPeriodo = null;
  }

  public cobradoresSearch = function(term: string) {
    return this.movsServ.obtenerCobradores(term, null)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombre, apellidoPaterno, apellidoMaterno }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombre} ${apellidoPaterno} ${apellidoMaterno}` }
          )
        ))
    );
  }.bind(this);
  
  resetCMXFCobradorFilter() {
    this.filtros.porFacturar.idCobrador = null;
  }

  resetCFPeriodoFilter() {
    this.filtros.facturados.idCobradorFacturacionPeriodo = null;
  }

  resetCFCobradorFilter() {
    this.filtros.facturados.idCobrador = null;
  }

  public periodosSearch = function(term: string) {
    return this.periodoServ.searchPeriodos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: `${idCobradorFacturacionPeriodo} - ${nombre}` }
          )
        ))
    );
  }.bind(this);

  onClick({ row, columnPressed }) {
    if(columnPressed === 'idCobrador') {
         this.linkDetalleAgente(row);
     }
   }
     
   linkDetalleAgente(data: any) {
       this.routerNav.navigateByUrl('/agentes/' + data.idCobrador); 
   }
  
}
