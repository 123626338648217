import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { JWToken } from 'src/app/shared/interfaces/jwtoken';
import { SessionData } from 'src/app/shared/interfaces/session-data';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getSessionData(): SessionData {
    const token = localStorage.getItem('access-token');

    if (!Boolean(token)) {
      return null;
    }

    let data: SessionData = null;

    try {
      const jwt = jwtDecode<JWToken>(token);

      data = jwt.data;
    } catch(error) {}

    return data;
  }

  saveToken(token: string) {
    localStorage.setItem('access-token', token);
  }
}
