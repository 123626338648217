import { Component, OnInit } from '@angular/core';
import {  FormBuilder,  FormGroup,  FormArray,  FormControl,  Validators, } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApplicationService } from './../../application/shared/application.service';
import { LenderService } from '../lender.service';
import { Checklist } from 'src/app/catalogos/checklists/modelos/checklist.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';


export interface Field {
  id: number;
  name: string;
  key: string;
  required: boolean;
  idFieldType: number;
  customized: boolean;
}

@Component({
  selector: 'app-layout-fields',
  templateUrl: './layout-fields.component.html',
  styleUrls: ['./layout-fields.component.css'],
  providers: [LenderService]
})
export class LayoutFieldsComponent implements OnInit {
  title: string = '';
  filterGroup: FormGroup;
  formGroup: FormGroup;
  fields: FormArray;
  loading: boolean = true;
  filtrosAbiertos: boolean = false;
  filtros: any = {
    search: '',
    filtro: null
  };
  private checklist: Checklist;
  public idValue: any;
 public custom: boolean = true;
 public hijosActivos: boolean = false;
 private onDestroy: Subject<void> = new Subject<void>();
 public canSave: boolean = false;


 itemsFieldType: Array<any> = [{
  name: 'Entero',
  value: 1
}, {
  name: 'Decimal',
  value: 2
}, {
  name: 'Alfanumérico',
  value: 3
}, {
  name: 'Fecha',
  value: 4
}, {
  name: 'Fecha y hora',
  value: 5
}, {
  name: 'Boleano',
  value: 6
}, {
  name: 'Hora',
  value: 7}
];

  filters: Array<any> = [{
    name: 'Seleccione',
    value: ''
  }, {
    name: 'Generico',
    value: 1
  }, {
    name: 'Personalizado',
    value: 2
  }, {
    name: 'Requerido',
    value: 3
  }, {
    name: 'Mostrar en APP',
    value: 4
  }];

  displayedColumns: string[] = [ 'name', 'key', 'idFieldType', 'required', 'showOnApp', 'delete'];
  dataSource;
  dataSourceFiltered;
  default: boolean;
  nombreFinanciera: any;
  breadcrumb: string = '';
  nombreProducto: String;
  idFinanciera: any;
  idProducto: any;
  idCliente: any;
  originalFieldsBase64: string[] = [];
  fieldsRequired = ['id', 'nombre', 'subId']; 


  constructor(
    private formBuilder: FormBuilder,
    private lenderService: LenderService,
    private appService: ApplicationService,
    private route: ActivatedRoute, 
    private router: Router,
    private dialog: MatDialog // Añade esto

  ){
 
  }


   ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    this.idFinanciera = this.route.snapshot.queryParamMap.get('idFinanciera');
    this.idProducto = this.route.snapshot.queryParamMap.get('idProducto');
    this.idCliente = this.route.snapshot.queryParamMap.get('idCliente');

    this.cargarCampos(this.idCliente,this.idProducto)
  }

  inactivarHijo(){
    this.idFinanciera = this.route.snapshot.queryParamMap.get('idFinanciera');
    this.idProducto = this.route.snapshot.queryParamMap.get('idProducto');
    this.idCliente = this.route.snapshot.queryParamMap.get('idCliente');
    this.cargarCampos(this.idCliente,this.idProducto );
  }

  objectToBase64(obj: any): string {
    return btoa(encodeURIComponent(JSON.stringify(obj)));
  }

  isFieldDisabledRequired(key: string): boolean {
    return this.fieldsRequired.includes(key);
  }
  
  cargarCampos(idCliente: any, idProducto: any) {
    this.loading = true;
    this.fields = this.formBuilder.array([]);
    this.formGroup = this.formBuilder.group({
      fields: this.fields,
    });
  
    const fieldsDisabledRequired = ['id', 'nombre'];
  
    this.lenderService.getLayoutFieldsByProduct(idCliente, idProducto).subscribe(
      (fields: any[]) => {
        const dataSource = fields.map((field, index) => {
          const rowField = this.formBuilder.group({
            id: new FormControl({ value: field.id, disabled: true }, Validators.required),
            name: new FormControl({ value: field.name, disabled: true }, Validators.required),
            key: new FormControl({ value: field.key, disabled: true }, Validators.required),
            idFieldType: new FormControl({ value: field.idFieldType, disabled: !field.customized }, Validators.required),
            required: new FormControl({
              value: field.required,
              disabled: !field.customized && fieldsDisabledRequired.includes(field.key),
            }),
            showOnApp: new FormControl(field.showOnApp),
            customized: new FormControl(field.customized),
          });
  
          if (field.customized) {
            this.createKey(rowField);
          }
  
          rowField.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(() => {
              this.checkIfCanSave();
            });
  
          this.originalFieldsBase64.push(this.objectToBase64(rowField.getRawValue()));
          this.fields.push(rowField);
          return Object.assign({}, field, { index });
        });
  
        this.dataSourceFiltered = dataSource;
        this.dataSource = dataSource;
        this.loading = false;
        this.checkIfCanSave();
      },
      (error: any) => {
        this.loading = false;
        this.appService.showError(error);
      }
    );
  }
  

  onClose() {
    this.router.navigate([`catalogos/checklists`], {
      queryParamsHandling: 'merge'
    });
  }

  checkIfCanSave() {
    const fields: FormArray = this.formGroup.get('fields') as FormArray;
    this.canSave = fields.controls.some((control, index) => {
      const currentFieldBase64 = this.objectToBase64(control.getRawValue());
      const isModified = currentFieldBase64 !== this.originalFieldsBase64[index];
      return isModified;
    });
  }
  
  getFieldTypeName(value: number): string {
    const fieldType = this.itemsFieldType.find(item => item.value === value);
    return fieldType ? fieldType.name : '';
  }

  nuevoCampoSwitch(){
    this.router.navigate([`catalogos/checklists/layout/nuevo`], {
      queryParams: 
      {
        idCliente: this.idCliente,
        idProducto: this.idProducto           
      },
      queryParamsHandling: 'merge'
    });
  }

  normalize(str: string) {
    return str.toLowerCase().replace(/^(\d+)?/, '')
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => 
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      ).replace(/\s+/g, '');
  }

  createKey(rowField: FormGroup) {
    rowField.get('name').valueChanges.pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(name => {
      const key = this.normalize(name);

      rowField.get('key').setValue(key);

      let itemsForm = this.fields.getRawValue().filter(f => f.name && f.name !== name);
      let keys = itemsForm.filter(i => i.key).map(i => i.key);

      if (keys.includes(key)) {
        rowField.get('key').setErrors({ custom: `Ya existe otra clave "${key}"` });
        this.appService.showSnackbar('¡Aviso!', `Ya existe otra clave "${key}"`, 3000, 'warning');
      }
      this.checkIfCanSave();
    });
  }

  save() {
    const fields: FormArray = this.formGroup.get('fields') as FormArray;
  
    if (fields) {
      const fieldsModified = fields.controls
        .map((control, index) => {
          const currentField = control.getRawValue();
          const currentFieldBase64 = this.objectToBase64(currentField);
          const isModified = currentFieldBase64 !== this.originalFieldsBase64[index];
  
          return isModified ? {
            idCuentaCampo: currentField.id,
            idCuentaCampoTipoDato: currentField.idFieldType,
            clave: currentField.key,
            descripcion: currentField.name,
            requerido: currentField.required,
            mostrarEnApp: currentField.showOnApp
          } : null;
        })
        .filter(field => field !== null);
  
      if (fieldsModified.length > 0) {
        const loading = this.appService.showLoading('Estamos guardando el layout...');

        this.lenderService.saveLayoutFields(this.idFinanciera, this.idProducto, fieldsModified)
          .subscribe(() => {
            this.originalFieldsBase64 = fields.controls.map(control => this.objectToBase64(control.getRawValue()));
            this.appService.hideLoading(loading);
            this.appService.showSnackbar('¡Aviso!', 'La configuración del layout ha sido guardada.', 3000, 'success');
            this.cargarCampos(this.idCliente, this.idProducto);
          }, error => {
            this.appService.hideLoading(loading);
            this.appService.showError(error);
            //this.cargarCampos(this.idCliente,this.idProducto)

          });
      } else {
        this.appService.showSnackbar('¡Aviso!', 'No hay cambios para guardar.', 3000, 'warning');
      }
    } else {
      this.appService.showSnackbar('¡Aviso!', 'No se pudo acceder a los campos del formulario.', 3000, 'error');
    }
  }

  clearFilter(){
    this.filtros = {
      search: '',
      filtro: null
    };
    this.applyFilter();
  }

  applyFilter() {
    const fieldsFiltered = [];

    let dataSource = this.dataSource.slice();

    if (Boolean(this.filtros.search)) {
      dataSource = dataSource.filter(f => 
        String(f.name).toLowerCase().indexOf(this.filtros.search.toLowerCase()) !== -1
      );
    }

    if (Boolean(this.filtros.filtro)) {
      const FILTERS = {
        GENERIC: 1,
        CUSTOM: 2,
        REQUIRED: 3,
        SHOW_ON_APP: 4
      };

      dataSource = dataSource.filter(f => {
        if (this.filtros.filtro === FILTERS.GENERIC) {
          return !f.customized;
        } else if (this.filtros.filtro === FILTERS.CUSTOM) {
          return f.customized;
        } else if (this.filtros.filtro === FILTERS.REQUIRED) {
          return f.required;
        } else if (this.filtros.filtro === FILTERS.SHOW_ON_APP) {
          return f.showOnApp;
        }

        return false;
      });
    }

    this.dataSourceFiltered = dataSource;
  }

  deleteField(index: number) {
    const control = this.fields.at(index) as FormGroup;
    const idCuentaCampo = control.get('id').value;
    const key = control.get('key').value;
    const nonDeletableKeys = ['id', 'subId', 'nombre'];
  
    if (!nonDeletableKeys.includes(key)) {
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data: {
          titulo: "Confirmar",
          mensaje: `¿Deseas eliminar el campo con clave "${key}"?`,
          icono: "delete_forever",
          boton1: "No",
          boton2: "Sí",
          claseAccion: "boton-accion-eliminar"
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.lenderService.deleteLayoutFields(this.idCliente, this.idProducto, idCuentaCampo).subscribe(
            () => {
              this.appService.showSnackbar('¡Aviso!', `El campo con clave "${key}" ha sido eliminado.`, 3000, 'success');
  
              setTimeout(() => {
                // Elimina el campo del FormArray
                this.fields.removeAt(index);
    
                // Actualiza la lista originalFieldsBase64
                this.originalFieldsBase64.splice(index, 1);
    
                // Actualiza la dataSource y dataSourceFiltered para reflejar los cambios en la tabla
                this.dataSourceFiltered = this.dataSourceFiltered.filter((_: any, i: number) => i !== index);
                this.dataSource = this.dataSourceFiltered; // Sincroniza ambas fuentes de datos
    
                // Comprueba si se puede guardar después de eliminar el campo
                this.checkIfCanSave();
              }, 100);
            },
            error => this.appService.showError(error)
          );
        }
      });
    } else {
      this.appService.showSnackbar('¡Aviso!', `El campo con clave "${key}" no puede ser eliminado.`, 3000, 'warning');
    }
  }
  
}
