<div class="table-header" *ngIf="tableHeaders">
  <div class="header-left">
    <ng-content select="[t-left]"></ng-content>
  </div>

  <div class="header-right" *ngIf="!headers">
    <ng-content select=".ui.mini.icon.buttons"></ng-content>
  </div>

  <div class="header-right" *ngIf="headers">
    <ng-content select="[t-right]"></ng-content>

    <mat-form-field [formGroup]="filterFormGroup">
      <mat-icon matPrefix>search</mat-icon>

      <input matInput textSanitizer  placeholder="Buscar" formControlName="search">

      <button
        [disabled]="!filterFormGroup.dirty"
        mat-icon-button
        matSuffix
        (click)="filterFormGroup.reset(); $event.stopPropagation();"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="filters?.length" mat-icon-button (click)="openFilters()">
      <mat-icon aria-label="Filtros">filter_list</mat-icon>
    </button>

    <button mat-icon-button (click)="onDownload.emit()">
      <mat-icon aria-label="Descargar">file_download</mat-icon>
    </button>
  </div>

</div>

<div class="paginator">
  <div class="actions shadow-chico" align="right">
    <ng-content select="[t-paginator]"></ng-content>
  </div>
</div>
<div style="background-color: white; margin-top: -20px">
  <div class="{{classContainer}}">
    <app-loader *ngIf="loading" [message]="'Cargando información...'"></app-loader>
    <div>
      <ng-content select="[t-header]" style="margin-bottom: 10px;"></ng-content>
    </div>

    <table
      *ngIf="dataSource"
      mat-table
      [dataSource]="dataSource"
      matSort
      class="shadow-chico"
      (matSortChange)="sortData($event)"
      [ngClass]="{'hide': loading}"
    >
      <ng-container matColumnDef="select">
        <th class="encabezado" mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            class="kobra-checkbox"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()" *ngIf="multiSelect">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            class="kobra-checkbox"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)" *ngIf="multiSelect">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
        <th class="encabezado" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="column.hide" style="font-weight: 550;" class="generic-mat-cell" sticky>
          {{ column.name }}
        </th>

        <td mat-cell *matCellDef="let item" [hidden]="column.hide" class="generic-mat-cell">
          <ng-container *ngIf="!column.type || column.type === 'string'">
            {{ getValueByKey(item, column.key) }}
          </ng-container>

          <ng-container *ngIf="column.type === 'boolean'">
            {{ getValueByKey(item, column.key) ? 'Sí' : 'No' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'dateTime'">
            {{ getValueByKey(item, column.key) ? momentDate(getValueByKey(item, column.key)).format('DD/MM/YYYY HH:mm') : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'date'">
            {{ getValueByKey(item, column.key) ? momentDate(getValueByKey(item, column.key)).format('DD/MM/YYYY') : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'money'">
            <ng-container *ngIf="!column.link">
              {{ getValueByKey(item, column.key) | currency }}
            </ng-container>
            <a *ngIf="column.link" (click)="linkRow($event, item, column.key)">{{ getValueByKey(item, column.key) | currency }}</a>
          </ng-container>

          <ng-container *ngIf="column.type === 'link'">
            <a class="sombreado-link" *ngIf="column.link" [routerLink]="column.link + getValueByKey(item, column.key)">
              {{ getValueByKey(item, column.key) }}
            </a>
            <a class="sombreado-link" *ngIf="!column.link" (click)="handleLinkClick($event, item, column.key)">
              {{ getValueByKey(item, column.key) }}
            </a>
          </ng-container>

          <ng-container *ngIf="column.type === 'link-boolean'">
            <a mat-button *ngIf="!column.link" (click)="linkRow($event, item, column.key)">
              {{ getValueByKey(item, column.key) ? 'Sí' : 'No'}}
            </a>
          </ng-container>

          <ng-container *ngIf="column.type === 'icono'">
            <a
              (click)="linkRow($event, item, column.key)"
              [matTooltip]="column.tooltip"
              mat-tooltip-panel-above
              style="color: #00C776;"
            >
              <mat-icon>{{ column.icono }}</mat-icon>
            </a>
          </ng-container>

          <ng-container *ngIf="column.type === 'copy'">
            <button
              mat-icon-button
              matTooltip="Copiar variable"
              mat-tooltip-panel-above
              ngxClipboard
              [cbContent]="getValueByKey(item, column.key)"
              aria-label="Button that displays a tooltip when focused or hovered over"
            >
              <mat-icon style="color: #00C776;">file_copy</mat-icon>
            </button>
          </ng-container>

          <ng-container *ngIf="column.type === 'estatus'">
            <span [ngClass]="{
                'cuentaActiva': getValueByKey(item, column.key) === 'Activa',
                'cuentaEnEspera': getValueByKey(item, column.key) === 'Visitada',
                'cuentaApartada': getValueByKey(item, column.key) === 'Apartada',
                'cuentaEnEsperaDeConfirmacion': getValueByKey(item, column.key) === 'En Espera de Confirmación',
                'cuentaInactiva': getValueByKey(item, column.key) === 'Inactiva'
            }">
              {{ getValueByKey(item, column.key) }}
            </span>
          </ng-container>

          <ng-container *ngIf="column.type === 'estatusAgente'">
            <span [ngClass]="{
                'sin-estatus': getValueByKey(item, column.key) === 'Sin Estatus',
                'registro-incompleto': getValueByKey(item, column.key) === 'Registro Incompleto',
                'en-capacitacion': getValueByKey(item, column.key) === 'En Capacitación',
                'no-aprobado': getValueByKey(item, column.key) === 'No Aprobado',
                'gestionando': getValueByKey(item, column.key) === 'Gestionando',
                'sin-estatus-diferente': !['Sin Estatus', 'Registro Incompleto', 'En Capacitación', 'No Aprobado', 'Gestionando'].includes(getValueByKey(item, column.key))
            }">
              {{ getValueByKey(item, column.key) }}
            </span>
          </ng-container>

          <ng-container *ngIf="column.type === 'icon'">
            <img [src]="getValueByKey(item, column.key)" style="width: 35px;" />
          </ng-container>

          <ng-container *ngIf="column.type === 'button'">
            <button class="botonGuardar" (click)="linkRow($event, item, column.key)">{{ column.key }}</button>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ highlight: selection.isSelected(row) }"
        (click)="seleccionar(row);"
        (dblclick)="onDblClick(row)"
      ></tr>
    </table>

    <div *ngIf="items.length == 0 && !loading" style="text-align: center; margin-top: 20px; margin-bottom: 20px; background-color: white;">Sin información disponible</div>
  </div>

  <div>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

