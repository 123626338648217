import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaquetesFinanciera, PaquetesPagination } from './paquetes-financiera.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Injectable({
  providedIn: 'root'
})
export class PaquetesFinancieraService {

  public paquete: PaquetesFinanciera;
  public paquetes$: Observable<PaquetesFinanciera[]>;
  public pagination: PaquetesPagination;
  public appService: any = this.app;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;
  public loader: boolean;

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService
  ) { }

  getSaldosPaquetesFinancieras(queryParams: string): Observable<any> {
    return this.saldosKobraServ.get(`/saldos-financieras-paquetes${queryParams}`);
  }

  getLenderInfo(idLender: number): Observable<any> {
    return this.saldosKobraServ.get(`/financiera/${idLender}/info`)
      .pipe(
        map(res => res.data)
      );
  }

  setPaqueteData(data: PaquetesFinanciera): void {
    this.paquete = data;
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

}
