import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostBinding,
  ElementRef,
  forwardRef,
  Optional,
  Self,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormGroup, FormBuilder, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { MatFormFieldControl }  from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export class Field {
  constructor(public value) {};
}

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: FieldComponent,
  }]
})
export class FieldComponent implements OnInit, OnDestroy, MatFormFieldControl<Field>, ControlValueAccessor {
  stateChanges = new Subject<void>();
  focused = false;
  fieldForm: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() itemsCheckbox?: string[] = [];
  selectedItemsChecked: string[] = [];

  @Input() type: string = 'string';
  @Input() maxLenghtOptions: number = 2000;
  @Input() items: any[] = [];
  @Input() fileAccept: Array<string> = ['*/*'];
  @Input() maxWidth?: number = 0;
  @Input() maxHeight?: number = 0;
  @Input() maxValue?: number = 9999;
  @Input() disabledCombo?: boolean = false;
  @ViewChild('optionsInput') optionsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  static nextId = 0;
  @HostBinding() id = `field-${FieldComponent.nextId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = '';

  @Input()
  get value(): Field | null {
    const value: Field = this.fieldForm.value;
    return (value.value || value.value == 0) ? value : null;
  }
  set value(value: Field | null) {
    value = new Field(value);

    this.fieldForm.setValue({ value: value.value });
    if(this._onChange) this._onChange(value.value);
    this.stateChanges.next();
  }

  get empty() {
    const value = this.fieldForm.value as Field;
    return !value.value && value.value != 0;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    const temp: any = required;
    required = (temp != "true");
    this._required = required;
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    disabled = disabled;
    this._disabled = disabled;
    this.setDisable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if(!this.disabled) {
      this._onTouched();
    }
  }

  public onInputLimite(): void {
    const value = this.fieldForm.value.value;
    if (value > this.maxValue) {
      setTimeout(() => {
        this.fieldForm.setValue({ value: this.maxValue });
        if (this._onChange) this._onChange(this.maxValue);
      }, 10);
    } else if (value <= 0 || value === null || value === undefined) {
      setTimeout(() => {
        this.fieldForm.setValue({ value: '' }); // Deja el campo vacío
        if (this._onChange) this._onChange(null); // Puedes enviar null como valor
      }, 10);
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private app: ApplicationService,
    private formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    this.fieldForm = this.formBuilder.group({
      value: null
    });

    if(this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    focusMonitor.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    const validators = this.ngControl.control.validator;
    this.fieldForm.controls['value'].setValidators(validators ? validators : null);
    this.fieldForm.controls['value'].updateValueAndValidity();
    
    this.setDisable();

    if(this.itemsCheckbox.length > 0){
      for (let index = 0; index < this.itemsCheckbox.length; index++) {
        const iselected = this.itemsCheckbox[index];
        this.selectedItemsChecked.push(iselected);
      }
    }
    
    this.fieldForm.valueChanges.subscribe(
      () => {
        const value = this.value;

        if(this._onChange && value) this._onChange(value.value);
        this.stateChanges.next();
      }
    );
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }


  writeValue(value: any): void {
    if (value === null || (value !== null && typeof(value) !== 'object')) {
      value = new Field(value);
    }

    if (Array.isArray(value)) {
      value = new Field(value);
    }


    if (value.value || value.value === 0 || value.value === false) {
      this.fieldForm.setValue(value);
    }
  }

  _onChange: (_: any) => void;
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  _onTouched: () => void;
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private setDisable(): void {
    if(this.disabled && this.fieldForm) {
      this.fieldForm.disable();
      this.fieldForm.updateValueAndValidity();
    }
    else if(this.fieldForm) {
      this.fieldForm.enable();
    }
  }

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    let archivoValido: boolean = true;
    if (file) {
      const mimeType = file.type;
      
      for (let index = 0; index < this.fileAccept.length; index++) {
        const accept = this.fileAccept[index];
        if(!new RegExp(accept).test(mimeType)){
          index = this.fileAccept.length;
          archivoValido = false;
        }
      }
      if (!archivoValido) {
        this.app.showSnackbar('Aviso', 'El archivo no contiene el/los formato(s) correcto(s). Por favor selecciona un archivo con el/los formato(s) solicitado(s).', 0, 'warning');
        return;
      }

      if(this.maxWidth > 0 && this.maxHeight>0){
        archivoValido = false; //Se coloca aqui para que no guarde el archivo debido a que el onload tarda en ejecutarse
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          URL.revokeObjectURL(img.src); 
  
          if (width > this.maxWidth || height > this.maxHeight) {
            this.app.showSnackbar('Aviso', `La resolución de la imagen es demasiado alta. Por favor añade una imagen menor a ${this.maxWidth}x${this.maxHeight}.`, 0, 'warning');
            return;
          }
          this.fieldForm.setValue({ value: event.target.files[0] });
        };
      }
    }
    if(!archivoValido){
      return;
    }
    this.fieldForm.setValue({ value: event.target.files[0] });
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.onFileDropped(Array.from(files));
    }
  }
  
  onFileDropped(files: File[]) {
    const regexp = new RegExp(`(${this.fileAccept.join('|').replace(/\./ig, '\\.')})$`, 'ig');
  
    let found = false;
  
    for (let file of files) {
      if (!regexp.test(file.name)) {
        found = true;
        break;
      }
    }
  
    if (found) {
      return;
    }
  
    this.fieldForm.setValue({ value: files[0] });
  }
  
  
  removeFile(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fieldForm.setValue({ value: null });
  }

  addToList(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const valueFromForm = this.fieldForm.get('value').value || [];
      this.fieldForm.setValue({
        value: [].concat(valueFromForm.slice(), [{ label: value.trim() }])
      });
    }

    if (input) {
      input.value = '';
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const valueFromForm = this.fieldForm.get('value').value || [];
    this.fieldForm.setValue({
      value: [].concat(valueFromForm.slice(), [event.option.value])
    });
    this.optionsInput.nativeElement.value = '';
  }

  removeFromList(item): void {
    const index = this.fieldForm.get('value').value.findIndex((v) => v.label === item.label);

    if (index >= 0) {
      const valueFromForm = this.fieldForm.get('value').value.slice();

      valueFromForm.splice(index, 1);
      this.fieldForm.setValue({ value: valueFromForm });
    }
  }


  onRadioGroupChange(value) {
    this.fieldForm.setValue({ value });
  }

  onCheckBoxChange(value: string){
    if (this.selectedItemsChecked.includes(value)) {
      // Si el valor ya está en el array, quitarlo
      this.selectedItemsChecked = this.selectedItemsChecked.filter(item => item !== value);
    } else {
      // Si el valor no está en el array, agregarlo
      this.selectedItemsChecked.push(value);
    }
    this.fieldForm.setValue({
      value: this.selectedItemsChecked
    });
  }
}
