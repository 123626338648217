<router-outlet (activate)="hijosActivos = true;" (deactivate)="hijosActivos = false;inactivarHijo();"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start"
  [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">

        <ol class="lista-filtros-side">
          <div class="div-filtros-side">
            <li class="filtro-side">
              <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Nombre del cliente</mat-label>
                <input matInput textSanitizer [(ngModel)]="filters.nombre" placeholder="Capture el nombre del cliente"
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                <mat-icon matSuffix class="icono-limpiar" *ngIf="filters.nombre"
                  (click)="filters.nombre = null; this.contabilizadorDeFiltros('generales');">clear</mat-icon>
                <mat-icon matSuffix>lightbulb_circle</mat-icon>
              </mat-form-field>
         
            </li>
            <li class="filtro-side">
              <mat-form-field style="width: 100%;">
                <mat-label>Estatus</mat-label>
                <mat-select [(ngModel)]="filters.activo" placeholder="Seleccione..."
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                  <mat-option [value]="false">Inactiva</mat-option>
                  <mat-option [value]="true">Activa</mat-option>

                </mat-select>
                <mat-icon matSuffix class="icono-limpiar" *ngIf="filters.activo != null"
                  (click)="filters.activo = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
                <mat-icon matSuffix>visibility</mat-icon>
              </mat-form-field>
          
            </li>
          </div>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ol class="lista-filtros-buttons lista-filtros-groupless">
    <button style="width: 100%;" mat-stroked-button color="primary" class="boton-accion-front"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; loadLenders(1)">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="limpiarFiltros()">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
<div class="app content kobra" style="height: inherit;" *ngIf="!hijosActivos">
  <div class="button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="filtros" (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar" mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
          <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
        </mat-button-toggle>
        <mat-button-toggle value="descargas" (click)="download()" matTooltip="Descargar etiquetas"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">cloud_download</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="actualizar" (click)="loadLenders(filters.page ? filters.page : 1);"
          matTooltip="Refrescar" mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="subMenu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menu">
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above (click)="ayudaComentario(!ayuda);">
          <mat-icon color="primary">help</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>



  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['nuevo']">
      <mat-icon class="kobra-icon">add</mat-icon>
      <span>Nuevo</span>
    </button>
    <button mat-menu-item (click)="openConfiguration()" [disabled]="this.itemsSelected?.length !== 1">
      <mat-icon class="kobra-icon">settings</mat-icon>
      <span>Configuración</span>
    </button>
    <button mat-menu-item (click)="openEncargados()" [disabled]="this.itemsSelected?.length !== 1">
      <mat-icon class="kobra-icon">support_agent</mat-icon>
      <span>Asignar Ejecutivos 360</span>
    </button>
    <button mat-menu-item (click)="openDescargaModal()">
      <mat-icon class="kobra-icon">sim_card_download</mat-icon>
      <span>Estado de cuenta</span>
    </button>
    <button mat-menu-item (click)="activarDesactivarClient()" [disabled]="this.itemsSelected?.length !== 1  ">
      <mat-icon [ngClass]="{'kobra-delete-icon': itemsSelected?.length === 0 || itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Activa',
                            'kobra-icon': itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Inactiva'}">
        {{(itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Inactiva') ? 'check' : 'do_not_disturb_on'}}
      </mat-icon>

      <span>{{(itemsSelected?.length === 1 && itemsSelected[0]?.estatus === 'Inactiva') ? 'Activar' :
        'Inactivar'}}</span>
    </button>
  </mat-menu>

  <app-table [classContainer]="'tabla-completa'" [loading]="loading" [columns]="tabla.columns" [headers]="tabla.headers"
    [multiSelect]="tabla.multiSelect" [items]="lenders" (onSelectedRows)="onSelected($event)"
    (onLinkRow)="onLinkRow($event)" (sortChange)="sortChange($event)">
    <div t-header>
      <mat-card *ngIf="ayuda" class="cardAyuda">

        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>

            Este módulo está diseñado para proporcionar una visión completa de todos los clientes registrados en el
            sistema, incluyendo entidades como financieras, bancos, fintech, entre otros. Este módulo ofrece varias
            características y funcionalidades para una gestión de clientes efectiva:

            Muestra un listado detallado de todos los clientes dados de alta en el sistema. Este listado es útil para
            obtener una visión general rápida de todos los clientes con los que trabaja la organización.
            Dentro de las acciones que puedes realizar están las siguientes:

          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los
            conceptos, permitiendo aplicar filtros basados en diversos criterios.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
            criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar Estado de Cuenta: </b> Los usuarios
            pueden descargar estados de cuenta de los clientes para análisis o archivo, proporcionando un acceso fácil a
            la información financiera detallada.
          </p>
          <p>
            <mat-icon class="kobra-icon">do_not_disturb_on</mat-icon>/ <mat-icon class="kobra-icon">check</mat-icon><b>
              Inactivar/Activar Clientes: </b> Esta función permite a los usuarios cambiar el estado de actividad de los
            clientes, lo cual es crucial para la gestión y el mantenimiento de la base de datos de clientes.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <ng-container t-paginator>
      <mat-paginator showFirstLastButtons [length]="pagination?.totalRows" [pageIndex]="pagination?.page-1"
        [pageSize]="filters.pageSize" (page)="onPage($event)" aria-label="Select page of periodic elements">
      </mat-paginator>
    </ng-container>

  </app-table>
</div>