import { FiltrosService } from './../../application/shared/filtros.service';
import { AgentService } from './../../agent/agent.service';
import { ApplicationService } from './../../application/shared/application.service';
import { GeoCercaService } from './geo-cerca.service';
import { SessionData } from './../../shared/interfaces/session-data';
import { environmentSelector } from './../../../environments/environment.selector';
import { Lender } from './../../lender/lender.model';
import { LenderService } from './../../lender/lender.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { debounce, debounceTime, take, throwIfEmpty } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import { FiltrosCuentas } from 'src/app/account/modelos/filtros-cuentas.model';
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

import * as turf from '@turf/turf';

declare var $: any;


export interface User {
    name: string;
}

@Component({
    selector: 'app-geo-cerca-create',
    templateUrl: './geo-cerca-create.component.html',
    styleUrls: ['./geo-cerca-create.component.css'],
    providers: [
        LenderService,
        GeoCercaService,
        AgentService,
    ]
})
export class GeoCercaCrearComponent implements OnInit, OnDestroy {
    @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;
    private environment = environmentSelector();
    hijosActivos: boolean = false;
    filtrosAbiertos: boolean = false;
    ayuda: boolean = false;
    public form: FormGroup;
    public geoCercaForm: FormGroup;
    public filtrosForm: FormGroup;
    private $filterModal: any;
    public lenders: Lender[];
    public idLender: number;
    public mostrarMapa = false;
    map = null;
    public draw = null;
    public geoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
    public ultimaGeoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: '', points: { type: '', coordinates: [] } };
    public geoCercaACrear = { nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
    public geoCercaAEditar = { nombre: '', descripcion: '', fechaVencimiento: '', points: { type: '', coordinates: [] } };
    public crearGeoCerca = false;
    public mostrarTodasLasGeoCercas = false;
    public sessionData: SessionData;
    public count = 0;
    public filtros: FiltrosCuentas;
    public geoCercas = [];
    private _onDestroy = new Subject<void>();
    public acreditadoFilterSearch: any;
    public momentGeocerca;
    public subscribeRouterParamsBounded: Function;
    public modoCirculoActivado = false;
    private agenteFounded: any;
    public textoAgente = '';
    public agentesEncontrados= [];

    public idCobradorAsignado;
    public agentesGeocercas:any = [];
    public cargandoAgentes = false;
    @ViewChild('agenteFilterInput') private agenteFilterInput: any;
    //@ViewChild('asignarAgenteInput') private asignarAgenteInput: any;
    agenteSeleccionado: any = null;
    private asignarAgenteFilterFlag = false;
    private asignarAgenteFlag = false;
    private asignarAgenteFilterFound: any;
    public fechaVencimientoAgente = null;
    loading: boolean;
    public today: string = new Date().toISOString();
    isEditar: boolean;
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    public tipoMapa = 1;
    public answerElement: HTMLElement | null = null;

    constructor(
        private lenderService: LenderService,
        private fb: FormBuilder,
        private filtrosFormBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private geoCercaService: GeoCercaService,
        private app: ApplicationService,
        private agenteServ: AgentService,
        private rootElement: ElementRef,
        private filtrosService: FiltrosService,
        private dialog: MatDialog,

    ) {
        mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsb2plZGEiLCJhIjoiODRhYjAwZThkOTJmZDQ5NTlhODJhYjMyYzc1OGEwYjkifQ.N-vE7__8U68b6uS18FFJeg';
        this.filtrosForm = this.filtrosFormBuilder.group(
            { clienteSeleccionado: [''] }
        );
        this.sessionData = this.localStorageService.getSessionData();
        this.filtros = new FiltrosCuentas();
        this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.contabilizadorDeFiltrosTotales();
    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Generales', 'generalesAdmin', [
            'financiera',
            'idCobrador'
           
        ]);
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'idCobrador'
           
        ]);
      }
    
      public contabilizadorDeFiltrosTotales():void{
            
        let totalFiltrosArray: number[] = [];
        if (this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('generalesAdmin') );
        }else{
            totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        }
        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    }
    
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
      }

    ngOnInit() {
        this.momentGeocerca = moment;
        //this.$filterModal = $(this.rootElement.nativeElement).find('.ui.filter.modal');
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
        this.loadLenders();
        //$('.menu .item').tab();
    }


    ngAfterViewChecked() {
        this.inicializarMapa();
        //$('.menu .item').tab();

    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public loadLenders(): void {
        this.lenderService.getAll().subscribe(
            (lenders) => {
                this.lenders = lenders;
                if(this.sessionData.isSuperAdmin){
                    this.idLender = this.filtros.financiera ? this.filtros.financiera.idFinanciera : null;
                    this.onChangeLender(this.idLender);
                }else{
                    this.idLender = this.sessionData.idFinanciera; 
                    this.onChangeLender(this.idLender);
                    this.obtenerGeoCercas();
                }
            }

        );
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                }
            });
        }
        else {
            this.filtros.financiera = null;
        }

        this.contabilizadorDeFiltros('generales');

    }

    public mostrarOcultarMapaGeo(bandera: boolean) {
        this.mostrarMapa = bandera;
    }

    cambioTipoMapa() {
        let sTipoMapa = "";
        if (this.tipoMapa == 2) {
            sTipoMapa = 'satellite-streets-v12'
        } else {
            sTipoMapa = 'streets-v12'
        }
    
        this.map.setStyle('mapbox://styles/mapbox/' + sTipoMapa);
    }
    

    inicializarMapa() {
        if (!this.map) {
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: 'mapbox://styles/mapbox/streets-v12', // style URL
                center: [this.environment.paisLng, this.environment.paisLat],
                zoom: 5 // starting zoom
              });
          
              this.map.on('load', () => {
                this.draw = new MapboxDraw({
                  displayControlsDefault: false
                });
                this.map.addControl(this.draw);
          
                this.map.on('draw.create', this.dibujarGeoCerca.bind(this));
                this.map.on('draw.update', this.updateArea.bind(this));
                this.map.on('draw.selectionchange', this.geoSercaSeleccionadaV2.bind(this));
                this.map.on('draw.delete', (event) => {
                });
            });
        }
        
    }


    onSelectListEvent(geoCerca) {
        this.map.flyTo({
            zoom: 10,
            center: geoCerca.coordenadas[0]
        });
    }

    agregarGeoCerca(geoCerca) {
        if (this.crearGeoCerca) {
            const mySource = this.map.getSource('maine');
            const coordenadas = mySource._data.geometry.coordinates;
            coordenadas.push(geoCerca.coordenadas);
            this.setDataMapaBloqueadas(coordenadas);
        } else {
            setTimeout(() => {
                this.draw.add({
                    'id': geoCerca.idGeocerca,
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [geoCerca.coordenadas]
                    }
                });
            }, 0)

        }
    }
    geoSercaSeleccionadaV2() {

        if(!this.crearGeoCerca){
            const data = this.draw.getSelected();
            if (data.features.length > 0) {
                const geoCerca = this.geoCercas.find(geoCerca => geoCerca.idGeocerca == data.features[0].id);
                this.editarGeocerca(geoCerca);
            }
        }
       
    }

    /*geoSercaSeleccionada() {
        const data = this.draw.getSelected();
        if (data.features.length > 0) {
            console.log("console 1");
            if (!this.crearGeoCerca) {
                this.isEditar = true;
            }

            const geoCerca = this.geoCercas.find(geoCerca => geoCerca.idGeocerca == data.features[0].id);
            if(geoCerca){
                this.bloquearGeoCercasParaSeleccion(geoCerca);
                this.geoCercaSeleccionadaMapa.points = {
                    type: 'Polygon',
                    coordinates: [[data.features[0].coordinates]]
                }
                if (!geoCerca.nombre) {
                    this.geoCercaSeleccionadaMapa.nombre = '';
                    this.geoCercaSeleccionadaMapa.descripcion = '';
                    this.geoCercaSeleccionadaMapa.fechaVencimiento = null;
                    console.log("console primero");
                } else {
                    console.log("console segundo");
                    this.geoCercaSeleccionadaMapa.idGeocerca = geoCerca.idGeocerca;
                    this.geoCercaSeleccionadaMapa.nombre = geoCerca.nombre;
                    this.geoCercaSeleccionadaMapa.descripcion = geoCerca.descripcion;
                    this.geoCercaSeleccionadaMapa.fechaVencimiento =geoCerca.fechaVencimiento?new Date(geoCerca.fechaVencimiento):null;
                    this.geoCercaSeleccionadaMapa.points = { type: 'Polygon', coordinates: geoCerca.coordenadas };
                    this.fechaVencimientoAgente = this.geoCercaSeleccionadaMapa.fechaVencimiento;
                    console.log(this.geoCercaSeleccionadaMapa.points);
                }
                if (this.geoCercaSeleccionadaMapa.idGeocerca) {
                    console.log("console tercero");
                    this.obtenerAgentesGeocercas();
                    //setTimeout(() => { $('.ui.menu').find('.item').tab('change tab', 'tab-formulario'); }, 0);
                }
            }
            

        } else {
            console.log("console 2");
            this.isEditar = false;
            if (!this.crearGeoCerca) {
                //setTimeout(() => { $('.ui.menu').find('.item').tab('change tab', 'tab-list-geocercas'); }, 0);
                this.geoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
                this.agentesGeocercas = [];
            }

            this.setDataMapaBloqueadas([]);
            for (let i = 0; i < this.geoCercas.length; i++) {
                const geoCerca = this.geoCercas[i];
                if (geoCerca.seleccionada) {
                    this.agregarGeoCerca(geoCerca);
                }
            }
        }
    }*/

    seleccionarTodasLasGeoCercas(checked) {

        for (let i = 0; i < this.geoCercas.length; i++) {
            const geoCerca = this.geoCercas[i];
            geoCerca.seleccionada = checked;
            if (checked) {
                this.agregarGeoCerca(geoCerca);
            }
        }
        if (!checked) {

            this.setDataMapaBloqueadas([]);
            if (!this.crearGeoCerca && this.map) {
                this.draw.deleteAll();        
            }
        }
    }

    updateArea() {
        if (this.crearGeoCerca) {
            setTimeout(() => {
                this.isEditar = false;
            }, 0)
        }
        const data = this.draw.getSelected();
        
        if (this.crearGeoCerca) {
            if (data.features.length > 0) {
                this.geoCercaACrear = {
                    nombre: '',
                    descripcion: '',
                    fechaVencimiento: null,
                    points: {
                        type: 'Polygon',
                        coordinates: [data.features[0].geometry.coordinates[0]]
                    },
                    agentes: this.agentesGeocercas
                }
            } else {
                this.geoCercaACrear = {
                    nombre: '',
                    descripcion: '',
                    fechaVencimiento: null,
                    points: {
                        type: 'Polygon',
                        coordinates: [data.features[0].geometry.coordinates[0]]
                    },
                    agentes: this.agentesGeocercas
                }
            }
        } else {
            
            const geoCerca = this.geoCercas.find(geoCerca => geoCerca.idGeocerca == data.features[0].id);
            this.geoCercaSeleccionadaMapa = {
                idGeocerca: data.features[0].id,
                nombre: geoCerca.nombre,
                descripcion: geoCerca.descripcion,
                fechaVencimiento: geoCerca.fechaVencimiento,
                points: {
                    type: 'Polygon',
                    coordinates: data.features[0].geometry.coordinates[0]
                },
                agentes: this.agentesGeocercas
            }
        }
    }

    dibujarGeoCerca() {
        if (this.crearGeoCerca) {
            setTimeout(() => {
                this.isEditar = false;
            }, 0)
        }

        this.crearGeoCerca = true;
        const data = this.draw.getAll();
        this.geoCercaACrear = {
            nombre: '',
            descripcion: '',
            fechaVencimiento: null,
            points: {
                type: 'Polygon',
                coordinates: [data.features[0].geometry.coordinates[0]]
            },
            agentes: this.agentesGeocercas
        }
    }

    modoDibujar() {
        if(!this.filtros.financiera){
            this.app.showSnackbar("Aviso", "Favor de seleccionar un cliente.", 3000, 'warning');
            return;
        }
        this.crearGeoCerca = true;
        this.isEditar = false;
        this.geoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
        this.bloquearGeoCercas();
        this.draw.changeMode(this.draw.modes.DRAW_POLYGON);
    }

    
    modoCirculo(checked) {
        this.modoCirculoActivado = checked;
    }

    bloquearGeoCercas() {
        var geoCercasBloqueadasCoordenadas = [];

        for (let i = 0; i < this.geoCercas.length; i++) {
            const geoCerca = this.geoCercas[i];
            if (geoCerca.seleccionada) {
                geoCercasBloqueadasCoordenadas.push(geoCerca.coordenadas);
            }
        }
        this.draw.deleteAll();

        this.setDataMapaBloqueadas(geoCercasBloqueadasCoordenadas);
    }

    bloquearGeoCercasParaSeleccion(geoCercaSeleccionada) {
        var geoCercasBloqueadasCoordenadas = [];
        var arrayIds = [];
        for (let i = 0; i < this.geoCercas.length; i++) {
            const geoCerca = this.geoCercas[i];
            if (geoCerca.seleccionada) {
                if (geoCercaSeleccionada.idGeocerca != geoCerca.idGeocerca) {
                    arrayIds.push(geoCerca.idGeocerca);
                    geoCercasBloqueadasCoordenadas.push(geoCerca.coordenadas);
                }
            }
        }
        setTimeout(() => {
            this.draw.delete(arrayIds);
        }, 0)
        this.setDataMapaBloqueadas(geoCercasBloqueadasCoordenadas);

    }

    setDataMapaBloqueadas(geoCercasBloqueadasCoordenadas) {
        if (this.map.getSource("maine")) {
            this.map.getSource("maine").setData({
                'type': 'Feature',
                'geometry': {
                    'type': 'Polygon',
                    // These coordinates outline Maine.
                    'coordinates':
                        geoCercasBloqueadasCoordenadas,
                }
            });
        }

    }

    desactivarBotonAgregarAgente(){
        if(!this.idCobradorAsignado && this.fechaVencimientoAgente == null){
            return true;
        }
        return false;
    }

    borrarGeocerca(idGeocerca:number) {
        let loadingID: number = this.app.showLoading('Borrando geocerca');
        this.centrarMapaEnCoordenadas();
        this.geoCercaService.borrarGeoCercas(this.filtros.financiera.idCliente, idGeocerca)
            .pipe(take(1))
            .subscribe((respuesta) => {
                this.app.showSnackbar("Aviso", "La geocerca fue borrada correctamente", 3000, 'success');
                this.app.hideLoading(loadingID);
                //this.editarGeoCerca = false;
                this.obtenerGeoCercas();
                
            }, (error: any) => {
                this.app.showSnackbar("Aviso", "Hubo un error al borrar la geocerca", 3000, 'warning');
                this.app.hideLoading(loadingID);
                this.app.showError(error);
            });
    }

    borrarAgenteGeocerca(idGeocerca:number, idAgente:number) {
        let loadingID: number = this.app.showLoading('Borrando agente...');
        this.geoCercaService.borrarAgenteGeocerca(this.filtros.financiera.idCliente, idGeocerca, idAgente)
            .pipe(take(1))
            .subscribe((respuesta) => {
                this.app.showSnackbar("Aviso", "El agente fue borrado correctamente", 3000, 'success');
                this.app.hideLoading(loadingID);
                this.agentesGeocercas = this.agentesGeocercas.filter((agente) => {
                    return idAgente != agente.idAgente;
                });
            }, (error: any) => {
                this.app.showSnackbar("Aviso", "Hubo un error al borrar al agente", 3000, 'error');
                this.app.hideLoading(loadingID);
                this.app.showError(error);
            });
    }

    guardarGeoCerca() {
        /*const agentes = this.agentesGeocercas.map(agente => {
            agente.fechaVencimiento =agente.fechaVencimiento?moment(agente.fechaVencimiento).format('YYYY-MM-DD'):null;
            return agente;
        })*/

        if (this.geoCercaSeleccionadaMapa.fechaVencimiento) {
            for (const agente of this.agentesGeocercas) {
                if (agente.fechaVencimiento && moment(agente.fechaVencimiento).isAfter(this.geoCercaSeleccionadaMapa.fechaVencimiento)) {
                    this.app.showSnackbar("Aviso", "La fecha de vencimiento del agente "+ agente.nombre +" no puede ser mayor que la fecha de vencimiento de la geocerca.", 3000, 'warning');
                    return; 
                }

                if(!agente.fechaVencimiento){
                    this.app.showSnackbar("Aviso", "Favor de colorcar la fecha de vencimiento para el agente "+ agente.nombre, 3000, 'warning');
                    return; 
                }
            }
        }
        
        //$('.ui.menu').find('.item').tab('change tab', 'tab-list-geocercas');
       //this.agentesGeocercas = [];
        let loadingID: number = this.app.showLoading('Guardando geocerca');
        if (this.crearGeoCerca) {
            this.geoCercaACrear.nombre = this.geoCercaSeleccionadaMapa.nombre;
            this.geoCercaACrear.descripcion = this.geoCercaSeleccionadaMapa.descripcion;
            this.geoCercaACrear.fechaVencimiento =  this.geoCercaSeleccionadaMapa.fechaVencimiento?moment(this.geoCercaSeleccionadaMapa.fechaVencimiento).format('YYYY-MM-DD'):null;
            this.geoCercaACrear.points.coordinates = this.geoCercaACrear.points.coordinates[0];
            this.geoCercaACrear.agentes = this.agentesGeocercas;
            this.geoCercaService.guardarGeoCercas(this.filtros.financiera.idCliente, this.geoCercaACrear)
                .pipe(take(1))
                .subscribe((respuesta) => {
                    this.crearGeoCerca = false;
                    this.isEditar = false;
                    this.app.showSnackbar("Aviso", "La geocerca fue guardada correctamente", 3000, 'success');
                    this.app.hideLoading(loadingID);
                    this.resetAsignarAgente();
                    this.obtenerGeoCercas();
                }, (error: any) => {
                    this.app.showSnackbar("Aviso", "Hubo un error al guardar la geocerca", 3000, 'warning');
                    this.app.hideLoading(loadingID);
                    this.app.showError(error);
                });
        } else {
            this.geoCercaSeleccionadaMapa.agentes = this.agentesGeocercas;
           // this.geoCercaACrear.fechaVencimiento =  this.geoCercaSeleccionadaMapa.fechaVencimiento?moment(this.geoCercaSeleccionadaMapa.fechaVencimiento).format('YYYY-MM-DD'):null;
            this.geoCercaService.editarGeoCercas(this.filtros.financiera.idCliente, this.geoCercaSeleccionadaMapa)
                .pipe(take(1))
                .subscribe((respuesta) => {
                    this.isEditar = false;
                    //$('.ui.menu').find('.item').tab('change tab', 'tab-list-geocercas');
                    this.agentesGeocercas = [];
                    this.resetAsignarAgente();
                    this.obtenerGeoCercas();
                    this.app.showSnackbar("Aviso", "La geocerca fue editada correctamente", 3000, 'success');
                    this.app.hideLoading(loadingID);
                }, (error: any) => {
                    this.app.showSnackbar("Aviso", "Hubo un error al editar la geocerca", 3000, 'error');
                    this.app.hideLoading(loadingID);
                    this.app.showError(error);
                });
        }
    }

    cancelarGeoCerca() {
        this.crearGeoCerca = false;
        this.isEditar = false;
        this.resetAsignarAgente();
        this.agentesGeocercas = [];
        //setTimeout(() => { $('.ui.menu').find('.item').tab('change tab', 'tab-list-geocercas'); }, 0);
        this.draw.deleteAll();
        this.setDataMapaBloqueadas([]);
        this.geoCercaACrear = { nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
        this.geoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
        this.draw.changeMode(this.draw.modes.SIMPLE_SELECT);

        for (let i = 0; i < this.geoCercas.length; i++) {
            const geoCerca = this.geoCercas[i];
            if (geoCerca.seleccionada) {
                this.agregarGeoCerca(geoCerca);
            }
        }

    }


    obtenerGeoCercas() {
        const camposDiferentesANull = Object.keys(this.filtros).filter(campo => this.filtros[campo] !== null);
    
        if (camposDiferentesANull.length < 5) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return; 
            //Visitas (Todas las visitas) Por lo menos un filtro - OJO: no aplica el checkbox Excluir demo
        }
       
        this.draw.deleteAll();
        this.setDataMapaBloqueadas([]);
        this.geoCercaSeleccionadaMapa = { idGeocerca: 0, nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
        this.geoCercaACrear = { nombre: '', descripcion: '', fechaVencimiento: null, points: { type: '', coordinates: [] }, agentes: [] };
        this.agentesGeocercas = [];
        this.loading = true;
        const queryParam = this.obtenerQueryParams();
        this.geoCercaService.obtenerGeoCercas(this.filtros.financiera.idCliente, queryParam)
            .pipe(take(1)).subscribe((respuesta) => {
                this.loading = false;
                this.geoCercas = respuesta.data;
                for (let i = 0; i < this.geoCercas.length; i++) {
                    const geoCerca = this.geoCercas[i];
                    geoCerca.seleccionada = true;
                    if (geoCerca.seleccionada) {
                        this.agregarGeoCerca(geoCerca);
                    }
                }
                this.mostrarTodasLasGeoCercas = true;

            }, (error: any) => {
                this.loading = false;
                this.app.showError(error);
            });

            this.contabilizadorDeFiltrosTotales();
    }

    onAsignarAgenteSelected(agente: any) {
        this.asignarAgenteFlag = true;
        this.idCobradorAsignado = agente.idAgente;
    }

    onAsignarAgenteFilterSelected(agente: any) {
        this.asignarAgenteFilterFlag = true;
        //this.filtros.idCobradorAsignado = agente.idCobrador;
        this.filtros.idCobrador = agente.idAgente;
    }
    public asignarAgenteFilterSearch = function (term: string, seleccionarPrimero: boolean = false) {
        //this.agenteFilterInput._element.nativeElement.firstChild.firstChild.blur();
        return this.agenteServ.obtenerAgentes(term)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAgente, idCobrador, nombreCompleto }) => (
                        { idAgente: idAgente, idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}` }
                    )
                ))
        );
    }.bind(this);

    public asignarAgenteSearch = async (term: string, seleccionarPrimero: boolean = false) => {
        if (this.asignarAgenteFlag) {
            this.asignarAgenteFlag = false;
            return this.agenteFounded;
        }

        //this.asignarAgenteInput._element.nativeElement.firstChild.firstChild.blur();
        return new Promise<any>(resolve => {
            this.agenteServ.obtenerAgentes(term)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idAgente, idCobrador, nombreCompleto }) => (
                            { idAgente: idAgente, nombre: `${idCobrador} - ${nombreCompleto}` }
                        )
                    ))
                ).subscribe((res: any) => {
                    this.agenteFounded = res;
                    resolve(res);

                    /*setTimeout(() => {
                        if (seleccionarPrimero) {
                            this.asignarAgenteInput._element.nativeElement.firstChild.firstChild.value = this.agenteFounded[0].nombre;
                        }
                        else {
                            this.asignarAgenteInput._element.nativeElement.firstChild.firstChild.focus();
                        }
                    }, 200);*/
                }, (error: any) => {
                    this.app.showError(error);
                });
        });
    }

    resetAsignarAgente() {
        this.agenteSeleccionado = null;
        //this.asignarAgenteInput._element.nativeElement.firstChild.firstChild.value = '';
        //this.asignarAgenteInput.searchService.reset();
        if(!this.geoCercaSeleccionadaMapa.fechaVencimiento){
            this.fechaVencimientoAgente = null;
        }
        
    }

    resetAsignarAgenteFilter() {
        this.filtros.idCobrador = null;
        //this.agenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        //this.agenteFilterInput.searchService.reset();
    }

    obtenerAgentesGeocercas() {
        this.cargandoAgentes = true;
        this.geoCercaService.obtenerAgentes(this.filtros.financiera.idCliente, this.geoCercaSeleccionadaMapa.idGeocerca)
            .pipe(take(1)).subscribe((respuesta) => {
                this.cargandoAgentes = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    respuesta.data[i].nombre = `${respuesta.data[i].idCobrador} - ${respuesta.data[i].nombre}  ${respuesta.data[i].apellidoPaterno} ${respuesta.data[i].apellidoMaterno?respuesta.data[i].apellidoMaterno:''}`;
                    respuesta.data[i].isEliminar = false;
                }
                this.agentesGeocercas = respuesta.data;
                this.geoCercaSeleccionadaMapa.agentes = this.agentesGeocercas;
            }, (error: any) => {
                this.cargandoAgentes = false;
                this.app.showError(error);
            });
    }

    agregarAgenteGeocerca() {
        var agente:any = this.agentesGeocercas.filter((agente) => agente.idAgente == this.agenteSeleccionado.idAgente);
       
        if (agente.length == 0) {
            this.insertarAgente();
        }else if(agente[0].isEliminar) {
            this.agentesGeocercas = this.agentesGeocercas.filter((agente) => {
                return this.agenteSeleccionado.idAgente != agente.idAgente;
            });
            this.insertarAgente();
           
        }else {
            this.app.showSnackbar("Aviso", "El cobrador ya esta asignado a esta geocerca", 3000, 'warning');
        }
        this.resetAsignarAgente();
    }

    insertarAgente(){
        this.agentesGeocercas.push({
            idAgente: this.agenteSeleccionado.idAgente,
            idCobrador: this.agenteSeleccionado.idCobrador,
            nombre: this.agenteSeleccionado.nombre,
            fechaVencimiento: this.fechaVencimientoAgente?moment(this.fechaVencimientoAgente).format('YYYY-MM-DD'):null,
            isEliminar: false
        });
    }

    cambioFechaVencimientoGeoCerca($event) {
        this.geoCercaSeleccionadaMapa.fechaVencimiento = moment(new Date($event)).format('MM-DD-YYYY');
    }

    cambioFechaVencimientoAgente(agente) {
        agente.fechaVencimiento = agente.fechaVencimiento;
        //this.menuTrigger.closeMenu();
    }
    /*borrarAgenteGeocerca(idAgente) {
        this.agentesGeocercas = this.agentesGeocercas.filter((agente) => {
            return idAgente != agente.idAgente;
        });
    }*/

    guardarAgentesGeocercas() {
        // const agentesIds = this.agentesGeocercas.map(agente => {
        //     return agente.idAgente;
        // })
        // const body = {
        //     'agentes': agentesIds
        // }
        // $('.ui.menu').find('.item').tab('change tab', 'tab-list-geocercas');
        // this.agentesGeocercas = [];
        // this.resetAsignarAgenteFilter();
        // this.geoCercaService.guardarAgentes(this.filtros.financiera.idCliente, this.geoCercaSeleccionadaMapa.idGeocerca, body)
        //     .pipe(take(1)).subscribe((respuesta) => {

        //     }, (error: any) => {
        //         this.app.showError(error);
        //     });
    }
    public showFilter(): void {
        this.$filterModal.modal('show');
    }

    public clearFilter(): void {
        /*Object.keys(this.filtros).forEach(key => {
            if (key != 'financiera') {
                this.filtros[key] = null;
            }
        });*/
        this.idLender = null;
        this.filtros.financiera = null;
        this.resetAsignarAgenteFilter();

        this.filtrosService.removerFiltros();
        //this.obtenerGeoCercas();

    }

    public setFilter(): void {
        this.$filterModal.modal('hide');
        this.filtrosService.guardarFiltros(this.filtros);
        this.obtenerGeoCercas();
        this.contabilizadorDeFiltrosTotales();

    }


    private subscribeRouterParams(filtrosNuevos: FiltrosCuentas): void {
        var iniciar = false;
        if (filtrosNuevos) {
            iniciar = true;
            this.filtros = filtrosNuevos;

            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;


            if (this.filtros.idCobrador) {
                this.asignarAgenteFilterSearch(this.filtros.idCobrador + '');
            }

            this.obtenerGeoCercas();

        }
        else{
            this.idLender = this.sessionData.idFinanciera;
        }
        if (!(this.sessionData.isSuperAdmin)) {
            this.filtros.excludeFinancierasDemo = false;
        }

    }

    obtenerQueryParams() {
        let queryParam = '';
        if (this.filtros.idCobrador) {
            queryParam += '?idAgente=' + this.filtros.idCobrador.toString();
        }

        return queryParam;
    }

    validarGuardar() {
        if (this.crearGeoCerca && this.geoCercaSeleccionadaMapa.nombre !== '' && this.geoCercaACrear.points.coordinates[0]) {
            return true;
        }else if (this.geoCercaSeleccionadaMapa.nombre !== '' && this.isEditar) {
            return true;
        }
         else {
            return false;
        }
    }
    
    
    confirmarBorrado(geocerca: any){
        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            //height: '400px',
            //width: '300px',
            //disableClose: true,
            data:{
                titulo: "Confirmar",
                mensaje: `¿Deseas eliminar la geocerca?`,
                icono:"delete_forever",
                boton1: "No",
                boton2: "Sí",
                claseAccion: "boton-accion-eliminar"
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.borrarGeocerca(geocerca.idGeocerca);
            }
        });
    }

    confirmarBorradoAgente(agente: any){
        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            data:{
                titulo: "Confirmar",
                mensaje: `¿Deseas eliminar al agente?`,
                icono:"delete_forever",
                boton1: "No",
                boton2: "Sí",
                claseAccion: "boton-accion-eliminar"
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                agente.isEliminar = true;
                //this.borrarAgenteGeocerca(this.geoCercaSeleccionadaMapa.idGeocerca, agente.idAgente);
            }
        });
    }

    ayudaDescripcion(geocerca: any){
        geocerca.isDescripcion = !geocerca.isDescripcion;
    }

    editarGeocerca(geoCerca: any){
        
        this.isEditar = true;
        this.geoCercaSeleccionadaMapa.idGeocerca = geoCerca.idGeocerca;
        this.geoCercaSeleccionadaMapa.nombre = geoCerca.nombre;
        this.geoCercaSeleccionadaMapa.descripcion = geoCerca.descripcion;
        this.geoCercaSeleccionadaMapa.fechaVencimiento =geoCerca.fechaVencimiento?new Date(geoCerca.fechaVencimiento):null;
        this.geoCercaSeleccionadaMapa.points = { type: 'Polygon', coordinates: geoCerca.coordenadas };
        this.fechaVencimientoAgente = this.geoCercaSeleccionadaMapa.fechaVencimiento;
        this.obtenerAgentesGeocercas();
        //const geoCercaFiltadas = this.geoCercas.find(geoCerca => geoCerca.idGeocerca == data.features[0].id);
        if(geoCerca){
            this.bloquearGeoCercasParaSeleccion(geoCerca);
        }
    }

    centrarMapaEnCoordenadas() {
        this.map.setCenter([this.environment.paisLng, this.environment.paisLat]);
    }
}

