import { Directive, HostListener, Input } from '@angular/core';
import { AsyncValidator, AsyncValidatorFn, FormControlName, ControlContainer, Validator, ValidatorFn, ControlValueAccessor } from "@angular/forms";

import { FormValidation } from "./form-validation";
@Directive({
  selector: '[formValidationInput]'
})
export class FormValidationInputDirective {
  @Input() formValidationInput: FormValidation

  constructor() { }

  @HostListener('focusout') onFocusout() {
    this.formValidationInput.setTouched(true);
  }
}
