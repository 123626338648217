import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environmentSelector } from '../../../../environments/environment.selector';

import { MobileKobraResponse } from './mobile-kobra-response';

@Injectable()
export class MobileKobraService {
  private baseURL: string = environmentSelector().kobraServices.mobile;

  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices.mobile;
  }

  private headers(): HttpHeaders {
    let header: any = {'Content-Type': 'application/json'};
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Access-Token'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, login: boolean = false): Observable<MobileKobraResponse> {
    return req.pipe(
      map(res => {
        let response = <MobileKobraResponse> res;
        if(!response.success) {
          throw response.message;
        }
        return response;
      }),
      catchError((err: HttpErrorResponse|string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = err.error.message;
          } else {
            console.log(err);
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            }
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              console.log(err);
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(
                `Backend returned code ${err.status}, ` +
                `body was: ${err.message}`);
              message = err.message;
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete( path: string ): Observable<MobileKobraResponse> {
    return this.request(this.httpClient.delete(this.baseURL + path, {headers: this.headers()}));
  }

  public get( path: string ): Observable<MobileKobraResponse> {
    return this.request(this.httpClient.get(this.baseURL + path, {headers: this.headers()}));
  }

  public patch( path: string, data: any ): Observable<MobileKobraResponse> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, {headers: this.headers()}));
  }

  public post( path: string, data: any ): Observable<MobileKobraResponse> {
    return this.request(this.httpClient.post(this.baseURL + path, data, {headers: this.headers()}));
  }
}
