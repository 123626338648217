 
  <div class="app content kobra">
  
    <mat-card class="kobra-card">
      <!-- <mat-card-header>
        <mat-card-title>{{titulo}}</mat-card-title>
      </mat-card-header> -->
      <mat-card-content class="kobra-card-content">
        <app-loader *ngIf="loadings.productos || loadings.financieras || loadings.tiposImportacion || loadings.tiposProductos" [message]="'Obteniendo información...'"></app-loader>
        <div *ngIf="!loadings.productos && !loadings.financieras && !loadings.tiposImportacion && !loadings.tiposProductos">
          <mat-grid-list-responsive [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="4:1" *ngIf="sessionData.isSuperAdmin && !editarPresente">
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-select-search
                class="id-card-field pago-gestor"
                [label]="'Cliente'"
                [placeHolder]="'Selecciona un cliente'"
                [value]="'idFinanciera'"
                [labelField]="'nombre'"
                [icon]="'account_balance'"
                [options]="financieras"
                [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos"
                [(ngModel)]="idFinanciera"
                (ngModelChange)="onChangeLender($event);"
              >
              </mat-select-search>
            </mat-grid-tile>
          </mat-grid-list-responsive>
  
          <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px">
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field class="pago-gestor">
                <mat-label>Nombre del producto</mat-label>
                <input matInput textSanitizer  textSanitizer
                  [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos"
                  [(ngModel)]="producto.nombre"
                  maxlength="100"
                  placeholder="Capture el nombre del producto">
                <mat-icon matSuffix>conveyor_belt</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field class="pago-gestor">
                <mat-label>Descripción</mat-label>
                <input matInput textSanitizer 
                  [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos"
                  maxlength="200"
                  [(ngModel)]="producto.descripcion"
                  placeholder="Capture la descripción del producto">
                <mat-icon matSuffix>description</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field class="pago-gestor">
                <mat-label>Tipo de producto</mat-label>
                <mat-select
                  [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos"
                  [(ngModel)]="producto.idTipoProducto"
                  placeholder="Seleccione el tipo de producto"
                >
                  <ng-container *ngFor="let tipoProducto of tiposProductos; index as ti">
                    <mat-option [value]="tipoProducto.idTipoProducto">{{tipoProducto.nombre}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-icon matSuffix>format_list_bulleted</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field class="pago-gestor">
                <mat-label>Tipos de importación del producto</mat-label>
                <mat-select
                  [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos"
                  [(ngModel)]="tiposImportacionSeleccionados"
                  (ngModelChange)="cambioSeleccion()"
                  placeholder="Seleccione los tipos de importación del producto"
                  multiple
                >
                  <mat-option [value]="cuentasNuevas" [disabled]="true">{{cuentasNuevas.nombre}}</mat-option>
                  <ng-container *ngFor="let tipoImportacion of tiposImportacion; index as ti">
                    <mat-option [value]="tipoImportacion">{{tipoImportacion.nombre}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-icon matSuffix>upload</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list-responsive>
          <div>
            <mat-card class="cardAyuda">
              <p>
                <b>NOTA:</b> Todos los productos llevan por defecto el tipo de importación de cuentas nuevas, si se desea agregar alguna otra se pueden seleccionar desde el campo designado.
              </p>
            </mat-card>
          </div>
  
          <div class="button-center">
            <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()" [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos">
              <mat-icon class="kobra-icon">
                close
              </mat-icon> Cancelar</button>
            <button mat-stroked-button class="kobra-button" type="submit" (click)="save()" [disabled]="loadings.financieras || loadings.guardando || loadings.tiposImportacion || loadings.tiposProductos">
              <mat-icon>
                save
              </mat-icon> Guardar</button>
          </div>
        </div>
        
      </mat-card-content>
      <!-- <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadings.guardando">
        <i *ngIf="loadings.guardando">Guardando producto...</i>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer> -->
    </mat-card>
  </div>
  