<!-- <div class="ui bottom attached segment"> -->
  <!-- <div class="ui two columns grid"> -->
  <!-- <div class="ui two columns grid"> -->
  <div class="app content kobra">
    <mat-card>
      <mat-card-header class="margin-header">
        <mat-card-title>Desasignación</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div class="kobra three columns">
            <div class="kobra column">
              <mat-date-picker-formater
                [label]="'Fecha inicio'"
                [placeHolder]="'Capture la fecha inicio'"
                [max]="today"
                [(ngModel)]="filterPeriodStartDate"
              />
             
            </div>
            <div class="kobra column">
              <mat-date-picker-formater
                [label]="'Fecha fin'"
                [placeHolder]="'Capture la fecha fin'"
                [min]="filterPeriodStartDate"
                [max]="today"
                [(ngModel)]="filterPeriodEndDate"
              />
              
            </div>
            <div align="right" class="kobra column">
              <button style="width: 40%;" mat-stroked-button (click)="loadPercentageDeAllocation()"><mat-icon class="kobra-icon">filter_alt</mat-icon> Filtrar</button> 
            </div>      
          </div>
          <div class="kobra three columns" style="margin-top: 20px;">
            <div class="kobra column">
              <mat-card class="metric-card">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-value valor">
                    {{percentageDeallocation.totalAssignedAccounts}}
                  </div>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-description">
                    Total cuentas asignadas
                  </div>
                </mat-card-content>
              </mat-card>
              <!-- <mat-card style="height: 120px;">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation" class="valor valor-centered">
                    {{percentageDeallocation.totalAssignedAccounts}}
                  </div>
                  <div class="titulo" *ngIf="!loadingPercentageDeAllocation">
                    Total cuentas asignadas
                  </div>
                </mat-card-content>
              </mat-card> -->
            </div>
            <div class="kobra column">
              <mat-card class="metric-card">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-value valor-amarrillo">
                    {{percentageDeallocation.totalExpiredAccounts}}
                  </div>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-description">
                    Porcentaje de desasignación
                  </div>
                </mat-card-content>
              </mat-card>
              <!-- <mat-card style="height: 120px;">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation" class="valor-amarrillo valor-centered">
                    {{percentageDeallocation.totalExpiredAccounts}}
                  </div>
                  <div class="titulo" *ngIf="!loadingPercentageDeAllocation">
                    Total cuentas vencidas
                  </div>
                </mat-card-content>
              </mat-card> -->
            </div>
            <div class="kobra column"> 
              <mat-card class="metric-card">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-value valor-rojo">
                    {{percentageDeallocation.percentageDeallocation}}
                  </div>
                  <div *ngIf="!loadingPercentageDeAllocation"  class="metric-description">
                    Porcentaje de desasignación
                  </div>
                </mat-card-content>
              </mat-card>
              <!-- <mat-card style="height: 120px;">
                <mat-card-content>
                  <app-loader *ngIf="loadingPercentageDeAllocation"></app-loader>
                  <div *ngIf="!loadingPercentageDeAllocation" class="valor-rojo valor-centered">
                    {{percentageDeallocation.percentageDeallocation}}
                  </div>
                  <div class="titulo" *ngIf="!loadingPercentageDeAllocation">
                    Porcentaje de desasignación
                  </div>
                </mat-card-content>
              </mat-card> -->
            </div>
          </div>
        </div>
        <!-- metric-card.component.html -->
      </mat-card-content>
    </mat-card>
  </div>
  
<!-- </div> -->
