<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="lenders"
          [(ngModel)]="idLender"
          (ngModelChange)="onChangeLender($event);"
          [ngModelOptions]="{standalone: true}"
        >
        </mat-select-search>
      
      </li>
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
          <mat-label>Nombre producto</mat-label>
          <input matInput textSanitizer  textSanitizer
            [(ngModel)]="filters.nombre" (ngModelChange)="this.contabilizadorDeFiltros('generales');"
            placeholder="Capture el producto a buscar">
            <mat-icon matSuffix  class="icono-limpiar"
            *ngIf="filters.nombre" 
            (click)="filters.nombre = null">clear</mat-icon>
          <mat-icon matSuffix>lightbulb_circle</mat-icon>
        </mat-form-field>
        <!-- <div align="left" *ngIf="filters.nombre" class="limpiar-seleccion-kobra">
          <a (click)="filters.nombre = null"><i>Limpiar</i></a>
        </div> -->
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filtrar(1)">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
  
  <div class="app content tabla kobra" *ngIf="productos && !hijosActivos">

    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar administradores"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadProductos(filters.page ? filters.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="agregarProducto()">
          <mat-icon class="kobra-icon">add</mat-icon>
          <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable" (click)="editarProducto()">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable" (click)="openDialogConfirmacion(itemsSelected[0])">
        <mat-icon color="warn">delete_forever</mat-icon>
        <span>Eliminar</span>
      </button>
  </mat-menu>
    <app-table class="table-margen"
      [classContainer]="'tabla-completa'"
      [loading]="loadings.conceptosProductos"
      [columns]="tabla.columns"
      [items]="productos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (sortChange)="sortChange($event)"
      (onDownload)="download()"
      (onDoubleClick)="onDoubleClick($event)"
    >
    <!--div class="list-actions" t-left>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          (click)="agregarProducto('nuevo')"
      >
          <mat-icon class="kobra-icon">add</mat-icon>
          Agregar
      </button>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable"
          (click)="editarProducto(itemsSelected[0])"
      >
          <mat-icon class="kobra-icon">edit</mat-icon>
          Editar
      </button>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable"
          (click)="openDialogConfirmacion(itemsSelected[0])"
      >
          <mat-icon class="kobra-delete-icon">delete</mat-icon>
          Borrar
      </button>
    </div>
  
  
      <div class="ui mini icon buttons">
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin" [ngClass]="{primary: filters.filtered}"
          matTooltip="Filtrar"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
        >
          <mat-icon class="kobra-icon" *ngIf="!filters.filtered">filter_alt_off</mat-icon>
          <mat-icon class="kobra-icon" *ngIf="filters.filtered">filter_alt</mat-icon>
        </button>
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin"
          matTooltip="Ayuda"
          mat-tooltip-panel-above
          class="botonAyuda"
          (click)="ayudaComentario(!ayuda)"
        >
          <mat-icon color="primary">help</mat-icon>
        </button>
      </div-->

      <div t-header>
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-content>
           <p><mat-icon color="primary">help</mat-icon> 
            El módulo de Productos está diseñado para ofrecer un control detallado sobre los productos que se ligan a las cuentas y a los cuestionarios utilizados durante las visitas. Este módulo es clave para personalizar y gestionar la relación entre productos y cuentas.
            Los productos se pueden vincular a cuentas específicas y a cuestionarios utilizados en las visitas. Esto permite que cada producto tenga un comportamiento y tratamiento único durante la gestión de la cuenta.
            Las características y funcionalidades principales de esta vista son:
            </p> 
           Acciones:
           <p>
             <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los conceptos, permitiendo aplicar filtros basados en diversos criterios.
           </p>
           <p>
             <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
           </p>
           <p>
             <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de los productos para su análisis o uso fuera de línea.
           </p>
           <p>
             <mat-icon class="kobra-icon">add</mat-icon><b> Nuevos: </b> Los usuarios pueden añadir nuevos productos al sistema, lo que permite un mejor control a la hora de gestionar sus cuentas y visitas.
           </p>
           <p>
             <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta función posibilita la actualización y modificación de los detalles de los productos existentes, asegurando que la información sea precisa y esté actualizada.
           </p>
           <p>
             <mat-icon color="warn">delete_forever</mat-icon><b> Borrar: </b> Ofrece la opción de eliminar productos del sistema, una funcionalidad crucial para mantener la relevancia y precisión del catálogo de productos.
           </p>
         </mat-card-content>
       </mat-card> 
     </div>

      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="datosConsulta.rows"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
    
    <!--mat-card-footer class="kobra-footer-catalogo" *ngIf="loadings.borrando">
      <i>Eliminando registro...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer-->
  </div>
  