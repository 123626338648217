
<!-- <h1 mat-dialog-title>Contratos</h1> -->
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="tabSeleccionado($event)">
  <mat-tab>
    <ng-template mat-tab-label >
      <mat-icon class="example-tab-icon kobra-icon">backup</mat-icon>
      <span class="titulo-tab">Subir Archivo</span>
    </ng-template>
    <mat-dialog-content>
      <div class="content" >
        <!-- <div *ngIf="tieneContratos">
            <alerta-informativa 
            clase="danger"
            titulo="¡Aviso!" 
            texto="Ya hay documentos cargados para la cita preparada para esta cuenta. Subir un nuevo archivo sustituirá los anteriores."></alerta-informativa>
        </div> -->
        <app-upload-file [allowMultiple]="true" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="handleFiles($event)"></app-upload-file>
      </div>
    </mat-dialog-content>
  </mat-tab>

  <mat-tab *ngIf="archivosContratos.length > 0">
    <ng-template mat-tab-label >
      <mat-icon  class="example-tab-icon kobra-icon">topic</mat-icon>
      <span  class="titulo-tab">Archivos Cargados</span>
    </ng-template>
    <mat-card-content style="overflow: auto; max-height: 300px;">
      <div *ngFor="let archivo of archivosContratos; let i = index;">
        <mat-card style="margin-bottom: 20px;">
          <mat-card-content>                      
            <div style="display: flex;">
              <mat-icon class="iconoDoc">picture_as_pdf</mat-icon>
              <div style="word-break: break-all; flex: 1; margin-top: 10px;">
                <span>{{archivo.nombreOriginal ? archivo.nombreOriginal : archivo.nombre}}</span> 
                <span *ngIf="archivo.peso" class="peso-archivo">({{archivo.peso}})</span> 
              </div>
              <div class="iconoGrid">
                <button mat-icon-button
                  (click)="descargarArchivo(archivo)"
                  matTooltip="Descargar contrato"
                  mat-tooltip-panel-above>
                  <mat-icon class="kobra-icon">cloud_download</mat-icon>
                </button>                         
              </div>
            </div>
            <div>
              <i style="color: #38373782;">{{archivo.fechaRegistro | date:'dd/MM/yyyy HH:mm'}} </i>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-tab>
</mat-tab-group>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()" ><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button *ngIf="!listado" mat-stroked-button [ngClass]="{'boton-guardar': desbloquear}" [disabled]="!desbloquear" (click)="subirContrato()"><mat-icon>save</mat-icon>Guardar</button>
</mat-dialog-actions>
<mat-card-footer *ngIf="selected"  class="kobra-card-footer" >
  <mat-progress-bar mode="indeterminate" *ngIf="isArchivosContratos || cargandoContratos"></mat-progress-bar>
</mat-card-footer>
<mat-card-footer *ngIf="descargandoArchivo"  class="kobra-card-footer" >
  <i>Descargando archivo...</i>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card-footer>