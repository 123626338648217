import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SucursalesService } from './sucursales.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ISucursalesAdministrador} from './sucursales.interface';

@Component({
  selector: 'app-sucursales-modificar',
  templateUrl: './sucursales-modificar.component.html',
  styleUrls: ['./sucursales-modificar.component.css'],
  providers: [SucursalesService]
})
export class AdmistradorSucursalesModificarComponent implements OnInit {
  title: string = '';
  sucursal: ISucursalesAdministrador;
  sucursalesGrid: ISucursalesAdministrador[] = [];
  sucursalesGridAux: ISucursalesAdministrador[] = [];
  sucursales: ISucursalesAdministrador[] = [];
  fechaActual = new Date();
  loadings: any = {
    sucursales: true,
    administradoresSucursal: false
  };

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [ {
        name: 'ID Sucursal',
        key: 'idSucursal',
        hide: false
      }, {
        name: 'Sucursal',
        key: 'nombreSucursal',
        hide: false
      }, {
        name: 'Fecha Registro',
        key: 'fecRegistro',
        type: 'date',
        hide: false
      }, {
        name: 'Estatus',
        key: 'activo',
        type: 'estatus',
        hide: true
      }, {
        name: ' ',
        key: 'eliminar',
        hide: false,
        icono:'delete_forever',
        tooltip: "Eliminar",
        type: 'icono',
    }]
  }
  nombreCliente:string;
  nombreAdmin:string;
  idClienteAdministrador:number;
  idAdministradorFinanciera:number;
  idCliente:number;
  constructor(
    private appService: ApplicationService,
    private sucursalesService: SucursalesService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idAdministradorFinanciera = +this.route.snapshot.queryParamMap.get('idAdministradorFinanciera');
    this.idClienteAdministrador = +this.route.snapshot.queryParamMap.get('idClienteAdministrador');
    this.nombreAdmin  = this.route.snapshot.queryParamMap.get('nombreAdmin');
    //this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
    if (!this.idCliente || !this.idClienteAdministrador || !this.idAdministradorFinanciera || !this.nombreAdmin) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos/administradores`]);
    }else{
      this.loadSucursal();
      this.loadSucursalAdministradores();
    }
  
  }

  compararObjetos(objeto1: any, objeto2: any): boolean {
    //Devuelve false, cuando los objetos san diferentes
    // Convertir ambos objetos a cadenas JSON
    const jsonStr1 = JSON.stringify(objeto1);
    const jsonStr2 = JSON.stringify(objeto2);

    // Comparar las cadenas JSON
    return jsonStr1 === jsonStr2;
  }


  cancel() {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];

    // Eliminamos todos los parámetros existentes y luego agregamos el parámetro 'f'
    this.router.navigate(['/catalogos/administradores'], {
      queryParams: { f: fParam }
    });
  }

  save() {
    const loading = this.appService.showLoading('Actualizando información...');

    this.sucursalesService.editarAdministradorSucursales(this.idAdministradorFinanciera, this.sucursalesGrid).subscribe({
        next: () => {
            this.appService.hideLoading(loading);
            this.appService.showSnackbar('Aviso', 'Se ha actualizado el administrador correctamente', 3000, 'success');
            this.cancel();
        },
        error: (error) => {
            this.appService.hideLoading(loading);
            this.appService.showError(error);
        }
    });
}


  agregarSucursal() {
    if(this.sucursal){
      this.sucursal.fecRegistro = this.fechaActual;
      const sucursal = this.sucursalesGrid.some(element => element.idSucursal === this.sucursal.idSucursal);

      if (sucursal) {
        this.appService.showSnackbar('¡Aviso!', `La sucursal "${this.sucursal.nombreSucursal}" ya pertenece a este administrador.`, 3000, 'warning');
      } else {
        this.sucursalesGrid = this.sucursalesGrid.concat(this.sucursal);
        this.sucursal = null;
      }
    }
    else{
      this.appService.showSnackbar('¡Aviso!', 'Es necesario seleccionar una sucursal.', 3000, 'warning');
    }
  }

  loadSucursal(): void {
    this.loadings.sucursales = true;
    this.sucursalesService.obtieneSucursalesPorCliente(this.idCliente).pipe(
      take(1)
    ).subscribe({
      next: (res: ISucursalesAdministrador[]) => {
        this.sucursales = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.loadings.sucursales = false;
      },
      complete: () => {
        this.loadings.sucursales = false;
      }
    });
  }

  loadSucursalAdministradores() {
    this.loadings.administradoresSucursal = true;
    this.sucursalesService.obtieneSucursalesPorAdministrador(this.idCliente, this.idClienteAdministrador).pipe(
      take(1)
    ).subscribe({
      next: (res: ISucursalesAdministrador[]) => {
        this.sucursalesGrid = res;
        this.sucursalesGridAux = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.loadings.administradoresSucursal = false;
      },
      complete: () => {
        this.loadings.administradoresSucursal = false;
      }
    });
  }

  onClick({ row, columnPressed }) {
    if (columnPressed == 'eliminar') {
        this.sucursalesGrid = this.sucursalesGrid.filter(sucursal => sucursal.idSucursal !== row.idSucursal);
    }
  
  }
  
}
