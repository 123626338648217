export class ConceptosProductos{
    idConcepto: number;
    idProducto: number;
    idTipoConcepto: number;
    cargo: boolean;
    nombre: string;
    nombreTipoConcepto: string;

    constructor(){
    }

    public static map(data: any): ConceptosProductos{
        let instance:ConceptosProductos = new ConceptosProductos();
        instance.idConcepto = data.idConcepto || 0;
        instance.idProducto = data.idProducto || 0;
        instance.idTipoConcepto = data.idTipoConcepto || 0;
        instance.cargo = data.cargo || false;
        instance.nombre = data.nombre || '';
        instance.nombreTipoConcepto = data.nombreTipoConcepto || '';
        return instance;
    }

    public static mapArray(data: any[]): ConceptosProductos[]{
        let instance:ConceptosProductos[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            instance.push(ConceptosProductos.map(element));
        }
        return instance;
    }
}