import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput', {read: false}) fileInput: ElementRef;
  @Input() accept: Array<string>[] = [];
  @Input() multiplesArchivos: boolean = false;
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  removable: boolean = false;

  constructor(
    //private elem: ElementRef
  )
  { }

  ngOnInit() { }

  onFileSelected(event: any) {
    this.removable = true;
    this.onUpload.emit(event.target.files);
  }

  onFileDropped(event: any) {
    const files: File[] = event.dataTransfer.files;
    let found = false;
    
    for (let i=0;i<files.length;i++) {
      const regexp = new RegExp(`(${this.accept.join('|').replace(/\./ig, '\\.')})$`, 'ig');
      if (!regexp.test(files[i].name)) {
        found = true;
        break;
      }
    }

    if (found) {
      return this.onError.emit("Archivo no permitido para subir.");
    }

    this.removable = true;

    this.onUpload.emit(files);
  }

  onRemoveFiles(event) {
    this.fileInput.nativeElement.value = '';
    this.removable = false;
    this.onRemove.emit();
  }

  public onQuitar() {
    this.fileInput.nativeElement.value = '';
    this.removable = false;
  }
}
