import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ClientesMovimientosService } from './clientes-movimientos.service';
import { LenderService } from '@lender/lender.service';
import { Lender } from '@lender/lender.model';
import { ConceptosSaldosService } from '../conceptos-saldos/conceptos-saldos.service';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import { IClientesMovimientosPagination } from './clientes-movimientos.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalClienteImportarComponent } from '../modales/cliente-importar/cliente-importar.component';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


@Component({
  selector: 'app-clientes-movimientos',
  templateUrl: './clientes-movimientos.component.html',
  styleUrls: ['./clientes-movimientos.component.css'],
  providers: [
    LenderService,
    ClientesMovimientosService,
    ServiciosSaldosKobraService
  ]
})
export class ClientesMovimientosComponent implements OnInit, OnDestroy {

  private querySub: Subscription;
  public filter = false;
  public lenders: Lender[];

  date: Date = new Date();
  diaInicial: Date = new Date(this.date.setMonth(this.date.getMonth() - 1));

  public hoy: Date = new Date();
  public fechaInicioDefault: Date = new Date(this.hoy.getTime() - 30 * 24 * 60 * 60 * 1000);  
  public fechaMinRegistroFin: Date = new Date(new Date().setDate(new Date().getDate() - 1));
  public fechaMinMovimientoFin: Date = new Date();
  public fechaMaxRegistroInicio: Date = this.hoy;
  public fechaMaxMovimientoInicio: Date = this.hoy;

  private flag = true;
  private counterInicio = 0;
  private counterFin = 0;
  public ayuda: boolean = false;
  filtrosAbiertos: boolean = false;
  public hijosActivos: boolean = false;
  tablaCuentas: any;
  movs: any[];
  cargandoLista: boolean = true;
  public filtros = {
    fechaInicio: this.fechaInicioDefault,
    fechaFin: this.hoy,
    fechaMovInicio: null,
    fechaMovFin: null,
    idFinanciera: null,
    concepto: null,
    nombreConcepto: null,
    tipoMovimiento: 1,
    page: 1,
    sortField: 'fm.created_at',
    sortDirection: 'desc'
  }
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;
  
  public pagination: IClientesMovimientosPagination;
  public pageSize = 50;
  public fromRowPage: number;
  public toRowPage: number;
  sessionData: SessionData;
  fechaFin: string;
  fechaInicio: string;  
  fechaMovFin: string;
  fechaMovInicio: string;  
  queryParamsGlobal: string;

  constructor(
    public clientesMovsServ: ClientesMovimientosService,
    private route: ActivatedRoute,
    private app: ApplicationService,
    private router: Router,
    private lenderService: LenderService,
    private conceptosServ: ConceptosSaldosService,
    private serviciosSaldosKobraService: ServiciosSaldosKobraService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,

  ) {
    
    this.filtros.fechaMovInicio = null;
    this.filtros.fechaMovFin = null;

    this.movs = [];
    this.crearTabla();
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();

  }

  ngOnInit() {

    this.filtros.fechaMovInicio = null;
    this.filtros.fechaMovFin = null;
    this.filtros.tipoMovimiento = 1;
    this.obtenerClientes();
    this.clientesMovsServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.respuestaFiltros.bind(this));
    
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
        'idFinanciera'
    ]);
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'tipoMovimiento',
        'concepto'
    ]);
    this.contabilizadorFiltros.add('Fechas registro', 'fechasR', [
        'fechaInicio',
        'fechaFin'
    ]);
    this.contabilizadorFiltros.add('Fechas movimiento', 'fechasM', [
        'fechaMovInicio',
        'fechaMovFin'
    ]);
    this.contabilizadorFiltros.add('Fechas Total', 'fechasTotal', [
      'fechaMovInicio',
      'fechaMovFin',
      'fechaInicio',
      'fechaFin'
  ]);

  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
    if(this.sessionData.isSuperAdmin){
      totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
    }
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasR') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasM') );
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  crearTabla() {
    this.tablaCuentas = {
      headers: false,
      multiSelect: false,
      columns: [
        {
          name: 'Cliente',
          key: 'financiera',
          hide: false,
          sortName: 'f.nombre'
        },
        {
          name: 'Concepto',
          key: 'concepto',
          hide: false,
          sortName: 'c.nombre'
        },
        {
          name: 'Monto',
          key: 'monto',
          hide: false,
          type: 'money',
          sortName: 'fm.monto'
        },
        {
          name: 'Fecha Movimiento',
          key: 'fechaMovimiento',
          hide: false,
          type: 'dateTime',
          sortName: 'fm.fec_movimiento'
        }, 
        {
          name: 'Fecha Registro',
          key: 'fechaRegistro',
          hide: false,
          type: 'dateTime',
          sortName: 'fm.created_at'
        }
      ]
    }
  }

  ngOnDestroy() {
    /*if (this.clientesMovsSubscription) {
      this.clientesMovsSubscription.unsubscribe();
    }*/
  }

  ayudaComentario(bool: boolean) {
    this.ayuda = bool;
  }

  respuestaFiltros(filtrosNuevos: any) {

    if (filtrosNuevos) {
      this.filter = true;
      this.filtros = filtrosNuevos;
      
    }
    this.obtieneMovimientos(this.filtros.page || 1);

  }

  queryParamFiltros(page: number) {
    this.queryParamsGlobal = "";
    this.queryParamsGlobal += this.filtros.fechaInicio ?
      `&fechaInicio=${this.filtros.fechaInicio.toISOString().substr(0, 10)}` : '';

    this.queryParamsGlobal += this.filtros.fechaFin ?
      `&fechaFin=${this.filtros.fechaFin.toISOString().substr(0, 10)}` : '';

    this.queryParamsGlobal += this.filtros.fechaMovInicio ?
      `&fechaMovInicio=${this.filtros.fechaMovInicio.toISOString().substr(0, 10)}` : '';

    this.queryParamsGlobal += this.filtros.fechaMovFin ?
      `&fechaMovFin=${this.filtros.fechaMovFin.toISOString().substr(0, 10)}` : '';

    this.queryParamsGlobal += this.filtros.idFinanciera ? `&idFinanciera=${this.filtros.idFinanciera}` : '';

    this.queryParamsGlobal += this.filtros.concepto ? `&idConcepto=${this.filtros.concepto.idConcepto}` : '';

    if (this.filtros.tipoMovimiento && this.filtros.tipoMovimiento !== 1) {
      this.queryParamsGlobal += `&cargo=${this.filtros.tipoMovimiento === 2 ? true : false}`;
    }

    if (page != this.filtros.page) {
      this.filtros.page = page;
      this.clientesMovsServ.filtrosService.guardarFiltros(this.filtros);
    }
    
    return this.queryParamsGlobal;
  }

  setSortField(sort: any) {
    this.filtros.sortDirection = sort.direction;
    if (sort.active != this.filtros.sortField) {
      this.filtros.sortField = sort.active;
    }
    this.obtieneMovimientos(1);
  }

  obtenerMovimientosClientesExcel(): void {

    let queryParams = "";
    const gridSize = this.pagination.totalRows

    if (gridSize > 30000){
      //console.log(this.pagination)
      this.app.showSnackbar("Aviso", "El limite para la descarga es de 30,000 registros", 3000, 'warning')
      return;
    }

    if (gridSize >= 1) {
       queryParams += '?rows=' + this.pageSize;
       queryParams += '&idClienteAdministrador=' + this.sessionData.idClienteAdministrador + this.queryParamsGlobal;
      this.serviciosSaldosKobraService.descargarMovimientosClientesExcel(queryParams)
        .pipe(take(1))
        .subscribe((respuesta) => {
          this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
        }, (error: any) => {
          this.app.showError(error);
        });
      //console.log(this.pagination)
      //this.app.showSnackbar("Aviso", "entro", 3000, 'success')
    } else {
      this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
    }
  }

  public obtieneMovimientos(page: number) {
    this.cargandoLista = true;

    const campos = '"idFinanciera", "usuario", "concepto", "movimiento", "financiera", "monto", "cargo", "fechaRegistro", "fechaMovimiento"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.filtros.sortField}${this.filtros.sortDirection ? ' ' + this.filtros.sortDirection : ''}`;
    queryParams += this.queryParamFiltros(page);
    this.clientesMovsServ.getGrid(queryParams).subscribe(
      (res: any) => {
        if (res.success) {
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos,
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };
          for (let i = 0; i < datos.length; i++) {
            datos[i].cargo = datos.cargo ? 'Abono' : 'Cargo';
          }
          this.movs = datos;
        }
        this.cargandoLista = false;

      }, (error) => {
        this.app.showError(error);
        this.cargandoLista = false;
      },
    );
  }


  filterModal(parametro: boolean) {
    if (parametro) {
      Object.keys(this.filtros).forEach(key => {
        if (key != 'page' && this.filtros[key]) {
          this.filter = true;
        }
      });

      if (
        (this.filtros.fechaInicio &&
          !this.filtros.fechaFin)
        ||
        (!this.filtros.fechaInicio &&
          this.filtros.fechaFin)
        ||
        (!this.filtros.fechaMovInicio &&
          this.filtros.fechaMovFin)
        ||
        (this.filtros.fechaMovInicio &&
          !this.filtros.fechaMovFin)
      ) {
        this.app.showSnackbar('Aviso', 'Es necesario colocar rangos de fecha válidos', 3000, 'warning');
        return;
      }

      if (this.filter) {
        this.filtros.page = 1;
        this.clientesMovsServ.filtrosService.guardarFiltros(this.filtros);
        this.obtieneMovimientos(1);
      }
    } else {
      this.counterInicio = 0;
      this.counterFin = 0;
      this.filter = false;
      this.filtros.page = 1;
      this.filtros.tipoMovimiento = 1;
      this.removerFiltros();
    
      //this.obtieneMovimientos(1);

    }

    this.contabilizadorDeFiltrosTotales();
  
  }
  movimientoNuevo(): void {
    const navigationExtras = {
      state: {
        idFinanciera: this.filtros.idFinanciera
      }
    };

    this.router.navigate(['/clientes-movimientos/form'], navigationExtras);
  }

  removerFiltros() {
    Object.keys(this.filtros).forEach(key => {
      if (key !== 'sortField' && key !== 'sortDirection') {
        this.filtros[key] = null;
      }
    });
  }
  

  obtenerClientes(): void {
    this.lenderService.getAll('all').subscribe((lenders) => {
      this.lenders = lenders;
    }, (err: any) => {
      this.app.showError(err);
    });
  }

  public buscarConceptos = function (term: string) {
    let idTipoConceptoFinanciera: number = 1;

    if (this.conceptosFilterFlag) {
      this.conceptosFilterFlag = false;
      return this.conceptosFilterFounded;
    }
    return this.conceptosServ.buscarConceptos(term, idTipoConceptoFinanciera)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idConcepto, nombre, idTipoConcepto }) => (
            { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto }
          )
        ))
      );
  }.bind(this);


  resetFinancieraFilter() {
    this.filtros.idFinanciera = null;
  }

  cambioInicio($event, tipo: string) {
    if ($event === undefined || $event === null) {
      return;
    }
  
    const fechaInicio = new Date($event);
    if (tipo === 'reg') {
      this.fechaMinRegistroFin = fechaInicio;
      // Asegúrate de que la fecha de fin no sea anterior a la nueva fecha de inicio
      if (this.filtros.fechaFin && fechaInicio > new Date(this.filtros.fechaFin)) {
        this.filtros.fechaFin = $event;
      }
    }
    this.contabilizadorDeFiltros('fechasR');
    this.contabilizadorDeFiltros('fechasM');

  }
  
  cambioFin($event, tipo: string) {
    if ($event === undefined || $event === null) {
      return;
    }
  
    const fechaFin = new Date($event);
    if (tipo === 'reg') {
      this.fechaMaxRegistroInicio = fechaFin;
      // Asegúrate de que la fecha de inicio no sea posterior a la nueva fecha de fin
      if (this.filtros.fechaInicio && fechaFin < new Date(this.filtros.fechaInicio)) {
        this.filtros.fechaInicio = $event;
      }
    }
    this.contabilizadorDeFiltros('fechasR');
    this.contabilizadorDeFiltros('fechasM');
  }
  

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.pageSize) {
      this.pageSize = newPagina.pageSize;
      this.obtieneMovimientos(newPagina.pageIndex + 1);
    }
  }

  subirExcel() {
    this.dialog.open(ModalClienteImportarComponent);
  }

  inactivarHijo(){
    this.clientesMovsServ.filtrosService.asignarOpcion();
    if(this.filter){
      this.clientesMovsServ.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.clientesMovsServ.filtrosService.obtenerFiltrosIniciales(this.filtros, this.respuestaFiltros.bind(this));
  }
}
