import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lender-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.css']
})
export class LenderDetailDialogComponent {
  title: string = '';
  lender: any;

  constructor(
    public dialogRef: MatDialogRef<LenderDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.lender = data;
    this.title = `Cliente ${this.lender.nombre}`;
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
