import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AgentService } from "../../agent/agent.service";
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { ApplicationService } from "src/app/application/shared/application.service";
import { LoaderService } from "../../loader/loader.service";
import { VisitService } from "../../visit/visit.service";
import { Subscription } from "rxjs";
@Component({
    selector: 'app-visualizar-cuentas-dialog',
    templateUrl: 'visualizar-cuentas-dialog.component.html',
    styleUrls: ['visualizar-cuentas-dialog.component.css'],
    providers: [ AgentService, VisitService, LoaderService]
  })
  export class VisualizarCuentasDialog {
    @ViewChild("asignarAgenteFilterInput") private asignarAgenteFilterInput: any;
    public agentesSeleccionado:any = null;
    private asignarAgenteFilterFlag = false;
    private asignarAgenteFilterFound: any;
    agente: any;
    public cobradorAsignado: boolean = false;
    public agentes: any = [];
    public idAgente: any;
    public idCobrador: any;
    public nombreCompleto: any;
    public tablaAgentes: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        columnas: [
            {
                name: 'ID Agente',
                key: 'idCobrador',
                hide: false
            }, {
                name: 'ID Agente PG',
                key: 'idAgente',
                hide: true
            }, {
                name: 'Nombre',
                key: 'nombreAgente',
                hide: false
            } 
        ]
    }
    constructor(
        private visitService: VisitService,
        private loaderService: LoaderService,
        private app: ApplicationService,
        private agenteServ: AgentService,
        public dialogRef: MatDialogRef<VisualizarCuentasDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        //this.agentes = data;
    }
  
    onNoClick(): void {
      this.dialogRef.close(false);
    }


     onSelected(row) {
        this.agentesSeleccionado = row[0];
    }

    public eliminarAgente(agente: any): void {
        
        var newArray = this.agentes.filter((item) => item.idAgente !== agente.idAgente);
        this.agentes =  newArray;
        
    }

    resetAsignarAgenteFilter() {
        //this.formAsignacion.get('cobradorAsignado').patchValue(undefined);
        this.agente = null;
        this.idAgente = null;
        this.idCobrador = null;
        this.nombreCompleto = null;
        this.cobradorAsignado = false;
    }

    public asignarAgenteFilterSearch = function (term: string) {
        return this.agenteServ
            .obtenerAgentes(term, true)
            .pipe(
                take(1),
                map((res: any) =>
                    res.data.map(({ idCobrador, nombreCompleto, idAgente }) => ({
                        idCobrador: idCobrador,
                        nombre: idCobrador + " - " + nombreCompleto,
                        nombreAgente: nombreCompleto,
                        idAgente: idAgente
                    }))
                )
            );
    }.bind(this);

    onAsignarAgenteFilterSelected(agente: any) {
        this.asignarAgenteFilterFlag = true;
        this.cobradorAsignado = true;
        
        this.idAgente = agente.idAgente;
        this.nombreCompleto = agente.nombre;
        this.idCobrador = agente.idCobrador;

        //this.resetAsignarAgenteFilter();
        //this.formAsignacion.get("cobradorAsignado").patchValue(agente.idCobrador);
    }

    public agregarAgente():void {
        if (!this.idAgente) {
            this.app.showSnackbar(
                "Aviso",
                "Favor de seleccionar un agente.",
                3000,
                "warning"
            );
            return;
        }
        
        var newArray = this.agentes.filter((item) => item.idAgente === this.idAgente);
        
        if(newArray.length > 0) {
            this.app.showSnackbar(
                "Aviso",
                "El agente ya se encuentra en el listado.",
                3000,
                "warning"
            );
            return;

        }else {

            this.agentes.push({
                idAgente: this.idAgente,
                idCobrador: this.idCobrador,
                nombreCompleto : this.nombreCompleto
            });
            
        }

        this.resetAsignarAgenteFilter();
    }

    public limpiarAgente():void{
        this.agentes = [];
    }

    public asignar():void {
       
        let loading: number = this.app.showLoading(
            "Asignando cuentas a agentes..."
        );

        let subscription: Subscription = this.visitService
            
            .asignarCuentaAgente(
                this.data.accounts,
                this.agentes,
                this.data.idCliente,
            )
            .subscribe(
                (response) => {
                    this.loaderService.hideHttpLoader();
                    if (response.success) {
                        
                        if(response.data.vinculados > 0){
                            this.app.showSnackbar(
                                "Aviso",
                                "La cuentas fueron asignadas correctamente.",
                                3000,
                                "success"
                            );
                        }else{
                            this.app.showSnackbar(
                                "Aviso",
                                "Ocurrió un inconveniente en asignar las cuentas.",
                                3000,
                                "warning"
                            );
                        }
                    
                        this.dialogRef.close(response.data);
                    } else {
                        this.app.showSnackbar(
                            "Error",
                            response.error.message,
                            3000,
                            "error"
                        );
                    }
                },
                (error) => {
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                },
                () => {
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                }
            );
    }

  }