import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentService } from "../agent.service";
import { ApplicationService } from "src/app/application/shared/application.service";

export interface Tag {
  name: string;
  idTag: number;
};

@Component({
  selector: 'app-agent-state',
  templateUrl: './agent-state.component.html',
  styleUrls: ['./agent-state.component.css'],
  providers: [
    AgentService
  ]
})
export class AgentStateComponent implements OnInit {
  title: string = '¿Quieres dar de baja a este agente?';

  PUNISHMENT_ID = 1;

  tags: Tag[] = [];

  tagControl = new FormControl('', [Validators.required]);
  comment = '';

  constructor(
    private appService: ApplicationService,
    private agentService: AgentService,
    public dialogRef: MatDialogRef<AgentStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    //console.log(this.data.type);
    if (this.data.type == 'up-general') {
      this.title = '¿Quieres dar de alta a este agente?';
    }else if(this.data.type == 'down-punishment'){
      this.title = 'Aplicar castigo por desasignación';
    }else if(this.data.type == 'up-punishment'){
      this.title = 'Quitar castigo por desasignación';
    }

    let loadingID: number = this.appService.showLoading('Cargando etiquetas...');

    this.agentService.getDownTags(1).subscribe(tags => {
      if (this.data.type.indexOf('punishment') !== -1) {
        this.tagControl.setValue(this.PUNISHMENT_ID+'');
      } else {
        tags = tags.filter(t => t.idTag !== this.PUNISHMENT_ID);
      }
      
      this.tags = tags;
      this.appService.hideLoading(loadingID);
    }, error => {
      this.appService.showError(error);
      this.appService.hideLoading(loadingID);
    });
  }

  onClose() {
    this.dialogRef.close(false);
  }

  down(): void {
    let loadingID: number = this.appService.showLoading('Dando de baja al gestor...');

    const query = new URLSearchParams();
    query.set('castigo', this.data.type.indexOf('punishment') !== -1 ? '1' : '0');
    query.set('idEtiqueta', this.tagControl.value);
    query.set('comentario', this.comment || '');

    this.agentService.baja( this.data.agent.idAgente, `?${query.toString()}` ).subscribe(
      res => {
        this.appService.hideLoading(loadingID);

        this.dialogRef.close({
          deleted: true,
          bajaPorDesasignacion: this.data.type.indexOf('punishment') !== -1,
          diasBaja: res.data.diasBaja,
          fecBaja: res.data.fecBaja,
          fecVencimientoBaja: res.data.fecVencimientoBaja
        });
      },
      error => {
        this.appService.showError(error);
        this.appService.hideLoading(loadingID);
      },
      () => {
        this.appService.hideLoading(loadingID);
      }
    );
  }

  up(): void {
    let loadingID: number = this.appService.showLoading('Dando de alta al gestor...');

    this.agentService.alta(this.data.agent.idAgente, {
      altaPorDesasignacion: this.data.type.indexOf('punishment') !== -1,
      comentario: this.comment || ''
    }).subscribe(
      res => {
        this.appService.hideLoading(loadingID);

        this.dialogRef.close({
          deleted: false,
          bajaPorDesasignacion: false,
          diasBaja: null,
          fecBaja: null,
          fecVencimientoBaja: null
        });
      },
      error => {
        this.appService.showError(error);
        this.appService.hideLoading(loadingID);
      },
      () => {
        this.appService.hideLoading(loadingID);
      }
    );
  }

  getMensaje():string{
      return "Fue dado de " + this.data.lastMovement.movement.toLowerCase()+' por "'+ this.data.lastMovement.tag + '"';
  }

}
