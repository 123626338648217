import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { CuentaService } from "../../../cuentas/shared/cuenta.service";

@Component({
  selector: 'etiqueta-cuenta.component',
  templateUrl: 'etiqueta-cuenta.component.html',
  styleUrls: ["etiqueta-cuenta.component.css"],
  providers: [
    CuentaService
  ]
})
export class ModalEtiquetaCuentaComponent {
  public idProductoNvo: any;
  public selectedEtiqueta: any;
  public comentario: string;
  constructor(
    public dialogRef: MatDialogRef<ModalEtiquetaCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private cuentaService: CuentaService,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
  
  }

  public actualizaEtiqueta(): void {
    let cuenta: any = {};
    //alex
    cuenta = {
        idFinanciera: this.data.idFinanciera,
        idCliente: this.data.idCliente,
        idAcreditadoPG: this.data.idAcreditadoPG,
        idCuentaPG: this.data.idCuentaPG,
        idVisita:this.data.idVisita,
        idAgente: this.data.idAgente
    };

    if (this.idProductoNvo) {
        cuenta.idProducto = this.idProductoNvo;
    }

    if (this.selectedEtiqueta) {
        cuenta.idEtiquetaCuenta = this.selectedEtiqueta;
        if (!this.comentario) {
            this.app.showSnackbar(
                "Aviso",
                "Es necesario escribir un comentario",
                2000,
                "warning"
            );
            return;
        }
        cuenta.comentarioEtiqueta = this.comentario;
    }

    //this.nonSelectedTagWarning = false;

    const loading: number = this.app.showLoading('Actualizando cuenta.');
    this.cuentaService.actualizarCuenta(this.data.idCuenta, cuenta).subscribe(
        res => {
            this.app.hideLoading(loading);
            if (res.success) {
                //this.cargarCuenta(this.account.id, true);
                this.app.showSnackbar(
                    "Aviso",
                    "La cuenta se actualizó correctamente.",
                    3000,
                    "success"
                );
                this.dialogRef.close(true);
            } else {
                this.app.showSnackbar(
                    "Aviso",
                    "Ocurrio un Error: " + res.error.message,
                    5000,
                    "error"
                );
                this.dialogRef.close(false);
            }
        },
        error => {
            this.app.showError(error);
            this.app.hideLoading(loading);
        }
    );
  }

  limpiarProducto(){
    this.idProductoNvo = null;
  }

  limpiarEtiqueta(){
    this.selectedEtiqueta = null;
  }


}
