import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServicesVisitasService } from 'src/app/core/http/services-visitas/services-visitas.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPaginatorGeneral } from '../../shared/components/table/interfaces/tabla.interface';
import { V } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class EtiquetasVisitasService {
  public loader: boolean;

  public pagination: IPaginatorGeneral;
  public paginationInfo: IPaginatorGeneral;

  constructor(
    private visitasService: ServicesVisitasService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder
  )
  {
    this.pagination = {
        page: 1,
        pageData: [],
        pages: 0,
        totalRows: 0,
        pageSize: 50
    };
    this.paginationInfo = {
      page: 1,
      pageData: [],
      pages: 0,
      totalRows: 0,
      pageSize: 50
    };
  }

  public obtenerEtiquetas(page: number, filters: any){
    this.loader = true;
    let estatus = filters.estatus || '';
    let nombre = filters.nombre || '';

    const campos = '"idEtiquetaVisita", "etiquetaVisita", "activo"';
    if(filters.estatus != null){
      estatus = `&activo=${filters.estatus}`;
    }
    

    if (nombre != '') {
    //  console.log("entro");
      nombre = `&nombre=${filters.nombre}`;
    }

    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pagination.pageSize}&_slt=${campos}${estatus}${nombre}&_orderBy=${filters.sortField}${filters.sortAsc ? ' ' + filters.sortAsc : ''}`;

    let etiquetas = this.visitasService.get(`/catalogos/etiquetas-visitas${queryParams}`)
      .pipe(
        map((res: any) => {
          this.loader = false;
          let datos = res.data;
          for (let i = 0; i < datos.pageData.length; i++) {
            datos.pageData[i].estatus = datos.pageData[i].activo ? 'Activa' : 'Inactiva';
          }
          this.paginationInfo = datos;
          this.pagination.page = this.paginationInfo.page;
          this.pagination.totalRows = this.paginationInfo.totalRows;
          this.pagination.pages = this.paginationInfo.pages;

          return this.paginationInfo.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
    etiquetas.subscribe((res: any) => {
        this.loader = false;
        //this.pagination = res.data;
        //this.productos = ProductoNvo.mapArray(res.data.pageData);
        //this.calcFromToRowPage();
    }, (err: any) => {
      this.loader = false;
      this.app.showError(err);
    });
  }

  public obtenerEtiquetasAutocomplete(nombre: string): Observable<any> {
    let queryParams = `?_paginate=0&nombre=${nombre}`;

    return this.visitasService.get(`/catalogos/etiquetas-visitas${queryParams}`)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((error: any) => {
          this.app.showError(error);

          return of([]);
        })
      );
  }

  public nuevaEtiqueta(data) {
    return this.visitasService.post(`/catalogos/etiquetas-visitas`, data);
  }

  descargarEtiquetasVisitas(pParams: string){
    return this.visitasService.getFile(`/catalogos/etiquetas-visitas-download${pParams}`)
  }

  /*public calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }*/
}
