import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { State } from "./state.model";
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { GenericReservedActiveResponse } from "@reservedActive/generic-reserved-active";
import { GenericReservedActive } from "@reservedActive/generic-reserved-active";
import { map } from 'rxjs/operators';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable()
export class StateService {
  private apiService: string = `/v2/states`;
  private resourceAppsServices: string = '/estados';
  private resourceVisitsServices: string = '/estados';

  constructor(
    private apiKobra: ApiKobraService,
    private serviciosKobra: ServiciosKobraService,
    private visitasServ: ServicesVisitasService

  ) { }

  public get(query: string = ''){
    return this.serviciosKobra.get(`${this.resourceAppsServices}${query}`).pipe(
      map((res) => res.data)
    );
  }

  public all(): Observable<State[]> {
    return this.apiKobra.get(`${this.apiService}`).pipe(
      map((res) => <State[]> res.data)
    );
  }
  //obtiene las cuentas apartadas activas por estado
  public getAccountReservedActive(idCliente): Observable<GenericReservedActive> {
    let query: string = ''

    if (idCliente) {
      query += '?idCliente=' + idCliente;
    }

    return this.visitasServ.get(this.resourceVisitsServices + '/cuentas-apartadas-activas' + query).pipe(
      map(res => {
        let sumActivas = 0;
        let sumApartadas = 0;
        let sumDiaCero = 0;
        let sumDiaUno = 0;
        let sumDiaDos = 0;
        let sumDiaTres = 0;
        let sumDiaCuatro = 0;
        let sumDiaCinco = 0;
        let sumDiaSeis = 0;
        let sumDiaSiete = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
            let porcentage = 0;

            if ( +res.data[i].totalCuentasActivas && +res.data[i].totalCuentasApartadas) {
              porcentage = (+res.data[i].totalCuentasApartadas * 100 /(+res.data[i].totalCuentasActivas + +res.data[i].totalCuentasApartadas))
            }

            sumActivas += +res.data[i].totalCuentasActivas;
            sumApartadas += +res.data[i].totalCuentasApartadas;
            sumDiaCero += +res.data[i].diaCero;
            sumDiaUno += +res.data[i].diaUno;
            sumDiaDos += +res.data[i].diaDos;
            sumDiaTres += +res.data[i].diaTres;
            sumDiaCuatro += +res.data[i].diaCuatro;
            sumDiaCinco += +res.data[i].diaCinco;
            sumDiaSeis += +res.data[i].diaSeis;
            sumDiaSiete += +res.data[i].diaSiete;

            let stateReservedActiveResponse: GenericReservedActiveResponse = {
              nombre: res.data[i].nombreEstado,
              totalActivas: +res.data[i].totalCuentasActivas,
              totalApartadas: +res.data[i].totalCuentasApartadas,
              porcentajeApartadas: porcentage,
              diaCero: +res.data[i].diaCero,
              diaUno: +res.data[i].diaUno,
              diaDos: +res.data[i].diaDos,
              diaTres: +res.data[i].diaTres,
              diaCuatro: +res.data[i].diaCuatro,
              diaCinco: +res.data[i].diaCinco,
              diaSeis: +res.data[i].diaSeis,
              diaSiete: +res.data[i].diaSiete
            };

            genericsReservedActiveResponse.push(stateReservedActiveResponse);
        }

        let sumPorcentage = 0;

        if ( +sumApartadas && sumActivas) {
          sumPorcentage = (+sumApartadas * 100 /(+sumApartadas + sumActivas))
        }

        let stateReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumActivas: sumActivas,
          sumApartadas: sumApartadas,
          sumPorcentajeApartadas: sumPorcentage,
          sumDiaCero: sumDiaCero,
          sumDiaUno: sumDiaUno,
          sumDiaDos: sumDiaDos,
          sumDiaTres: sumDiaTres,
          sumDiaCuatro: sumDiaCuatro,
          sumDiaCinco: sumDiaCinco,
          sumDiaSeis: sumDiaSeis,
          sumDiaSiete: sumDiaSiete
        };

        return stateReservedActive;
      })
    );
  }

  estadoCuentasDetalle(queryParams): Observable<any> {
    return this.visitasServ.get(`${this.resourceVisitsServices}/cuentas-detalle${queryParams}`);
  }
}
