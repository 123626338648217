import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take, filter } from 'rxjs/operators';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SucursalesService } from '../sucursales.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { environmentSelector } from '../../../../environments/environment.selector';
declare var $: any;
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales-list.component.html',
  styleUrls: ['./sucursales-list.component.css'],
  providers: [LenderService]
})
export class SucursalesComponent implements OnInit {
  private environment = environmentSelector();

  filtrosAbiertos: boolean = false;
  columnasAbiertas: boolean = false;
  hijosActivos: boolean = false;
  ayuda: boolean = false;
  private $filterModal: any;
  sessionData: SessionData;
  public branches: any[] = [];
  public itemsSelected: any[] = [];
  public pagination: IPaginatorSucursales;

  public queryParams: URLSearchParams;
  public listFields: any = {};
  public lenders: any[] = [];
  public idLender: number = null;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [{
        name: 'Folio',
        key: 'idSucursal',
        hide: false
      }, {
        name: 'ID Financiera',
        key: 'idFinanciera',
        hide: true
      }, {
        name: 'Cliente',
        key: 'nombreFinanciera',
        hide: false
      }, {
        name: 'ID Sucursal',
        key: 'idSucursal',
        hide: true
      }, {
        name: 'Sucursal',
        key: 'nombreSucursal',
        hide: false
      }, {
        name: 'Alias',
        key: 'alias',
        type: 'string',
        hide: true
      }, {
        name: 'Activa',
        key: 'estatus',
        type: 'estatus',
        hide: false
      }
    ]
  }



  public filters: any = {
    financiera: null,
    nombreSucursal: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  }

  public datosConsulta: any = {
    slt: '"idFinanciera", nombreFinanciera, "idSucursal", "nombreSucursal", alias, descripcion, createdAt, updatedAt, activa, estatus',
    pageSize: 50,
    rows: 50,
    sortField: 'idSucursal',
    sortAsc: true,
    query: "",
    fromRowPage: 0,
    toRowPage: 10
  }

  public loadings: any = {
    sucursales: true,
    financieras: true
  }

  constructor(
    private lenderService: LenderService,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private sucursalesService: SucursalesService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router,
    private rootElement: ElementRef
    )
  {
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
  }

  ngOnInit() {
    this.$filterModal = $(this.rootElement.nativeElement).find('.ui.filter.modal');
    if(!this.sessionData.isSuperAdmin){
      this.filters.excluirDemo = false;
    }
    this.initListColumns();
    this.loadLenders();

    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));

  }

  private agregarContabilizadoresDeFiltros(){
   
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'financiera',
        'nombreSucursal',
        'activa',
       
    ]);

  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }
  

  public contabilizadorDeFiltrosTotales():void{
      

      let totalFiltrosArray: number[] = [];
    
      totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );


      this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }


  resultadoFiltros(filtrosNuevos: any){
    if(filtrosNuevos){
      this.filters = filtrosNuevos;
      this.filters.filter = true;
      this.filters.filtered = true;
    }
    this.idLender=this.filters.financiera ? this.filters.financiera.idFinanciera : null;
    this.loadings.sucursales = false;
   // this.loadBranches(this.filters.page || 1);
  }

  private initListColumns(): void {
    var listFields: any = JSON.parse(localStorage.getItem('visitListFields')) || {};

    this.listFields.lender = listFields.lender == false ? false : true;
    this.listFields.branchName = listFields.branchName == false ? false : true;
    this.listFields.status = listFields.status == false ? false : true;
    this.tabla.columns[0].hide = !this.listFields.lender;
    this.tabla.columns[2].hide = !this.listFields.branchName;
    this.tabla.columns[4].hide = !this.listFields.status;
  }

  public saveInStorage(index: number, value: boolean): void {
    this.tabla.columns[index].hide = !value;
    localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
  }



  public showFilter(): void {
    this.$filterModal.modal('show');
  }

  //INICIO EVENTOS TABLA
    onSelected(rows) {
      this.itemsSelected = rows.slice();
    }

    onLinkRow({ row, columnPressed }) {
      if (columnPressed === 'nombreSucursal') {
        //this.editarSucursal();
      }
    }

    onDoubleClick({row}){
      this.editarSucursal();
    }

    sortChange(sort: any) {
      if (sort.active == this.datosConsulta.sortField) {
        this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
      } else {
          this.datosConsulta.sortField = sort.active;
          this.datosConsulta.sortAsc = true;
      }
      this.loadBranches(1);
    }

    filtersChange(values) {
    }

    searchChange(text) {
    }

    public download(): void {
      const token = localStorage.getItem('access-token');
      let country = "&Country=";
      country+= this.environment.country;
      const url = `/sucursales/csv${this.crearURLQuery(1)}&token=${token}${country}`;
      window.open(this.environment.kobraServices.servicios + url);
    }
  //FIN EVENTOS TABLA

  //INICIO Eventos botones

    //Muestra el detalle de la sucursal ademas de validar si será para agregar, editaro o ver info solamente
    agregarSucursal() {
      this.router.navigate(
        ['nuevo', {}],
        {
            relativeTo: this.route,
            queryParamsHandling: 'preserve'
        }).then(() => {
            this.itemsSelected = [];
    });
    }

  // Función de edición del producto
  editarSucursal() {
      this.router.navigate(['editar'], {
        relativeTo: this.route,
        queryParams: {
          idFinanciera: this.itemsSelected[0].idFinanciera,
          idSucursal: this.itemsSelected[0].idSucursal,
          nombreSucursal: this.itemsSelected[0].nombreSucursal,
          nombreCliente: this.itemsSelected[0].nombreFinanciera
        },
        queryParamsHandling: 'merge'
      });
  }

    activarDesactivarSucursal(sucursal: any) {

      if (sucursal.activa) {
        var actionLabel = 'inactivar';
        var actionLabelSuccess = 'inactivó';
        var actionCliente = this.sucursalesService.deleteBranch(sucursal.idSucursal, sucursal.idFinanciera);
        var icono = 'delete_forever';
        var claseAccion = 'boton-accion-eliminar';


      } else {
        var actionLabel = 'activar';
        var actionLabelSuccess = 'activó';
        var actionCliente = this.sucursalesService.editBranch(sucursal.idSucursal, sucursal);
        var icono = 'save';
        var claseAccion = 'boton-accion-guardar';

      }

      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
                titulo: "Confirmar",
                mensaje: `¿Estas seguro de ${actionLabel} a "${sucursal.nombreSucursal}"?`,
                icono: icono,
                claseAccion: claseAccion,
                boton1: "No",
                boton2: "Sí"

            }
      });

      dialogRef.afterClosed().subscribe(result => {
    
        if(result) {
          actionCliente.subscribe(() => {
            this.app.showSnackbar('Aviso', `Se ${actionLabelSuccess} "${sucursal.nombreSucursal}" con éxito`, 3000, 'success');

            this.loadBranches(1);
          }, (error) => {
            this.app.showError(error);
          });
        }   
      });
      /*const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '400px', disableClose: true,
        data: {
          message: `¿Estas seguro de ${actionLabel} a "${sucursal.nombreSucursal}"?`
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          actionCliente.subscribe(() => {
            this.app.showSnackbar('Aviso', `Se ${actionLabelSuccess} "${sucursal.nombreSucursal}" con éxito`, 3000, 'success');

            this.loadBranches(1);
          }, (error) => {
            this.app.showError(error);
          });
        }
      });*/
    }

  //FIN Eventos Botones

  //INICIO Metodos del modal filtro

    public setFilter(): void {
      this.filters.filtered = true;
      this.filters.page = 1;
      this.filtrosService.guardarFiltros(this.filters);
      //this.$filterModal.modal('hide');
      this.loadBranches(1);
      this.contabilizadorDeFiltrosTotales()
    }

    public clearFilter(): void {
        Object.keys(this.filters).forEach(key => {
          if(this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')){
            this.filters[key] = null;
          }
        });
        this.idLender = null;
        this.filters.excluirDemo = true;
        this.filters.activa = true;
        this.filters.filtered = false;
        this.filters.page = 1;
        this.filtrosService.removerFiltros();
        this.contabilizadorDeFiltrosTotales()
    }

    public onChangeLender(event){
        if(event){
            this.lenders.forEach(lender => {
                if(lender.idFinanciera == event){
                    this.filters.financiera = lender;
                }
            });
        }
        else{
            this.filters.financiera = null;
        }

    }

  //FIN Metodos del modal filtro

  //Metodos Generales
    public loadBranches(page: number): void {
      if(this.filters.financiera == null){
        this.app.showSnackbar('Aviso', `Se necesita seleccionar una financiera`, 3000, 'warning');

        return;
      }
      this.loadings.sucursales = true;

      if(page != this.filters.page){
        this.filters.page = page;
        this.filtrosService.guardarFiltros(this.filters);
      }

      let query:string = this.crearURLQuery(page);

      this.sucursalesService.getBranches(query)
        .pipe(
          take(1)
        ).subscribe((res: any) => {
          this.pagination = res.data;
          this.branches = res.data.pageData;
          this.calcFromToRowPage();

          this.loadings.sucursales = false;
        }, (error: any) => {
          this.app.showError(error);

          this.loadings.sucursales = false;
        });
    }

    private crearURLQuery(page: number): string {
      let queryParams: string = '';

      queryParams += `?paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;
      if (this.datosConsulta.slt) {
          queryParams += '&slt=' + this.datosConsulta.slt;
      }

      if (this.datosConsulta.sortField) {
          queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
      }

      if (this.filters.financiera) {
          queryParams += '&idFinanciera=' + this.filters.financiera.idFinanciera;
      }

      if (this.filters.excluirDemo) {
        queryParams += '&excluirDemo=' + this.filters.excluirDemo;
      }

      if (this.filters.idSucursal) {
          queryParams += '&idSucursal=' + this.filters.idSucursal;
      }

      if (this.filters.nombreSucursal && this.filters.nombreSucursal != '') {
          queryParams += '&nombreSucursal=' + this.filters.nombreSucursal;
      }

      if (this.filters.alias && this.filters.alias != '') {
          queryParams += `&alias=${this.filters.alias}`;
      }

      if (this.filters.activa) {
          queryParams += `&activa=${this.filters.activa}`;
      }
      return queryParams;
    }

    /*onPage(pagina: number): void {
      if (pagina !== this.pagination.page) {
        this.loadBranches(pagina);
      }
    }*/

    onPage(newPagina: any): void {
      if(newPagina.pageIndex+1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
          this.datosConsulta.rows = newPagina.pageSize;
          this.loadBranches(newPagina.pageIndex+1);
      }
  }

    calcFromToRowPage() {
      if (this.pagination.pageData.length <= 0) {
        this.datosConsulta.fromRowPage = 0;
        this.datosConsulta.toRowPage = 0;
      } else {
        this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

        if (this.pagination.page === this.pagination.pages) {
          this.datosConsulta.toRowPage = this.pagination.totalRows;
        } else {
          this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
        }
      }
    }

    public loadLenders(): void {
      this.loadings.financieras = true;

      this.lenderService.getAll('all').subscribe(
          res => {
              this.lenders = res;
              if(!this.sessionData.isSuperAdmin){
                this.idLender = this.sessionData.idFinanciera;
                this.onChangeLender(this.idLender);
              }
          },
          err => {
              this.app.showError(err);
              this.loadings.financieras = false;
          },
          () => this.loadings.financieras = false
      );
    }

    resetFinancieraFilter() {
      this.idLender = null;
      this.filters.financiera = null;
    }

    inactivarHijo(){
      this.filtrosService.asignarOpcion();
      if(this.filters && this.filters.filtered){
        this.filtrosService.guardarFiltros(this.filters, true);
      }
      this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
    }
  //Fin Metodos Generales

}

export interface ISucursales {
  idFinanciera: number
  nombreFinanciera: string;
  idSucursal: number
  nombreSucursal: string;
  alias: string;
  descripcion: string,
  createdAt: string,
  updatedAt: string,
  activa: boolean;
  estatus: string;
}

export interface IPaginatorSucursales {
  page: number;
  pageData: [ISucursales];
  pages: number;
  totalRows: number;
}
