import { Account } from "./account.model";
import { Pagination } from "../pagination/pagination.model";

export class AccountPaginator {
  pagination: Pagination;
  data: Account[];

  constructor() {
    this.pagination = new Pagination();
  }
}