<mat-card [ngClass]="{ 'container': true, 'fullWidth': fullWidth }">
  <ng-container *ngIf="title">
    <mat-card-header class="header">
      <mat-card-title class="title">{{ title }}</mat-card-title>

      <ng-content select="[f-actions]"></ng-content>
    </mat-card-header>

    <mat-divider></mat-divider>
  </ng-container>

  <mat-card-content class="content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>